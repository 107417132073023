import React, { useState, useRef, useEffect, useLayoutEffect } from "react";

import { Typography, CircularProgress, InputBase, Paper, Popper, Grow, ClickAwayListener, Box, TextField } from "@mui/material";

import { mdiArrowRight } from "@mdi/js";

import { SvgIcon, Icons, SelectList } from "Components";

import { currencyFormat } from "Utils";

import { APIHelper, InteractionHelper } from "Helper";

import style from "../style.module.scss";

interface WalletCurrencyInfo {
	available: string;
	symbol: string;
	name: string;
	category: "crypto" | "fiat" | "unrecognizable";
	pairs: string[];
	disabled: boolean;
}

interface CountingCurrencyInfo {
	counting: number;
	symbol: string;
	name: string;
	category: "crypto" | "fiat" | "unrecognizable";
	min_amount: number;
	max_amount: number;
	disabled: boolean;
}

const SimulatePurchase: React.FC<{
	swapProps: {
		[p: string]: any;
	};
	onChange: (target: any) => void;
}> = ({ swapProps, onChange }) => {
	const props = new Proxy(typeof swapProps === "object" ? swapProps : {}, {
		set: function (target, property: string, value, receiver) {
			target[property] = value;
			if (typeof onChange === "function") {
				onChange(target);
			}
			return true;
		},
	});

	const [from, setFrom] = useState<string>(props.from);
	const [nameFrom, setNameFrom] = useState<string>("");
	const [to, setTo] = useState<string>(props.to);
	const [nameTo, setNameTo] = useState<string>("");

	const [loading, setLoading] = useState<boolean>(true);

	const [balanceAvailable, setBalanceAvailable] = useState<number>(0);

	const [value, setValue] = useState<number | string>(props.amount);

	const [convertValue, setConvertValue] = useState<number>(0);
	const [counting, setCounting] = useState<number>(0);

	const [conversionList, setConversionList] = useState<{
		[symbol: string]: WalletCurrencyInfo;
	}>({});
	const [countingList, setCountingList] = useState<{
		[symbol: string]: CountingCurrencyInfo;
	}>({});

	const [openPopper, setOpenPopper] = useState<boolean>(false);
	const [openPopperTo, setOpenPopperTo] = useState<number>(0);

	const [searchCurrency, setSearchCurrency] = useState<string>("");

	const selectSwapRef = useRef<HTMLDivElement | null>(null);

	useLayoutEffect(() => {
		setLoading(true);

		APIHelper.fetch<{
			[symbol: string]: WalletCurrencyInfo;
		}>(`swap/${props.walletId}/list_currency`, {}, 5)
			.then((list) => {
				if (!list[props.from] || list[props.from].disabled) {
					const info = Object.values(list).find(({ disabled }) => !disabled);
					if (info) {
						props.from = info.symbol;
						setFrom(props.from);
					}
				}

				setConversionList(() => list);

				return APIHelper.fetch<{ [symbol: string]: CountingCurrencyInfo }>(
					`swap/list_convert/${props.from}`,
					{
						walletType: props.walletType,
					},
					5,
				);
			})
			.then((list) => {
				if (!(props.to in list) || (props.to in list && list[props.to].disabled === true)) {
					const t = Object.values(list).find(({ disabled }) => disabled !== true);
					props.to = t?.symbol;
				}
				setCountingList(() => list);
			})
			.catch(() => {
				InteractionHelper.close();
				InteractionHelper.toast("Algo deu errado, verifique sua conexão e tente novamente!", null, "error");
			})
			.finally(() => {
				setFrom(props.from);
				setTo(props.to);
				setLoading(false);
			});
	}, [from]);

	useEffect(() => {
		const value1: WalletCurrencyInfo | undefined = Object.values(conversionList).find(
			({ symbol }) => String(symbol).trim().toUpperCase() === from,
		) as any;

		const available = !value1 ? 0 : parseFloat(value1.available ?? "0") ?? 0;

		setBalanceAvailable(available);
		setValue(available);
		setNameFrom(!value1 ? "" : value1.name);

		props.from = from;

		const value2: CountingCurrencyInfo | undefined = Object.values(countingList).find(({ symbol }) => String(symbol).trim().toUpperCase() === to);

		setCounting(!value2 ? 0 : value2.counting);
		setNameTo(!value2 ? "" : value2.name);

		props.to = to;
	}, [from, to, conversionList, countingList]);

	useEffect(() => {
		props.amount = isNaN(parseFloat(value as any)) ? 0 : parseFloat(value as any) ?? 0;
		setConvertValue(props.amount * counting);
	}, [value, counting]);

	if (loading) {
		return (
			<div className={[style["showSwap"], style["loading"]].join(" ")}>
				<CircularProgress color="inherit" />
			</div>
		);
	}

	const fromIsCurrencySystem = currencyFormat.indexOf(String(props.from).toUpperCase()) >= 0;

	const renderValueBalanceAvailable = currencyFormat.convert(
		balanceAvailable ?? 0,
		!fromIsCurrencySystem ? "USD" : String(props.from).toUpperCase(),
	);

	const inputValue = isNaN(parseFloat(value as any)) ? 0 : parseFloat(value as any) ?? 0;

	return (
		<div className={style["showSwap"]}>
			<div
				className={style["select-swap-convert"]}
				ref={selectSwapRef}
			>
				<div
					className={openPopper && openPopperTo === 0 ? style["active"] : ""}
					onClick={() => {
						setOpenPopperTo(0);
						setOpenPopper(true);
					}}
				>
					<Typography component="span">De</Typography>
					<div className={style["select-currency-swap"]}>
						<Icons
							name={String(props.from).toUpperCase()}
							style={{ marginRight: 15 }}
						/>
						<div>
							<Typography component="h6">{String(props.from).toUpperCase()}</Typography>
							<Typography component="div">{nameFrom}</Typography>
						</div>
					</div>
				</div>
				<div className={style["input-arrow-icon"]}>
					<SvgIcon path={mdiArrowRight} />
				</div>
				<div
					className={openPopper && openPopperTo === 1 ? style["active"] : ""}
					onClick={() => {
						setOpenPopperTo(1);
						setOpenPopper(true);
					}}
				>
					<Typography component="span">Para</Typography>
					<div className={style["select-currency-swap"]}>
						<Icons
							name={String(props.to).toUpperCase()}
							style={{ marginRight: 15 }}
						/>
						<div>
							<Typography component="h6">{String(props.to).toUpperCase()}</Typography>
							<Typography component="div">{nameTo}</Typography>
						</div>
					</div>
				</div>
			</div>
			<div className={style["input-swap-convert"]}>
				<div
					className={style["input-base"]}
					style={{
						borderColor: balanceAvailable < inputValue ? "var(--error-dark)" : "",
						backgroundColor: balanceAvailable < inputValue ? "var(--error-light)" : "",
						backgroundImage:
							balanceAvailable < inputValue ? "linear-gradient(var(--action-light-active), var(--action-light-active))" : "",
					}}
				>
					<Icons
						className={style["input-symbol"]}
						name={String(props.from).toUpperCase()}
						style={{ marginRight: 15 }}
					/>
					<div className={style["input-base-by"]}>
						<InputBase
							type="number"
							fullWidth
							sx={{ ml: 1, flex: 1 }}
							placeholder="0,00"
							value={value || ""}
							onChange={({ target: { value } }) => {
								setValue(value);
							}}
						/>
					</div>
					<Typography
						className={style["balance"]}
						variant="caption"
						component="p"
					>
						Saldo:{" "}
						{!fromIsCurrencySystem
							? `${renderValueBalanceAvailable?.toString().split(/\s/gi).pop()} ${props.from}`
							: renderValueBalanceAvailable}
					</Typography>
				</div>
				<div className={style["input-base"]}>
					<Icons
						className={style["input-symbol"]}
						name={String(props.to).toUpperCase()}
						style={{ marginRight: 15 }}
					/>
					<div className={style["input-base-by"]}>
						<InputBase
							type="number"
							fullWidth
							sx={{ ml: 1, flex: 1 }}
							placeholder="0,00"
							value={convertValue}
							readOnly
						/>
					</div>
					<Typography
						className={style["balance"]}
						variant="caption"
						component="p"
					></Typography>
				</div>
			</div>
			<div
				className={style["description"]}
				style={{ fontSize: "italic" }}
			>
				<Typography
					variant="caption"
					component="span"
				>
					1 {String(props.to).toUpperCase()} ={" "}
					{(currencyFormat.convert(parseFloat((1 / counting).toFixed(8))) ?? "0").toString().trim().split(/\s/gi).pop()}{" "}
					{String(props.from).toUpperCase()}
				</Typography>
			</div>
			<div className={style["description"]}>
				<Typography
					variant="caption"
					component="span"
				>
					{(currencyFormat.convert(parseFloat((inputValue || 0).toFixed(8))) ?? "0").toString().trim().split(/\s/gi).pop()}{" "}
					{String(props.from).toUpperCase()} ={" "}
					{(currencyFormat.convert(parseFloat(convertValue.toFixed(8))) ?? "0").toString().trim().split(/\s/gi).pop()}{" "}
					{String(props.to).toUpperCase()}
				</Typography>
			</div>

			<Popper
				open={openPopper}
				anchorEl={(openPopperTo === 0 ? selectSwapRef.current?.firstChild : selectSwapRef.current?.lastChild) as HTMLDivElement}
				transition
				container={document.body}
				className={style["popper-component"]}
				placement={openPopperTo === 0 ? "bottom-start" : "bottom-end"}
			>
				{({ TransitionProps, placement }) => {
					let disableds = Object.values(openPopperTo === 0 ? conversionList : countingList)
						.filter(({ disabled }) => disabled)
						.map(({ symbol }) => symbol);

					const list_select = Object.values(openPopperTo === 0 ? conversionList : countingList).map(({ symbol }, i) => {
						return {
							icon: (
								<Icons
									name={symbol}
									style={{ marginRight: 15 }}
								/>
							),
							label: symbol,
							value: symbol,
						};
					});

					const indiceListSelect = list_select.findIndex(({ value }) => value === (openPopperTo === 0 ? props.from : props.to));

					list_select.unshift(...list_select.splice(indiceListSelect, 1));

					const listHidden = list_select.filter(({ value }) => value.search(searchCurrency.toUpperCase()) < 0).map(({ value }) => value);

					return (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin: "center top",
								width: `${selectSwapRef.current?.offsetWidth ?? "200"}px`,
							}}
						>
							<Paper className={style["popper-paper-component"]}>
								<ClickAwayListener
									onClickAway={(event) => {
										if (selectSwapRef.current && event.target && selectSwapRef.current.contains(event.target as any)) {
											return;
										}
										setOpenPopper(false);
										setSearchCurrency("");
									}}
								>
									<Box>
										<Box sx={{ padding: "8px", marginBottom: "5px" }}>
											<TextField
												type={"text"}
												label="Pesquisar"
												variant="standard"
												size="small"
												fullWidth
												value={searchCurrency}
												onChange={({ target: { value } }) => setSearchCurrency(value)}
											/>
										</Box>
										<Box
											sx={{
												maxHeight: "300px",
												overflowY: "auto",
											}}
										>
											<SelectList
												value={openPopperTo === 0 ? props.from : props.to}
												list={list_select}
												disabled={disableds}
												hidden={listHidden}
												onSelect={(value) => {
													if (openPopperTo === 0) {
														setFrom(value as any);
													} else {
														setTo(value as any);
													}

													setOpenPopper(false);
													setSearchCurrency("");
												}}
											/>
										</Box>
									</Box>
								</ClickAwayListener>
							</Paper>
						</Grow>
					);
				}}
			</Popper>
		</div>
	);
};
export default SimulatePurchase;
