import React, { useEffect, useState } from "react";
import { Popover, Typography, CircularProgress, IconButton } from "@mui/material";
import { currencyFormat } from "Utils";
import { useRouter, useAsync } from "Utils/useHook";

import { UserHelper } from "Helper";
import { useWallets } from "Helper/WalletHelper";
import { Usuario, Wallet } from "Models";

import style from "./index.module.scss";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import { SvgIcon } from "Components";

const WalletsBar: React.FC<{}> = () => {
	//const windowSize = useHook.useWindowSize();

	const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
	const [indexHover, setIndexHover] = useState<number>(0);
	const [activeEye, setActiveEye] = useState<boolean>(true);

	const { push, history, location } = useRouter();

	const { wallets } = useWallets();
	//const wallets = [];

	const { value: user, ...userProps } = useAsync<Usuario>(UserHelper.getUser, false);

	const handlePopoverOpen = ({ currentTarget }: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		setIndexHover(index);
		setAnchorEl(currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const openPopover = Boolean(anchorEl);

	useEffect(() => {
		userProps.execute();
	}, [history, location]);

	if (!user || !(user instanceof Usuario)) {
		return <></>;
	}

	const walletSelect = wallets[indexHover] || new Wallet(null);
	const typeName = Wallet.validType(walletSelect.type) ? Wallet.walletTypeList()[walletSelect.type] : "";

	const click = (path: string) => {
		push(`/wallet/${path.split("/").pop()}`);
		handlePopoverClose();
	};

	const isLoading = Array.isArray(wallets) !== true || wallets.length <= 0;

	const eyeOff = () => {
		setActiveEye(!activeEye);
	};

	return (
		<div className={style.wallets_bar}>
			{isLoading && (
				<div style={{ background: "transparent", cursor: "auto" }}>
					<CircularProgress
						size={18}
						sx={{
							color: "var(--text-primary)",
							margin: "0px !important",
						}}
					/>
				</div>
			)}
			{wallets.length > 0 && (
				<IconButton
					className={style.eye}
					component="span"
					onClick={eyeOff}
					disableRipple
				>
					<SvgIcon
						path={activeEye ? mdiEye : mdiEyeOff}
						children={undefined}
					/>
				</IconButton>
			)}
			{wallets.map(({ totalValue, type, path, currencyType }, i) => {
				return (
					<div
						key={`Wallets-${type}-${i}`}
						onClick={() => click(path as any)}
						onMouseEnter={(e) => handlePopoverOpen(e, i)}
						onMouseLeave={handlePopoverClose}
					>
						{Wallet.walletIcon({ type })}
						<span>
							{activeEye
								? currencyFormat.secretCurrencyValue(currencyType ?? "USD")
								: currencyFormat.convert(parseFloat(totalValue.toFixed(2)), currencyType)}
						</span>
					</div>
				);
			})}
			<Popover
				sx={{ pointerEvents: "none" }}
				open={openPopover}
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				transformOrigin={{ vertical: "top", horizontal: "center" }}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<div className={style["Popover"]}>
					<div>
						{Wallet.walletIcon({ type: walletSelect.type })}
						<Typography
							className={style["WalletType"]}
							variant="overline"
						>
							{typeName}
						</Typography>
					</div>
					<div>
						<div className={style["WalletInfo"]}>
							<Typography variant="overline">{walletSelect.nome}</Typography>
							<Typography variant="caption">{walletSelect.getWalletCode()}</Typography>
						</div>
						<Typography className={style["WalletPrice"]}>
							{currencyFormat.convert(parseFloat(walletSelect.totalValue.toFixed(2)), walletSelect.currencyType)}
						</Typography>
					</div>
				</div>
			</Popover>
		</div>
	);
};

export default WalletsBar;
