import React, { useLayoutEffect } from "react";
import Home from "./Home";
import Wallet from "./Wallet";
import Ranking from "./Ranking";
import Settings from "./Settings";
import Quiz from "./Quiz";
import QuizEnd from "./Quiz/Components/QuizEnd";
import Profile from "./Profile";
import BDManager from "./BDManager";
import Chat from "./Chat";
import PreVenda from "./PreVenda";
import Page_404 from "./Page_404";
import Staking from "./Staking";
import NftCatalog from "./NftCatalog";
import Users from "./Users";
import PerfilUsuario from "./Users/Components/PerfilUsuario";
import Swap from "./Swap";
import EconomiaAoVivo from "./EconomiaAoVivo";
import ConversaoRapida from "./ConversaoRapida";
import Schedule from "./Schedule";
import QuizAdmin from "./QuizAdmin";
import TestCanvas from "./TestCanvas";

export const pages: Array<{
	path: string | string[];
	component: JSX.Element | React.FC<any> | React.ComponentClass<any, any> | React.FunctionComponent<any>;
	exact?: boolean;
	permissoes?: string[];
}> = [];

pages.push({
	path: "/schedule",
	component: Schedule,
	exact: false,
});

pages.push({
	path: "/chat",
	component: Chat,
	exact: false,
});

pages.push({
	path: "/nfts_catalog",
	component: NftCatalog,
});

pages.push({
	path: "/bd_manager",
	component: BDManager,
	permissoes: ["root", "desenvolvedor"],
});

pages.push({
	path: "/settings",
	component: Settings,
});

pages.push({
	path: "/profile",
	component: Profile,
});

pages.push({
	path: ["/wallet", "/wallet/:path"],
	component: Wallet,
});

pages.push({
	path: "/staking",
	component: Staking,
});

pages.push({
	path: "/swap",
	component: Swap,
});

pages.push({
	path: "/ranking",
	component: Ranking,
});

pages.push({
	path: ["/quiz", "/quiz/section/:sectionId", "/quiz/section/:sectionId/game"],
	component: Quiz,
	// permissoes: ["root", "desenvolvedor"],
});

pages.push({
	path: "/quizadmin",
	component: QuizAdmin,
	permissoes: ["root", "desenvolvedor"],
});

pages.push({
	path: "/quizend",
	component: QuizEnd,
});

pages.push({
	path: "/conversao_rapida",
	component: ConversaoRapida,
	permissoes: ["root", "desenvolvedor", "restrito"],
});

pages.push({
	path: "/users",
	component: Users,
	permissoes: ["root", "desenvolvedor", "restrito"],
});

pages.push({
	path: "/users/:id",
	component: PerfilUsuario,
	permissoes: ["root", "desenvolvedor", "restrito"],
});

pages.push({
	path: "/pre_venda",
	component: PreVenda,
});

pages.push({
	path: "/economia_ao_vivo",
	component: EconomiaAoVivo,
});

pages.push({
	path: ["/ivipay", "/copy_trade", "/advanced_trading"],
	component: Page_404,
	permissoes: ["root", "desenvolvedor", "restrito"],
});

pages.push({
	path: "/under_construction/:route",
	component: Page_404,
	permissoes: ["root", "desenvolvedor", "restrito"],
});

pages.push({
	path: "/teste_canvas",
	component: TestCanvas,
	permissoes: ["root", "desenvolvedor", "restrito"],
});

pages.push({
	path: "/",
	component: Home,
});

export default { pages };
