import { APIHelper } from "Helper";
import { calculateAchievement, homeData, sectionUnitNivel, sectionUnits, sections } from "./simulated_data";
import type { FetchOptions } from "Helper/APIHelper";
import { UserStatus, SectionInfo, SectionInfoUnits, RankTypes, AnswersBody } from "Types/Quiz";
import img_bronze from "Resources/insignias/bronze.png";
import img_silver from "Resources/insignias/silver.png";
import img_gold from "Resources/insignias/gold.png";
import img_platinum from "Resources/insignias/platinum.png";
import img_diamond from "Resources/insignias/diamond.png";
import img_master from "Resources/insignias/master.png";
import img_supreme from "Resources/insignias/supreme.png";
import img_legendary from "Resources/insignias/legendary.png";

export const insigniasId: RankTypes[] = ["bronze", "silver", "gold", "platinum", "diamond", "master", "supreme", "legendary"];

export const insigniasImage: {
	[p in RankTypes]: string;
} = {
	bronze: img_bronze,
	silver: img_silver,
	gold: img_gold,
	platinum: img_platinum,
	diamond: img_diamond,
	master: img_master,
	supreme: img_supreme,
	legendary: img_legendary,
};

export const fetchOptions = {
	user_status(): FetchOptions<UserStatus> {
		return {
			route: "education/user_status",
			method: "GET",
			cacheId: "quiz_user_status",
			// useSimulation: true, handleSimulation: () => homeData
		};
	},
	list_sections(): FetchOptions<SectionInfo[]> {
		return {
			route: "education/quiz/sections",
			method: "GET",
			cacheId: "quiz_sections",
			// useSimulation: true,
			// handleSimulation: () => sections,
		};
	},
	section(id: string): FetchOptions<SectionInfoUnits> {
		return {
			route: `education/quiz/section/${id}`,
			method: "GET",
			cacheId: `quiz_section_${id}`,
			// useSimulation: true,
			// handleSimulation: () => sectionUnits(id),
		};
	},
	getQuizzesInLevel(sectionId: string, unitId: string, levelId: string): FetchOptions {
		return {
			route: `/education/quiz/section/${sectionId}/${unitId}/${levelId}`,
			method: "GET",
			// useSimulation: true,
			// handleSimulation() {
			// 	return sectionUnitNivel();
			// },
		};
	},
	finishQuiz(sectionId: string, unitId: string, levelId: string, answers: AnswersBody): FetchOptions {
		return {
			route: `/education/quiz/section/${sectionId}/${unitId}/${levelId}/finish`,
			method: "POST",
			body: {
				answers,
			},
			// useSimulation: true,
			// handleSimulation: () => calculateAchievement(sectionId, unitId, levelId),
		};
	},
};

export const updateDataQuiz = (sectionId: string) => {
	return new Promise<[UserStatus, SectionInfo[], SectionInfoUnits]>((resolve, reject) => {
		Promise.all([
			APIHelper.fetch<UserStatus>({
				...fetchOptions.user_status(),
				forceRefresh: true,
			}),
			APIHelper.fetch<SectionInfo[]>({
				...fetchOptions.list_sections(),
				forceRefresh: true,
			}),
			APIHelper.fetch<SectionInfoUnits>({
				...fetchOptions.section(sectionId),
				forceRefresh: true,
			}),
		])
			.then((responses) => {
				resolve(responses);
			})
			.catch(reject);
	});
};
