import React, { useContext, useLayoutEffect, useEffect, useState } from "react";

import { matchPath } from "react-router";
import { useRouter } from "Utils/useHook";

import WalletList from "./WalletList";
import WalletView from "./WalletView";

import style from "./style.module.scss";
import { Wallet } from "Models";

const WalletPage: React.FC = () => {
	const [loading, setLoading] = useState(true);
	let { query, push } = useRouter(["/wallet/:path", "/wallet"]);

	useLayoutEffect(() => {
		setLoading(false);
	}, []);

	if (loading) {
		return <></>;
	}

	const selectWallet = ({ path }: Wallet) => {
		path = typeof path === "string" ? (path.split("/").pop() as string) : "";
		push(`/wallet/${path}`);
	};

	if ("path" in query) {
		return <WalletView path={query["path"] as string} />;
	}

	return (
		<div className={style["Wallet-Component"]}>
			<WalletList onClick={selectWallet} />
		</div>
	);
};

export default WalletPage;
