import React, { useEffect, useState, useCallback } from "react";

import { Typography, IconButton, Tabs, Tab } from "@mui/material";

import { SvgIcon, HorizontalButtonGroup, PageHeaderWrapper } from "Components";

import {
	mdiEye,
	mdiEyeOff,
	mdiCashFast,
	mdiPencil,
	mdiHandCoin,
	mdiDelete,
	mdiClipboardAlert,
	mdiSwapVerticalCircleOutline,
	mdiClipboardTextSearchOutline,
	mdiBankTransfer,
	mdiBitcoin,
} from "@mdi/js";

import { Usuario, Wallet } from "Models";

import { currencyFormat } from "Utils";

import style from "./style.module.scss";

import WalletHelper from "Helper/WalletHelper";
import { InteractionHelper, UserHelper } from "Helper";

import ViewMoviments from "./ViewMoviments";
import ViewCredit from "./ViewCredit";
import { useFitText } from "Utils/useHook";

import ViewStatistic from "./ViewStatistic";
import ProcessEditWallet from "./ProcessEditWallet";
import ProcessRemoveWallet from "./ProcessRemoveWallet";
import ViewTrade from "./ViewTrade";
import { Bep20Dialog, DepositDialog, WithdrawDialog } from "Dialog";

const WalletView: React.FC<{
	path: string;
}> = ({ path }) => {
	const [activeEye, setActiveEye] = useState<boolean>(true);
	const [user, setUser] = useState<Usuario | null>(null);
	const [wallet, setWallet] = useState<Wallet | null>(null);
	const [tapValue, setTapValue] = useState<number>(0);

	const { containerRef, textRef } = useFitText<HTMLDivElement>(10, 40, 0.5);

	const updateWallet = useCallback(() => {
		if (typeof path !== "string") {
			return;
		}

		if (wallet instanceof Wallet && wallet.path !== path) {
			setWallet(() => null);
			setActiveEye(true);
			setTapValue(0);
		}

		WalletHelper.getWallet(path)
			//.then((wallet) => WalletHelper.getWalletInfo(wallet))
			.then((wallet) => {
				setWallet(() => wallet);
			})
			.catch(() => {
				InteractionHelper.toast("Erro ao tentar buscar informações da carteira!", null, "error");
			});
	}, [path]);

	useEffect(() => {
		updateWallet();
	}, [path]);

	useEffect(() => {
		UserHelper.getUser()
			.then((user) => setUser(() => user))
			.catch(() => {
				setUser(null);
			});
	}, []);

	const handleChangeTap = (event: any, newValue: number) => {
		setTapValue(newValue);
	};

	const tapsPage = [
		{
			label: "Estatística",
			hidden: false,
			disabled: false,
			component: (
				<ViewStatistic
					user={user}
					wallet={wallet}
					activeEye={activeEye}
				/>
			),
		},
		{
			label: "Trade",
			hidden: wallet && wallet.type !== "BINANCE",
			disabled: false,
			component: <ViewTrade wallet={wallet} />,
		},
		{
			label: "Movimentações",
			hidden: false,
			disabled: false,
			component: <ViewMoviments wallet={wallet} />,
		},
		{
			label: "iVip Crédito",
			hidden: wallet && wallet.type !== "IVIPCOIN",
			disabled: false,
			component: <ViewCredit wallet={wallet} />,
		},
	];

	const eyeOff = () => {
		setActiveEye(!activeEye);
	};

	const statusList = Wallet.walletStatusList();

	const isUnderAnalysis = (wallet?.status ?? statusList["underAnalysis"]) === statusList["underAnalysis"];

	const isInvalidWallet = (wallet?.status ?? statusList["invalid"]) === statusList["invalid"];

	const isValidWallet = !isUnderAnalysis && !isInvalidWallet;

	const isLoading = !user || user instanceof Usuario !== true || !wallet || wallet instanceof Wallet !== true;

	return (
		<PageHeaderWrapper
			colorLight={"#e0e0e0"}
			colorDark={"#212121"}
			isLoading={isLoading}
			title={
				<span>
					<b>{wallet?.nome || ""}</b> - <i>{wallet?.getWalletCode() || ""}</i>
				</span>
			}
			header={
				<div className={style.balanceCard}>
					<div className={style.background}>{Wallet.walletIcon({ type: wallet?.type ?? "IVIPCOIN" })}</div>

					<div
						ref={containerRef}
						className={[style.price, wallet?.apiType === "testnet" ? style.testnet : ""].join(" ")}
						invalid-label={isUnderAnalysis ? "Em análise!" : isInvalidWallet ? "Carteira inválida!" : ""}
					>
						{isValidWallet && (
							<>
								<div className={style.topBar}>
									<Typography component="p">Balanço Total</Typography>
									<IconButton
										component="span"
										onClick={eyeOff}
									>
										<SvgIcon
											path={activeEye ? mdiEye : mdiEyeOff}
											children={undefined}
										/>
									</IconButton>
								</div>
								<Typography
									ref={textRef}
									variant="h5"
								>
									{activeEye
										? currencyFormat.secretCurrencyValue(wallet?.currencyType ?? "USD")
										: currencyFormat.convert(parseFloat(wallet?.totalValue?.toFixed(2) ?? "0"), wallet?.currencyType ?? "USD")}
								</Typography>
							</>
						)}
					</div>

					<HorizontalButtonGroup
						buttons={[
							{
								icon: mdiBitcoin,
								label: "Bep-20",
								hidden: wallet?.type !== "IVIPCOIN",
								onClick: () => {
									Bep20Dialog.show({
										wallet: wallet?.path ?? "",
									});
								},
								disabled: !isValidWallet,
							},
							{
								icon: mdiCashFast,
								label: "Depósito",
								hidden: ["IVIPBANK", "IVIPCOIN"].includes(wallet?.type ?? "") !== true,
								onClick: () =>
									DepositDialog.show({
										wallet: wallet?.path ?? "",
									}),
								disabled: !isValidWallet || ["IVIPCOIN"].includes(wallet?.type ?? "") !== true,
							},
							{
								icon: mdiHandCoin,
								label: "Saque",
								hidden: wallet?.type !== "IVIPCOIN",
								onClick: () =>
									WithdrawDialog.show({
										wallet: String(wallet?.path)
											.replace(/(\/+)$/gi, "")
											.split("/")
											.pop() as any,
									}),
								disabled: !isValidWallet,
							},
							{
								icon: mdiSwapVerticalCircleOutline,
								label: "Swap",
								hidden: ["BINANCE", "IVIPCOIN"].includes(wallet?.type ?? "") !== true,
								onClick: () =>
									WalletHelper.showSwap({
										walletId: String(wallet?.path)
											.replace(/(\/+)$/gi, "")
											.split("/")
											.pop(),
										walletType: wallet?.type ?? "IVIPCOIN",
									}),
								disabled: !isValidWallet,
							},
							{
								icon: mdiBankTransfer,
								label: "Transferência",
								disabled: true,
							},
							{
								icon: mdiClipboardTextSearchOutline,
								label: "Relatório",
								disabled: true,
							},
							{
								icon: mdiClipboardAlert,
								label: "Pedir vistoria",
								disabled: true,
								hidden: ["BINANCE", "IVIPCOIN"].includes(wallet?.type ?? "") !== true,
							},
							{
								icon: mdiPencil,
								label: "Editar",
								onClick: () => {
									if (!wallet) {
										return;
									}
									ProcessEditWallet(wallet)
										.then(() => updateWallet())
										.catch(() => {
											InteractionHelper.toast("Erro ao tentar editar a carteira!", null, "error");
										});
								},
								disabled: ["BINANCE", "IVIPCOIN", "IVIPBANK"].includes(wallet?.type ?? "") !== true,
							},
							{
								icon: mdiDelete,
								label: "Excluir",
								hidden: wallet?.type === "IVIPCOIN",
								onClick: () => {
									if (!wallet) {
										return;
									}
									ProcessRemoveWallet(wallet)
										.then(() => {
											window.routerHistory.push("/wallet");
										})
										.catch((message) => {
											InteractionHelper.toast(message, null, "error");
										});
								},
								disabled: ["IVIPCOIN"].includes(wallet?.type ?? ""),
							},
						]}
						// style={{ marginBottom: "25px" }}
					/>

					{isValidWallet && (
						<Tabs
							value={tapValue}
							onChange={handleChangeTap}
							scrollButtons="auto"
							variant="scrollable"
						>
							{tapsPage
								.filter(({ hidden }) => hidden !== true)
								.map(({ label, disabled }, i) => {
									return (
										<Tab
											key={i}
											label={label}
											disabled={disabled}
										/>
									);
								})}
						</Tabs>
					)}
				</div>
			}
			headerStyle={{ padding: 0, boxShadow: "none" }}
			content={!isLoading && isValidWallet && tapsPage.filter(({ hidden }) => hidden !== true)[tapValue].component}
		/>
	);
};

export default WalletView;
