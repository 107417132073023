import React from "react";
import InteractionHelper from "Helper/InteractionHelper";
import { ConfirmOrder } from "../Components";
import { APIHelper } from "Helper";
import { ListParConvertWallet } from "Types/Swap";
import { MultiStorager } from "Utils";
import { ViewPaymentDialog } from "Dialog";

const showTradeSwap = (
	props: {
		walletId: string;
		symbol: string[];
		quantity: number;
		amount: number;
		type: "BUY" | "SELL";
	},
	stage: number = 0,
	body: {
		[k: string]: any;
	} = {},
) => {
	return new Promise<void>((resolve, reject) => {
		switch (stage) {
			case 1: {
				InteractionHelper.loading();

				const from = (props.type === "BUY" ? props.symbol[1] : props.symbol[0]).toUpperCase();
				const to = (props.type === "SELL" ? props.symbol[1] : props.symbol[0]).toUpperCase();

				const amount = props.type === "BUY" ? props.amount : props.quantity;

				try {
					APIHelper.fetch(`swap/${props.walletId}`, {
						from,
						to,
						amount,
					})
						.then((response) => {
							if (!response || !response.history_id) {
								return Promise.reject();
							}
							body.response = response;
							return showTradeSwap(props, 2, body);
						})
						.then(resolve)
						.catch((e) => {
							const errorMsg =
								e && typeof e.message === "string" && e.message.trim() !== ""
									? e.message
									: "Algo deu errado, verifique sua conexão e tente novamente!";
							InteractionHelper.close();
							InteractionHelper.toast(errorMsg, null, "error");
							reject(errorMsg);
						});
				} catch (e) {
					reject(e);
				}
				return;
			}
			case 2: {
				InteractionHelper.loading();

				APIHelper.fetch<ListParConvertWallet>(`swap/${props.walletId}/list_currency`, {}, 5)
					.then((list) => {
						MultiStorager.DataStorager.set("swap_list_par_convert_wallet", list);
					})
					.catch((e) => {
						console.log(e);
					});

				ViewPaymentDialog.show({ wallet: props.walletId, paymentId: body.response.history_id });
				InteractionHelper.close();

				return resolve();
			}
			default: {
				InteractionHelper.confirm(
					<>
						<ConfirmOrder {...props} />
					</>,
					props.type === "BUY"
						? `Compra de ${props.symbol[0]} por ${props.symbol[1]}`
						: `Venda de ${props.symbol[0]} para ${props.symbol[1]}`,
				)
					.then(() => {
						return showTradeSwap(props, 1);
					})
					.then(resolve)
					.catch(reject);
			}
		}
	});
};

export default showTradeSwap;
