import React, { useState } from 'react'
import Switch from '@mui/material/Switch'
import style from './style.module.scss'

export default function Swap() {
  const [usePancakeSwap, setUsePancakeSwap] = useState(true)

  const handleChange = () => {
    setUsePancakeSwap(!usePancakeSwap)
  }

  const getSwapUrl = () => {
    if (usePancakeSwap) {
      return 'https://pancakeswap.finance/swap?outputCurrency=0xbdC87A65e0b6bfB631847B7dE815D2B07DEC8ee7'
    } else {
      return 'https://poocoin.app/tokens/0xbdC87A65e0b6bfB631847B7dE815D2B07DEC8ee7'
    }
  }

  return (
    <div className={style['root']}>
      <div className={style['container']}>
        <Switch
          className={style['switch']}
          defaultChecked
          onChange={handleChange}
          color="default"
          sx={{
            '& .MuiSwitch-thumb': {
              backgroundColor: '#eb6705',
            },
            '& .MuiSwitch-track': {
              backgroundColor: 'lightgray',
            },
            '&.Mui-checked': {
              backgroundColor: 'blue',
            },
          }}
        />
        <iframe
          frameBorder="0"
          src={getSwapUrl()}
          width="100%"
          height="1000px"
          title="Swap"
        ></iframe>
      </div>
    </div>
  )
}
