import React, { useCallback, useEffect, useState } from "react";
import { WithdrawDialogProps, identification_valid } from "./index";
import { PhaseProps, validBep20 } from "Dialog/utils";

import { Typography, TextField, FormControl, InputLabel, Select, MenuItem, Slider, Button, Box, CircularProgress } from "@mui/material";

import style from "../utils/style.module.scss";

import { maskAmount, timeFormat } from "Utils";

import { InputAmount, OtpInputWithValidation, SimulateAmountCosts } from "Components";
import { list_withdrawal_methods } from "Utils";
import { ListPaymentMethodsIten, PaymentMethodId } from "Types/ListPaymentMethods";
import { APIHelper, InteractionHelper } from "Helper";

const SimulateWithdraw: React.FC<PhaseProps<WithdrawDialogProps>> = ({ state, reject }) => {
	const [paymentType, setPaymentType] = useState(state.type);

	const [amount, setAmount] = useState<number>(typeof state.amount === "number" ? state.amount : 0);
	const [walletAddress, setWalletAddress] = useState<string>(typeof state.wallet_address === "string" ? state.wallet_address.trim() : "");

	const [identification, setIdentification] = useState<string>(state.identification.number);

	const [verificationCode, setVerificationCode] = useState<string>(state.verificationCode);

	const ResendConfirmationCode: React.FC = () => {
		const [countdown, setCountdown] = React.useState(0);
		const timeRef = React.useRef<NodeJS.Timeout>();

		const maxCountdown = 60;

		const progress = (countdown / maxCountdown) * 100;

		const handleClick = () => {
			clearInterval(timeRef.current);
			setCountdown(maxCountdown);
			APIHelper.fetch(`user/email_verification_code`).then(() => {
				timeRef.current = setInterval(() => {
					setCountdown((prev) => {
						if (prev <= 0) {
							clearInterval(timeRef.current);
							return 0;
						}
						return prev - 1;
					});
				}, 1000);
				InteractionHelper.toast("Código de verificação enviado com sucesso!", undefined, "success");
			});
		};

		return (
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end",
				}}
			>
				<Button
					role={undefined}
					variant="outlined"
					disabled={countdown > 0}
					onClick={handleClick}
					startIcon={
						countdown > 0 ? (
							<Box sx={{ position: "relative", display: "inline-flex" }}>
								<CircularProgress
									variant="determinate"
									value={progress}
									size={20}
								/>
								<Box
									sx={{
										top: 0,
										left: 0,
										bottom: 0,
										right: 0,
										position: "absolute",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<Typography
										variant="caption"
										component="div"
										color="text.secondary"
										sx={{ fontSize: "0.6rem" }}
									>
										{countdown}
									</Typography>
								</Box>
							</Box>
						) : undefined
					}
				>
					Enviar código
				</Button>
			</div>
		);
	};

	const getFindPayment = (): Pick<
		ListPaymentMethodsIten,
		| "min_allowed_amount"
		| "max_allowed_amount"
		| "payer_costs"
		| "types_installments"
		| "currency_id"
		| "settings"
		| "released"
		| "requires_identification"
	> => {
		const {
			min_allowed_amount = 0,
			max_allowed_amount = 0,
			payer_costs = [],
			types_installments,
			currency_id = "BRL",
			settings = [],
			released = true,
			requires_identification = false,
		} = state.list_withdrawal_methods?.find(({ id, payment_type_id }) => {
			const now = list_withdrawal_methods.find(({ value }) => value === paymentType);
			return now && id === now.value && now.payment_id === payment_type_id;
		}) ?? {};

		return { min_allowed_amount, max_allowed_amount, payer_costs, types_installments, currency_id, settings, released, requires_identification };
	};

	useEffect(() => {
		state.type = paymentType;
		const [type_identification] = Object.entries(identification_valid).find(([k, v]) => new RegExp(v, "g").test(identification)) ?? [];
		if (typeof type_identification === "string") {
			state.identification.type = type_identification as any;
		}
		state.identification.number = identification;
		state.verificationCode = verificationCode;
	}, [paymentType, identification, verificationCode]);

	const change = (value: string | number) => {
		value = parseFloat(value as string) ?? 0;
		value = isNaN(value) ? 0 : value;
		setAmount(value);
		state.amount = value;
	};

	const changeWalletAddress = (value: string | number) => {
		value = String(value ?? "").trim();
		setWalletAddress(value);
		state.wallet_address = value;
		console.log("state.wallet_address", state.wallet_address);
	};

	useEffect(() => {
		if (paymentType === "bep20") {
			const item = state.bep20Trust.find(({ validDate }) => {
				return new Date(validDate).getTime() < Date.now();
			});

			changeWalletAddress(item?.address ?? "");
		}
	}, [paymentType]);

	const { min_allowed_amount, max_allowed_amount, payer_costs, currency_id, settings, released, requires_identification } = getFindPayment();

	const inputError = max_allowed_amount < min_allowed_amount || amount > max_allowed_amount || amount < min_allowed_amount;

	//const walletError = validBep20(walletToken) !== true;

	const validationPatern = settings.find((s) => "address" in s)?.address?.validation;

	const walletError = validationPatern ? new RegExp(validationPatern, "g").test(walletAddress) !== true : false;

	const inputWalletToken: {
		[k: string]: {
			label: string;
			error: string;
			description: string;
		};
	} = {
		bep20: {
			label: "Endereço de carteira BEP-20",
			error: "Endereço de carteira BEP-20 inválido",
			description:
				"Certifique-se de inserir o endereço BEP-20 corretamente, pois, uma vez que a operação seja concluída, ela não poderá ser revertida. Após a aprovação, os tokens serão transferidos para a carteira fornecida. Se ocorrer algum erro durante o processo, não se preocupe; você poderá tentar novamente mais tarde sem maiores problemas.",
		},
		pix: {
			label: "Chave PIX",
			error: "Chave PIX inválido",
			description:
				"Ao inserir a chave PIX e definir o valor que deseja sacar, o processo de análise será realizado da mesma forma que ocorre para depósitos via WhatsApp. Após aprovação, o valor será transferidos para a chave informada.",
		},
	};

	const identificationError = Object.values(identification_valid).findIndex((v) => new RegExp(v, "g").test(identification)) === -1;

	return (
		<div className={style["dialogPayment"]}>
			{!state.hidenOptions && (
				<div className={style["paymentMethods"]}>
					{list_withdrawal_methods.map(({ icon, label, value, disabled }, i) => {
						if (disabled === true) {
							return;
						}

						const isActive = value === paymentType;

						return (
							<div
								className={isActive ? style["paymentMethods-active"] : ""}
								onClick={() => {
									setPaymentType(value as PaymentMethodId);
								}}
								key={i}
							>
								<div className={style["paymentMethods-logo"]}>{icon()}</div>
								<span className={style["paymentMethods-label"]}>{label}</span>
							</div>
						);
					})}
				</div>
			)}
			<InputAmount
				value={amount}
				currency={currency_id}
				onChange={(value) => change(value)}
				min={min_allowed_amount}
				max={max_allowed_amount}
			/>
			<br />

			<div className={style["payment-limit-view"]}>
				{`Você pode sacar uma quantia de até ${maskAmount(max_allowed_amount, 0, currency_id, "RTL")} e no valor mínimo de ${maskAmount(
					min_allowed_amount,
					0,
					currency_id,
					"RTL",
				)}.`}
			</div>

			{!released && <div className={style["payment-unavailable"]}>No momento esse método de saque está indisponível!</div>}

			{Array.isArray(payer_costs) && payer_costs.length > 0 && (
				<SimulateAmountCosts
					amount={amount}
					currencyId={currency_id}
					payment={{
						min_allowed_amount,
						max_allowed_amount,
					}}
					costs={payer_costs[0] as any}
					typeAmount="transfer"
				/>
			)}

			<br />
			<br />

			<Typography variant="h5">Informações para saque</Typography>

			<br />

			{paymentType === "bep20" ? (
				<FormControl fullWidth>
					<InputLabel id="select-bep-20-withdraw">{inputWalletToken[paymentType]?.label ?? ""}</InputLabel>
					<Select
						labelId="select-bep-20-withdraw"
						value={walletAddress}
						label={inputWalletToken[paymentType]?.label ?? ""}
						onChange={({ target: { value } }) => {
							if (value === "new-address") {
								InteractionHelper.route.replace(`/settings#SETTINGS-BEP20-TRUST`);
								return reject();
							}
							changeWalletAddress(value);
						}}
					>
						{state.bep20Trust.map(({ id, name, address, validDate }) => {
							validDate = new Date(validDate).getTime();

							return (
								<MenuItem
									key={id}
									value={address}
									disabled={validDate > Date.now()}
								>
									{name}{" "}
									<Typography
										variant="body2"
										sx={{ opacity: 0.6, marginLeft: 2 }}
										component="span"
									>
										<i>{address.slice(0, 6) + "..." + address.slice(-4)}</i>
									</Typography>
									{validDate > Date.now() && (
										<Typography
											variant="caption"
											sx={{ opacity: 0.6, marginLeft: 2 }}
											component="span"
										>
											<i>Válido em: {timeFormat(validDate - Date.now())}</i>
										</Typography>
									)}
								</MenuItem>
							);
						})}
						<MenuItem value={"new-address"}>
							<i>Adicionar novo endereço</i>
						</MenuItem>
					</Select>
				</FormControl>
			) : (
				<TextField
					label={inputWalletToken[paymentType]?.label ?? ""}
					variant="outlined"
					fullWidth
					value={walletAddress}
					onChange={({ target: { value } }) => changeWalletAddress(value)}
					error={walletError}
					helperText={walletError ? inputWalletToken[paymentType]?.error ?? "" : " "}
					autoComplete="off"
				/>
			)}

			{requires_identification && (
				<>
					<br />
					<TextField
						label="CPF/CNPJ"
						variant="outlined"
						fullWidth
						value={identification}
						onChange={({ target: { value } }) => setIdentification(value.replace(/\D/gi, ""))}
						error={identificationError}
						helperText={identificationError ? "Documento de identificação inválido!" : " "}
						autoComplete="off"
					/>
				</>
			)}

			<div className={style["description"]}>{inputWalletToken[paymentType]?.description ?? ""}</div>

			<br />
			<br />

			<Typography variant="h5">Código de verificação</Typography>

			<br />

			<OtpInputWithValidation
				numberOfDigits={8}
				value={verificationCode}
				onChange={setVerificationCode}
			/>

			<br />

			<ResendConfirmationCode />

			<br />

			<div className={style["description"]}>
				O código de verificação será enviado para o e-mail associado à sua conta no formato <b>0000-0000</b>. Caso não o encontre, verifique
				também sua caixa de <i>spam</i>.
			</div>

			<br />
			<br />
		</div>
	);
};

export default SimulateWithdraw;
