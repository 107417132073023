import React, { useEffect } from "react";
import { applyForwardRef, applyStyledProps, useCanvas, usePropsImperativeHandle } from "../Utils";
import { CANVASImageElement } from "../Elements";

export const Image = applyForwardRef<CANVASImageElement>((p, ref) => {
	const imageRef = React.useRef<HTMLImageElement>(null);

	const [props, setProps] = usePropsImperativeHandle<CANVASImageElement>(ref, p, {
		x: 0,
		y: 0,
		src: "",
	});

	const constext = useCanvas(
		({ canvas, isGroup }) => {
			const ctx = canvas?.getContext("2d");
			const { x, y, width, height, src, smoothingEnabled, smoothingQuality } = props;

			if (ctx && imageRef.current) {
				ctx.save();
				applyStyledProps(ctx, props, (ctx) => {
					if (!imageRef.current) {
						return;
					}
					if (typeof smoothingEnabled === "boolean") {
						ctx.imageSmoothingEnabled = smoothingEnabled;
					}
					if (typeof smoothingQuality === "string") {
						ctx.imageSmoothingQuality = smoothingQuality as any;
					}
					ctx.drawImage(imageRef.current, x, y, width ?? imageRef.current.width, height ?? imageRef.current.height);
				});
				ctx.restore();
			}
		},
		[imageRef?.current, props],
	);

	useEffect(() => {
		const time = setTimeout(() => {
			if (!imageRef.current || imageRef.current.src !== props.src) {
				const img = new window.Image();
				img.src = props.src;
				img.onload = () => {
					(imageRef as any).current = img;
					constext?.updateCanvas();
				};
			}
		}, 1000);

		return () => {
			clearTimeout(time);
		};
	}, [props.src, constext]);

	return null;
});
