import { UserHelper, APIHelper, ChatHelper, ChatHelperTypes } from "Helper";
import { Usuario } from "Models";
import { validateEmail, matchPath, MultiStorager } from "Utils";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { getAuth } from "ivipbase";

const phoneUtil = PhoneNumberUtil.getInstance();

const validatePassword = (value: string) => {
	try {
		if (value.length < 8) {
			return Promise.reject("A senha deve ser superior ou igual a 8 (oito) caracteres!");
		}

		if (!(/[0-9]/.test(value) && /[a-zA-Z]/.test(value))) {
			return Promise.reject("A senha deve ser alfanumérica (letras e números)!");
		}

		if (/^[0-9]/.test(value)) {
			return Promise.reject("Evite começar sua senha com números!");
		}

		if (!/[A-Z]/.test(value)) {
			return Promise.reject("A senha deve conter pelo menos uma letra maiúscula!");
		}

		if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value)) {
			return Promise.reject("A senha deve conter pelo menos um caractere especial (@, $, !, &, etc)!");
		}
		return Promise.resolve(true);
	} catch {
		return Promise.reject("Senha inválida!");
	}
};

const identificationData = (number: string) => {
	const numbersOnly = number.replace(/[^\d]/g, "");
	let type = null;
	let formattedNumber = null;

	if (numbersOnly.length === 11) {
		type = "CPF";
		formattedNumber = `${numbersOnly.slice(0, 3)}.${numbersOnly.slice(3, 6)}.${numbersOnly.slice(6, 9)}-${numbersOnly.slice(9)}`;
	} else if (numbersOnly.length === 14) {
		type = "CNPJ";
		formattedNumber = `${numbersOnly.slice(0, 2)}.${numbersOnly.slice(2, 5)}.${numbersOnly.slice(5, 8)}/${numbersOnly.slice(
			8,
			12,
		)}-${numbersOnly.slice(12)}`;
	}

	return { type, number: formattedNumber };
};

const delay = (): Promise<void> =>
	new Promise((resolve) => {
		setTimeout(() => {
			resolve();
		}, Math.round(Math.random() * 20000) + 25000);
	});

const login_bot = new ChatHelper("login_bot");

login_bot.push("start", async function (this: ChatHelperTypes.MessageBotInstance): Promise<ChatHelperTypes.MessageBotProcessFun> {
	if (this.props.isStarted) {
		return await this.dispatch("options");
	}

	try {
		const { params } = matchPath("/:referenceBy", window.location.href);
		if (/^[A-Z0-9]{16,18}$/gi.test(String(params.referenceBy).trim())) {
			MultiStorager.DataStorager.set("referenceBy", params.referenceBy.trim());
		}
	} catch {}

	const { allowed_access } = (await APIHelper.fetch("use/accessQueue").catch(() =>
		Promise.resolve({
			assets: 100000,
			maximum_assets: 0,
			allowed_access: false,
		}),
	)) as { allowed_access: boolean };

	if (allowed_access !== true) {
		this.props.delay_mss = "A verificar disponibilidade...";

		return {
			type: "text",
			message: "No momento, estamos com alta demanda. Por favor, aguarde um momento e em breve será atendido",
			next: "delay",
		};
	}

	console.log(window.location.href);
	const { exact, params } = matchPath("/RedefinePassword/:resetCode", window.location.href);
	console.log(exact, params);

	if (exact) {
		return {
			type: "text",
			message: "Vamos redefinir sua senha?",
			next: "change_password",
		};
	}

	this.props.isStarted = true;

	if (MultiStorager.LocalStorager.hasKey("last_access")) {
		this.values.email = MultiStorager.LocalStorager.get<string>("last_access");

		return {
			type: "option",
			message: `Pretende continuar como *${this.values.email}*?`,
			options: [
				{
					label: "Sim",
					next: "sign_in",
					processMoment: 2,
				},
				{
					label: "Não",
					next: "options",
				},
			],
		};
	}

	return {
		type: "text",
		message: ["Olá, bem vindo ao Painel iVip Coin 🤩", 'Toda vez que quiser sair de uma etapa do diálogo, digite "Sair" ou "Cancelar"'],
		next: "options",
	};
});

// login_bot.push("onChange", function(this: ChatHelperTypes.MessageBotInstance, msg: string = ""):void | ChatHelperTypes.MessageBotProcessFun{
//     if(["sair", "cancelar", "exit", "cancel"].includes(String(msg).trim().toLowerCase())){
//         return this.dispatch("options");
//     }
// });

login_bot.push("options", function (this: ChatHelperTypes.MessageBotInstance): ChatHelperTypes.MessageBotProcessFun {
	if (MultiStorager.LocalStorager.hasKey("last_access")) {
		MultiStorager.LocalStorager.delete("last_access");
	}
	return {
		type: "option",
		message: "Em quê posso ser útil?",
		options: [
			{
				label: "Entrar",
				next: "sign_in",
			},
			{
				label: "Cadastrar",
				next: "sign_out",
			},
			{
				label: "Restaurar senha",
				next: "reset_password",
			},
			{
				label: "Suporte",
				next: "support",
			},
		],
	};
});

const sign_in = login_bot.push("sign_in");

//sign_in::0
sign_in.pushProcess(function (): ChatHelperTypes.MessageBotProcessFun {
	return {
		type: "email",
		message: "Digite seu e-mail:",
	};
});

//sign_in::1
sign_in.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value: string = ""): Promise<ChatHelperTypes.MessageBotFlowProcessFun> {
	value = String(value).trim();

	if (validateEmail(value)) {
		try {
			this.values.email = String(value).toLowerCase();

			const emailAvailable = await UserHelper.emailAvailable(this.values.email);

			if (typeof emailAvailable !== "boolean") {
				return await this.dispatch("internal_error");
			} else if (!emailAvailable) {
				return await this.dispatch("options", 0, ["E-mail inexistente 😰"]);
			}

			const emailReadyForLogin = await UserHelper.emailReadyForLogin(this.values.email);

			switch (emailReadyForLogin) {
				case 0:
					return await this.dispatch("options", 0, [
						"Pedimos desculpas, mas a conta associada a esse e-mail foi criada recentemente",
						"Estamos ainda configurando o seu perfil, e dentro de um minuto, ou menos, a conta já estará pronta para acesso 😊",
					]);

				case 2:
					return await this.dispatch("options", 0, ["Essa conta está temporariamente suspensa, pedimos desculpas pela inconveniência 😰"]);
			}

			return await this.next();
		} catch {}
	}

	return await this.back(["E-mail inválido, tente novamente!"]);
});

//sign_in::2
sign_in.pushProcess(function (): ChatHelperTypes.MessageBotProcessFun {
	return {
		type: "password",
		message: "Digite sua senha:",
	};
});

//sign_in::3
sign_in.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value: string = ""): Promise<ChatHelperTypes.MessageBotProcessFun> {
	let error = "";

	const isLogin = await UserHelper.login(this.values.email, value)
		.then(() => {
			MultiStorager.LocalStorager.set("last_access", this.values.email);
			return Promise.resolve(true);
		})
		.catch((e) => {
			error = "Senha inválida 😕, tente novamente!";
			return Promise.resolve(false);
		});

	if (!isLogin) {
		return await this.dispatch("options", 0, [error]);
	}

	return {
		type: "text",
		message: "Entrando",
	};
});

const sign_out = login_bot.push("sign_out");

//sign_out::0
sign_out.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance): Promise<ChatHelperTypes.MessageBotProcessFun> {
	return this.next([
		"Olá olá olá 🤩",
		"Estamos muito contentes em saber que está interessado em fazer parte da iVip",
		"Nesse processo de cadastro, preste bastante atenção ao preencher os dados 🔍",
		"Enquanto a questão de segurança e proteção de dados, fique tranquilo. \nPossuímos a certificação SSL 🔐 e seguimos com os termos da Lei Geral de Proteção de Dados (LGPD) 📃",
	]);
});

//sign_out::1
sign_out.pushProcess(function (this: ChatHelperTypes.MessageBotInstance): ChatHelperTypes.MessageBotProcessFun {
	return {
		type: "text",
		message:
			typeof this.props.isInvalidName === "boolean" && this.props.isInvalidName
				? "Digite corretamente o seu nome completo (primeiro nome e sobre nome)"
				: "Para começar, me diga o seu nome completo",
	};
});

//sign_out::2
sign_out.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	console.log(value);
	this.values.nome = String(value)
		.trim()
		.replace(/[\s\n]+/gi, " ")
		.split(" ")
		.map((n) => {
			return n.charAt(0).toUpperCase() + n.slice(1).toLowerCase();
		})
		.join(" ");

	if (this.values.nome.length < 4 || this.values.nome.split(" ").length <= 1) {
		return await this.back(["Não entendi 😕"]);
	}

	return this.next([`Prazer em te conhecer ${this.values.nome.split(" ").shift().toUpperCase()} 😁`]);
});

//sign_out::3
sign_out.pushProcess(function (this: ChatHelperTypes.MessageBotInstance): ChatHelperTypes.MessageBotProcessFun {
	return {
		type: "email",
		message: "Digite o seu e-mail:",
	};
});

//sign_out::4
sign_out.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	value = String(value).trim();

	if (validateEmail(value)) {
		this.values.email = String(value).toLowerCase();

		const emailAvailable = await UserHelper.emailAvailable(this.values.email);

		if (typeof emailAvailable !== "boolean") {
			return await this.dispatch("internal_error");
		} else if (emailAvailable) {
			return await this.back(["Que pena, infelizmente esse e-mail já existe, tente novamente!"]);
		}

		this.props.verification_code_initial = true;

		return await this.next();
	}

	return await this.back(["E-mail inválido, tente novamente!"]);
});

//sign_out::5
sign_out.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	const send_verification_code = await APIHelper.fetch(
		`user/email_verification_code`,
		{ email: this.values.email },
		this.props.verification_code_error,
	).catch(() => Promise.resolve(false));

	this.props.verification_code_error = false;

	if (!send_verification_code) {
		this.props.delay_next = "sign_out";
		this.props.delay_next_process_moment = 5;

		return {
			type: "text",
			message: ["Algo deu errado", "Vou tentar novamente e verificar o problema"],
			next: "delay",
		};
	}

	let message = ["Reenviamos o código novamente", "Digite o código de verificação enviado por e-mail"];

	if (this.props.verification_code_initial === true) {
		this.props.verification_code_initial = false;

		message = [
			"Enviamos um código de verificação para o seu e-mail",
			"Por favor, certifique-se de ter recebido o código e, caso não o encontre na caixa de entrada, verifique a pasta de spam",
			"Digite o código de verificação",
		];
	}

	return {
		type: "text",
		message: message,
	};
});

//sign_out::6
sign_out.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	value = String(value).trim().replace(/\D/gi, "");
	let erro_mss = null;

	if (typeof value === "string" && /^(\d{8})$/gi.test(value)) {
		const codeAvailable = await APIHelper.fetch(`user/email_verification_code/${value}`, {
			email: this.values.email,
		}).catch(({ message }) => {
			erro_mss = message;
			return Promise.resolve(false);
		});

		if (codeAvailable === true) {
			return await this.next();
		}
	}

	erro_mss = typeof erro_mss === "string" && (erro_mss as String).trim() !== "" ? erro_mss : "O código de verificação inserido está incorreto!";

	this.props.verification_code_error = true;

	return {
		type: "option",
		message: [erro_mss, "O que pretende fazer agora?"],
		options: [
			{
				label: "Reenviar código",
				next: "sign_out",
				processMoment: 5,
			},
			{
				label: "Editar e-mail",
				next: "sign_out",
				processMoment: 3,
			},
		],
	};
});

//sign_out::7
sign_out.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	this.values.atSign = this.values.email.split("@").shift().replace(/\s/gi, "_");

	let atSignExists = true;

	if (this.values.atSign.length > 5) {
		atSignExists = await UserHelper.atSignAvailable(this.values.atSign);
	}

	if (typeof atSignExists !== "boolean") {
		return await this.dispatch("internal_error");
	} else if (atSignExists) {
		return await this.next(["Na plataforma da iVip, possui um sistema de login e identificação dos usuários, o tão famoso arroba"]);
	}

	return {
		type: "option",
		message: [
			"Na plataforma da iVip, possui um sistema de login e identificação dos usuários, o tão famoso arroba",
			`Sugerimos que utilize o mesmo do seu e-mail: ${this.values.atSign}`,
			"Deseja manter?",
		],
		options: [
			{
				label: "Sim",
				next: "sign_out",
				processMoment: 10,
			},
			{
				label: "Não",
				next: "sign_out",
				processMoment: 8,
			},
		],
	};
});

//sign_out::8
sign_out.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	return {
		type: "text",
		message: "Digite o seu arroba",
	};
});

//sign_out::9
sign_out.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	this.values.atSign = String(value).trim();

	if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(this.values.atSign) || /\s/.test(this.values.atSign)) {
		return await this.back([
			"Esse arroba não é válido 😟",
			"Lembre-se que o seu arroba não pode possuir espaços e nem caracteres especiais (@, $, !, &, etc)!",
		]);
	}

	const atSignExists = await UserHelper.atSignAvailable(this.values.atSign).catch(() => null);

	if (typeof atSignExists !== "boolean") {
		return await this.dispatch("internal_error");
	} else if (atSignExists) {
		return await this.back(["Que pena, infelizmente esse arroba já existe, tente novamente!"]);
	}

	return await this.next();
});

//sign_out::10
sign_out.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	return {
		type: "tel",
		message: "Digite o seu telefone de contato",
	};
});

//sign_out::11
sign_out.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	this.values.telefone = String(value).replace(/\D/gi, "");

	try {
		const phoneNumber = phoneUtil.parse(`+${this.values.telefone}`.trim());

		if (phoneUtil.isValidNumber(phoneNumber)) {
			this.values.telefone = phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
			return await this.next();
		}
	} catch {}

	return await this.back(["Número de telefone incorreto, tente novamente!"]);
});

//sign_out::12
sign_out.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	return {
		type: "password",
		message: [
			"Agora define a sua senha",
			"Lembre-se dos requisitos necessários ao criar uma senha:\n\n● A senha deve ser superior ou igual a 8 (oito) caracteres!\n● A senha deve ser alfanumérica (letras e números)!\n● A senha deve conter pelo menos uma letra maiúscula!\n● A senha deve conter pelo menos um caractere especial (@, $, !, &, etc)!",
		],
	};
});

//sign_out::13
sign_out.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	let error = "Senha inválida!";

	const valid = await validatePassword(value as string).catch((msg) => {
		error = msg;
	});

	if (valid !== true) {
		return await this.back([error]);
	}

	this.props.password = value;

	return await this.next();
});

//sign_out::14
sign_out.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	return {
		type: "password",
		message: "Digite novamente a senha que definiu",
	};
});

//sign_out::15
sign_out.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	if (value !== this.props.password) {
		return {
			type: "text",
			message: ["As senhas que informou não coincidem!", "Vamos tentar novamente?"],
			next: "sign_out",
			processMoment: 12,
		};
	}

	this.props.password = value;

	return {
		type: "text",
		message: "Agora, aguarde um instante que iremos criar sua conta de acesso 😁",
		next: "sign_out",
		processMoment: 16,
	};
});

//sign_out::16
sign_out.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	let user: Usuario | null = new Usuario().parse(null, this.values) as Usuario;
	let error = "Ocorreu um Erro ao tentar criar a conta de usuário 😟";

	user = await UserHelper.criarUsuario(user, this.props.password).catch((e) => {
		error = e.message;
		return null;
	});

	if (!user || !(user instanceof Usuario)) {
		return await this.dispatch("sign_out", 1, [error, "Vamos tentar novamente?"]);
	}

	return await this.dispatch("options", 0, [
		"Conta criada com sucesso 🥳",
		"Agradecemos muito pela sua paciência no processo de cadastro e por se associar ao iVip 🤩",
		"A etapa de cadastramento foi finalizado 😌",
	]);
});

const reset_password = login_bot.push("reset_password");

//reset_password::0
reset_password.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	return {
		type: "text",
		message: "Digite o seu e-mail:",
	};
});

//reset_password::1
reset_password.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	value = String(value).trim();

	if (validateEmail(value)) {
		this.values.email = value;

		const emailAvailable = await UserHelper.emailAvailable(this.values.email);

		if (typeof emailAvailable !== "boolean") {
			return await this.dispatch("internal_error");
		} else if (emailAvailable) {
			return await this.next();
		}

		return await this.back([
			"Desculpe, não foi possível localizar uma conta associada ao endereço de e-mail fornecido",
			"Favor, verifique se o endereço está correto e tente novamente",
		]);
	}

	return await this.back(["E-mail inválido, tente novamente!"]);
});

//reset_password::2
reset_password.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	try {
		await getAuth().sendPasswordResetEmail(this.values.email);
	} catch {
		return await this.dispatch("internal_error");
	}

	return this.dispatch("options", 0, [
		"Acabamos de enviar para o seu endereço de e-mail o link para redefinir a sua senha",
		"Por favor, verifique a sua caixa de entrada e siga as instruções para recuperar o acesso à sua conta",
		"Qualquer dúvida, estamos à disposição para ajudá-lo. Obrigado!",
	]);
});

const change_password = login_bot.push("change_password");

//change_password::0
change_password.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	return {
		type: "password",
		message: "Digite sua nova senha",
	};
});

//change_password::1
change_password.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	let error = "Senha inválida!";

	const valid = await validatePassword(value as string).catch((msg) => {
		error = msg;
	});

	if (valid !== true) {
		return await this.back([error]);
	}

	this.props.password = value;

	return await this.next();
});

//change_password::2
change_password.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	return {
		type: "password",
		message: "Digite novamente a senha que definiu",
	};
});

//change_password::3
change_password.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	if (value !== this.props.password) {
		return await this.back(["As senhas que informou não coincidem!", "Vamos tentar novamente?"]);
	}

	this.props.password = value;

	return await this.next(["Agora, aguarde um instante que iremos redefinir sua senha de acesso 😁"]);
});

//change_password::4
change_password.pushProcess(async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	try {
		const { params } = matchPath("/RedefinePassword/:resetCode", window.location.href);
		await getAuth().confirmPasswordReset(params.resetCode, this.props.password);
	} catch {
		return this.dispatch("options", 0, [
			"Desculpe, houve um erro interno em que no momento não será possível resolver 😰",
			"Uma alternativa seria solicitar uma nova redefinição de senha",
		]);
	}

	window.routerHistory?.push("/");

	return this.dispatch("options", 0, ["Sua senha foi redefinida com sucesso 😌"]);
});

login_bot.push("support", async function (this: ChatHelperTypes.MessageBotInstance, value): Promise<ChatHelperTypes.MessageBotProcessFun> {
	const encodedMessage = window.encodeURIComponent(
		`Olá, estou enfrentando alguns problemas no aplicativo e decidi entrar em contato com o suporte para solicitar ajuda. Será que você poderia me auxiliar? Agradeço desde já`,
	);
	const url = `https://api.whatsapp.com/send?phone=551147574992&text=${encodedMessage}`;
	window.open(url, "_blank");

	return this.dispatch("options", 0, [
		"Estou confiante de que o suporte poderá me ajudar e resolver o meu problema",
		"Agradeço antecipadamente pela atenção e suporte prestados",
	]);
});

login_bot.push("internal_error", function (): ChatHelperTypes.MessageBotProcessFun {
	return {
		type: "text",
		message: ["Desculpe, houve um erro interno em que no momento não será possível resolver 😰", "Tente novamente mais tarde..."],
		next: "options",
	};
});

login_bot.push("delay", async function (this: ChatHelperTypes.MessageBotInstance): Promise<ChatHelperTypes.MessageBotProcessFun> {
	await delay();

	return {
		type: "text",
		message: typeof this.props.delay_mss === "string" && this.props.delay_mss.trim() !== "" ? this.props.delay_mss : "Aguarde um instante...",
		next: typeof this.props.delay_next === "string" && this.props.delay_next.trim() !== "" ? this.props.delay_next : "start",
		processMoment: typeof this.props.delay_next_process_moment === "number" ? Math.max(0, this.props.delay_next_process_moment) : 0,
	};
});

export default login_bot;
