import React, { useEffect, useLayoutEffect, useState } from "react";
import Insignia from "../Insignia";

import style from "./style.module.scss";
import { Chart, SvgIcon } from "Components";
import * as libIcon from "@mdi/js";
import { mdiArchiveCheck, mdiArchiveStar, mdiChevronLeft, mdiChevronRight, mdiCircle } from "@mdi/js";
import { Tooltip, Typography } from "@mui/material";
import ProgressPrizes from "../ProgressPrizes";
import { UserStatus } from "Types/Quiz";
import { APIHelper, InteractionHelper, QuizHelper } from "Helper";

const ProfileInfo: React.FC = () => {
	const {
		error,
		response: data,
		status,
	} = APIHelper.useFetch<UserStatus>({
		...QuizHelper.fetchOptions.user_status(),
		immediate: true,
	});

	const loading = status !== "success";

	useEffect(() => {
		const time = setTimeout(() => {
			if (status === "error" && error) {
				InteractionHelper.toast(error.message, null, "error");
			}
		}, 2000);
		return () => clearTimeout(time);
	}, [error, status]);

	// const [loading, setLoading] = useState<boolean>(true);
	// const [data, setData] = useState<UserStatus | null>(null);

	// useLayoutEffect(() => {
	// 	const time = setTimeout(() => {
	// 		setData(homeData);
	// 		setLoading(false);
	// 	}, 2000);

	// 	return () => clearTimeout(time);
	// }, []);

	const progressDateValue = data?.daily.value ?? 0;
	const progressDate = (data?.daily.awards ?? []).map(({ icon, conqueredIcon, ...props }) => {
		return {
			...props,
			icon: icon && icon in libIcon ? (libIcon as { [p: string]: string })[icon] : mdiArchiveStar,
			conqueredIcon: conqueredIcon && conqueredIcon in libIcon ? (libIcon as { [p: string]: string })[conqueredIcon] : mdiArchiveCheck,
		};
	});

	const radarValue = (data?.statistic.radarProfile ?? []).map(({ value }) => {
		return value;
	});

	return (
		<div className={style["main"]}>
			{typeof data === "object" && data !== null && (
				<>
					<div className={style["insignia"]}>
						<Insignia
							type={data.rank.type as any}
							progress={(data.statistic.xp - data.rank.minXP) / data.rank.maxXP}
							stars={data.rank.stars}
						/>
					</div>
					<div className={style["general_info"]}>
						<div className={style.bar_basic_information}>
							<div>
								<Tooltip title="Inventário">
									<div
										onClick={() => {
											InteractionHelper.toast("Indisponível no momento!", undefined, "warning");
										}}
									>
										<SvgIcon path={libIcon.mdiStore} />
									</div>
								</Tooltip>
							</div>
							<div>
								<Tooltip title="Passe">
									<div
										onClick={() => {
											InteractionHelper.toast("Indisponível no momento!", undefined, "warning");
										}}
									>
										<SvgIcon path={libIcon.mdiTicket} />
										<Typography
											variant="h5"
											component="span"
										>
											0
										</Typography>
									</div>
								</Tooltip>
								<Tooltip title="Moedas">
									<div
										onClick={() => {
											InteractionHelper.toast("Indisponível no momento!", undefined, "warning");
										}}
									>
										<SvgIcon path={libIcon.mdiPiggyBank} />
										<Typography
											variant="h5"
											component="span"
										>
											0
										</Typography>
									</div>
								</Tooltip>
								<Tooltip title="Diamantes">
									<div
										onClick={() => {
											InteractionHelper.toast("Indisponível no momento!", undefined, "warning");
										}}
									>
										<SvgIcon path={libIcon.mdiDiamond} />
										<Typography
											variant="h5"
											component="span"
										>
											0
										</Typography>
									</div>
								</Tooltip>
							</div>
						</div>
						<div className={style["details"]}>
							<div className={style["informations"]}>
								<div className={style["conquest"]}>
									<div className={style["boxTitle"]}>Conquistas</div>
									<div className={style.content}>
										<div className={[style.btnPrev, style.disabled].join(" ")}>
											<SvgIcon path={mdiChevronLeft} />
										</div>
										<div className={style["conquestList"]}>{/* <div className={style["conquestItem"]}></div> */}</div>
										<div className={[style.btnNext, style.disabled].join(" ")}>
											<SvgIcon path={mdiChevronRight} />
										</div>
									</div>
								</div>
								<ProgressPrizes
									awards={progressDate}
									value={progressDateValue}
								/>
							</div>
							<div className={style["progressProfile"]}>
								<div className={style["boxTitle"]}>Avanço do perfil</div>
								<Chart
									chartType="RadarChart"
									data={{
										labels: data.statistic.radarProfile.map(({ label, icon }) => {
											return {
												label,
												icon: icon && icon in libIcon ? (libIcon as { [p: string]: string })[icon] : mdiCircle,
											};
										}),
										data: [radarValue],
									}}
									options={{
										color: ["#ffc107"],
										min: Math.floor(Math.min(...radarValue) * 0.8),
										max: Math.floor(Math.max(...radarValue) * 1.2),
									}}
									width={200}
									height={200}
								/>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default ProfileInfo;
