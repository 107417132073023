/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useCallback, useEffect, useLayoutEffect } from "react";

import style from "./index.module.scss";

import { MultiStorager } from "Utils";

import { CircularProgress, Box } from "@mui/material";

import { APIHelper } from "Helper";
import { Usuario } from "Models";

import { formatDate, isDate, assignObj } from "./utils";

import { Markdown } from "Components";
import { getDatabase } from "ivipbase";

const addChatRoom = ({ chatId, name, type, picture, last_message, preview_message, count_message, chat_started }) => {
	let chatRooms = MultiStorager.DataStorager.get("chatRooms");
	chatRooms = Array.isArray(chatRooms) ? chatRooms : [];

	const index = chatRooms.findIndex(({ chatId: _chatId }) => _chatId === chatId);

	if (index >= 0) {
		chatRooms[index] = Object.assign(chatRooms[index], {
			chatId,
			name,
			type,
			picture,
			last_message,
			preview_message,
			count_message,
			chat_started,
		});
	} else {
		chatRooms.push({
			name,
			chatId,
			type,
			picture,
			last_message,
			preview_message,
			count_message,
			chat_started,
		});
	}

	MultiStorager.DataStorager.set("chatRooms", chatRooms);
};

export default ({ onSelect, onUpdateSelect, idRoom, user, botId }) => {
	const [loading, setLoading] = useState(false);
	const [list, setList] = useState([]);

	const gotTo = useCallback(
		(info) => {
			if (typeof onSelect === "function") {
				onSelect(info);
			}
		},
		[onSelect],
	);

	const updateSelect = useCallback(
		(info) => {
			if (typeof onUpdateSelect === "function") {
				onUpdateSelect(info);
			}
		},
		[onUpdateSelect],
	);

	const updateList = useCallback(() => {
		APIHelper.fetch("/chat")
			.then((list) => {
				list.forEach((room) => {
					addChatRoom(room);
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		let listener;

		if (user instanceof Usuario) {
			const userId = user.path.split("/").pop();
			listener = getDatabase()
				.ref("NotifyMembersChat")
				.child(userId)
				.on("mutated", (snap) => {
					let [root, userId, chatId, lastMessageRoot, ...propertyTrail] = snap.ref.path.split("/");
					let newValue = snap.val(),
						data = {
							lastMessage: {},
							unreadMessages: 0,
						};

					if (typeof chatId === "string" && chatId.trim() !== "" && !lastMessageRoot) {
						data = newValue;
					}

					if (lastMessageRoot === "lastMessage") {
						let targetObject = propertyTrail.slice(0, -1).reduce((target, prop) => {
							target[prop] = !target[prop] ? {} : target[prop];
							return target[prop];
						}, data["lastMessage"]);
						const targetProperty = propertyTrail.slice(-1)[0];
						targetObject[targetProperty] = newValue;
						delete data["unreadMessages"];
					}

					if (lastMessageRoot === "unreadMessages") {
						data["unreadMessages"] = newValue;
						delete data["lastMessage"];
					}

					let chatRooms = MultiStorager.DataStorager.get("chatRooms");
					chatRooms = Array.isArray(chatRooms) ? chatRooms : [];

					const index = chatRooms.findIndex(({ chatId: _chatId }) => _chatId === chatId);

					if (index < 0) {
						return updateList();
					}

					if (typeof data["lastMessage"] === "object") {
						chatRooms[index].preview_message = assignObj(chatRooms[index].preview_message || {}, data["lastMessage"]);
						chatRooms[index].last_message = chatRooms[index].preview_message.timestamp;
					}

					if (typeof data["unreadMessages"] === "number" && data["unreadMessages"] >= 0) {
						chatRooms[index].count_message = data["unreadMessages"];
					}

					MultiStorager.DataStorager.set("chatRooms", chatRooms);
				});
		}

		return () => {
			listener?.stop();
		};
	}, [user, updateList]);

	useLayoutEffect(() => {
		setList((prev) => {
			const chatRooms = MultiStorager.DataStorager.get("chatRooms");
			return Array.isArray(chatRooms) ? chatRooms : prev;
		});
		setLoading(true);

		let time;

		const event = MultiStorager.DataStorager.addListener("chatRooms", (list) => {
			clearTimeout(time);
			time = setTimeout(() => {
				setList((prev) => {
					list.sort((a, b) => b.last_message - a.last_message);
					return JSON.parse(JSON.stringify(Array.isArray(list) ? list : prev));
				});
			}, 500);
		});

		updateList();

		return () => {
			clearTimeout(time);
			event.stop();
		};
	}, []);

	useEffect(() => {
		const info = list.find(({ chatId }) => chatId === idRoom);
		if (!info) {
			return;
		}
		updateSelect(info);
	}, [list, idRoom, updateSelect]);

	return (
		<>
			{loading && (
				<Box
					className={style["loading"]}
					sx={{ display: "flex" }}
				>
					<CircularProgress />
				</Box>
			)}
			{!loading && list.length <= 0 && (
				<div className={style["info"]}>Inicie uma nova conversa clicando em "Nova conversa" e pesquisando por um usuário ou grupo</div>
			)}
			{list
				.filter(({ chat_started }) => chat_started === true)
				.map((info, index) => {
					const { name, picture, chatId, type, last_message, preview_message, count_message } = info;

					const isActive = chatId === idRoom;
					const mainClasses = [style["item"], style[type]];
					let previewMessageText = preview_message && preview_message?.context ? preview_message.context?.message : null;

					previewMessageText = typeof previewMessageText === "string" && previewMessageText.trim() !== "" ? previewMessageText : null;

					if (isActive) {
						mainClasses.push(style["active"]);
					}

					return (
						<div
							className={mainClasses.join(" ")}
							key={index}
							onClick={() => {
								gotTo(info);
							}}
						>
							<div
								className={style["picture"]}
								style={{ backgroundImage: picture && picture.trim() !== "" ? `url(${picture})` : "" }}
							></div>
							<div className={style["preview"]}>
								<div className={style["title"]}>
									<div
										className={style["label"]}
										title={name}
									>
										{name}
									</div>
									{isDate(last_message) && (
										<div
											className={style["time"]}
											title={new Date(last_message).toLocaleString()}
										>
											{formatDate(new Date(last_message))}
										</div>
									)}
								</div>
								{previewMessageText && (
									<div className={style["label"]}>
										<div>
											<Markdown
												children={String(previewMessageText)}
												limitChars={100}
												nextLimitChars={0}
												simplify
											/>
										</div>
										{count_message > 0 && <div className={style["unread-messages"]}>{count_message}</div>}
									</div>
								)}
							</div>
						</div>
					);
				})}
		</>
	);
};
