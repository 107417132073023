import React from "react";
import { mdiChevronLeft, mdiChevronRight, mdiDotsVertical } from "@mdi/js";
import { SvgIcon } from "Components";
import { IconButton, CircularProgress } from "@mui/material";

import { useRouter } from "Utils/useHook";

import style from "./index.module.scss";

const NavigationBar: React.FC<
	Partial<{
		isLoading: boolean;
		title: string | React.ReactNode;
		autoActive: boolean;
	}>
> = ({ isLoading = false, title = "", autoActive = true }) => {
	const { length, currentIndex, history } = useRouter();

	return (
		<div className={[style.navigationBar, autoActive ? "" : style.active].join(" ")}>
			<IconButton
				disableRipple
				sx={{
					padding: "0px",
				}}
				disabled={length === 1 || currentIndex === 0}
				onClick={() => history.goBack()}
			>
				<SvgIcon
					viewBox="0 0 24 24"
					path={mdiChevronLeft}
					fontSize={"large"}
				/>
			</IconButton>

			<IconButton
				disableRipple
				sx={{
					padding: "0px",
				}}
				disabled={currentIndex >= length - 1}
				onClick={() => history.goForward()}
			>
				<SvgIcon
					viewBox="0 0 24 24"
					path={mdiChevronRight}
					fontSize={"large"}
				/>
			</IconButton>

			{isLoading && (
				<CircularProgress
					color="inherit"
					size={25}
				/>
			)}

			<div className={style.title}>{isLoading ? "Carregando..." : title}</div>

			<IconButton
				disableRipple
				sx={{
					padding: "0px",
				}}
				disabled
			>
				<SvgIcon
					viewBox="0 0 24 24"
					path={mdiDotsVertical}
					fontSize={"large"}
				/>
			</IconButton>
		</div>
	);
};

export default NavigationBar;
