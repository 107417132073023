import FirestoreObject, { DataTypes } from "./FirestoreObject";

export default class Address extends FirestoreObject {
	constructor(path) {
		super(path, {
			municipio: { type: DataTypes.String, default: "" },
			estado: { type: DataTypes.String, default: "" },
			pais: { type: DataTypes.String, default: "" },
			cep: { type: DataTypes.String, default: "" },
			logradouro: { type: DataTypes.String, default: "" },
			numero: { type: DataTypes.String, default: "" },
			complemento: { type: DataTypes.String, default: "" },
			bairro: { type: DataTypes.String, default: "" },
		});
	}

	getFormatAddress() {
		let address = "";

		if (this.pais.toLowerCase() === "brasil" || this.pais.toLowerCase() === "brazil" || this.pais.toLowerCase() === "br") {
			if (!this.logradouro || !this.numero || !this.bairro || !this.municipio || !this.estado || !this.cep) {
				return "";
			}
			address = this.logradouro + ", " + this.numero;
			if (this.complemento.trim() !== "") {
				address += " - " + this.complemento;
			}
			address += " - " + this.bairro + ", " + this.municipio + " - " + this.estado;
			if (this.cep.trim() !== "") {
				address += " - " + this.cep;
			}
		} else {
			if (!this.logradouro || !this.municipio || !this.pais) {
				return "";
			}
			address = this.logradouro;
			if (this.numero.trim() !== "") {
				address += ", " + this.numero;
			}
			if (this.complemento.trim() !== "") {
				address += " - " + this.complemento;
			}
			address += " - " + this.municipio;
			if (this.estado.trim() !== "") {
				address += ", " + this.estado;
			}
			address += " - " + this.pais;
		}

		return address.replace(/([\-,])/gi, "").trim() === "" ? "" : address;
	}
}
