import React from "react";
import { useRouter } from "Utils/useHook";
import { Home, Section, Game } from "./Pages";
import { InteractionHelper } from "Helper";
import QuizEnd from "./Components/QuizEnd";
import { AnswersBody } from "Types/Quiz";

const Quiz: React.FC = () => {
	let { query, pathname } = useRouter(["/quiz/section/:sectionId/game", "/quiz/section/:sectionId", "/quiz"]);

	if ("sectionId" in query) {
		if (InteractionHelper.route.isExact("/quiz/section/:sectionId/game")) {
			const { sectionId, unitId, levelId, isConcluded, answers } = InteractionHelper.route.query<{
				sectionId: string;
				unitId: string;
				levelId: string;
				isConcluded?: boolean;
				answers: AnswersBody;
			}>("/quiz/section/:sectionId/game");

			if (isConcluded) {
				return (
					<QuizEnd
						sectionId={sectionId}
						unitId={unitId}
						levelId={levelId}
						answers={answers}
					/>
				);
			}

			return (
				<Game
					sectionId={sectionId}
					unitId={unitId}
					levelId={levelId}
				/>
			);
		}
		return <Section id={query.sectionId} />;
	}

	return <Home />;
};

export default Quiz;
