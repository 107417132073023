import { getMsg } from "Crucial/Messages";
import * as mdi from "@mdi/js";
import { Menu } from "Utils";

const menu = new Menu();

menu.createItem({
	icon: mdi.mdiHome,
	label: getMsg("MENU_HOME"),
	href: "/",
	exact: true,
	simplifiable: true,
});

menu.createItem({
	icon: mdi.mdiChevronTripleUp,
	label: "Ranking",
	href: "/ranking",
	permissions: ["root", "desenvolvedor"],
	isNew: true,
});

menu.createItem({
	icon: mdi.mdiWalletOutline,
	label: getMsg("MENU_WALLET"),
	href: "/wallet",
	simplifiable: true,
});

menu.createItem({
	icon: mdi.mdiSafe,
	label: "Staking",
	href: "/staking",
	simplifiable: true,
});

menu.createItem({
	icon: mdi.mdiImageAlbum,
	label: "NFT",
	href: "/nfts_catalog",
});

menu.createItem({
	icon: mdi.mdiMessageSettingsOutline,
	label: "Quiz",
	href: "/quiz",
});

menu.createItem({
	icon: mdi.mdiAccessPoint,
	label: "AO VIVO",
	href: "/live",
	permissions: ["root", "desenvolvedor"],
	isNew: true,
});

menu.createItem({
	icon: mdi.mdiAutorenew,
	label: "Swap",
	href: "/swap",
});

menu.createItem({
	icon: mdi.mdiAccountCash,
	label: "iViPay",
	href: "/ivipay",
	permissions: ["root", "desenvolvedor"],
	isNew: true,
});

menu.createItem({
	icon: mdi.mdiForum,
	label: "Chat",
	href: "/chat",
	simplifiable: true,
	isActive: ({ location }) => {
		try {
			return location.pathname.search("/chat") === 0;
		} catch {
			return false;
		}
	},
});

menu.createItem({
	icon: mdi.mdiHandCoin,
	label: "Pré-venda",
	href: "/pre_venda",
});

const negociacao = menu.createSubmenu({ label: "Negociação" });

negociacao.createItem({
	icon: mdi.mdiFinance,
	label: "Conversão Rápida",
	href: "/conversao_rapida",
});

negociacao.createItem({
	icon: mdi.mdiHumanGreetingProximity,
	label: "Sala de copyTrade",
	href: "/copy_trade",
});

negociacao.createItem({
	icon: mdi.mdiAccountFilter,
	label: "Trading Avançada",
	href: "/advanced_trading",
});

const escola = menu.createSubmenu({
	label: "Escola",
	permissions: ["root", "desenvolvedor"],
});

escola.createItem({
	label: "Bosque",
	href: "/under_construction/bosque",
});

escola.createItem({
	label: "Viveiro",
	href: "/under_construction/viveiro",
});

escola.createItem({
	icon: mdi.mdiCertificate,
	label: "Certificados",
	href: "/under_construction/certificados",
});

menu.createItem({
	icon: mdi.mdiAccountCard,
	label: "Economia ao vivo",
	href: "/economia_ao_vivo",
	isNew: true,
});

menu.createItem({
	icon: mdi.mdiChartGantt,
	label: "Cronograma",
	href: "/schedule",
});

const gestao = menu.createSubmenu({
	label: "Gestão",
	permissions: ["root", "desenvolvedor", "administrador", "gestor"],
	hidden: true,
});

gestao.createItem({
	icon: mdi.mdiAccountGroup,
	label: "Usuários",
	href: "/users",
});

gestao.createItem({
	icon: mdi.mdiDatabase,
	label: "Base de Dados",
	href: "/bd_manager",
	permissions: ["root", "desenvolvedor"],
	hidden: true,
});

export default menu;
