import React from "react";

import { Typography } from "@mui/material";

import style from "./style.module.scss";

import { currencyFormat, maskAmount } from "Utils";

export interface Costs {
	percentage_rate: number;
	installment_rate: number;
	installments: number;
	additional_rate: number;
}

export const calculateCosts = (amount: number, costs?: Costs, type: "deposit" | "renegotiation" | "transfer" = "deposit") => {
	let totalAmount = amount;
	const costsOperations = ["transfer"].includes(type) ? -1 : 1;

	if (typeof costs === "object") {
		if (costs.percentage_rate !== 0) {
			totalAmount += parseFloat((amount * costs.percentage_rate * costsOperations).toFixed(2));
		}

		if (costs.installment_rate !== 0) {
			totalAmount += parseFloat((amount * costs.installment_rate * costsOperations).toFixed(2));
		}

		if (costs.additional_rate !== 0) {
			totalAmount += costs.additional_rate;
		}
	}

	return totalAmount;
};

export const listCosts = (amount: number, costs?: Costs) => {
	const result = [];

	if (typeof costs === "object") {
		if (costs.additional_rate !== 0) {
			result.push({
				title: "Taxa de serviço",
				label: `Taxa de ${maskAmount(costs.additional_rate, undefined, "R$")}`,
				amount: costs.additional_rate,
			});
		}

		if (costs.percentage_rate !== 0) {
			result.push({
				title: "Taxa de serviço",
				label: `Taxa de ${maskAmount(costs.percentage_rate * 100, 2, "%", "RTL")}`,
				amount: amount * costs.percentage_rate,
			});
		}

		if (costs.installment_rate !== 0) {
			result.push({
				title: "Taxa de parcelamento",
				label: `Em ${costs.installments} parcela(s) ${maskAmount(costs.installment_rate * 100, 2, "%", "RTL")}%`,
				amount: amount * costs.installment_rate,
			});
		}
	}

	return result;
};

const SimulateAmountCosts: React.FC<{
	amount: number;
	currencyId: string;
	payment?: {
		min_allowed_amount?: number;
		max_allowed_amount?: number;
	};
	costs?: Costs;
	typeAmount?: "deposit" | "renegotiation" | "transfer";
}> = ({ amount, currencyId, payment, costs, typeAmount = "deposit" }) => {
	const min_allowed_amount = !payment || typeof payment.min_allowed_amount !== "number" ? amount : payment.min_allowed_amount;
	const max_allowed_amount = !payment || typeof payment.max_allowed_amount !== "number" ? amount : payment.max_allowed_amount;

	const original_amount = Math.min(Math.max(min_allowed_amount, amount), max_allowed_amount);

	const totalAmount = calculateCosts(original_amount, costs, typeAmount);
	const costs_render = listCosts(original_amount, costs);

	let title = "Valor de depósito";
	let label = "Valor a pagar";

	if (typeAmount === "renegotiation") {
		title = "Valor a renegociar";
	} else if (typeAmount === "transfer") {
		title = "Valor solicitado";
		label = "Valor a receber";
	}

	return (
		<div className={style["amount-costs"]}>
			<div>
				<div className={style["label"]}>
					<Typography
						variant="subtitle1"
						component="div"
					>
						{title}
					</Typography>
				</div>
				<div className={style["value"]}>
					<Typography
						variant="h6"
						component="div"
					>
						{maskAmount(original_amount, 4, currencyId, "RTL")}
					</Typography>
				</div>
			</div>

			{costs_render.length > 0 ? (
				costs_render.map(({ title, label, amount }, index) => {
					return (
						<div key={index}>
							<div className={style["label"]}>
								<Typography
									variant="body2"
									component="div"
								>
									{title}
								</Typography>
								<Typography
									variant="caption"
									component="div"
								>
									{label}
								</Typography>
							</div>
							<div className={style["value"]}>
								<Typography
									variant="subtitle1"
									component="div"
								>
									{maskAmount(amount * (totalAmount >= original_amount ? 1 : -1), 4, currencyId, "RTL")}
								</Typography>
							</div>
						</div>
					);
				})
			) : (
				<div>
					<div className={style["label"]}>
						<Typography
							variant="subtitle1"
							component="div"
							sx={{ margin: "0px auto" }}
						>
							<i>Sem taxas</i>
						</Typography>
					</div>
				</div>
			)}

			<div>
				<div className={style["label"]}>
					<Typography
						variant="subtitle1"
						component="div"
					>
						{label}
					</Typography>
				</div>
				<div className={style["value"]}>
					<Typography
						variant="h6"
						component="div"
					>
						{maskAmount(totalAmount, 4, currencyId, "RTL")}
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default SimulateAmountCosts;
