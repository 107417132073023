import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import style from "./style.module.scss";
import { APIHelper, InteractionHelper, QuizHelper } from "Helper";
import { Button, CircularProgress, Typography } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { SectionUnitNivelQuiz, AnswersBody } from "Types/Quiz";
import { MainFrame, Markdown, SvgIcon } from "Components";
import { mdiAutoFix } from "@mdi/js";

const GamePrepared: React.FC<{
	sectionId: string;
	unitId: string;
	levelId: string;
}> = ({ sectionId = "", unitId = "", levelId = "" }) => {
	const {
		error,
		response: quizzes = {
			title: "",
			abstractionText: "",
			questions: [],
		},
		status,
	} = APIHelper.useFetch<SectionUnitNivelQuiz>({
		...QuizHelper.fetchOptions.getQuizzesInLevel(sectionId, unitId, levelId),
		immediate: true,
	});

	const [stage, setStage] = useState(0);
	const [answers, setAnswers] = useState<AnswersBody>([]);

	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [percentageWidth, setPercentageWidth] = useState(0);

	const [currentTime, setCurrentTime] = useState(0);
	const countTime = useRef<NodeJS.Timeout>();

	const loading = status !== "success";
	// reajustar para 15sec
	const timePerQuestion = 25;
	const totalTime = timePerQuestion * quizzes.questions.length;

	const countdownRef = useRef<HTMLDivElement>(null);
	const countdownTime = useRef<NodeJS.Timeout>();

	useLayoutEffect(() => {
		return () => {
			clearInterval(countTime.current);
		};
	}, []);

	useEffect(() => {
		MainFrame.Controller.hiddenMenuSummary();
		return () => {
			MainFrame.Controller.showMenuSummary();
		};
	}, []);

	useEffect(() => {
		const time = setTimeout(() => {
			if (status === "error" && error) {
				InteractionHelper.toast(error.message, null, "error");
				InteractionHelper.route.goBack();
			}
		}, 2000);
		return () => clearTimeout(time);
	}, [error, status]);

	useEffect(() => {
		if (Array.isArray(quizzes.questions) !== true || status !== "success") {
			return;
		}
		if (quizzes.questions.length === 0) {
			InteractionHelper.toast("Algo não deu muito certo. Tente novamente mais tarde!", null, "warning");
			InteractionHelper.route.goBack();
		} else {
			setAnswers(
				quizzes.questions.map(({ id }) => {
					return { id, selectedOptionId: "" };
				}),
			);
			setCurrentQuestionIndex(0);
			setPercentageWidth(0);
			setStage(0);
		}
	}, [quizzes, status]);

	const initCountTime = () => {
		clearInterval(countTime.current);
		if (currentTime >= totalTime) {
			return;
		}
		let oltTime = Date.now();
		countTime.current = setInterval(() => {
			setCurrentTime((count) => count + 1);
			oltTime = Date.now();
		}, 1000);
	};

	const initCountdown = () => {
		clearInterval(countdownTime.current);
		const startNum = 5;
		let currentNum = startNum;

		if (countdownRef.current) {
			setTimeout(() => {
				countdownRef.current?.classList.add(style["puffer"]);
			}, 600);
			countdownRef.current.innerHTML = currentNum.toString();
			countdownRef.current.classList.remove(style["puffer"]);
		}

		countdownTime.current = setInterval(() => {
			if (currentNum <= 0) {
				clearInterval(countdownTime.current);
				nextStage(2);
				return;
			}
			if (countdownRef.current) {
				countdownRef.current.innerHTML = currentNum.toString();
				setTimeout(() => {
					countdownRef.current?.classList.add(style["puffer"]);
				}, 600);
				currentNum -= 1;
				countdownRef.current.innerHTML = currentNum.toString();
				countdownRef.current.classList.remove(style["puffer"]);
			}
		}, 1000);
	};

	const nextStage = (nextIndex?: number) => {
		nextIndex = nextIndex === undefined ? stage + 1 : nextIndex;
		switch (nextIndex) {
			case 1:
				setTimeout(() => {
					initCountdown();
				}, 600);
				break;
			case 2:
				setPercentageWidth(1 / quizzes.questions.length);
				initCountTime();
				break;
		}
		setStage(nextIndex);
	};

	const handleOptionSelect = (index: number, id: string) => {
		id = answers[currentQuestionIndex].selectedOptionId === id ? "" : id;
		setAnswers((p) => {
			p[index].selectedOptionId = id;
			return [...p];
		});
	};

	const handleContinue = () => {
		const nextQuestionIndex = currentQuestionIndex + 1;
		initCountTime();

		if (nextQuestionIndex < quizzes.questions.length) {
			setCurrentQuestionIndex(nextQuestionIndex);
			setPercentageWidth((nextQuestionIndex + 1) / quizzes.questions.length);
			setCurrentTime(nextQuestionIndex * timePerQuestion);
		} else {
			clearInterval(countTime.current);
			MainFrame.Controller.showMenuSummary();
			InteractionHelper.route.replace(InteractionHelper.route.pathname, {
				sectionId,
				unitId,
				levelId,
				isConcluded: true,
				answers,
			});
		}
	};

	useEffect(() => {
		const idealIndex = Math.floor(currentTime / timePerQuestion);
		if (idealIndex != currentQuestionIndex) {
			handleContinue();
		}
	}, [currentTime]);

	useEffect(() => {
		let time: NodeJS.Timeout;
		const exitAttempt = () => {
			clearTimeout(time);
			time = setTimeout(() => {
				clearInterval(countTime.current);
				InteractionHelper.alert(
					"Detectamos uma tentativa de mudança de tela ou aplicativo, o que viola nossas regras e o espírito de competição. Pedimos que respeite nossas diretrizes para uma experiência melhor e para construir seu perfil financeiro de forma mais precisa.",
					"Violando regras",
				);
				InteractionHelper.route.replace(InteractionHelper.route.pathname + "/..");
			}, 100);
		};

		const eventMouse = (event: MouseEvent) => {
			if (event.clientY <= 0 || event.clientX <= 0 || event.clientX >= window.innerWidth || event.clientY >= window.innerHeight) {
				exitAttempt();
			}
		};

		const handleVisibilityChange = () => {
			if (document.visibilityState === "hidden") {
				exitAttempt();
			}
		};

		window.addEventListener("mouseout", eventMouse);
		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			window.removeEventListener("mouseout", eventMouse);
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	const currentQuiz = quizzes.questions[currentQuestionIndex];

	return (
		<div className={style["root"]}>
			{loading && <CircularProgress color="inherit" />}

			{!loading && answers.length >= quizzes.questions.length && (
				<div className={style["content"]}>
					<div className={style["bar-tools"]}>
						<ArrowBack
							className={style["arrowback-icon"]}
							onClick={() => {
								InteractionHelper.confirm(
									<>
										<Typography variant="body1">
											Ao sair do QUIZ, você corre o risco de perder vantagens e benefícios, como suas pontuações e ofensivas
											acumuladas. Certifique-se de concluir o QUIZ antes de sair para garantir que suas conquistas sejam salvas.
										</Typography>
									</>,
									"Deseja realmente sair do QUIZ?",
								)
									.then(() => {
										InteractionHelper.route.replace(InteractionHelper.route.pathname + "/..");
									})
									.catch(() => {});
							}}
						/>
						<div className={style["progress-bar-container"]}>
							{quizzes.questions.length > 0 && (
								<div
									style={{ width: `${Math.round(percentageWidth * 100)}%` }}
									className={style["progress-bar"]}
								></div>
							)}
						</div>
						<p className={[style["btn-tool"], style["help-question"], stage === 0 ? style["disabled"] : ""].join(" ")}>
							<span>0</span>
							<SvgIcon
								className={style["favorite-border-icon"]}
								path={mdiAutoFix}
							/>
						</p>
					</div>

					{stage === 0 && (
						<div className={style["main-div"]}>
							<div className={style["article"]}>
								{typeof quizzes.title === "string" && quizzes.title.trim() !== "" && <h1>{quizzes.title}</h1>}
								<Markdown>{quizzes.abstractionText}</Markdown>
							</div>
						</div>
					)}

					{stage === 1 && (
						<div className={style["main-div"]}>
							<div className={style["countdown"]}>
								<div
									className={style["countdown-number"]}
									ref={countdownRef}
								></div>
								<Typography
									variant="body1"
									component="div"
								>
									Você terá apenas {Math.round(totalTime)} segundos para completar o QUIZ. Fique atento ao tempo!
								</Typography>
							</div>
						</div>
					)}

					{stage === 2 && currentQuiz && (
						<div className={style["main-div"]}>
							<div>
								<div className={style["fake-space"]}></div>
								<header className={style["main-header"]}>
									<div className={style["question-div"]}>
										<h2 className={style.pergunta}> Pergunta {currentQuestionIndex + 1}</h2>
										<span className={style["question-text"]}>{currentQuiz.questionText}</span>
									</div>
								</header>
								<section className={style["questions-buttons"]}>
									{currentQuiz.options.map(({ id, label }, index: number) => {
										const isSelected = answers[currentQuestionIndex].selectedOptionId === id;

										return (
											<Button
												key={index}
												className={`${style["button"]} ${isSelected ? style["selected"] : ""}`}
												variant="outlined"
												onClick={() => handleOptionSelect(currentQuestionIndex, id)}
											>
												<p className={`${style["button-alternative"]} ${isSelected ? style["selected"] : ""}`}>
													<span>{index + 1}</span>
												</p>
												<p className={style["button-option"]}>
													<Markdown>{label}</Markdown>
												</p>
											</Button>
										);
									})}
								</section>
								<div className={style["fake-space"]}></div>
							</div>
						</div>
					)}

					<section className={style["sections"]}>
						{stage === 0 && (
							<Button
								className={style["start-button"]}
								variant="contained"
								onClick={() => nextStage()}
							>
								<p>Iniciar</p>
							</Button>
						)}

						{stage === 2 && (
							<>
								<div className={style["progress-with-label"]}>
									<CircularProgress
										variant="determinate"
										value={(Math.round(currentTime % timePerQuestion) / timePerQuestion) * 100}
									/>
									<div>
										<Typography
											variant="caption"
											component="div"
										>
											{`${Math.round(currentTime % timePerQuestion)}s`}
										</Typography>
									</div>
								</div>
								<Button
									className={style["confirm-button"]}
									onClick={handleContinue}
									disabled={answers[currentQuestionIndex].selectedOptionId === ""}
								>
									<p>Confirmar</p>
								</Button>
							</>
						)}
					</section>
				</div>
			)}
		</div>
	);
};

export default GamePrepared;
