import Button from "@mui/material/Button";
import "./index.css";
import { TextField } from "@mui/material";

export default {
    id: "Tela2",
    title: "Tela 2",
    render: () => {
        return (
            <div className="centralizar-tela2">
                <div className="bordas-box-tela2 bg-box-tela2 margem-box-tela2">
                    <Button variant="text">
                        <p>Conta - nomeUser - nomeWallet</p>
                    </Button>
                </div>
                <div className="bordas-box-tela2 bg-box-tela2 margem-box-tela2-top">
                    <Button variant="text">
                        <p>BTC/USDT</p>
                    </Button>
                </div>
                <p className="margem-box-tela2" id="porcentagem-variacao">
                    X,XX%
                </p>
                <div className="bordas-box-tela2 bg-box-tela2 margem-box-tela2 conteudo-tela2 flex-row-tela2">
                    <div>
                        <h1>BitCoin</h1>
                        <p>Carteira: X.XXXXX</p>
                        <p>Livre: X.XXXXX</p>
                        <p>Usado: X.XXXXX</p>
                    </div>
                    <div>
                        <h1>Tether</h1>
                        <p>Carteira: X.XXXXX</p>
                        <p>Livre: X.XXXXX</p>
                        <p>Usado: X.XXXXX</p>
                    </div>
                </div>
                <div className="bordas-box-tela2 bg-box-tela2 margem-box-tela2 valor-box-tela2">
                    <p>
                        Preço: R$ XX,XXX.XX / min: R$ XX,XXX.XX / max: R$
                        XX,XXX.XX
                    </p>
                </div>
                <div className="bordas-box-tela2 bg-box-tela2 margem-box-tela2 botoes flex-row-tela2 div-btn">
                    <Button color="warning" variant="text">
                        <p>Comprar</p>
                    </Button>
                    <Button variant="text">
                        <p>Vender</p>
                    </Button>
                </div>
                <div className="bordas-box-tela2 bg-box-tela2 margem-box-tela2 botoes flex-row-tela2 div-btn">
                    <Button variant="text" color="warning">
                        <p>Limite</p>
                    </Button>
                    <Button variant="text">
                        <p>Market</p>
                    </Button>
                    <Button variant="text">
                        <p>Stop</p>
                    </Button>
                    <Button variant="text">
                        <p>oco</p>
                    </Button>
                </div>
                <div className="bordas-box-tela2 bg-box-tela2 margem-box-tela2 flex-row-tela2 div-input-tela2">
                    <TextField
                        variant="standard"
                        label="Preço"
                        inputProps={{ inputMode: "numeric", min: "0" }}
                        type="number"
                        size="small"
                        className="bordas-box-tela2 bg-box-tela2 margem-box-tela2 TextField"
                    ></TextField>
                </div>
                <div className="bordas-box-tela2 bg-box-tela2 margem-box-tela2 flex-row-tela2 div-input-tela2">
                    <TextField
                        variant="standard"
                        label="Quantia"
                        inputProps={{ inputMode: "numeric", min: "0" }}
                        type="number"
                        size="small"
                        className="bordas-box-tela2 bg-box-tela2 margem-box-tela2 TextField"
                    ></TextField>
                </div>
                <div
                    className="bordas-box-tela2 bg-box-tela2 margem-box-tela2"
                    id="btn-comprarBtc div-btn"
                >
                    <Button variant="text">
                        <p className="decendo-buton">Comprar btc</p>
                    </Button>
                </div>
            </div>
        );
    },
};
