import { throttle, debounce } from "Utils/lodash";
import React, { useEffect, useRef, useState, useCallback } from "react";

const useScroll = () => {
    const containerRef = useRef(null);
    const [isBottom, setIsBottom] = useState(false);
    const [isTop, setIsTop] = useState(false);

    const updateScroll = useCallback(()=>{
        const container = containerRef.current;
        if(!container) return;

        const margin = 0.04;

        if(container.scrollTop > (container.scrollHeight - container.clientHeight) * (1 - margin)){
            setIsBottom(true);
        }else{
            setIsBottom(false);
        }

        if(container.scrollTop <= container.clientHeight * margin){
            setIsTop(true);
        }else{
            setIsTop(false);
        }
    }, [ containerRef.current ]);

    useEffect(() => {
        updateScroll();

        const handleScroll = debounce(()=>{
            updateScroll();
        }, 100);

        containerRef.current?.addEventListener("scroll", handleScroll);

        return () => {
            containerRef.current?.removeEventListener("scroll", handleScroll);
        };
    }, [containerRef.current, updateScroll]);

    return [isBottom, isTop, containerRef];
};

export default useScroll;