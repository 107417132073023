/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-children-prop */
import React, { useState, useCallback, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import rehypeRaw from "rehype-raw";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

type MarkdownTree = {
	type: string;
	children?: MarkdownTree[];
	isVisited?: boolean;
} & (
	| {
			type: "text";
			value: string;
	  }
	| {
			type: "root";
			children: MarkdownTree[];
	  }
	| {
			type: "element";
			tagName: string;
			children: MarkdownTree[];
	  }
);

const rehypeSimplify = ({
	disable = false,
	includeTags = ["em", "strong", "del"],
}: {
	disable?: boolean;
	includeTags?: string[];
} = {}) => {
	const simplify = (node: any) => {
		if (node.type === "text") {
			return;
		}

		if (node.type === "root" || node.type === "element") {
			if (node.type === "element" && !includeTags.includes(node.tagName)) {
				node.tagName = "span";
			}

			for (let i = 0; i < node.children.length; i++) {
				simplify(node.children[i]);
			}
		}

		return node;
	};

	return (tree: any) => {
		if (!disable) {
			return simplify(tree);
		}
	};
};

const rehypeTruncate = ({
	disable = false,
	ellipses = "\u2026",
	ignoreTags = [],
	maxChars = 120,
	onTruncate,
}: {
	disable?: boolean;
	ellipses?: string;
	ignoreTags?: string[];
	maxChars?: number;
	onTruncate?: (isTruncate: boolean) => void;
} = {}) => {
	const splitEmoji = (string: string) => [...new (Intl as any).Segmenter().segment(string)].map((x) => x.segment);

	const truncateNode = (node: any, tf = 0) => {
		let foundText = tf;

		if (node.type === "text") {
			const value = splitEmoji(node.value);
			foundText += value.length;
			if (foundText >= maxChars) {
				node.value = `${value.slice(0, value.length - (foundText - maxChars)).join("")}${ellipses}`;
				return maxChars;
			}
		}

		if (node.type === "root" || node.type === "element") {
			if (node.type === "element" && ignoreTags.includes(node.tagName)) {
				return foundText;
			}
			for (let i = 0; i < node.children.length; i++) {
				if (foundText === maxChars) {
					node.children.splice(i, 1);
					i--;
					continue;
				}
				foundText = truncateNode(node.children[i], foundText);
			}
		}

		return foundText;
	};

	const callTruncate = (isTruncate: boolean) => {
		if (typeof onTruncate === "function") {
			onTruncate(isTruncate);
		}
	};

	return (tree: any) => {
		if (!disable) {
			const foundText = truncateNode(tree);
			callTruncate(foundText >= maxChars);
		} else {
			callTruncate(false);
		}
	};
};

const getLastChildLine = ({
	className,
}: {
	className?: string | string[];
} = {}) => {
	className = (Array.isArray(className) ? className : (className ?? "").split(/\s+/gi)).filter((s) => typeof s === "string");

	return (tree: any) => {
		if (tree.type === "root" && tree.children.length && Array.isArray(className) && className.length) {
			const lastChild = tree.children[tree.children.length - 1];
			if (["p", "span"].includes(lastChild.tagName)) {
				lastChild.properties.className = (Array.isArray(lastChild.properties.className) ? lastChild.properties.className : []).concat(
					className,
				);
			}
		}

		return tree;
	};
};

const Markdown: React.FC<{
	nextLimitChars?: number;
	limitChars?: number;
	simplify?: boolean;
	children: string;
}> = ({ children, limitChars: _limitChars, nextLimitChars: _nextLimitChars, simplify = false }) => {
	const [isLimitChars, setIsLimitChars] = useState<boolean>(false);
	const [limitChars, setLimitChars] = useState<number>(100);
	const [nextLimitChars, setNextLimitChars] = useState<number>(255);
	const [truncated, setTruncated] = useState<boolean>(false);
	const timeForTruncate = useRef<NodeJS.Timeout>();

	useEffect(() => {
		setIsLimitChars(typeof _limitChars === "number" && _limitChars > 0);
		if (typeof _limitChars === "number" && _limitChars > 0) {
			setLimitChars(_limitChars);
		}
	}, [_limitChars, nextLimitChars]);

	useEffect(() => {
		if (typeof _nextLimitChars === "number") {
			setNextLimitChars(_nextLimitChars);
		}
	}, [_nextLimitChars]);

	const showMoreChars: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
		(e) => {
			e?.preventDefault();
			if (!truncated) {
				return;
			}
			setLimitChars((prev) => prev + nextLimitChars);
		},
		[truncated, nextLimitChars],
	);

	const updateTruncated = useCallback((isTruncated: any) => {
		clearTimeout(timeForTruncate.current);
		timeForTruncate.current = setTimeout(() => {
			setTruncated(isTruncated);
		}, 200);
	}, []);

	return (
		<>
			<ReactMarkdown
				children={children}
				skipHtml={false}
				components={{
					text: ({ children }) => {
						children = children.map((content) => {
							if (typeof content === "string") {
								content = content.split(/\\[\(\)]/gi).map((l, i) => {
									return (i + 1) % 2 === 0 ? (
										<InlineMath
											key={i}
											math={l}
										/>
									) : (
										l
									);
								});
							}

							return content;
						});
						return <>{children}</>;
					},
					a: ({ children, href, ...props }) => {
						return (
							<a
								href={href}
								{...props}
								target={String(href).search("#") === 0 ? "" : "_blank"}
							>
								{children}
							</a>
						);
					},
					table: (): any => {
						return undefined;
					},
					img: (): any => {
						return undefined;
					},
					p: ({ children, node, ...props }) => {
						return <p {...props}>{children}</p>;
					},
				}}
				remarkPlugins={[remarkGfm, remarkBreaks]}
				rehypePlugins={[
					rehypeRaw,
					[
						rehypeTruncate,
						{
							disable: !isLimitChars,
							ellipses: "",
							maxChars: limitChars,
							onTruncate: updateTruncated,
						},
					],
					[rehypeSimplify, { disable: !simplify }],
					[
						getLastChildLine,
						{
							className: "message-last-child",
						},
					],
					() => {
						const textToElement = (tree: MarkdownTree): MarkdownTree => {
							if (tree.type === "text" && !tree.isVisited) {
								return {
									type: "element",
									tagName: "text",
									children: [
										{
											type: "text",
											value: tree.value,
											isVisited: true,
										},
									],
								};
							}

							if (tree.children && tree.children.length > 0) {
								tree.children = tree.children.map(textToElement);
							}

							return tree;
						};

						return textToElement;
					},
				]}
			/>
			{truncated && nextLimitChars > 0 && (
				<span>
					{" "}
					<a
						href="#"
						onClick={showMoreChars}
					>
						ler mais...
					</a>
				</span>
			)}
		</>
	);
};

export default Markdown;
