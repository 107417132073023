import React from "react";

export interface ProgressProfileOptions {
	[p: string]: any;
}

export type ProgressProfileData = {
	indexes: string[];
	labels: string[];
	data: any[][];
};

const ProgressProfile: React.FC<{}> = () => {
	return <></>;
};

export default ProgressProfile;
