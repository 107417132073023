import React, { useLayoutEffect, useState, useCallback } from "react";

import {
	CircularProgress,
	Typography,
	Link,
	Checkbox,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	ButtonGroup,
	Button,
} from "@mui/material";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import { APIHelper, InteractionHelper, WalletHelper } from "Helper";

import { SvgIcon } from "Components";

import { mdiHandCoin, mdiCancel, mdiHandshakeOutline, mdiArmFlex } from "@mdi/js";

import { Wallet } from "Models";

import { currencyFormat } from "Utils";

import style from "./style.module.scss";
import { WalletCredit, WalletCreditContract } from "Types/Wallet";
import { DepositDialog } from "Dialog";

const diffEmDias = (date1: Date | number | string, date2: Date | number | string): number => {
	date1 = date1 instanceof Date ? date1.getTime() : typeof date1 === "string" ? new Date(date1).getTime() : date1;
	date2 = date2 instanceof Date ? date2.getTime() : typeof date2 === "string" ? new Date(date2).getTime() : date2;
	const diffEmMilissegundos = Math.abs(date1 - date2);
	return Math.ceil(diffEmMilissegundos / (1000 * 60 * 60 * 24));
};

const cancelContractDialog = (walletId: string, contract: WalletCreditContract, onClose?: () => void, stage: number = 0) => {
	let { id, original_amount, description, date_created } = contract;

	date_created = new Date(date_created);

	if (stage === 1) {
		InteractionHelper.loading();

		APIHelper.fetch(`wallet/credit/${walletId}/cancel/${id}`)
			.then(() => {
				InteractionHelper.toast(`Contrato ${id} cancelado com sucesso.`, null, "success");
			})
			.catch(({ message }) => {
				InteractionHelper.toast(String(message), null, "success");
			})
			.finally(() => {
				if (typeof onClose === "function") {
					onClose();
				}
				InteractionHelper.close();
			});
	} else {
		InteractionHelper.confirm(
			<>
				<div className={style["cancelContractDialog-text"]}>
					<Typography variant="subtitle2">
						Ao pressionar "CONFIRMAR", o contrato de número {id} será cancelado. Por favor, esteja ciente de que para efetuar o
						cancelamento deste contrato, é necessário que a sua carteira possua o valor total do contrato para a devolução, ou seja, o
						montante de {currencyFormat.convert(parseFloat(original_amount.toFixed(2)), "BRL")}. Certifique-se de ter o valor adequado
						disponível antes de prosseguir com o cancelamento.
					</Typography>
				</div>
				<div className={style["cancelContractDialog-text"]}>
					<Typography variant="h6">
						<b>Descrição:</b>
					</Typography>
					<Typography variant="subtitle2">{description}</Typography>
				</div>
				<div className={style["cancelContractDialog-text"]}>
					<Typography variant="h6">
						<b>Data:</b>
					</Typography>
					<Typography variant="subtitle2">O contrato foi solicitado em {date_created.toLocaleString()}.</Typography>
				</div>
			</>,
			`Cancelamento do contrato ${id}`,
		)
			.then(() => {
				cancelContractDialog(walletId, contract, onClose, 1);
			})
			.catch(() => {
				if (typeof onClose === "function") {
					onClose();
				}
				InteractionHelper.close();
			});
	}
};

const ViewContracts: React.FC<{
	walletId?: string;
	credit?: WalletCredit;
	forRefresh?: () => void;
}> = ({ walletId = "", credit, forRefresh }) => {
	const [expanded, setExpanded] = useState<string | null>();

	const handleExpandedChange = (panel: string) => (event: React.SyntheticEvent<Element, Event>, newExpanded: boolean) => {
		setExpanded(newExpanded ? panel : null);
	};

	const cancelContract = (contractId: string) => () => {
		if (!credit || !credit.contracts) {
			return;
		}
		cancelContractDialog(walletId, credit?.contracts[contractId], forRefresh);
	};

	const renegotiateContract = (contractId: string) => () => {
		WalletHelper.showRenegotiation({
			wallet_id: walletId,
			contract_id: contractId,
		});
	};

	const payOff = useCallback(() => {
		InteractionHelper.toast(
			"Estamos trabalhando nesta funcionalidade e gostaríamos de informar que ainda não está disponível. Por favor, aguarde pela próxima versão onde estará disponível. Obrigado pela compreensão.",
			null,
			"warning",
		);
	}, []);

	const renegotiate = useCallback(() => {
		InteractionHelper.toast(
			"Estamos trabalhando nesta funcionalidade e gostaríamos de informar que ainda não está disponível. Por favor, aguarde pela próxima versão onde estará disponível. Obrigado pela compreensão.",
			null,
			"warning",
		);
	}, []);

	return (
		<div className={style["credit-invoices"]}>
			{credit &&
				credit.contracts &&
				Object.entries(credit.contracts).map(
					([contract, { total_amount, date_created, installments, invoices, description }], index, contracts) => {
						const installments_paid = Object.values(invoices).reduce((acc, curr) => (curr.status === "paid" ? acc + 1 : acc), 0);
						const late_installments = Object.values(invoices).reduce((acc, curr) => (curr.status === "overdue" ? acc + 1 : acc), 0);
						const pending_installments = Object.values(invoices).reduce((acc, curr) => (curr.status === "pending" ? acc + 1 : acc), 0);

						date_created = new Date(date_created);

						const amount_paid = Object.values(invoices).reduce(
							(acc, curr) => (curr.status === "paid" ? acc + curr.total_amount : acc),
							0,
						);

						const contract_status = late_installments > 0 ? "overdue" : "in_day";

						const contract_status_label = {
							in_day: { label: "Em dia", color: "--info-main" },
							overdue: { label: "Em atraso", color: "--warning-main" },
						};

						//const disableCancellation = diffEmDias(new Date(), date_created) > 15 || amount_paid > 0;
						const disableCancellation = amount_paid > 0;
						const disableRenegotiate = parseFloat((total_amount - amount_paid).toFixed(2)) <= 0;

						return (
							<Accordion
								disableGutters
								elevation={0}
								square
								expanded={(index === 0 && contracts.length <= 1) || expanded === contract}
								onChange={handleExpandedChange(contract)}
								key={index}
							>
								<AccordionSummary
									expandIcon={
										<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", display: contracts.length <= 1 ? "none" : "" }} />
									}
								>
									<Typography
										variant="subtitle1"
										sx={{ marginLeft: contracts.length > 1 ? "15px" : "0px" }}
									>
										Contrato - <b>{contract}</b>
									</Typography>
									<Typography variant="subtitle1">
										<b>
											{installments_paid}/{installments}
										</b>{" "}
										-{" "}
										<span
											style={{
												color: `var(${contract_status_label[contract_status].color})`,
												fontStyle: "italic",
											}}
										>
											{contract_status_label[contract_status].label}
										</span>
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<div className={style["AccordionDetails-text"]}>
										<Typography variant="h6">
											<b>Descrição:</b>
										</Typography>
										<Typography variant="subtitle2">{description}</Typography>
									</div>
									<div className={style["AccordionDetails-text"]}>
										<Typography variant="h6">
											<b>Data:</b>
										</Typography>
										<Typography variant="subtitle2">O contrato foi solicitado em {date_created.toLocaleString()}.</Typography>
									</div>
									<div className={style["AccordionDetails-text"]}>
										<Typography variant="h6">
											<b>Parcelas:</b>
										</Typography>
									</div>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell align="center">Parcela</TableCell>
												<TableCell align="center">Vencimento</TableCell>
												<TableCell align="left">Valor</TableCell>
												<TableCell align="left">Situação</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{Object.entries(invoices).map(([invoice, { installment, total_amount, status }], index) => {
												const year = parseInt(invoice.substring(0, 4));
												const month = parseInt(invoice.substring(4, 6)) - 1;
												const due_date = new Date(year, month, 15, 0, 0, 0);
												const status_label = {
													paid: { label: "Pago", color: "--success-main" },
													pending: { label: "Pendente", color: "--warning-main" },
													overdue: { label: "Vencido", color: "--error-main" },
												};

												return (
													<TableRow
														key={`invoice-${index}`}
														sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
													>
														<TableCell align="center">
															{installment}/{installments}
														</TableCell>
														<TableCell align="center">{due_date.toLocaleDateString()}</TableCell>
														<TableCell align="left">
															{currencyFormat.convert(parseFloat(total_amount.toFixed(2)), "BRL")}
														</TableCell>
														<TableCell align="left">
															<Typography
																variant="subtitle2"
																style={{ color: `var(${status_label[status]?.color || ""})` }}
															>
																{status_label[status]?.label || status}
															</Typography>
														</TableCell>
													</TableRow>
												);
											})}

											<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
												<TableCell
													align="right"
													colSpan={2}
												>
													Valor total pago:
												</TableCell>
												<TableCell
													align="left"
													colSpan={2}
												>
													{currencyFormat.convert(parseFloat(amount_paid.toFixed(2)), "BRL")}
												</TableCell>
											</TableRow>

											<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
												<TableCell
													align="right"
													colSpan={2}
												>
													Valor restante à pagar:
												</TableCell>
												<TableCell
													align="left"
													colSpan={2}
												>
													{currencyFormat.convert(parseFloat((total_amount - amount_paid).toFixed(2)), "BRL")}
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
									<div className={style["ButtonGroup-content"]}>
										<ButtonGroup>
											<Button
												variant="outlined"
												disabled={disableCancellation}
												startIcon={
													<SvgIcon
														path={mdiCancel}
														children={undefined}
													/>
												}
												onClick={disableCancellation ? undefined : cancelContract(contract)}
											>
												Cancelar
											</Button>
											<Button
												variant="outlined"
												disabled
												startIcon={
													<SvgIcon
														path={mdiHandCoin}
														children={undefined}
													/>
												}
												onClick={payOff}
											>
												Quitar
											</Button>
											<Button
												variant="outlined"
												disabled={disableRenegotiate}
												startIcon={
													<SvgIcon
														path={mdiHandshakeOutline}
														children={undefined}
													/>
												}
												onClick={disableRenegotiate ? undefined : renegotiateContract(contract)}
											>
												Renegociar
											</Button>
										</ButtonGroup>
									</div>
								</AccordionDetails>
							</Accordion>
						);
					},
				)}
		</div>
	);
};

const ViewCredit: React.FC<{
	[prop: string]: any;
	wallet: Wallet | null;
}> = ({ wallet }) => {
	const [credit, setCredit] = useState<WalletCredit | null>(null);

	useLayoutEffect(() => {
		if (!(wallet instanceof Wallet) || !wallet.path) {
			return;
		}

		APIHelper.fetch(
			`wallet/credit/${wallet.path
				.replace(/(\/+)$/gi, "")
				.split("/")
				.pop()}`,
			{},
			15,
		)
			.then((credit) => {
				setCredit(() => credit as any);
			})
			.catch(() => {
				setCredit(() => null);
			});
	}, [wallet]);

	const forceUpdate = useCallback(() => {
		if (!(wallet instanceof Wallet) || !wallet.path) {
			return;
		}

		const walletId = wallet.path
			.replace(/(\/+)$/gi, "")
			.split("/")
			.pop();
		setCredit(() => null);

		APIHelper.fetch(`wallet/credit/${walletId}`, {}, true)
			.then((credit) => {
				setCredit(() => credit as any);
			})
			.catch(() => {
				setCredit(() => null);
			});
	}, [wallet]);

	const applyForCredit = useCallback(() => {
		if (!(wallet instanceof Wallet) || !wallet.path) {
			return;
		}

		const walletId = wallet.path
			.replace(/(\/+)$/gi, "")
			.split("/")
			.pop();
		InteractionHelper.loading();

		APIHelper.fetch(`wallet/credit/${walletId}/request_credit_assessment`, {}, 5)
			.then((message) => {
				InteractionHelper.close();
				InteractionHelper.toast(String(message), null, "success", null, 15000);
			})
			.catch((e) => {
				const errorMsg =
					e && typeof e.message === "string" && e.message.trim() !== ""
						? e.message
						: "Algo deu errado, verifique sua conexão e tente novamente!";
				InteractionHelper.close();
				InteractionHelper.toast(errorMsg, null, "warning", null, 15000);
			})
			.finally(() => {
				forceUpdate();
			});
	}, [wallet, forceUpdate]);

	const showForPay = useCallback(
		(type: string) => {
			if (!(wallet instanceof Wallet) || !wallet.path) {
				return;
			}
			const walletId = wallet?.path
				.replace(/(\/+)$/gi, "")
				.split("/")
				.pop();

			if (!walletId) {
				InteractionHelper.toast("Algo deu errado, tente novamente mais tarde!", null, "warning", null, 15000);
				return;
			}

			WalletHelper.payInstallment(walletId, type as any, {}).finally(() => {
				forceUpdate();
			});
		},
		[wallet, forceUpdate],
	);

	if (credit === null) {
		return <CircularProgress color="inherit" />;
	} else if (Object.keys(credit).length <= 0) {
		return <React.Fragment></React.Fragment>;
	}

	const lastReview = new Date(credit.lastReview);
	const analysisRequested = new Date(credit.analysisRequested);

	if (credit.approvedLimit < 500 && credit.limitUsed <= 0) {
		if (analysisRequested > lastReview && diffEmDias(new Date(), analysisRequested) < 14) {
			return (
				<div className={style["credit-initial-message"]}>
					<Typography
						className={style["display-message"]}
						variant="subtitle1"
						gutterBottom
					>
						O seu limite de crédito está em análise. Pedimos que aguarde o retorno da nossa equipe financeira.
					</Typography>
				</div>
			);
		}

		if (analysisRequested < lastReview && diffEmDias(new Date(), lastReview) < 14) {
			return (
				<div className={style["credit-initial-message"]}>
					<Typography
						className={style["display-message"]}
						variant="subtitle1"
						gutterBottom
					>
						Infelizmente, sua solicitação de crédito foi rejeitada. Pedimos que continue movimentando sua carteira financeira e solicite
						uma nova análise em breve. Agradecemos sua compreensão e permanecemos à disposição para quaisquer dúvidas.
					</Typography>
				</div>
			);
		}

		return (
			<div className={style["credit-initial-message"]}>
				<Typography
					variant="subtitle1"
					gutterBottom
					sx={{
						marginBottom: "40px",
						textAlign: "center",
					}}
				>
					Com um limite de crédito, você pode ter mais flexibilidade e agilidade em suas transações financeiras, além de poder aproveitar
					oportunidades de negócio que possam surgir. Não perca tempo, solicite agora mesmo a sua linha de crédito!
				</Typography>
				<Button
					variant="contained"
					onClick={applyForCredit}
				>
					Pedir crédito agora
				</Button>
			</div>
		);
	}

	const limitUsed = credit.limitUsed - (credit.originalAmountToPay + credit.originalOverdueAmount);

	const percentage_topay = parseFloat((credit.originalAmountToPay / credit.approvedLimit).toFixed(4));
	const percentage_overdue = parseFloat((credit.originalOverdueAmount / credit.approvedLimit).toFixed(4));
	const percentage_used = parseFloat((limitUsed / credit.approvedLimit).toFixed(4));

	const walletId =
		wallet?.path
			?.replace(/(\/+)$/gi, "")
			.split("/")
			.pop() ?? "";

	return (
		<>
			<div className={style["credit-chart"]}>
				<div className={style["credit-chart-view"]}>
					<div
						className={style["credit-chart-total-indicador"]}
						title={String(currencyFormat.convert(parseFloat(credit.approvedLimit.toFixed(2)), "BRL", 2)) ?? "0,00"}
					></div>
					<div style={{ flex: "1" }}>
						<div className={style["credit-chart-color-green"]}></div>
					</div>
					{percentage_overdue > 0 && (
						<div style={{ width: `${percentage_overdue * 100}%` }}>
							<div className={style["credit-chart-color-purple"]}></div>
						</div>
					)}
					{percentage_topay > 0 && (
						<div style={{ width: `${percentage_topay * 100}%` }}>
							<div className={style["credit-chart-color-orange"]}></div>
						</div>
					)}
					<div style={{ width: `${percentage_used * 100}%` }}>
						<div className={style["credit-chart-color-red"]}></div>
					</div>
				</div>
				<div className={style["credit-chart-indexes"]}>
					{diffEmDias(new Date(), lastReview) > 3 && (
						<div
							className={style["btn"]}
							onClick={applyForCredit}
							style={{ ["--color" as any]: "var(--primary-dark)" }}
						>
							<SvgIcon
								path={mdiArmFlex}
								children={undefined}
								style={{ fontSize: "30px" }}
							/>
							<div className={style["credit-chart-indexes-label"]}>
								<Typography
									variant="h6"
									gutterBottom
								>
									POTENCIALIZE SEU CRÉDITO
								</Typography>
							</div>
						</div>
					)}
					<div
						className={style["btn"]}
						onClick={() =>
							DepositDialog.show({
								wallet: wallet?.path ?? "",
								hidenOptions: true,
								altoSelect: false,
								type: "emprestimo",
							})
						}
					>
						<SvgIcon
							path={mdiHandCoin}
							children={undefined}
							style={{ fontSize: "30px" }}
						/>
						<div className={style["credit-chart-indexes-label"]}>
							<Typography
								variant="h6"
								gutterBottom
							>
								CRÉDITO AQUI
							</Typography>
						</div>
					</div>
					<div>
						<div className={[style["credit-chart-indexes-view"], style["credit-chart-color-green"]].join(" ")}></div>
						<div className={style["credit-chart-indexes-label"]}>
							<Typography
								variant="h6"
								gutterBottom
							>
								{currencyFormat.convert(parseFloat((credit.approvedLimit - credit.limitUsed).toFixed(2)), "BRL")}
							</Typography>
							<Typography
								variant="subtitle1"
								component="p"
								gutterBottom
							>
								Limite disponível
							</Typography>
						</div>
					</div>
					{percentage_overdue > 0 && (
						<div>
							<div className={[style["credit-chart-indexes-view"], style["credit-chart-color-purple"]].join(" ")}></div>
							<div className={style["credit-chart-indexes-label"]}>
								<Typography
									variant="h6"
									gutterBottom
								>
									{currencyFormat.convert(parseFloat(credit.totalOverdueAmount.toFixed(2)), "BRL")}
								</Typography>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
								>
									Fatura em atraso
								</Typography>
							</div>
							<Button
								variant="outlined"
								size="small"
								onClick={() => showForPay("overdue")}
							>
								Pagar
							</Button>
						</div>
					)}
					{percentage_topay > 0 && (
						<div>
							<div className={[style["credit-chart-indexes-view"], style["credit-chart-color-orange"]].join(" ")}></div>
							<div className={style["credit-chart-indexes-label"]}>
								<Typography
									variant="h6"
									gutterBottom
								>
									{currencyFormat.convert(parseFloat(credit.totalAmountToPay.toFixed(2)), "BRL")}
								</Typography>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
								>
									Próxima fatura a pagar
								</Typography>
							</div>
							{percentage_overdue <= 0 && (
								<Button
									variant="outlined"
									size="small"
									onClick={() => showForPay("payable")}
								>
									Pagar
								</Button>
							)}
						</div>
					)}
					{limitUsed > 0 && (
						<div>
							<div className={[style["credit-chart-indexes-view"], style["credit-chart-color-red"]].join(" ")}></div>
							<div className={style["credit-chart-indexes-label"]}>
								<Typography
									variant="h6"
									gutterBottom
								>
									{currencyFormat.convert(parseFloat(limitUsed.toFixed(2)), "BRL")}
								</Typography>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
								>
									Fatura em processo
								</Typography>
							</div>
						</div>
					)}
				</div>
			</div>

			<ViewContracts
				walletId={walletId}
				credit={credit}
				forRefresh={forceUpdate}
			/>
		</>
	);
};

export default ViewCredit;
