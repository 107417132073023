import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import ButtonGroup from '@mui/material/ButtonGroup';

import Typography from '@mui/material/Typography';
// import TabsCateiraIvipIvipay from "../../Components/TabsCateiraIvipIvipay";



import "./index.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(20),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default {
  id: "Tela1",
  title: "Tela 1",
  render: () => {
    return (
      <div >



        <Box sx={{ flexGrow: 1 }} >
          <Grid container spacing={2} >
            <Grid item xs={12} sx={{ borderRadius: 0 }}>

              <div className='quadrado-inteiroO'>
                <Button classes={{ root: "MuiButton-root-cateira-vip" }} variant="text">cex</Button >
                <Button classes={{ root: "MuiButton-root-cateira-vip-Pay" }} variant="text">todos</Button >
                <Button classes={{ root: "MuiButton-root-cateira-vip-Pay" }} variant="text">dex</Button >
              </div>
              <div className='separandoBotoes'>
                <ButtonGroup classes={{ root: "MuiButtonGroup-root-acertandoBotoes" }} variant="outlined" aria-label="outlined button group">
                  <Button>Btc</Button>
                  <Button>usdt</Button>
                </ButtonGroup>
                <ButtonGroup classes={{ root: "MuiButtonGroup-root-acertandoBotoeS" }} variant="outlined" aria-label="outlined button group">
                  <Button>1d</Button>
                  <Button>1s</Button>
                  <Button>1M</Button>
                </ButtonGroup>
              </div>
              <div className="ajustando-numeros">
                  
                  <Typography classes={{root:"MuiTypography-root-ajustandoTipography"}} variant="subtitle1" gutterBottom>0.23046258 BTC</Typography>
                  <Typography classes={{root:"MuiTypography-root-ajustandoTipographyY"}} variant="subtitle1" gutterBottom>+0.03046258 BTC</Typography>
              </div>
            </Grid>

          </Grid>
        </Box>
      </div>
    );
  },
};







