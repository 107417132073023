import { mdiBorderNoneVariant, mdiCashFast, mdiChartLineVariant, mdiHandCoin, mdiNetworkPos, mdiSwapVerticalCircle, mdiWallet } from "@mdi/js";
import FirestoreObject, { DataTypes } from "./FirestoreObject";

import { SvgIcon } from "@mui/material";
import React from "react";

import { walletType, WalletStatus, OrderInfoType } from "Types/Wallet";
import { isDate } from "Utils";

import { SvgIcon as SvgIconCunstom } from "Components";

/**
 * Class responsável pelo abstrato de uma carteira com informações de identificação ao banco de dados e dados de estatistica
 * @module Wallet
 */
class Wallet extends FirestoreObject<Wallet> {
	type: walletType = "IVIPCOIN";
	nome: string = "";
	status: WalletStatus = "underAnalysis";
	dataCriacao: Date | number | string = Date.now();
	dataModificacao: Date | number | string = Date.now();
	totalValue: number = 0;
	currencyType: string = "USD";
	information: { [key: string]: any } = {};
	apiType: "api" | "testnet" | "invalid" = "invalid";

	/**
	 * Cria uma nova carteira abstrata com informações de identificação a base de dados
	 * @param {string} path - caminho direcional a base de dados
	 * @param {("BINANCE"|"GATE"|"OKX"|"KUCOIN"|"TRUSTWALLET"|"METAMASK")} type - definição da carteira
	 * @param {string} nome - nome da carteira
	 * @param {Data<number>} dataCriacao - data em que foi criada a carteira na base de dados
	 */
	constructor(path?: string | null) {
		super(path as any, {
			type: { type: DataTypes.String, default: "" },
			nome: { type: DataTypes.String, default: "" },
			status: { type: DataTypes.String, default: "underAnalysis" },
			dataCriacao: {
				type: DataTypes.Date,
				default: Date.now(),
				get: (value) => {
					return value && isDate(value) ? new Date(value as any) : new Date();
				},
			},
			dataModificacao: {
				type: DataTypes.Date,
				default: Date.now(),
				get: (value) => {
					return value && isDate(value) ? new Date(value as any) : new Date();
				},
			},
			totalValue: { type: DataTypes.Number, default: 0 },
			currencyType: { type: DataTypes.String, default: "USD" },
			information: { type: DataTypes.Object },
			apiType: { enum: ["api", "testnet", "invalid"], default: "invalid" },
		});

		/** @readonly*/
		/** @type {number} */
		this.addInvisibleProperty("totalValue");

		/** @readonly*/
		/** @type {string} */
		this.addInvisibleProperty("currencyType");

		/** @readonly */
		/** @type {object} */
		this.addInvisibleProperty("information");
	}

	getWalletId(): string {
		if (typeof this.path !== "string") {
			return "";
		}
		let code = this.path.split("/").pop();
		return typeof code !== "string" || code.length !== 18 ? "" : code;
	}

	/** getWalletCode - número padrão iVip de registro da carteira */
	getWalletCode(): string {
		let code = this.getWalletId();
		if (typeof code !== "string" || code.trim().length !== 18) {
			return "";
		}
		let format = "";
		for (let i = 0; i < 18; i += 4) {
			format += code.substring(i, i + 4) + (format.length / 5 <= 3 ? (format.length / 5 == 3 ? "-" : ".") : "");
		}
		return format;
	}

	/** getWalletCode - número padrão iVip de registro da carteira */
	static getWalletCode(id: string): string {
		if (typeof id !== "string" || id.trim().length !== 18) {
			return "";
		}
		let format = "";
		for (let i = 0; i < 18; i += 4) {
			format += id.substring(i, i + 4) + (format.length / 5 <= 3 ? (format.length / 5 == 3 ? "-" : ".") : "");
		}
		return format;
	}

	/**
	 * walletStatusList - lista de tipos de defição de carteira reconhecidos no sistema iVip
	 * @returns {{"underAnalysis": "underAnalysis", "valid": "valid", "invalid": "invalid"}}
	 */
	static walletStatusList(): { [type in WalletStatus]: string } {
		return {
			underAnalysis: "underAnalysis",
			valid: "valid",
			invalid: "invalid",
		};
	}

	/**
	 * walletTypeList - lista de tipos de defição de carteira reconhecidos no sistema iVip
	 * @returns {{IVIPCOIN: "iVipCoin", IVIPBANK: "iVipBank", "BINANCE": "Binance", CM_CAPITAL: "CM Capital", "GATE": "Gate.io", "OKX": "OKX", "KUCOIN": "KuCoin", "TRUSTWALLET": "Trust Wallet", "METAMASK": "MetaMask"}}
	 */
	static walletTypeList(): { [type in walletType]: string } {
		return {
			IVIPCOIN: "iVipCoin",
			IVIPBANK: "iVipBank",
			BINANCE: "Binance",
			CM_CAPITAL: "CM Capital",
			GATE: "Gate.io",
			OKX: "OKX",
			KUCOIN: "KuCoin",
			TRUSTWALLET: "Trust Wallet",
			METAMASK: "MetaMask",
		};
	}

	static walletCategoryList(): { [type in walletType]: { label: string; value: string } } {
		return {
			IVIPCOIN: { label: "Carteira", value: "wallet" },
			IVIPBANK: { label: "Banco", value: "bank" },
			BINANCE: { label: "Corretora", value: "exchange" },
			CM_CAPITAL: { label: "Corretora", value: "exchange" },
			GATE: { label: "Corretora", value: "exchange" },
			OKX: { label: "Corretora", value: "exchange" },
			KUCOIN: { label: "Corretora", value: "exchange" },
			TRUSTWALLET: { label: "Carteira", value: "wallet" },
			METAMASK: { label: "Carteira", value: "wallet" },
		};
	}

	/**
	 * validType - verificador do tipo de defição de carteira reconhecido no sistema iVip
	 * @param  {string} type
	 * @returns {boolean}
	 */
	static validType(type: string): boolean {
		return typeof type === "string" && Object.keys(Wallet.walletTypeList()).includes(type);
	}

	//https://3commas.io/pt/exchanges

	/**
	 * walletIcon - ícone de cada tipo de carteira
	 * @param {Props} type - tipo de carteira
	 * @type {React.ComponentClass<SvgIcon>}
	 */
	static walletIcon({ type, ...props }: { [prop: string]: any; type: walletType }): JSX.Element | null {
		if (Wallet.validType(type) !== true) {
			return null;
		}

		switch (type) {
			case "IVIPCOIN": {
				return (
					<SvgIcon
						viewBox="-78 -9.5 500 500"
						{...props}
					>
						<path
							fill="#FF6D00"
							d="M78.73,215.08h89.41l46.94,164.28L203.9,419.59h8.94l82.7-285h87.17L292.19,431.89c-1.68,5-7.63,27.47-28.5,43A77.15,77.15,0,0,1,219.55,490a77.88,77.88,0,0,1-46.38-16.2c-19.85-15.43-24.59-34.65-27.38-41.91Q112.25,323.49,78.73,215.08Z"
							transform="translate(-78.05 -9.5)"
							strokeMiterlimit="10"
						/>
						<polygon
							fill="#FF6D00"
							points="322.88 66.44 310.81 105.55 222.52 105.55 234.17 66.44 322.88 66.44"
							strokeMiterlimit="10"
						/>
						<polygon
							fill="#FF6D00"
							points="335.28 26.2 329.08 46.32 240.16 46.32 246.15 26.2 335.28 26.2"
							strokeMiterlimit="10"
						/>
						<polygon
							fill="#FF6D00"
							points="343.22 0.5 341.5 6.09 252.14 6.09 253.81 0.5 343.22 0.5"
							strokeMiterlimit="10"
						/>
					</SvgIcon>
				);
			}
			case "IVIPBANK": {
				return (
					<SvgIcon
						viewBox="0 0 247.95 247.95"
						{...props}
					>
						<rect
							width="247.95"
							height="247.95"
							rx="55.71"
							fill="#ed6c0c"
						/>
						<path
							d="M223,81.19c0,65.24-49.35,118.13-110.22,118.13-1.1,0-2.19,0-3.27,0V68.63H222.4Q223,74.83,223,81.19Z"
							fill="#593d90"
						/>
						<path
							d="M95.65,68.63v80a64.72,64.72,0,0,1-59.6-39.47c-.23-.53-.45-1.06-.66-1.6a64.49,64.49,0,0,1-4.46-23.64V68.63Z"
							fill="#593d90"
						/>
						<path
							d="M95.65,164.33v34.81a64.7,64.7,0,0,1-64.72-64.71,66.73,66.73,0,0,1,.39-7.11C34.86,148.13,62.31,164.33,95.65,164.33Z"
							fill="#593d90"
						/>
					</SvgIcon>
				);
			}
			case "BINANCE": {
				return (
					<SvgIcon
						viewBox="0 0 126.611 126.611"
						{...props}
					>
						<polygon
							fill="#F3BA2F"
							points="38.171,53.203 62.759,28.616 87.36,53.216 101.667,38.909 62.759,0 23.864,38.896 "
						/>
						<rect
							x="3.644"
							y="53.188"
							transform="matrix(0.7071 0.7071 -0.7071 0.7071 48.7933 8.8106)"
							fill="#F3BA2F"
							width="20.233"
							height="20.234"
						/>
						<polygon
							fill="#F3BA2F"
							points="38.171,73.408 62.759,97.995 87.359,73.396 101.674,87.695 101.667,87.703 62.759,126.611   23.863,87.716 23.843,87.696 "
						/>
						<rect
							x="101.64"
							y="53.189"
							transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 235.5457 29.0503)"
							fill="#F3BA2F"
							width="20.234"
							height="20.233"
						/>
						<polygon
							fill="#F3BA2F"
							points="77.271,63.298 77.277,63.298 62.759,48.78 52.03,59.509 52.029,59.509 50.797,60.742 48.254,63.285   48.254,63.285 48.234,63.305 48.254,63.326 62.759,77.831 77.277,63.313 77.284,63.305 "
						/>
					</SvgIcon>
				);
			}
			case "CM_CAPITAL": {
				return (
					<SvgIcon
						viewBox="0 0 247.95 247.95"
						{...props}
					>
						<rect
							width="247.95"
							height="247.95"
							rx="55.71"
							fill="#21689d"
						/>
						<path
							d="M124,37.28h54.73a4.83,4.83,0,0,1,5,3.62,7,7,0,0,1,.21,1.72q0,50.85,0,101.7a5,5,0,1,1-9.93.51c0-1.07,0-2.14,0-3.2q0-44.38,0-88.76a5.54,5.54,0,0,0-1.51-4.18,4.75,4.75,0,0,0-3.45-1.46h-.72q-44.31,0-88.61,0a7.63,7.63,0,0,0-2.48.35,5,5,0,0,0-3.18,4.77c0,5.87,0,11.73,0,17.59q0,37.18,0,74.37a5.06,5.06,0,0,1-3.86,5.12,4.93,4.93,0,0,1-6.1-4.72V42.58a4.8,4.8,0,0,1,2.66-4.71,6.63,6.63,0,0,1,2.72-.57q18.61,0,37.22,0Z"
							fill="#fff"
						/>
						<path
							d="M199,177.48a7.18,7.18,0,0,1-4.79,7.07,6.78,6.78,0,0,1-7.58-2,61.29,61.29,0,0,0-13.2-11.75,17.11,17.11,0,0,0-23.89,4.48,30.43,30.43,0,0,1-17.68,12.18,31.13,31.13,0,0,1-33.22-11.94,17.58,17.58,0,0,0-10.77-7.34,16.47,16.47,0,0,0-12.67,2.23,57,57,0,0,0-13.42,11.7A7.46,7.46,0,0,1,57.06,185a7.3,7.3,0,0,1-7.91-5.36,6.38,6.38,0,0,1,1.17-5.7,68.82,68.82,0,0,1,19.37-16.59,27.9,27.9,0,0,1,12.59-3.58c11.57-.55,20.76,3.93,27.59,13.23a16.92,16.92,0,0,0,11.62,7.24,16.47,16.47,0,0,0,16.42-7,31.55,31.55,0,0,1,15.31-11.75,30.38,30.38,0,0,1,27,2.95,70.55,70.55,0,0,1,17.18,15.1A6.34,6.34,0,0,1,199,177.48Z"
							fill="#fff"
						/>
						<path
							d="M93.05,107.86c0-3.29-.1-6.59,0-9.88a35.74,35.74,0,0,1,6.32-20c5-7.06,11.69-11.5,20.33-12.67,7.82-1.06,15.49-.43,22.67,3.07,7.75,3.76,12.48,9.92,13.82,18.52a55.45,55.45,0,0,1,.41,5.63c0,.21-.24.61-.36.61a37.93,37.93,0,0,1-6.5-.28,9.84,9.84,0,0,1-7.55-6.67c-2.16-5.9-6.44-9-12.47-9.91a21.15,21.15,0,0,0-10.12.52c-4.46,1.49-7.45,4.66-9.62,8.69a24.79,24.79,0,0,0-3.06,11.24c-.15,6.94-.24,13.9-.11,20.85A25.8,25.8,0,0,0,112,133.13a16,16,0,0,0,12,6.55,22.68,22.68,0,0,0,9.64-1.18,13.31,13.31,0,0,0,8.35-8.32c1.15-3.18,3-5.71,6.24-7a15.45,15.45,0,0,1,4-.82c1.25-.14,2.52,0,3.78,0,.5,0,.7.16.7.66,0,6.78-1.76,13-6.38,18.12a28.31,28.31,0,0,1-16.4,8.91,44.27,44.27,0,0,1-9.75.71c-15.77-.53-25.43-10.71-29.16-22a38.78,38.78,0,0,1-1.89-11.84c0-3,0-6,0-9Z"
							fill="#fff"
						/>
						<path
							d="M124,210.67H69.24a4.84,4.84,0,0,1-4.87-3.5,6,6,0,0,1-.24-1.57q0-6.79,0-13.59a5.16,5.16,0,0,1,4.32-5.16,4.92,4.92,0,0,1,5.6,4.56c0,1.38,0,2.76,0,4.14a5.06,5.06,0,0,0,5.2,5.18h89.55A5.08,5.08,0,0,0,174,195.6c0-1.33,0-2.67,0-4a4.83,4.83,0,0,1,4.38-4.7,5,5,0,0,1,5.42,4.08,11.23,11.23,0,0,1,.13,1.74c0,4.21,0,8.43,0,12.65a4.84,4.84,0,0,1-3.06,4.89,6.23,6.23,0,0,1-2.26.4Q151.33,210.69,124,210.67Z"
							fill="#fff"
						/>
					</SvgIcon>
				);
			}
			case "GATE": {
				return (
					<SvgIcon
						viewBox="0 0 560 560.04"
						{...props}
					>
						<path
							d="m219.42 275.6-4.86-4.88a5.85 5.85 0 0 0 -10 4.13v69.72a5.85 5.85 0 0 0 5.87 5.84h69.73a5.83 5.83 0 0 0 4.12-10 5.85 5.85 0 0 1 0-8.25l74.18-74.38a5.84 5.84 0 0 0 0-8.26l-6.76-6.73a5.84 5.84 0 0 1 4.13-10h69.7a5.86 5.86 0 0 1 5.86 5.84v69.72a5.86 5.86 0 0 1 -10 4.13l-4.71-4.69a5.7 5.7 0 0 0 -8.24 0l-125 125a5.89 5.89 0 0 1 -8.27 0l-153-153a5.83 5.83 0 0 1 0-8.28l211.83-211.74a5.89 5.89 0 0 0 0-8.27l-49.86-49.8a5.87 5.87 0 0 0 -8.27 0l-274.17 274.2a5.87 5.87 0 0 0 0 8.27l274.17 274.17a5.87 5.87 0 0 0 8.27 0l274.16-274.17a5.85 5.85 0 0 0 0-8.25l-161.3-161.3a5.86 5.86 0 0 0 -8.26 0l-161 161a5.83 5.83 0 0 1 -8.28 0z"
							fill="#de5959"
						/>
					</SvgIcon>
				);
			}
			case "OKX": {
				return (
					<SvgIcon
						viewBox="0 0 1080 1080"
						{...props}
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M425.465 236H249.533c-7.474 0-13.533 6.059-13.533 13.533v175.932c0 7.475 6.059 13.534 13.533 13.534h175.932c7.475 0 13.534-6.059 13.534-13.534V249.533c0-7.474-6.059-13.533-13.534-13.533zm203.087 202.999H452.62c-7.475 0-13.534 6.059-13.534 13.533v175.932c0 7.474 6.059 13.533 13.534 13.533h175.932c7.474 0 13.533-6.059 13.533-13.533V452.532c0-7.474-6.059-13.533-13.533-13.533zM655.532 236h175.932c7.474 0 13.533 6.059 13.533 13.533v175.932c0 7.475-6.059 13.534-13.533 13.534H655.532c-7.475 0-13.534-6.059-13.534-13.534V249.533c0-7.474 6.059-13.533 13.534-13.533zM425.465 641.998H249.533c-7.474 0-13.533 6.059-13.533 13.534v175.932c0 7.474 6.059 13.533 13.533 13.533h175.932c7.475 0 13.534-6.059 13.534-13.533V655.532c0-7.475-6.059-13.534-13.534-13.534zm230.067 0h175.932c7.474 0 13.533 6.059 13.533 13.534v175.932c0 7.474-6.059 13.533-13.533 13.533H655.532c-7.475 0-13.534-6.059-13.534-13.533V655.532c0-7.475 6.059-13.534 13.534-13.534z"
							fill="#000"
						/>
					</SvgIcon>
				);
			}
			case "KUCOIN": {
				return (
					<SvgIcon
						viewBox="0 0 537 587"
						{...props}
					>
						<g>
							<g transform="matrix(.847 0 0 .847 103 166)">
								<path
									d="M-62.5-137.3c32.5 0 58.9 26.4 58.9 58.9v457.7c0 32.5-26.4 58.9-58.9 58.9s-58.9-26.4-58.9-58.9V-78.4c.1-32.5 26.4-58.9 58.9-58.9z"
									fill="#0097e8"
								/>
								<path
									d="M317.8-172.9c22.9 23.1 22.7 60.4-.4 83.2L-8 232.3c-23.1 22.9-60.4 22.7-83.2-.4-22.9-23.1-22.7-60.4.4-83.2l325.4-321.9c23.1-23 60.4-22.8 83.2.3z"
									fill="#0097e8"
								/>
								<path
									d="M317.8 473.4c22.9-23.1 22.7-60.4-.4-83.2L-8 68.3c-23.1-22.9-60.4-22.7-83.2.4-22.9 23.1-22.7 60.4.4 83.2l325.4 321.9c23.1 23 60.4 22.8 83.2-.4z"
									fill="#0097e8"
								/>
								<path
									d="M495.3 226.3c22.9 23.1 22.8 60.4-.2 83.3L323.9 480c-23.1 22.9-60.4 22.8-83.3-.2-22.9-23.1-22.8-60.4.2-83.3L412 226.1c23-22.9 60.3-22.8 83.3.2z"
									fill="#0097e8"
								/>
								<path
									d="M495.3 75c22.9-23.1 22.8-60.4-.2-83.3L323.9-178.7c-23.1-22.9-60.4-22.8-83.3.2-22.9 23.1-22.8 60.4.2 83.3L412 75.2c23.1 22.9 60.4 22.9 83.3-.2z"
									fill="#0097e8"
								/>
								<circle
									cx="283"
									cy="151"
									r="59"
									fill="#0097e8"
								/>
							</g>
						</g>
					</SvgIcon>
				);
			}
			case "TRUSTWALLET": {
				return (
					<SvgIcon
						viewBox="0 0 1024 1024"
						{...props}
					>
						<path
							d="M0 260C0 116.406 116.406 0 260 0H764C907.594 0 1024 116.406 1024 260V764C1024 907.594 907.594 1024 764 1024H260C116.406 1024 0 907.594 0 764V260Z"
							fill="white"
						/>
						<path
							d="M512.3 215C615.619 301.288 734.101 295.966 767.953 295.966C760.548 786.707 704.128 689.395 512.3 827C320.472 689.395 264.405 786.707 257 295.966C290.499 295.966 408.981 301.288 512.3 215Z"
							stroke="#3375BB"
							strokeWidth="70"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</SvgIcon>
				);
			}
			case "METAMASK": {
				return (
					<SvgIcon
						viewBox="0 0 256 240"
						{...props}
						preserveAspectRatio="xMidYMid"
					>
						<polygon
							fill="#E17726"
							points="250.066018 -8.89651791e-15 140.218553 81.2793133 160.645643 33.3787726"
						/>
						<polygon
							fill="#E27625"
							points="6.19062016 0.0955267053 95.3715526 33.38465 114.767923 81.9132784"
						/>
						<polygon
							fill="#E27625"
							points="205.859986 172.858026 254.410647 173.782023 237.442988 231.424252 178.200429 215.112736"
						/>
						<polygon
							fill="#E27625"
							points="50.1391619 172.857971 77.6964289 215.11288 18.5530579 231.425317 1.68846828 173.782036"
						/>
						<polygon
							fill="#E27625"
							points="112.130724 69.5516472 114.115388 133.635085 54.744344 130.933905 71.6319541 105.456448 71.8456974 105.210668"
						/>
						<polygon
							fill="#E27625"
							points="143.254237 68.8369186 184.153999 105.213392 184.365514 105.45719 201.253537 130.934656 141.89632 133.635226"
						/>
						<polygon
							fill="#E27625"
							points="79.4347776 173.043957 111.853145 198.302774 74.1951401 216.484384"
						/>
						<polygon
							fill="#E27625"
							points="176.57078 173.040009 181.701672 216.484523 144.149363 198.301203"
						/>
						<polygon
							fill="#D5BFB2"
							points="144.977922 195.921642 183.084879 214.373531 147.637779 231.220354 148.005818 220.085704"
						/>
						<polygon
							fill="#D5BFB2"
							points="111.01133 195.929982 108.102093 219.90359 108.340838 231.207237 72.8105145 214.373665"
						/>
						<polygon
							fill="#233447"
							points="100.007166 141.998856 109.965172 162.926822 76.0615945 152.995277"
						/>
						<polygon
							fill="#233447"
							points="155.991579 142.000941 180.049716 152.994594 146.03608 162.923638"
						/>
						<polygon
							fill="#CC6228"
							points="82.0263962 172.830401 76.5459821 217.870023 47.1731221 173.814952"
						/>
						<polygon
							fill="#CC6228"
							points="173.976111 172.8305 208.830462 173.815081 179.347016 217.871514"
						/>
						<polygon
							fill="#CC6228"
							points="202.112267 128.387342 176.746779 154.238424 157.190334 145.301352 147.82685 164.985265 141.688645 131.136429"
						/>
						<polygon
							fill="#CC6228"
							points="53.8753865 128.386879 114.309585 131.136429 108.17138 164.985265 98.8061425 145.303856 79.3525107 154.238823"
						/>
						<polygon
							fill="#E27525"
							points="52.165606 123.082486 80.8639084 152.203386 81.8584812 180.952278"
						/>
						<polygon
							fill="#E27525"
							points="203.863346 123.029784 174.117491 181.003017 175.237428 152.202737"
						/>
						<polygon
							fill="#E27525"
							points="112.906762 124.855691 114.061658 132.125682 116.915771 150.236518 115.080954 205.861884 106.405804 161.177486 106.402953 160.71542"
						/>
						<polygon
							fill="#E27525"
							points="143.077997 124.755417 149.599051 160.715451 149.596194 161.177486 140.899333 205.973714 140.55515 194.76913 139.198167 149.907127"
						/>
						<polygon
							fill="#F5841F"
							points="177.788479 151.045975 176.81718 176.023897 146.543342 199.61119 140.4233 195.28712 147.283427 159.951634"
						/>
						<polygon
							fill="#F5841F"
							points="78.3167053 151.046455 108.716464 159.952427 115.576437 195.28712 109.456385 199.611197 79.1807344 176.021881"
						/>
						<polygon
							fill="#C0AC9D"
							points="67.0180978 208.857597 105.750143 227.209502 105.586194 219.372868 108.826835 216.528328 147.160694 216.528328 150.518758 219.363342 150.271375 227.194477 188.757733 208.903978 170.030292 224.379509 147.384611 239.933315 108.516484 239.933315 85.8855503 224.315941"
						/>
						<polygon
							fill="#161616"
							points="142.203502 193.479367 147.679764 197.347701 150.888964 222.952494 146.244706 219.030957 109.769299 219.030957 105.213447 223.031398 108.317268 197.349663 113.795429 193.479367"
						/>
						<polygon
							fill="#763E1A"
							points="242.814251 2.24978946 256 41.8072765 247.765337 81.803692 253.629038 86.3274221 245.694407 92.3812097 251.657525 96.9865879 243.761206 104.178247 248.609106 107.688972 235.743366 122.714803 182.973386 107.350364 182.516079 107.105244 144.488982 75.0267414"
						/>
						<polygon
							fill="#763E1A"
							points="13.1860054 2.24978557 111.51151 75.0267402 73.4844118 107.105244 73.0271023 107.350365 20.2567388 122.714804 7.39121291 107.688927 12.2352706 104.180751 4.34251001 96.9865923 10.2945566 92.3862179 2.24133703 86.315099 8.32629691 81.7886671 -8.89651791e-15 41.8087534"
						/>
						<polygon
							fill="#F5841F"
							points="180.391638 103.990363 236.304873 120.269177 254.470245 176.254719 206.546445 176.25462 173.525532 176.671282 197.539657 129.863284"
						/>
						<polygon
							fill="#F5841F"
							points="75.6080363 103.990376 58.4568191 129.863284 82.4741865 176.671282 49.4693913 176.254719 1.63053271 176.254719 19.6938968 120.269548"
						/>
						<polygon
							fill="#F5841F"
							points="163.383898 33.1117385 147.744691 75.3505047 144.425852 132.411352 143.155934 150.295986 143.055195 195.983514 112.943788 195.983514 112.846176 150.381702 111.572114 132.395585 108.251786 75.3505047 92.6150854 33.1117385"
						/>
					</SvgIcon>
				);
			}
			default:
				return null;
		}
	}

	static getMethodRenderBy(name: OrderInfoType | "main"): {
		icon: JSX.Element;
		label: string;
	} {
		const methods: {
			[type in OrderInfoType | "main"]: {
				icon: JSX.Element;
				label: string;
			};
		} = {
			main: {
				icon: <SvgIconCunstom path={mdiBorderNoneVariant} />,
				label: "...",
			},
			transfer: {
				icon: <SvgIconCunstom path={mdiCashFast} />,
				label: "Transfer",
			},
			deposit: {
				icon: <SvgIconCunstom path={mdiCashFast} />,
				label: "Depósito",
			},
			swap: {
				icon: <SvgIconCunstom path={mdiSwapVerticalCircle} />,
				label: "SWAP",
			},
			emprestimo: {
				icon: <SvgIconCunstom path={mdiHandCoin} />,
				label: "Empréstimo",
			},
			staking: {
				icon: <SvgIconCunstom path={mdiChartLineVariant} />,
				label: "Staking",
			},
			system: {
				icon: <SvgIconCunstom path={mdiNetworkPos} />,
				label: "Sistema",
			},
			wallet: {
				icon: <SvgIconCunstom path={mdiWallet} />,
				label: "Carteira",
			},
		};

		return methods[name in methods ? name : "main"];
	}
}

export default Wallet;
