import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Typography,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'

import style from './style.module.scss'
import { Usuario } from 'Models'
import { ProfilePicture } from 'Components'

export default function PerfilUsuario() {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [userList, setUserList] = useState([])
  const [user, setUser] = useState(null)
  const history = useHistory()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await APIHelper.fetch(
          'user/get_all_users',
          {
            props_list: [
              'nome',
              'email',
              'fotoPerfil',
              'telefone',
              'atSign',
              'dataCriacao',
              'permissoes',
              'address',
              'points',
              'descricao',
              'wallets',
              'referenceCode',
              'referenceBy',
            ],
          },
          600
        )
        const responseArray = responseData.map((item) =>
          new Usuario().parse(null, item)
        )
        setUserList(responseArray)
        setLoading(false)
      } catch (error) {
        console.error(error.message)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const getUser = () => {
      if (userList.length > 0) {
        const filteredUser = userList.filter((user) => user.atSign === id)
        if (filteredUser.length > 0) {
          setUser(filteredUser[0])
        }
      }
    }
    getUser()
  }, [id, userList])

  return (
    <div className={style['root']}>
      <div className={style['main-content']}>
        <div className={style['main-content-header']}>
          <ArrowCircleLeftIcon
            sx={{
              fontSize: 60,
            }}
            onClick={() => {
              history.goBack()
            }}
            className={style['main-content-header-arrow']}
          />
          <Typography
            className={style['user_title']}
            variant="h4"
            component="h1"
            gutterBottom
          >
            Perfil do Usuário
          </Typography>
        </div>
        {loading ? (
          <Box className={style['loading']} sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        ) : (
          <div className={style['profile_content']}>
            <div className={style['profile_content_image']}>
              {user && <ProfilePicture src={user} size={140} />}
            </div>
            <div className={style['profile_content_infos']}>
              <div>
                <Typography
                  className={style['profile_info']}
                  variant="h6"
                  gutterBottom
                >
                  <span id="name">Nome:</span> {user?.nome}
                </Typography>
                <Typography
                  className={style['profile_info']}
                  variant="body1"
                  gutterBottom
                >
                  <span id="surname">Apelido:</span> @{user?.atSign}
                </Typography>
                <Typography
                  className={style['profile_info']}
                  variant="body1"
                  gutterBottom
                >
                  <span id="email">Email:</span> {user?.email}
                </Typography>
                <Typography
                  className={style['profile_info']}
                  variant="body1"
                  gutterBottom
                >
                  <span id="phone">Telefone:</span> {user?.telefone}
                </Typography>
                <Typography
                  className={style['profile_info']}
                  variant="body1"
                  gutterBottom
                >
                  <span id="createdAt">Data de Criação: </span>
                  {user?.dataCriacao?.toLocaleString()}
                </Typography>
                <Typography
                  className={style['profile_info']}
                  variant="body1"
                  gutterBottom
                >
                  <span id="points">Experiência:</span> {user?.points}
                </Typography>
              </div>
              <div>
                <Typography
                  className={style['profile_info']}
                  variant="body1"
                  gutterBottom
                >
                  <span id="description">Descrição:</span> {user?.descricao}
                </Typography>
                <Typography
                  className={style['profile_info']}
                  variant="body1"
                  gutterBottom
                >
                  <span id="permissions">Permissões:</span>{' '}
                  {Array.isArray(user?.permissoes)
                    ? user.permissoes.join(', ')
                    : user?.permissoes}
                </Typography>
                <Typography
                  className={style['profile_info']}
                  variant="body1"
                  gutterBottom
                >
                  <span id="address">Endereço: </span>
                </Typography>
                <Typography
                  className={style['profile_info']}
                  variant="body1"
                  gutterBottom
                >
                  <span id="city">Cidade:</span> {user?.address?.municipio}
                </Typography>
                <Typography
                  className={style['profile_info']}
                  variant="body1"
                  gutterBottom
                >
                  <span id="state">Estado:</span> {user?.address?.estado}
                </Typography>
                <Typography
                  className={style['profile_info']}
                  variant="body1"
                  gutterBottom
                >
                  <span id="country">País:</span> {user?.address?.pais}
                </Typography>
              </div>
              <div>
                <Typography
                  className={style['profile_info']}
                  variant="body1"
                  gutterBottom
                >
                  <span id="cep">CEP:</span> {user?.address?.cep}
                </Typography>
                <Typography
                  className={style['profile_info']}
                  variant="body1"
                  gutterBottom
                >
                  <span id="street">Logradouro:</span>{' '}
                  {user?.address?.logradouro}
                </Typography>
                <Typography
                  className={style['profile_info']}
                  variant="body1"
                  gutterBottom
                >
                  <span id="number">Número:</span> {user?.address?.numero}
                </Typography>
                <Typography
                  className={style['profile_info']}
                  variant="body1"
                  gutterBottom
                >
                  <span id="complement">Complemento:</span>{' '}
                  {user?.address?.complemento}
                </Typography>
                <Typography
                  className={style['profile_info']}
                  variant="body1"
                  gutterBottom
                >
                  <span id="neighborhood">Bairro:</span> {user?.address?.bairro}
                </Typography>
              </div>
            </div>
            {user && (
              <div>
                <Typography
                  className={style['wallets']}
                  variant="h4"
                  component="h1"
                  gutterBottom
                >
                  Carteiras
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>Número</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {user.wallets.map((carteira, index) => (
                        <TableRow key={index}>
                          <TableCell>{carteira.split('/')[0]}</TableCell>
                          <TableCell>
                            {carteira.substring(carteira.indexOf('/') + 1)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
