import { TextField } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";

interface IProps {
	numberOfDigits?: number;
	value?: string;
	onChange?: (otp: string) => void;
}

const OtpInputWithValidation: React.FC<IProps> = ({ numberOfDigits = 6, value = "", onChange }) => {
	const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
	const otpBoxReference = useRef<HTMLInputElement[]>([]);

	useEffect(() => {
		if (value) {
			const oltValue = value.replace(/\D/g, "").split("");
			let newArr = [...otp];
			for (let i = 0; i < oltValue.length; i++) {
				newArr[i] = oltValue[i];
			}
			setOtp(newArr);
		}
	}, [value]);

	const handleChange = (value: string, index: number) => {
		value = value.replace(/\D/g, "");
		value = value.length > 1 ? value.substr(-1) : value;

		let newArr = [...otp];
		newArr[index] = value;
		setOtp(newArr);

		if (value && index < numberOfDigits - 1) {
			otpBoxReference.current[index + 1].focus();
		} else if (!value && index > 0) {
			otpBoxReference.current[index - 1].focus();
		}
	};

	const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
		if (e.key === "Backspace" && !otpBoxReference.current[index].value && index > 0) {
			otpBoxReference.current[index - 1].focus();
		}
		if (e.key === "Enter" && otpBoxReference.current[index].value && index < numberOfDigits - 1) {
			otpBoxReference.current[index + 1].focus();
		}
	};

	const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>, index: number) => {
		e.preventDefault();
		let pastedData = e.clipboardData.getData("text").replace(/\D/g, "");
		const inputs = otpBoxReference.current;
		const values = [...otp];

		for (let i = index; i < inputs.length; i++) {
			if (pastedData.length > 0) {
				inputs[i].value = pastedData[0];
				values[i] = pastedData[0];
				pastedData = pastedData.substring(1);
				index = i;
			}
		}
		otpBoxReference.current[index].focus();

		setOtp(values);
	};

	useEffect(() => {
		if (typeof onChange === "function") {
			onChange(otp.join(""));
		}
	}, [onChange, otp]);

	return (
		<div
			style={{
				display: "grid",
				gridTemplateColumns: "repeat(auto-fit, minmax(50px, 1fr))",
				gridAutoRows: "50px",
				gridGap: "10px",
			}}
		>
			{otp.map((digit, index) => (
				<TextField
					key={index}
					value={digit}
					onChange={(e) => {
						handleChange(e.target.value, index);
					}}
					onKeyUp={(e) => {
						handleKeyUp(e, index);
					}}
					onPaste={(e) => {
						handlePaste(e, index);
					}}
					InputProps={{
						inputRef: (reference) => {
							(otpBoxReference as any).current[index] = reference;
						},
						type: "tel",
					}}
					size="small"
					sx={{
						width: "40px",
						boxSizing: "border-box",
						margin: "0px auto",
					}}
					autoComplete="off"
					placeholder="0"
				/>
			))}
		</div>
	);
};

export default OtpInputWithValidation;
