import { APIHelper, InteractionHelper } from "Helper";
import { ListParConvertWallet, ParConvertWallet, ParInfo } from "Types/Swap";
import { walletType } from "Types/Wallet";
import React, { useState, useLayoutEffect, useRef, useEffect, useCallback } from "react";

import style from "./style.module.scss";
import { Typography, CircularProgress, InputBase, Paper, Popper, Grow, ClickAwayListener, Box, TextField, Button } from "@mui/material";
import Icons from "Components/Icons";
import { SelectList, SvgIcon } from "Components";
import { mdiArrowLeft, mdiArrowRight, mdiSwapHorizontal } from "@mdi/js";
import { useResizeObserver } from "@react-hookz/web";
import { currencyFormat } from "Utils";
import { TramRounded } from "@mui/icons-material";
import { useDataStorager } from "Utils/MultiStorager";

const SimulateSwap: React.FC<{
	walletId: string;
	walletType: walletType;
	from: string;
	to: string;
	validateBalance?: boolean;
	convertible?: boolean;
	invertible?: boolean;
	tradeButton?: boolean;
	onChange?: (symbol: string[], quantity: number, amount: number, type: "BUY" | "SELL") => void;
	onApllyOrder?: (symbol: string[], quantity: number, amount: number, type: "BUY" | "SELL") => void;
}> = ({
	walletId,
	walletType,
	from: defaultFrom,
	to: defaultTo,
	validateBalance = false,
	convertible,
	invertible,
	tradeButton = false,
	onChange,
	onApllyOrder,
}) => {
	const [from, setFrom] = useState<string>(defaultFrom.toUpperCase().trim());
	const [nameFrom, setNameFrom] = useState<string>("");
	const [to, setTo] = useState<string>(defaultTo.toUpperCase().trim());
	const [nameTo, setNameTo] = useState<string>("");

	const [loading, setLoading] = useState<boolean>(true);

	const [balanceAvailableFrom, setBalanceAvailableFrom] = useState<number>(0);
	const [balanceAvailableTo, setBalanceAvailableTo] = useState<number>(0);

	const [value, setValue] = useState<number | string>(0);
	const [convertValue, setConvertValue] = useState<number>(0);

	const [counting, setCounting] = useState<number>(0);
	const [isConversion, setIsConversion] = useState<boolean>(true);

	const [conversionList, setConversionList] = useDataStorager<ListParConvertWallet>("swap_list_par_convert_wallet", {});
	const [countingList, setCountingList] = useState<{
		[symbol: string]: ParInfo;
	}>({});

	const [openPopper, setOpenPopper] = useState<boolean>(false);
	const [openPopperTo, setOpenPopperTo] = useState<number>(0);

	const [searchCurrency, setSearchCurrency] = useState<string>("");

	const selectSwapRef = useRef<HTMLDivElement | null>(null);

	useResizeObserver(selectSwapRef, (e) => {
		if (e.contentRect.width > 600 && e.target.classList.contains(style.column)) {
			e.target.classList.remove(style.column);
		} else if (e.contentRect.width <= 600 && e.target.classList.contains(style.column) !== true) {
			e.target.classList.add(style.column);
		}
	});

	const updateListTo = () => {
		if (loading) {
			return;
		}
		setLoading(true);

		APIHelper.fetch<{ [symbol: string]: ParInfo }>(
			`swap/list_convert/${from}`,
			{
				walletType,
				walletId,
			},
			5,
		)
			.then((list) => {
				setCountingList(() => list);
				setTo((to) => {
					if (!(to in list) || (to in list && list[to].disabled === true)) {
						const t = Object.values(list).find(({ disabled }) => disabled !== true);
						return t?.symbol ?? "";
					}
					return to;
				});
			})
			.catch(() => {
				InteractionHelper.toast("Algo deu errado, verifique sua conexão e tente novamente!", "[swap:01]", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useLayoutEffect(() => {
		setLoading(true);
		setValue(0);
		setConvertValue(0);
		setCounting(0);
		setIsConversion(true);
		setBalanceAvailableFrom(0);
		setBalanceAvailableTo(0);

		APIHelper.fetch<ListParConvertWallet>(`swap/${walletId}/list_currency`, {}, 5)
			.then((list) => {
				setConversionList(list);
				setFrom((from) => {
					if (!list[from] || list[from].disabled) {
						const info = Object.values(list).find(({ disabled }) => !disabled);
						if (info) {
							return info.symbol;
						}
					}

					return from;
				});
			})
			.catch(() => {
				InteractionHelper.toast("Algo deu errado, verifique sua conexão e tente novamente!", "[swap:01]", "error");
			})
			.finally(() => {
				setLoading(false);
				updateListTo();
			});
	}, [walletId, defaultFrom]);

	useEffect(() => {
		updateListTo();
	}, [from, conversionList]);

	useEffect(() => {
		const value1: ParConvertWallet | undefined = Object.values(conversionList).find(
			({ symbol }) => String(symbol).trim().toUpperCase() === from,
		) as any;

		const available = !value1 ? 0 : parseFloat(value1.available.toString() ?? "0") ?? 0;

		setValue(available);
		setNameFrom(!value1 ? "" : value1.name);

		setIsConversion(true);

		const value2: ParInfo | undefined = Object.values(countingList).find(({ symbol }) => String(symbol).trim().toUpperCase() === to);

		setCounting(!value2 ? 0 : value2.counting);
		setNameTo(!value2 ? "" : value2.name);
	}, [from, to, conversionList, countingList]);

	useEffect(() => {
		const availableFrom = Object.values(conversionList).reduce(
			(c, { symbol, available }) => c + (String(symbol).trim().toUpperCase() === from ? parseFloat(available.toString() ?? "0") : 0),
			0,
		);

		setBalanceAvailableFrom(availableFrom);

		const availableTo = Object.values(conversionList).reduce(
			(c, { symbol, available }) => c + (String(symbol).trim().toUpperCase() === to ? parseFloat(available.toString() ?? "0") : 0),
			0,
		);

		setBalanceAvailableTo(availableTo);
	}, [from, to, conversionList, isConversion]);

	useEffect(() => {
		const amount = isNaN(parseFloat(value as any)) ? 0 : parseFloat(value as any) ?? 0;
		setConvertValue(amount * (isConversion ? counting : 1 / counting));
	}, [value, counting, isConversion]);

	const getInfo = (): { symbol: string[]; quantity: number; amount: number; type: "BUY" | "SELL" } => {
		convertible = typeof convertible === "boolean" ? convertible : countingList[to]?.convertible;
		invertible = typeof invertible === "boolean" ? invertible : countingList[to]?.invertible;

		const type = convertible && !invertible ? "BUY" : "SELL";

		let v = isNaN(parseFloat(value as any)) ? 0 : parseFloat(value as any) ?? 0;
		v = v * (isConversion ? counting : 1 / counting);

		const symbol = type === "BUY" ? [from, to] : [to, from];
		const quantity = parseFloat((isConversion ? v / counting : v).toFixed(8));
		const amount = parseFloat((quantity * counting).toFixed(8));

		return {
			symbol,
			quantity: type === "BUY" ? quantity : amount,
			amount: type === "BUY" ? amount : quantity,
			type,
		};
	};

	useEffect(() => {
		if (typeof onChange === "function") {
			const { symbol, quantity, amount, type } = getInfo();
			onChange(symbol, quantity, amount, type);
		}
	}, [from, to, value, counting, isConversion, onChange, convertible, invertible, countingList]);

	const apllyOrder = (type: "BUY" | "SELL") => {
		return () => {
			if (typeof onApllyOrder === "function") {
				const { symbol, quantity, amount } = getInfo();
				onApllyOrder(symbol, quantity, amount, type);
			}
		};
	};

	convertible = typeof convertible === "boolean" ? convertible : countingList[to]?.convertible;
	invertible = typeof invertible === "boolean" ? invertible : countingList[to]?.invertible;

	const fromIsCurrencySystem = currencyFormat.indexOf(String(from).toUpperCase()) >= 0;
	const toIsCurrencySystem = currencyFormat.indexOf(String(to).toUpperCase()) >= 0;

	const renderValueBalanceAvailableFrom = currencyFormat.convert(balanceAvailableFrom, !fromIsCurrencySystem ? "USD" : String(from).toUpperCase());

	const renderValueBalanceAvailableTo = currencyFormat.convert(balanceAvailableTo, !toIsCurrencySystem ? "USD" : String(to).toUpperCase());

	const inputValueFrom = parseFloat(
		(isNaN(parseFloat((isConversion ? value : convertValue) as any)) ? 0 : parseFloat((isConversion ? value : convertValue) as any) ?? 0).toFixed(
			8,
		),
	);

	const inputValueTo = parseFloat(
		(isNaN(parseFloat((!isConversion ? value : convertValue) as any))
			? 0
			: parseFloat((!isConversion ? value : convertValue) as any) ?? 0
		).toFixed(8),
	);

	return (
		<div className={style.root}>
			{loading && (
				<div className={style.loading}>
					<CircularProgress color="inherit" />
				</div>
			)}

			<div
				className={style["select-swap-convert"]}
				ref={selectSwapRef}
			>
				<div
					className={openPopper && openPopperTo === 0 ? style["active"] : ""}
					onClick={() => {
						setOpenPopperTo(0);
						setOpenPopper(true);
					}}
				>
					<Typography component="span">De</Typography>
					<div className={style["select-currency-swap"]}>
						<Icons
							name={String(from).toUpperCase()}
							style={{ marginRight: 15 }}
						/>
						<div>
							<Typography component="h6">{String(from).toUpperCase()}</Typography>
							<Typography component="div">{nameFrom}</Typography>
						</div>
					</div>
				</div>
				<div className={style["input-arrow-icon"]}>
					<SvgIcon path={convertible && !invertible ? mdiArrowRight : !convertible && invertible ? mdiArrowLeft : mdiSwapHorizontal} />
				</div>
				<div
					className={openPopper && openPopperTo === 1 ? style["active"] : ""}
					onClick={() => {
						setOpenPopperTo(1);
						setOpenPopper(true);
					}}
				>
					<Typography component="span">Para</Typography>
					<div className={style["select-currency-swap"]}>
						<Icons
							name={String(to).toUpperCase()}
							style={{ marginRight: 15 }}
						/>
						<div>
							<Typography component="h6">{String(to).toUpperCase()}</Typography>
							<Typography component="div">{nameTo}</Typography>
						</div>
					</div>
				</div>
			</div>

			<div
				className={style["input-swap-convert"]}
				style={{
					flexDirection: !convertible && invertible ? "column-reverse" : "column",
				}}
			>
				<div
					className={style["input-base"]}
					style={
						validateBalance
							? {
									borderColor:
										isConversion && (balanceAvailableFrom < inputValueFrom || inputValueFrom < 0) ? "var(--error-dark)" : "",
									backgroundColor:
										isConversion && (balanceAvailableFrom < inputValueFrom || inputValueFrom < 0) ? "var(--error-light)" : "",
									backgroundImage:
										isConversion && (balanceAvailableFrom < inputValueFrom || inputValueFrom < 0)
											? "linear-gradient(var(--action-light-active), var(--action-light-active))"
											: "",
							  }
							: {}
					}
				>
					<Icons
						className={style["input-symbol"]}
						name={String(from).toUpperCase()}
						style={{ marginRight: 15 }}
					/>
					<div className={style["input-base-by"]}>
						<InputBase
							type="number"
							fullWidth
							sx={{ ml: 1, flex: 1 }}
							placeholder="0,00"
							value={!isConversion ? inputValueFrom : value || ""}
							onChange={({ target: { value } }) => {
								setIsConversion(true);
								setValue(value);
							}}
						/>
					</div>
					<Typography
						className={style["balance"]}
						variant="caption"
						component="p"
					>
						Saldo:{" "}
						{!fromIsCurrencySystem
							? `${renderValueBalanceAvailableFrom?.toString().split(/\s/gi).pop()} ${from}`
							: renderValueBalanceAvailableFrom}
					</Typography>
				</div>

				<div
					className={style["input-base"]}
					style={
						validateBalance
							? {
									borderColor: !isConversion && (balanceAvailableTo < inputValueTo || inputValueTo < 0) ? "var(--error-dark)" : "",
									backgroundColor:
										!isConversion && (balanceAvailableTo < inputValueTo || inputValueTo < 0) ? "var(--error-light)" : "",
									backgroundImage:
										!isConversion && (balanceAvailableTo < inputValueTo || inputValueTo < 0)
											? "linear-gradient(var(--action-light-active), var(--action-light-active))"
											: "",
							  }
							: {}
					}
				>
					<Icons
						className={style["input-symbol"]}
						name={String(to).toUpperCase()}
						style={{ marginRight: 15 }}
					/>
					<div className={style["input-base-by"]}>
						<InputBase
							type="number"
							fullWidth
							sx={{ ml: 1, flex: 1 }}
							placeholder="0,00"
							value={isConversion ? inputValueTo : value || ""}
							onChange={({ target: { value } }) => {
								setIsConversion(false);
								setValue(value);
							}}
						/>
					</div>
					<Typography
						className={style["balance"]}
						variant="caption"
						component="p"
					>
						Saldo:{" "}
						{!toIsCurrencySystem
							? `${renderValueBalanceAvailableTo?.toString().split(/\s/gi).pop()} ${to}`
							: renderValueBalanceAvailableTo}
					</Typography>
				</div>
			</div>

			<div
				className={style["description"]}
				style={{ fontSize: "italic" }}
			>
				<Typography
					variant="caption"
					component="span"
				>
					1 {String(from).toUpperCase()} ={" "}
					{(currencyFormat.convert(parseFloat(counting.toFixed(8))) ?? "0").toString().trim().split(/\s/gi).pop()}{" "}
					{String(to).toUpperCase()}
				</Typography>
			</div>

			<div className={style["description"]}>
				<Typography
					variant="caption"
					component="span"
				>
					{(currencyFormat.convert(parseFloat((inputValueFrom || 0).toFixed(8))) ?? "0").toString().trim().split(/\s/gi).pop()}{" "}
					{String(from).toUpperCase()} ={" "}
					{(currencyFormat.convert(parseFloat((inputValueTo || 0).toFixed(8))) ?? "0").toString().trim().split(/\s/gi).pop()}{" "}
					{String(to).toUpperCase()}
				</Typography>
			</div>

			{tradeButton && (
				<div className={style["trade-button"]}>
					<Button
						variant="contained"
						color="success"
						size="small"
						onClick={apllyOrder("BUY")}
					>
						Comprar/Buy
					</Button>
					<Button
						variant="contained"
						color="error"
						size="small"
						onClick={apllyOrder("SELL")}
					>
						Vender/Sell
					</Button>
				</div>
			)}

			<Popper
				open={openPopper}
				anchorEl={(openPopperTo === 0 ? selectSwapRef.current?.firstChild : selectSwapRef.current?.lastChild) as HTMLDivElement}
				transition
				container={document.body}
				className={style["popper-component"]}
				placement={openPopperTo === 0 ? "bottom-start" : "bottom-end"}
			>
				{({ TransitionProps, placement }) => {
					let disableds = Object.values(openPopperTo === 0 ? conversionList : countingList)
						.filter(({ disabled }) => disabled)
						.map(({ symbol }) => symbol);

					const list_select = Object.values(openPopperTo === 0 ? conversionList : countingList).map(({ symbol, name }, i) => {
						return {
							icon: (
								<Icons
									name={symbol}
									style={{ marginRight: 15 }}
								/>
							),
							label: typeof name === "string" && name.trim() !== "" ? `${symbol} - ${name}` : symbol,
							value: symbol,
						};
					});

					const indiceListSelect = list_select.findIndex(({ value }) => value === (openPopperTo === 0 ? from : to));

					list_select.unshift(...list_select.splice(indiceListSelect, 1));

					const listHidden = list_select.filter(({ value }) => value.search(searchCurrency.toUpperCase()) < 0).map(({ value }) => value);

					return (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin: "center top",
								width: `${
									(selectSwapRef.current?.classList.contains(style.column)
										? (selectSwapRef.current?.firstChild as any)?.offsetWidth
										: selectSwapRef.current?.offsetWidth) ?? "200"
								}px`,
							}}
						>
							<Paper className={style["popper-paper-component"]}>
								<ClickAwayListener
									onClickAway={(event) => {
										if (selectSwapRef.current && event.target && selectSwapRef.current.contains(event.target as any)) {
											return;
										}
										setOpenPopper(false);
										setSearchCurrency("");
									}}
								>
									<Box>
										<Box sx={{ padding: "8px", marginBottom: "5px" }}>
											<TextField
												type={"text"}
												label="Pesquisar"
												variant="standard"
												size="small"
												fullWidth
												value={searchCurrency}
												onChange={({ target: { value } }) => setSearchCurrency(value)}
											/>
										</Box>
										<Box
											sx={{
												maxHeight: "300px",
												overflowY: "auto",
											}}
										>
											<SelectList
												value={openPopperTo === 0 ? from : to}
												list={list_select}
												disabled={disableds}
												hidden={listHidden}
												onSelect={(value) => {
													if (openPopperTo === 0) {
														setFrom(value as any);
													} else {
														setTo(value as any);
													}

													setOpenPopper(false);
													setSearchCurrency("");
												}}
											/>
										</Box>
									</Box>
								</ClickAwayListener>
							</Paper>
						</Grow>
					);
				}}
			</Popper>
		</div>
	);
};

export default SimulateSwap;
