import React from "react";
import { applyForwardRef, applyStyledProps, useCanvas, usePropsImperativeHandle } from "../Utils";
import { CANVASArcElement } from "../Elements";

export const Arc = applyForwardRef<Partial<CANVASArcElement>, CANVASArcElement>((p, ref) => {
	const [props, setProps] = usePropsImperativeHandle<CANVASArcElement>(ref, p, {
		x: 0,
		y: 0,
		radius: 15,
		startAngle: 0,
		endAngle: Math.PI * 2,
		counterclockwise: false,
		stroke: "#000000",
		strokeWidth: 2,
	});

	useCanvas(
		({ canvas }) => {
			const ctx = canvas?.getContext("2d");
			const { x, y, radius, fill, stroke, strokeWidth, startAngle, endAngle, counterclockwise } = props;

			if (ctx) {
				ctx.save();
				applyStyledProps(ctx, props, (ctx) => {
					ctx.beginPath();
					ctx.arc(x, y, radius, startAngle, endAngle, counterclockwise);
					if (typeof fill === "string") {
						ctx.fillStyle = fill;
					}
					ctx.fill();
					if (typeof stroke === "string") {
						ctx.strokeStyle = stroke;
					}
					if (typeof strokeWidth === "number") {
						ctx.lineWidth = strokeWidth;
					}
					ctx.stroke();
					ctx.fill;
					ctx.closePath();
				});
				ctx.restore();
			}
		},
		[props],
	);

	return null;
});
