/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useLayoutEffect, useCallback } from "react";

import { useElementSize } from "Utils/useHook";
import { uniqueid, Color } from "Utils";

import { useColorList } from "./utils";

import style from "./style.module.scss";

export type AreaChartOptions = Partial<{
	format: {
		vAxis?: (value: any) => any;
		hAxis?: (value: any) => any;
	};
	chartBackground: boolean;
}>;

export type AreaChartData = any[][];

const AreaChart: React.FC<{
	data: AreaChartData;
	options?: AreaChartOptions;
}> = ({ data, options }) => {
	const [chartViewRef, { width, height }] = useElementSize();
	const [optValue, setOptValue] = useState({ min: 0, max: 0, columns: 0, decimalLen: 0 });
	const [ids, setIds] = useState<string[]>([]);
	const colors = useColorList();

	options = {
		chartBackground: true,
		...(options ?? {}),
	};

	useEffect(() => {
		const lines = data.slice(1, data.length).map((d) => d.slice(1, d.length));
		const lengLines = Math.max.apply(
			null,
			lines.map((d) => d.length),
		);
		const values = Array.prototype.concat.apply([], lines);

		if (lengLines < 1) {
			return;
		}

		setOptValue((prev) => {
			return {
				...prev,
				min: Math.min.apply(null, values),
				max: Math.max.apply(null, values),
				columns: data.length - 1,
				decimalLen: Math.min(
					Math.max.apply(
						null,
						values.map((v) => String(v).split(".")[1]?.length || 0),
					),
					2,
				),
			};
		});

		setIds((prev: any[]) => {
			return [`mask-${uniqueid()}`, `mask-${uniqueid()}`, `zero-line-${uniqueid()}`].concat(
				new Array(lengLines).fill("chart-grad").map((id, i) => {
					return i < prev.length && prev.length > 0 && typeof prev[i] === "string" ? prev[i] : `${id}-${uniqueid()}`;
				}),
			);
		});
	}, [data]);

	const zeroLine = optValue.min < 0 ? 1 - (0 - optValue.min) / (optValue.max - optValue.min) : 1;
	const margin = 10,
		h = height - margin * 2;
	const vAxis_len = Math.round(height / 35);
	const hAxis_len = Math.round(width / 100);

	const getIndexPoints = useCallback(
		(i: number) => {
			const d = data.slice(1, data.length);
			if (i < 0 || i >= d.length) {
				return [];
			}
			return d[i].slice(1, d.length).map((v) => {
				return [(width / (optValue.columns - 1)) * i || 0, (1 - (v - optValue.min) / (optValue.max - optValue.min)) * h + margin].map(
					Math.round,
				);
			});
		},
		[optValue, width, height],
	);

	const renderVAxis = useCallback(
		(value: any) => {
			value = parseFloat((typeof value === "number" && isFinite(value) ? value : parseFloat(value) || 0).toFixed(optValue.decimalLen));
			return typeof options?.format?.vAxis === "function" ? options?.format?.vAxis(parseFloat(value)) : value;
		},
		[data, options],
	);

	const renderHAxis = useCallback(
		(value: any) => {
			return typeof options?.format?.hAxis === "function"
				? options?.format?.hAxis(value)
				: ["string", "number"].includes(typeof value)
				? value
				: value instanceof Date
				? value.toLocaleDateString()
				: "";
		},
		[data, options],
	);

	const showMouseEvent = useCallback(
		(x: number, y: number) => {
			const popup = chartViewRef?.current?.querySelector(`div.${style["popup"]}`);
			const indexLine = chartViewRef?.current?.querySelector(`svg > path.${style["indexLine"]}`);
			const indexes = chartViewRef?.current?.querySelectorAll(`svg > g.${style["indexes"]} > g`);
			if (!popup) {
				return;
			}

			popup.style.display = "";

			const { width: w, height: h } = popup.getBoundingClientRect();
			let transformX = -100;

			const midColumn = width / (data.length - 2);
			const i = Math.round(x / midColumn);
			const points = getIndexPoints(i);

			//console.log(width, (data.length-2), midColumn, x, i, points);

			const p_y = points.map(([x, y]) => y);
			const max_y = Math.max.apply(null, p_y);
			const min_y = Math.min.apply(null, p_y);
			const n_y = min_y + (max_y - min_y) * 0.5;

			y = !n_y || n_y <= 0 || n_y >= height ? y : n_y;

			x = i * midColumn || 0;

			if (x - (w + 5) < 5) {
				transformX = 0;
			}

			y = y - h / 2 < 5 ? 5 + (y - (y - h / 2)) : y + h / 2 > height - 5 ? height - 5 - h / 2 : y;

			popup.style.top = `${y || 0}px`;
			popup.style.left = `${x + (transformX > -100 ? 10 : -10)}px`;
			popup.style.transform = `translate(${transformX}%, -50%)`;

			for (let g = 0; g < indexes.length; g++) {
				indexes[g].style.display = g != i ? "none" : "block";
				if (indexLine && g === i) {
					indexLine.style.display = "block";
					const cx = indexes[g]?.querySelector(`circle`).getAttribute("cx") || 0;
					indexLine.setAttribute("d", `M ${cx} 0 L ${cx} ${height}`);
				}
			}

			let code = "";

			const d = data.slice(1, data.length)[i];

			if (!d) {
				return;
			}

			d.slice(1, d.length).forEach((v, i) => {
				code += `<div class="${style["popup-item"]}"><div class="${style["popup-item-line"]}" style="background: ${
					colors[i]
				};"></div><span>${renderVAxis(v)}</span></div>`;
			});

			code += `<div class="${style["popup-subtitle"]}">${renderHAxis(d[0])}</div>`;

			popup.innerHTML = code;
		},
		[chartViewRef, optValue, width, height],
	);

	useLayoutEffect(() => {
		const mousemove: EventListener = (e) => {
			const { top, left } = (chartViewRef?.current as HTMLDListElement)?.getBoundingClientRect();
			const { x, y } = e as any;
			showMouseEvent(x - left, y - top);
		};

		const mouseout: EventListener = () => {
			const popup = chartViewRef?.current?.querySelector(`div.${style["popup"]}`);
			const indexLine = chartViewRef?.current?.querySelector(`svg > path.${style["indexLine"]}`);
			const indexes = chartViewRef?.current?.querySelectorAll(`svg > g.${style["indexes"]} > g`);
			if (!popup) {
				return;
			}
			popup.style.display = "none";
			for (let g = 0; g < indexes.length; g++) {
				indexes[g].style.display = "none";
			}
			if (indexLine && indexLine.style) {
				indexLine.style.display = "none";
			}
		};

		chartViewRef?.current?.addEventListener("mouseover", mousemove);
		chartViewRef?.current?.addEventListener("mousemove", mousemove);
		chartViewRef?.current?.addEventListener("mouseout", mouseout);
		chartViewRef?.current?.addEventListener("mouseleave", mouseout);

		return () => {
			chartViewRef?.current?.removeEventListener("mouseover", mousemove);
			chartViewRef?.current?.removeEventListener("mousemove", mousemove);
			chartViewRef?.current?.removeEventListener("mouseout", mouseout);
			chartViewRef?.current?.removeEventListener("mouseleave", mouseout);
		};
	}, [chartViewRef, optValue, width, height]);

	return (
		<div className={[style["chart-content"], style["chart-area"]].join(" ")}>
			<div>
				<div className={style["vAxis"]}>
					{new Array(vAxis_len)
						.fill(0)
						.map((v, i) => {
							v = (i / (vAxis_len - 1)) * (optValue.max - optValue.min) + optValue.min;
							return <span key={`vAxis-${i}`}>{renderVAxis(v)}</span>;
						})
						.reverse()}
				</div>
				<div
					className={[style["chart-view"], options?.chartBackground ? style.background : ""].join(" ")}
					ref={chartViewRef as any}
				>
					<svg xmlns="http://www.w3.org/2000/svg">
						<defs>
							{ids.slice(3, ids.length).map((id, i) => {
								const red = new Color("#ff1744").blend(colors[i], 0.5).hex;
								return [
									<linearGradient
										id={id}
										key={`linearGradient-${i}`}
										x1="0%"
										x2="0%"
										y1="0%"
										y2="100%"
									>
										<stop
											offset="0%"
											stopColor={colors[i]}
											stopOpacity="0.3"
										></stop>
										<stop
											offset={`${Math.round(zeroLine * 100) - 5}%`}
											stopColor={colors[i]}
											stopOpacity="0"
										></stop>
										<stop
											offset={`${Math.round(zeroLine * 100) - 5}%`}
											stopColor={red}
											stopOpacity="0"
										></stop>
										<stop
											offset={`100%`}
											stopColor={red}
											stopOpacity="0"
										></stop>
									</linearGradient>,
									<linearGradient
										id={`red-${id}`}
										key={`linearGradient-red-${i}`}
										x1="0%"
										x2="0%"
										y1="0%"
										y2="100%"
									>
										<stop
											offset="0%"
											stopColor={colors[i]}
											stopOpacity="0"
										></stop>
										<stop
											offset={`${Math.round(zeroLine * 100) + 5}%`}
											stopColor={colors[i]}
											stopOpacity="0"
										></stop>
										<stop
											offset={`${Math.round(zeroLine * 100) + 5}%`}
											stopColor={red}
											stopOpacity="0"
										></stop>
										<stop
											offset={`100%`}
											stopColor={red}
											stopOpacity="0.3"
										></stop>
									</linearGradient>,
								];
							})}
							<linearGradient
								id={ids[2]}
								x1="0%"
								x2="0%"
								y1="0%"
								y2="100%"
							>
								<stop
									offset="0%"
									stopColor={"#ff1744"}
									stopOpacity="0"
								></stop>
								<stop
									offset={`${Math.round(zeroLine * 100) + 5}%`}
									stopColor={"#ff1744"}
									stopOpacity="0"
								></stop>
								<stop
									offset={`${Math.round(zeroLine * 100) + 5}%`}
									stopColor={"#ff1744"}
									stopOpacity="0"
								></stop>
								<stop
									offset={`100%`}
									stopColor={"#ff1744"}
									stopOpacity="0.3"
								></stop>
							</linearGradient>
							<clipPath id={ids[0]}>
								<rect
									height={Math.abs(zeroLine * h + margin + (zeroLine >= 1 ? 2 : 0))}
									width={width}
									x="0"
									y="0"
								></rect>
							</clipPath>
							<clipPath id={ids[1]}>
								<rect
									height={Math.abs(height - Math.round(zeroLine * h + margin))}
									width={width}
									x="0"
									y={Math.abs(zeroLine * h + margin + (zeroLine >= 1 ? 2 : 0))}
								></rect>
							</clipPath>
						</defs>

						{ids.slice(3, ids.length).map((id, i) => {
							const points = data
								.slice(1, data.length)
								.map((d) => d[i + 1])
								.map((v, i) => {
									return [
										(width / (optValue.columns - 1)) * i,
										(1 - (v - optValue.min) / (optValue.max - optValue.min)) * h + margin,
									]
										.map(Math.round)
										.join(" ");
								});

							const path = `M ${points.join(" L ")}`;

							return [
								<path
									key={`chart-grad-fill-${i}`}
									clipPath={`url(#${ids[0]})`}
									d={`${path} L ${width} ${height} L 0 ${height} Z`}
									stroke="transparent"
									style={{ fill: `url(#${id})` }}
								></path>,
								<path
									key={`chart-grad-stroke-${i}`}
									clipPath={`url(#${ids[0]})`}
									d={path}
									stroke={colors[i]}
									style={{ fill: `transparent` }}
								></path>,
								<path
									key={`chart-grad-fill-red-${i}`}
									clipPath={`url(#${ids[1]})`}
									d={`${path} L ${width} 0 L 0 0 Z`}
									stroke="transparent"
									style={{ fill: `url(#red-${id})` }}
								></path>,
								<path
									key={`chart-grad-stroke-red-${i}`}
									clipPath={`url(#${ids[1]})`}
									d={path}
									stroke={new Color("#ff1744").blend(colors[i], 0.7).hex}
									style={{ fill: `transparent` }}
								></path>,
							];
						})}

						{zeroLine < 1 ? (
							<path
								key={`zeroLine-0`}
								className={style["zeroLine"]}
								d={`M 0 ${Math.round(zeroLine * h + margin)} L ${width} ${Math.round(zeroLine * h + margin)}`}
							></path>
						) : null}

						<path
							className={style["indexLine"]}
							d={`M 10 0 L 10 ${height}`}
						></path>

						<g className={style["indexes"]}>
							{data.slice(1, data.length).map((d, i) => {
								d = d.slice(1, d.length);

								const points = getIndexPoints(i);

								return (
									<g key={`group-${i}`}>
										{points.map(([x, y], j) => {
											y = !y || typeof y !== "number" || !isFinite(y) ? 0 : y;
											return (
												<circle
													key={`group-${i}-circle-${j}`}
													cx={x}
													cy={y}
													r="5"
													fill={colors[j]}
												/>
											);
										})}
									</g>
								);
							})}
						</g>
					</svg>
					<div className={style["popup"]}>
						<div className={style["popup-title"]}>Title</div>
						<div className={style["popup-item"]}>
							<div
								className={style["popup-item-line"]}
								style={{ background: colors[0] }}
							></div>
							<span>R$ 15,00</span>
						</div>
						<div className={style["popup-item"]}>
							<div
								className={style["popup-item-line"]}
								style={{ background: colors[1] }}
							></div>
							<span>R$ 15,00</span>
						</div>
						<div className={style["popup-subtitle"]}>19/08/2022</div>
					</div>
				</div>
			</div>
			<div>
				<div
					className={style["hAxis"]}
					style={{ width: width }}
				>
					{new Array(hAxis_len).fill(0).map((v, i) => {
						const axis = Math.floor((i / (hAxis_len - 1)) * (data.length - 2));
						if (!data[axis + 1]) {
							return <span key={`hAxis-${i}`}></span>;
						}
						const value = data[axis + 1][0];
						return <span key={`hAxis-${i}`}>{renderHAxis(value)}</span>;
					})}
				</div>
			</div>
		</div>
	);
};

export default AreaChart;
