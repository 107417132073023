import { useState, useEffect, useCallback } from "react";

import { Address, Usuario } from "Models";
import { InteractionHelper, UserHelper } from "Helper";

import {
	Typography,
	ButtonGroup,
	Button,
	Divider,
	Chip,
	Grid,
	Switch,
	FormGroup,
	FormControlLabel,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	IconButton,
} from "@mui/material";
import { SvgIcon } from "Components";
import { mdiPencil, mdiContentSaveEdit, mdiPencilRemove } from "@mdi/js";

import { LoadingButton } from "@mui/lab";

import styles from "./style.module.scss";

import { imageGifts } from "./Resources";

import AddressView from "./AddressView";

const dialogAddress = (address = {}, callback) => {
	address = new Address().parse(null, address);
	let editAddress = new Address().parse(null, address);

	InteractionHelper.confirm(
		<AddressView
			address={address}
			updateAddress={(address) => {
				editAddress = new Address().parse(null, address);
			}}
		/>,
		"Endereço",
	)
		.then(() => {
			address = editAddress.toJson();
		})
		.catch(() => {
			address = address.toJson();
		})
		.finally(() => {
			if (typeof callback === "function") {
				callback(address);
			}
		});
};

const DefinitionsGifts = ({ disabled, user, updateUser }) => {
	const [carregando, setCarregando] = useState(false);
	const [isEditable, setIsEditable] = useState(false);
	const [editDefinitions, setEditDefinitions] = useState({
		shirt: {
			sex: "unissex",
			size: "M",
		},
		receiveAtAddress: true,
		address: {},
	});

	useEffect(() => {
		if (user instanceof Usuario) {
			const {
				definitionsGifts: { shirt, receiveAtAddress },
				address,
			} = user.toJson();
			setEditDefinitions(() => ({ shirt, receiveAtAddress: true, address }));
		}
	}, [user]);

	const editInput = useCallback(
		(prop = "", value) => {
			if (!isEditable || carregando) {
				return;
			}

			prop = prop.split(".");

			setEditDefinitions((prev) => {
				const lastProp = prop.pop();
				const current = prop.reduce((obj, prop) => {
					return typeof obj[prop] === "object" && obj[prop] !== null ? obj[prop] : (obj[prop] = {});
				}, prev);
				current[lastProp] = value;
				return { ...prev };
			});
		},
		[isEditable, carregando],
	);

	const cancelEdit = useCallback(() => {
		if (user instanceof Usuario) {
			const {
				definitionsGifts: { shirt, receiveAtAddress },
				address,
			} = user.toJson();
			setEditDefinitions(() => ({ shirt, receiveAtAddress: true, address }));
		}
		setIsEditable(false);
	}, [user]);

	const editAddress = useCallback(() => {
		dialogAddress(editDefinitions.address, (address) => {
			editInput("address", address);
		});
	}, [editDefinitions, editInput]);

	const saveEdit = useCallback(() => {
		if (!(user instanceof Usuario)) {
			return;
		}

		setCarregando(true);
		const { shirt, receiveAtAddress, address } = editDefinitions;

		const usuario = new Usuario().parse(user.path, {
			...user.toJson(),
			definitionsGifts: { shirt, receiveAtAddress },
			address,
		});

		UserHelper.salvarUsuario(usuario)
			.then((usuario) => {
				if (typeof updateUser === "function") {
					updateUser(usuario);
				}
				setIsEditable(false);
				InteractionHelper.toast("Salvo com sucesso", null, "success");
			})
			.catch((err) => {
				InteractionHelper.toast("Erro ao tentar salvar os dados!", null, "error");
			})
			.finally(() => {
				setCarregando(false);
			});
	}, [editDefinitions, user]);

	const loading = carregando || disabled;

	let addressFormat = new Address().parse(null, editDefinitions.address).getFormatAddress();
	addressFormat = String(addressFormat).trim() === "" ? "Endereço não informado" : addressFormat;

	return (
		<Grid
			className={[styles["container-box"], styles["reverse"]].join(" ")}
			container
			rowSpacing={4}
			columnSpacing={0}
		>
			<Grid
				item
				xs={12}
				md={6}
			>
				<div
					className={styles["image"]}
					style={{ backgroundImage: `url(${imageGifts})` }}
				></div>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
			>
				<div className={styles["content"]}>
					<div className={styles["body"]}>
						<Typography
							variant="h5"
							style={{ marginBottom: "15px", fontWeight: "bold" }}
						>
							Desfrute de Brindes Exclusivos da iVipCoin
						</Typography>

						<Typography
							variant="subtitle1"
							style={{ marginBottom: "25px" }}
						>
							A iVipCoin deseja agradecer aos usuários que compraram tokens e mantiveram suas contas ativas. Por isso, estamos
							oferecendo brindes incríveis, incluindo NFTs, camisas, canetas, bonés e muito mais. Aproveite essas recompensas exclusivas
							como uma forma de reconhecer sua participação em nossa comunidade.
						</Typography>

						<div className={styles["definitions"]}>
							<Typography
								variant="subtitle1"
								style={{ marginBottom: "15px" }}
							>
								Especifique suas preferências em relação aos brindes e como você gostaria de recebê-los.
							</Typography>

							<Divider textAlign="left">Camisa</Divider>

							<div>
								<Grid
									container
									rowSpacing={0}
									columnSpacing={2}
								>
									<Grid
										item
										xs={6}
										md={8}
									>
										<FormControl
											fullWidth
											size="small"
										>
											<InputLabel id="definition-shirt-sex">Sexo</InputLabel>
											<Select
												labelId="definition-shirt-sex"
												label="Sexo"
												readOnly={!isEditable}
												disabled={loading}
												value={editDefinitions.shirt.sex}
												onChange={({ target: { value } }) => editInput("shirt.sex", value)}
											>
												<MenuItem value={"unissex"}>Unissex</MenuItem>
												<MenuItem value={"masculino"}>Masculino</MenuItem>
												<MenuItem value={"feminino"}>Feminino</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									<Grid
										item
										xs={6}
										md={4}
									>
										<FormControl
											fullWidth
											size="small"
										>
											<InputLabel id="definition-shirt-size">Tamanho</InputLabel>
											<Select
												labelId="definition-shirt-size"
												label="Tamanho"
												readOnly={!isEditable}
												disabled={loading}
												value={editDefinitions.shirt.size}
												onChange={({ target: { value } }) => editInput("shirt.size", value)}
											>
												<MenuItem value={"P"}>P</MenuItem>
												<MenuItem value={"M"}>M</MenuItem>
												<MenuItem value={"G"}>G</MenuItem>
												<MenuItem value={"GG"}>GG</MenuItem>
												<MenuItem value={"EXG"}>EXG</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</div>

							<Divider textAlign="left">Modo de entrega</Divider>

							{/* <div>
                            <FormGroup>
                                <FormControlLabel control={<Switch 
                                    checked={!editDefinitions.receiveAtAddress} disabled={loading}
                                    onChange={({target: { checked }})=> editInput("receiveAtAddress", !checked)}
                                />} label="Receber os brindes no local do evento" />
                            </FormGroup>
                        </div> */}

							<div className={styles["definition-label-edit"]}>
								{editDefinitions.receiveAtAddress && <Typography variant="body2">{addressFormat}</Typography>}

								{editDefinitions.receiveAtAddress && (
									<IconButton
										color="primary"
										component="label"
										size={"small"}
										sx={{ marginLeft: "15px" }}
										onClick={editAddress}
										disabled={loading || !isEditable}
									>
										<SvgIcon path={mdiPencil} />
									</IconButton>
								)}

								{!editDefinitions.receiveAtAddress && (
									<Typography variant="body2">
										Você selecionou a opção de receber o brinde no local do evento. Caso deseje receber o brinde em sua
										residência, desative essa opção e informe um endereço de entrega.
									</Typography>
								)}
							</div>
						</div>

						<div
							style={{
								paddingTop: "20px",
								paddingBottom: "0px",
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							<ButtonGroup size="small">
								{isEditable && (
									<LoadingButton
										loading={loading}
										loadingPosition="start"
										disabled={loading}
										variant="outlined"
										startIcon={<SvgIcon path={mdiContentSaveEdit} />}
										onClick={() => saveEdit()}
									>
										Salvar
									</LoadingButton>
								)}

								{isEditable && (
									<Button
										disabled={loading}
										variant="outlined"
										startIcon={<SvgIcon path={mdiPencilRemove} />}
										onClick={() => cancelEdit()}
									>
										Cancelar
									</Button>
								)}

								{!isEditable && (
									<Button
										disabled={loading}
										variant="contained"
										startIcon={<SvgIcon path={mdiPencil} />}
										onClick={() => {
											setIsEditable((prev) => !prev);
										}}
									>
										Editar
									</Button>
								)}
							</ButtonGroup>
						</div>
					</div>
				</div>
			</Grid>
		</Grid>
	);
};

export default DefinitionsGifts;
