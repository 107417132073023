import React, { useState, lazy, Suspense, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

const GestaoUsuario = lazy(() => import('./Components/GestaoUsuario'))

export default function Users() {
  const [selectedUser, setSelectedUser] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const history = useHistory()

  const handleCardClick = useCallback(
    (atSign) => {
      setSelectedUser(atSign)
      history.push(`/users/${atSign}`)
    },
    [selectedUser]
  )

  return (
    <Suspense fallback={() => <p>Loading...</p>}>
      <GestaoUsuario
        onCardClick={handleCardClick}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Suspense>
  )
}
