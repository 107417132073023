import React, { useState, useRef, useEffect, useLayoutEffect, useCallback } from "react";
import { useClickOutside } from "@react-hookz/web";
import { MultiStorager, Color } from "Utils";
import { InteractionHelper, APIHelper } from "Helper";

import { Typography, Stack, Button } from "@mui/material";

import style from "./style.module.scss";
import { useDataStorager } from "Utils/MultiStorager";

export const showNftCard = (props: object) => {
	MultiStorager.DataStorager.set(
		"__show_nft_card__",
		Object.assign(
			{
				show: true,
			},
			props,
		),
	);
};

const ViewNFTCard = () => {
	const [info, setInfo] = useDataStorager<{
		[k: string]: any;
		show: boolean;
	}>("__show_nft_card__", {
		show: false,
	});

	const rootRef = useRef<HTMLDivElement>(null);
	const cardRef = useRef<HTMLDivElement>(null);

	const close = useCallback(() => {
		const info = MultiStorager.DataStorager.get("__show_nft_card__") ?? {};
		MultiStorager.DataStorager.set("__show_nft_card__", { ...info, show: false });
	}, []);

	useEffect(() => {
		if (!rootRef.current) {
			let time: NodeJS.Timeout;
			const init = () => {
				time = setTimeout(() => {
					if (!rootRef.current) {
						init();
						return;
					}
					rootRef.current.style.visibility = "hidden";
				}, 200);
			};

			init();

			return () => {
				clearTimeout(time);
			};
		}
		rootRef.current.style.visibility = "hidden";
	}, []);

	useEffect(() => {
		if (!rootRef.current) {
			return;
		}
		let time: NodeJS.Timeout;

		if (info.show) {
			rootRef.current.style.visibility = "visible";
		} else {
			time = setTimeout(() => {
				if (!rootRef.current) {
					return;
				}
				rootRef.current.style.visibility = "hidden";
			}, 200);
		}

		return () => {
			clearTimeout(time);
		};
	}, [info, info?.show]);

	const clickOutside: React.MouseEventHandler<HTMLDivElement> = useCallback((e) => {
		const element = cardRef.current;
		if (e.target !== element && (e.target as any).contains(element)) {
			close();
		}
	}, []);

	const clickAcquire = useCallback(() => {
		if (typeof info.id !== "string" || info.id.trim() === "") {
			return;
		}

		InteractionHelper.loading();

		APIHelper.fetch(`/nft/acquire/${info.id}`)
			.then((r) => {
				showNftCard(r);
				if (!r.isOwns) {
					InteractionHelper.toast("Esse NFT já foi adquirido por outro usuário!", null, "error", null, 10000);
				}
				return APIHelper.fetch(`/nft`, {}, true);
			})
			.then((list) => {
				MultiStorager.DataStorager.set("__nft_catalog_list__", list);
			})
			.catch(() => {
				InteractionHelper.toast("Ocorreu um erro interno, tente novamente mais tarde!", null, "error", null, 10000);
			})
			.finally(() => {
				InteractionHelper.close();
			});
	}, [info, info?.id]);

	const color = new Color(info.color ?? "#f5f5f5");
	const colorIsDark = color.vector.reduce((a, b) => a + b, 0) / 3 <= 150;
	const buttonText = colorIsDark ? color.lighten(0.5) : color.darken(0.5);
	const colorText = colorIsDark ? color.lighten(0.7) : color.darken(0.7);

	return (
		<div
			ref={rootRef}
			className={[style["main"], info.show === true ? style["show"] : ""].join(" ")}
			onClick={clickOutside}
		>
			<div
				ref={cardRef}
				className={style["container"]}
				style={
					{
						"--color": color.vector.join(", "),
						"--button-color": buttonText.vector.join(", "),
						"--text-color": colorText.vector.join(", "),
					} as React.CSSProperties & { [k: string]: string }
				}
			>
				<button
					className={style["button-close"]}
					onClick={close}
				>
					<div className={style["icon"]}>
						<svg viewBox="0 0 72 72">
							<path
								fill="none"
								d="m61.5 9.5-7 7m-38 38-7 7M30.3 42l2.8-3m8.6 3L30.3 30m11.4 0-2.6 2.8"
							></path>
						</svg>
					</div>
				</button>

				<div className={style["view-image"]}>
					{info.ownsOwner && (
						<div className={style["ribbon"]}>
							<span>sold out</span>
						</div>
					)}
					<div
						className={style["image"]}
						style={{ backgroundImage: `url(${info?.originalImg})` }}
					>
						<span className={style["topLeft"]}></span>
						<span className={style["topRight"]}></span>
						<span className={style["bottomLeft"]}></span>
						<span className={style["bottomRight"]}></span>
						<div className={style["marking"]}>
							<span className={style["top"]}></span>
							<span className={style["bottom"]}></span>
						</div>
					</div>
				</div>

				<div className={style["view-info"]}>
					<div className={style["code"]}>{info.id ?? ""}</div>
					<div className={style["title"]}>{info.title ?? ""}</div>
					<div className={style["description"]}>
						<Typography
							variant="body1"
							gutterBottom
						>
							Descrição:
						</Typography>
						<Typography variant="body2">{info.description ?? "..."}</Typography>
					</div>
					<div className={style["user-info"]}>
						<div
							className={style["picture"]}
							style={{
								backgroundImage: `url(${info?.creator?.fotoPerfil ?? ""})`,
							}}
						></div>
						<div className={style["label"]}>
							<Typography variant="caption">Criador</Typography>
							<Typography variant="body1">{info?.creator?.nome ?? "..."}</Typography>
						</div>
					</div>
					<div className={style["user-info"]}>
						<div
							className={style["picture"]}
							style={{
								backgroundImage: `url(${info?.owner?.fotoPerfil ?? ""})`,
							}}
						></div>
						<div className={style["label"]}>
							<Typography variant="caption">Proprietário</Typography>
							<Typography variant="body1">{info?.owner?.nome ?? "..."}</Typography>
						</div>
					</div>
					{info.available && (
						<Stack
							spacing={2}
							direction="row"
							justifyContent="center"
						>
							<Button
								variant="outlined"
								size="large"
								color="inherit"
								style={{
									color: "#ffffff",
								}}
								onClick={clickAcquire}
							>
								Adquirir
							</Button>
						</Stack>
					)}
				</div>
			</div>
		</div>
	);
};

export default ViewNFTCard;
