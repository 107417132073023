import React, { useState, useEffect, useRef, useCallback } from "react";
import { ModernCard, PageHeaderWrapper, SelectList, StakingCard, SvgIcon } from "Components";
import { Box, CircularProgress, Typography } from "@mui/material";

import style from "./style.module.scss";

import { MultiStorager, maskAmount, uniqueid } from "Utils";

import { APIHelper, InteractionHelper, WalletHelper } from "Helper";
import { Color } from "Utils";
import { StakingInfo } from "Types/Staking";
import { StakingApplyDialog } from "Dialog";
import { mdiChevronDoubleUp, mdiPlusCircle, mdiPlusMinus } from "@mdi/js";

const api_mold = () => {
	return new Array(4).fill(null).map((v, i) => {
		i = i + 1;
		return {
			id: uniqueid(8),
			name: `Staking - ${i}`,
			duration: i * 5,
			performance: i / 100,
			minAmount: 1000,
			maxAmount: 8000000,
			stakingAmount: 300000000,
			stakingAvailableAmount: 300000000,
			amount: 800000,
			applyStart: "2023-05-28T15:32:54.047Z",
			lastIncome: 0,
			expectedIncome: 0,
			applicable: false,
		};
	});
};

const colors = ["#ff9800", "#4caf50", "#673ab7"];

const getColor = (i: number, length: number) => {
	const interval = 1 / (colors.length - 1);
	const percent = i / (length - 1);
	const index = Math.floor(percent / interval);
	const color1 = colors[index];
	const color2 = colors[index + 1] || color1;
	return new Color(color2).blend(color1, (percent - interval * index) / interval).hex;
};

const durationFormat = (value: number): string => {
	if (value <= 0) "---";
	const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);
	const duration_years = Math.floor(value / 12);
	const duration_months = value % 12;
	let duration_format: string[] | string = [];
	//`${duration_years > 0 ? `${duration_years} ano(s)` : ""}${duration_months > 0 ? `${duration_years} ano(s)` : ""}`;

	if (duration_years > 0) {
		duration_format.push(duration_years > 1 ? `${duration_years} anos` : `um ano`);
	}

	if (duration_months > 0) {
		duration_format.push(duration_months > 1 ? `${duration_months} meses` : `um mês`);
	}

	return capitalize(duration_format.join(" e "));
};

const differenceInMonths = (startDate: number | string | Date, endDate: number | string | Date): number => {
	const start = new Date(startDate);
	const end = new Date(endDate);
	const dif = end.getTime() - start.getTime();
	const ms = 30 * 24 * 60 * 60 * 1000;
	return dif / ms;
};

const BodyList: React.FC<{
	list: StakingInfo[];
	defaltValue?: number;
	handle: (value: any, index: any) => void;
}> = ({ list, handle, defaltValue }) => {
	const options = list.map(({ name, id, typeAmount, duration, performance, minAmount, stakingAvailableAmount }, i) => {
		stakingAvailableAmount = minAmount > stakingAvailableAmount ? 0 : stakingAvailableAmount;
		return {
			label: name,
			description: `Tipo: ${typeAmount} | Rendimento a.a: ${maskAmount(performance * 100, 2, "%", "RTL")} | Duração: ${durationFormat(
				duration ?? 0,
			)} | Valor disponível: ${maskAmount(stakingAvailableAmount, 2, typeAmount, "RTL")};`,
			value: id,
			category: typeAmount,
		};
	});

	const filters = options
		.map(({ category }) => {
			return {
				label: category,
				value: category,
				dataIndex: "category",
			};
		})
		.filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i);

	const disabled = list.filter(({ applyStart }) => applyStart !== null).map(({ id }) => id);

	return (
		<SelectList
			list={options}
			filters={filters}
			onChange={handle}
			disabled={disabled}
			value={defaltValue}
		/>
	);
};

const applyNewStaking = (list: StakingInfo[], props: { selectType: number; id: string | number } = { selectType: 0, id: "" }) => {
	const disabledItem: string[] = list.filter(({ applicable, redeemable }) => !applicable && !redeemable).map(({ id }) => id);

	try {
		InteractionHelper.confirm(
			<BodyList
				list={list}
				defaltValue={props.selectType}
				handle={(value: any, index: any) => {
					props.selectType = index;
					props.id = value;
				}}
			/>,
			"Aplicar stake",
		)
			.then(() => {
				if (disabledItem.includes(list[props.selectType].id)) {
					InteractionHelper.toast("O staking que escolheu para investir não está disponível para aplicação!", "", "error");
					applyNewStaking(list, props);
					return;
				}

				const onApply = MultiStorager.DataStorager.get<(id: string | number) => void>("toApplyStaking");
				onApply?.(props.id);
			})
			.catch(() => {});
	} catch (e) {
		console.log(e);
		InteractionHelper.toast("Ocorreu um erro ao tentar aplicar um novo staking!", "", "error");
	}
};

const StakingStatisticResume: React.FC<{ stakings: StakingInfo[] }> = ({ stakings }) => {
	const [stakingsUpdated, setStakingsUpdated] = useState<StakingInfo[]>(stakings);

	useEffect(() => {
		const loop = () => {
			const dateNow = new Date().getTime();

			setStakingsUpdated(
				stakings
					.filter(({ applyEnd, applyStart }) => {
						return (!applyStart || !applyEnd) !== true;
					})
					.map((staking) => {
						if (!staking.applyStart || !staking.applyEnd) {
							return staking;
						}

						const applyEnd = new Date(staking.applyEnd).getTime();

						const lastPerformance = differenceInMonths(staking.applyStart, applyEnd > dateNow ? dateNow : applyEnd) * staking.performance;
						const lastIncome = parseFloat((staking.amount * lastPerformance).toFixed(4));

						return {
							...staking,
							lastIncome,
							lastIncomeDate: new Date(applyEnd > dateNow ? dateNow : applyEnd).toISOString(),
						};
					}),
			);
		};

		const interval = setInterval(loop, 1000);
		loop();

		return () => {
			clearInterval(interval);
		};
	}, [stakings]);

	const balance = stakingsUpdated.reduce((acc, { typeAmount, amount, lastIncome }) => {
		const index = acc.findIndex((v) => v.typeAmount === typeAmount);
		const value = acc[index] || { typeAmount, amount: 0, lastIncome: 0, performance: 0, length: 0 };
		value.amount += amount;
		value.lastIncome += lastIncome;
		value.performance = value.lastIncome / value.amount;
		value.length++;
		acc[index >= 0 ? index : acc.length] = value;
		return acc;
	}, [] as { typeAmount: string; amount: number; lastIncome: number; performance: number; length: number }[]);

	return (
		<div className={style["statistic-resume"]}>
			<div className={style["balance"]}>
				<div className={style["balance-container"]}>
					{balance.map(({ typeAmount, amount, lastIncome, performance, length }, i) => {
						return (
							<div
								className={style["balance-item"]}
								data-amount={typeAmount}
								key={i}
							>
								<Typography
									variant="subtitle1"
									component="div"
								>
									<div>{typeAmount}</div>
									<SvgIcon
										path={mdiChevronDoubleUp}
										children={undefined}
									/>
								</Typography>
								<Typography
									variant="body1"
									component="div"
								>
									<div>
										<b>Investimentos:</b>
									</div>{" "}
									<div>{length}</div>
								</Typography>
								<Typography
									variant="body1"
									component="div"
								>
									<div>
										<b>Aplicação total:</b>
									</div>{" "}
									<div>{maskAmount(amount, 3, undefined, "LTR")}</div>
								</Typography>
								<Typography
									variant="body1"
									component="div"
								>
									<div>
										<b>Rendimento:</b>
									</div>{" "}
									<div>{maskAmount(lastIncome, 3, undefined, "LTR")}</div>
								</Typography>
								<Typography
									variant="body1"
									component="div"
								>
									<div>
										<b>Valor total:</b>
									</div>{" "}
									<div>{maskAmount(amount + lastIncome, 3, undefined, "LTR")}</div>
								</Typography>
								<Typography
									variant="body1"
									component="div"
								>
									<div>
										<b>Performance:</b>
									</div>{" "}
									<div>{maskAmount(performance * 100, 2, "%", "RTL")}</div>
								</Typography>
							</div>
						);
					})}
					<div className={style["balance-add-btn"]}>
						<Typography
							variant="h6"
							component="div"
							onClick={() => applyNewStaking(stakings)}
						>
							<SvgIcon path={mdiPlusCircle} />
							<span>Investir</span>
						</Typography>
					</div>
				</div>
			</div>
			<div className={style["chart"]}>
				<svg viewBox="2 0 396 198">
					<defs>
						<defs>
							<linearGradient
								id="chart-grad-_Fw0YZprsAeLI1sQPusCP2AE_38"
								x1="0%"
								x2="0%"
								y1="0%"
								y2="100%"
							>
								<stop
									offset="0%"
									stopColor="rgba(0,0,0,.4)"
									stopOpacity="0.50"
								></stop>
								<stop
									offset="50%"
									stopColor="rgba(0,0,0,.4)"
									stopOpacity="0"
								></stop>
							</linearGradient>
						</defs>
						<clipPath id="mask-_Fw0YZprsAeLI1sQPusCP2AE_37">
							<rect
								height="200"
								width="400"
								x="2"
								y="0"
							></rect>
						</clipPath>
					</defs>
					<path
						clip-path="url(#mask-_Fw0YZprsAeLI1sQPusCP2AE_37)"
						d="M 0,200 L 66.66,160 133.33,140 200,115 266.66,90 333.33,60 400,0 400,400 0,400 Z"
						strokeWidth={2}
						stroke="rgba(0, 0, 0, .4)"
						fill="none"
						style={{ fill: "url(#chart-grad-_Fw0YZprsAeLI1sQPusCP2AE_38)" }}
					/>
				</svg>
			</div>
		</div>
	);
};

const StakeCard: React.FC<
	StakingInfo & {
		color: string;
	}
> = ({
	type,
	color,
	name,
	duration,
	performance,
	minAmount,
	maxAmount,
	typeAmount,
	price,
	rebate,
	amount,
	applyStart,
	applyEnd,
	stakingAmount,
	stakingAvailableAmount,
}) => {
	const [image, setImage] = useState<string>("");
	const [lastIncome, setLastIncome] = useState<number>(0);
	const canvas = useRef<HTMLCanvasElement>(document.createElement("canvas"));

	useEffect(() => {
		const width = 300,
			height = 300;
		canvas.current.width = width;
		canvas.current.height = height;
		const ctx = canvas.current.getContext("2d");
		if (!ctx) {
			return;
		}

		ctx.fillStyle = color;
		ctx.fillRect(0, 0, width, height);

		ctx.font = "25px din-round, sans-serif";
		ctx.fillStyle = "rgba(255, 255, 255, .7)";
		ctx.textAlign = "end";
		ctx.fillText(type === "target" ? "Preço (USD)" : "Duração", width - 20, 40);

		ctx.font = "40px din-round, sans-serif";
		ctx.fillStyle = "white";
		ctx.textAlign = "end";
		ctx.fillText(type === "target" ? maskAmount(price ?? 0, 2, undefined, "RTL") : durationFormat(duration ?? 0), width - 20, 80);

		ctx.font = "25px din-round, sans-serif";
		ctx.fillStyle = "rgba(255, 255, 255, .7)";
		ctx.textAlign = "end";
		ctx.fillText(type === "target" ? "Rebate" : "Rendimento a.a", width - 20, 120);

		ctx.font = "40px din-round, sans-serif";
		ctx.fillStyle = "white";
		ctx.textAlign = "end";
		ctx.fillText(maskAmount((type === "target" ? rebate ?? 0 : performance * 12) * 100, 2, "%", "RTL"), width - 20, 160);

		ctx.font = "25px din-round, sans-serif";
		ctx.fillStyle = "rgba(255, 255, 255, .7)";
		ctx.textAlign = "end";
		ctx.fillText("Quantia máxima", width - 20, 200);

		ctx.font = "30px din-round, sans-serif";
		ctx.fillStyle = "white";
		ctx.textAlign = "end";
		ctx.fillText(maskAmount(maxAmount ?? 0, 2, typeAmount, "RTL"), width - 20, 235);

		ctx.font = "25px din-round, sans-serif";
		ctx.fillStyle = "rgba(255, 255, 255, .8)";
		ctx.textAlign = "end";
		ctx.fillText(name, width - 10, height - 20);

		setImage(canvas.current.toDataURL());
	}, [type, color, name, duration, performance, maxAmount, typeAmount, price, rebate]);

	useEffect(() => {
		if (!applyStart || !applyEnd) {
			return;
		}

		const loop = () => {
			const dateNow = new Date().getTime();
			if (!applyStart || !applyEnd) {
				return;
			}
			const end = new Date(applyEnd).getTime();
			const lastPerformance = differenceInMonths(applyStart, end > dateNow ? dateNow : end) * performance;
			setLastIncome(parseFloat((amount * lastPerformance).toFixed(4)));
		};

		const interval = setInterval(loop, 2000);
		loop();

		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<>
			<ModernCard
				color={color}
				image={image}
				imageInView={true}
				colorInImage={true}
				headerStyle={{
					padding: "5px 15px 4px 15px",
				}}
				header={
					type === "target" ? (
						<Typography
							variant="subtitle1"
							style={{ opacity: 0.5, fontStyle: "italic" }}
						>
							Não há informações disponíveis ainda
						</Typography>
					) : (
						<>
							<div className={style["staking-card-header"]}>
								<div>
									<Typography
										variant="caption"
										style={{ opacity: 0.5 }}
									>
										Quantia aplicada ({typeAmount})
									</Typography>
									<Typography variant="body1">{maskAmount(amount ?? 0, 2, typeAmount, "RTL")}</Typography>
								</div>
								<div>
									<Typography
										variant="caption"
										style={{ opacity: 0.5 }}
									>
										Rendimento ({typeAmount})
									</Typography>
									<Typography variant="body1">{maskAmount(lastIncome ?? 0, 2, typeAmount, "RTL")}</Typography>
								</div>
								<div>
									<Typography
										variant="caption"
										style={{ opacity: 0.5 }}
									>
										Rendimento (%)
									</Typography>
									<Typography variant="body1">
										{maskAmount(((amount ?? 0) === 0 ? 0 : lastIncome / amount ?? 0) * 100, 2, "%", "RTL")}
									</Typography>
								</div>
								{applyStart && (
									<div>
										<Typography
											variant="caption"
											style={{ opacity: 0.5 }}
										>
											Data de aplicação
										</Typography>
										<Typography variant="body1">{new Date(applyStart).toLocaleDateString()}</Typography>
									</div>
								)}
								{applyEnd && (
									<div style={{ marginBottom: "15px" }}>
										<Typography
											variant="caption"
											style={{ opacity: 0.5 }}
										>
											Data de resgate
										</Typography>
										<Typography variant="body1">{new Date(applyEnd).toLocaleDateString()}</Typography>
									</div>
								)}
							</div>
							<div
								style={{
									width: "100%",
									border: "2px solid var(--text-primary)",
									borderRadius: "15px",
									opacity: 0.6,
								}}
							>
								<div
									style={{
										width: `${Math.min(
											100,
											100 - (minAmount > stakingAvailableAmount ? 0 : (stakingAmount - stakingAvailableAmount) / stakingAmount),
										).toFixed(2)}%`,
										minWidth: "10px",
										maxWidth: "100%",
										height: "10px",
										backgroundColor: "var(--text-primary)",
										borderRadius: "15px",
									}}
								></div>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									width: "100%",
									fontSize: "10px",
									opacity: 0.6,
									color: "var(--text-primary)",
									padding: "2px 2px 0px 2px",
								}}
							>
								<div>{maskAmount(minAmount ?? 0, 2, typeAmount, "RTL")}</div>
								<div>{maskAmount(maxAmount ?? 0, 2, typeAmount, "RTL")}</div>
							</div>
						</>
					)
				}
			/>
		</>
	);
};

const ViewStaking = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const [showStaking, setShowStaking] = useState<string | number | null>(0);
	const [stakingList, setStakingList] = useState<StakingInfo[]>([]);

	const openStaking = (id: string | number) => () => {
		setShowStaking((p) => (p === id ? null : id));
	};

	const applyStaking = (id: string | number) => () => {
		StakingApplyDialog.show({
			stakingId: id,
		}).then((list) => {
			setStakingList((p) => list ?? p);
			return 0;
		});
	};

	useEffect(() => {
		APIHelper.fetch("/staking", { toPage: "/staking" }, 25)
			.then((list: Response) => {
				setStakingList(() => list as unknown as StakingInfo[]);
			})
			.catch(() => {
				InteractionHelper.toast("Algo deu errado ao pegar os dados na base de dados, tente novamente mais tarde!", "", "warning");
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		const toApplyStaking = MultiStorager.DataStorager.set<(id: string | number) => void>("toApplyStaking", (id) => {
			StakingApplyDialog.show({
				stakingId: id,
			}).then((list) => {
				setStakingList((p) => list ?? p);
				return 0;
			});
		});

		return () => {
			toApplyStaking.delete();
		};
	}, []);

	const lengthApplyed = stakingList.filter(({ applyStart }) => applyStart !== null).length;

	return (
		<PageHeaderWrapper
			colorLight={"#9ccc65"}
			colorDark={"#558b2f"}
			isLoading={loading}
			title="Staking investimentos"
			headerStyle={{
				padding: 0,
			}}
			header={<StakingStatisticResume stakings={stakingList} />}
		>
			<div className={style["staking-cards"]}>
				{(stakingList as any)
					.concat([
						{
							type: "target",
							name: "TargetStake#01:IVIP",
							price: 0.0005,
							rebate: 0.1,
							applyStart: null,
							applyEnd: null,
						},
						{
							type: "target",
							name: "TargetStake#02:IVIP",
							price: 0.001,
							rebate: 0.15,
							applyStart: null,
							applyEnd: null,
						},
						{
							type: "target",
							name: "TargetStake#03:IVIP",
							price: 0.01,
							rebate: 0.2,
							applyStart: null,
							applyEnd: null,
						},
					])
					.map((props: StakingInfo, i: number, list: StakingInfo[]) => {
						return {
							...props,
							index: i,
							color: getColor(i, list.length),
						};
					})
					.sort((a: any, b: any) => {
						if (a.applyStart !== null && b.applyStart === null) {
							return -1;
						}
						if (a.applyStart === null && b.applyStart !== null) {
							return 1;
						}
						if (a.applyStart !== null && b.applyStart !== null) {
							return 0;
						}
						return a.index > b.index ? 1 : -1;
					})
					.map((props: StakingInfo & { color: string }, i: number, list: StakingInfo[]) => {
						return (
							<StakeCard
								key={i}
								{...props}
							/>
						);
					})}
			</div>

			{/* <div className={style["main"]}>
				{loading && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							color: "var(--text-primary)",
							marginTop: "20px",
							marginBottom: "20px",
						}}
					>
						<CircularProgress color="inherit" />
					</Box>
				)}

				{!loading &&
					(stakingList as any)
						.concat([
							{
								type: "target",
								name: "TargetStake#01:IVIP",
								price: 0.0005,
								rebate: 0.1,
							},
							{
								type: "target",
								name: "TargetStake#02:IVIP",
								price: 0.001,
								rebate: 0.15,
							},
							{
								type: "target",
								name: "TargetStake#03:IVIP",
								price: 0.01,
								rebate: 0.2,
							},
						])
						.map((props: StakingInfo, i: number, list: StakingInfo[]) => {
							return (
								<StakingCard
									key={i}
									{...props}
									color={getColor(i, list.length)}
									show={showStaking === i}
									onClick={openStaking(i)}
									onApply={applyStaking(props.id)}
								/>
							);
						})}
			</div> */}
		</PageHeaderWrapper>
	);
};

export default ViewStaking;
