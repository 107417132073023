import { mdiAccountHardHat } from '@mdi/js';
import MainComponent from './MainComponent';

export default {
    id: "UnderMaintenance",
    title: "Em manutenção",
    icon: mdiAccountHardHat,
    render: ()=>{
        return <MainComponent />
    }
}