import React, { useState } from 'react';
import { startOfMonth, endOfMonth, eachDayOfInterval, format, subMonths, addMonths } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import styles from './style.module.scss';
import { InteractionHelper } from 'Helper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

interface Event {
  id: string;
  summary: string;
  start: string;
  end: string;
}

interface CalendarProps {
  events: Event[];
  selectedMonth: Date;
  onMonthChange: (newMonth: Date) => void;
}

const Calendar: React.FC<CalendarProps> = ({ events, selectedMonth, onMonthChange }) => {
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [hoveredEvent, setHoveredEvent] = useState<string>('');

  const monthStart = startOfMonth(selectedMonth);
  const monthEnd = endOfMonth(selectedMonth);
  const daysOfMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const formattedStartDate = format(monthStart, 'yyyy-MM-dd');
  const formattedEndDate = format(monthEnd, 'yyyy-MM-dd');

  const handleMouseEnter = (eventId: string) => {
    setHoveredEvent(eventId);
  };

  const handleMouseLeave = () => {
    setHoveredEvent('');
  };

  const handleSummaryModal = (event: any) => {
    setSelectedEvent(event);
    if (selectedEvent) {
      InteractionHelper.alert(
        <>
          <h2>{selectedEvent.summary}</h2>
          <p><span>Início:</span> {selectedEvent.start}</p>
          <p><span>Prazo fim:</span> {selectedEvent.end}</p>
        </>
      )
    }
  };

  const handlePrevMonth = () => {
    const prevMonth = subMonths(selectedMonth, 1);
    onMonthChange(prevMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = addMonths(selectedMonth, 1);
    onMonthChange(nextMonth);
  };

  const days = daysOfMonth.map((day, index) => {
    const formattedDay = format(day, 'dd', { locale: ptBR });
    const weekday = format(day, 'eeee', { locale: ptBR });

    const dayEvents = events.filter((event: any) => {
      return event.start === format(day, 'yyyy-MM-dd') || event.end === format(day, 'yyyy-MM-dd');
    });

    const eventItems = dayEvents.map((event: any) => {
      let eventDate = '';

      if (event.start === format(day, 'yyyy-MM-dd')) {
        eventDate = event.start;
      } else if (event.end === format(day, 'yyyy-MM-dd')) {
        eventDate = event.end;
      }

      return (
        <li
          key={event.id}
          className={`${styles['ball']} ${hoveredEvent === event.id ? styles['highlighted'] : ''}`}
          onMouseEnter={() => handleMouseEnter(event.id)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleSummaryModal(event)}
        >
          {event.summary[0]}
        </li>
      );
    });

    return (
      <div className={styles['day-cell']} key={index}>
        <p className={styles['dayOfTheWeek']}>{weekday.charAt(0).toUpperCase() + weekday.slice(1)}</p>
        <p className={styles['dayOfTheWeekNumber']}>
          {formattedDay}
        </p>
        <ul className={styles['ball-list']}>
          {eventItems.length > 0 ? eventItems : (
            <li>Sem eventos</li>
          )}
        </ul>
      </div>
    );
  });

  return (
    <>
      <div className={styles['calendar-container']}>
        {days}
      </div>
    </>
  );
};

export default Calendar;
