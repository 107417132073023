import Button from "@mui/material/Button";
import "./index.css";

export default {
    id: "Tela3",
    title: "Tela 3",
    render: () => {
        return (
            <div>
                <div className="mover-griD-froM-scren-Two">
                    <div className="coMprar-aNd-venDer">
                        <div className="CoMprar-up">
                            <Button variant="text">Carteira iVip</Button>
                        </div>
                        <div className="VeNder-up">
                            <Button variant="text">Carteira iViPay</Button>
                        </div>
                    </div>
                    <div className="vaRiacao">
                        <div className="biticON-wiTh-usdt">
                            <div className="coMprar-ANd-venDer">
                                <div className="CoMprar-up">
                                    <Button variant="text">iVipCoin</Button>
                                </div>
                                <div className="VeNder-up">
                                    <Button variant="text">bnb</Button>
                                </div>
                            </div>
                            <div className="unificando-ivip-bnb">
                                <div className="numero-da-ivip">112,798</div>
                                <div className="numero-da-bnb">0.4926</div>
                            </div>
                            <div className="divisao"></div>
                        </div>
                    </div>
                </div>
                <div className="mover-griD-froM-scren-Two">
                    <div className="vaR">
                        <div className="btcivip">
                            <div className="unificando-ivipbnb">
                                <div className="numero-da-ivip-one">$1,000</div>
                                <div className="numero-da-bnb-one">$384.65</div>
                            </div>
                        </div>
                    </div>
                    <div className="vaRiacoes">
                        <div className="butocoes">
                            <div className="depositar">
                                <Button variant="text">depositar</Button>
                            </div>
                            <div className="leilão">
                                <Button variant="text">leilão</Button>
                            </div>
                            <div className="sacar">
                                <Button variant="text">sacar</Button>
                            </div>
                        </div>
                        <div className="bitecon">
                            <div className="coMprar-ANd-venDer">
                                <div className="iviPay">
                                    <Button variant="text">iViPay</Button>
                                </div>
                            </div>

                            <div className="unindo-ivip-ivipay">
                                <div className="numero-da-ivip-um">
                                    Carteira: 13.232.133
                                </div>
                                <div className="numero-da-ivip-two">
                                    livre: 10.000.000
                                </div>
                                <div className="numero-da-ivip-tres">
                                    usado:3.232.133
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    },
};
