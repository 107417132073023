import { useState } from "react";

import { Typography, TextField, FormControl, InputLabel, Select, MenuItem, Slider } from '@mui/material';

import style from "../style.module.scss";

import { maskAmount } from "../Utils";

import { currencyFormat } from "Utils";

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const formatDuration = (value = 0)=>{
    const duration_years = Math.floor(value/12);
    const duration_months = value%12;
    let duration_format = [];

    if(duration_years > 0){
        duration_format.push(duration_years > 1 ? `${duration_years} anos` : `um ano`);
    }

    if(duration_months > 0){
        duration_format.push(duration_months > 1 ? `${duration_months} meses` : `um mês`);
    }

    return capitalize(duration_format.join(" e "));
}

const SimulateStakingApply = ({byProps, onChange})=>{
    const props = new Proxy(typeof byProps === "object" ? byProps : {}, {
        set: function(target, property, value, receiver) {
            target[property] = value;
            if(typeof onChange === "function"){
                onChange(target);
            }
            return true;
        }
    });

    const [amount, setAmount] = useState(typeof props.amount === "number" ? props.amount : 0);

    const change = (value)=>{
        value = parseFloat(value) ?? 0;
        value = isNaN(value) ? 0 : value;
        setAmount(value);
        props.amount = value;
    }

    const inputError = props.valid_allowed_amount <= 0 || amount > props.valid_allowed_amount || amount < props.min_allowed_amount;

    return <div className={style["dialogPayment"]}>
        <div className={style["amount"]}>
            <TextField
                hiddenLabel
                value={amount}
                onChange={({target: { value }})=> change(value)}
                size="small"
                variant="standard"
                inputProps={{
                    style: {fontSize: "30px", textAlign: "center"}
                }}
                error={inputError}
            />

            <Slider 
                min={props.min_allowed_amount} max={props.max_allowed_amount} step={1} 
                value={Math.min(props.max_allowed_amount, Math.max(props.min_allowed_amount, amount))} 
                onChange={({target: { value }})=> change(value)} valueLabelDisplay="off"
                color={inputError ? "error" : "primary"}
                marks={[
                    { value: props.min_allowed_amount, label: maskAmount(props.min_allowed_amount).split(" ").pop() },
                    { value: props.valid_allowed_amount, label: maskAmount(props.valid_allowed_amount).split(" ").pop() },
                    { value: props.max_allowed_amount, label: "" }
                ]}
            />

            {inputError && <Typography variant="body2">{
                props.max_allowed_amount <= 0 ? "Não há moedas suficiente" : 
                amount > props.valid_allowed_amount ? `Valor máximo aceitável é ${maskAmount(props.valid_allowed_amount).split(" ").pop()}` : 
                amount < props.min_allowed_amount ? `Valor mínimo aceitável é ${maskAmount(props.min_allowed_amount).split(" ").pop()}` : 
                " "
            }</Typography>}
        </div>

        <div className={style["description"]}>
            <Typography variant="body2">Você está prestes a investir na {props.stakingInfo.name}, com um investimento de {currencyFormat.convert(props.stakingInfo.stakingAvailableAmount)} {props.stakingInfo.typeAmount} de um total de {currencyFormat.convert(props.stakingInfo.stakingAmount)} {props.stakingInfo.typeAmount} disponíveis para aplicação. É importante lembrar que o seu saldo atual é de {currencyFormat.convert(props.available_amount)} {props.stakingInfo.typeAmount}, dos quais você pode utilizar para esse tipo de investimento. Nessa Staking específica, o valor mínimo de investimento é de {currencyFormat.convert(props.stakingInfo.minAmount)} {props.stakingInfo.typeAmount} e o máximo é de {currencyFormat.convert(props.stakingInfo.maxAmount)} {props.stakingInfo.typeAmount}.</Typography>
            <br/>
            <Typography variant="body2">Ao realizar o investimento nessa Staking, é necessário aguardar um prazo de {formatDuration(props.stakingInfo.duration)} para que o valor investido seja resgatado automaticamente, juntamente com a porcentagem de rendimento correspondente (no caso dessa Staking, {currencyFormat.convert(parseFloat(((props.stakingInfo.performance*12)*100).toFixed(4)))}% ao ano).</Typography>
        </div>
    </div>
}

export default SimulateStakingApply;