import { useState, forwardRef, useImperativeHandle, useRef, useEffect, useLayoutEffect, Fragment } from 'react';
import { isDate } from 'Utils';

let months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAIO', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];

const onTouch = (evt)=>{
    if(evt.touches.length > 1 || (evt.type === "touchend" && evt.touches.length > 0)) return;
    //evt.preventDefault();

    const newEvt = document.createEvent("MouseEvents");
    let type = null;
    let touch = null;

    switch(evt.type){
        case "touchstart":
            type = "mousedown";
            touch = evt.changedTouches[0];
            break;
        case "touchmove":
            type = "mousemove";
            touch = evt.changedTouches[0];
            break;
        case "touchend":
            type = "mouseup";
            touch = evt.changedTouches[0];
            break;
    }

    newEvt.initMouseEvent(type, true, true, evt.target?.ownerDocument?.defaultView, 0, touch.screenX, touch.screenY, touch.clientX, touch.clientY, evt.ctrlKey, evt.altKey, evt.shiftKey, evt.metaKey, 0, null);
    evt.target.dispatchEvent(newEvt);
    return true;
}

const TimeLine = forwardRef(({ dateList, margin_bottom, margin_right, width_signal, height_drag, width, height, timeline_color, timeline_anchor, onScroll }, ref)=>{
    const [indicator_list, set_indicator_list] = useState([]);

    const [months_label, set_months_label] = useState([]);
    const [years_label, set_years_label] = useState([]);

    const [translateX, setTranslateX ] = useState(0);
    const position = useRef(0);
    const [range_type, set_range_type] = useState("hours");
    const mainRef = useRef();
    const rectDragRef = useRef();
    const xDividerPos = useRef();

    const getPosition = ()=>{
        let timeline_width = (indicator_list.length * width_signal) + margin_right;
        let timeline_position = Math.max(0, Math.min(timeline_width, timeline_width-position.current)) - width;
        return timeline_position * (-1);
    }

    useLayoutEffect(()=>{
        if(typeof onScroll === "function"){
            onScroll(getPosition());
        }
    }, [indicator_list, width, width_signal, margin_right, translateX]);

    useLayoutEffect(()=>{
        position.current = 0;
        setTranslateX(getPosition);
    }, [indicator_list]);

    useImperativeHandle(ref, () => {
        return mainRef.current;
    }, [mainRef]);

    const updateLabelsPosition = ()=>{
        if(!mainRef.current){ return; }

        const el = mainRef.current.querySelector("g.timeline_content");

        let timelineAnchor = String(timeline_anchor).toLowerCase();
        timelineAnchor = ["start", "end", "middle"].includes(timelineAnchor) ? timelineAnchor : "middle";

        const translateX = getPosition();

        const labelsList = Array.from(el.querySelectorAll("g.timeline_months > text, g.timeline_years > text"));

        let labelWidth = labelsList.reduce((c, t) => {
            let { width } = t.getBoundingClientRect();
            return Math.max(width * 1.5, c);
        }, 20);

        labelsList.forEach((t, i)=>{
            let start = translateX + (parseFloat(t.getAttribute("start") || "0") * width_signal);
            let end = translateX + (parseFloat(t.getAttribute("end") || "0") * width_signal);

            let { width: width_text } = t.getBoundingClientRect();

            if(start > width + width_text || end < -width_text){ 
                t.style.opacity = "0";
                return; 
            }

            t.style.opacity = "1";

            let to = timelineAnchor === "middle" ? width/2 : timelineAnchor === "end" ? width-labelWidth : labelWidth;

            t.setAttribute("x", Math.round(Math.max(Math.min(to, end - width_text), start + width_text)));
        });
    }

    useEffect(()=>{
        setTranslateX(getPosition);
    }, [indicator_list, margin_right, margin_bottom, width_signal, width, timeline_anchor]);

    useEffect(()=>{
        updateLabelsPosition();
    }, [indicator_list, width_signal, width, timeline_anchor, translateX]);

    useEffect(()=>{
        let list = (Array.isArray(dateList) ? dateList : []).filter(isDate).map(d => new Date(d)).sort((a, b)=> a > b ? 1 : a < b ? -1 : 0);

        if(JSON.stringify(indicator_list) === JSON.stringify(list)){ return; }

        const isDiffDays = list.every((date, i, self) =>{
            if(i === 0) return true;
            const diferenca = Math.abs((date.getTime() - self[i-1].getTime())/(1000 * 60 * 60 * 24));
            return Math.floor(diferenca) === diferenca;
        });

        set_range_type(isDiffDays ? "days" : "hours");
        set_indicator_list(()=> list);

        set_months_label(()=> Object.entries(list.map(date => months[date.getMonth()]).reduce((acc, curr, index)=>{
            acc[curr] = Array.isArray(acc[curr]) ? [acc[curr][0], index] : [Math.max(0, index-1), index];
            return acc;
        }, {})));

        set_years_label(()=> Object.entries(list.map(date => date.getFullYear().toString()).reduce((acc, curr, index)=>{
            acc[curr] = Array.isArray(acc[curr]) ? [acc[curr][0], index] : [Math.max(0, index-1), index];
            return acc;
        }, {})));
    }, [ dateList ]);

    useLayoutEffect(()=>{
        const onMouseHoldDown = (e)=>{
            e.preventDefault();
            xDividerPos.current = e.clientX;
            document.body.style.cursor = "move";
        };

        const onWheelHold = (e)=>{
            e.preventDefault();
            const { wheelDelta } = e;
            position.current += width_signal * (wheelDelta > 0 ? 2 : -2);
            position.current = Math.max(0, Math.min((indicator_list.length * width_signal) - (width/2), position.current));
            setTranslateX(getPosition);
        }

        rectDragRef.current?.addEventListener("wheel", onWheelHold, { passive: false });
        rectDragRef.current?.addEventListener("mousedown", onMouseHoldDown, { passive: false });
        rectDragRef.current?.addEventListener("touchstart", onTouch, { passive: false });

        return ()=>{
            rectDragRef.current?.removeEventListener("wheel", onWheelHold, { passive: false });
            rectDragRef.current?.removeEventListener("mousedown", onMouseHoldDown, { passive: false });
            rectDragRef.current?.removeEventListener("touchstart", onTouch, { passive: false });
        }
    }, [rectDragRef, indicator_list, width_signal, width, margin_right]);

    useLayoutEffect(()=>{
        const onMouseHoldUp = ()=>{
            if(!xDividerPos.current){ return; }
            xDividerPos.current = null;
            document.body.style.cursor = "";
        };

        const onMouseHoldMove = (e)=>{
            if(!xDividerPos.current){ return; }
            e.preventDefault();
            position.current = Math.max(0, Math.min((indicator_list.length * width_signal) - (width/2), position.current + e.clientX - xDividerPos.current));
            setTranslateX(getPosition);
            xDividerPos.current = e.clientX;
        };

        document.addEventListener("mouseup", onMouseHoldUp);
        document.addEventListener("mousemove", onMouseHoldMove);
        document.addEventListener("touchend", onTouch, { passive: false });
        document.addEventListener("touchmove", onTouch, { passive: false });

        return ()=>{
            document.removeEventListener("mouseup", onMouseHoldUp);
            document.removeEventListener("mousemove", onMouseHoldMove);
            document.removeEventListener("touchend", onTouch, { passive: false });
            document.removeEventListener("touchmove", onTouch, { passive: false });
        }
    }, [ indicator_list, width_signal, width ]);
        
    let width_drag = width;

    return <g ref={mainRef} className="timeline_drag" transform={`translate(0, ${height-margin_bottom-height_drag})`} style={{
        opacity: "0.6",
        transition: "opacity 0.2s ease-in-out 0s"
    }}>
        <g className="timeline_content">
            <g transform={`translate(${translateX}, 0)`}>
                <path d={indicator_list.map((date, i, self)=>{
                    const nextDate = i < self.length-1 ? self[i+1] : self[i-1];
                    let lineHeight = 2;

                    if(date.toLocaleDateString() === nextDate.toLocaleDateString()){
                        lineHeight = 2;
                    }else if(date.getMonth() === nextDate.getMonth()){
                        lineHeight = 10;
                    }else{
                        lineHeight = 20;
                    }

                    return `M${i*width_signal} 0 L${i*width_signal} ${lineHeight} Z`;
                }).join(" ")} stroke={timeline_color} strokeWidth="2" fill="none"/>
            </g>

            <g className="timeline_months" transform="translate(0, 0)" opacity="1">
                {months_label.map(([month, [start, end]], i)=>{
                    return <text key={i} x="0" y="0" transform="translate(0, 16)" fill={timeline_color} fontSize="10px" dominantBaseline="central" textAnchor="middle" start={start} end={end}>{month}</text>
                })}
            </g>
            <g className="timeline_years" transform="translate(0, 15)" opacity="1">
                {years_label.map(([year, [start, end]], i, self)=>{
                    return <Fragment key={i} >
                        <text x="0" y="0" transform={`translate(0, 15)`} fill={timeline_color} fontSize="14px" dominantBaseline="central" textAnchor="middle" fontWeight="bold" start={start} end={end}>{year}</text>
                        {i < self.length-1 && <circle cx={end*width_signal} cy="15" r="5" stroke="none" fill={timeline_color} />}
                    </Fragment>
                })}
            </g>
        </g>
        <rect ref={rectDragRef} x="0" y="0" width={width_drag} height={height_drag} fill={"transparent"} stroke="none"/>
    </g>;
});

export default TimeLine;