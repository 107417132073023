import React from "react";
import style from "./style.module.scss";
import { mdiArchiveCheck, mdiArchiveStar } from "@mdi/js";
import { SvgIcon } from "Components";

//https://webartdevelopers.com/blog/39-ridiculous-things-to-do-with-css3-box-shadows/
//https://www.cssscript.com/bubble-confetti-effect-button/

interface AwardsItemProps {
	[p: string]: any;
	title?: string;
	icon?: string;
	conqueredIcon?: string;
	requires?: number;
}

interface ProgressPrizesProps {
	value?: number;
	awards: Array<
		AwardsItemProps & {
			onClick?: (award: AwardsItemProps) => void;
		}
	>;
}

const ProgressPrizes: React.FC<ProgressPrizesProps> = ({ value = 0, awards = [] }) => {
	const preparedAwards = awards.map(({ requires, ...item }) => {
		return { ...item, requires, before: { start: 0, end: 1, countPercent: 0 }, after: { start: 1, end: 1, countPercent: 0 } };
	});

	for (let i = 0; i < preparedAwards.length; i++) {
		const { requires } = preparedAwards[i];

		if (typeof requires !== "number") {
			let indexStart = awards.findIndex((item, index) => index < i && typeof item.requires === "number");
			let indexEnd = awards.findIndex((item, index) => index > i && typeof item.requires === "number");

			const start = indexStart < 0 ? 0 : (awards[indexStart].requires as number);
			const end = indexEnd < 0 ? 1 : (awards[indexEnd].requires as number);

			indexStart = indexStart < 0 ? -1 : indexStart;
			indexEnd = indexEnd < 0 ? preparedAwards.length : indexEnd;

			const length = Math.abs(indexEnd - indexStart) - 1;
			const countPercent = (end - start) / (length + 1);

			preparedAwards[i].before.end = start + countPercent * (i - indexStart);
		} else {
			preparedAwards[i].before.end = requires;
		}
	}

	for (let i = 0; i < preparedAwards.length; i++) {
		preparedAwards[i].before.start = (preparedAwards[i - 1] ?? { before: { end: 0 } }).before.end;
		preparedAwards[i].before.countPercent = preparedAwards[i].before.end - preparedAwards[i].before.start;
		preparedAwards[i].after.start = preparedAwards[i].before.end;
		preparedAwards[i].after.end = (preparedAwards[i + 1] ?? { before: { start: 1 } }).before.end;
		preparedAwards[i].after.countPercent = preparedAwards[i].after.end - preparedAwards[i].after.start;
	}

	return (
		<div className={style.main}>
			<div className={style["achievements"]}>
				{preparedAwards.map(({ title = "", before, after, icon = mdiArchiveStar, conqueredIcon = mdiArchiveCheck }, i, self) => {
					const isActive = before.end <= value;
					const progressDateStart = Math.max(before.start, Math.min(value, before.end)) - before.start;
					const progressDateEnd = Math.max(after.start, Math.min(value, after.end)) - after.start;

					return (
						<React.Fragment key={i}>
							<div
								className={style["progressBar"]}
								style={
									{
										"--progress": Math.floor((progressDateStart / (before.end - before.start)) * 100) + "%",
										"opacity": i > 0 && progressDateStart === 0 ? 0 : 1,
									} as React.CSSProperties & { [p: string]: any }
								}
							></div>
							<div className={isActive ? style.active : undefined}>
								<SvgIcon path={!isActive ? icon : conqueredIcon} />
							</div>
							{i >= self.length - 1 && after.start !== 1 && (
								<div
									className={style["progressBar"]}
									style={
										{
											"--progress": Math.floor((progressDateEnd / (after.end - after.start)) * 100) + "%",
											"opacity": i > 0 && progressDateStart === 0 ? 0 : 1,
										} as React.CSSProperties & { [p: string]: any }
									}
								></div>
							)}
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
};

export default ProgressPrizes;
