import React, { Component } from "react";

export default class Page_404 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div></div>;
  }
}
