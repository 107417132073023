import React, { useState } from 'react'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import AddIcon from '@mui/icons-material/Add'
import PercentIcon from '@mui/icons-material/Percent'
import RemoveIcon from '@mui/icons-material/Remove'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import style from './style.module.scss'
import TradingViewWidget from './Components/TradingView'
import Button from '@mui/material/Button'

const ConversaoRapida = () => {
  const [selectedOption, setSelectedOption] = useState('')
  const [quantity, setQuantity] = useState(0)
  const [attachSelected, setAttachSelected] = useState(false)
  const [percentSelected, setPercentSelected] = useState(false)
  const [price, setPrice] = useState(0)
  const [stopOf, setStopOf] = useState(0)
  const [qtd, setQtd] = useState(0)
  const [total, setTotal] = useState(0)

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value)
  }

  const selectIcon = (icon: string) => {
    if (icon === 'attach') {
      setAttachSelected(true)
      setPercentSelected(false)
    } else if (icon === 'percent') {
      setAttachSelected(false)
      setPercentSelected(true)
    }
  }

  const controlPrice = (type: string) => {
    if (type === 'increase') {
      setPrice((prevPrice) => prevPrice + 1)
    } else if (type === 'decrease' && price > 0) {
      setPrice((prevPrice) => prevPrice - 1)
    }
  }

  const stopOfPrice = (type: string) => {
    if (type === 'increase') {
      setStopOf((prevPrice) => prevPrice + 1)
    } else if (type === 'decrease' && price > 0) {
      setStopOf((prevPrice) => prevPrice - 1)
    }
  }

  const controlQtd = (type: string) => {
    if (type === 'increase') {
      setQtd((prevPrice) => prevPrice + 1)
    } else if (type === 'decrease' && qtd > 0) {
      setQtd((prevPrice) => prevPrice - 1)
    }
  }

  const setTotalControl = (type: string) => {
    if (type === 'increase') {
      setTotal((prevTotal) => prevTotal + 1)
    } else if (type === 'decrease' && total > 0)
      setTotal((prevTotal) => prevTotal + 1)
  }

  return (
    <div className={style['root']}>
      <div className={style['tradingview-main-content']}>
        <div className={style['trading-view-left']}>
          <TradingViewWidget />
        </div>
        <div className={style['trading-view-right']}>
          <div className={style['trading-chart-options']}>
            <div className={style['trading-chart-strategy']}>
              <p className={style['trading-chart-item']}>Estratégia</p>
              <Select
                value={selectedOption}
                onChange={handleChange}
                displayEmpty
                variant="outlined"
                className={style['select_menu']}
              >
                <MenuItem value="" disabled>
                  Selecione uma opção
                </MenuItem>
                <MenuItem className={style['select_menu_item']} value="option1">
                  Opção 1
                </MenuItem>
                <MenuItem className={style['select_menu_item']} value="option2">
                  Opção 2
                </MenuItem>
                <MenuItem className={style['select_menu_item']} value="option3">
                  Opção 3
                </MenuItem>
              </Select>
              <div className={style['add_icon']}>
                <AddIcon
                  className={style['add_icon_image']}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>
            <div className={`${style['trading-chart-price']} ${style['']}`}>
              <p className={style['trading-chart-item']}>Preço</p>
              <input
                className={`${style['trading-chart-item']} ${style['input']}`}
                type="text"
                name=""
                id=""
                value={price}
              />
              <div className={style['control-icons']}>
                <AddIcon
                  onClick={() => {
                    controlPrice('increase')
                  }}
                />
                <RemoveIcon
                  onClick={() => {
                    controlPrice('decrease')
                  }}
                />
              </div>
            </div>
            <div className={style['trading-chart-stop']}>
              <p className={style['trading-chart-item']}>Stop Of.</p>
              <input
                className={`${style['trading-chart-item']} ${style['input']}`}
                type="text"
                name=""
                id=""
                value={stopOf}
              />
              <div className={style['control-icons']}>
                <AddIcon
                  onClick={() => {
                    stopOfPrice('increase')
                  }}
                />
                <RemoveIcon
                  onClick={() => {
                    stopOfPrice('decrease')
                  }}
                />
              </div>
            </div>
            <div className={style['trading-chart-quantity']}>
              <p className={style['trading-chart-item']}>Qtd.</p>
              <input
                className={`${style['trading-chart-item']} ${style['input']}`}
                type="text"
                name=""
                id=""
                value={qtd}
              />
              <div className={style['control-icons']}>
                <AddIcon
                  onClick={() => {
                    controlQtd('increase')
                  }}
                />
                <RemoveIcon
                  onClick={() => {
                    controlQtd('decrease')
                  }}
                />
              </div>
            </div>
            <div className={style['trading-chart-total-buttons-container']}>
              <div className={style['trading-chart-total-buttons']}>
                <button className={style['trading-chart-total-buttons-item']}>
                  1
                </button>
                <button className={style['trading-chart-total-buttons-item']}>
                  2
                </button>
                <button className={style['trading-chart-total-buttons-item']}>
                  3
                </button>
              </div>
              <div className={style['trading-chart-total-result']}>
                <p
                  id={style['total-id']}
                  className={style['trading-chart-item']}
                >
                  Total.
                </p>
                <input
                  className={`${style['trading-chart-item']} ${style['input']}`}
                  type="text"
                  name=""
                  id=""
                  value={total}
                />
                <div className={style['control-icons']}>
                  <AddIcon
                    onClick={() => {
                      setTotalControl('increase')
                    }}
                  />
                  <RemoveIcon
                    onClick={() => {
                      setTotalControl('decrease')
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={style['button-group']}>
            <button className={`${style['button']} ${style['orange']}`}>
              C Limite
            </button>
            <button className={`${style['button']} ${style['green']}`}>
              V Stop
            </button>
            <button className={`${style['button']} ${style['orange']}`}>
              C Mercado
            </button>
            <button className={`${style['button']} ${style['green']}`}>
              V Mercado
            </button>
            <button className={`${style['button']} ${style['orange']}`}>
              <p>C Ask 102 370</p>
              {/* <p>102 370</p> */}
            </button>
            <button className={`${style['button']} ${style['green']}`}>
              <p>V Bid 102 365</p>
              {/* <p>102 365</p> */}
            </button>
            <button className={`${style['button']} ${style['grey']}`}>
              Cancel Order
            </button>
            <button className={`${style['button']} ${style['grey']}`}>
              Inverter
            </button>
            <button className={`${style['button-reset']} ${style['button']}`}>
              Zerar
            </button>
            <button className={`${style['button-cancel']} ${style['button']}`}>
              Cancelar ordens + zerar
            </button>
            <div className={style['result-container']}>
              <p className={style['result']}>
                <p>Resultado</p>
                <div>
                  <AttachMoneyIcon
                    className={attachSelected ? style['attach-money'] : ''}
                    onClick={() => {
                      selectIcon('attach')
                    }}
                  />
                  <PercentIcon
                    className={percentSelected ? style['percent-icon'] : ''}
                    onClick={() => {
                      selectIcon('percent')
                    }}
                  />
                </div>
              </p>
              <div className={style['result_resume']}>
                <div className={style['result_opened']}>
                  Res. Aberto <span>0,00</span>
                </div>
                <div className={style['result_daily']}>
                  Res. do Dia{' '}
                  <span className={style['result_day']}>R$ -21,50</span>
                </div>
              </div>
            </div>
            <div className={style['result-medium-container']}>
              <div className={style['result-medium']}>
                Médio<span>R$ 0,00</span>
              </div>
              <div className={style['result-medium-total']}>
                Total <span>R$ 0,00</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConversaoRapida
