import React, { useState, useEffect, useLayoutEffect, useCallback, forwardRef } from "react";

import { Box, Chip, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import { mdiCheckboxBlankCircle } from "@mdi/js";

import { SvgIcon } from "Components";

import style from "./style.module.scss";

interface SelectListProps {
	list: Array<{
		[k: string]: any;
		icon?: string | JSX.Element | null;
		label: string;
		description?: string;
		value: string | number;
	}>;
	filters?: Array<{
		label: string;
		value: string;
		dataIndex: string;
	}>;
	disabled?: Array<string | number>;
	hidden?: Array<string | number>;
	value?: string | number;
	onSelect?: (value: string | number, index: number) => void;
	onChange?: (value: string | number, index: number) => void;
}

const SelectList: React.FC<SelectListProps> = ({ list, filters = [], disabled, hidden = [], value, onSelect, onChange }) => {
	const [index, setIndex] = useState<number>(-1);
	const [valueFilter, setValueFilter] = useState<{
		value: string;
		dataIndex: string;
	} | null>();

	const getIndexValid = useCallback(
		(i: number = 0) => {
			i = typeof i === "number" ? Math.min(Math.max(0, i), list.length - 1) : 0;

			const visitedIndex = [];

			const d = (disabled ?? []).map((s) => list.findIndex(({ value }, index) => value === s || index === s));

			while (d.includes(i) && visitedIndex.length <= list.length) {
				i = Math.min(Math.max(0, i + 1), list.length - 1);

				if (visitedIndex.length >= list.length) {
					i = -1;
				}

				visitedIndex.push(i);
			}

			return i;
		},
		[list, disabled],
	);

	useLayoutEffect(() => {
		//setIndex(getIndexValid(0));
	}, [list, disabled]);

	useEffect(() => {
		value = !value ? 0 : value;
		const index = list.findIndex(({ value: v }, index) => v === value || index === value);
		setIndex(getIndexValid(index));
	}, [list, disabled, value]);

	useEffect(() => {
		if (typeof onChange === "function" && list.length > 0) {
			onChange(typeof list[index] === "object" && "value" in list[index] ? list[index].value : index, index);
		}
	}, [list, index]);

	const handleClick = (i: number) => {
		setIndex(i);
		if (typeof onSelect === "function" && list.length > 0) {
			onSelect(typeof list[i] === "object" && "value" in list[i] ? list[i].value : i, i);
		}
	};

	return (
		<Box sx={{ width: "100%" }}>
			<style>{`
            .MuiListItemButton-root{
                border: 1px solid transparent;
                margin: 2px 0px;
            }

            .MuiListItemButton-root.Mui-selected {
                border: 1px solid var(--primary-main);
            }
        `}</style>
			{filters.length > 0 && (
				<div className={style["filters"]}>
					<div className={style["options"]}>
						<Chip
							color="info"
							size="small"
							label="Todos"
							variant={!valueFilter ? "filled" : "outlined"}
							onClick={() => setValueFilter(null)}
						/>

						{filters.map(({ label, value, dataIndex }, index) => {
							return (
								<Chip
									key={index}
									color="info"
									size="small"
									label={label}
									variant={
										valueFilter && dataIndex === valueFilter.dataIndex && value === valueFilter.value ? "filled" : "outlined"
									}
									onClick={() => setValueFilter({ value, dataIndex })}
								/>
							);
						})}
					</div>
				</div>
			)}
			<ListItem style={{ flexDirection: "column", alignItems: "stretch", padding: "0px" }}>
				{list.map((option, i) => {
					const { icon, label, value, description, ...props } = option;
					const isHidden =
						(valueFilter && props[valueFilter.dataIndex] !== valueFilter.value) || hidden.includes(value) || hidden.includes(i);
					return (
						!isHidden && (
							<ListItemButton
								key={i}
								selected={index === i}
								disabled={(disabled ?? []).includes(value) || (disabled ?? []).includes(i)}
								style={{ borderRadius: "10px" }}
								onClick={(e) => handleClick(i)}
							>
								{icon && (
									<ListItemIcon>
										{icon ? typeof icon === "string" ? <SvgIcon path={icon} /> : icon : <SvgIcon path={mdiCheckboxBlankCircle} />}
									</ListItemIcon>
								)}
								<ListItemText
									primary={label}
									secondary={description}
								/>
							</ListItemButton>
						)
					);
				})}
			</ListItem>
		</Box>
	);
};

export default SelectList;

// export default forwardRef((props, ref) => <SelectList {...props} forwardedRef={ref} />);
