import React, { useState, useEffect, useReducer, createContext } from "react";

import { Box, Paper, Typography, Divider, Link, CircularProgress } from "@mui/material";

import { SelectList, InputCustom, SvgIcon } from "Components";

import { Wallet, Usuario } from "Models";

import WalletHelper, { useWallets } from "Helper/WalletHelper";

import { InteractionHelper, UserHelper } from "Helper";

import { mdiWalletPlusOutline } from "@mdi/js";

import { currencyFormat } from "Utils";

import BrowsersList from "./BrowsersList";
import style from "../style.module.scss";
import DialogTerm from "./DialogTerm";
import { NewWalletDialog } from "Dialog";

const BodyList: React.FC<{
	list: [string, string][];
	category: {
		[k: string]: {
			label: string;
			value: string;
		};
	};
	defaltValue?: number;
	handle?: (value: any, index: any) => void;
}> = ({ category, list, defaltValue, handle }) => {
	const [options] = useState(
		list.map((option, i) => {
			const [type, name] = option;

			return {
				icon: Wallet.walletIcon({ type } as any),
				label: name,
				value: type,
				category: category[type].value,
			};
		}),
	);

	const [filters] = useState(
		Object.values(category)
			.map(({ label, value }) => {
				return {
					label,
					value,
					dataIndex: "category",
				};
			})
			.filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i),
	);

	return (
		<SelectList
			list={options}
			filters={filters}
			onChange={handle}
			disabled={["IVIPCOIN"]}
			value={defaltValue}
		/>
	);
};

const disabledItem = ["GATE", "OKX", "KUCOIN", "TRUSTWALLET", "METAMASK", "IVIPCOIN", "CM_CAPITAL"];

const newWallet = (props: { selectType: number } = { selectType: 0 }) => {
	const list = Object.entries(Wallet.walletTypeList());
	const category = Wallet.walletCategoryList() as { [k: string]: { label: string; value: string } };

	InteractionHelper.confirm(
		<BodyList
			list={list}
			category={category}
			defaltValue={props.selectType}
			handle={(value: any, index: any) => {
				props.selectType = index;
			}}
		/>,
		"Exchanges e Carteiras",
	)
		.then(() => {
			if (disabledItem.includes(list[props.selectType][0])) {
				InteractionHelper.toast("Tipo de carteira indisponível no momento", "", "error");
				newWallet(props);
				return;
			}
			NewWalletDialog.show({
				type: list[props.selectType][0] as any,
				data: {},
				wallet: new Wallet(),
			})
				.then((response) => {
					if (response instanceof Wallet) {
						InteractionHelper.toast("Carteira salva com sucesso", "", "success");
						return;
					}
					newWallet(props);
				})
				.catch((e) => {
					InteractionHelper.toast(String(e), "", "error");
					newWallet(props);
				});
			//DialogTerm(list[props.selectType][0]);
		})
		.catch(() => Promise.resolve());
};

const WalletList: React.FC<{
	onClick?: (wallet: Wallet) => void;
}> = ({ onClick }) => {
	const [checkStatus, setCheckStatus] = useState<Array<"loading" | "pending" | "success" | "error">>([]);
	const [user, setUser] = useState<Usuario | null>(null);
	const { wallets, status } = useWallets();

	useEffect(() => {
		UserHelper.getUser()
			.then((user) => setUser(() => user))
			.catch(() => {
				setUser(null);
			});
	}, []);

	useEffect(() => {
		setCheckStatus((checkStatus) => {
			if (checkStatus[checkStatus.length - 1] === status) {
				return checkStatus;
			}
			checkStatus.push(status);
			return checkStatus;
		});
	}, [status]);

	const isLoading =
		Array.isArray(wallets) !== true || (wallets.length <= 0 && ["", "loading", "pending", "loading;pending"].includes(checkStatus.join(";")));

	if (isLoading || (Array.isArray(wallets) && wallets.length <= 0)) {
		return (
			<div className={style["WalletList"]}>
				<Paper
					elevation={3}
					className={style["WalletList-loading"]}
				>
					<CircularProgress color="inherit" />
				</Paper>

				<div className={style["WalletDefaultSpace"]}></div>
				<div className={style["WalletDefaultSpace"]}></div>
				<div className={style["WalletDefaultSpace"]}></div>
				<div className={style["WalletDefaultSpace"]}></div>
				<div className={style["WalletDefaultSpace"]}></div>
				<div className={style["WalletDefaultSpace"]}></div>
			</div>
		);
	}

	const selectWallet = (i: number) => {
		if (!onClick || typeof onClick !== "function") {
			return;
		}
		onClick(wallets[i]);
	};

	const permission = user instanceof Usuario ? user.verificarPermissao(["root", "desenvolvedor", "restrito"]) : false;

	return (
		<div className={style["WalletList"]}>
			{wallets.map((wallet, i) => {
				if (!(wallet instanceof Wallet)) {
					return;
				}
				const { nome, type, dataCriacao, totalValue, currencyType } = wallet;
				const typeName = Wallet.validType(type) ? Wallet.walletTypeList()[type] : "";
				return (
					<Paper
						elevation={3}
						key={"WalletList-item-" + i}
						onClick={() => {
							selectWallet(i);
						}}
					>
						<div className={style["WalletLogo"]}>{Wallet.walletIcon({ type })}</div>
						<div className={style["WalletInfo"]}>
							<Typography variant="overline">{nome}</Typography>
							<Typography variant="caption">{wallet.getWalletCode()}</Typography>
						</div>
						<div className={style["WalletPrice"]}>
							<Typography variant="h5">{currencyFormat.convert(totalValue, currencyType)}</Typography>
						</div>
						<div className={style["WalletDate"]}>
							<Typography variant="caption">{new Date(dataCriacao).toLocaleDateString()}</Typography>
						</div>
						<div className={style["WalletType"]}>
							<Typography variant="overline">{typeName}</Typography>
						</div>
					</Paper>
				);
			})}

			<Paper
				elevation={3}
				className={style["WalletList-btn-new"]}
				onClick={() => newWallet()}
			>
				<SvgIcon path={mdiWalletPlusOutline} />
				<Typography variant="h6">NOVA CARTEIRA</Typography>
			</Paper>

			<div className={style["WalletDefaultSpace"]}></div>
			<div className={style["WalletDefaultSpace"]}></div>
			<div className={style["WalletDefaultSpace"]}></div>
			<div className={style["WalletDefaultSpace"]}></div>
			<div className={style["WalletDefaultSpace"]}></div>
			<div className={style["WalletDefaultSpace"]}></div>
		</div>
	);
};

export default WalletList;
