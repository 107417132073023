import { APIHelper } from 'Helper';

export default class ResponseHelper{

    static async getCountry(){
       return await APIHelper.fetch("utils/country/list")
    }

    static async getLanguages(){
        return await APIHelper.fetch("utils/country/languages")
    }

    static async getCep(cep) {
        return await APIHelper.fetch(`utils/cep/${cep}`)
    }
}