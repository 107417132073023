import Button from "@mui/material/Button";
import Tab from "../../Components/Tab";
import "./index.css";

export default {
    id: "Tela7",
    title: "Tela 7",
    render: () => {
        return (
            <div>
                <div className="mover-griD-froM-scren-Ttwo">
                    <div className="RankingPas">
                        <Tab></Tab>
                    </div>
                    <div className="sorteio-um">
                        <div className="sorteiodois">#12</div>
                        <div className="sorteiotres">410</div>
                    </div>
                    <div className="together">
                        <div className="togethertres">Faltam 1d12h56m</div>
                        <div className="togetherquatro">
                            <Button variant="text">?</Button>{" "}
                        </div>
                    </div>
                </div>
            </div>
        );
    },
};
