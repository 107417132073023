import { Resultado } from "../../Models";

const SvgToDataURL = function (svg, type_, width, height) {
  return new Promise((resolve, reject) => {
    type_ = typeof type_ === "string" ? type_.toLocaleLowerCase() : "png";
    svg = typeof svg === "string" ? this.StringToHTML(svg).shift() : svg;
    width = typeof width === "number" ? width : null;
    height = typeof height === "number" ? height : null;
    let typesImges = {
      png: "image/png",
      jpeg: "image/jpeg",
      jpg: "image/jpeg",
      ico: "image/ico",
      bmp: "image/bmp",
      webp: "image/webp",
    };

    if (!(svg instanceof window.SVGElement)) {
      reject(
        new Resultado(
          -1,
          "Valor passado é inválido, pois não é uma String ou Object que seja instanciado do SVGElement!"
        )
      );
      return;
    }

    let copyStylesInline = (destinationNode, sourceNode) => {
      let containerElements = ["svg", "g"],
        nodeTypes = [1];
      for (let i = 0; i < destinationNode.childNodes.length; i++) {
        let child = destinationNode.childNodes[i];
        if (containerElements.indexOf(child.tagName) === -1) {
          copyStylesInline(child, sourceNode.childNodes[i]);
          continue;
        }
        if (nodeTypes.indexOf(child.nodeType) === -1) {
          continue;
        }
        let style =
          sourceNode.childNodes[i].currentStyle ||
          window.getComputedStyle(sourceNode.childNodes[i], "");
        if (style == "undefined" || style == null) continue;
        for (let st = 0; st < style.length; st++) {
          if (!(style.getPropertyValue || "getPropertyValue" in style)) {
            continue;
          }
          child.style.setProperty(style[st], style.getPropertyValue(style[st]));
        }
      }
    };

    if (width !== null && height === null) {
      svg.setAttribute("width", width);
      svg.setAttribute("height", width);
    } else if (width === null && height !== null) {
      svg.setAttribute("width", height);
      svg.setAttribute("height", height);
    } else if (width !== null && height !== null) {
      svg.setAttribute("width", width);
      svg.setAttribute("height", height);
    }

    let copy = svg.cloneNode(true);
    copyStylesInline(copy, svg);
    let canvas = window.document.createElement("canvas");
    let data = new window.XMLSerializer().serializeToString(copy);
    let DOMURL = window.URL || window.webkitURL || window;
    let img = window.document.createElement("img");
    img.style.display = "none";
    let svgBlob = new window.Blob([data], {
      type: "image/svg+xml;charset=utf-8",
    });
    let url = DOMURL.createObjectURL(svgBlob);
    document.body.appendChild(img);
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      let ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, img.width, img.height);
      ctx.drawImage(img, 0, 0);
      let imgURI = canvas.toDataURL(
        type_ in typesImges ? typesImges[type_] : "image/png"
      );
      DOMURL.revokeObjectURL(url);
      window.document.body.removeChild(img);
      resolve(imgURI);
    };
    img.src = url;
  });
};

export default SvgToDataURL;
