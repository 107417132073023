export default [
	// { w: 12, h: 12, x: 0, y: 0, name: "Presale", isDraggable: false, isResizable: false, isBounded: true, md: { h: 20 } },
	{ w: 12, h: 8, x: 0, y: 0, name: "iVipBank", isDraggable: false, isResizable: false, isBounded: true },
	{ w: 12, h: 12, minW: 6, maxW: 12, minH: 8, maxH: 18, x: 0, y: 0, name: "TraderView" },
	{ w: 6, h: 10, minW: 6, maxW: 12, minH: 8, maxH: 10, x: 0, y: 0, name: "StakingView" },
	{ w: 6, h: 10, minW: 6, maxW: 12, minH: 7, maxH: 10, x: 6, y: 0, name: "NftCatalog" },
	//{ w: 7, h: 5, x: 0, y: 0, name: "Tela1" },
	//{ w: 4, h: 11, x: 8, y: 0, name: "Teste1" },
	//{ w: 4, h: 5, x: 0, y: 10, name: "Tela1" },
	//{ w: 5, h: 14, x: 12, y: 2, name: "Tela2" },
	//{ w: 7, h: 9, x: 0, y: 10, name: "Tela4" },
	//{ w: 7, h: 8, x: 0, y: 10, name: "Tela5" },
	//{ w: 12, h: 7, x: 0, y: 11, name: "Tela6" },
	//{ w: 5, h: 8, x: 12, y: 2, name: "Tela7" },
	//{ w: 4, h: 9, x: 0, y: 2, name: "Tela8" },
];
