import React from "react";
import { PhaseProps, Structure } from "Dialog/utils";
import { qrcode, svg2url } from "pure-svg-code";

import style from "../utils/style.module.scss";
import { Button, IconButton, Typography } from "@mui/material";
import { SvgIcon } from "Components";
import { mdiContentCopy } from "@mdi/js";
import { APIHelper, InteractionHelper } from "Helper";
import { copyStringToClipboard } from "Utils";
import { OrderInfo } from "Models";
import ViewPaymentDialog from "Dialog/ViewPaymentDialog";

export interface Bep20DialogProps {
	wallet: string;
	address: string;
}

export default new Structure<Bep20DialogProps>(
	"BEP-20",
	{
		wallet: "",
		address: "",
	},
	[
		({ state, nextPhase, reject }) => {
			const wallet_id = state.wallet
				.replace(/(\/+)$/gi, "")
				.split("/")
				.pop();

			APIHelper.get<string>(`finances/bep20_address/${wallet_id}`)
				.then((response) => {
					state.address = response;
					nextPhase();
				})
				.catch((e) => {
					reject(e);
				});
		},
		{
			phase: (p) => {
				return <Dialog {...p} />;
			},
			btnNames: ["Impostar tokens"],
		},
		({ state, reject, resolve }) => {
			const wallet_id = state.wallet
				.replace(/(\/+)$/gi, "")
				.split("/")
				.pop();

			APIHelper.get<OrderInfo>(`finances/bep20_address/${wallet_id}/import`)
				.then((response) => {
					InteractionHelper.toast("Tokens importados com sucesso", null, "success");
					resolve();
					ViewPaymentDialog.show({ wallet: wallet_id, paymentId: String(response.id) });
				})
				.catch((e) => {
					reject(e);
				});
		},
	],
);

const Dialog: React.FC<PhaseProps<Bep20DialogProps>> = ({ state }) => {
	const imageCode = qrcode({
		content: state.address,
		padding: 0,
		width: 256,
		height: 256,
		color: "#212121",
		background: "transparent",
		ecl: "M",
	});

	console.log(state.toJson());
	return (
		<div className={style["dialogPayment"]}>
			<div className={style["payment-code"]}>
				<div className={style["payment-code-image"]}>
					<object data={svg2url(imageCode)}></object>
				</div>
				<div className={style["payment-code-input"]}>
					<div className={style["payment-code-input-value"]}>
						<Typography variant="subtitle2">{state.address}</Typography>
					</div>
					<IconButton
						size="large"
						disableRipple
						onClick={() => {
							copyStringToClipboard(state.address)
								.then(() => {
									InteractionHelper.toast("Código copiado com sucesso", null, "success");
								})
								.catch(() => {
									InteractionHelper.toast("Não foi possível copiar o código", null, "error");
								});
						}}
					>
						<SvgIcon path={mdiContentCopy} />
					</IconButton>
				</div>
			</div>

			<div className={style["description"]}>
				Esse será o endereço que você irá utilizar para depositar seus tokens IVIP na plataforma. É um endereço BEP-20, então você pode
				utilizar qualquer carteira que suporte essa tecnologia. Após realizar a trânsferência dos tokens, será preciso realizar a operação de
				importação para que os tokens sejam creditados em sua conta.
			</div>

			<div className={[style["description"], style["warns"]].join(" ")}>
				<b>Atenção:</b> Não envie tokens de outras redes para esse endereço, pois eles não serão creditados em sua conta.
			</div>
		</div>
	);
};
