/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";

import { uniqueid, MultiStorager } from "Utils";

import { Usuario } from "Models";
import { UserHelper, ChatHelper, ChatHelperTypes } from "Helper";

type MessageTypes = "text" | "option" | "email" | "password" | "tel";

const types = ["text", "option", "email", "password", "tel"];

interface MessagesHookResult {
	messages: ChatHelperTypes.MessageSettings[];
	update: (newMessages: ChatHelperTypes.MessageSettings | Array<ChatHelperTypes.MessageSettings>) => void;
	reset: () => void;
	prepareMessage: (
		type: MessageTypes,
		context: ChatHelperTypes.MessageContextSettings,
		userId: string,
		roomId: string,
		user?: Usuario,
	) => ChatHelperTypes.MessageSettings | undefined;
}

export const useMessages = (): MessagesHookResult => {
	//const {value: messages, setValue: setMessages} = useStorager(`Chat_MessagesStorager_${uniqueid(16)}`, []);
	let [messages, setMessages] = useState<any[]>([]);

	const updateMessages = useCallback((newMessages: ChatHelperTypes.MessageSettings | Array<ChatHelperTypes.MessageSettings>): void => {
		if (Array.isArray(newMessages) !== true) {
			newMessages = [newMessages as ChatHelperTypes.MessageSettings];
		}

		setMessages((messages) =>
			Array.from(messages)
				.concat(newMessages)
				.filter((message, pos, self) => {
					return (
						message && typeof message.timestamp === "number" && self.findIndex((mss) => mss && mss.timestamp === message.timestamp) == pos
					);
				})
				.sort(({ timestamp: a }, { timestamp: b }) => a - b),
		);
	}, []);

	const resetMessages: () => void = useCallback(() => {
		setMessages(() => []);
	}, []);

	const prepareMessage = useCallback(
		(
			type: MessageTypes,
			context: ChatHelperTypes.MessageContextSettings,
			userId: string,
			roomId: string,
			user?: Usuario,
		): ChatHelperTypes.MessageSettings | undefined => {
			if (types.includes(type) !== true) {
				return;
			}

			if (typeof context.message === "string" && context.message.trim() === "") {
				return;
			}

			let userIdNow: string = "";

			if (user && user instanceof Usuario && user.path) {
				userIdNow = user.path.split("/").pop() ?? "";
			}

			//const {message, value, suggestions} = context;

			userId = !userId ? userIdNow : userId;

			return {
				isMe: userId === userIdNow,
				userId,
				type,
				context: context,
				timestamp: Date.now(),
				roomId,
			};
		},
		[],
	);

	return {
		messages,
		update: updateMessages,
		reset: resetMessages,
		prepareMessage,
	};
};

export const useBot = () => {
	const { messages, update: updateMessages, prepareMessage } = useMessages();

	const [loading, setLoading] = useState<boolean>(true);
	const [user, setUser] = useState<Usuario | null>(null);

	const [type, setType] = useState<string>("text");
	const [typing, setTyping] = useState<boolean>(false);
	const [containsOnChange, setContainsOnChange] = useState<boolean>(false);
	const [disabledInput, setDisabledInput] = useState<boolean>(false);

	const [botId, setBotId] = useState<string>(uniqueid(25));
	const [bot, setBot] = useState<ChatHelper | null>(null);

	const [messagesProcess, setMessagesProcess] = useState<ChatHelperTypes.Message[]>([]);

	useEffect(() => {
		UserHelper.getUser()
			.then((user: Usuario) => {
				if (user instanceof Usuario) {
					setUser(user);
					setLoading(false);
					return;
				}

				setUser(null);
				setLoading(false);
			})
			.catch(() => {
				setUser(null);
				setLoading(false);
			});
	}, [botId]);

	const updateBot = useCallback((_bot: ChatHelper) => {
		if (!(_bot instanceof ChatHelper)) {
			return;
		}

		const id = typeof _bot.id === "string" ? _bot.id : uniqueid(25);

		setBotId(id);
		setBot(_bot);
	}, []);

	useEffect(() => {
		if (!(user instanceof Usuario) && loading) {
			return;
		}

		let listener: any;

		listener = MultiStorager.DataStorager.addListener<ChatHelperTypes.MessageSettings[]>(`Chat_messages_${botId}`, (messages) => {
			updateMessages(messages ?? []);
		});

		return () => {
			listener?.stop();
		};
	}, [user, botId, loading]);

	const sendMessages = async (botMessages: ChatHelperTypes.Message[]): Promise<ChatHelperTypes.Message | null> => {
		let lastMessage: ChatHelperTypes.Message | null = null;

		for (let i = 0; i < botMessages.length; i++) {
			const message: ChatHelperTypes.Message = botMessages[i];
			setTyping(true);
			setDisabledInput(true);
			setContainsOnChange(true);

			await new Promise((r) => setTimeout(r, Math.round(2000 * Math.random()) + 1000));

			const { type, context, roomId } = message;

			const preparedMessage = prepareMessage(type !== "option" ? "text" : type, context, roomId, roomId, user as Usuario);

			MultiStorager.DataStorager.push(`Chat_messages_${botId}`, preparedMessage);

			if (i >= botMessages.length - 1) {
				setType(type);
				lastMessage = message;

				const disabled_input: boolean = ["option"].includes(type);
				setTyping(false);
				setDisabledInput(disabled_input);
				setContainsOnChange(bot?.isWaitingForMessage() ? true : false);
			}
		}

		return lastMessage;
	};

	useEffect(() => {
		let time: any;

		if (messages.length && messages[messages.length - 1].isMe) {
			const message: ChatHelperTypes.Message = new ChatHelperTypes.Message(messages[messages.length - 1]);

			setTyping(true);
			setDisabledInput(true);

			time = setTimeout(() => {
				bot?.processMessage(message);
			}, 2000);

			//setType("text");
		}

		return () => {
			clearTimeout(time);
		};
	}, [messages]);

	useEffect(() => {
		if (loading || !(bot instanceof ChatHelper)) {
			return;
		}

		bot.onMenssage = async (messages) => {
			//console.log(bot);
			await sendMessages(messages);
		};

		let time: any;

		if (Array.isArray(messages) && messages.length < 1) {
			setTyping(true);
			setDisabledInput(true);

			time = setTimeout(() => {
				bot.init();
			}, 2000);
		}

		return () => {
			bot.onMenssage = null;
			clearTimeout(time);
		};
	}, [messages, bot, loading]);

	return {
		botId,
		containsBoot: bot !== null,
		update: updateBot,
		inputType: type,
		typing,
		inputDisabled: disabledInput || loading,
		containsOnChange: containsOnChange,
	};
};
