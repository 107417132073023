import React, { useEffect, useState } from "react";

import style from "./style.module.scss";
import { InteractionHelper, WalletHelper } from "Helper";
import { Resultado, Usuario } from "Models";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";

import { APIHelper } from "Helper";
import { ViewPaymentDialog } from "Dialog";

const Voucher: React.FC<{
	user: Usuario;
}> = ({ user }) => {
	const [voucher, setVoucher] = useState("0000");

	const [validatedLoading, setValidatedLoading] = useState(true);

	const [allowed, setAllowed] = useState(false);
	const [validatedVoucher, setValidatedVoucher] = useState(false);

	useEffect(() => {
		APIHelper.fetch("user/voucher/permission", {}, 4)
			.then((allowed: any): Promise<any> => {
				setAllowed(allowed);
				return allowed ? (APIHelper.fetch("user/voucher/verify", {}, 4) as any) : Promise.resolve(false);
			})
			.then((verified: any) => {
				setValidatedVoucher(verified);
				setValidatedLoading(false);
			})
			.catch(({ message }: Resultado) => {
				InteractionHelper.toast(message, null, "error");
			});
	}, [user]);

	const handleVoucherResgate = () => {
		setValidatedLoading(true);

		APIHelper.fetch(
			"user/voucher/rescue",
			{
				voucherId: voucher,
			},
			2,
		)
			.then((value) => {
				setValidatedVoucher((value as any).verify);
				if ((value as any).verify) {
					ViewPaymentDialog.show({ wallet: (value as any).wallet_id, paymentId: (value as any).history_id });
				} else {
					InteractionHelper.toast("Código VOUCHER inválido, tente novamente!", null, "warning");
				}
				setValidatedLoading(false);
			})
			.catch(({ message }: Resultado) => {
				InteractionHelper.toast(message, null, "error");
			});
	};

	if (!allowed) {
		return null;
	}

	return (
		<div className={[style.voucher, validatedVoucher ? style.found : ""].join(" ")}>
			<Typography
				variant="h5"
				gutterBottom
			>
				Voucher
			</Typography>
			<div className={style.main}>
				{validatedLoading && (
					<CircularProgress
						color="inherit"
						size={30}
					/>
				)}

				{!validatedLoading && validatedVoucher && <Typography variant="h6">Você já conseguiu ativar um voucher. Parabéns!</Typography>}

				{!validatedLoading && !validatedVoucher && (
					<>
						<TextField
							label="Insira seu Voucher"
							variant="outlined"
							value={voucher}
							onChange={(e) => setVoucher(e.target.value)}
							sx={{
								marginBottom: "20px",
							}}
						/>
						<Button
							variant="contained"
							color="primary"
							onClick={handleVoucherResgate}
						>
							Resgatar Voucher
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

export default Voucher;
