import React, { Component, useEffect, useState, useContext } from "react";
import { Marquee } from "Components";
import { useHook, currencyFormat } from "Utils";
import ContextRoot from "Context";
import style from "./index.module.scss";
import userEvent from "@testing-library/user-event";

import { APIHelper } from "Helper";

let timeoutWatchData;

const fiterDefault = ["bitcoin","ethereum","tether","usd-coin","binance-coin","cardano","binance-usd","xrp","solana","dogecoin","polkadot","shiba-inu","avalanche","polygon","multi-collateral-dai","terra-luna","uniswap","tron","wrapped-bitcoin","ethereum-classic"];

const getCurrencyIndexData = ()=>{
    return new Promise((resolve, reject)=>{
        fetch("https://api.coincap.io/v2/assets").then((t) => t.json()).then(({ data }) => {
            //console.log(JSON.stringify(data.map(({id, name, symbol})=> ({value: id, label: `${symbol} - ${name}`}))));

            data = data.filter(({id})=> typeof id === "string" && id).map((d) => {
                d.icon = `https://assets.coincap.io/assets/icons/${String(d.symbol).toLocaleLowerCase()}@2x.png`;
                d.icon = APIHelper.urlBy(`binance/assets/${String(d.symbol).toUpperCase()}`);
                return d;
            });

            resolve(data);
        }).catch(reject);
    });
}

const CurrencyIndex = ()=>{
    const [listComponent, setListComponent] = useState([]);
    const [filter, setFilter] = useState([]);
    const { settings } = useContext(ContextRoot);

    const {execute, status, value: data} = useHook.useAsync(getCurrencyIndexData, false);

    const updateFilter = ()=>{
        let f = Array.isArray(settings["notification.currencyIndex"]) ? settings["notification.currencyIndex"] : fiterDefault;
        f = f.length > 0 ? f : fiterDefault;
        setFilter(f);
    }

    useEffect(()=>{
        updateFilter();

        const timeInterval = window.setInterval(()=>{
            if(status !== "pending"){
                execute();
            }
        }, (10 * 60 * 1000));

        execute();

        return ()=>{
            window.clearInterval(timeInterval);
        }
    }, []);

    useEffect(()=>{
        updateFilter();
    }, [settings, data]);

    useEffect(()=>{
        if(Array.isArray(data)){
            setListComponent(data.filter(({id})=> filter.includes(id)));
        }
    }, [status, data, filter]);

    return <div className={style["main"]}>
        <Marquee duration={120}>
            {listComponent.map(({ icon, name, symbol, priceUsd, changePercent24Hr }, i) => {
                const indicator = parseFloat(changePercent24Hr) < 0 ? "fall" : "";

                return (<div className={style["rank"]} key={"CryptoRank-item-"+i}>
                        <div className={style["icon"]} style={{ backgroundImage: `url("${icon}")` }}></div>
                        <div className={style["content"]}>
                            <div className={style["price"]}>{currencyFormat.convert(parseFloat(parseFloat(priceUsd).toFixed(6)), "USD")}</div>
                            <div className={style["name"]}>{name}/{symbol}</div>
                        </div>
                        <div className={[style["indicator"], style[indicator]].join(" ")}>
                            <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                                <path fill="currentColor" d="M11.93 5L14.76 7.83L5 17.59L6.42 19L16.18 9.25L19 12.07V5H11.93Z"/>
                            </svg>
                            <span>{parseFloat(changePercent24Hr).toFixed(2)}%</span>
                        </div>
                    </div>);
                }
            )}
        </Marquee>
    </div>;
}

export default CurrencyIndex;
