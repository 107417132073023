import React from "react";

import { InputCustom } from "Components";

import { Resultado, Wallet } from "Models";

import WalletHelper from "Helper/WalletHelper";

import { InteractionHelper } from "Helper";

const ProcessEditWallet = (wallet: Wallet, props: any = {}) => {
	return new Promise(async (resolve: (wallet: Wallet) => void, reject) => {
		if (!(wallet instanceof Wallet)) {
			return reject(new Resultado(-1, "Erro ao tentar iniciar o processo de edição!"));
		}

		InteractionHelper.confirm(
			<React.Fragment>
				<InputCustom
					label={"Nome"}
					helperText=" "
					value={wallet.nome}
					onChange={(value) => {
						wallet.nome = String(value);
					}}
				/>
				{wallet.type === "BINANCE" && wallet.status !== "valid" && (
					<InputCustom
						label={"API Key"}
						helperText=" "
						value={props.APIKey ?? ""}
						onChange={(value) => {
							props.APIKey = value;
						}}
					/>
				)}
				{wallet.type === "BINANCE" && wallet.status !== "valid" && (
					<InputCustom
						label={"API Secret"}
						helperText=" "
						value={props.APISecret ?? ""}
						type={"password"}
						onChange={(value) => {
							props.APISecret = value;
						}}
					/>
				)}
				<InputCustom
					label={"Número"}
					helperText=" "
					value={wallet.getWalletCode()}
					readOnly={true}
				/>
			</React.Fragment>,
			wallet.type,
		)
			.then(() => {
				InteractionHelper.loading();
				const data =
					wallet.type === "BINANCE"
						? {
								walletAPIKey: props.APIKey ?? "",
								walletAPISecret: props.APISecret ?? "",
								walletType: "BINANCE",
								walletPath: wallet.path,
						  }
						: {};

				WalletHelper.saveWallet(wallet, undefined, data)
					.then((wallet) => {
						InteractionHelper.close();
						InteractionHelper.toast("Carteira salva com sucesso!", null, "success");
						resolve(wallet);
					})
					.catch((error: Resultado) => {
						InteractionHelper.close();
						ProcessEditWallet(wallet, props).then(resolve).catch(reject);
						InteractionHelper.toast(error.message, null, "error");
					});
			})
			.catch(() => Promise.resolve());
	});
};

export default ProcessEditWallet;
