const getStyle = function(oElm, strCssRule, isNumber){
    let strValue = "";
    if(oElm.currentStyle || window.getComputedStyle){
        strCssRule = strCssRule.replace(/\-(\w)/g, function (strMatch, p1){
            return p1.toUpperCase();
        });
        strValue = oElm.currentStyle ? oElm.currentStyle : window.getComputedStyle(oElm);
		strValue = strValue[strCssRule]
    }else if(document.defaultView && document.defaultView.getComputedStyle){
        strValue = document.defaultView?.getComputedStyle(oElm, "").getPropertyValue(strCssRule);
    }
    return isNumber === true ? (Number(strValue.match(/[\-]?\d+/g).join("")) || 0) : strValue;
}

const getElementPosition = function(element){
	let size = {top: 0, right: 0, bottom: 0, left: 0, x: 0, y: 0};

	if(!element || !element.getBoundingClientRect){
		return size;
	}

	const rect = element.getBoundingClientRect();
	return {top: rect.top, right: rect.right, bottom: rect.bottom, left: rect.left, x: rect.x, y: rect.y};
}

const getElementSize = function(element){
	let size = {width: 0, height: 0};

  if (
    typeof element !== "object" ||
    !(
      element &&
      ("offsetWidth" in element ||
        "clientWidth" ||
        "getBoundingClientRect" in element)
    )
  ) {
    return size;
  }

  if ("getBoundingClientRect" in element) {
    let info = element.getBoundingClientRect();
    size.width = info.width;
    size.height = info.height;
  } else {
    size.width = element.offsetWidth || element.clientWidth;
    size.height = element.offsetHeight || element.clientHeight;
  }

  return size;
};

const getInputSelection = function (el) {
  var start = 0,
    end = 0,
    normalizedValue,
    range,
    textInputRange,
    len,
    endRange;

  if (
    typeof el.selectionStart == "number" &&
    typeof el.selectionEnd == "number"
  ) {
    start = el.selectionStart;
    end = el.selectionEnd;
  } else {
    range = document.selection.createRange();

    if (range && range.parentElement() == el) {
      len = el.value.length;
      normalizedValue = el.value.replace(/\r\n/g, "\n");

      // Create a working TextRange that lives only in the input
      textInputRange = el.createTextRange();
      textInputRange.moveToBookmark(range.getBookmark());

      // Check if the start and end of the selection are at the very end
      // of the input, since moveStart/moveEnd doesn't return what we want
      // in those cases
      endRange = el.createTextRange();
      endRange.collapse(false);

      if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
        start = end = len;
      } else {
        start = -textInputRange.moveStart("character", -len);
        start += normalizedValue.slice(0, start).split("\n").length - 1;

        if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
          end = len;
        } else {
          end = -textInputRange.moveEnd("character", -len);
          end += normalizedValue.slice(0, end).split("\n").length - 1;
        }
      }
    }
  }

  return {
    start: start,
    end: end,
  };
};

const getCursorXY = function (input) {
  //const {offsetLeft: inputX, offsetTop: inputY, offsetWidth: inputWidth, offsetHeight: inputHeight} = input;

  const {
    x: inputX,
    y: inputY,
    width: inputWidth,
    height: inputHeight,
  } = input.getBoundingClientRect();

  const selectionPoint = this.getInputSelection(input).start;

  const div = document.createElement("div");
  const copyStyle = getComputedStyle(input);
  for (const prop of copyStyle) {
    div.style[prop] = copyStyle[prop];
  }

  const swap = ".";
  const inputValue =
    input.tagName === "INPUT" ? input.value.replace(/ /g, swap) : input.value;
  const textContent = inputValue.substr(0, selectionPoint);
  div.textContent = textContent;

  div.style.width = inputWidth + "px";
  div.style.height = inputHeight + "px";

  if (input.tagName === "TEXTAREA") {
    div.style.overflowY = "auto";
  }

  if (input.tagName === "INPUT") {
    div.style.overflowY = "hidden";
    div.style.whiteSpace = "nowrap";
  }

  div.scrollTop = input.scrollTop;
  div.scrollLeft = input.scrollLeft;

  const span = document.createElement("span");
  span.textContent = inputValue.substr(selectionPoint) || ".";
  div.appendChild(span);

  document.body.appendChild(div);

  const { offsetLeft: spanX, offsetTop: spanY } = span;
  document.body.removeChild(div);

  return {
    x: inputX + spanX,
    y: inputY + spanY,
  };
};

const StringToHTML = function (a) {
  if (!a || typeof a !== "string") {
    return [];
  }
  var b,
    c = [];
  b = new window.DOMParser().parseFromString(a, "text/html").body;
  if (!b || b.getElementsByTagName("parsererror").length) {
    console.error("Invalid XML: " + a);
  } else {
    while (b.firstChild) {
      c.push(b.firstChild);
      b.removeChild(b.firstChild);
    }
  }
  return c;
};

export {
	getStyle,
	getElementSize,
	getCursorXY,
	StringToHTML,
	getInputSelection,
	getElementPosition
}
