import React from "react";
import style from "./style.module.scss";
import { SvgIcon } from "Components";
import { Color } from "ivip-utils";

const Button: React.FC<{
	icon?: string;
	label?: string;
	color?: string;
	onClick?: () => void;
	className?: string;
	style?: React.CSSProperties;
	disabled?: boolean;
}> = ({ label = "", icon, onClick, color = "#212121", disabled = false, className = "", style: _style = {} }) => {
	const colorPrimary = new Color(!disabled ? color : "#212121");
	const colorDark = colorPrimary.darken(0.2);
	const colorLight = colorPrimary.lighten(0.2);

	return (
		<div
			className={[style.btn, disabled ? style.disabled : "", className].join(" ")}
			style={
				{
					..._style,
					"--color-primary": colorPrimary.vector.join(", "),
					"--color-dark": colorDark.vector.join(", "),
					"--color-light": colorLight.vector.join(", "),
				} as React.CSSProperties & { [p: string]: string }
			}
		>
			<button onClick={disabled ? undefined : onClick}>
				{typeof icon === "string" && (
					<SvgIcon
						path={icon}
						style={{ marginRight: "10px" }}
					/>
				)}
				<span>{label}</span>
			</button>
		</div>
	);
};

export default Button;
