import React, { useState, useCallback, useEffect } from "react";

import { Box, Typography, CircularProgress, Alert, Stack, TextField, Link, Breadcrumbs, IconButton } from "@mui/material";

import { mdiArrowRightDropCircleOutline, mdiDelete, mdiPlus } from "@mdi/js";

import { SvgIcon } from "Components";

import { getDatabase } from "ivipbase";

import { InteractionHelper } from "Helper";

import styles from "./style.module.scss";

const PathRoute = ({ path: _path, onChange }) => {
	const [path, setPath] = useState(["/"]);

	useEffect(() => {
		if (Array.isArray(_path) && _path.every((p) => typeof p === "string")) {
			setPath(() => _path);
		}
	}, [_path]);

	const goTo = useCallback(
		(to) => {
			if (typeof onChange === "function") {
				onChange(to);
			}
		},
		[onChange],
	);

	return (
		<Breadcrumbs separator="/">
			{path[0] === "/" ? (
				path.join("") === "/" ? (
					<Typography color="text.primary">root</Typography>
				) : (
					<Link
						underline="hover"
						color="inherit"
						href="#"
						onClick={() => {
							goTo(["/"]);
						}}
					>
						root
					</Link>
				)
			) : null}

			{path
				.filter((p, i) => !(p === "/" && i === 0))
				.map((value, index, pathnames) => {
					const last = index === pathnames.length - 1;
					const to = path.slice(0, index + 1).concat([value]);
					const name = value.length >= 15 ? `${value.substring(0, 12)}...` : value;

					return last ? (
						<Typography
							key={to.join("/")}
							color="text.primary"
						>
							{name}
						</Typography>
					) : (
						<Link
							key={to.join("/")}
							underline="hover"
							color="inherit"
							href="#"
							onClick={() => {
								goTo(to);
							}}
						>
							{name}
						</Link>
					);
				})}
		</Breadcrumbs>
	);
};

const TreeViewEdit = ({ contents: _contents, path, onChange, onDelete }) => {
	const [contents, setContents] = useState([]);

	useEffect(() => {
		if (Array.isArray(_contents)) {
			setContents(() => {
				return _contents.filter(
					(obj) => typeof obj === "object" && ["number", "string"].includes(typeof obj.key) && typeof obj.type === "string",
				);
			});
		}
	}, [_contents]);

	const goTo = useCallback(
		(to, type) => {
			if (typeof onChange === "function" && ["object", "array"].includes(type)) {
				onChange(to);
			}
		},
		[onChange],
	);

	const deleteChild = useCallback(
		(path) => {
			if (typeof onDelete === "function") {
				onDelete(path);
			}
		},
		[onDelete],
	);

	return (
		<div className={styles["node-tree"]}>
			<div className={styles["node-view"]}>
				<div className={styles["node-view-child"]}>
					<Link
						className={styles["node-key"]}
						underline="none"
						color="inherit"
					>
						{path[path.length - 1] === "/" ? "root" : path[path.length - 1]}
					</Link>
				</div>
				<div className={styles["node-view-actions"]}>
					<IconButton
						size="small"
						disableRipple
						disableFocusRipple
					>
						<SvgIcon
							path={mdiPlus}
							fontSize="inherit"
						/>
					</IconButton>
					<IconButton
						size="small"
						disableRipple
						disableFocusRipple
					>
						<SvgIcon
							path={mdiDelete}
							fontSize="inherit"
						/>
					</IconButton>
				</div>
			</div>

			{contents.map(({ key, type, value }) => {
				const renderValue = ["string"].includes(type) ? `"${value || ""}"` : value;
				const pathTo = path.concat([key]);

				return (
					<div key={key}>
						<div className={styles["node-sibling"]}>
							{["object", "array"].includes(type) ? (
								<div className={styles["node-sibling-object"]}>
									<SvgIcon path={mdiArrowRightDropCircleOutline} />
								</div>
							) : null}
						</div>
						<div className={styles["node-view"]}>
							<div className={styles["node-view-child"]}>
								{["object", "array"].includes(type) ? (
									<Link
										className={styles["node-key"]}
										underline={"hover"}
										color="inherit"
										href="#"
										onClick={() => {
											goTo(pathTo, type);
										}}
									>
										{key}
									</Link>
								) : (
									<Link
										className={styles["node-key"]}
										underline={"none"}
										color="inherit"
									>
										{key}
									</Link>
								)}
								{["object", "array"].includes(type) ? null : (
									<React.Fragment>
										<span>:</span>
										<span className={[styles["node-value"], styles["editable"]].join(" ")}>{renderValue}</span>
									</React.Fragment>
								)}
							</div>
							<div className={styles["node-view-actions"]}>
								<IconButton
									size="small"
									disableRipple
									disableFocusRipple
									onClick={() => deleteChild(pathTo)}
								>
									<SvgIcon
										path={mdiDelete}
										fontSize="inherit"
									/>
								</IconButton>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

const arrayPathToRef = (path) => {
	return path.slice(1).reduce((ref, name) => ref.child(name), getDatabase().ref(path[0]));
};

export default () => {
	const [path, setPath] = useState(["/"]);
	const [contents, setContents] = useState([]);
	const [status, setStatus] = useState("loading");

	const [childrenChanged, setChildrenChanged] = useState([]);

	const getChildren = useCallback(async () => {
		try {
			const ref = arrayPathToRef(path);

			const {
				children: { list },
				exists,
				...info
			} = await ref.reflect("info", { child_limit: 200, child_skip: 0 });

			if (!exists) {
				throw "Path does not exist!";
			}

			let contents = list.sort(({ key: a }, { key: b }) => (a > b ? 1 : a < b ? -1 : 0));

			contents = await Promise.all(
				contents.map(
					({ key, type, value, address }) =>
						new Promise(async (resolve, reject) => {
							if (["object", "array"].includes(type)) {
								return resolve({ key, type, value });
							}

							if (typeof address === "object") {
								value = await ref
									.child(key)
									.get()
									.then((r) => Promise.resolve(r.val()))
									.catch(() => null);
							}

							return resolve({ key, type, value });
						}),
				),
			).catch(() => null);

			setContents(() => contents.filter((c) => c !== null));
			setStatus("success");
		} catch (e) {
			console.log(e);
			setStatus("error");
		}
	}, [path]);

	useEffect(() => {
		const ref = arrayPathToRef(path);
		let timeOut = childrenChanged.map(({ timestamp }) => {
			return setTimeout(() => {
				const now = Date.now();
				setChildrenChanged((list) => {
					return list.filter(({ timestamp: t }) => t !== timestamp && now - timestamp < 5000);
				});
			}, 5000);
		});

		const callback = (event, ref) => {
			const timestamp = Date.now();

			setChildrenChanged((list) => {
				const p = path.concat([ref.key]);

				return list.concat([
					{
						event,
						path: p,
						timestamp,
					},
				]);
			});

			timeOut.push(
				setTimeout(() => {
					const now = Date.now();
					setChildrenChanged((list) => {
						return list.filter(({ timestamp: t }) => t !== timestamp && now - timestamp < 5000);
					});
				}, 5000),
			);

			getChildren();
		};

		const changes = ref.on("notify_child_changed").subscribe((ref) => callback("changed", ref));
		const adds = ref.on("notify_child_added").subscribe((ref) => callback("added", ref));
		const removes = ref.on("notify_child_removed").subscribe((ref) => callback("removed", ref));

		return () => {
			changes.stop();
			adds.stop();
			removes.stop();
			timeOut.forEach((t) => {
				clearTimeout(t);
			});
		};
	}, [path]);

	useEffect(() => {
		setStatus("loading");
		getChildren();
		//console.log(path);
	}, [path]);

	useEffect(() => {
		console.log(childrenChanged);
	}, [childrenChanged]);

	const removeChildren = useCallback((path, confirm) => {
		if (typeof confirm !== "boolean" || !confirm) {
			InteractionHelper.confirm(
				"Tem certeza que deseja remover esse item no banco de dados? \nDepois de removido, não será possível de refazer, escolha com bastante atenção!",
				"Confirmação",
			)
				.then(() => {
					removeChildren(path, true);
				})
				.catch(() => {});

			return;
		}

		InteractionHelper.loading();
		try {
			const ref = arrayPathToRef(path);
			ref.remove()
				.then(() => {
					InteractionHelper.close();
					InteractionHelper.toast("Item removido no banco de dados com sucesso.", "Item removido", "success");
				})
				.catch(() => {
					InteractionHelper.close();
					InteractionHelper.toast("Não foi possível remove o item no banco de dados!", "Erro!", "error");
				});
		} catch {
			InteractionHelper.close();
			InteractionHelper.toast("Não foi possível remove o item no banco de dados!", "Erro!", "error");
		}
	}, []);

	let component = (
		<Box sx={{ display: "flex", flexWrap: "nowrap", minHeight: "100px", alignItems: "center", justifyContent: "center" }}>
			<CircularProgress
				color="inherit"
				size={35}
				sx={{ margin: "10px 0px", color: "var(--text-primary)", opacity: ".3" }}
			/>
		</Box>
	);

	if (status === "error") {
		component = (
			<Box sx={{ display: "flex", flexWrap: "nowrap", minHeight: "50px", alignItems: "center", justifyContent: "center" }}>
				<Stack
					sx={{ width: "100%" }}
					spacing={2}
				>
					<Alert
						variant="outlined"
						severity="warning"
						sx={{ borderWidth: "0px" }}
					>
						Algo deu errado!!!
					</Alert>
				</Stack>
			</Box>
		);
	} else if (status === "success") {
		component = (
			<TreeViewEdit
				contents={contents}
				path={path}
				onChange={setPath}
				onDelete={removeChildren}
			/>
		);
	}

	return (
		<div className={styles["container"]}>
			<Box className={styles["view"]}>
				<div className={styles["toolbar"]}>
					<PathRoute
						path={path}
						onChange={setPath}
					/>
				</div>
				<Box
					className={styles["main"]}
					component="main"
					sx={{ p: 3 }}
				>
					{component}
				</Box>
			</Box>
		</div>
	);
};
