import React from "react";
import { Structure } from "Dialog/utils";
import { APIHelper, InteractionHelper, WalletHelper } from "Helper";
import { list_withdrawal_methods, maskAmount } from "Utils";
import { ListPaymentMethodsIten, PaymentMethodId } from "Types/ListPaymentMethods";
import { StakingInfo } from "Types/Staking";
import { Resultado } from "Models";
import { WalletInfo } from "Types/Wallet";
import SimulateStakingApply from "./SimulateStakingApply";

export interface StakingApplyProps {
	stakingId: number | string | null | undefined;
	amount: number;
	available_amount: number;
	min_allowed_amount: number;
	max_allowed_amount: number;
	valid_allowed_amount: number;
	stakingInfo: Partial<StakingInfo>;
}

export default new Structure<StakingApplyProps, StakingInfo[]>(
	"Staking",
	{
		stakingId: null,
		amount: 0,
		available_amount: 0,
		min_allowed_amount: 0,
		max_allowed_amount: 0,
		valid_allowed_amount: 0,
		stakingInfo: {},
	},
	[
		({ state, nextPhase, reject }) => {
			APIHelper.fetch("/staking", {}, true)
				.then((list: any) => {
					state.stakingInfo = (list as StakingInfo[]).find(({ id }) => id === state.stakingId) as Partial<StakingInfo>;
					return !state.stakingInfo
						? Promise.reject(new Resultado(-1, "Staking não encontrado!"))
						: APIHelper.fetch<WalletInfo>(`/wallet/balance`);
				})
				.then((response) => {
					const { information = {} } = response;

					state.available_amount = Object.values(information?.balances ?? {})
						.filter(({ symbol }) => symbol === state.stakingInfo.typeAmount)
						.reduce((a, { available = "0" }) => {
							return Math.max(0, a + parseFloat(available));
						}, 0);

					state.min_allowed_amount = state.stakingInfo.minAmount as number;
					state.max_allowed_amount = Math.max(state.stakingInfo.maxAmount as number, state.available_amount);
					state.valid_allowed_amount = Math.min(state.stakingInfo.maxAmount as number, state.available_amount);

					nextPhase();
				})
				.catch((e) => {
					reject(String(e.message));
				});
		},
		{ phase: (p) => <SimulateStakingApply {...p} />, term: "financial" },
		({ state, previousPhase, resolve, reject }) => {
			if (state.amount < state.min_allowed_amount) {
				previousPhase("Valor menor que o permitido");
				return;
			}
			if (state.amount > Math.min(state.max_allowed_amount, state.valid_allowed_amount)) {
				previousPhase("Valor maior que o permitido");
				return;
			}

			APIHelper.fetch(`/staking/apply/${state.stakingId}`, {
				amount: state.amount,
			})
				.then(() => {
					return APIHelper.fetch<StakingInfo[]>("/staking", {}, true);
				})
				.then((list) => {
					InteractionHelper.toast("Staking aplicado com sucesso", null, "success");
					resolve(list);
				})
				.catch((e) => {
					reject(e);
				});
		},
	],
);
