import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useId } from "Utils/useHook";
import { useTheme } from "@mui/material";

const TradingView: React.FC<{
	[p: string]: any;
}> = (props) => {
	const idRoot = useId();
	const div_view = useRef<HTMLDivElement | null>(null);
	const onLoadScriptRef = useRef<Promise<any> | undefined>();
	const {
		palette: { mode: theme },
	} = useTheme();

	const getTradingView = useCallback(() => {
		if (div_view.current?.querySelector(`#${idRoot}`) && "TradingView" in window) {
			new window.TradingView.widget({
				autosize: true,
				symbol: "PANCAKESWAP:IVIPBTCB_93A441.USD",
				interval: "60",
				timezone: "America/Sao_Paulo",
				theme: theme,
				style: "1",
				locale: "br",
				toolbar_bg: "#f1f3f6",
				enable_publishing: false,
				hide_side_toolbar: false,
				allow_symbol_change: true,
				container_id: idRoot,
			});
		}
	}, [div_view, idRoot, theme]);

	useLayoutEffect(() => {
		getTradingView();
	}, [div_view, getTradingView, theme]);

	useEffect(() => {
		let script: HTMLScriptElement | null = document.head.querySelector("script#tradingview-widget-loading-script");

		if (!script) {
			onLoadScriptRef.current = new Promise((resolve) => {
				script = document.createElement("script");
				script.id = "tradingview-widget-loading-script";
				script.src = "https://s3.tradingview.com/tv.js";
				script.type = "text/javascript";
				script.onload = getTradingView;
				document.head.appendChild(script);
			});
		} else {
			getTradingView();
		}

		onLoadScriptRef.current?.then(getTradingView);

		//return () => onLoadScriptRef.current = null;
	}, [getTradingView]);

	return (
		<div
			{...props}
			ref={div_view}
		>
			<div
				id={idRoot}
				style={{ width: "100%", height: "100%" }}
			></div>
		</div>
	);
};

export default TradingView;
