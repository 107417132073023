import { mdiBlur } from '@mdi/js';
import { Button } from '@mui/material';
import style from './style.module.scss';
//import img_background from 'Resources/images/widget-nft-catalog-background.png';
import img_background from 'Resources/images/widget-nft-catalog-background-2.png';

const MainComponent = ()=>{
    return <div className={[style["main"], style["initial"]].join(" ")} style={{backgroundImage: `url(${img_background})`}}>
        <Button 
            variant="contained" color="inherit"
            onClick={()=>{
                window.routerHistory.push("/nfts_catalog");
            }}
        >VER CATÁLOGO NFT</Button>
    </div>
}

export default {
    id: "NftCatalog",
    title: "Catálogo NFT",
    icon: mdiBlur,
    render: ()=>{
        return <MainComponent />
    }
}