/* eslint-disable no-dupe-class-members */

import { MultiStorager } from "Utils";
import { Resultado, Usuario } from "Models";
import { useCallback, useEffect, useState } from "react";
import { getAuth } from "ivipbase";

const isLocalhost = Boolean(
	window.location.hostname === "localhost" ||
		window.location.hostname === "[::1]" ||
		window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

const promiseState = (p: Promise<any>): "pending" | "fulfilled" | "rejected" => {
	const t = JSON.stringify({ __timestamp__: Date.now(), __msg__: "For state detect pending, fulfilled or rejected" });
	return Promise.race([p, t])
		.then((v) => Promise.resolve(v === t ? "pending" : "fulfilled"))
		.catch(() => Promise.resolve("rejected")) as any;
};

interface fetchBody {
	[p: string]: string | number | Date | boolean | undefined | fetchBody | fetchBody[] | Array<string | number | Date | boolean>;
}

type originalMethod = "ALL" | "GET" | "POST" | "PATCH" | "PUT" | "DELETE" | "HEAD" | "OPTIONS";

type fetchMethod = "all" | "get" | "post" | "patch" | "put" | "delete" | "head" | "options" | originalMethod;

export interface RequestInit {
	route: string;
	method: fetchMethod;
	version?: string;
	headers?: Headers | string[][] | Record<string, string>;
	body?: fetchBody;
	cacheExpiration?: number | boolean;
	localStorager?: boolean;
}

export interface FetchOptions<D = any> extends RequestInit {
	cacheId?: string;
	forceRefresh?: boolean;
	useSimulation?: boolean;
	handleSimulation?: (args: { route: string; method: originalMethod; body: fetchBody }) => D | null | Promise<D>;
}

export interface UseFetchOptions<D = any> extends FetchOptions<D> {
	immediate?: boolean;
	delay?: number;
	handleResult?: (result: any) => D;
}

/**
 * Classe para auxiliar no gerenciamento de chamadas à API.
 */
class APIHelper {
	/**
	 * URL da API em ambiente de produção.
	 * @type {string}
	 */
	public API_URL: string;

	/**
	 * URL da API em ambiente de desenvolvimento.
	 * @type {string}
	 */
	public DEV_API_URL: string;

	/**
	 * Tempo de expiração das respostas da API em segundos (padrão: 15 segundos).
	 * @type {number}
	 */
	public API_RESPONSE_EXPIRES = 15;

	/**
	 * Cache para armazenar respostas da API.
	 * @type {Map<string | number, { props: string; expires: number; promise: Promise<any> }>}
	 * @private
	 */
	private __cache: Map<string | number, { props: string; expires: number; promise: Promise<any> }>;

	/**
	 * Cache para armazenar respostas da API permanentemente.
	 * @type {Map<string, { props: string; promise: Promise<any> }>}
	 * @private
	 */
	readonly __cache_permanent: Map<string, { props: string; promise: Promise<any> }>;

	/**
	 * Intervalo para atualização do cache.
	 * @type {NodeJS.Timer | undefined}
	 * @private
	 */
	private __chache_interval: NodeJS.Timer | undefined;

	/**
	 * Cria uma instância de APIHelper.
	 * @param {string} api_url - URL da API em ambiente de produção.
	 * @param {string} dev_api_url - URL da API em ambiente de desenvolvimento.
	 */
	constructor(api_url: string = "", dev_api_url: string = "") {
		this.API_URL = api_url;
		this.DEV_API_URL = dev_api_url;
		this.__cache = new Map();
		this.__cache_permanent = new Map();

		// Inicializa o cache e o intervalo para atualização.
		this.updateCache();
	}

	/**
	 * Gera uma URL com base na rota e na versão fornecidas.
	 *
	 * @param {string} route - A rota para a qual você deseja criar a URL.
	 * @param {string|undefined} version - (Opcional) A versão da API a ser usada na URL.
	 * @returns {string} A URL resultante com base na rota e versão especificadas.
	 */
	urlBy(route: string, version: string | undefined = undefined) {
		/**
		 * Condição para verificar a validade da versão e formatá-la corretamente.
		 * @type {string}
		 */
		version = typeof version === "string" && /^(v[0-9\.]+)$/gi.test(version) ? "/" + version : "/v1";

		/**
		 * Configurações obtidas do armazenamento local.
		 * @type {Object}
		 */
		const settings = JSON.parse(
			window.localStorage.hasOwnProperty("main-settings") ? (window.localStorage.getItem("main-settings") as any) : "{}",
		);

		/**
		 * Determina se a API local deve ser usada com base nas configurações e no ambiente.
		 * @type {boolean}
		 */
		const useLocalApi = isLocalhost && settings["development.localApi"] === true;

		/**
		 * Constrói a URL final com base na rota, versão e ambiente.
		 * @type {string}
		 */
		const finalUrl =
			(useLocalApi ? this.DEV_API_URL : this.API_URL) +
			(typeof route === "string" ? (version + "/" + route).replace(/^(\/+)/gi, "").replace(/(\/+)/gi, "/") : "");

		return finalUrl;
	}

	/**
	 * Atualiza o cache com base no tempo de expiração definido.
	 * @private
	 */
	updateCache() {
		clearInterval(this.__chache_interval as any);
		this.__chache_interval = setInterval(async () => {
			const now = Date.now();

			for (const [key, value] of this.__cache) {
				const state = await promiseState(value.promise);

				if (state !== "pending" || value.expires < now) {
					this.__cache.delete(key);
				}
			}
		}, 10 * 60 * 1000);
	}

	/**
	 * Cria um objeto com um método "fetch" que simula chamadas à API com base em uma função de moldagem.
	 *
	 * @template D - O tipo de dados a ser retornado pela função de moldagem.
	 * @param {(body: fetchBody | undefined) => D} func - A função de moldagem que transforma o corpo da solicitação em dados.
	 * @returns {Object} Um objeto com um método "fetch" para simular chamadas à API.
	 */
	forMold<D = any>(
		func: (body: fetchBody | undefined) => D,
	): {
		fetch: (route: string, body: fetchBody | undefined, cacheExpiration: number | boolean | undefined) => Promise<D>;
	} {
		/**
		 * Garante que "func" seja uma função válida, caso contrário, fornece uma função de retorno vazia.
		 * @type {(body: fetchBody | undefined) => D}
		 */
		func = typeof func === "function" ? func : () => ({} as D);

		/**
		 * Simula uma chamada à API e retorna os dados moldados.
		 *
		 * @param {string} route - A rota da API.
		 * @param {fetchBody | undefined} body - (Opcional) O corpo da solicitação.
		 * @param {number | boolean | string | undefined} cacheExpiration - (Opcional) Tempo de expiração do cache em segundos.
		 * @returns {Promise<D>} Uma Promise que resolve com os dados moldados.
		 */
		const fetch = (
			route: string,
			body: fetchBody | undefined = undefined,
			cacheExpiration: number | boolean | string | undefined = undefined,
		): Promise<D> => {
			return new Promise((resolve) => {
				const data = func(body);
				setTimeout(() => {
					resolve(data);
				}, 1000 + Math.floor(Math.random() * 1000)); // Simula um atraso na resposta.
			});
		};

		return { fetch };
	}

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {fetchOptions<D>} option - As opções personalizadas para a solicitação.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 * @example
	 * // Realizar uma solicitação à API com opções personalizadas.
	 * const response = await fetch({ route: "rota-da-api", method: "GET" });
	 * @example
	 * // Realizar uma solicitação à API com opções personalizadas.
	 * const response = await fetch({ route: "rota-da-api", method: "POST", body: { prop: "valor" } });
	 * @example
	 * // Realizar uma solicitação à API com opções personalizadas.
	 * const response = await fetch({ route: "rota-da-api", method: "GET", version: "v2" });
	 * @example
	 * // Realizar uma solicitação à API com opções personalizadas.
	 * const response = await fetch({ route: "rota-da-api", method: "POST", body: { prop: "valor" }, version: "v2" });
	 */
	async fetch<D = any>(option: FetchOptions<D>): Promise<D>;

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com rota personalizada.
	 * const response = await fetch("rota-da-api");
	 */
	async fetch<D = any>(route: string): Promise<D>;

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {fetchMethod} method - O método HTTP a ser utilizado na solicitação (por exemplo, "GET", "POST", etc.).
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com rota personalizada.
	 * const response = await fetch("rota-da-api", "GET");
	 */
	async fetch<D = any>(route: string, method: fetchMethod): Promise<D>;

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {string} [version] - (Opcional) A versão da API.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com versão personalizados.
	 * const response = await fetch("rota-da-api", "v2");
	 */
	async fetch<D = any>(route: string, version: string): Promise<D>;

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {fetchMethod} method - O método HTTP a ser utilizado na solicitação (por exemplo, "GET", "POST", etc.).
	 * @param {string} [version] - (Opcional) A versão da API.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com versão personalizados.
	 * const response = await fetch("rota-da-api", "GET", "v2");
	 */
	async fetch<D = any>(route: string, method: fetchMethod, version: string): Promise<D>;

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {string|fetchBody} [body] - (Opcional) O corpo da solicitação.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com corpo.
	 * const response = await fetch("rota-da-api", { prop: "valor" });
	 */
	async fetch<D = any>(route: string, body: fetchBody): Promise<D>;

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {fetchMethod} method - O método HTTP a ser utilizado na solicitação (por exemplo, "GET", "POST", etc.).
	 * @param {string|fetchBody} [body] - (Opcional) O corpo da solicitação.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com corpo.
	 * const response = await fetch("rota-da-api", "POST", { prop: "valor" });
	 */
	async fetch<D = any>(route: string, method: fetchMethod, body: fetchBody): Promise<D>;

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {string|fetchBody} [body] - (Opcional) O corpo da solicitação.
	 * @param {string} [version] - (Opcional) A versão da API.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com corpo e versão personalizados.
	 * const response = await fetch("rota-da-api", { prop: "valor" }, "v2");
	 */
	async fetch<D = any>(route: string, body: fetchBody, version: string): Promise<D>;

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {fetchMethod} method - O método HTTP a ser utilizado na solicitação (por exemplo, "GET", "POST", etc.).
	 * @param {string|fetchBody} [body] - (Opcional) O corpo da solicitação.
	 * @param {string} [version] - (Opcional) A versão da API.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com corpo e versão personalizados.
	 * const response = await fetch("rota-da-api", "POST", { prop: "valor" }, "v2");
	 */
	async fetch<D = any>(route: string, method: fetchMethod, body: fetchBody, version: string): Promise<D>;

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {number|boolean} [cacheExpiration] - (Opcional) Tempo de expiração do cache.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com tempo de expiração do cache.
	 * const response = await fetch("rota-da-api", 15);
	 */
	async fetch<D = any>(route: string, cacheExpiration: number | boolean): Promise<D>;

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {fetchMethod} method - O método HTTP a ser utilizado na solicitação (por exemplo, "GET", "POST", etc.).
	 * @param {number|boolean} [cacheExpiration] - (Opcional) Tempo de expiração do cache.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com tempo de expiração do cache.
	 * const response = await fetch("rota-da-api", "POST", 15);
	 */
	async fetch<D = any>(route: string, method: fetchMethod, cacheExpiration: number | boolean): Promise<D>;

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {number|boolean} [cacheExpiration] - (Opcional) Tempo de expiração do cache.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com corpo e tempo de expiração do cache.
	 * const response = await fetch("rota-da-api", { prop: "valor" }, 15);
	 */
	async fetch<D = any>(route: string, body: fetchBody, cacheExpiration: number | boolean): Promise<D>;

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {fetchMethod} method - O método HTTP a ser utilizado na solicitação (por exemplo, "GET", "POST", etc.).
	 * @param {number|boolean} [cacheExpiration] - (Opcional) Tempo de expiração do cache.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com corpo e tempo de expiração do cache.
	 * const response = await fetch("rota-da-api", "POST", { prop: "valor" }, 15);
	 */
	async fetch<D = any>(route: string, method: fetchMethod, body: fetchBody, cacheExpiration: number | boolean): Promise<D>;

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {string} [version] - (Opcional) A versão da API.
	 * @param {number|boolean} [cacheExpiration] - (Opcional) Tempo de expiração do cache.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com versão personalizados e tempo de expiração do cache.
	 * const response = await fetch("rota-da-api", "v2", 15);
	 */
	async fetch<D = any>(route: string, version: string, cacheExpiration: number | boolean): Promise<D>;

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {fetchMethod} method - O método HTTP a ser utilizado na solicitação (por exemplo, "GET", "POST", etc.).
	 * @param {string} [version] - (Opcional) A versão da API.
	 * @param {number|boolean} [cacheExpiration] - (Opcional) Tempo de expiração do cache.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com versão personalizados e tempo de expiração do cache.
	 * const response = await fetch("rota-da-api", "GET", "v2", 15);
	 */
	async fetch<D = any>(route: string, method: fetchMethod, version: string, cacheExpiration: number | boolean): Promise<D>;

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {string|fetchBody} [body] - (Opcional) O corpo da solicitação.
	 * @param {string} [version] - (Opcional) A versão da API.
	 * @param {number|boolean} [cacheExpiration] - (Opcional) Tempo de expiração do cache.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com corpo, versão personalizados e tempo de expiração do cache.
	 * const response = await fetch("rota-da-api", { prop: "valor" }, "v2", 15);
	 */
	async fetch<D = any>(route: string, body: fetchBody, version: string, cacheExpiration: number | boolean): Promise<D>;

	/**
	 * Realiza uma solicitação à API com opções personalizadas.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {fetchMethod} method - O método HTTP a ser utilizado na solicitação (por exemplo, "GET", "POST", etc.).
	 * @param {string|fetchBody} [body] - (Opcional) O corpo da solicitação.
	 * @param {string} [version] - (Opcional) A versão da API.
	 * @param {number|boolean} [cacheExpiration] - (Opcional) Tempo de expiração do cache.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com corpo, versão personalizados e tempo de expiração do cache.
	 * const response = await fetch("rota-da-api", "POST", { prop: "valor" }, "v2", 15);
	 */
	async fetch<D = any>(route: string, method: fetchMethod, body: fetchBody, version: string, cacheExpiration: number | boolean): Promise<D>;

	/**
	 * Realiza uma solicitação assíncrona a uma rota da API com opções adicionais.
	 *
	 * @template D - O tipo de dados a ser retornado pela solicitação.
	 * @param {...any} args - Argumentos da solicitação, incluindo rota, corpo, versão e tempo de expiração do cache.
	 * @returns {Promise<D>} Uma Promise que resolve com os dados da solicitação.
	 *
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 *
	 * @example
	 * // Realizar uma solicitação à API com rota personalizada.
	 * const response = await fetch("rota-da-api");
	 *
	 * // Realizar uma solicitação à API com corpo e versão personalizados.
	 * const response = await fetch("rota-da-api", { prop: "valor" }, "v2");
	 */
	async fetch<D = any>(...args: any[]): Promise<D> {
		const methods = ["all", "get", "post", "patch", "put", "delete", "head", "options"];

		const options: FetchOptions<D> =
			typeof args[0] === "object"
				? args[0]
				: {
						route: args[0],
						method: args.slice(1).find((v) => typeof v === "string" && methods.includes(v.toLowerCase())) ?? "POST",
						body: args.slice(1).find((v) => typeof v === "object"),
						version: args.slice(1).find((v) => typeof v === "string" && /^(v[0-9\.]+)$/gi.test(v.toLowerCase())),
						cacheExpiration: args.slice(1).find((v) => typeof v === "number" || typeof v === "boolean"),
				  };

		let { route, method, body, version, cacheId, cacheExpiration, forceRefresh = false, headers = {}, localStorager = false } = options;

		method = method.toUpperCase() as fetchMethod;

		const now = Date.now();
		let key: number | string = now;
		const propsCompare: string = JSON.stringify([route, method, body, version]);

		if (cacheExpiration !== true && typeof cacheId !== "string") {
			for (const [k, { props }] of this.__cache) {
				if (props === propsCompare) {
					key = k;
					break;
				}
			}

			const cachedItem = this.__cache.get(key);

			if (cachedItem) {
				const state = await promiseState(cachedItem.promise);

				if (state === "pending" || cachedItem.expires >= now) {
					return cachedItem.promise;
				} else {
					this.__cache.delete(key);
				}
			}
		} else if (typeof cacheId === "string" && !forceRefresh) {
			const cachedItem = this.__cache_permanent.get(cacheId);

			if (cachedItem) {
				return cachedItem.promise;
			}
		}

		const expirationDuration = (typeof cacheExpiration === "number" ? cacheExpiration : this.API_RESPONSE_EXPIRES) * 1000;
		const newExpiration = now + expirationDuration;

		const promise: Promise<D> = new Promise(async (resolve: (error: D) => void, reject: (error: Resultado) => void) => {
			try {
				if (typeof route !== "string") {
					reject(new Resultado(-1, "", null, {}));
					return;
				}

				route = this.urlBy(route, version);

				const timestamp = Date.now();

				body = typeof body === "object" ? body : {};

				const settings = window.localStorage.hasOwnProperty("main-settings")
					? JSON.parse(window.localStorage.getItem("main-settings") as any)
					: {
							"account.currencyType": "USD",
					  };

				const currencyType =
					"account.currencyType" in settings && typeof settings["account.currencyType"] === "string"
						? settings["account.currencyType"]
						: "USD";

				let h: Headers = new Headers({
					"Accept": "application/json",
					"Content-Type": "application/json",
				});

				if (headers instanceof Headers) {
					headers.forEach((v, k) => h.set(k, v));
				} else if (Array.isArray(headers)) {
					headers.forEach(([k, v]) => h.set(k, v));
				} else if (typeof headers === "object") {
					for (const k in headers) {
						h.set(k, headers[k]);
					}
				}

				if (MultiStorager.DataStorager.get("usuario") instanceof Usuario) {
					let usuario = MultiStorager.DataStorager.get("usuario");
					h.set("__USER_ID", usuario.path.split("/").pop());
				}

				const user = getAuth().currentUser;

				if (user && typeof user.accessToken === "string" && user.accessToken.trim() !== "") {
					h.set("Authorization", `Bearer ${user.accessToken}`);
				} else if (MultiStorager.DataStorager.get("usuario") instanceof Usuario) {
					let usuario = MultiStorager.DataStorager.get("usuario");
					h.set("X-USER-ID", usuario.path.split("/").pop());
				}

				if (options.useSimulation === true && typeof options.handleSimulation === "function") {
					const r = await options.handleSimulation({
						route,
						method: method.toUpperCase() as originalMethod,
						body,
					});

					setTimeout(() => {
						if (r !== null) {
							resolve(r);
						} else {
							reject(new Resultado(-1, "Simulation failed", null, {}));
						}
					}, 1000 + Math.floor(Math.random() * 1000));

					return;
				}

				const rawResponse = await fetch(route, {
					method: method,
					headers: h,
					body: ["GET"].includes(method.toUpperCase()) ? undefined : JSON.stringify({ ...body, currencyType, timestamp }),
				});

				const contentType = rawResponse.headers.get("content-type");

				const status = rawResponse.status;

				if (status !== 200) {
					if (contentType && contentType.indexOf("application/json") !== -1) {
						const { description } = await rawResponse.json();
						return reject(new Resultado(-1, description, null, {}));
					}
					return reject(new Resultado(-1, rawResponse.statusText, null, {}));
				}

				let errorMsg = "";

				if (contentType && contentType.indexOf("application/json") !== -1) {
					const { code, response, description } = await rawResponse.json();

					if (code === 200) {
						resolve(response);
						return;
					} else {
						errorMsg = description;
					}
				} else {
					const content = await rawResponse.blob();
					if (!(Blob as any).prototype.buffer || typeof (Blob as any).prototype.buffer !== "function") {
						(Blob as any).prototype.buffer = function () {
							return this.arrayBuffer().then((b: any) => Promise.resolve(Buffer.from(b)));
						};
					}
					resolve(content as any);
					return;
				}

				reject(new Resultado(-1, errorMsg, null, {}));
			} catch (e) {
				reject(new Resultado(-1, String(e), null, {}));
			}
		});

		if (typeof cacheId === "string") {
			const memo = { props: propsCompare, promise };
			this.__cache_permanent.set(cacheId, memo);
		} else {
			const memo = { props: propsCompare, expires: newExpiration, promise };
			this.__cache.set(key, memo);
		}

		if (localStorager) {
			try {
				promise.then((data) => {
					MultiStorager.LocalStorager.set(`_API_FETCH_${propsCompare}`, data);
				});
			} catch {}

			const data = MultiStorager.LocalStorager.get(`_API_FETCH_${propsCompare}`);

			if (data) {
				return Promise.resolve(data);
			}
		}

		return promise;
	}

	/**
	 * Realiza uma solicitação à API com o método HTTP "GET".
	 * @template T - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {Partial<Pick<RequestInit, "headers" | "version" | "cacheExpiration">} [options] - (Opcional) Opções personalizadas para a solicitação.
	 * @returns {Promise<T>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 * @example
	 * // Realizar uma solicitação à API com o método HTTP "GET".
	 * const response = await get("rota-da-api");
	 */
	get<T = any>(route: string, options?: Partial<Pick<RequestInit, "headers" | "version" | "cacheExpiration">>): Promise<T> {
		return this.fetch<T>({
			route,
			method: "GET",
			version: options?.version as any,
			cacheExpiration: options?.cacheExpiration as any,
			headers: options?.headers as any,
		});
	}

	/**
	 * Realiza uma solicitação à API com o método HTTP "POST".
	 * @template T - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {Partial<Pick<RequestInit, "body" | "headers" | "version" | "cacheExpiration">} [options] - (Opcional) Opções personalizadas para a solicitação.
	 * @returns {Promise<T>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 * @example
	 * // Realizar uma solicitação à API com o método HTTP "POST".
	 * const response = await post("rota-da-api", { body: { prop: "valor" } });
	 */
	post<T = any>(route: string, options?: Partial<Pick<RequestInit, "body" | "headers" | "version" | "cacheExpiration">>): Promise<T> {
		return this.fetch<T>({
			route,
			method: "POST",
			body: options?.body as any,
			version: options?.version as any,
			cacheExpiration: options?.cacheExpiration as any,
			headers: options?.headers as any,
		});
	}

	/**
	 * Realiza uma solicitação à API com o método HTTP "PUT".
	 * @template T - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {Partial<Pick<RequestInit, "body" | "headers" | "version" | "cacheExpiration">} [options] - (Opcional) Opções personalizadas para a solicitação.
	 * @returns {Promise<T>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 * @example
	 * // Realizar uma solicitação à API com o método HTTP "PUT".
	 * const response = await put("rota-da-api", { body: { prop: "valor" } });
	 */
	put<T = any>(route: string, options?: Partial<Pick<RequestInit, "body" | "headers" | "version" | "cacheExpiration">>): Promise<T> {
		return this.fetch<T>({
			route,
			method: "PUT",
			body: options?.body as any,
			version: options?.version as any,
			cacheExpiration: options?.cacheExpiration as any,
			headers: options?.headers as any,
		});
	}

	/**
	 * Realiza uma solicitação à API com o método HTTP "PATCH".
	 * @template T - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {Partial<Pick<RequestInit, "body" | "headers" | "version" | "cacheExpiration">} [options] - (Opcional) Opções personalizadas para a solicitação.
	 * @returns {Promise<T>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 * @example
	 * // Realizar uma solicitação à API com o método HTTP "PATCH".
	 * const response = await patch("rota-da-api", { body: { prop: "valor" } });
	 */
	patch<T = any>(route: string, options?: Partial<Pick<RequestInit, "body" | "headers" | "version" | "cacheExpiration">>): Promise<T> {
		return this.fetch<T>({
			route,
			method: "PATCH",
			body: options?.body as any,
			version: options?.version as any,
			cacheExpiration: options?.cacheExpiration as any,
			headers: options?.headers as any,
		});
	}

	/**
	 * Realiza uma solicitação à API com o método HTTP "DELETE".
	 * @template T - O tipo de dados a ser retornado pela solicitação.
	 * @param {string} route - A rota da API.
	 * @param {Partial<Pick<RequestInit, "body" | "headers" | "version" | "cacheExpiration">} [options] - (Opcional) Opções personalizadas para a solicitação.
	 * @returns {Promise<T>} Uma Promise que resolve com os dados da solicitação.
	 * @throws {Resultado} Lança um erro se a solicitação falhar.
	 * @example
	 * // Realizar uma solicitação à API com o método HTTP "DELETE".
	 * const response = await delete("rota-da-api", { body: { prop: "valor" } });
	 */
	delete<T = any>(route: string, options?: Partial<Pick<RequestInit, "body" | "headers" | "version" | "cacheExpiration">>): Promise<T> {
		return this.fetch<T>({
			route,
			method: "DELETE",
			body: options?.body as any,
			version: options?.version as any,
			cacheExpiration: options?.cacheExpiration as any,
			headers: options?.headers as any,
		});
	}

	/**
	 * Obtém o endereço IP do usuário a partir da API, com suporte ao armazenamento em cache.
	 *
	 * @returns {Promise<string>} Uma Promise que resolve com o endereço IP do usuário.
	 *
	 * @throws {Resultado} Lança um erro com código -1 e mensagem de erro, caso a solicitação falhe.
	 *
	 * @example
	 * // Obtém o endereço IP do usuário.
	 * const userIP = await getMyIP();
	 */
	getMyIP(): Promise<string> {
		return new Promise(async (resolve, reject) => {
			try {
				if (MultiStorager.DataStorager.hasKey("__IpNetwork")) {
					let ip = MultiStorager.DataStorager.get("__IpNetwork");
					resolve(ip);
					return;
				}

				const ip = await this.fetch<string>("use/ip");
				MultiStorager.DataStorager.set("__IpNetwork", ip);

				resolve(ip);
			} catch (erro) {
				reject(new Resultado(-1, (erro as any).message, null, {}));
			}
		});
	}

	/**
	 * Limpa o cache de respostas da API.
	 * @returns {void}
	 * @example
	 * // Limpa o cache de respostas da API.
	 * clearCache();
	 */
	clearCache(): void {
		this.__cache.clear();
	}

	useFetch<D = any>(
		args: UseFetchOptions,
	): {
		execute: () => Promise<D | undefined>;
		status: "idle" | "pending" | "success" | "error";
		response: D | undefined;
		error: Error | null;
	} {
		return {
			execute: () => Promise.reject(new Error("Not implemented")),
			status: "error",
			response: undefined,
			error: new Error("Not implemented"),
		};
	}
}

const api_helper = new APIHelper("https://api.ivipcoin.com/", "http://localhost:8080/");

if (isLocalhost) {
	api_helper.API_URL = ["true"].includes(
		String(process.env.REACT_APP_LOCAL_API || "false")
			.trim()
			.toLowerCase(),
	)
		? "http://localhost:8080/"
		: api_helper.API_URL;
	window.APIHelper = api_helper;
}

api_helper.useFetch = <D = any>({
	route = "",
	method = "GET",
	version = "v1",
	body = {},
	cacheId,
	cacheExpiration = 5,
	immediate = true,
	delay = 500,
	handleResult,
	useSimulation = false,
	handleSimulation,
}: UseFetchOptions): {
	execute: () => Promise<D | undefined>;
	status: "idle" | "pending" | "success" | "error";
	response: D | undefined;
	error: Error | null;
} => {
	const [status, setStatus] = useState<"idle" | "pending" | "success" | "error">("idle");
	const [response, setResponse] = useState<D | undefined>(undefined);
	const [error, setError] = useState<Error | null>(null);
	const [isImmediateExecuted, setIsImmediateExecuted] = useState<boolean>(false);

	useEffect(() => {
		if (cacheId) {
			const cached = api_helper.__cache_permanent.get(cacheId);
			if (cached) {
				const id_undefined = Math.round(Math.random() * 1000000);
				Promise.race([cached.promise, Promise.resolve(id_undefined)]).then((response) => {
					setResponse(response === id_undefined ? undefined : response);
					setStatus(response === id_undefined ? "pending" : "success");
				});
			}
		}
	}, [cacheId]);

	const execute = useCallback<() => Promise<D | undefined>>(() => {
		setStatus((p) => (typeof cacheId === "string" ? p : "pending"));
		//setValue(null);
		setError(null);

		if (useSimulation) {
			return new Promise<D | undefined>(async (resolve, reject) => {
				setTimeout(async () => {
					if (typeof handleSimulation === "function") {
						try {
							const response: D | null = await Promise.any([
								(handleSimulation as (args: { route: string; method: originalMethod; body: fetchBody }) => D | null | Promise<D>)({
									route,
									method: method.toUpperCase() as any,
									body: method.toUpperCase() === "GET" ? body ?? {} : {},
								}),
							]);
							setResponse(response ?? undefined);
							setError(null);
							setStatus("success");
							resolve(response ?? undefined);
						} catch (e) {
							setError(new Error((e as any)?.message ?? String(e)));
							setStatus("error");
							resolve(undefined);
						}
					} else {
						resolve(handleSimulation ?? undefined);
					}
				}, Math.min(5000, 1000 + Math.floor(Math.random() * 1000)));
			});
		}

		return api_helper
			.fetch<D>({
				route,
				method: method,
				body: method.toUpperCase() === "GET" ? undefined : body,
				version: version,
				cacheId,
				cacheExpiration: cacheExpiration,
				forceRefresh: true,
			})
			.then((response: any) => {
				if (typeof handleResult === "function") {
					response = handleResult(response);
				}
				setResponse(response);
				setError(null);
				setStatus("success");
				return Promise.resolve(response);
			})
			.catch((error: any) => {
				setError(new Error(error.message));
				setStatus("error");
				return Promise.resolve(undefined);
			});
	}, [handleResult, route, method, body, version, cacheId, cacheExpiration, useSimulation, handleSimulation]);

	useEffect(() => {
		if (immediate) {
			const time = setTimeout(() => {
				if (isImmediateExecuted) {
					return;
				}
				setIsImmediateExecuted(true);
				execute();
			}, Math.max(delay, 500));

			return () => {
				clearTimeout(time);
			};
		}
	}, [delay, immediate, execute, isImmediateExecuted]);

	return {
		execute,
		status,
		response,
		error,
	};
};

export default api_helper;
