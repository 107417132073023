import React, { useState, useEffect, useLayoutEffect } from "react";
import indicadores from "./indicadores.json";
import flagsIcon from "./flagsIcon.json";
import currencyIcon from "./currencyIcon.json";

const defaultIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAC1UlEQVRoge2YTU8TURSG3zsztx0hBGVjiLhx5aq4wqgL2KG/wS0/QhIXLDDuZOkG/4DxDzRoUpIaDCAtpYFKG/xCok356geknY/rYgDpzJTeO21mkMyzas/MufO+c865My0QEhISEtIBxC14NIUhpmOGAOMM6PNb1HkIUGHAnAw8U6eRdznezIn4VQADvijkZ48wxHpe4Nf5oGQ/i+mYweUTDwADJsEre9BhgADj/ugRx02bswIB93wb+u0Bh4H/DSVoAZAp6NgElNhjAAx6Jg4tMQsYGld64Abo2ATow6f/vp981j685soPvIWsO2+LDT/hzu+qga1tGVs/ZcEs5gyZBnd21wwcHRN8SlMspClqx64PeFf0TJwr1oquzcDHFEW9YQlPrkQw/qjOlaclZi0hMWuL1zNxaPNvuK/ruFW15241vZjcloKFNG2KPbin4e4dXXSptvRON2vuuIUqNYKlLHXEl9coDqv8reSVjgwwBiQ/R6C73GjNsI4x4XqK0ZGBtU0Fv0utlyjuSsjm3cesuE1R3HZWThTPQ3xQlpDOtReQWqe4ddPEQL95FqseyPiRi4Ix4Fqvib4b/NumHU8VMEwgsUhhcFzXMIH5xcjZuVqdoLCqwjStFixkVDTq3mfFk4HUOsV+mT/1oEKQ2qCugrU6QSFtGfKCsIHiXuu+vohsXsFGSkVl3/mkrh7K+LauCq8JCBrQDCC57G1nua4QVEutjZd2FE9DLWRgKeNtb++RCQaj7S/1fSPqWqGL4Daw80fCl6/iraMQ4HZUcv/7w4aXoeYy0NAIkisR7kVPIQCGVAmKQJ1Fh5pr6YWU2BvmKYNRCb2yeJ7IUHP1xOhIA6Mjwjp8IfBfZJ0SGgiaK2mg7LsKfg7tATcD730Q4pU5e8BhQDYwCWDXFzli7MkKJu1BhwH1JTYJwzAD3uJytFMZwDtZwX11CoWgxYSEhIRcMf4CX0bv+buNPRoAAAAASUVORK5CYII=";

const Icons:React.FC<{
    [prop:string]: any;
    name: string;
    width?: number;
    height?: number;
    style?: React.CSSProperties
}> = ({name, width, height, style: _style, ...props})=>{
    const [img, setImg] = useState<string>(defaultIcon);
    const [style, setStyle] = useState<React.CSSProperties>({ display: "inline-block", width: 25, height: 25 });

    useEffect(()=>{
        if(typeof name !== "string"){return;}
        name = name.trim();

        const nameIcon = name.replace("indicador-", "").replace("flag-", "").replace("currency-", "");

        if(nameIcon.toLowerCase() in indicadores){
            setImg(()=> (indicadores as {[icon:string]:string})[nameIcon.toLowerCase()]);
        }else if(nameIcon in flagsIcon){
            setImg(()=> (flagsIcon as {[icon:string]:string})[nameIcon]);
        }else if(nameIcon.toUpperCase() in currencyIcon){
            setImg(()=> (currencyIcon as {[icon:string]:string})[nameIcon.toUpperCase()]);
        }else{
            setImg(()=> defaultIcon);
        }
    }, [name]);

    useEffect(()=>{
        setStyle((prev)=>{
            _style = typeof _style === "object" ? {...prev, ..._style} : prev;
            return {..._style, width: typeof width === "number" ? width : prev.width, height: typeof height === "number" ? height : prev.height};
        });
    }, [width, height, _style]);

    return <div {...props} style={{
        ...style,
        backgroundImage: `url(${img})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain"
    }}></div>
}

export default Icons;