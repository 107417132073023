import { Context } from "Utils/Canvas";
import React, { useState, useEffect, useContext, ForwardedRef, useImperativeHandle, forwardRef } from "react";
import { CanvasElement, GlobalSettingsStyled } from "./Types";
import { CanvasContext, GroupContext } from "./Context";
import { CanvasCurrentElement } from "./Elements";

export const generateUniqueId = (prefix = "id") => {
	const timestamp = Date.now().toString(36);
	const randomNum = Math.random().toString(36).substring(2, 5);
	return `${prefix}_${timestamp}_${randomNum}`;
};

export const useCanvasContext = () => {
	const canvasContext = useContext(CanvasContext);
	const groupContext = useContext(GroupContext);
	return groupContext.state.isGroup ? groupContext : canvasContext;
};

export const useCanvas = (callback: CanvasElement, deps: React.DependencyList) => {
	const canvasContext = useCanvasContext();
	const [id] = useState<string>(generateUniqueId());
	const [time] = useState<number>(Date.now());
	const [index, setIndex] = useState<number>(0);

	useEffect(() => {
		const { pushElement, removeElement, updateCanvas } = canvasContext;
		const index = pushElement(id, time, callback);
		setIndex(index);
		updateCanvas();
		return () => {
			removeElement(id, time);
		};
	}, [...deps, id]);

	return canvasContext;
};

export const applyForwardRef = <P = {}, T = P>(render: React.ForwardRefRenderFunction<CanvasCurrentElement<T>, P>) =>
	forwardRef<CanvasCurrentElement<T>, P>(render);

export const getElementProperties = <P extends Object>(
	props: P | CanvasCurrentElement<P>,
	ctx?: CanvasRenderingContext2D | null,
	alternativeProps?: P | CanvasCurrentElement<P>,
): CanvasCurrentElement<P> => {
	const scale = Object.fromEntries(
		Object.entries(
			typeof (props as any)?.transform?.scale === "number"
				? {
						y: (props as any)?.transform?.scale,
						x: (props as any)?.transform?.scale,
				  }
				: {
						x: (props as any)?.transform?.scale?.x ?? undefined,
						y: (props as any)?.transform?.scale?.y ?? undefined,
				  },
		).filter(([k, v]) => v !== undefined),
	);

	const alternativeScale = Object.fromEntries(
		Object.entries(
			typeof (alternativeProps as any)?.transform?.scale === "number"
				? {
						y: (alternativeProps as any)?.transform?.scale,
						x: (alternativeProps as any)?.transform?.scale,
				  }
				: {
						x: (alternativeProps as any)?.transform?.scale?.x ?? undefined,
						y: (alternativeProps as any)?.transform?.scale?.y ?? undefined,
				  },
		).filter(([k, v]) => v !== undefined),
	);

	return {
		...(props as P),
		x: (props as any)?.x ?? (alternativeProps as any)?.x ?? 0,
		y: (props as any)?.y ?? (alternativeProps as any)?.y ?? 0,
		fill: (props as any)?.fill ?? (alternativeProps as any)?.fill ?? (ctx?.fillStyle as string) ?? "black",
		stroke: (props as any)?.stroke ?? (alternativeProps as any)?.stroke ?? (ctx?.strokeStyle as string) ?? "black",
		strokeWidth: (props as any)?.strokeWidth ?? (alternativeProps as any)?.strokeWidth ?? (ctx?.lineWidth as number) ?? 1,
		opacity: (props as any)?.opacity ?? (alternativeProps as any)?.opacity ?? (ctx?.globalAlpha as number) ?? 1,
		boxShadow: {
			color: (props as any)?.boxShadow?.color ?? (alternativeProps as any)?.boxShadow?.color ?? (ctx?.shadowColor as string) ?? "black",
			blur: (props as any)?.boxShadow?.blur ?? (alternativeProps as any)?.boxShadow?.blur ?? (ctx?.shadowBlur as number) ?? 0,
			offsetX: (props as any)?.boxShadow?.offsetX ?? (alternativeProps as any)?.boxShadow?.offsetX ?? (ctx?.shadowOffsetX as number) ?? 0,
			offsetY: (props as any)?.boxShadow?.offsetY ?? (alternativeProps as any)?.boxShadow?.offsetY ?? (ctx?.shadowOffsetY as number) ?? 0,
		},
		filter: (props as any)?.filter ?? (alternativeProps as any)?.filter ?? (ctx?.filter as string) ?? "none",
		lineCap: (props as any)?.lineCap ?? (alternativeProps as any)?.lineCap ?? (ctx?.lineCap as any) ?? "butt",
		lineDashOffset: (props as any)?.lineDashOffset ?? (alternativeProps as any)?.lineDashOffset ?? (ctx?.lineDashOffset as number) ?? 0,
		lineJoin: (props as any)?.lineJoin ?? (alternativeProps as any)?.lineJoin ?? (ctx?.lineJoin as any) ?? "bevel",
		transform: {
			matrix: (props as any)?.transform?.matrix ??
				(alternativeProps as any)?.transform?.matrix ??
				(ctx?.getTransform?.() as any) ?? [1, 0, 0, 0, 1, 0, 0, 0, 1],
			origin: {
				x: (props as any)?.transform?.origin?.x ?? (alternativeProps as any)?.transform?.origin?.x ?? 0,
				y: (props as any)?.transform?.origin?.y ?? (alternativeProps as any)?.transform?.origin?.y ?? 0,
			},
			translate: {
				x: (props as any)?.transform?.translate?.x ?? (alternativeProps as any)?.transform?.translate?.x ?? 0,
				y: (props as any)?.transform?.translate?.y ?? (alternativeProps as any)?.transform?.translate?.y ?? 0,
				z: (props as any)?.transform?.translate?.z ?? (alternativeProps as any)?.transform?.translate?.z ?? undefined,
			},
			rotate: (props as any)?.transform?.rotate ?? (alternativeProps as any)?.transform?.rotate ?? 0,
			scale: Object.assign({ x: 1, y: 1 }, alternativeScale, scale),
			skew: {
				x: (props as any)?.transform?.skew?.x ?? (alternativeProps as any)?.transform?.skew?.x ?? 0,
				y: (props as any)?.transform?.skew?.y ?? (alternativeProps as any)?.transform?.skew?.y ?? 0,
			},
		},
		update: () => {},
	};
};

const useEllementProperties = <P extends Object>(
	initialProps: Partial<P>,
	defaultProps: P & Partial<GlobalSettingsStyled>,
): [CanvasCurrentElement<P>, (p: React.SetStateAction<Partial<P>>, isOriginal?: boolean) => void, CanvasCurrentElement<P>] => {
	const [originalProps, setOriginalProps] = useState<P & Partial<GlobalSettingsStyled>>({ ...defaultProps, ...initialProps });
	const [props, setProps] = useState<P & Partial<GlobalSettingsStyled>>({ ...defaultProps, ...initialProps });
	const canvasContext = useContext(CanvasContext);

	useEffect(() => {
		setProps((pre) => {
			const p = { ...pre, ...initialProps };
			return JSON.stringify(p) !== JSON.stringify(pre) ? p : pre;
		});
	}, [initialProps]);

	const canvas = canvasContext?.state.canvas;
	const ctx = canvas?.getContext("2d");
	return [
		getElementProperties<P>(props as any, ctx),
		(p: React.SetStateAction<Partial<P>>, isOriginal: boolean = false) => {
			setProps(p as any);
			if (isOriginal) setOriginalProps(p as any);
		},
		getElementProperties<P>(originalProps as any, ctx),
	];
};

const observable = <O extends Object>(
	originalObj: O,
	onChange: (target: {
		type: "add" | "update" | "delete" | "forceUpdate";
		prop: string;
		value: any;
		previousValue: any;
		path: string[];
		root: keyof O;
		obj: O;
	}) => void,
): O => {
	const createDeepProxy = <O extends Object>(
		obj: O,
		info: {
			path: string[];
			previousObj: O;
		} = {
			path: [],
			previousObj: obj,
		},
	): O & {
		render: () => void;
	} => {
		if (typeof obj !== "object" || obj === null) {
			return obj;
		}

		return new Proxy<
			O & {
				render: () => void;
			}
		>(obj as any, {
			set(target, prop: string, value, receiver) {
				if (prop === "toString" || (prop === "__original__" && "__original__" in target)) {
					return false;
				}
				const previousValue = Reflect.get(target, prop, receiver);
				if (value !== previousValue) {
					const path = [...info.path, prop];
					const type = prop in target ? "update" : "add";
					Reflect.set(target, prop, value, receiver);
					onChange({ type, prop, value, previousValue, path, root: path[0] as any, obj: originalObj });
					return Reflect.set(target, prop, value, receiver);
				}
				return true;
			},
			deleteProperty(target, prop: string) {
				if (prop === "toString" || (prop === "__original__" && "__original__" in target)) {
					return false;
				}
				const previousValue = Reflect.get(target, prop);
				const path = [...info.path, prop];
				Reflect.deleteProperty(target, prop);
				onChange({ type: "delete", prop, value: undefined, previousValue, path, root: path[0] as any, obj: originalObj });
				return Reflect.deleteProperty(target, prop);
			},
			get(target, prop: string, receiver) {
				if (prop === "toString") {
					return () => JSON.stringify(target);
				} else if (prop === "render") {
					return () => {
						onChange({
							type: "forceUpdate",
							prop: "render",
							value: undefined,
							previousValue: undefined,
							path: [],
							root: "" as any,
							obj: originalObj,
						});
					};
				} else if (prop === "__original__" && "__original__" in target) {
					return (target as any).__original__ ?? {};
				}
				const value = Reflect.get(target, prop, receiver);
				return createDeepProxy(value as Object, {
					path: [...info.path, prop],
					previousObj: target,
				});
			},
		});
	};

	return createDeepProxy(originalObj);
};

export const usePropsImperativeHandle = <P extends Object>(ref: ForwardedRef<CanvasCurrentElement<P>>, initialProps: Partial<P>, defaultProps: P) => {
	const [props, setProps, originalProps] = useEllementProperties<P>(initialProps, defaultProps);
	const canvasContext = useCanvasContext();

	useEffect(() => {
		setProps((pre) => {
			return { ...pre, ...initialProps };
		}, true);
	}, [initialProps]);

	useImperativeHandle(
		ref,
		() => {
			return observable<CanvasCurrentElement<P>>({ ...props, __original__: originalProps } as any, ({ type, root, obj }) => {
				if (type === "forceUpdate") {
					canvasContext?.updateCanvas();
					return;
				}

				setProps((prev) => ({ ...prev, [root]: obj[root] }));
			});
		},
		[props, canvasContext],
	);

	return [props, setProps] as const;
};

export const applyStyledProps = (
	ctx: CanvasRenderingContext2D,
	props: Partial<GlobalSettingsStyled>,
	draw?: (ctx: CanvasRenderingContext2D) => void,
) => {
	const { x = 0, y = 0, fill, stroke, strokeWidth, opacity, boxShadow, filter, lineCap, lineDashOffset, lineJoin, transform } = props;
	if (typeof fill === "string") {
		ctx.fillStyle = fill === "none" ? "transparent" : fill;
	}
	if (typeof stroke === "string") {
		ctx.strokeStyle = stroke;
	}
	if (typeof strokeWidth === "number") {
		ctx.lineWidth = strokeWidth;
	}
	if (typeof opacity === "number" && opacity < 1) {
		ctx.globalAlpha = opacity;
	}
	if (typeof boxShadow === "object" && boxShadow !== null) {
		const { color, blur, offsetX, offsetY } = boxShadow;
		if (typeof color === "string") ctx.shadowColor = color === "none" ? "transparent" : color;
		if (typeof blur === "number") ctx.shadowBlur = blur;
		if (typeof offsetX === "number") ctx.shadowOffsetX = offsetX;
		if (typeof offsetY === "number") ctx.shadowOffsetY = offsetY;
	}
	if (typeof filter === "string") {
		ctx.filter = filter;
	}
	if (typeof lineCap === "string") {
		ctx.lineCap = lineCap;
	}
	if (typeof lineDashOffset === "number") {
		ctx.lineDashOffset = lineDashOffset;
	}
	if (typeof lineJoin === "string") {
		ctx.lineJoin = lineJoin;
	}

	if (typeof transform === "object" && transform !== null) {
		const matrix = ctx.getTransform();
		if (transform.origin) {
			matrix.translateSelf(x + (transform.origin.x ?? 0), y + (transform.origin.y ?? 0));
		}

		if (typeof transform.translate === "object" && transform.translate !== null) {
			matrix.translateSelf(transform.translate.x ?? 0, transform.translate.y ?? 0, transform.translate.z);
		}

		if (typeof transform.rotate === "number") {
			matrix.rotateSelf(transform.rotate);
		}

		if (typeof transform.scale === "number" || (typeof transform.scale === "object" && transform.scale !== null)) {
			const { x: sX, y: sY } = typeof transform.scale === "number" ? { x: transform.scale, y: transform.scale } : transform.scale;
			matrix.scaleSelf(sX ?? 1, sY ?? 1, 1);
		}

		if (Array.isArray(transform.matrix) && transform.matrix.length === 6 && transform.matrix.every((n) => typeof n === "number")) {
			matrix.multiplySelf(new DOMMatrix(transform.matrix));
		}

		if (transform.origin) {
			matrix.translateSelf(-(x + (transform.origin.x ?? 0)), -(y + (transform.origin.y ?? 0)));
		}

		ctx.setTransform(matrix);
	}

	draw?.(ctx);
};

export const spiralTraversal = (
	rows: number,
	cols: number,
	callback: (row: number, col: number, i: number, matrix: number[][][], rows: number, cols: number) => void,
	inverted: boolean = false,
): void => {
	if (rows === 0 || cols === 0) {
		return;
	}

	const matrix: number[][][] = new Array(rows).fill(null).map((_, i) => {
		return new Array(cols).fill(null).map((_, j) => {
			return [i, j];
		});
	});

	let result: number[][] = [];
	const vis: boolean[][] = new Array(rows);
	for (let n = 0; n < rows; n++) {
		vis[n] = new Array(cols).fill(false);
	}

	const dx: number[] = [0, 1, 0, -1];
	const dy: number[] = [1, 0, -1, 0];
	let n = 0,
		m = 0,
		d = 0;

	for (let i = 0; i < rows * cols; i++) {
		result.push(matrix[n][m]);
		vis[n][m] = true;

		let nrow: number = n + dx[d];
		let ncol: number = m + dy[d];

		if (nrow >= 0 && nrow < rows && ncol >= 0 && ncol < cols && !vis[nrow][ncol]) {
			n = nrow;
			m = ncol;
		} else {
			d = (d + 1) % 4;
			n += dx[d];
			m += dy[d];
		}
	}

	result = inverted ? result.reverse() : result;

	result.forEach(([row, col], i) => {
		callback(row, col, i, matrix, rows, cols);
	});
};

const qualityRenderPerformance = (
	canvas: HTMLCanvasElement,
	options: Partial<{
		average_fps: number;
		ideal_resolution: number;
		renderPixel: (x: number, y: number, color: [number, number, number, number]) => [number, number, number, number];
		onRender: (fps: number, resolution: number) => void;
	}> = {},
) => {
	const { average_fps = 60, ideal_resolution = 0.4, renderPixel, onRender } = options;
	let fps: number = 0,
		resolution: number = 0.4,
		lastCalledTime: number = Date.now(),
		animationFrame: number | null = null;

	const update_fps = (): number => {
		let delta = (Date.now() - lastCalledTime) / 1000;
		lastCalledTime = Date.now();
		fps = 1 / delta;
		resolution += resolution * 0.05 * (fps > average_fps ? 1 : -1);
		resolution = Math.max(0.05, Math.min(1, resolution));
		return fps;
	};

	const render = () => {
		const width = canvas.width,
			height = canvas.height;
		const size = Math.min(width, height);
		const quality = Math.round(Math.sqrt(size) * (1 - resolution));
		const rows = Math.ceil(height / quality),
			cols = Math.ceil(width / quality);

		const ctx = canvas.getContext("2d");
		if (!ctx) {
			return;
		}

		const imgData = ctx.createImageData(width, height);

		spiralTraversal(rows, cols, (row, col, i) => {
			if (typeof renderPixel !== "function") {
				return;
			}
			const x = col * quality + quality / 2,
				y = row * quality + quality / 2;
			const index = ((height - y) * width + x) * 4;
			const color: [number, number, number, number] = imgData.data.slice(index, index + 4) as any;

			const [r, g, b, a] = renderPixel(x, y, color);

			for (let i = x - quality / 2; i < x + quality / 2; i++) {
				for (let j = y - quality / 2; j < y + quality / 2; j++) {
					const index = ((height - j) * width + i) * 4;
					imgData.data[index + 0] = r;
					imgData.data[index + 1] = g;
					imgData.data[index + 2] = b;
					imgData.data[index + 3] = a;
				}
			}
		});

		ctx.putImageData(imgData, 0, 0);

		if (typeof onRender === "function") {
			onRender(fps, resolution);
		}

		update_fps();
		animationFrame = requestAnimationFrame(render);
	};

	return {
		start: () => {
			animationFrame = requestAnimationFrame(render);
		},
		stop: () => {
			if (typeof animationFrame === "number") {
				cancelAnimationFrame(animationFrame);
			}
		},
	};
};
