import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TabsOperacao from "../../Components/TabsOperacao";
import Button from '@mui/material/Button';
import "./index.css";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#555555' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default {
    id: "Tela6",
    title: "Tela 6",
    render: () => {
        return (
            <div>
                    <TabsOperacao></TabsOperacao>

                <Box sx={{ flexGrow: 1,marginTop:4,marginLeft:2,marginRight:2}}>
                    <Grid container spacing={{ xs: 4, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {Array.from(Array(6)).map((_, index) => (
                            <Grid item xs={2} sm={2} md={2} key={index} >
                                <Item >
                                <div className="vaRiacOes">
                        <div className="Bitecon">
                            <div className="coMprAr-ANd-venDer">
                                <div className="idenx-btc">
                                    <Button variant="contained">idex/btc</Button>
                                </div>
                            </div>
                            <div className="unindo-index">
                                <div className="numero-index-btc-one">
                                    +2,5%
                                </div>
                            </div>
                        </div>
                    </div>
                                </Item>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </div>
        );
    },
};