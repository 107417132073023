import React from "react";
import { PhaseProps } from "Dialog/utils";
import { NewWalletDialogProps } from "..";
import { InputCustom } from "Components";

const Binance: React.FC<PhaseProps<NewWalletDialogProps<"BINANCE">>> = ({ state }) => {
	return (
		<>
			<InputCustom
				label={"Nome"}
				helperText=" "
				value={state.wallet.nome}
				onChange={(value) => {
					state.wallet.nome = value.toString();
					state.wallet = state.wallet;
				}}
			/>
			{state.wallet.status !== "valid" && (
				<InputCustom
					label={"API Key"}
					helperText=" "
					value={state.data.APIKey}
					onChange={(value) => {
						state.data.APIKey = value;
					}}
				/>
			)}
			{state.wallet.status !== "valid" && (
				<InputCustom
					label={"API Secret"}
					helperText=" "
					value={state.data.APISecret}
					type={"password"}
					onChange={(value) => {
						state.data.APISecret = value;
					}}
				/>
			)}
			<InputCustom
				label={"Número"}
				helperText=" "
				value={state.wallet.getWalletCode()}
				readOnly={true}
			/>
		</>
	);
};

export default Binance;
