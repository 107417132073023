import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MultiStorager } from "Utils";

import Palette from "./Palette";

export const ColorModeContext = React.createContext({
	toggleColorMode: () => {},
});

const declarePropertyValue = (json: { [p: string]: any }, initialName: string[] = []) => {
	if (typeof json !== "object") {
		return;
	}
	for (let k in json) {
		if (typeof json[k] === "number" || typeof json[k] === "string") {
			document.body.style.setProperty(`--${initialName.join("-")}-${k}`, json[k]);
		} else {
			try {
				declarePropertyValue(json[k], [...initialName, k]);
			} catch (e) {}
		}
	}
};

const Theme: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const [mode, setMode] = React.useState<"dark" | "light">(MultiStorager.LocalStorager.get("modeTheme") || "dark");

	const colorMode = React.useMemo(
		() => ({
			toggleColorMode: () => {
				setMode((prevMode) => {
					let m = prevMode === "light" ? "dark" : "light";
					MultiStorager.LocalStorager.set("modeTheme", m);
					return m as any;
				});
			},
		}),
		[],
	);

	const theme = React.useMemo(
		() =>
			createTheme({
				palette: {
					mode,
					...(Palette(mode) as any),
				},
			}),
		[mode],
	);

	let root = document.body;

	if (mode === "light" && root.classList.contains("theme-dark")) {
		root.classList.remove("theme-dark");
	}

	if (mode === "dark" && root.classList.contains("theme-light")) {
		root.classList.remove("theme-light");
	}

	root.classList.add("theme-" + mode);

	window.theme = theme;
	window.palette = theme.palette;

	declarePropertyValue(theme.palette);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</ColorModeContext.Provider>
	);
};

export default Theme;
