import Tabs from "../../Components/Tabs";
import Typography from "@mui/material/Typography";
import "./index.css";
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData("", "R$100.000.00","0.21315674 BTC", "8:32%"),
    createData("", "R$52.238.25","0.13546852 BTC","6:59%"),
    createData("", "R$100.000.00", "0.13546852 BTC","12:32%"),



];


export default {
    id: "Tela5",
    title: "Tela 5",
    render: () => {
        return (
            
                <div className="mover-griD-from-scren-four">
                    <div className="mY-cex-todos-dex">
                        <Tabs></Tabs>
                    </div>
                    <Typography
                        classes={{ root: "MuiTypography-root" }}
                        variant="h6"
                        gutterBottom
                        component="div"
                        sx={{ textAlign: "center", top: 20 }}
                    >
                        Lista de ativos - Todas as Exchanges e carteiras
                    </Typography>

                    <TableContainer component={Paper}   sx={{  top: 40,height:160 }}   classes={{ root: "MuiTableContainer-root-five" }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                
                            <TableHead>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.calories}</TableCell>
                                        <TableCell align="right">{row.fat}</TableCell>
                                        <TableCell align="right">{row.carbs}</TableCell>
                                    </TableRow>
                                ))} 
                                    <div>
                                        <Avatar alt="Remy Sharp" src="src/image/meta-mask.png" sx={{ width: 60, height: 20,top: -145,left:1 }}/>
                                        <Avatar alt="Remy Sharp"src="binance-icone.png"sx={{ width: 25, height: 25,top: -110,left:18 }}/>
                                        <Avatar alt="Remy Sharp"src="gate.io_logo.png"sx={{ width: 25, height: 25,top: -85,left:18 }}/>
                                    </div>
                                    
                                    <div >
                                        <Button variant="text" sx={{  top: -220, left:145,width:150 }}>METAMASK</Button >
                                        <Button variant="text" sx={{  top: -170,right:5, width:150 }}>BINANCE</Button >
                                       
                                    </div>
                                    <div> <Button variant="text" sx={{  top: -154,left:145, width:150 }}>GATE.IO</Button ></div>
                            </TableBody>
                        </Table>
                    </TableContainer>

                </div>
            
        );
    },
};
