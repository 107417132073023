import React, { useCallback, useEffect, useState } from "react";
import { CanvasCurrentElement } from "./Elements";
import { BezierEasing, Color } from "ivip-utils";
import { getElementProperties } from "./Utils";

const easingList = {
	"linear": "linear",
	"elastic": "elastic",
	"ease": "ease",
	"ease-in": "easeIn",
	"ease-in-elastic": "easeInElastic",
	"ease-in-bounce": "easeInBounce",
	"ease-in-expo": "easeInExpo",
	"ease-in-sine": "easeInSine",
	"ease-in-quad": "easeInQuad",
	"ease-in-cubic": "easeInCubic",
	"ease-in-back": "easeInBack",
	"ease-in-quart": "easeInQuart",
	"ease-in-quint": "easeInQuint",
	"ease-in-circ": "easeInCirc",
	"ease-in-out": "easeInOut",
	"ease-in-out-elastic": "easeInOutElastic",
	"ease-in-out-bounce": "easeInOutBounce",
	"ease-in-out-sine": "easeInOutSine",
	"ease-in-out-quad": "easeInOutQuad",
	"ease-in-out-cubic": "easeInOutCubic",
	"ease-in-out-back": "easeInOutBack",
	"ease-in-out-quart": "easeInOutQuart",
	"ease-in-out-quint": "easeInOutQuint",
	"ease-in-out-expo": "easeInOutExpo",
	"ease-in-out-circ": "easeInOutCirc",
	"ease-out": "easeOut",
	"ease-out-elastic": "easeOutElastic",
	"ease-out-bounce": "easeOutBounce",
	"ease-out-sine": "easeOutSine",
	"ease-out-quad": "easeOutQuad",
	"ease-out-cubic": "easeOutCubic",
	"ease-out-back": "easeOutBack",
	"ease-out-quart": "easeOutQuart",
	"ease-out-quint": "easeOutQuint",
	"ease-out-expo": "easeOutExpo",
	"ease-out-circ": "easeOutCirc",
	"fast-out-slow-in": "fastOutSlowIn",
	"fast-out-linear-in": "fastOutLinearIn",
	"linear-out-slow-in": "linearOutSlowIn",
};

export const brentProps = <E extends Object>(from: CanvasCurrentElement<E>, to: CanvasCurrentElement<E>, time: number): E => {
	const verifyComparison = (from: any, to: any, result: any = {}) => {
		const props: Array<keyof CanvasCurrentElement<E>> = [...Object.keys(from), ...Object.keys(to)].filter((k, i, l) => l.indexOf(k) === i) as any;

		for (const prop of props) {
			const fromValue: any = from[prop];
			const toValue: any = to[prop];

			if (
				!(prop in to) ||
				fromValue == toValue ||
				(typeof fromValue === "object" && typeof toValue === "object" && JSON.stringify(fromValue) === JSON.stringify(toValue))
			) {
				continue;
			}

			if (typeof fromValue === "number" && typeof toValue === "number") {
				(result as any)[prop] = fromValue + (toValue - fromValue) * time;
			} else if (typeof fromValue === "string" && typeof toValue === "string" && Color.isColor(fromValue) && Color.isColor(toValue)) {
				(result as any)[prop] = new Color(fromValue).blend(toValue, 1 - time).rgb;
			} else if (typeof fromValue === "object" && typeof toValue === "object") {
				(result as any)[prop] = verifyComparison(fromValue, toValue, { ...fromValue });
			} else if (time > 0.5) {
				(result as any)[prop] = toValue;
			}
		}

		return result;
	};

	return verifyComparison(from, to, {}) as E;
};

interface AnimateProps<E extends Object> {
	to: Partial<E>;
	duration?: number;
	delay?: number;
	easing?: BezierEasing | keyof typeof easingList | ((t: number) => number);
	iterationCount?: number | "infinite";
	direction?: "normal" | "reverse" | "alternate" | "alternate-reverse";
}

interface BezierOptions {
	delay?: number;
	duration: number;
	easing: BezierEasing | keyof typeof easingList | ((t: number) => number);
	iterationCount?: number | "infinite";
	direction?: "normal" | "reverse" | "alternate" | "alternate-reverse";
}

const BezierTimes = new Map<
	number,
	| { type: "timeout"; id: NodeJS.Timeout | undefined }
	| { type: "interval"; id: number | NodeJS.Timeout }
	| { type: "animation_frame"; id: number }
	| { type: "undefined"; id: undefined }
>();

const setBezierEasing = (beziers: BezierOptions | BezierOptions[], callback: (...t: number[]) => void): number => {
	const id = BezierTimes.size;
	const list: BezierOptions[] = (Array.isArray(beziers) ? beziers : [beziers]).map(({ iterationCount, ...b }) => {
		iterationCount = iterationCount === "infinite" ? iterationCount : iterationCount ?? 1;
		if (typeof iterationCount === "number" && (b.direction ?? "").search("alternate") === 0) {
			iterationCount += iterationCount % 2;
		}
		return { ...b, iterationCount };
	});
	const currentInfo: Array<{ count: number; start: number; end: number; delay: number }> = list.map((b) => ({
		delay: b.delay ?? 0,
		start: b.delay ?? 0,
		end: b.duration,
		count: 0,
	}));
	const delay = Math.min(...currentInfo.map((b) => b.delay));

	BezierTimes.set(id, {
		type: "timeout",
		id: setTimeout(() => {
			const start = Date.now();

			for (const i in currentInfo) {
				currentInfo[i].start = start + (currentInfo[i].delay > delay ? currentInfo[i].delay - delay : 0);
				currentInfo[i].end = currentInfo[i].start + list[i].duration;
				currentInfo[i].count += 1;
			}

			const loop = () => {
				const now = Date.now();

				const tList = list.map((b, i) => {
					const { start, end, count } = currentInfo[i];
					const t = Math.max(0, Math.min(1, (now - start) / b.duration));

					let current =
						typeof b.easing === "function"
							? b.easing(t)
							: typeof b.easing === "string" && b.easing in easingList
							? (BezierEasing as any)[easingList[b.easing]](t)
							: b.easing instanceof BezierEasing
							? b.easing.to(t)
							: t;

					current = (b.direction ?? "").search("alternate") === 0 ? ((count - 1) % 2 === 0 ? t : 1 - t) : t;
					current = (b.direction ?? "").search("reverse") >= 0 ? 1 - current : current;

					if (t >= 1 && (b.iterationCount === "infinite" || count < (b.iterationCount ?? 1))) {
						currentInfo[i].start = Date.now() + (b.delay ?? 0);
						currentInfo[i].end = currentInfo[i].start + b.duration + 20;
						currentInfo[i].count += 1;
					}

					return current;
				});

				callback(...tList);

				const end = Math.max(...currentInfo.map((b) => b.end));

				if (now < end) {
					BezierTimes.set(id, {
						type: "animation_frame",
						id: requestAnimationFrame(loop),
					});
				} else {
					BezierTimes.set(id, {
						type: "undefined",
						id: undefined,
					});
				}
			};

			loop();
		}, delay),
	});

	return id;
};

const clearBezierEasing = (id: number | undefined) => {
	if (id === undefined) return;
	const { type, id: _id } = BezierTimes.get(id) ?? { type: "undefined", id: undefined };
	if (type === "timeout") {
		clearTimeout(_id);
	} else if (type === "interval") {
		clearInterval(_id);
	} else if (type === "animation_frame") {
		cancelAnimationFrame(_id as number);
	}
};

export const useAnimate = <E extends Object>(
	props: AnimateProps<E>,
): {
	ref: React.RefObject<CanvasCurrentElement<E>>;
	start: () => void;
} => {
	const element = React.useRef<CanvasCurrentElement<E>>(null);
	const timeRef = React.useRef<number | undefined>(undefined);

	const start = useCallback(() => {
		if (!element.current) {
			return;
		}

		const from = getElementProperties<E>(JSON.parse(JSON.stringify(element.current.__original__ ?? {})));
		const { to, duration = 1000, delay = 0, easing = "linear", iterationCount = 1, direction = "normal" } = props;

		clearBezierEasing(timeRef.current);
		timeRef.current = setBezierEasing(
			{
				delay,
				duration,
				easing,
				iterationCount,
				direction,
			},
			(t) => {
				const newProperties = brentProps<E>(from, getElementProperties<E>(to as any, undefined, from), t);

				for (const prop in newProperties) {
					(element.current as any)[prop] = newProperties[prop];
				}
			},
		);
	}, [props]);

	useEffect(() => {
		return () => {
			clearBezierEasing(timeRef.current);
		};
	}, [element]);

	return {
		ref: element,
		start,
	};
};
