import { useReducer, useEffect } from 'react';
import { produce } from 'immer';
import { isDate } from 'Utils';
import { keysModel, reduceDataToDays } from './utils';
import { Resultado } from 'Models';

const initialState = {
    maxValue: 0,
    minValue: 0,
    maxVolume: 0,
    minVolume: 0,
    dateEnd: new Date(),
    dateStart: new Date(),
    data: [],
    isDataValid: false
};

const reducerActions = {
    set_data: (state, payload) => {
        if(typeof payload.maxValue === "number"){
            state.maxValue = payload.maxValue;
        }
        if(typeof payload.minValue === "number"){
            state.minValue = payload.minValue;
        }
        if(typeof payload.maxVolume === "number"){
            state.maxVolume = payload.maxVolume;
        }
        if(typeof payload.minVolume === "number"){
            state.minVolume = payload.minVolume;
        }
        if(typeof payload.minVolume === "number"){
            state.minVolume = payload.minVolume;
        }
        if(isDate(payload.dateEnd)){
            state.dateEnd = new Date(payload.dateEnd);
        }
        if(isDate(payload.dateStart)){
            state.dateStart = new Date(payload.dateStart);
        }
        if(Array.isArray(payload.data)){
            state.data = payload.data;
        }
        if(typeof payload.isDataValid === "boolean"){
            state.isDataValid = payload.isDataValid;
        }
    }
};

const reducer = (state, action) => {
    let fn = reducerActions[action.type];
    if(fn){
        return produce(state, draftState => fn(draftState, action.payload));
    }
    //console.log('[WARNING] Action without reducer:', action);
    return state;
};

const useLoadData = (data, { numberOfPricePoints = 13 })=>{
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(()=>{
        let isDataValid = Array.isArray(data) && data.length > 0 && data.every(v => (v && v.hasOwnProperty && keysModel.every(item => v.hasOwnProperty(item))));

        if(isDataValid !== true){ 
            return;
        }

        numberOfPricePoints = typeof numberOfPricePoints === "number" ? (Math.max(7, numberOfPricePoints) - 1) : 13;

        data.sort((a, b)=>{
            return b.date - a.date;
        });

        let maxValue = data[0]?.high ?? 0, minValue = data[0]?.low ?? 0;
        let maxVolume = data[0]?.volume ?? 0, minVolume = data[0]?.volume ?? 0;
        let dateStart = data[0]?.date ?? new Date(), dateEnd = data[0]?.date ?? new Date();
        
        data.forEach((obj, index)=>{
            maxValue = Math.max(maxValue, obj.open, obj.high, obj.low, obj.close);
            minValue = Math.min(minValue, obj.open, obj.high, obj.low, obj.close);

            maxVolume = Math.max(maxVolume, obj.volume);
            minVolume = Math.min(minVolume, obj.volume);

            dateStart = Math.min(dateStart, obj.date);
            dateEnd = Math.max(dateEnd, obj.date);
        });

        let result = [];
        
        data.forEach((v, index)=>{
            let value = JSON.parse(JSON.stringify(v));

            const start = Math.max(0, index - numberOfPricePoints);
            const end = index;

            const subset = data.slice(start, end + 1);
            const sum = subset.reduce((a, b) => (a + b.close), 0);

            value.average = (sum / subset.length);

            value.date = new Date(value.date);

            const days = Math.round((value.date - dateStart)/(1000 * 60 * 60 * 24));
            const hours = value.date.getHours();

            result.push(value);
        });

        result = result.sort((a, b)=>{
            return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
        });

        //result = reduceDataToDays(result);

        dateEnd = result[0].date;
        dateStart = result[result.length-1].date;

        //const list = Object.keys(result).sort().map(key => result[key]).reverse();

        dispatch({
            type: "set_data",
            payload: {
                maxValue: maxValue,
                minValue: minValue,
                maxVolume: maxVolume,
                minVolume: minVolume,
                dateEnd: new Date(dateEnd),
                dateStart: new Date(dateStart),
                data: result,
                isDataValid: true
            }
        });
    }, [data]);

    return state;
}

export default useLoadData;