import React from "react";
import { createRoot } from "react-dom/client";
import Root from "./Routes";
import { initializeApp } from "ivipbase";

import { MainFrame } from "Components";
import ThemeContext from "Crucial/Theme";

import { ContextProvider } from "Context";
import { InteractionProvider } from "Providers";

import { InteractionHelper, UserHelper } from "Helper";

import "./index.css";

import "../node_modules/overlayscrollbars/styles/overlayscrollbars.css";
import ViewNFTCard from "Pages/NftCatalog/ViewNFTCard";

const app = initializeApp({
	isServer: false,
	protocol: "https",
	host: "api.ivipcoin.com",
	port: 4044,
	dbname: "ivipcoin:production",
});

window.isLocalhost = Boolean(
	window.location.hostname === "localhost" ||
		window.location.hostname === "[::1]" ||
		window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

if (window.isLocalhost !== true) {
	const savedConsole = console;

	console = {
		blockade: ["Bias changed. ", "Local time changed. "],
		...savedConsole,
	};

	console.isLockable = function () {
		return (
			console.blockade.findIndex(
				(s) =>
					Array.from(arguments)
						.filter((i) => typeof i === "string")
						.findIndex((t) => t.indexOf(s) >= 0) >= 0,
			) >= 0
		);
	};

	console.log = function () {
		if (console.isLockable.apply(console, arguments) !== true) {
			savedConsole.log.apply(null, arguments);
		}
	};

	console.warn = function () {
		if (console.isLockable.apply(console, arguments) !== true) {
			savedConsole.warn.apply(null, arguments);
		}
	};

	console.error = function () {
		if (console.isLockable.apply(console, arguments) !== true) {
			savedConsole.error.apply(null, arguments);
		}
	};

	console.info = function () {
		if (console.isLockable.apply(console, arguments) !== true) {
			savedConsole.info.apply(null, arguments);
		}
	};
}

if (window.isLocalhost !== true) {
	console.log = function () {
		return;
	};
	console.warn = function () {
		return;
	};
	console.error = function () {
		return;
	};
	console.table = function () {
		return;
	};
	console.info = function () {
		return;
	};
	console.debug = function () {
		return;
	};
	console.trace = function () {
		return;
	};
}

const delay = (time) => new Promise((resolve) => setTimeout(resolve, typeof time === "number" ? time : 2000));

InteractionHelper.default();

const loadingMessages = [
	"Conectando ao servidor...",
	"Carregando dados...",
	"Processando informações...",
	"Não deve demorar muito...",
	"Aguarde uns instantes...",
	"Por favor, aguarde...",
	"Estamos preparando tudo para você...",
	"Carregando recursos...",
	"Buscando atualizações...",
	"O aplicativo está sendo iniciado...",
	"O sistema está carregando...",
	"Se houver demora, pode ser disponibilizada uma nova versão em breve...",
	"Aguarde um momento...",
];

let time;

const initial = () => {
	app.ready(async () => {
		await UserHelper.getUser().catch(console.log);
		clearTimeout(time);

		const progressElement = document.querySelector("body > #progress");
		if (progressElement) {
			progressElement.remove();
		}

		document.getElementById("root").innerHTML = "";
		const root = createRoot(document.getElementById("root"));

		root.render(
			<React.StrictMode>
				<ContextProvider>
					<ThemeContext>
						<InteractionProvider>
							<MainFrame.Window>
								<Root />
							</MainFrame.Window>
						</InteractionProvider>
						<ViewNFTCard />
					</ThemeContext>
				</ContextProvider>
			</React.StrictMode>,
		);
	}).catch(() => {
		const progressElement = document.querySelector("body > #progress");
		if (progressElement) {
			progressElement.setAttribute("label", loadingMessages[Math.floor(Math.random() * loadingMessages.length)]);
		}
		time = setTimeout(initial, 5000);
	});
};

initial();
