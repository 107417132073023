import React from "react";
import { applyForwardRef, applyStyledProps, useCanvas, usePropsImperativeHandle } from "../Utils";
import { CANVASPathElement } from "../Elements";
import { Path2D } from "../Types";

const convertPathDataToPath2D = (pathData: string): Path2D => {
	const commands: Path2D = [];
	const commandRegex = /([a-zA-Z])([^a-zA-Z]*)/g;

	let match;
	while ((match = commandRegex.exec(pathData)) !== null) {
		const [, type, argsStr] = match;
		const args = argsStr
			.trim()
			.split(/[(\s+)(\s+\,\s+)]/gi)
			.map((arg) => parseFloat(arg));

		const command: {
			type: string;
			args: { [key: string]: number };
		} = { type, args: {} };

		switch (type.toUpperCase()) {
			case "M":
			case "L":
			case "T":
				if (args.length > 1) {
					for (let i = 0; i < args.length; i += 2) {
						commands.push({ type: type as "m" | "M" | "l" | "L" | "t" | "T", args: { x: args[i], y: args[i + 1] } });
					}
				}
				break;
			case "H":
				if (args.length >= 1) {
					for (let i = 0; i < args.length; i += 2) {
						commands.push({ type: type as "h" | "H", args: { x: args[i] } });
					}
				}
				break;
			case "V":
				if (args.length >= 1) {
					for (let i = 0; i < args.length; i += 2) {
						commands.push({ type: type as "v" | "V", args: { y: args[i] } });
					}
				}
				break;
			case "C":
				if (args.length >= 5) {
					commands.push({
						type: type as "c" | "C",
						args: {
							x1: args[0],
							y1: args[1],
							x2: args[2],
							y2: args[3],
							x: args[4],
							y: args[5],
						},
					});
				}
				break;
			case "S":
				if (args.length >= 3) {
					commands.push({
						type: type as "s" | "S",
						args: {
							x2: args[0],
							y2: args[1],
							x: args[2],
							y: args[3],
						},
					});
				}
				break;
			case "Q":
				if (args.length >= 3) {
					commands.push({
						type: type as "q" | "Q",
						args: {
							x1: args[0],
							y1: args[1],
							x: args[2],
							y: args[3],
						},
					});
				}
				break;
			case "A":
				if (args.length >= 6) {
					commands.push({
						type: type as "a" | "A",
						args: {
							x: args[5],
							y: args[6],
							angle: args[2],
							largeArcFlag: args[3],
							sweepFlag: args[4],
							rx: args[0],
							ry: args[1],
						},
					});
				}
				break;
		}
	}

	return commands;
};

function drawEllipticalArc(
	ctx: CanvasRenderingContext2D,
	x1: number,
	y1: number,
	x2: number,
	y2: number,
	rx: number,
	ry: number,
	angle: number,
	largeArcFlag: number,
	sweepFlag: number,
) {
	// Calcula a distância entre os pontos inicial e final
	const dx = x1 - x2;
	const dy = y1 - y2;
	const d = Math.sqrt(dx * dx + dy * dy);

	// Calcula os ângulos de início e término da elipse
	const theta1 = Math.atan2(y1 - y2, x1 - x2);
	const theta2 = theta1 + angle * (Math.PI / 180);

	// Calcula os raios efetivos
	const cosAngle = Math.cos(theta2);
	const sinAngle = Math.sin(theta2);
	const x1p = (cosAngle * dx) / 2 + (sinAngle * dy) / 2;
	const y1p = (-sinAngle * dx) / 2 + (cosAngle * dy) / 2;

	const rxsq = rx * rx;
	const rysq = ry * ry;
	const x1psq = x1p * x1p;
	const y1psq = y1p * y1p;

	// Calcula o ponto médio da elipse
	let factor = Math.sqrt((rxsq * rysq - rxsq * y1psq - rysq * x1psq) / (rxsq * y1psq + rysq * x1psq));
	if (largeArcFlag === sweepFlag) factor = -factor;

	const cxp = (factor * rx * y1p) / ry;
	const cyp = (-factor * ry * x1p) / rx;

	const cx = (x1 + x2) / 2 + cosAngle * cxp - sinAngle * cyp;
	const cy = (y1 + y2) / 2 + sinAngle * cxp + cosAngle * cyp;

	// Calcula os ângulos de início e término do arco
	const thetaArcStart = Math.atan2((y1p - cyp) / ry, (x1p - cxp) / rx);
	const thetaArcEnd = Math.atan2((-y1p - cyp) / ry, (-x1p - cxp) / rx);

	// Desenha o arco
	ctx.save();
	ctx.translate(cx, cy);
	ctx.rotate(theta2);
	const arcFlag = sweepFlag ? 0 : 1;
	ctx.scale(rx, ry);
	ctx.arc(0, 0, 1, thetaArcStart, thetaArcEnd, arcFlag === 1);
	ctx.restore();
}

export const Path = applyForwardRef<CANVASPathElement>((p, ref) => {
	const [props, setProps] = usePropsImperativeHandle<CANVASPathElement>(ref, p, {
		d: "",
	});

	useCanvas(
		({ canvas }) => {
			const ctx = canvas?.getContext("2d");
			const { d } = props;

			const path2D: Path2D = typeof d === "string" ? convertPathDataToPath2D(d) : d;

			if (ctx) {
				ctx.save();
				applyStyledProps(ctx, props, (ctx) => {
					ctx.beginPath();
					let backupPos = { x: 0, y: 0, x2: 0, y2: 0 };

					for (const { type, args = {} as any } of path2D) {
						let x = 0,
							y = 0,
							x1 = 0,
							y1 = 0,
							x2 = 0,
							y2 = 0;

						switch (type) {
							case "M":
								ctx.moveTo(args.x, args.y);
								backupPos = { x: args.x, y: args.y, x2: args.x, y2: args.y };
								break;
							case "m":
								x = backupPos.x + args.x;
								y = backupPos.y + args.y;
								ctx.moveTo(x, y);
								backupPos = { x, y, x2: x, y2: y };
								break;
							case "L":
								ctx.lineTo(args.x, args.y);
								backupPos = { x: args.x, y: args.y, x2: args.x, y2: args.y };
								break;
							case "l":
								x = backupPos.x + args.x;
								y = backupPos.y + args.y;
								ctx.lineTo(x, y);
								backupPos = { x, y, x2: x, y2: y };
								break;
							case "H":
								ctx.lineTo(args.x, backupPos.y);
								backupPos = { x: args.x, y: backupPos.y, x2: args.x, y2: backupPos.y };
								break;
							case "h":
								x = backupPos.x + args.x;
								ctx.lineTo(x, backupPos.y);
								backupPos = { x, y: backupPos.y, x2: x, y2: backupPos.y };
								break;
							case "V":
								ctx.lineTo(backupPos.x, args.y);
								backupPos = { x: backupPos.x, y: args.y, x2: backupPos.x, y2: args.y };
								break;
							case "v":
								y = backupPos.y + args.y;
								ctx.lineTo(backupPos.x, y);
								backupPos = { x: backupPos.x, y, x2: backupPos.x, y2: y };
								break;
							case "C":
								ctx.bezierCurveTo(args.x1, args.y1, args.x2, args.y2, args.x, args.y);
								backupPos = { x: args.x, y: args.y, x2: args.x2, y2: args.y2 };
								break;
							case "c":
								x = backupPos.x + args.x;
								y = backupPos.y + args.y;
								x1 = backupPos.x + args.x1;
								y1 = backupPos.y + args.y1;
								x2 = backupPos.x + args.x2;
								y2 = backupPos.y + args.y2;
								ctx.bezierCurveTo(x1, y1, x2, y2, x, y);
								backupPos = { x, y, x2, y2 };
								break;
							case "S":
								x = 2 * backupPos.x - backupPos.x2;
								y = 2 * backupPos.y - backupPos.y2;
								ctx.bezierCurveTo(x, y, args.x2, args.y2, args.x, args.y);
								backupPos = { x: args.x, y: args.y, x2: args.x2, y2: args.y2 };
								break;
							case "s":
								x = 2 * backupPos.x - backupPos.x2;
								y = 2 * backupPos.y - backupPos.y2;
								x1 = backupPos.x + args.x2;
								y1 = backupPos.y + args.y2;
								x2 = backupPos.x + args.x;
								y2 = backupPos.y + args.y;
								ctx.bezierCurveTo(x, y, x1, y1, x2, y2);
								backupPos = { x: x2, y: y2, x2: x1, y2: y1 };
								break;
							case "Q":
								ctx.quadraticCurveTo(args.x1, args.y1, args.x, args.y);
								backupPos = { x: args.x, y: args.y, x2: args.x1, y2: args.y1 };
								break;
							case "q":
								ctx.quadraticCurveTo(backupPos.x + args.x1, backupPos.y + args.y1, backupPos.x + args.x, backupPos.y + args.y);
								backupPos = {
									x: backupPos.x + args.x,
									y: backupPos.y + args.y,
									x2: backupPos.x + args.x1,
									y2: backupPos.y + args.y1,
								};
								break;
							case "T":
								x1 = 2 * backupPos.x - backupPos.x2;
								y1 = 2 * backupPos.y - backupPos.y2;
								ctx.quadraticCurveTo(x1, y1, args.x, args.y);
								backupPos = { x: args.x, y: args.y, x2: x1, y2: y1 };
								break;
							case "t":
								x1 = 2 * backupPos.x - backupPos.x2;
								y1 = 2 * backupPos.y - backupPos.y2;
								ctx.quadraticCurveTo(x1, y1, backupPos.x + args.x, backupPos.y + args.y);
								backupPos = { x: backupPos.x + args.x, y: backupPos.y + args.y, x2: x1, y2: y1 };
								break;
							case "A":
								drawEllipticalArc(
									ctx,
									backupPos.x,
									backupPos.y,
									args.x,
									args.y,
									args.rx,
									args.ry,
									args.angle,
									args.largeArcFlag,
									args.sweepFlag,
								);
								backupPos = { x: args.x, y: args.y, x2: args.x, y2: args.y };
								break;
							case "a":
								drawEllipticalArc(
									ctx,
									backupPos.x,
									backupPos.y,
									backupPos.x + args.x,
									backupPos.y + args.y,
									args.rx,
									args.ry,
									args.angle,
									args.largeArcFlag,
									args.sweepFlag,
								);
								backupPos = { x: backupPos.x + args.x, y: backupPos.y + args.y, x2: backupPos.x + args.x, y2: backupPos.y + args.y };
								break;
							case "Z":
							case "z":
								ctx.closePath();
								break;
						}
					}
					ctx.stroke();
					ctx.fill();
				});
				ctx.restore();
			}
		},
		[props],
	);
	return null;
});
