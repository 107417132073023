import React, { useRef, useState, useLayoutEffect, useEffect } from "react";
import { mdiSafe } from "@mdi/js";
import { InteractionHelper, APIHelper } from "Helper";
import { Grid, Typography } from "@mui/material";
import { SvgIcon } from "Components";
import { mdiChevronDoubleUp } from "@mdi/js";
import { useAnimateValue, calculateIncome, getTimeUntilEnd } from "Components/StakingCard/Body";
import { currencyFormat } from "Utils";

import background_img from "Resources/images/widget-staking-background.png";

import style from "./style.module.scss";
import { StakingInfo } from "Types/Staking";

const colors = ["#ffb3f6", "#7aa0ff", "#FFD100", "#FF9300", "#FF7FA4"];

const shapes = [
	"M 21,0,28.053423027509677,11.29179606750063,40.97218684219823,14.510643118126104,32.412678195541844,24.70820393249937,33.34349029814194,37.989356881873896,21,33,8.656509701858067,37.989356881873896,9.587321804458158,24.70820393249937,1.0278131578017735,14.510643118126108,13.94657697249032,11.291796067500632z",
	"m 20 1 a 1 1 0 0 0 0 25 a 1 1 0 0 0 0 -25",
	"M 18,0,22.242640687119284,13.757359312880714,36,18,22.242640687119284,22.242640687119284,18.000000000000004,36,13.757359312880716,22.242640687119284,0,18.000000000000004,13.757359312880714,13.757359312880716z",
	"M18,0,27.192388155425117,8.80761184457488,36,18,27.19238815542512,27.192388155425117,18.000000000000004,36,8.807611844574883,27.19238815542512,0,18.000000000000004,8.80761184457488,8.807611844574884",
];

const Particles = ({ length = 40 }) => {
	const ref_root = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		if (!ref_root || !ref_root.current) {
			return;
		}
		const times: NodeJS.Timer[] = [];
		const shapes = ref_root.current.querySelectorAll("svg");

		for (let shape of shapes) {
			const sec = Math.floor(Math.random() * 1700) + 1000;

			times.push(
				setInterval(() => {
					if (!ref_root || !ref_root.current) {
						return;
					}

					const rect_root = ref_root.current.getBoundingClientRect();

					const scale = Math.floor(Math.random() * (8 - 4 + 1)) + 4;
					const rotate = Math.floor(Math.random() * 360) + 360;

					shape.style.left = `${rect_root.width / 2 - 25}px`;
					shape.style.top = `${rect_root.height / 2 - 25}px`;
					shape.style.transform = `scale(0.${scale}) rotate(${rotate}deg)`;
					shape.style.transition = "0ms";
					shape.style.opacity = `1`;

					setTimeout(() => {
						const x = rect_root.width / 2 + (Math.floor(Math.random() * 600) - 300);
						const y = rect_root.height / 2 + (Math.floor(Math.random() * 400) - 200);

						shape.style.transition = sec + "ms";
						shape.style.left = `${x}px`;
						shape.style.top = `${y}px`;
						shape.style.transform = `scale(0) rotate(0deg)`;
						shape.style.opacity = `0`;
					}, 100);
				}, sec + 100),
			);
		}

		return () => {
			times.forEach((time) => clearInterval(time as any));
		};
	}, [ref_root, length]);

	return (
		<div
			ref={ref_root}
			className={style["particles-container"]}
		>
			{new Array(length).fill(null).map((n, i) => {
				const randBG = Math.floor(Math.random() * colors.length);
				const getShape = Math.floor(Math.random() * shapes.length);
				const color = colors[randBG];

				return (
					<SvgIcon
						key={i}
						path={shapes[getShape]}
						viewBox="0 0 50 50"
						sx={{ fill: color, opacity: 0 }}
					/>
				);
			})}
		</div>
	);
};

const StakingView = () => {
	const [loading, setLoading] = useState(true);
	const [in_a_group, set_in_a_group] = useState(false);
	const [requested, set_requested] = useState(false);
	const [stakingList, setStakingList] = useState([]);

	useEffect(() => {
		APIHelper.fetch("/staking", {}, 150)
			.then((list: any): Promise<boolean> => {
				list = list.filter(({ amount, typeAmount }: StakingInfo) => amount > 0 && typeAmount === "IVIP");
				setStakingList(() => list);

				return APIHelper.fetch("/chat/messages/ldchat34978caac479ad8a18/members/contains", {}, 5) as any;
			})
			.then((contains: boolean): Promise<boolean> => {
				set_in_a_group((p) => (typeof contains === "boolean" ? contains : p));
				return contains ? Promise.resolve(true) : (APIHelper.fetch("/chat/group/ldchat34978caac479ad8a18/member/requested", {}, 5) as any);
			})
			.then((requested: boolean) => {
				set_requested((p) => (typeof requested === "boolean" ? requested : p));
			})
			.catch(() => {
				InteractionHelper.toast("Algo deu errado ao pegar os dados na base de dados, tente novamente mais tarde!", null, "warning");
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const amount = stakingList.reduce((a, { amount }) => a + amount, 0);
	const current_last_income = stakingList.reduce((a, { lastIncome }) => a + lastIncome, 0);

	const handle = () => {
		if (in_a_group) {
			window.routerHistory.push("chat/ldchat34978caac479ad8a18");
		} else if (requested) {
			InteractionHelper.toast("Sua solicitação já foi enviada, aguarde até que seja aprovado a sua solicitação", null, "warning");
		} else if (amount < 5000000) {
			InteractionHelper.toast(
				"Sinto muito, mas o seu perfil de investimento não se enquadra no momento para fazer parte da sala VIP",
				null,
				"warning",
			);
		} else {
			InteractionHelper.loading();

			APIHelper.fetch("/chat/group/ldchat34978caac479ad8a18/member/request_access", {}, 5)
				.then((access) => {
					return access ? Promise.resolve() : Promise.reject();
				})
				.then(() => {
					window.routerHistory.push("chat/ldchat34978caac479ad8a18");
				})
				.catch((e) => {
					const errorMsg =
						e && typeof e.message === "string" && e.message.trim() !== ""
							? e.message
							: "Algo deu errado ao pegar os dados na base de dados, tente novamente mais tarde!";
					InteractionHelper.toast(errorMsg, null, "warning");
				})
				.finally(() => {
					InteractionHelper.close();
				});
		}
	};

	const amount_format = currencyFormat.convert(amount);

	const lastIncome_format = currencyFormat.convert(current_last_income);

	const current_income = amount !== 0 ? parseFloat((current_last_income / amount).toFixed(4)) : 0;
	const current_income_format = currencyFormat.convert(parseFloat((current_income * 100).toFixed(4)));

	const in_income = current_income > 0 || parseFloat(current_last_income.toFixed(3)) > 0;

	return (
		<div className={style["root"]}>
			<div
				className={style["access-container"]}
				style={{ backgroundImage: `url(${background_img})` }}
			>
				{/* {!loading && <Particles />} */}
				{!loading && (
					<button
						className={style["btn"]}
						data-sala="SALA VIP"
						onClick={handle}
					></button>
				)}
				{!loading && !in_a_group && !requested && amount < 5000000 && (
					<div className={style["warning"]}>
						Conquiste acesso à Sala VIP! Tenha 5 milhões de tokens em staking e desfrute de privilégios exclusivos. Sua determinação e
						investimentos o levarão ao próximo nível.
					</div>
				)}
			</div>
			<div className={[style["resume-container"], stakingList.length <= 0 && loading ? style["loading"] : ""].join(" ")}>
				<div className={style["banner"]}>
					<span>Calculando...</span>
				</div>
				<div className={style["resume"]}>
					<div>
						<Typography
							variant="body2"
							component="p"
							gutterBottom
						>
							Quantia aplicada (IVIP)
						</Typography>
						<Typography
							variant="h4"
							component="p"
						>
							{amount_format}
						</Typography>
					</div>
					<div>
						<Typography
							variant="body2"
							component="p"
							gutterBottom
						>
							Rendimento (%)
						</Typography>
						<Typography
							variant="h4"
							component="p"
							sx={{
								color: current_income > 0 ? "var(--success-main)" : "",
							}}
						>
							{in_income && <SvgIcon path={mdiChevronDoubleUp} />}
							{current_income_format}
							<Typography
								variant="h6"
								component="span"
							>
								%
							</Typography>
						</Typography>
					</div>
					<div>
						<Typography
							variant="body2"
							component="p"
							gutterBottom
						>
							Rendimento (IVIP)
						</Typography>
						<Typography
							variant="h4"
							component="p"
						>
							{in_income && (
								<SvgIcon
									path={mdiChevronDoubleUp}
									style={{ color: "var(--success-main)" }}
								/>
							)}
							{lastIncome_format}
						</Typography>
					</div>
				</div>
			</div>
		</div>
	);
};

export default {
	id: "StakingView",
	title: "Staking",
	icon: mdiSafe,
	render: () => {
		return <StakingView />;
	},
};
