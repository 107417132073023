import WindowComponent from "./WindowComponent";
import ToolbarComponent from "./ToolbarComponent";
import MenubarComponent from "./MenubarComponent";
import Controller from "./Controller";

export default class MainFrame {
  static Window = WindowComponent;
  static Toolbar = ToolbarComponent;
  static Menubar = MenubarComponent;
  static Controller = Controller;
}
