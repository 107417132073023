import React, { useState, useRef, useLayoutEffect, useCallback } from "react";
import { Messages } from "Crucial";

import { Box, Typography, Divider } from "@mui/material";

import {
	mdiCog,
	mdiBell,
	mdiEarth,
	mdiAlertOctagon,
	mdiBellBadge,
	mdiForum,
	mdiNewspaper,
	mdiCurrencyBtc,
	mdiCheckDecagram,
	mdiInformationOutline,
	mdiAccountCircle,
	mdiShieldLock,
	mdiPalette,
	mdiCash,
	mdiSeal,
	mdiAccountMultiplePlus,
	mdiSend,
	mdiAccountHardHat,
	mdiApi,
	mdiShieldKey,
	mdiHandshake,
} from "@mdi/js";

import { SvgIcon } from "Components";

import indicadores from "./indicadores.json";
import flagsIcon from "./flagsIcon.json";
import currencyIcon from "./currencyIcon.json";

import style from "./index.module.scss";

import "./style.css";

import List, { ListItem } from "./ListComponent";
import SettingsSecurity from "./SettingsSecurity";
import SettingsSecurityAccess from "./SettingsSecurityAccess";
import Bep20Trust from "./Bep20Trust";

interface SettingItem {
	id: string;
	icon: string;
	title: string;
	component: React.FC<{}>;
}

const Settings: React.FC<{}> = () => {
	const contentDiv = useRef<HTMLDivElement>(null);
	const [activeItemMenu, setActiveItemMenu] = useState<number[]>([]);
	const [settings, setSettings] = useState<SettingItem[]>([]);

	useLayoutEffect(() => {
		const settings: SettingItem[] = [];

		settings.push({
			id: "SETTINGS-ACCOUNT",
			icon: mdiAccountCircle,
			title: Messages.getMsg("SETTINGS-ACCOUNT"),
			component: () => {
				const listItens: ListItem[] = [];

				listItens.push({
					id: "language.selectLanguage",
					type: "select",
					defaultValue: Messages.lang,
					icon: mdiEarth,
					label: Messages.getMsg("SETTINGS-ACCOUNT-ITEM-SELECT-LANGUAGE"),
					itens: Messages.langList.map(({ code, name, nativeName }) => ({
						value: code,
						label: `${nativeName}`,
						img: (flagsIcon as any)[code],
					})),
					disabled: false,
				});

				listItens.push({
					id: "account.currencyType",
					type: "select",
					defaultValue: "USD",
					icon: mdiCash,
					label: Messages.getMsg("SETTINGS-ACCOUNT-ITEM-CURRENCY-TYPE"),
					itens: [
						{ value: "USD", label: Messages.getMsg("SETTINGS-ACCOUNT-ITEM-CURRENCY-TYPE-USD") },
						{ value: "EUR", label: Messages.getMsg("SETTINGS-ACCOUNT-ITEM-CURRENCY-TYPE-EUR") },
						{ value: "BRL", label: Messages.getMsg("SETTINGS-ACCOUNT-ITEM-CURRENCY-TYPE-BRL") },
					].map(({ value, label }) => ({ value, label, img: (currencyIcon as any)[value] })),
					disabled: false,
				});

				return <List itens={listItens} />;
			},
		});

		settings.push({
			id: "SETTINGS-BEP20-TRUST",
			icon: mdiHandshake,
			title: Messages.getMsg("SETTINGS-BEP20-TRUST"),
			component: () => <Bep20Trust />,
		});

		// settings.push({
		// 	icon: mdiShieldLock,
		// 	title: Messages.getMsg("SETTINGS-SECURITY"),
		// 	component: () => <SettingsSecurity />,
		// });

		settings.push({
			id: "SETTINGS-SECURITY-ACCESS",
			icon: mdiShieldKey,
			title: Messages.getMsg("SETTINGS-SECURITY-ACCESS"),
			component: () => <SettingsSecurityAccess />,
		});

		settings.push({
			id: "SETTINGS-NOTIFICATION",
			icon: mdiBell,
			title: Messages.getMsg("SETTINGS-NOTIFICATION"),
			component: () => {
				const listItens: ListItem[] = [];

				listItens.push({
					id: "notification.securityAlert",
					type: "switch",
					defaultValue: true,
					icon: mdiAlertOctagon,
					label: Messages.getMsg("SETTINGS-NOTIFICATION-ITEM-SECURITY-ALERT"),
					disabled: true,
				});

				listItens.push({
					id: "notification.friendshipInvitation",
					type: "switch",
					defaultValue: true,
					icon: mdiAccountMultiplePlus,
					label: Messages.getMsg("SETTINGS-NOTIFICATION-ITEM-FRIENDSHIP-INVITATION"),
					disabled: true,
				});

				listItens.push({
					id: "notification.chatMessage",
					type: "switch",
					defaultValue: true,
					icon: mdiForum,
					label: Messages.getMsg("SETTINGS-NOTIFICATION-ITEM-CHAT-MESSAGE"),
					disabled: true,
				});

				listItens.push({
					id: "notification.pvpInvite",
					type: "switch",
					defaultValue: true,
					icon: mdiSend,
					label: Messages.getMsg("SETTINGS-NOTIFICATION-ITEM-PVP-INVITE"),
					disabled: true,
				});

				listItens.push({
					id: "notification.achievementAchieved",
					type: "switch",
					defaultValue: true,
					icon: mdiSeal,
					label: Messages.getMsg("SETTINGS-NOTIFICATION-ITEM-ACHIEVEMENT-ACHIEVED"),
					disabled: true,
				});

				listItens.push({
					id: "notification.traderAlert",
					type: "switch",
					defaultValue: true,
					icon: mdiBellBadge,
					label: Messages.getMsg("SETTINGS-NOTIFICATION-ITEM-TRADER-ALERT"),
					disabled: true,
				});

				listItens.push({
					id: "notification.topicMoment",
					type: "switch",
					defaultValue: true,
					icon: mdiNewspaper,
					label: Messages.getMsg("SETTINGS-NOTIFICATION-ITEM-TOPIC-MOMENT"),
					disabled: true,
				});

				listItens.push({
					id: "notification.currencyIndex",
					type: "multiple-select",
					multiple: true,
					defaultValue: [
						"bitcoin",
						"ethereum",
						"tether",
						"usd-coin",
						"binance-coin",
						"cardano",
						"binance-usd",
						"xrp",
						"solana",
						"dogecoin",
						"polkadot",
						"shiba-inu",
						"avalanche",
						"polygon",
						"multi-collateral-dai",
						"terra-luna",
						"uniswap",
						"tron",
						"wrapped-bitcoin",
						"ethereum-classic",
					],
					icon: mdiCurrencyBtc,
					label: Messages.getMsg("SETTINGS-NOTIFICATION-ITEM-CURRENCY-INDEX"),
					renderValue: (selected) => {
						if (selected.length === 0) {
							return Messages.getMsg("SETTINGS-NOTIFICATION-ITEM-CURRENCY-INDEX-SELECT-SYSTEM");
						}
						return selected.map(({ label }) => label.split(" ").shift()).join(", ");
					},
					itens: indicadores,
					disabled: false,
				});

				return <List itens={listItens} />;
			},
		});

		settings.push({
			id: "SETTINGS-APPEARANCE",
			icon: mdiPalette,
			title: Messages.getMsg("SETTINGS-APPEARANCE"),
			component: () => {
				const listItens: ListItem[] = [];

				return <List itens={listItens} />;
			},
		});

		if (window.isLocalhost) {
			settings.push({
				id: "SETTINGS-DEVELOPMENT",
				icon: mdiAccountHardHat,
				title: Messages.getMsg("SETTINGS-DEVELOPMENT"),
				component: () => {
					const listItens: ListItem[] = [];

					listItens.push({
						id: "development.localApi",
						type: "switch",
						defaultValue: false,
						icon: mdiApi,
						label: Messages.getMsg("SETTINGS-DEVELOPMENT-ITEM-LOCAL-API"),
					});

					return <List itens={listItens} />;
				},
			});
		}

		settings.push({
			id: "SETTINGS-ABOUT-SYSTEM",
			icon: mdiInformationOutline,
			title: Messages.getMsg("SETTINGS-ABOUT-SYSTEM"),
			component: () => {
				const listItens: ListItem[] = [];

				listItens.push({
					type: "info",
					icon: mdiCheckDecagram,
					label: Messages.getMsg("SETTINGS-ABOUT-SYSTEM-ITEM-VERSION"),
					value: window.package?.version || "0.0.0",
				});

				return <List itens={listItens} />;
			},
		});

		setSettings(() => settings);
	}, []);

	useLayoutEffect(() => {
		//console.log(Messages.langList);
		//console.log(Messages.lang);

		let delay: number | undefined;
		if (!contentDiv.current) {
			return;
		}

		const scroll = () => {
			window.clearTimeout(delay);

			delay = window.setTimeout(() => {
				const element = contentDiv.current?.querySelectorAll("div.MuiDivider-root, div.Settings-List");
				if (!contentDiv.current || !element) {
					return;
				}
				const elementsInView: number[] = [];

				for (let i = 0; i < element.length - 1; i += 2) {
					const position_start = element[i].getBoundingClientRect();
					const position_end = element[i + 1].getBoundingClientRect();

					// checking whether fully visible
					if (position_start.top >= 0 && position_end.bottom <= contentDiv.current.offsetHeight) {
						//elementsInView.push(Math.floor((i+1)/2));
					}

					// checking for partial visibility
					if (position_start.top < contentDiv.current.offsetHeight && position_end.bottom - 40 >= 0) {
						elementsInView.push(Math.floor((i + 1) / 2));
					}
				}
				setActiveItemMenu(() => elementsInView);
			}, 50);
		};

		contentDiv.current.addEventListener("scroll", scroll);
		window.addEventListener("resize", scroll);
		scroll();

		return () => {
			window.clearTimeout(delay);
			window.removeEventListener("resize", scroll);
			if (!contentDiv.current) {
				return;
			}
			contentDiv.current.removeEventListener("scroll", scroll);
		};
	}, [contentDiv]);

	const toItemScroll = useCallback(
		(i: number) => {
			if (activeItemMenu.includes(i) || !contentDiv.current) {
				return;
			}
			const element = contentDiv.current.querySelectorAll("div.MuiDivider-root")[i];
			contentDiv.current.scrollTop = (element as any).offsetTop;
		},
		[contentDiv, activeItemMenu],
	);

	return (
		<div className="Settings-Container">
			<div>
				{[{ icon: mdiCog, title: Messages.getMsg("SETTINGS") }].concat(settings).map(({ icon, title }, i) => {
					return (
						<div
							className={i === 0 ? "title" : activeItemMenu.includes(i - 1) ? "active" : ""}
							key={`Settings-intem-${i}`}
							onClick={() => toItemScroll(i - 1)}
						>
							<SvgIcon path={icon} />
							<Typography
								variant="subtitle1"
								gutterBottom
								component="div"
							>
								{title}
							</Typography>
						</div>
					);
				})}
			</div>
			<div ref={contentDiv}>
				<Box>
					{settings.map(({ id, icon, title, component: Component }, i) => {
						return [
							<Divider
								id={id}
								key={`Settings-Container-Divider-${i}`}
							>
								{title}
							</Divider>,
							<Component key={`Settings-Container-Component-${i}`} />,
						];
					})}
				</Box>
			</div>
		</div>
	);
};

export default Settings;
