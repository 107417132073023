import React, { useState, useLayoutEffect, useCallback, useRef, useEffect } from "react";
import { useResizeObserver, useDebouncedCallback } from "@react-hookz/web";
import { useId } from "Utils/useHook";

import {
	getPathRectPanel,
	getPathMarkerBoxplot,
	getAreaSlice,
	getPathAverage,
	getBasicSettingGridlines,
	keysModel,
	SVG_clear,
	SVG_appendTo,
	themes,
} from "./utils";

import useLoadData from "./useLoadData";
import TimeLine from "./TimeLine";

const TraderChart = ({ data: _data, theme, radius, timelineAnchor, width, height, numberOfPricePoints, ...props }) => {
	const id = useId();
	const data = useLoadData(_data, { numberOfPricePoints });

	const [boundingClientRect, setBoundingClientRect] = useState({ width: 0, height: 0, top: 0, left: 0, right: 0, bottom: 0, x: 0, y: 0 });

	const [timeline_pos, set_timeline_pos] = useState(0);

	const [propsTheme, setPropsTheme] = useState(themes["light"]);

	const divMain = useRef(null);
	const svg_area_ref = useRef(null);
	const svg_gridlines_area_ref = useRef(null);
	const svg_currency_label_ref = useRef(null);

	const [timeline_options, set_timeline_options] = useState({
		margin_top: 20,
		margin_left: 20,
		margin_right: 20,
		margin_bottom: 2,
		width_signal: 20,
		height: 40,
	});

	useState(() => {
		setPropsTheme((prev) => {
			return Object.keys(themes).includes(String(theme).toLowerCase()) ? { ...themes[String(theme).toLowerCase()] } : prev;
		});
	}, [theme]);

	const getBasicSetting = () => {
		const { width, height } = boundingClientRect;
		const { margin_top, margin_right, margin_bottom: margin_bottom_drag, width_signal, height: height_drag } = timeline_options;

		let width_area = width - margin_right;
		let height_area = height - (height_drag + margin_bottom_drag + margin_top + 10);

		const { start, end } = getAreaSlice(data, { width, width_signal, timeline_pos });

		const d_area = data.data.slice(start, end + 1);

		const maxValue =
			Math.max.apply(
				null,
				d_area.map((obj) => Math.max(obj.high, obj.average)),
			) * 1.03;
		const minValue =
			Math.min.apply(
				null,
				d_area.map((obj) => Math.min(obj.low, obj.average)),
			) * 0.9;

		let maxVolume = Math.max.apply(
			null,
			d_area.map((obj) => obj.volume),
		);
		let minVolume = Math.min.apply(
			null,
			d_area.map((obj) => obj.volume),
		);
		maxVolume = maxVolume + maxVolume * 0.1;
		minVolume = Math.max(0, minVolume - minVolume * 0.1);

		let deficit_width = 0;

		let dateEnd = new Date(),
			dateStart = new Date(dateEnd.getFullYear(), dateEnd.getMonth() - 4, 1);

		if (data.isDataValid) {
			dateEnd = new Date(data.dateEnd);
			dateStart = new Date(data.dateStart);
			dateEnd = new Date(dateEnd.getFullYear(), dateEnd.getMonth(), dateEnd.getDate() - 7);
		}

		return {
			...timeline_options,
			margin_top,
			margin_right,
			margin_bottom_drag,
			width,
			height,
			height_drag,
			width_area,
			height_area,
			width_signal,
			start,
			end,
			d_area,
			maxValue,
			minValue,
			maxVolume,
			minVolume,
			deficit_width,
			dateEnd,
			dateStart,
		};
	};

	useEffect(() => {
		try {
			if (!svg_currency_label_ref.current) {
				return;
			}

			const { labels } = getBasicSettingGridlines(data, getBasicSetting());

			const { timeline_color } = propsTheme;
			//SVG_clear(svg_currency_label_ref.current);

			let text_list = svg_currency_label_ref.current.querySelectorAll("text");

			if (text_list.length > labels.length) {
				for (let i = labels.length - 1; i < text_list.length; i++) {
					if (text_list[i]?.parentNode) {
						text_list[i].parentNode.removeChild(text_list[i]);
					}
				}
				text_list = svg_currency_label_ref.current.querySelectorAll("text");
			}

			let width = 0;

			labels.forEach((l, i) => {
				let el;

				if (i >= text_list.length) {
					el = SVG_appendTo(
						svg_currency_label_ref.current,
						"text",
						{
							"x": 0,
							"y": l.y,
							"font-family": "Arial, Helvetica, sans-serif",
							"font-size": "12px",
							"fill": timeline_color,
							"dominant-baseline": "central",
						},
						l.label,
					);
				} else {
					text_list[i].setAttribute("y", l.y);
					text_list[i].textContent = l.label;
					el = text_list[i];
				}

				width = Math.max(width, el.getBBox().width);
			});

			let margin_right = width + 15;

			set_timeline_options((pre) => ({ ...pre, margin_right }));

			const { width: width_main } = boundingClientRect;

			svg_currency_label_ref.current.setAttribute("transform", `translate(${width_main - (margin_right - 15 / 2)}, 0)`);

			if (!svg_area_ref.current) {
				return;
			}

			let path = ["", "", "", ""];

			const basicSetting = getBasicSetting();
			const margin_top = basicSetting.margin_top;
			const { start, end } = basicSetting;

			for (let i = start; i <= end; i++) {
				let p = getPathMarkerBoxplot(data, i, basicSetting);
				path[data.data[i]["close"] < data.data[i]["open"] ? 0 : 1] += p[0];
				path[data.data[i]["close"] < data.data[i]["open"] ? 2 : 3] += p[1];
			}

			svg_area_ref.current.querySelector("path.boxplot_area_path_average").setAttribute("d", getPathAverage(data, basicSetting));

			svg_area_ref.current.querySelector("path.boxplot_area_path_volume_low").setAttribute("d", path[2]);
			svg_area_ref.current.querySelector("path.boxplot_area_path_volume_high").setAttribute("d", path[3]);

			svg_area_ref.current.querySelector("path.boxplot_area_path_low").setAttribute("d", path[0]);
			svg_area_ref.current.querySelector("path.boxplot_area_path_high").setAttribute("d", path[1]);

			svg_area_ref.current.setAttribute("transform", `translate(${timeline_pos}, ${margin_top})`);

			const { path: gridlines_path } = getBasicSettingGridlines(data, basicSetting, { ...timeline_options, margin_right });

			svg_gridlines_area_ref.current?.querySelector("path.boxplot_gridlines_area_path")?.setAttribute("d", gridlines_path);

			const { width_area, height_area } = basicSetting;

			document
				.querySelector("#RectAreaClip_" + id + " > path")
				.setAttribute(
					"d",
					`M0 ${margin_top} L${width_area} ${margin_top} L${width_area} ${height_area + margin_top} L0 ${height_area + margin_top} Z`,
				);
		} catch (e) {
			console.error(e);
		}
	}, [data, boundingClientRect, timeline_pos, propsTheme]);

	const updateSize = useDebouncedCallback(
		() => {
			if (!divMain.current) {
				return;
			}
			const rect = divMain.current.getBoundingClientRect();
			setBoundingClientRect(() => rect);
		},
		[],
		200,
	);

	useResizeObserver(divMain, updateSize);

	const basicSetting = getBasicSetting();

	const { width_area, height_area, margin_top } = basicSetting;

	const { timeline_color, background } = propsTheme;

	const { path: gridlinesAreaPath } = getBasicSettingGridlines(data, basicSetting);

	const style_width = !width ? "100%" : width;
	const style_height = !height ? 500 : height;

	return (
		<div
			{...props}
			ref={divMain}
			style={{
				width: style_width,
				minWidth: style_width,
				maxWidth: style_width,
				height: style_height,
				minHeight: style_height,
				maxHeight: style_height,
				overflow: "hidden",
				userSelect: "none",
			}}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={style_width}
				height={style_height}
			>
				<defs>
					<clipPath id={"RectPanelClip_" + id}>
						<path d={getPathRectPanel(Object.assign(boundingClientRect, { radius }))} />
					</clipPath>
					<clipPath id={"RectAreaClip_" + id}>
						<path
							d={`M0 ${margin_top} L${width_area} ${margin_top} L${width_area} ${height_area + margin_top} L0 ${
								height_area + margin_top
							} Z`}
						/>
					</clipPath>
				</defs>

				<g clipPath={`url(#${"RectPanelClip_" + id})`}>
					<rect
						x="0"
						y="0"
						width={boundingClientRect.width}
						height={boundingClientRect.height}
						fill={background}
						stroke="none"
					/>

					<g clipPath={`url(#${"RectAreaClip_" + id})`}>
						<g
							ref={svg_gridlines_area_ref}
							className="boxplot_gridlines_area"
						>
							<path
								className="boxplot_gridlines_area_path"
								d={gridlinesAreaPath}
								stroke={timeline_color}
								strokeDasharray="0"
								strokeLinecap="butt"
								strokeWidth="1"
								strokeOpacity="0.1"
							/>
						</g>
						<g
							ref={svg_area_ref}
							className="boxplot_area"
							transform={`translate(${timeline_pos}, ${margin_top})`}
						>
							<path
								className={"boxplot_area_path_volume_low"}
								d=""
								fill={"#E91E63"}
								fillOpacity="0.2"
								stroke={"none"}
							/>

							<path
								className={"boxplot_area_path_volume_high"}
								d=""
								fill={"#2196F3"}
								fillOpacity="0.2"
								stroke={"none"}
							/>

							<path
								className={"boxplot_area_path_average"}
								d=""
								fill={"none"}
								stroke={"#FF8900"}
								strokeOpacity="1"
								strokeWidth="2"
								strokeLinejoin="round"
								strokeLinecap="round"
							/>

							<path
								className={"boxplot_area_path_low"}
								d=""
								fill={"#c0392b"}
								fillOpacity="1"
								stroke={"#c0392b"}
								strokeOpacity="1"
								strokeLinecap="butt"
								strokeWidth="2"
								strokeDasharray="0"
							/>

							<path
								className={"boxplot_area_path_high"}
								d=""
								fill={"#03a678"}
								fillOpacity="1"
								stroke={"#03a678"}
								strokeOpacity="1"
								strokeLinecap="butt"
								strokeWidth="2"
								strokeDasharray="0"
							/>
						</g>
					</g>
					<g
						ref={svg_currency_label_ref}
						className="boxplot_currency_label"
					></g>
					<TimeLine
						timeline_anchor={timelineAnchor}
						timeline_color={timeline_color}
						{...basicSetting}
						dateList={data.data.map(({ date }) => date)}
						onScroll={(pos) => set_timeline_pos(pos)}
					/>
				</g>
			</svg>
		</div>
	);
};

export default TraderChart;
