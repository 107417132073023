import { mdiChartWaterfall } from '@mdi/js';
import MainTrader from './MainTrader';

export default {
    id: "TraderView",
    title: "Trader",
    icon: mdiChartWaterfall,
    render: ()=>{
        return <MainTrader />
    }
}