import React, { Component, useState, useRef, useCallback, useEffect, useLayoutEffect, forwardRef, useImperativeHandle } from "react";

import { createSvgIcon } from "@mui/material/utils";
import { Paper, InputBase, IconButton, Divider, Stack, Chip, CircularProgress } from "@mui/material";

import PhoneInput from "react-phone-input-2";

import { mdiSend, mdiPlus, mdiChevronLeft, mdiPencil } from "@mdi/js";

import SearchUser from "./SearchUser";
import ConversationList from "./ConversationList";
import ChatMessages from "./ChatMessages";
import NewChat from "./NewChat";

import { uniqueid, MultiStorager } from "Utils";

import { useMessages, useBot } from "./useMessages";
import textareaAutosize, { AutosizeFunctionProps } from "Utils/textareaAutosize";

import "./react-tel-input.scss";
import style from "./index.module.scss";
import { Usuario } from "Models";
import { UserHelper, APIHelper, InteractionHelper, ChatHelper } from "Helper";

import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumber, PhoneNumberType } from "google-libphonenumber";

import { MainFrame, SvgIcon } from "Components";

import backgroundChat01 from "Resources/images/background-chat-01.svg";

const phoneUtil = PhoneNumberUtil.getInstance();

const getAllCountryPhoneFormats = (): { [key: string]: string } => {
	const allFormats: { [key: string]: string } = {};
	const countryCodes = phoneUtil.getSupportedRegions();
	countryCodes.forEach((countryCode) => {
		try {
			const exampleNumber: PhoneNumber = phoneUtil.getExampleNumberForType(countryCode, PhoneNumberType.MOBILE);
			const nationalFormat: string = phoneUtil.format(exampleNumber, PhoneNumberFormat.NATIONAL);
			allFormats[countryCode.toLowerCase()] = nationalFormat.replace(/\d/gi, ".");
		} catch {}
	});
	return allFormats;
};

const tel_masks = getAllCountryPhoneFormats();

const SendIcon = createSvgIcon(<path d={mdiSend} />, "Send");

type InputAutosizeRef = HTMLInputElement & {
	getValue?(): string;
	setValue?(value: string): void;
};

interface InputAutosizeProps {
	value: string;
	type: "text" | "option" | "email" | "password" | "tel";
	disabled: boolean;
	onChange(value: string): void;
	onKeyPress(...args: any[]): void;
}

const InputAutosize = forwardRef<InputAutosizeRef, Partial<InputAutosizeProps>>(
	({ value, type = "", disabled, onChange, onKeyPress: onkeypress }, ref) => {
		const [inputValue, setInputValue] = useState("");
		const inputRef = useRef<InputAutosizeRef>();
		const inputAutosizeRef = useRef<AutosizeFunctionProps>(textareaAutosize());
		const inputFocusRef = useRef<any>();

		const inputFocus = useCallback(() => {
			clearInterval(inputFocusRef.current);
			if (disabled) {
				return;
			}

			const initial = () => {
				if (!inputRef.current) {
					return;
				}
				clearInterval(inputFocusRef.current);
				inputRef.current.focus();
				inputAutosizeRef.current?.update();
			};

			inputFocusRef.current = setInterval(initial, 1000);
			initial();
		}, [inputRef.current, disabled]);

		useLayoutEffect(() => {
			if (inputRef.current) {
				inputAutosizeRef.current?.update();
			}
			inputFocus();
		}, [inputRef.current, disabled]);

		useEffect(() => {
			if (typeof value === "string" && value !== inputValue) {
				setInputValue(value);
			}
		}, [value]);

		useEffect(() => {
			if (inputRef.current) {
				inputRef.current.value = inputValue;
				inputAutosizeRef.current?.update();
			}

			if (typeof onChange === "function") {
				onChange(inputValue);
			}
		}, [inputValue]);

		useImperativeHandle(
			ref,
			(): InputAutosizeRef => {
				const current = inputRef.current as InputAutosizeRef;

				current.getValue = () => {
					return typeof value === "string" ? current.value : typeof inputValue === "string" ? inputValue : "";
				};

				current.setValue = (value) => {
					setInputValue((prev) => (typeof value === "string" ? value : prev));
				};

				// current.focus = ()=>{
				//     inputFocus();
				// }

				return current;
			},
			[inputRef.current, inputValue],
		);

		useLayoutEffect(() => {
			if (!inputRef || !inputRef.current) {
				return;
			}
			inputAutosizeRef.current.by(inputRef.current);
			const time = setTimeout(() => {
				inputAutosizeRef.current?.update();
			}, 500);

			return () => {
				inputAutosizeRef.current?.destroy();
				clearTimeout(time);
			};
		}, [inputRef.current]);

		return (
			<InputBase
				type={type}
				sx={{ m: "0px", p: "10px" }}
				inputProps={{
					style: {
						margin: "0px",
						padding: "0px",
						minHeight: "24px",
					},
					autoCapitalize: ["email"].includes(type) ? "off" : "on",
				}}
				onChange={({ target }: any) => {
					inputAutosizeRef.current.by(target);
					setInputValue(target.value);
				}}
				disabled={disabled}
				fullWidth
				inputComponent={["password"].includes(type) ? "input" : "textarea"}
				placeholder="Escreva aqui..."
				onKeyPress={onkeypress}
				inputRef={(target: any) => {
					if (target) {
						inputAutosizeRef.current.by(target);
						inputRef.current = target;
					}
				}}
			/>
		);
	},
);

const onSelectRoom = ({
	chatId,
	name,
	type,
	picture,
	last_message,
	preview_message,
	count_message,
	chat_started,
}: Partial<{
	chatId: string;
	name: string;
	type: "private" | "group";
	picture: null | undefined | string;
	last_message: number | Date;
	preview_message: number | Date;
	count_message: number;
	chat_started: any;
}>) => {
	let chatRooms = MultiStorager.DataStorager.get("chatRooms");
	chatRooms = Array.isArray(chatRooms) ? chatRooms : [];

	const index = chatRooms.findIndex(({ chatId: _chatId }: { chatId: string }) => _chatId === chatId);

	if (index >= 0) {
		chatRooms[index] = Object.assign(
			chatRooms[index],
			Object.fromEntries(
				Object.entries({
					chatId,
					name,
					type,
					picture,
					last_message,
					preview_message,
					count_message,
					chat_started,
				}).filter(([k, valor]) => valor !== null && valor !== undefined && !isNaN(valor)),
			),
		);
	} else {
		chatRooms.push({
			name,
			chatId,
			type,
			picture,
			last_message,
			preview_message,
			count_message,
			chat_started,
		});
	}

	MultiStorager.DataStorager.set("chatRooms", chatRooms);
};

const Chat: React.FC<
	Partial<{
		suggestionChange(value: string, messages: Array<any>): void;
		disabled: boolean;
		onStart(): void;
		bot: ChatHelper;
		idRoom: string;
		disableConversationList: boolean;
		changeRoomId(...args: any[]): void;
	}>
> = forwardRef(
	(
		{
			suggestionChange,
			disabled: _disabled,
			onStart,
			bot: _bot,
			idRoom: _idRoom,
			disableConversationList: _disableConversationList,
			changeRoomId: _changeRoomId,
		},
		ref,
	) => {
		const [typing, setTyping] = useState<boolean>(false);

		const [loading, setLoading] = useState<boolean>(true);

		const [idRoom, setIdRoom] = useState<String | null>(null);

		const [user, setUser] = useState<Usuario | null>(null);

		const { messages, reset: resetMessages, prepareMessage }: { [key: string]: any } = useMessages();

		const {
			botId,
			containsBoot,
			update: updateBot,
			inputType: type,
			typing: botTyping,
			inputDisabled: botInputDisabled,
			containsOnChange: botContainsOnChange,
		}: { [key: string]: any } = useBot();

		const [disabled, setDisabled] = useState<boolean>(Boolean(_disabled));
		const [inputLoading, setInputLoading] = useState<boolean>(false);
		const [disabledInput, setDisabledInput] = useState<boolean>(false);

		const [disableConversationList, setDisableConversationList] = useState<boolean>(true);

		const inputRef = useRef<InputAutosizeRef | null>(null);

		const [showSearchUser, setShowSearchUser] = useState<boolean>(false);

		const [chatRooms, setChatRooms] = useState<Array<any>>([]);

		const conversationListScrollPosition = useRef<number>(0);

		useLayoutEffect(() => {
			let time: any;

			const event = MultiStorager.DataStorager.addListener<Array<any>>("chatRooms", (list) => {
				clearTimeout(time);
				time = setTimeout(() => {
					setChatRooms((prev: Array<any>) => {
						return Array.isArray(list) ? list : prev;
					});
				}, 500);
			});

			return () => {
				clearTimeout(time);
				event.stop();
			};
		}, []);

		useEffect(() => {
			if (typeof idRoom === "string" && idRoom.trim() !== "") {
				MainFrame.Controller.hiddenMenuSummary();
			} else {
				MainFrame.Controller.showMenuSummary();
			}

			return () => {
				MainFrame.Controller.showMenuSummary();
			};
		}, [idRoom]);

		useEffect(() => {
			if (!containsBoot || !botId || loading) {
				return;
			}
			onSelectRoom({
				name: "Boot",
				chatId: botId,
				type: "private",
				picture: null,
				last_message: Date.now(),
				chat_started: true,
			});
		}, [botId, containsBoot, loading, user, disableConversationList]);

		useEffect(() => {
			UserHelper.getUser()
				.then((user) => {
					if (user instanceof Usuario) {
						setUser(user);
						return;
					}

					setUser(null);
				})
				.catch(() => {
					setUser(null);
				})
				.finally(() => {
					setLoading(false);
				});
		}, []);

		useEffect(() => {
			setIdRoom(typeof _idRoom === "string" ? _idRoom : null);
		}, [_idRoom]);

		useEffect(() => {
			setDisabled((b) => (typeof _disabled === "boolean" ? _disabled : b));
		}, [_disabled]);

		useEffect(() => {
			updateBot(_bot);
		}, [_bot]);

		useEffect(() => {
			setDisableConversationList((v) => (typeof _disableConversationList === "boolean" ? _disableConversationList : v));
		}, [_disableConversationList]);

		useEffect(() => {
			if (!inputRef.current) {
				return;
			}

			if (!disabledInput) {
				setTimeout(() => {
					inputRef.current?.focus();
					inputRef.current?.click();
				}, 200);
			}
		}, [disabledInput, inputRef]);

		const clearInputValue = () => {
			if (inputRef && inputRef.current && inputRef.current.setValue) {
				inputRef.current.setValue("");
			}
		};

		const sendMessage = useCallback(
			(type: string, context: any) => {
				if (typeof idRoom !== "string" || idRoom.trim() === "") {
					return;
				}
				const userId = user instanceof Usuario && user.path ? user.path.split("/").pop() : null;

				const message = prepareMessage(type, context, userId, idRoom, user);

				if (!message || typeof message !== "object") {
					return;
				}

				if (botId === idRoom) {
					MultiStorager.DataStorager.push(`Chat_messages_${idRoom}`, message);
					clearInputValue();
					setInputLoading(false);
				} else if (typeof userId === "string" && userId.trim() !== "") {
					setInputLoading(true);
					const { context, roomId, timestamp, type, userId } = message;

					APIHelper.fetch(
						`chat/messages/${idRoom}/send`,
						{
							message: {
								context,
								roomId,
								timestamp,
								type,
								userId,
							},
						},
						10,
					)
						.then(() => {
							clearInputValue();
						})
						.catch(() => {
							InteractionHelper.toast("Não foi possível enviar sua mensagem, tente novamente!", null, "error");
						})
						.finally(() => {
							setInputLoading(false);
						});

					// getDatabase().ref("Chats").child(idRoom).child("messages").child(message.timestamp).set({
					//     context, roomId, timestamp, type, userId
					// });
				}
			},
			[user, idRoom, botId, inputRef],
		);

		useEffect(() => {
			resetMessages();
		}, [idRoom]);

		const onClickBtnSendMessage = useCallback(() => {
			if (!inputRef.current) {
				return;
			}

			sendMessage(type, {
				message: typeof inputRef.current.getValue === "function" ? inputRef.current.getValue() : inputRef.current.value,
			});
		}, [inputRef, sendMessage, type]);

		const onkeypress = useCallback(
			(evt: any) => {
				//console.log(evt);
				if (evt.which === 13 && !evt.shiftKey) {
					evt.preventDefault();
					onClickBtnSendMessage();
				}
			},
			[inputRef, onClickBtnSendMessage],
		);

		const suggestionChangeIn = useCallback(
			(value: string) => {
				if (typeof suggestionChange === "function") {
					suggestionChange(value, messages);
				}
			},
			[suggestionChange, messages],
		);

		useImperativeHandle(
			ref,
			() => ({
				disable: (disabled: boolean) => {
					setDisabled(typeof disabled === "boolean" ? disabled : true);
				},
				disableConversationList: (disabled: boolean) => {
					setDisabled(typeof disabled === "boolean" ? disabled : true);
				},
			}),
			[],
		);

		useLayoutEffect(() => {
			if (typeof onStart === "function") {
				onStart();
			}
		}, []);

		const changeRoomId = useCallback(
			(...args: any[]) => {
				if (typeof _changeRoomId === "function") {
					_changeRoomId.apply(null, args);
				}
			},
			[_changeRoomId],
		);

		const containsSuggestions = messages.length && Array.isArray(messages[messages.length - 1].context.suggestions);
		//const containsSuggestions = false;

		const isChatBot = containsBoot && botId && typeof botId === "string" && botId.trim() !== "" && botId === idRoom;

		const inputDisabled =
			disabled ||
			inputLoading ||
			disabledInput ||
			(isChatBot && botInputDisabled) ||
			((containsSuggestions || ["suggestions"].includes(type)) && !botContainsOnChange);

		const infoRoom = (Array.isArray(chatRooms) ? chatRooms : []).find(({ chatId }) => {
			return chatId === idRoom;
		});

		return (
			<div
				className={[
					style["Chat-root"],
					loading || disableConversationList || typeof idRoom !== "string" || idRoom.trim() === "" ? "" : style["show-chat"],
				].join(" ")}
			>
				{!disableConversationList && (
					<div
						className={style["conversation-list"]}
						onScroll={({ target }: React.UIEvent<HTMLElement>) => {
							const pos = conversationListScrollPosition.current;
							const scrollTop = (target as any)?.scrollTop ?? 0;
							if (Math.abs(pos - scrollTop) > 15 || scrollTop < 5) {
								if (typeof idRoom !== "string" || idRoom.trim() === "") {
									const isHidden = MainFrame.Controller.isMenuSummaryHidden();

									if (scrollTop < 5 && isHidden) {
										MainFrame.Controller.showMenuSummary();
									} else {
										if (scrollTop > pos && !isHidden) {
											MainFrame.Controller.hiddenMenuSummary();
										} else if (scrollTop < pos && isHidden) {
											MainFrame.Controller.showMenuSummary();
										}
									}
								}

								conversationListScrollPosition.current = scrollTop;
							}
						}}
					>
						{!loading && !showSearchUser && (
							<div
								className={[style["item"], style["new-chat"]].join(" ")}
								onClick={() => {
									setShowSearchUser(true);
								}}
							>
								<div className={style["picture"]}>
									<SvgIcon
										path={mdiPencil}
										children={undefined}
									/>
								</div>
								<div className={style["preview"]}>
									<div className={style["title"]}>Nova conversa</div>
								</div>
							</div>
						)}
						{!showSearchUser && (
							<ConversationList
								onSelect={(info: any) => {
									onSelectRoom(info);
									changeRoomId(info.chatId);
								}}
								idRoom={idRoom}
								onUpdateSelect={onSelectRoom}
								user={user}
								botId={botId}
							/>
						)}
						{showSearchUser && (
							<SearchUser
								toBack={() => {
									setShowSearchUser(false);
								}}
								onSelect={(info: any) => {
									onSelectRoom(info);
									changeRoomId(info.chatId);
								}}
							/>
						)}
					</div>
				)}
				<div className={[style["Chat-room"], !disableConversationList ? style["separator"] : ""].join(" ")}>
					{!disableConversationList && !infoRoom && (
						<div
							className={style["background"]}
							style={{
								backgroundImage: `url(${backgroundChat01})`,
							}}
						></div>
					)}
					{!disableConversationList && infoRoom && infoRoom.chat_started && (
						<div className={style["header"]}>
							<IconButton
								className={style["back-chats"]}
								color="primary"
								sx={{ m: "2px", p: "5px" }}
								onClick={() => {
									changeRoomId();
								}}
							>
								<SvgIcon
									path={mdiChevronLeft}
									children={undefined}
								/>
							</IconButton>
							<div
								className={style["picture"]}
								style={{ backgroundImage: `url(${infoRoom?.picture || ""})` }}
							></div>
							<div className={style["preview"]}>
								<div className={style["label"]}>{infoRoom?.name || ""}</div>
							</div>
						</div>
					)}
					{infoRoom && !infoRoom.chat_started && (
						<NewChat
							infoRoom={infoRoom}
							onUpdateRoom={(infoRoom: any) => {
								onSelectRoom(infoRoom);
								setShowSearchUser(false);
							}}
						/>
					)}
					{infoRoom && infoRoom.chat_started && (
						<>
							<div className={style["chatbox"]}>
								<ChatMessages
									disabled={disabled}
									onSendMessage={sendMessage}
									suggestionChange={suggestionChangeIn}
									isChatBot={isChatBot}
									botTyping={botTyping}
									user={user}
									infoRoom={infoRoom}
									botId={botId}
								/>
							</div>
							<div
								className={style["form"]}
								style={{
									opacity: !infoRoom || !infoRoom.chat_started ? "0" : "1",
									transform: !infoRoom || !infoRoom.chat_started ? "translateX(-100%)" : "translateX(0)",
								}}
							>
								<div className={style["input"]}>
									<Paper
										component="form"
										sx={{ p: "2px 5px", display: "flex", alignItems: "center", borderRadius: 2.5 }}
									>
										{type === "tel" ? (
											<PhoneInput
												specialLabel={""}
												country={"br"}
												onlyCountries={Object.keys(tel_masks)}
												masks={tel_masks}
												disabled={isChatBot && inputDisabled}
												onKeyDown={onkeypress}
												inputProps={{
													ref: (input: InputAutosizeRef) => {
														inputRef.current = input;
													},
												}}
											/>
										) : (
											<InputAutosize
												type={isChatBot ? type : "text"}
												disabled={(isChatBot && inputDisabled) || inputLoading}
												onKeyPress={onkeypress}
												ref={inputRef}
											/>
										)}
										<Divider
											sx={{ m: "10px 5px" }}
											orientation="vertical"
											flexItem
										/>
										<IconButton
											className={style["button-send"]}
											color="primary"
											sx={{ m: "2px", p: "10px" }}
											disabled={inputDisabled}
											onClick={onClickBtnSendMessage}
											disableRipple
										>
											{!inputLoading ? <SendIcon /> : <CircularProgress />}
										</IconButton>
									</Paper>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		);
	},
);

export default Chat;
