/**
 * @namespace BezierEasing
 * @function {@link https://github.com/gre/bezier-easing bezierEasing-Github}
 * @param {number} x1 
 * @param {number} y1 
 * @param {number} x2 
 * @param {number} y2 
 * @returns {Function}
 */
const BezierEasing = function(x1, y1, x2, y2){
    if (!(0 <= x1 && x1 <= 1 && 0 <= x2 && x2 <= 1)) {
        throw new Error("bezier x values must be in [0, 1] range");
    }
    let kSplineTableSize = 11,
        kSampleStepSize = 1.0 / (kSplineTableSize - 1.0),
        A = (a, b)=>{
            return 1.0 - 3.0 * b + 3.0 * a;
        },
        B = (a, b)=>{
            return 3.0 * b - 6.0 * a;
        },
        C = (a)=>{
            return 3.0 * a;
        },
        calcBezier = (a, b, c) => {
            return ((A(b, c) * a + B(b, c)) * a + C(b)) * a;
        },
        getSlope = (a, b, c) => {
            return 3.0 * A(b, c) * a * a + 2.0 * B(b, c) * a + C(b);
        },
        binarySubdivide = (a, b, c, d, e) => {
            let f,
                g,
                i = 0;
            do {
                g = b + (c - b) / 2.0;
                f = calcBezier(g, d, e) - a;
                if (f > 0.0) {
                    c = g;
                } else {
                    b = g;
                }
            } while (Math.abs(f) > 0.0000001 && ++i < 10);
            return g;
        },
        newtonRaphsonIterate = (a, b, c, d) => {
            for (let i = 0; i < 4; ++i) {
                let currentSlope = getSlope(b, c, d);
                if (currentSlope === 0.0) {
                    return b;
                }
                let currentX = calcBezier(b, c, d) - a;
                b -= currentX / currentSlope;
            }
            return b;
        };
    if (x1 === y1 && x2 === y2) {
        return (x) => {
            return x;
        };
    }
    let sampleValues =
        typeof Float32Array === "function"
            ? new Float32Array(kSplineTableSize)
            : new Array(kSplineTableSize);
    for (let i = 0; i < kSplineTableSize; ++i) {
        sampleValues[i] = calcBezier(i * kSampleStepSize, x1, x2);
    }
    let getTForX = (a) => {
        let b = 0.0,
            c = 1,
            d = kSplineTableSize - 1;
        for (; c !== d && sampleValues[c] <= a; ++c) {
            b += kSampleStepSize;
        }
        --c;
        let e = (a - sampleValues[c]) / (sampleValues[c + 1] - sampleValues[c]),
            f = b + e * kSampleStepSize,
            g = getSlope(f, x1, x2);
        if (g >= 0.001) {
            return newtonRaphsonIterate(a, f, x1, x2);
        } else if (g === 0.0) {
            return f;
        } else {
            return binarySubdivide(a, b, b + kSampleStepSize, x1, x2);
        }
    };
    return (x) => {
        return x === 0 ? 0 : x === 1 ? 1 : calcBezier(getTForX(x), y1, y2);
    };
};

/**
 * @function ease
 * @memberof BezierEasing
 * @static
 */
BezierEasing.prototype.ease = BezierEasing(0.25, 0.1, 0.25, 1.0);
/**
 * @function linear
 * @memberof BezierEasing
 * @static
 */
BezierEasing.prototype.linear = BezierEasing(0.0, 0.0, 1.0, 1.0);
/**
 * @function easeIn
 * @memberof BezierEasing
 * @static
 */
BezierEasing.prototype.easeIn = BezierEasing(0.42, 0.0, 1.0, 1.0);
/**
 * @function easeOut
 * @memberof BezierEasing
 * @static
 */
BezierEasing.prototype.easeOut = BezierEasing(0.0, 0.0, 0.58, 1.0);
/**
 * @function easeInOut
 * @memberof BezierEasing
 * @static
 */
BezierEasing.prototype.easeInOut = BezierEasing(0.42, 0.0, 0.58, 1.0);


export default BezierEasing;