import React, { useEffect, useState } from "react";
import { format, subMonths, addMonths } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Calendar from "./Components/Calendar";
import style from './style.module.scss'
import { APIHelper } from "Helper";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

interface Event {
  id: string;
  summary: string;
  start: string;
  end: string;
}

const Schedule = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [month, setMonth] = useState<Date>(new Date());

  useEffect(() => {
    const fetchEvents = async () => {
      const apiKey = "AIzaSyBeLi4ib3T-Oimf6L2sbgUq9MBDvjxVtCE";
      const calendarId = "04e136f22aae7b4c1c0d4a07f3724b2fb585f5496e5a7d58872e61102c40e872@group.calendar.google.com";
      const url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?key=${apiKey}`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        const myDate = new Date();
        const monthString = format(myDate, 'MMMM', { locale: ptBR });
        setMonth(myDate);
        const fetchedEvents: Event[] = data.items.map((item: any) => ({
          id: item.id,
          summary: item.summary,
          start: item.start.date,
          end: item.end.date
        }));
        setEvents(fetchedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const handleMonthChange = (newMonth: Date) => {
    setMonth(newMonth);
  };

  const monthString = format(month, 'MMMM yyyy', { locale: ptBR });

  return (
    <div className={style['schedule-main']}>
      <h1 className={style['month-title']}>{monthString.toUpperCase()}</h1>
      <div className={style['container']}>
        <div className={style['left-arrow']} onClick={() => handleMonthChange(subMonths(month, 1))}>
          <ArrowCircleLeftIcon />
        </div>
        <div className={style['calendar-content']}>
          <Calendar events={events} selectedMonth={month} onMonthChange={handleMonthChange} />
        </div>
        <div className={style['right-arrow']} onClick={() => handleMonthChange(addMonths(month, 1))}>
          <ArrowCircleRightIcon />
        </div>
      </div>
    </div>
  );
};

export default Schedule;



