import React, { useState, useEffect, useCallback } from "react";

import { FormControl, Select, MenuItem, OutlinedInput } from '@mui/material';

import { Icons } from "Components";

const SelectComponent = ({value: _value, defaultValue, sx, disabled, multiple, onChange, renderValue, itens})=>{
    const [value, setValue] = useState(multiple ? [] : "");
    const [selectList, setSelectList] = useState([]);
    const [isImg, setIsImg] = useState(false);

    useEffect(()=>{
        if(!Array.isArray(itens)){return;}
        setSelectList(()=> itens.filter(({value, label})=> (["string", "number"].includes(typeof value) && typeof label === "string")));
    }, [itens]);

    useEffect(()=>{
        setIsImg(selectList.findIndex(({img}) => img && typeof img === "string") >= 0);
    }, [selectList]);

    useEffect(()=>{
        if(multiple){
            setValue(Array.isArray(_value) ? _value : defaultValue);
            return;
        }
        setValue(typeof _value === "string" && selectList.findIndex(({value}) => (_value == value)) >= 0 ? _value : defaultValue);
    }, [_value, defaultValue, selectList]);

    const handleChange = useCallback(({target})=>{
        if(multiple){
            const v = typeof value === 'string' ? target.value.split(',') : target.value;
            typeof onChange === "function" && onChange(v);
            setValue(v);
            return;
        }
        typeof onChange === "function" && onChange(target.value);
        setValue(target.value);
    }, [value, onChange, multiple]);

    const renderValueChange = useCallback((selected)=>{
        selected = typeof selected === "string" ? [selected] : selected;
        const objs = selectList.filter(({value})=> selected.includes(value));
        if(renderValue && typeof renderValue === "function"){
            return renderValue(objs);
        }
        if(selected.length === 0){return "";}
        return objs.map(({label})=> label).join(', ');
    }, [selectList, renderValue]);

    return <FormControl sx={Object.assign({ m: 1, minWidth: 180, maxWidth: 200 }, sx)} size="small" disabled={disabled}>
        <Select
            autoWidth multiple={multiple} input={multiple ? <OutlinedInput/> : null}
            value={value} onChange={handleChange} renderValue={renderValueChange}
        >
            {selectList.map(({value, label, img, disabled}, i)=>{
                return <MenuItem key={`Settings-List-select-MenuItem-${i}`} value={value} disabled={disabled}>
                    {isImg && <Icons name={img} width={25} height={25} style={{marginRight: 15}}/>}{label}
                </MenuItem>
            })}
        </Select>
    </FormControl>
}

export default SelectComponent;