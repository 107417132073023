import React, { useLayoutEffect } from "react";

import { Chat } from "Components";

import style from "./index.module.scss";

import bot from "./chatBot";

import defineBot from "./defineChatBot";

import { ColorModeContext } from "Crucial/Theme";
import { MultiStorager } from "Utils";

interface props {
	desconectado: boolean;
}

const Splash: React.FC<props> = ({ desconectado }: props) => {
	const colorMode = React.useContext(ColorModeContext);

	useLayoutEffect(() => {
		MultiStorager.DataStorager.delete("Chat_messages_login_bot");
		const mode = window.palette.mode;

		if (mode === "dark") {
			return;
		}

		colorMode.toggleColorMode();

		return () => {
			colorMode.toggleColorMode();
		};
	}, []);

	return (
		<div className={[style["Splash-root"], desconectado === true ? style["Splash-login"] : ""].join(" ")}>
			<div className={style["Splash-main"]}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className={style["logo_marca_animation"]}
					viewBox="-400 -359.5 1200 1200"
				>
					<polygon
						className={style["blur-shadow"]}
						points="343.22 0.5 341.5 6.09 252.14 6.09 253.81 0.5 343.22 0.5"
					/>
					<polygon
						className={style["blur-shadow"]}
						points="335.28 26.2 329.08 46.32 240.16 46.32 246.15 26.2 335.28 26.2"
					/>
					<polygon
						className={style["blur-shadow"]}
						points="322.88 66.44 310.81 105.55 222.52 105.55 234.17 66.44 322.88 66.44"
					/>
					<path
						className={style["blur-shadow"]}
						d="M78.73,215.08h89.41l46.94,164.28L203.9,419.59h8.94l82.7-285h87.17L292.19,431.89c-1.68,5-7.63,27.47-28.5,43A77.15,77.15,0,0,1,219.55,490a77.88,77.88,0,0,1-46.38-16.2c-19.85-15.43-24.59-34.65-27.38-41.91Q112.25,323.49,78.73,215.08Z"
						transform="translate(-78.05 -9.5)"
					/>

					<polygon points="343.22 0.5 341.5 6.09 252.14 6.09 253.81 0.5 343.22 0.5" />
					<polygon points="335.28 26.2 329.08 46.32 240.16 46.32 246.15 26.2 335.28 26.2" />
					<polygon points="322.88 66.44 310.81 105.55 222.52 105.55 234.17 66.44 322.88 66.44" />
					<path
						d="M78.73,215.08h89.41l46.94,164.28L203.9,419.59h8.94l82.7-285h87.17L292.19,431.89c-1.68,5-7.63,27.47-28.5,43A77.15,77.15,0,0,1,219.55,490a77.88,77.88,0,0,1-46.38-16.2c-19.85-15.43-24.59-34.65-27.38-41.91Q112.25,323.49,78.73,215.08Z"
						transform="translate(-78.05 -9.5)"
					/>
				</svg>
			</div>
			<div className={style["Splash-content"]}>
				{desconectado === true && (
					<Chat
						bot={defineBot}
						idRoom={"login_bot"}
						disableConversationList
					/>
				)}
			</div>
		</div>
	);
};

export default Splash;
