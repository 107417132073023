type JSONObject =
	| {
			[k: string]: any;
	  }
	| Array<string | number | boolean | Date | undefined | null | JSONObject>;

export const DataTypes = {
	String: (value: any): value is string => typeof value === "string",
	Number: (value: any): value is number => typeof value === "number",
	Integer: (value: any): value is number => typeof value === "number" && Math.floor(value) === value,
	Float: (value: any): value is number => typeof value === "number" && Math.floor(value) !== value,
	Boolean: (value: any): value is boolean => typeof value === "boolean",
	Date: (value: any): value is Date => value instanceof Date || (typeof value === "string" && !isNaN(Date.parse(value))),
	Object: "Object",
	Array: "Array",
};

type Value = Date | number | string | boolean | null | undefined;

type ValueFieldType = (typeof DataTypes)[keyof typeof DataTypes] | FirestoreObject;

interface ValueField {
	type?: ValueFieldType | ValueFieldType[];
	enum?: any[];
	items?: ValueField | ModelSchema;
	properties?: ModelSchema;
	default?: any;
	set?: (value: Value) => any;
	get?: (value: Value) => any;
}

interface ModelSchema {
	[k: string]: ValueField;
}

type ModelSchemaObject<T extends { [K in keyof T]: ValueField }> = {
	[P in keyof T]: any;
};

const isEmpty = (n: any): boolean => {
	return typeof n !== "boolean" && !(!!n ? (typeof n === "object" ? (Array.isArray(n) ? !!n.length : !!Object.keys(n).length) : true) : false);
};

const normalizePath = (...path: string[]): string => {
	return String(path.join("/"))
		.replace(/(\/)+/gi, "/")
		.replace(/^(\/)+/gi, "")
		.replace(/(\/)+$/gi, "");
};

const removeProperties = (obj: JSONObject, exclude: string[] = [], path: string = ""): JSONObject => {
	if (typeof obj !== "object" || obj === null || !Array.isArray(exclude) || exclude.length <= 0) {
		return obj;
	}

	exclude = exclude.filter((s) => typeof s === "string").map((p) => normalizePath(p));

	if (Array.isArray(obj)) {
		return obj.map((item: any) => removeProperties(item, exclude, normalizePath(path, "/*/")));
	}

	for (let key in obj) {
		const p = normalizePath(path, key);
		if (exclude.includes(p)) {
			delete obj[key];
		} else if (typeof obj[key] === "object") {
			obj[key] = removeProperties(obj[key], exclude, p);
			if (Array.isArray(obj[key])) {
				obj[key] = obj[key].filter((v: any) => !isEmpty(v));
			}
			if (isEmpty(obj[key])) {
				delete obj[key];
			}
		}
	}

	return obj;
};

const getPathsExclude = (obj: JSONObject, include: string[] = [], exclude: string[] = [], path: string = ""): string[] => {
	include = include.filter((s) => typeof s === "string").map((p) => normalizePath(p));
	exclude = exclude.filter((s) => typeof s === "string").map((p) => normalizePath(p));

	if (typeof obj !== "object" || obj === null || !Array.isArray(include) || include.length <= 0) {
		return exclude;
	}

	if (Array.isArray(obj)) {
		return Array.prototype.concat.apply(
			[],
			obj.map((item: any) => getPathsExclude(item, include, exclude, normalizePath(path, "/*/"))),
		);
	}

	for (let key in obj) {
		const p = normalizePath(path, key);

		if (!include.includes(p)) {
			exclude.push(p);

			if (typeof obj[key] === "object") {
				exclude = exclude.concat(getPathsExclude(obj[key], include, exclude, p));
			}
		} else {
			const pathsInclude = include
				.filter((s) => s.search(p) === 0)
				.map((s) => normalizePath(s.replace(p, "")).split("/").join("/"))
				.filter((s) => !isEmpty(s))
				.map((s) => normalizePath(p, s));

			exclude = exclude.concat(getPathsExclude(obj[key], pathsInclude, [], p));
		}
	}

	return exclude.filter((v, i, s) => s.indexOf(v) === i);
};

const renderPath = (path: (string | number)[]): string => {
	let result = "";
	for (let key of path) {
		if (typeof key === "number") {
			result += `[${key}]`;
		} else if (typeof key === "string") {
			result += `/${key}`;
		}
	}
	return result.replace(/(\/)+/gi, "/").replace(/^(\/)/gi, "").replace(/(\/)$/gi, "");
};

const prepareValue = (value: Value, newValue: Value, field: ValueField) => {
	if (field.type && typeof field.type === "function") {
		value = typeof newValue !== "undefined" && field.type(newValue) ? newValue : value;
	} else {
		value = newValue;
	}

	if (Array.isArray(field.enum) && field.enum.includes(value) !== true) {
		value = null;
	}

	if (value === null || value === undefined || isEmpty(value)) {
		value = field.default && typeof field.default === "function" ? field.default() : field.default ?? value;
	}

	if (field.set && typeof field.set === "function") {
		value = field.set(value);
	}
	return value;
};

const parseSchema = <T extends Object, M = JSONObject>(rootPath: (string | number)[], obj: T, data: JSONObject, model: M): T => {
	model = Array.isArray(data) ? (new Array(data.length).fill(model) as any) : (model as any);

	const prepareProperty = (key: string | number, value: any, newValue: any) => {
		const field = (model as any)[key] ?? {};
		const path: (string | number)[] = [...rootPath, key];

		newValue = newValue && newValue instanceof FirestoreObject ? newValue.toJson() : newValue;

		if (field.type === "Object") {
			if (!value) {
				value = {};
			}
			if (field.properties && typeof field.properties === "object") {
				value = parseSchema(path, value, newValue ?? {}, field.properties);
			} else if (typeof newValue === "object") {
				value = Object.assign(value, newValue);
			}
		} else if (field.type === "Array") {
			if (!Array.isArray(value)) {
				value = [];
			}
			if (Array.isArray(newValue) && field.items && typeof field.items === "object") {
				value = parseSchema(path, value, newValue ?? [], field.items);
			} else if (Array.isArray(newValue) && newValue.length) {
				value = newValue;
			}
		} else if (field.type && field.type instanceof FirestoreObject) {
			value = field.type.parse(renderPath(path), Object.assign(value ?? {}, newValue ?? {}));
		} else {
			value = prepareValue(value, newValue, field);
		}

		return value;
	};

	for (const key in model) {
		if ((model as any).hasOwnProperty(key) || Array.isArray(data)) {
			(obj as any)[key] = prepareProperty(key, (obj as any)[key], (data as any)[key]);
		}
	}

	return new Proxy(obj, {
		set(obj, prop, value) {
			if ((model as any).hasOwnProperty(prop)) {
				value = prepareProperty(prop as any, Reflect.get(obj, prop), value);
			}
			return Reflect.set(obj, prop, value);
		},
		get(obj, prop) {
			let value = Reflect.get(obj, prop);
			if ((model as any).hasOwnProperty(prop)) {
				const field = (model as any)[prop] ?? {};
				if (field.get && typeof field.get === "function") {
					value = field.get(value);
				}
			}
			return value;
		},
	}) as any;
};

/**
 * Classe FirestoreObject.
 * Representa um objeto do Firestore com funcionalidades adicionais.
 *
 * @class
 * @param {string} path - O caminho do objeto no Firestore.
 * @param {object} schema - O esquema (schema) do objeto.
 * @returns {object} - O objeto parseado correspondente ao caminho fornecido.
 */
class FirestoreObject<T = FirestoreObject<any>, S = ModelSchema> {
	/**
	 * Propriedade que representa o caminho do objeto no Firestore.
	 * @type {string}
	 */
	path: string | undefined | null;

	/**
	 * Propriedade que armazena o esquema (schema) do objeto.
	 * @private
	 * @type {object}
	 */
	private _schema: S = {} as any;

	/**
	 * Construtor da classe FirestoreObject.
	 * Cria uma nova instância de FirestoreObject.
	 *
	 * @constructor
	 * @param {string} path - O caminho do objeto no Firestore.
	 * @param {object} schema - O esquema (schema) do objeto.
	 * @returns {object} - O objeto parseado correspondente ao caminho fornecido.
	 */
	constructor(path: string | undefined | null, schema: S) {
		this.path = path;

		this._schema = {} as S;

		this.defineSchema(schema);

		this.criarAtributoReferencial("path", false);
		this.addInvisibleProperty("_schema");

		return this.parse(path, {}) as any;
	}

	/**
	 * Define o esquema (schema) do objeto.
	 *
	 * @param {object} schema - O esquema (schema) do objeto.
	 */
	defineSchema(schema: S) {
		this._schema = schema && typeof schema === "object" ? schema : this._schema;
	}

	/**
	 * Adiciona uma propriedade invisível ao objeto.
	 *
	 * @param {string} key - O nome da propriedade.
	 */
	addInvisibleProperty(key: string) {
		Object.defineProperty(this, key, {
			writable: true,
			configurable: true,
			enumerable: false,
		});
	}

	/**
	 * Corrige uma referência removendo barras "/" desnecessárias.
	 *
	 * @param {string} value - O valor da referência.
	 * @returns {string} - O valor corrigido da referência.
	 */
	corrigirReferencia(value: string): string {
		if (typeof value === "string") {
			if (value[0] === "/") {
				value = value.substring(1, value.length);
			}
			if (value[value.length - 1] === "/") {
				value = value.substring(0, value.length - 1);
			}
		}
		return value;
	}

	/**
	 * Cria um atributo referencial no objeto.
	 *
	 * @param {string} nome - O nome do atributo.
	 * @param {boolean} enumeravel - Indica se o atributo é enumerável.
	 */
	criarAtributoReferencial(nome: string, enumeravel: boolean | undefined = undefined) {
		let atributo: any;
		Object.defineProperty(this, nome, {
			configurable: true,
			enumerable: enumeravel ? true : false,
			get: () => {
				return atributo;
			},
			set: (value) => {
				atributo = this.corrigirReferencia(value);
			},
		});
	}

	/**
	 * Converte o objeto em um JSON, removendo propriedades indesejadas.
	 * @param {object} [target=this] - O objeto a ser convertido em JSON.
	 * @param {object} options - Opções de configuração.
	 * @param {string[]} options.exclude - Lista de propriedades a serem excluídas.
	 * @param {string[]} options.include - Lista de propriedades a serem incluídas.
	 * @returns {JSONObject} - O objeto convertido em JSON, com as propriedades filtradas.
	 */
	toJson(target: T | undefined = undefined, options = { exclude: [], include: [] }): any {
		const targetNow: any = target instanceof FirestoreObject ? target : this;
		let simpleObject = {};
		parseSchema([this.path as any], simpleObject, targetNow, this._schema as any);
		simpleObject = Object.fromEntries(
			Object.entries(simpleObject).filter(([property]) => {
				return Object.getOwnPropertyDescriptor(targetNow, property)?.enumerable ?? false;
			}),
		);
		const object = JSON.parse(JSON.stringify(simpleObject));
		return removeProperties(object, getPathsExclude(object, options?.include ?? [], options?.exclude ?? []));
	}

	/**
	 * Faz o parse do objeto com base no caminho e no esquema.
	 *
	 * @param {string|null} path - O caminho do objeto no Firestore.
	 * @param {object} objetoGenerico - O objeto genérico.
	 * @returns {object} - O objeto parseado correspondente ao caminho fornecido.
	 */
	parse(path: string | null | undefined, objetoGenerico: JSONObject): this {
		this.path = path;
		objetoGenerico =
			objetoGenerico instanceof FirestoreObject ? objetoGenerico.toJson() : typeof objetoGenerico === "object" ? objetoGenerico : {};
		return parseSchema([this.path as any], this, objetoGenerico, this._schema as any);
	}
}

export default FirestoreObject;
