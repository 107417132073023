export default class SwipeEventDispatcher {
  constructor(element, options = {}) {
    this.evtMap = {
      swipe_left: [],
      swipe_up: [],
      swipe_down: [],
      swipe_right: [],
    };

    if (element === document.body || document.body.contains(element) !== true) {
      console.error(
        "No HTML element required for SwipeEventDispatcher was reported!"
      );
      return;
    }

    this.xDown = null;
    this.yDown = null;
    this.element = element;
    this.isMouseDown = false;
    this.listenForMouseEvents = true;
    this.options = Object.assign({ triggerPercent: 0.3 }, options);

    element.addEventListener(
      "touchstart",
      (evt) => this.handleTouchStart(evt),
      false
    );
    element.addEventListener(
      "touchend",
      (evt) => this.handleTouchEnd(evt),
      false
    );
    element.addEventListener(
      "mousedown",
      (evt) => this.handleMouseDown(evt),
      false
    );
    element.addEventListener(
      "mouseup",
      (evt) => this.handleMouseUp(evt),
      false
    );
  }

  on(evt, cb) {
    if (evt in this.evtMap) {
      this.evtMap[evt].push(cb);
    }
  }

  off(evt, lcb) {
    if (evt in this.evtMap) {
      this.evtMap[evt] = this.evtMap[evt].filter((cb) => cb !== lcb);
    }
  }

  trigger(evt, data) {
    if (
      !(evt in this.evtMap) ||
      this.element === document.body ||
      this.document.body.contains(this.element) !== true
    ) {
      return;
    }
    this.evtMap[evt].map((handler) => handler(data));
  }

  handleTouchStart(evt) {
    this.xDown = evt.touches[0].clientX;
    this.yDown = evt.touches[0].clientY;
  }

  handleMouseDown(evt) {
    if (this.listenForMouseEvents == false) {
      return;
    }
    this.xDown = evt.clientX;
    this.yDown = evt.clientY;
    this.isMouseDown = true;
  }

  handleMouseUp(evt) {
    if (this.isMouseDown == false) {
      return;
    }
    const deltaX = evt.clientX - this.xDown;
    const deltaY = evt.clientY - this.yDown;
    const distMoved = Math.abs(
      Math.abs(deltaX) > Math.abs(deltaY) ? deltaX : deltaY
    );
    const activePct = distMoved / this.element.offsetWidth;

    if (activePct > this.options.triggerPercent) {
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        deltaX < 0 ? this.trigger("swipe_left") : this.trigger("swipe_right");
      } else {
        deltaY > 0 ? this.trigger("swipe_down") : this.trigger("swipe_up");
      }
    }
  }

  handleTouchEnd(evt) {
    const deltaX = evt.changedTouches[0].clientX - this.xDown;
    const deltaY = evt.changedTouches[0].clientY - this.yDown;
    const distMoved = Math.abs(
      Math.abs(deltaX) > Math.abs(deltaY) ? deltaX : deltaY
    );
    const activePct = distMoved / this.element.offsetWidth;

    if (activePct > this.options.triggerPercent) {
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        deltaX < 0 ? this.trigger("swipe_left") : this.trigger("swipe_right");
      } else {
        deltaY > 0 ? this.trigger("swipe_down") : this.trigger("swipe_up");
      }
    }
  }
}
