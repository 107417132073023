/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect, useRef, useLayoutEffect, useCallback, EventHandler } from "react";

import { MultiStorager } from "Utils";

import { Messages } from "Crucial";

import {
	Stack,
	Alert,
	AlertTitle,
	Snackbar,
	Backdrop,
	CircularProgress,
	Button,
	ButtonGroup,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Box,
	IconButton,
	useTheme,
	useMediaQuery,
	Checkbox,
	Divider,
	Typography,
} from "@mui/material";

import { SnackbarProvider, useSnackbar } from "notistack";

import { InputCustom, CropImage, SvgIcon } from "Components";
import { InteractionElementProps, getConfBy, severity, types, getStackInfo } from "./Interactions";
import { mdiAlert, mdiAlertCircle, mdiCheckDecagram, mdiChevronDown, mdiCloseCircle, mdiCloseThick, mdiInformation } from "@mdi/js";

import style from "./style.module.scss";
import { InteractionHelper } from "Helper";

const StackContent: React.FC<{
	stacks: Array<ReturnType<(typeof types)["toast"]>>;
}> = ({ stacks }) => {
	const [messageInfo, setMessageInfo] = useState<
		| {
				title: string | null | undefined;
				message: string | JSX.Element;
				severity: severity;
				duration: number;
				key: number;
		  }
		| undefined
	>(undefined);
	const [snackPack, setSnackPack] = useState<NonNullable<typeof messageInfo>[]>([]);
	const [open, setOpen] = useState<boolean>(false);
	const [ids, setIds] = useState<number[]>([]);

	useEffect(() => {
		if (snackPack.length && !messageInfo) {
			setMessageInfo({ ...snackPack[0] });
			setSnackPack((prev) => prev.slice(1));
			setOpen(true);
		} else if (snackPack.length && messageInfo && open) {
			setOpen(false);
		}
	}, [snackPack, messageInfo, open]);

	// useEffect(() => {
	// 	if (!id_ || !message || (typeof id_ === "number" && ids.includes(id_))) {
	// 		return;
	// 	}
	// 	setIds((prev) => [...prev, id_]);
	// 	setSnackPack((prev) => [...prev, { title, message, severity, duration, key: new Date().getTime() }]);
	// }, [id_]);

	const handleClose = (event: any, reason?: string) => {
		// if (reason === "clickaway") {
		// 	return;
		// }
		// setOpen(false);
		// if (typeof onClose === "function") {
		// 	onClose();
		// }
	};

	// const handleCloseAction = typeof onClose === "function";
	const handleCloseAction = false;

	const handleExited = () => {
		setMessageInfo(undefined);
	};

	return (
		<Stack
			spacing={2}
			sx={{ width: "100%" }}
		>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				key={messageInfo ? messageInfo.key : undefined}
				open={open}
				autoHideDuration={messageInfo && typeof messageInfo.duration === "number" ? messageInfo.duration : handleCloseAction ? 6000 : null}
				TransitionProps={{ onExited: handleExited }}
				onClose={handleCloseAction ? handleClose : undefined}
			>
				<Alert
					variant="filled"
					severity={messageInfo ? (messageInfo.severity as any) : undefined}
					onClose={handleCloseAction ? handleClose : undefined}
					style={{ maxWidth: "400px", width: "90vw" }}
				>
					{messageInfo && typeof messageInfo.title === "string" && <AlertTitle>{messageInfo.title}</AlertTitle>}
					{messageInfo ? messageInfo.message : ""}
				</Alert>
			</Snackbar>
		</Stack>
	);
};

interface StateDialog<T extends keyof typeof types> {
	show: boolean;
	type: T | null;
	stage: number;
	props: T extends keyof typeof types ? InteractionElementProps[keyof typeof types] : {};
}

const InteractionContent: React.FC = () => {
	const [dialog, setDialog] = useState<StateDialog<keyof typeof types>>({ show: false, type: null, stage: 0, props: {} });
	const [confirmTerm, setConfirmTerm] = useState<boolean>(false);
	const [notifications, setNotifications] = useState<
		Array<{
			title: string;
			message: string;
			route?: string;
			severity?: "error" | "info" | "success" | "warning";
			icon?: string;
			type?: string;
			action?: ((...args: any[]) => void) | null;
			stackInfo?: ReturnType<typeof getStackInfo>;
			time: number;
		}>
	>([]);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const notificationRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setConfirmTerm(false);
	}, [dialog]);

	const uploadImage = useRef<{
		show: boolean;
		imageUploadBase46: string;
		props: Partial<InteractionElementProps["uploadImage"]>;
	}>({ show: false, imageUploadBase46: "", props: {} });

	const inputUploadImage = useRef<HTMLInputElement | null>(null);

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const byUploadImage = useCallback(() => {
		if (!inputUploadImage.current) {
			return;
		}
		inputUploadImage.current.value = "";

		inputUploadImage.current.onchange = () => {
			if (!inputUploadImage.current) {
				return;
			}
			if (inputUploadImage.current.files && inputUploadImage.current.files[0]) {
				let image = new window.FileReader();
				image.onload = ({ target }) => {
					uploadImage.current.show = true;
					setDialog({
						show: true,
						type: "confirm",
						stage: 0,
						props: {
							title: null,
							body: (
								<CropImage
									config={{
										imageSize: uploadImage.current.props.imageSize,
										aspectRatio: uploadImage.current.props.aspectRatio,
									}}
									style={{ height: "100%" }}
									src={target?.result}
									onChange={(image: any) => {
										uploadImage.current.imageUploadBase46 = image;
									}}
								/>
							),
							onClose: (confirmed: boolean) => {
								if (typeof uploadImage.current.props.onClose === "function") {
									uploadImage.current.props.onClose(confirmed ? uploadImage.current.imageUploadBase46 : null);
								}
								close("uploadImage", {});
							},
						},
					});
				};
				image.readAsDataURL(inputUploadImage.current.files[0]);
			} else {
				close("uploadImage", {});
			}
		};

		window.setTimeout(() => {
			inputUploadImage.current?.click();
		}, 300);
	}, []);

	const pushToast = ({
		id,
		duration,
		message,
		onClose,
		severity,
		title,
	}: {
		id: number;
		duration?: number | null;
		message: string | JSX.Element;
		onClose: boolean | (() => void) | null;
		severity: severity;
		title?: string | null;
	}) => {
		enqueueSnackbar(message, {
			variant: severity ?? "info",
			autoHideDuration: duration,
			key: id,
			onClose: () => (typeof onClose === "function" ? onClose() : null),
			action: () => {
				return (
					<IconButton
						onClick={() => close("toast", { id })}
						disableRipple
						style={{ color: "currentcolor" }}
					>
						<SvgIcon
							path={mdiCloseCircle}
							style={{ fill: "currentcolor" }}
						/>
					</IconButton>
				);
			},
			anchorOrigin: {
				vertical: "bottom",
				horizontal: "right",
			},
		});
	};

	const show = useCallback((type: keyof typeof types, props: any) => {
		switch (type) {
			case "alert":
			case "confirm":
			case "loading":
			case "prompt":
				setDialog({ show: true, type, stage: 0, props: getConfBy(type, props) });
				break;
			case "uploadImage":
				uploadImage.current = { show: true, imageUploadBase46: "", props: getConfBy(type, props) };
				byUploadImage();
				return;
			case "toast":
				const { title = undefined, message = "", severity = "info", id = 0, onClose = () => null, duration = 5000 } = getConfBy(type, props);

				const stackInfo = getStackInfo().slice(6);

				const severityOpts: {
					[p in severity]: {
						title: string;
						icon: string;
					};
				} = {
					error: {
						title: "Erro de sistema",
						icon: mdiAlertCircle,
					},
					info: {
						title: "Notificação",
						icon: mdiInformation,
					},
					success: {
						title: "Sucesso",
						icon: mdiCheckDecagram,
					},
					warning: {
						title: "Atenção",
						icon: mdiAlert,
					},
				};

				setNotifications((p) => {
					return [
						...p,
						{
							title: title ?? severityOpts[severity ?? "info"].title,
							icon: severityOpts[severity ?? "info"].icon,
							message,
							severity: severity ?? "info",
							type: "toast",
							action: () => (typeof onClose === "function" ? onClose() : null),
							stackInfo,
							route: InteractionHelper.route.pathname,
							time: new Date().getTime(),
						} as any,
					];
				});

				if (!notificationRef.current || notificationRef.current.classList.contains(style["show"]) !== true) {
					pushToast({ title, message, severity, id, onClose, duration });
				}
				break;
		}
	}, []);

	const close = useCallback((type: keyof typeof types, props: any) => {
		switch (type) {
			case "alert":
			case "confirm":
			case "loading":
			case "prompt":
				setDialog({ show: false, type: null, stage: 0, props: {} });
				break;
			case "uploadImage":
				uploadImage.current = { show: false, imageUploadBase46: "", props: {} };
				setDialog({ show: false, type: null, stage: 0, props: {} });
				break;
			case "toast":
				closeSnackbar(props?.id);
				break;
		}
	}, []);

	useLayoutEffect(() => {
		MultiStorager.DataStorager.set<(type: keyof typeof types, props: any) => void>("InteractionContent-show", (type, props) => {
			show(type, props);
		});

		MultiStorager.DataStorager.set<(type: keyof typeof types, props: any) => void>("InteractionContent-close", (type, props) => {
			close(type, props);
		});

		MultiStorager.DataStorager.set<(options: (typeof notifications)[number]) => void>("InteractionContent-push-notification", (options) => {
			const { message, title, action = null, icon, severity = "info", type } = options;
			const id = Math.round(Math.random() * 100000);
			options.stackInfo = getStackInfo();
			options.route = InteractionHelper.route.pathname;
			options.time = new Date().getTime();

			setNotifications((p) => {
				return [...p, options];
			});

			if (!notificationRef.current || notificationRef.current.classList.contains(style["show"]) !== true) {
				pushToast({ title, message, severity, id, onClose: action, duration: 5000 });
			}
		});

		let time: NodeJS.Timeout;

		MultiStorager.DataStorager.set<(show: boolean | undefined) => void>("InteractionContent-toggle-notifications", (show = undefined) => {
			if (notificationRef.current) {
				clearTimeout(time);
				if (typeof show === "boolean") {
					if (show) {
						notificationRef.current.classList.add(style["show"]);
					} else {
						notificationRef.current.classList.remove(style["show"]);
					}
				} else if (notificationRef.current.classList.contains(style["show"])) {
					notificationRef.current.classList.remove(style["show"]);
				} else {
					notificationRef.current.classList.add(style["show"]);
				}
			}
		});

		const closeNotifications = (event: MouseEvent) => {
			clearTimeout(time);
			time = setTimeout(() => {
				if (
					notificationRef.current &&
					notificationRef.current.classList.contains(style["show"]) &&
					!notificationRef.current.contains(event.target as Node)
				) {
					notificationRef.current.classList.remove(style["show"]);
				}
			}, 100);
		};

		document.addEventListener("mousedown", closeNotifications);

		return () => {
			MultiStorager.DataStorager.delete("InteractionContent-show");
			MultiStorager.DataStorager.delete("InteractionContent-close");
			MultiStorager.DataStorager.delete("InteractionContent-push-notification");
			MultiStorager.DataStorager.delete("InteractionContent-toggle-notifications");

			clearTimeout(time);
			document.removeEventListener("mousedown", closeNotifications);
		};
	}, []);

	const clearDialog = () => {
		setDialog((p) => ({ ...p, show: false }));
	};

	const closeDialog = (value: any) => {
		if (!dialog.show) {
			return;
		}
		if (dialog.type === "loading") {
			return;
		}

		if (typeof (dialog.props as any).onClose === "function") {
			(dialog.props as any).onClose(typeof value === "boolean" || typeof value === "string" ? value : false);
		}

		clearDialog();
	};

	const dialogActionConfirm = () => {
		closeDialog(dialog.type === "prompt" ? (dialog.props as any).value : true);
	};

	const dialog_stages: number = Array.isArray((dialog.props as any)?.body) ? (dialog.props as any)?.body?.length ?? 1 : 1;

	return (
		<>
			<input
				ref={inputUploadImage}
				id="InteractionContent_InputUploadImage"
				type="file"
				accept="image/*"
				style={{ display: "none" }}
			/>

			<Backdrop
				sx={{ color: "#fff", zIndex: 9999 }}
				open={dialog.show && dialog.type === "loading"}
				onClick={closeDialog}
			>
				<CircularProgress color="inherit" />
				{dialog.type === "alert" && <CircularProgress color="inherit" />}
			</Backdrop>

			<Dialog
				fullWidth
				maxWidth={"sm"}
				open={dialog.show && ["alert", "confirm", "prompt"].includes(dialog.type as any)}
				onClose={closeDialog}
				fullScreen={fullScreen && dialog.type !== "alert"}
			>
				{typeof (dialog.props as any).title === "string" && <DialogTitle>{(dialog.props as any).title}</DialogTitle>}
				<DialogContent
					className={"select-text"}
					dividers
					style={{ padding: uploadImage.current.show ? "0px" : undefined }}
				>
					<Box
						style={{
							marginBottom: dialog.type === "prompt" ? "20px" : "",
							height: uploadImage.current.show ? "70vh" : "",
							maxHeight: uploadImage.current.show ? "600px" : "",
							minHeight: uploadImage.current.show ? "" : "100%",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{dialog_stages > 1
							? (dialog.props as any).body[Math.min(dialog_stages - 1, Math.max(0, dialog.stage))]
							: (dialog.props as any).body}
					</Box>
					{dialog.type === "prompt" && (
						<InputCustom
							value={(dialog.props as any).value}
							onChange={(value) => {
								(dialog.props as any).value = value;
							}}
						/>
					)}
				</DialogContent>
				<DialogActions
					sx={{
						flexDirection: "column",
						padding: "0px",
					}}
				>
					{dialog.props && typeof (dialog.props as any).term === "string" && dialog.stage === dialog_stages - 1 && (
						<>
							<Box
								sx={{
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "center",
								}}
							>
								<Checkbox
									size="small"
									checked={confirmTerm}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										setConfirmTerm(event.target.checked);
									}}
									disableRipple
								/>
								<Typography
									variant="caption"
									sx={{ cursor: "pointer" }}
									onClick={() => {
										setConfirmTerm((p) => !p);
									}}
								>
									{Messages.getMsg("INTERACTION-DIALOG-TERM")}
								</Typography>
								<Typography
									variant="caption"
									sx={{ marginLeft: "5px" }}
								>
									<a
										href={`https://api.ivipcoin.com/v1/term/${(dialog.props as any).term}/view`}
										target="_blank"
									>
										{Messages.getMsg("INTERACTION-DIALOG-TERM-LINK")}
									</a>
								</Typography>
							</Box>
							<Divider
								sx={{
									width: "100%",
									margin: "0px !important",
								}}
							/>
						</>
					)}
					<div
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
							alignItems: "center",
							padding: "10px",
							margin: "0px",
							gap: "10px",
						}}
					>
						{dialog_stages > 1 && (
							<ButtonGroup
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									padding: "0px",
									margin: "0px !important",
								}}
							>
								<Button
									onClick={() => {
										setDialog((p) => ({ ...p, stage: p.stage - 1 }));
									}}
									variant="outlined"
									color="warning"
									disabled={dialog.stage === 0}
								>
									{Messages.getMsg("INTERACTION-DIALOG-RETURN")}
								</Button>
							</ButtonGroup>
						)}
						{dialog_stages > 1 && (
							<Typography
								variant="h6"
								component="div"
								sx={{
									flex: 1,
									textAlign: "center",
								}}
							>
								{dialog.stage + 1}/{dialog_stages}
							</Typography>
						)}
						<ButtonGroup
							sx={{
								flex: dialog_stages > 1 ? undefined : 1,
								display: "flex",
								justifyContent: "flex-end",
								padding: "0px",
								margin: "0px !important",
							}}
						>
							<Button
								onClick={
									dialog.stage < dialog_stages - 1
										? () => {
												setDialog((p) => ({ ...p, stage: p.stage + 1 }));
										  }
										: dialogActionConfirm
								}
								variant="contained"
								disabled={dialog.props && (dialog.props as any).term && !confirmTerm && dialog.stage === dialog_stages - 1}
							>
								{dialog.props && Array.isArray((dialog.props as any).btnNames) && (dialog.props as any).btnNames[0]
									? (dialog.props as any).btnNames[0]
									: dialog.stage < dialog_stages - 1
									? Messages.getMsg("INTERACTION-DIALOG-CONTINUE")
									: dialog.type === "alert"
									? Messages.getMsg("INTERACTION-DIALOG-OK")
									: Messages.getMsg("INTERACTION-DIALOG-CONFIRM")}
							</Button>
							{["confirm", "prompt"].includes(dialog.type as any) && (
								<Button
									onClick={closeDialog}
									variant="outlined"
									color="warning"
								>
									{dialog.props && Array.isArray((dialog.props as any).btnNames) && (dialog.props as any).btnNames[1]
										? (dialog.props as any).btnNames[1]
										: Messages.getMsg("INTERACTION-DIALOG-CANCEL")}
								</Button>
							)}
						</ButtonGroup>
					</div>
				</DialogActions>
			</Dialog>

			<div
				className={style["notification-container"]}
				ref={notificationRef}
			>
				<div className={style["content"]}>
					<Typography
						variant="subtitle1"
						component="div"
					>
						Notificações
					</Typography>
					<div
						className={style["itens"]}
						data-empty-text={"Não há notificações novas"}
					>
						{notifications
							.sort((a, b) => {
								return b.time - a.time;
							})
							.map(({ icon, title, route, message, severity, stackInfo, type, time }, i) => {
								title = `${title} - ${new Date(time).toLocaleString()}`;
								return (
									<div
										key={i}
										className={style[severity ?? "info"]}
									>
										<div className={style.icon}>
											<SvgIcon path={icon ?? mdiInformation} />
										</div>
										<div className={style["notification-info"]}>
											<Typography
												variant="subtitle2"
												component="div"
												title={title}
											>
												{title}
											</Typography>
											<Typography
												variant="body2"
												component="div"
												title={message}
											>
												{message}
											</Typography>
											{typeof route === "string" && route.trim() !== "" && (
												<Typography
													variant="caption"
													component="div"
													title={route}
												>
													{route}
												</Typography>
											)}
										</div>
										<div className={style["notification-actions"]}>
											<IconButton
												size="small"
												disableRipple
												onClick={() => {
													setNotifications((p) => p.filter((_, index) => index !== i));
												}}
											>
												<SvgIcon path={mdiCloseCircle} />
											</IconButton>
											<IconButton
												size="small"
												disableRipple
												disabled
											>
												<SvgIcon path={mdiChevronDown} />
											</IconButton>
										</div>
									</div>
								);
							})}
					</div>
				</div>
				<div className={style["actions"]}>
					<Typography
						variant="subtitle1"
						component="div"
					>
						Serviços
					</Typography>
					<div
						className={style["itens"]}
						data-empty-text={"Não há serviços no momento"}
					></div>
				</div>
				<div className={style["close"]}>
					<IconButton
						size="large"
						disableRipple
						onClick={() => {
							if (notificationRef.current) {
								notificationRef.current.classList.remove(style["show"]);
							}
						}}
					>
						<SvgIcon path={mdiCloseCircle} />
					</IconButton>
				</div>
			</div>

			{/* <StackContent {...toast} /> */}
		</>
	);
};

const InteractionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<SnackbarProvider>
			{children}
			<InteractionContent />
		</SnackbarProvider>
	);
};

export default InteractionProvider;
