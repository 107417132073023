import { APIHelper } from "Helper";
import React, { useCallback, useEffect, useState } from "react";

import style from "./style.module.scss";
import { IconButton, InputBase, Typography } from "@mui/material";
import { ModernCard, PageHeaderWrapper, SvgIcon } from "Components";
import { mdiFilterMenu, mdiHelpRhombus, mdiMagnify } from "@mdi/js";
import { maskAmount } from "Utils";
import { Color } from "ivip-utils";
import { showNftCard } from "./ViewNFTCard";

export interface NftOwner {
	userId?: string;
	type: "creator" | "owner";
	nome?: string;
	fotoPerfil?: string;
	appliedDate: string;
	price: number;
	currencyId: string;
}

export interface NftCatalogItem {
	id: string;
	type: "chameleon";
	title: string;
	description?: string;
	color?: string;
	img?: string;
	originalImg?: string;
	isOwns: boolean;
	ownsOwner: boolean;
	private: boolean;
	available: boolean;
	creator: NftOwner | null;
	owner: NftOwner | null;
	previousOwners: {
		[id: string]: NftOwner;
	};
}

const setLightness = (color: Color, sum: number): Color => {
	const [h, s, l] = color.props.hsl;
	sum = sum < 0 ? 1 - Math.abs(sum) : 1 + sum;
	return new Color(Color.hsl(h, s, Math.min(100, Math.max(0, l * sum))).hex);
};

const NftCatalog: React.FC = () => {
	const { status, response, error } = APIHelper.useFetch<NftCatalogItem[]>({
		route: "/nft",
		method: "GET",
		immediate: true,
		delay: 2000,
		cacheExpiration: 25,
		handleResult: (response: NftCatalogItem[]) => {
			return response
				.map(({ title, id, description, ...props }) => {
					title = (title ?? "").trim() === "" ? `#${id}` : title;
					description = (description ?? "").trim() === "" ? "..." : description;
					return {
						title,
						description,
						id,
						...props,
					};
				})
				.sort((a, b) => {
					return a.isOwns
						? 1
						: b.isOwns
						? -1
						: a.ownsOwner || b.ownsOwner
						? a.ownsOwner
							? 1
							: b.ownsOwner
							? -1
							: 0
						: b.title.localeCompare(a.title);
				});
		},
	});

	const [defaultColor, setDefaultColor] = useState<{
		dark: string;
		light: string;
	}>({
		dark: "#00838f",
		light: "#80deea",
	});

	const [color, setColor] = useState<{
		dark: string;
		light: string;
	}>({
		dark: "#00838f",
		light: "#80deea",
	});

	const [ownerInfo, setOwnerInfo] = useState<NftCatalogItem>({
		id: "00000000000000000000",
		type: "chameleon",
		title: "NFT não encontrado!",
		description: "...",
		isOwns: false,
		ownsOwner: false,
		private: false,
		available: false,
		creator: null,
		owner: null,
		previousOwners: {},
	});

	const updateColor = useCallback(
		(color?: string) => {
			if (typeof color === "string") {
				setColor({
					dark: setLightness(typeof color === "string" ? new Color(color) : color, 0.3).hex,
					light: setLightness(typeof color === "string" ? new Color(color) : color, 0.15).hex,
				});
			} else {
				setColor(defaultColor);
			}
		},
		[defaultColor],
	);

	useEffect(() => {
		const { color } = ownerInfo;

		const palette =
			typeof color === "string" && color.trim() !== ""
				? {
						dark: setLightness(typeof color === "string" ? new Color(color) : color, 0.3).hex,
						light: setLightness(typeof color === "string" ? new Color(color) : color, 0.15).hex,
				  }
				: {
						dark: "#00838f",
						light: "#80deea",
				  };

		setDefaultColor(palette);
		updateColor(color);
	}, [ownerInfo]);

	useEffect(() => {
		updateColor();

		setOwnerInfo(() => {
			const defaultOwnerInfo: NftCatalogItem = {
				id: "00000000000000000000",
				type: "chameleon",
				title: "NFT não encontrado!",
				description: "...",
				isOwns: false,
				ownsOwner: false,
				private: false,
				available: false,
				creator: null,
				owner: null,
				previousOwners: {},
			};

			if (!(Array.isArray(response) && response.length > 0)) {
				return defaultOwnerInfo;
			}

			//return response[Math.round(response.length * Math.random())] as NftCatalogItem;

			return (response ?? []).find((item) => item.isOwns) ?? defaultOwnerInfo;
		});
	}, [status, response]);

	const colorPrimary = new Color(ownerInfo.color ?? "#00838f");
	const colorIsDark = colorPrimary.vector.reduce((a, b) => a + b, 0) / 3 <= 150;
	const colorText = colorIsDark ? colorPrimary.lighten(0.7) : colorPrimary.darken(0.7);

	return (
		<PageHeaderWrapper
			colorLight={color.light}
			colorDark={color.dark}
			isLoading={!Array.isArray(response)}
			title="Catálogo de NFTs"
			header={
				<div
					className={style["header"]}
					style={
						{
							"--color": ownerInfo?.color,
							"--text-color": colorText.vector.join(", "),
						} as React.CSSProperties & { [k: string]: string }
					}
				>
					<div
						className={style["nft-cart"]}
						style={
							{
								backgroundImage: `url(${ownerInfo?.originalImg})`,
							} as React.CSSProperties & { [k: string]: string }
						}
					>
						{!ownerInfo.originalImg && <SvgIcon path={mdiHelpRhombus} />}
					</div>
					<div className={style["nft-cart-info"]}>
						<div className={style["code"]}>{ownerInfo?.id ?? ""}</div>
						<div
							className={style["title"]}
							style={{ fontStyle: ownerInfo.isOwns ? undefined : "italic" }}
						>
							{ownerInfo?.title ?? ""}
						</div>
						<div className={style["description"]}>
							<Typography
								variant="body1"
								gutterBottom
							>
								Descrição:
							</Typography>
							<Typography variant="body2">{ownerInfo?.description ?? "..."}</Typography>
							<Typography
								variant="body1"
								gutterBottom
							>
								Data adquirido:
							</Typography>
							<Typography variant="body2">
								{ownerInfo?.owner?.appliedDate ? new Date(ownerInfo.owner.appliedDate).toLocaleString() : "..."}
							</Typography>
						</div>
						<div className={style["user-info"]}>
							<div
								className={style["picture"]}
								style={{
									backgroundImage: `url(${ownerInfo?.creator?.fotoPerfil ?? ""})`,
								}}
							></div>
							<div className={style["label"]}>
								<Typography variant="caption">Criador</Typography>
								<Typography variant="body1">{ownerInfo?.creator?.nome ?? "..."}</Typography>
							</div>
						</div>
					</div>
				</div>
			}
		>
			<div className={style["actions-bar"]}>
				<InputBase
					type="text"
					fullWidth
					sx={{ m: "0px", p: "10px" }}
					inputProps={{
						style: {
							margin: "0px",
							padding: "0px",
						},
					}}
					onChange={({ target: { value } }) => {}}
					placeholder="Procurar NFT..."
					disabled
				/>
				<IconButton
					sx={{ m: "2px", p: "10px" }}
					disabled
					onClick={() => {}}
				>
					<SvgIcon path={mdiMagnify} />
				</IconButton>
				<IconButton
					sx={{ m: "2px", p: "10px" }}
					disabled
					onClick={() => {}}
				>
					<SvgIcon path={mdiFilterMenu} />
				</IconButton>
			</div>
			<div className={style["nft-catalog"]}>
				{Array.isArray(response) &&
					response.map((props, index) => {
						const { ownsOwner, color, img, title, id, isOwns, owner, creator } = props;
						const textColor = (({ vector }) => (vector.reduce((a, b) => a + b, 0) / vector.length > 100 ? "#212121" : "#fafafa"))(
							new Color(color),
						);

						const date = new Date((isOwns ? owner?.appliedDate : creator?.appliedDate) ?? new Date().toISOString()).toLocaleDateString();

						return (
							<ModernCard
								color={color}
								image={img ?? ""}
								header={
									<>
										<Typography variant="h6">NFT {title ?? "..."}</Typography>
										<Typography
											variant="caption"
											sx={{
												opacity: 0.4,
											}}
										>
											Ultima aplicação: {date}
										</Typography>
										<div style={{ height: "10px" }}></div>
										<div className={style["nft-card-price"]}>{maskAmount(0, 0, "IVIP", "RTL")}</div>
									</>
								}
								style={{
									cursor: "pointer",
								}}
								onClick={() => showNftCard(props)}
							/>
						);

						return (
							<div
								key={index}
								className={[style["nft-card"], isOwns ? style["mark"] : ""].join(" ")}
								style={
									{
										"backgroundImage": `url(${img})`,
										"--color": color,
										"--color-rgb": new Color(color).vector.join(", "),
										"--text-color": textColor,
									} as React.CSSProperties & { [k: string]: string }
								}
								onMouseMove={() => updateColor(color)}
								onMouseOut={() => updateColor()}
								onClick={() => showNftCard(props)}
							>
								{ownsOwner && (
									<div className={style["ribbon"]}>
										<span>sold out</span>
									</div>
								)}
								<div className={style["nft-card-price"]}>{maskAmount(0, 0, "IVIP", "RTL")}</div>
								<div className={style["nft-card-title"]}>{title ?? "..."}</div>
							</div>
						);
					})}
			</div>
		</PageHeaderWrapper>
	);
};

export default NftCatalog;
