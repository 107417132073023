import { isJson, BezierEasing, Color } from "Utils";

const isInt = (n: number | string): boolean => {
	return Number(n) === n && n % 1 === 0;
};

const isFloat = (n: number | string): boolean => {
	return Number(n) === n && n % 1 !== 0;
};

const getNumber = (a: string) => {
	let r = { value: a, number: 0, type: "px", isFloat: false };
	let s = /([+-]?(?=\.\d|\d)(?:\d+)?((?:\.?\d*)(?:[eE][+-]?\d+)?))(px|em|cm|mm|in|pt|pc|%)/;

	if (typeof a === "number") {
		r.number = a;
		r.isFloat = isFloat(r.number);
		return r;
	} else if (typeof a === "string" && s.test(a)) {
		let v = a.match(s) ?? ["0px", "0", "px"];
		r.number = parseFloat(v[1]);
		r.type = v[3] === "%" ? "percent" : v[3];
		r.isFloat = isFloat(r.number);
		return r;
	}
	return null;
};

export default class Animation {
	private style: { [key: string]: { [key: string]: { value: Color | ReturnType<typeof getNumber> | number | string; type: string } } };

	constructor(style: { [key: string]: { [key: string]: string } }) {
		console.log(Color.isColor("#00ff00"));
		console.log(Color.isColor("red"));
		console.log(Color.isColor("undefined"));

		console.log(getNumber(".5px"));

		this.style = {};

		this.setAnimation(style);
	}

	setAnimation(style: { [key: string]: { [key: string]: string } }) {
		if (typeof style !== "object") {
			return;
		}
		let allKeys = Object.keys(style);

		if (allKeys.includes("from") && allKeys.includes("to")) {
			style["0%"] = Object.assign({}, style["from"]);
			style["100%"] = Object.assign({}, style["to"]);
			allKeys = Object.keys(style);
		}

		const keys = allKeys.filter((e) => {
			let a = getNumber(e);
			return a && a.type === "percent";
		});

		if (keys.length > 0) {
			keys.forEach((k) => {
				this.style[k] = {};
				Object.entries(style[k]).forEach(([propertie, value]) => {
					let type = "static";
					let v: Color | ReturnType<typeof getNumber> | number | string = value;

					if (Color.isColor(value)) {
						type = "color";
						v = new Color(value);
					} else if (getNumber(value) !== null) {
						type = "number";
						v = getNumber(value);
					}

					this.style[k][propertie] = { value: v, type };
				});
			});
		}
	}
}
