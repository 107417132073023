import React from "react";
import { PageHeaderWrapper } from "Components";
import ProfileInfo from "../../Components/ProfileInfo";
import ListSections from "../../Components/ListSections";

const Home: React.FC = () => {
	return (
		<PageHeaderWrapper
			colorDark="#00897b"
			colorLight="#4db6ac"
			title="Quiz Educacional"
			header={<ProfileInfo />}
		>
			<ListSections />
		</PageHeaderWrapper>
	);
};

export default Home;
