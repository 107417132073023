import { InteractionHelper } from "Helper";
import React from "react";

export interface PhaseProps<P extends Object, R = any> {
	state: AllProps<P>;
	toPhase: (index?: number) => void;
	nextPhase: () => void;
	previousPhase: (error?: any) => void;
	resolve: (resolve?: R) => void;
	reject: (error?: any) => void;
	setTitle: (title: string) => void;
	setDescription: (description: string) => void;
	currentStage: number;
	lengthStage: number;
}

type AllProps<P extends Object> = P & { toJson: () => P };

type ReactNode<
	P extends Object,
	R = any,
	T extends React.ReactElement<PhaseProps<P, R>> | null | undefined | void = React.ReactElement<PhaseProps<P, R>> | null | undefined | void,
> = (state: PhaseProps<P, R>) => Promise<T | R> | T | R;

type PhaseProcessDialog<P extends Object, R = any> = {
	id?: string;
	phase: ReactNode<P, R>;
	title?: string;
	description?: string;
	term?: string;
	btnNames?: [string] | [string, string];
	phases?: number;
	type?: "confirm" | "alert";
};

type PhaseProcess<P extends Object, R = any> = ReactNode<AllProps<P>, R> | PhaseProcessDialog<AllProps<P>, R>;

class PromiseStructure<R = any> {
	private response: R | undefined = undefined;
	private error: Error | undefined = undefined;
	private status: "pending" | "resolved" | "rejected" = "pending";
	private callbackThen: ((data: R) => any) | null = null;
	private callbackCatch: ((error: Error) => any) | null = null;
	private callbackFinally: (() => void) | null = null;
	private nextPromise: PromiseStructure | null = null;

	clear() {
		this.response = undefined;
		this.error = undefined;
		this.status = "pending";
		this.callbackThen = null;
		this.callbackCatch = null;
		this.callbackFinally = null;
		this.nextPromise = null;
	}

	resolve(resolve: R) {
		this.response = resolve;
		this.status = "resolved";
		try {
			if (this.callbackThen !== null) {
				const result = this.callbackThen(resolve);
				if (result instanceof Promise) {
					result
						.then((data) => {
							if (this.nextPromise !== null) {
								this.nextPromise.resolve(data);
							}
						})
						.catch((error) => {
							if (this.nextPromise !== null) {
								this.nextPromise.reject(error);
							}
						});
				} else if (this.nextPromise !== null) {
					this.nextPromise.resolve(result);
				}
			}
		} catch (e) {
			this.reject(new Error(e as any));
			return;
		}
		this.finish();
	}

	reject(error?: Error) {
		this.error = error;
		this.status = "rejected";
		if (this.callbackCatch !== null) {
			const result = this.callbackCatch(error as any);
			if (result instanceof Promise) {
				result
					.then((data) => {
						if (this.nextPromise !== null) {
							this.nextPromise.resolve(data);
						}
					})
					.catch((error) => {
						if (this.nextPromise !== null) {
							this.nextPromise.reject(error);
						}
					});
			}
		} else if (this.nextPromise !== null) {
			this.nextPromise.reject(error);
		}
		this.finish();
	}

	finish() {
		if (this.callbackFinally !== null) {
			this.callbackFinally();
		} else if (this.nextPromise !== null) {
			this.nextPromise.finish();
		}
	}

	then<T = any>(callback: (data: R) => T) {
		this.callbackThen = callback;
		const next = new PromiseStructure<T>();
		this.nextPromise = next;
		if (this.status !== "pending") {
			if (this.response !== undefined) {
				this.resolve(this.response);
			} else if (this.error !== undefined) {
				this.reject(this.error);
			} else {
				this.finish();
			}
		}
		return next;
	}

	catch<T = any>(callback: (error: Error) => T) {
		this.callbackCatch = callback;
		const next = new PromiseStructure<T>();
		this.nextPromise = next;
		if (this.status !== "pending") {
			if (this.response !== undefined) {
				this.resolve(this.response);
			} else if (this.error !== undefined) {
				this.reject(this.error);
			} else {
				this.finish();
			}
		}
		return next;
	}

	finally(callback: () => void) {
		this.callbackFinally = callback;
	}
}

export class Structure<
	P extends object = {
		[p: string]: any;
	},
	R = any,
> extends PromiseStructure<R> {
	private currentIndex: number = 0;
	private currentPhase: number = 0;
	private state: P;
	private resultResolve: R | undefined = undefined;
	private phasesLength: number = 0;
	private ids: Array<{ id: number | string; phases: number }> = [];

	constructor(private title: string, private initialState: P, readonly phases: PhaseProcess<P, R>[], private description: string = "") {
		super();
		this.state = this.initialState;
		this.phasesLength = phases.reduce((c, v) => c + ("phases" in v ? Math.max(1, v.phases ?? 1) : 1), 0);
		this.ids = phases.map((v, i) => {
			const id = typeof v === "object" && "id" in v ? v.id ?? i : i;
			return {
				id,
				phases: "phases" in v ? Math.max(1, v.phases ?? 1) : 1,
			};
		});
	}

	private async toPhase(index: number = 0, error: any = undefined) {
		if (index > this.phasesLength - 1) {
			this.resolve(this.resultResolve as R);
			return;
		}

		InteractionHelper.loading();

		await new Promise((resolve) => setTimeout(resolve, 500));

		this.currentPhase = Math.max(0, Math.min(index, this.phasesLength - 1));

		if (error) {
			InteractionHelper.toast(String(error), null, "warning");
		}

		const underObservation: AllProps<P> = new Proxy(this.state, {
			set: (target, prop, value, receiver) => {
				this.state[prop as keyof P] = value;
				return Reflect.set(this.state, prop, value, receiver);
			},
			get: (target, prop, receiver) => {
				if (prop === "toJson") {
					return () => JSON.parse(JSON.stringify(this.state));
				}
				return Reflect.get(target, prop, receiver);
			},
		}) as AllProps<P>;

		this.currentIndex = ((phases) => {
			let c = 0;
			for (let i = 0; i < phases.length; i++) {
				c += "phases" in phases[i] ? Math.max(1, (phases[i] as any).phases ?? 1) : 1;
				if (c > this.currentPhase) {
					return i;
				}
			}
			return phases.length - 1;
		})(this.phases);

		const {
			phase,
			title,
			description,
			term,
			btnNames,
			phases,
			type = "confirm",
		}: PhaseProcessDialog<P> = typeof this.phases[this.currentIndex] === "object"
			? (this.phases[this.currentIndex] as any)
			: ({ phase: this.phases[this.currentIndex] } as any);

		const currentStage =
			this.currentPhase -
			this.phases.reduce((c, v, i) => c + (i < this.currentIndex ? ("phases" in v ? Math.max(1, v.phases ?? 1) : 1) : 0), 0);

		const lengthStage = phases ?? 0;

		const body = await phase({
			state: underObservation,
			toPhase: this.toPhase.bind(this),
			nextPhase: this.nextPhase.bind(this),
			previousPhase: this.previousPhase.bind(this),
			resolve: (r: R) => {
				InteractionHelper.close();
				this.resolve.apply(this, [r]);
			},
			reject: (e?: Error) => {
				InteractionHelper.close();
				this.reject.apply(this, [e ?? new Error("Reject")]);
			},
			setTitle: this.setTitle.bind(this),
			setDescription: this.setDescription.bind(this),
			currentStage,
			lengthStage,
		});

		// if (Array.isArray(body)) {
		// 	InteractionHelper.close();
		// 	try {
		// 		for (let i = 0; i < body.length; i++) {
		// 			if (!React.isValidElement(body[i])) {
		// 				continue;
		// 			}
		// 			await InteractionHelper.confirm(body[i], title ?? this.title, i === body.length - 1 ? term : undefined);
		// 		}
		// 		this.nextPhase();
		// 	} catch (e) {
		// 		this.resolve(this.resultResolve as R);
		// 	}
		// } else
		if (body && (Array.isArray(body) || React.isValidElement(body))) {
			InteractionHelper.close();
			if (type === "confirm") {
				InteractionHelper.confirm(
					Array.isArray(body) ? body.filter((v) => React.isValidElement(v)) : body,
					title ?? this.title,
					term,
					btnNames,
				)
					.then(() => {
						this.nextPhase();
					})
					.catch((e) => {
						this.resolve(this.resultResolve as R);
					});
			} else {
				InteractionHelper.alert(Array.isArray(body) ? body.filter((v) => React.isValidElement(v)) : body, title ?? this.title)
					.then(() => {
						this.nextPhase();
					})
					.catch((e) => {
						this.resolve(this.resultResolve as R);
					});
			}
		} else if (body instanceof Promise) {
			body.then((e) => {
				this.resultResolve = e;
				this.nextPhase();
			}).catch((e) => {
				this.reject(e);
			});
		} else if (body) {
			this.resultResolve = body;
		}
	}

	private setTitle(title: string) {
		this.title = title;
	}

	private setDescription(description: string) {
		this.description = description;
	}

	private nextPhase() {
		this.toPhase(this.currentPhase + 1);
	}

	private previousPhase(error: any = undefined) {
		this.toPhase(this.currentPhase - 1, error);
	}

	show(state: Partial<P>) {
		super.clear();
		this.state = Object.assign({}, this.initialState, state);
		this.toPhase(0);
		return this;
	}
}
