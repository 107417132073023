import React, { Component } from 'react';

import "./style.css";

export default class CropImage extends Component {
	constructor(props){
        super(props);
        this.state = {
        	image: {
        		top: 0,
        		left: 0,
        		width: 150,
        		height: 150,
        		originalWidth: 150,
        		originalHeight: 150
        	},
        	crop: {
        		top: 0,
        		left: 0,
        		width: 150,
        		height: 150,
        		originalWidth: 150,
        		originalHeight: 150,
        		autoRatio: true
        	},
        	isLoading: true
        }

        this.CropImage_canvas_back = React.createRef();
        this.CropImage_canvas_front = React.createRef();
        this.CropImage_crop = React.createRef();

        this.CropImage_container = React.createRef();
        this.CropImage_face_move = React.createRef();
        this.CropImage_line_e = React.createRef();
        this.CropImage_line_n = React.createRef();
        this.CropImage_line_w = React.createRef();
        this.CropImage_line_s = React.createRef();
        this.CropImage_point_e = React.createRef();
        this.CropImage_point_n = React.createRef();
        this.CropImage_point_w = React.createRef();
        this.CropImage_point_s = React.createRef();
        this.CropImage_point_ne = React.createRef();
        this.CropImage_point_nw = React.createRef();
        this.CropImage_point_sw = React.createRef();
        this.CropImage_point_se = React.createRef();

        this.image = new window.Image();

        this.imageMargin = 15;
    }

    componentDidMount(){
    	let config = this.props.config || {};

    	this.image.onload = (img)=>{
    		this.state.image.originalWidth = this.image.width;
    		this.state.image.originalHeight = this.image.height;

    		let width = this.image.width, height = this.image.height, 
    			maxSize = Math.max(this.image.width, this.image.height), 
    			minSize = Math.min(this.image.width, this.image.height);

    		if(config.aspectRatio && typeof config.aspectRatio === "string" && (/^([0-9]+):([0-9]+)$/gi).test(config.aspectRatio)){
    			let aspectRatio = config.aspectRatio.split(":").map(n=>Number(n));
    			if(aspectRatio[0] >= aspectRatio[1]){
    				width = minSize;
    				height = minSize*(aspectRatio[1]/aspectRatio[0]);
    			}else{
    				height = minSize;
    				width = minSize*(aspectRatio[0]/aspectRatio[1]);
    			}

    			this.state.crop.autoRatio = false;
	    		this.state.crop.originalWidth = width;
	    		this.state.crop.originalHeight = height;
    		}

    		this.state.isLoading = false;
    		this.updatePosImage(true);

    		/*this.CropImage_container.current.addEventListener('resize', ()=>{
    			this.updatePosImage();
    		});*/

    		window.addEventListener('resize', ()=>{
    			this.updatePosImage();
    		});
    	}

    	this.image.src = this.props.src;
    	this.addEventListeners();
    }

    updatePosImage = (isInitial)=>{
    	isInitial = isInitial !== true ? false : true;

    	const CropImage_container = this.CropImage_container.current;

    	if(!CropImage_container 
    		|| !(typeof CropImage_container.clientWidth === "number") 
    		|| !(typeof CropImage_container.offsetWidth === "number") 
    		|| !(typeof CropImage_container.clientHeight === "number") 
    		|| !(typeof CropImage_container.offsetHeight === "number")
    	){
    		return;
    	}

    	let width = CropImage_container.clientWidth || CropImage_container.offsetWidth, 
    		height = CropImage_container.clientHeight || CropImage_container.offsetHeight;

    	const lastWidth = this.state.image.width, lastHeight = this.state.image.height;

    	this.state.image.top = this.imageMargin;
		this.state.image.height = height-(this.state.image.top*2);

		this.state.image.width = (this.state.image.originalWidth*this.state.image.height)/this.state.image.originalHeight;
		this.state.image.left = (width-this.state.image.width)/2;

    	if(this.state.image.width > width){
    		this.state.image.left = this.imageMargin;
    		this.state.image.width = width-(this.state.image.left*2);

    		this.state.image.height = (this.state.image.originalHeight*this.state.image.width)/this.state.image.originalWidth;
    		this.state.image.top = (height-this.state.image.height)/2;
    	}

    	if(isInitial){
			this.state.crop.top = 0;
			this.state.crop.height = this.state.image.height;

			this.state.crop.width = (this.state.crop.originalWidth*this.state.crop.height)/this.state.crop.originalHeight;
			this.state.crop.left = (this.state.image.width-this.state.crop.width)/2;

    		if(this.state.crop.width >= this.state.image.width){
    			this.state.crop.left = 0;
    			this.state.crop.width = this.state.image.width;

    			this.state.crop.height = (this.state.crop.originalHeight*this.state.crop.width)/this.state.crop.originalWidth;
    			this.state.crop.top = (this.state.image.height-this.state.crop.height)/2;
    		}

    		this.setState({}, ()=>{
	    		this.renderRatio();
	    		this.onChange();
	    	});
    	}else{
    		this.state.crop.top = (this.state.crop.top*this.state.image.height)/lastHeight;
    		this.state.crop.left = (this.state.crop.left*this.state.image.width)/lastWidth;
    		this.state.crop.height = (this.state.crop.height*this.state.image.height)/lastHeight;
    		this.state.crop.width = (this.state.crop.width*this.state.image.width)/lastWidth;
    	}

    	this.renderRatio();
    }

    addEventListeners = ()=>{
    	const refs = {
    		"CropImage_face_move": "faceMove",
    		"CropImage_line_e": "line_e",
    		"CropImage_line_n": "line_n",
    		"CropImage_line_w": "line_w",
    		"CropImage_line_s": "line_s",
    		"CropImage_point_e": "line_e",
    		"CropImage_point_n": "line_n",
    		"CropImage_point_w": "line_w",
    		"CropImage_point_s": "line_s",
    		"CropImage_point_ne": "point_ne",
    		"CropImage_point_nw": "point_nw",
    		"CropImage_point_sw": "point_sw",
    		"CropImage_point_se": "point_se",
    	};
    	const events = {
    		"mousedown": "onFaceMoveStart", 
    		"touchstart": "onFaceMoveStart", 
    	};

    	for(let ref in refs){
    		for(let event in events){
    			this[ref].current.addEventListener(event, (e)=>{
    				if(String(event).search("touch") >= 0){
    					this[events[event]](refs[ref], e.touches[0].pageX, e.touches[0].pageY, e);
    				}else{
    					this[events[event]](refs[ref], e.pageX, e.pageY, e);
    				}
    			});
    		}
    	}

    	const mainEvents = {
    		"mouseup": "onFaceMoveEnd", 
    		"mousemove": "onFaceMoveUpdatePosition", 
    		"touchend": "onFaceMoveEnd", 
    		"touchmove": "onFaceMoveUpdatePosition", 
    	};

		for(let event in mainEvents){
	    	document.addEventListener(event, (e)=>{
				if(String(event).search("touch") >= 0){
					this[mainEvents[event]](e.touches[0].pageX, e.touches[0].pageY, e);
				}else{
					this[mainEvents[event]](e.pageX, e.pageY, e);
				}
			});
	    }
    }

    eventListenerEditor = {
    	x: 0,
    	y: 0,
    	type: "",
    	mouseDown: false,
    	faceMove: (deltaX, deltaY)=>{
			let toX = this.state.crop.left - deltaX, 
    			toY = this.state.crop.top - deltaY;

	    	this.state.crop.left = toX <= 0 ? 0 : toX+this.state.crop.width >= this.state.image.width ? (this.state.image.width-this.state.crop.width) : toX;

	    	this.state.crop.top = toY <= 0 ? 0 : toY+this.state.crop.height >= this.state.image.height ? (this.state.image.height-this.state.crop.height) : toY;
    	},
    	line_e: (deltaX, deltaY)=>{
    		let toX = (this.state.crop.left+this.state.crop.width) - deltaX;

    		let width = (toX - this.state.crop.left) <= 2 ? 2 : toX >= this.state.image.width ? (this.state.image.width - this.state.crop.left) : (toX - this.state.crop.left);

    		if(this.state.crop.autoRatio !== true){
    			let height = (this.state.crop.height*width)/this.state.crop.width,
    				top = this.state.crop.top+((this.state.crop.height-height)/2);
                
                top = height+top >= this.state.image.height ? this.state.image.height - height : top;
                top = top <= 0 ? 0 : top;

    			if(height < 25 || height+top > this.state.image.height){
    				return;
    			}

    			this.state.crop.top = top;
    			this.state.crop.height = height;
    		}

    		if(width < 25){return;}
    		this.state.crop.width = width;
    	},
    	line_n: (deltaX, deltaY)=>{
    		let toY = this.state.crop.top - deltaY;

    		let top = toY <= 0 ? 0 : this.state.crop.height-(toY-this.state.crop.top) <= 3 ? this.state.crop.top : toY;

    		let height = this.state.crop.height - (top - this.state.crop.top);

    		if(this.state.crop.autoRatio !== true){
    			let width = (this.state.crop.width*height)/this.state.crop.height,
    				left = this.state.crop.left+((this.state.crop.width-width)/2);

                left = width+left >= this.state.image.width ? this.state.image.width - width : left;
                left = left <= 0 ? 0 : left;

    			if(width < 25 || width+left > this.state.image.width){
    				return;
    			}
    			this.state.crop.left = left;
    			this.state.crop.width = width;
    		}

    		if(height < 25){return;}
    		this.state.crop.top = top;
    		this.state.crop.height = height;
    	},
    	line_w: (deltaX, deltaY)=>{
    		let toX = this.state.crop.left - deltaX;

    		let left = toX <= 0 ? 0 : this.state.crop.width-(toX-this.state.crop.left) <= 3 ? this.state.crop.left : toX;

    		let width = this.state.crop.width - (left - this.state.crop.left);

    		if(this.state.crop.autoRatio !== true){
    			let height = (this.state.crop.height*width)/this.state.crop.width,
    				top = this.state.crop.top+((this.state.crop.height-height)/2);

                top = height+top >= this.state.image.height ? this.state.image.height - height : top;
                top = top <= 0 ? 0 : top;

    			if(height < 25 || height+top > this.state.image.height){
    				return;
    			}
    			this.state.crop.top = top;
    			this.state.crop.height = height;
    		}

    		if(width < 25){return;}
    		this.state.crop.left = left;
    		this.state.crop.width = width;
    	},
    	line_s: (deltaX, deltaY)=>{
    		let toY = (this.state.crop.top+this.state.crop.height) - deltaY;

    		let height = (toY - this.state.crop.top) <= 2 ? 2 : toY >= this.state.image.height ? (this.state.image.height - this.state.crop.top) : (toY - this.state.crop.top);

    		if(this.state.crop.autoRatio !== true){
    			let width = (this.state.crop.width*height)/this.state.crop.height,
    				left = this.state.crop.left+((this.state.crop.width-width)/2);

                left = width+left >= this.state.image.width ? this.state.image.width - width : left;
                left = left <= 0 ? 0 : left;

    			if(width < 25 || width+left > this.state.image.width){
    				return;
    			}
    			this.state.crop.left = left;
    			this.state.crop.width = width;
    		}

    		if(height < 25){return;}
    		this.state.crop.height = height;
    	},
    	point_ne: (deltaX, deltaY)=>{
    		let toX = (this.state.crop.left+this.state.crop.width) - deltaX, 
    			toY = this.state.crop.top - deltaY;

    		let top = toY <= 0 ? 0 : this.state.crop.height-(toY-this.state.crop.top) <= 3 ? this.state.crop.top : toY;

    		let height = this.state.crop.height - (top - this.state.crop.top);

    		if(this.state.crop.autoRatio !== true){
    			let width = (toX - this.state.crop.left) <= 2 ? 2 : toX >= this.state.image.width ? (this.state.image.width - this.state.crop.left) : (toX - this.state.crop.left);

    			width = this.state.crop.autoRatio === true ? width : (this.state.crop.width*height)/this.state.crop.height;

    			if(width < 25 || (this.state.crop.left+width) >= this.state.image.width){
    				return;
    			}
    			this.state.crop.width = width;
    		}

    		if(height < 25){return;}
    		this.state.crop.top = top;
    		this.state.crop.height = height;
    	},
    	point_nw: (deltaX, deltaY)=>{
    		let toX = this.state.crop.left - deltaX, 
    			toY = this.state.crop.top - deltaY;

    		let left = toX <= 0 ? 0 : this.state.crop.width-(toX-this.state.crop.left) <= 3 ? this.state.crop.left : toX;

    		let top = toY <= 0 ? 0 : this.state.crop.height-(toY-this.state.crop.top) <= 3 ? this.state.crop.top : toY;

    		let width = this.state.crop.width - (left - this.state.crop.left);

    		let height = this.state.crop.height - (top - this.state.crop.top);

    		if(this.state.crop.autoRatio !== true){
    			width = (this.state.crop.width*height)/this.state.crop.height;
	    		left = this.state.crop.left+(this.state.crop.width-width);

	    		if(left < 0 || (left+width) > this.state.image.width){
	    			return;
	    		}
    		}

    		if(height >= 25){
    			this.state.crop.top = top;
    			this.state.crop.height = height;
    		}

			if(width >= 25){
	    		this.state.crop.left = left;
	    		this.state.crop.width = width;
	    	}
    	},
    	point_sw: (deltaX, deltaY)=>{
			let toX = this.state.crop.left - deltaX,
    			toY = (this.state.crop.top+this.state.crop.height) - deltaY;

			let left = toX <= 0 ? 0 : this.state.crop.width-(toX-this.state.crop.left) <= 3 ? this.state.crop.left : toX;

    		let width = this.state.crop.width - (left - this.state.crop.left);

    		let height = (toY - this.state.crop.top) <= 2 ? 2 : toY >= this.state.image.height ? (this.state.image.height - this.state.crop.top) : (toY - this.state.crop.top);

    		height = this.state.crop.autoRatio === true ? height : (this.state.crop.height*width)/this.state.crop.width;

    		if(this.state.crop.autoRatio !== true && (this.state.crop.top+height) >= this.state.image.height){
    			return;
    		}

    		if(height >= 25){
    			this.state.crop.height = height;
    		}

			if(width >= 25){
	    		this.state.crop.left = left;
	    		this.state.crop.width = width;
	    	}
    	},
    	point_se: (deltaX, deltaY)=>{
    		let toX = (this.state.crop.left+this.state.crop.width) - deltaX, 
    			toY = (this.state.crop.top+this.state.crop.height) - deltaY;

    		let width = (toX - this.state.crop.left) <= 2 ? 2 : toX >= this.state.image.width ? (this.state.image.width - this.state.crop.left) : (toX - this.state.crop.left);

    		let height = (toY - this.state.crop.top) <= 2 ? 2 : toY >= this.state.image.height ? (this.state.image.height - this.state.crop.top) : (toY - this.state.crop.top);

    		height = this.state.crop.autoRatio === true ? height : (this.state.crop.height*width)/this.state.crop.width;

    		if(this.state.crop.autoRatio !== true && (this.state.crop.top+height) >= this.state.image.height){
    			return;
    		}

    		if(height >= 25){
    			this.state.crop.height = height;
    		}

			if(width >= 25){
	    		this.state.crop.width = width;
	    	}
    	}
    }

    onFaceMoveStart = (type_, x, y, e)=>{
    	this.eventListenerEditor.x = x;
    	this.eventListenerEditor.y = y;
    	this.eventListenerEditor.type = type_;
    	this.eventListenerEditor.mouseDown = true;
    	if(e.stopPropagation) e.stopPropagation();
	    if(e.nativeEvent && e.nativeEvent.stopImmediatePropagation) e.nativeEvent.stopImmediatePropagation();
	    if(e.preventDefault) e.preventDefault();
    }

    onFaceMoveEnd = ()=>{
    	this.eventListenerEditor.type = "";
    	this.eventListenerEditor.mouseDown = false;
    	this.onChange();
    }

    onFaceMoveUpdatePosition = (x, y, e)=>{
    	if(this.eventListenerEditor.mouseDown !== true){return;}
    	let type_ = this.eventListenerEditor.type,
    		deltaX = this.eventListenerEditor.x - x, 
    		deltaY = this.eventListenerEditor.y - y;

    	if(type_ in this.eventListenerEditor){
    		this.eventListenerEditor[type_](deltaX, deltaY);
    	}

    	this.eventListenerEditor.x = x;
    	this.eventListenerEditor.y = y;

    	this.renderRatio();
    }

    renderRatio = ()=>{
    	this.CropImage_canvas_back.current.style.top = this.state.image.top+"px";
    	this.CropImage_canvas_back.current.style.left = this.state.image.left+"px"; 
    	this.CropImage_canvas_back.current.style.width = this.state.image.width+"px";
    	this.CropImage_canvas_back.current.style.height = this.state.image.height+"px";

    	this.CropImage_crop.current.style.top = (this.state.crop.top+this.state.image.top)+"px";
    	this.CropImage_crop.current.style.left = (this.state.crop.left+this.state.image.left)+"px";
    	this.CropImage_crop.current.style.width = this.state.crop.width+"px";
    	this.CropImage_crop.current.style.height = this.state.crop.height+"px";

    	this.CropImage_canvas_front.current.style.top = (-this.state.crop.top)+"px";
    	this.CropImage_canvas_front.current.style.left = (-this.state.crop.left)+"px";
    	this.CropImage_canvas_front.current.style.width = this.state.image.width+"px";
    	this.CropImage_canvas_front.current.style.height = this.state.image.height+"px";
    }

    onChange = ()=>{
    	if(!this.props.onChange || typeof this.props.onChange !== "function"){return;}
    	let config = this.props.config || {};

    	let top = (this.state.image.originalHeight*this.state.crop.top)/this.state.image.height,
    		left = (this.state.image.originalWidth*this.state.crop.left)/this.state.image.width,
    		width = (this.state.image.originalHeight*this.state.crop.width)/this.state.image.height,
    		height = (this.state.image.originalHeight*this.state.crop.height)/this.state.image.height;

    	let can = window.document.createElement("canvas"),
    	ctx = can.getContext("2d");
    	can.width = width;
    	can.height = height;

    	ctx.drawImage(this.image, -left, -top, this.state.image.originalWidth, this.state.image.originalHeight);

    	let imageSize = config.imageSize && typeof config.imageSize === "number" ? config.imageSize : Math.max(width, height);

    	imageSize = imageSize/Math.max(width, height);

    	let can2 = window.document.createElement("canvas"),
    	ctx2 = can2.getContext("2d");
    	can2.width = width*imageSize;
    	can2.height = height*imageSize;

    	ctx2.drawImage(can, 0, 0, can2.width, can2.height);
    	this.props.onChange(can2.toDataURL());
    }

    render(){
    	let {style} = this.props;

    	return (<div className={"CropImage-main"} style={style}>
    		<div className={"CropImage-container"+(this.state.isLoading ? " loading" : "")} ref={this.CropImage_container}>
    			<div className={"CropImage-wrap"}>
    				<div className="CropImage-canvas" ref={this.CropImage_canvas_back} style={{backgroundImage: "url("+this.props.src+")"}}></div>
    			</div>
    			<div className="CropImage-drag"></div>
    			<div className="CropImage-crop" ref={this.CropImage_crop}>
	    			<div className="CropImage-view">
	    				<div className="CropImage-canvas" ref={this.CropImage_canvas_front} style={{backgroundImage: "url("+this.props.src+")"}}></div>
	    			</div>
	    			<div className="CropImage-dashed dashed-h"></div>
	    			<div className="CropImage-dashed dashed-v"></div>
	    			<div className="CropImage-center"></div>
	    			<div className="CropImage-face CropImage-move" ref={this.CropImage_face_move}></div>
	    			<div className="CropImage-line line-e" ref={this.CropImage_line_e}></div>
	    			<div className="CropImage-line line-n" ref={this.CropImage_line_n}></div>
	    			<div className="CropImage-line line-w" ref={this.CropImage_line_w}></div>
	    			<div className="CropImage-line line-s" ref={this.CropImage_line_s}></div>
	    			<div className="CropImage-point point-e" ref={this.CropImage_point_e}></div>
	    			<div className="CropImage-point point-n" ref={this.CropImage_point_n}></div>
	    			<div className="CropImage-point point-w" ref={this.CropImage_point_w}></div>
	    			<div className="CropImage-point point-s" ref={this.CropImage_point_s}></div>
	    			<div className="CropImage-point point-ne" ref={this.CropImage_point_ne}></div>
	    			<div className="CropImage-point point-nw" ref={this.CropImage_point_nw}></div>
	    			<div className="CropImage-point point-sw" ref={this.CropImage_point_sw}></div>
	    			<div className="CropImage-point point-se" ref={this.CropImage_point_se}></div>
    			</div>
    		</div>
    	</div>);
    }
}