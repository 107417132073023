import React, { useState, useEffect, useLayoutEffect, useCallback, useMemo, useRef, useReducer } from "react";

import { useParams, useLocation, useHistory as _useHistory, useRouteMatch } from "react-router-dom";

import _history, { getHistoryCurrentIndex, getHistoryLentgth } from "../../routerHistory";

import { matchPath, normalizePath, uniqueid } from "Utils/Global";

import queryString, { stringify } from "query-string";

import MultiStorager, { Storager } from "../MultiStorager";

const storage_ids: string[] = [];

export const useId = () => {
	const [id] = useState(
		(() => {
			let id = uniqueid(16);
			while (storage_ids.includes(id)) {
				id = uniqueid(16);
			}
			return id;
		})(),
	);
	return id;
};

/**
 * Observa as alterações em um objeto e executa uma função de retorno quando ocorre uma mudança.
 *
 * @template Props - O tipo do objeto a ser observado.
 * @param {Props} props - O objeto a ser observado.
 * @return {[Props, Function]} - Um array contendo o objeto observado e uma função para definir a função de retorno a ser executada.
 *
 * @example
 * // Uso típico:
 * const [observedObject, setOnChange] = useObserveObjectChange({ prop1: 'valor1', prop2: 'valor2' });
 *
 * // Definindo uma função de retorno para observar mudanças:
 * setOnChange((target) => {
 *   console.log('Objeto foi alterado:', target);
 * });
 *
 * // Modificando o objeto observado (isso disparará a função de retorno):
 * observedObject.prop1 = 'novoValor';
 */
export const useObserveObjectChange = <Props extends object>(
	props: Props,
): [Props, React.Dispatch<React.SetStateAction<((target: Props) => void) | null>>] => {
	const [byOnChange, onChange] = useState<((target: Props) => void) | null>(null);

	const bySet = useCallback(
		(target: Props, property: string | symbol, value: any, receiver: any): boolean => {
			(target as any)[property] = value;
			if (typeof byOnChange === "function") {
				byOnChange(target);
			}
			return true;
		},
		[byOnChange],
	);

	const state: Props = new Proxy<Props>(typeof props === "object" && props !== null ? props : ({} as Props), {
		set: bySet as any,
	});

	return [state, onChange];
};

/**
 * Hook que executa um callback após um determinado tempo de espera (delay),
 * mas só executa uma vez, mesmo que as dependências mudem antes do fim do delay.
 *
 * @param {function} callback - A função a ser executada após o delay.
 * @param {number} delay - O tempo de espera em milissegundos antes de executar o callback.
 * @param {Array} dependencies - Um array de dependências que, se modificadas, fazem com que a execução do callback seja reiniciada.
 * @returns {void}
 */
export const useDebounceCallbackEffect = (callback: () => void, delay: number, dependencies: any[]) => {
	const callbackBy = useCallback(() => {
		callback();
	}, [callback]);

	useEffect(() => {
		const handler = setTimeout(callbackBy, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [...dependencies, delay]);
};

/**
 * Função para manipular classes CSS dinamicamente.
 *
 * @template Instance - O tipo do objeto que contém as classes CSS.
 * @param {Instance} classes - Objeto contendo os nomes das classes CSS e seus valores booleanos para ativar/desativar a classe.
 * @returns {object} - Retorna um objeto com as propriedades className, push e remove.
 *   - A propriedade className é uma string contendo as classes CSS que estão com valor true.
 *   - A função push permite adicionar dinamicamente classes CSS ao objeto.
 *   - A função remove permite remover dinamicamente classes CSS do objeto.
 *
 * @example
 * const { className, push, remove } = useClassNames({
 *   active: true,
 *   disabled: false,
 *   highlighted: true,
 * });
 *
 * // className agora contém: "active highlighted"
 *
 * push("new-class");
 * // className agora contém: "active highlighted new-class"
 *
 * remove("highlighted");
 * // className agora contém: "active new-class"
 */
export const useClassNames = <Instance = { [property: string]: any }>(classes: Instance) => {
	const [classNames, setClassNames] = useState<Instance>(typeof classes === "object" ? classes : ({} as Instance));

	const getClassName = useCallback(() => {
		return Object.entries(classNames as any)
			.filter(([key, value]) => typeof value === "boolean" && value)
			.map(([key, value]) => key)
			.join(" ");
	}, [classNames]);

	const push = useCallback((className: string) => {
		if (typeof className !== "string") {
			return;
		}

		setClassNames((props) => {
			return { ...props, [className]: true };
		});
	}, []);

	const remove = useCallback((className: string) => {
		if (typeof className !== "string") {
			return;
		}

		setClassNames((props) => {
			return { ...props, [className]: false };
		});
	}, []);

	return {
		className: getClassName(),
		push,
		remove,
	};
};

/**
 * Cria um hook para gerenciar o estado de um valor em um armazenamento (storage) persistente, como local storage ou session storage.
 *
 * @param {string} key - A chave para armazenar o valor no storage.
 * @param {any} defaultValue - O valor padrão para ser utilizado caso não exista um valor armazenado para a chave.
 * @param {Storager} storager - O objeto de armazenamento que deve ser utilizado. Se não for passado, será utilizado o armazenamento padrão do aplicativo.
 *
 * @returns {Object} - Um objeto contendo o valor armazenado e uma função para atualizar esse valor.
 *
 * @example
 * // Exemplo de uso:
 * const { value, setValue } = useStorager('user', { name: 'John' }, localStorage);
 * console.log(value); // { name: 'John' }
 * setValue({ name: 'Alice' }); // Atualiza o valor no localStorage
 */
export const useStorager = (key: string, defaultValue: any, storager: Storager) => {
	const [value, setValue] = useState(defaultValue);
	const [dataStorager, setDataStorager] = useState<Storager>(MultiStorager.DataStorager as Storager);

	useEffect(() => {
		setDataStorager((dataStorager) => {
			return storager && storager instanceof Storager ? storager : dataStorager;
		});
	}, [storager]);

	useEffect(() => {
		if (dataStorager.hasKey(key) !== true) {
			dataStorager.set(key, defaultValue);
			setValue(() => {
				return defaultValue;
			});
		} else {
			setValue(() => {
				return dataStorager.get(key);
			});
		}
	}, [key, dataStorager, defaultValue]);

	useEffect(() => {
		if (typeof key !== "string") {
			return;
		}

		const listener = dataStorager.addListener(key, (value) => {
			setValue(() => value);
		});

		return () => {
			listener?.stop();
		};
	}, [key, dataStorager]);

	const changeValue = useCallback(
		(value: any) => {
			dataStorager.set(key, value);
		},
		[key, dataStorager],
	);

	return { value, setValue: changeValue };
};

/**
 * Hook personalizado que rastreia a largura e altura da janela do navegador.
 *
 * @function useWindowSize
 * @returns {{ width: number, height: number }} Um objeto contendo a largura e altura da janela do navegador.
 *
 * @example
 * // Exemplo de uso:
 * function MyComponent() {
 *   const { width, height } = useWindowSize();
 *
 *   return (
 *     <div>
 *       Largura da janela: {width}px
 *       Altura da janela: {height}px
 *     </div>
 *   );
 * }
 */
export const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	return windowSize;
};

/**
 * Hook personalizado que gerencia chamadas assíncronas.
 *
 * @template T
 * @param {(...props: any[]) => Promise<T>} asyncFunction - A função assíncrona a ser executada.
 * @param {boolean} [immediate=true] - Define se a chamada à função assíncrona deve ser imediata.
 * @returns {{
 *   execute: (...props: any[]) => Promise<void>,
 *   status: ("idle" | "pending" | "success" | "error"),
 *   value: T | null,
 *   error: any | null
 * }}
 *
 * @example
 * const fetchData = async () => {
 *   const response = await fetch('https://api.example.com/data');
 *   const data = await response.json();
 *   return data;
 * };
 *
 * const { execute, status, value, error } = useAsync(fetchData, false);
 *
 * useEffect(() => {
 *   execute();
 * }, []);
 *
 * if (status === 'idle') {
 *   return <div>Pronto para começar.</div>;
 * }
 *
 * if (status === 'pending') {
 *   return <div>Carregando...</div>;
 * }
 *
 * if (status === 'error') {
 *   return <div>Erro: {error.message}</div>;
 * }
 *
 * if (status === 'success') {
 *   return <div>Dados: {value}</div>;
 * }
 */
export const useAsync = <T = any>(asyncFunction: (...props: any[]) => Promise<T>, immediate = true) => {
	const [status, setStatus] = useState<"idle" | "pending" | "success" | "error">("idle");
	const [value, setValue] = useState<T | null>(null);
	const [error, setError] = useState<any | null>(null);

	const execute = useCallback(
		(...props: any[]) => {
			setStatus("pending");
			//setValue(null);
			setError(null);
			return Promise.race([asyncFunction.apply(null, props)])
				.then((response: any) => {
					setValue(response);
					setStatus("success");
				})
				.catch((error) => {
					setError(error);
					setStatus("error");
				});
		},
		[asyncFunction],
	);

	useEffect(() => {
		if (immediate) {
			execute();
		}
	}, [immediate]);

	return { execute, status, value, error };
};

/**
 * Um hook personalizado para lidar com o estado de alternância.
 *
 * @param {boolean} [initialState=false] - O valor inicial do estado.
 * @returns {[boolean, function]} - Uma matriz contendo o estado atual e uma função para alterná-lo.
 *
 * @example
 * const [isOpen, toggleIsOpen] = useToggle(false);
 * console.log(isOpen); // Valor atual do estado (inicialmente false)
 * toggleIsOpen(); // Alternar o estado (de false para true)
 * console.log(isOpen); // Novo valor do estado (true)
 */
export const useToggle = (initialState: boolean = false) => {
	const [state, setState] = useState(initialState);
	const toggle = useCallback(() => setState((state) => !state), []);
	return [state, toggle];
};

/**
 * Um hook personalizado para lidar com a navegação de rotas.
 *
 * @param {string | string[] | RegExp | RegExp[]} path - Um caminho de rota opcional ou um array de caminhos de rota ou uma expressão regular.
 * @returns {{
 *   push: useHistory.prototype.push<Function>,
 *   replace: useHistory.prototype.replace<Function>,
 *   pathname: useLocation.prototype.pathname<string>,
 *   query: Object,
 *   location: Object,
 *   history: {[key:string]: any},
 *   length: number,
 *   currentIndex: number
 * }} Retorna um objeto com propriedades relacionadas à navegação de rota.
 * @example
 * const { pathname, query, push } = useRouter("/example");
 */
export const useRouter = (path: string | string[] | RegExp | RegExp[] = "") => {
	const [params, setParams] = useState<{ [k: string]: any }>(_history.location.state as { [k: string]: any });
	const [location, setLocation] = useState(_history.location);
	const [history, setHistory] = useState(_history);
	const [currentIndex, setCurrentIndex] = useState<number>(getHistoryCurrentIndex());
	const [length, setLength] = useState<number>(getHistoryLentgth());

	useEffect(() => {
		const interval = setInterval(() => {
			if (
				history.length !== _history.length ||
				history.action !== _history.action ||
				location.key !== _history.location.key ||
				location.pathname !== _history.location.pathname ||
				currentIndex !== getHistoryCurrentIndex() ||
				length !== getHistoryLentgth()
			) {
				setLocation(() => ({ ..._history.location }));
				setHistory(() => _history);
				setCurrentIndex(() => getHistoryCurrentIndex());
				setLength(() => getHistoryLentgth());
			}
		}, 500);

		return () => {
			clearInterval(interval);
		};
	}, [history, location]);

	useEffect(() => {
		setParams(() => {
			let paths: (string | RegExp)[] = (Array.isArray(path) ? path : [path]).filter((s) => typeof s === "string" || s instanceof RegExp);
			if (paths.length <= 0) {
				return _history.location.state || {};
			}
			return Object.assign.apply(
				null,
				(paths as any).map((p: string) => matchPath(p, _history.location.pathname)["query"] || {}).concat(_history.location.state || {}),
			);
		});
	}, [location, history]);

	return useMemo(() => {
		return {
			// For convenience add push(), replace(), pathname at top level
			push: history.push,
			replace: history.replace,
			pathname: normalizePath(location.pathname),
			// Merge params and parsed query string into single "query" object
			// so that they can be used interchangeably.
			// Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
			query: {
				...queryString.parse(location.search), // Convert string to object
				...params,
			},
			// Include location, history objects so we have
			// access to extra React Router functionality if needed.
			location: location,
			history,

			length,
			currentIndex,
		};
	}, [params, location, history]);
};

/**
 * Adiciona um ouvinte de evento a um elemento específico.
 *
 * @param {string} eventName - O tipo de evento a ser ouvido, como "click" ou "keydown".
 * @param {function(Event): void} handler - A função de retorno de chamada a ser executada quando o evento é acionado.
 * @param {boolean} [wantsUntrusted=false] - Se `true`, permite que o evento seja acionado por fontes não confiáveis.
 * @param {Window | Document | HTMLElement} [element=window] - O elemento ao qual o ouvinte de evento será anexado. O padrão é `window`.
 *
 * @example
 * // Exemplo de uso:
 * useEventListener('click', handleClick);
 *
 * @returns {void}
 */
export const useEventListener = (
	eventName: string,
	handler: (event: Event) => void,
	wantsUntrusted: boolean = false,
	element: Window | Document | HTMLElement = window,
) => {
	const savedHandler = useRef<(event: Event) => void | null>(null);

	useEffect(() => {
		(savedHandler as any).current = handler;
	}, [handler]);

	useEffect(() => {
		const isSupported = element && element.addEventListener;
		if (!isSupported) return;

		const eventListener = (event: Event) => {
			if (savedHandler.current) savedHandler.current(event);
		};

		element.addEventListener(eventName, eventListener, wantsUntrusted);

		return () => {
			element.removeEventListener(eventName, eventListener, wantsUntrusted);
		};
	}, [eventName, element]);
};

/**
 * Retorna o valor associado à primeira consulta de mídia que corresponder às condições do navegador.
 *
 * @param {string[]} queries - Um array de strings contendo consultas de mídia, cada uma especificando as condições em que o valor associado deve ser usado.
 * @param {Object} values - Um objeto onde cada chave corresponde a uma consulta de mídia e o valor associado a essa chave será retornado quando a consulta corresponder.
 * @param {*} defaultValue - O valor padrão a ser retornado quando nenhuma consulta de mídia corresponder.
 * @returns {*} - O valor associado à primeira consulta de mídia que corresponder às condições do navegador ou o valor padrão.
 */
export const useMedia = (queries: string[], values: { [query: string]: any }, defaultValue: any) => {
	const mediaQueryLists = queries.map((q) => window.matchMedia(q));
	const getValue = () => {
		const index = mediaQueryLists.findIndex((mql) => mql.matches);
		return typeof values[index] !== "undefined" ? values[index] : defaultValue;
	};

	const [value, setValue] = useState(getValue);

	useEffect(() => {
		const handler = () => setValue(getValue);

		mediaQueryLists.forEach((mql) => mql.addListener(handler));

		return () => {
			mediaQueryLists.forEach((mql) => mql.removeListener(handler));
		};
	}, []);

	return value;
};

const initialStateHistory = {
	past: [],
	present: null,
	future: [],
};

/**
 * Função de redutor para gerenciar o histórico do estado.
 *
 * @template T
 * @param {Object} state - O estado atual, incluindo o histórico passado, presente e futuro.
 * @param {string} action.type - O tipo de ação a ser executada ("UNDO", "REDO", "SET" ou "CLEAR").
 * @param {T} action.newPresent - O novo estado a ser definido.
 * @param {T} action.initialPresent - O estado inicial quando "CLEAR" é acionado.
 * @returns {Object} O novo estado após a ação ser aplicada.
 */
const reducerHistory = <T = any>(
	state: { past: T[]; present: T; future: T[] },
	action: { type: "UNDO" | "REDO" | "SET" | "CLEAR"; newPresent?: T; initialPresent?: T },
) => {
	const { past, present, future } = state;
	switch (action.type) {
		case "UNDO":
			const previous = past[past.length - 1];
			const newPast = past.slice(0, past.length - 1);
			return {
				past: newPast,
				present: previous,
				future: [present, ...future],
			};
		case "REDO":
			const next = future[0];
			const newFuture = future.slice(1);
			return {
				past: [...past, present],
				present: next,
				future: newFuture,
			};
		case "SET":
			const { newPresent } = action;
			if (newPresent === present) {
				return state;
			}
			return {
				past: [...past, present],
				present: newPresent,
				future: [],
			};
		case "CLEAR":
			const { initialPresent } = action;
			return {
				...initialStateHistory,
				present: initialPresent,
			};
	}
};

/**
 * Hook personalizado para gerenciar o histórico de estados.
 *
 * @template T
 * @param {T} initialPresent - O valor inicial do histórico.
 * @returns {{
 *   state: T,
 *   set: (newPresent: T) => void,
 *   undo: () => void,
 *   redo: () => void,
 *   clear: () => void,
 *   canUndo: boolean,
 *   canRedo: boolean
 * }} Retorna o estado atual do valor do histórico e callbacks de navegação.
 *
 * @example
 * const { state, set, undo, redo, clear, canUndo, canRedo } = useHistory(initialValue);
 */
export const useHistory = <T = any>(initialPresent: T) => {
	const [state, dispatch] = useReducer(reducerHistory, {
		...initialStateHistory,
		present: initialPresent,
	});
	const canUndo = state.past.length !== 0;
	const canRedo = state.future.length !== 0;
	// Setup our callback functions
	// We memoize with useCallback to prevent unnecessary re-renders
	const undo = useCallback(() => {
		if (canUndo) {
			dispatch({ type: "UNDO" });
		}
	}, [canUndo, dispatch]);

	const redo = useCallback(() => {
		if (canRedo) {
			dispatch({ type: "REDO" });
		}
	}, [canRedo, dispatch]);

	const set = useCallback(
		(newPresent: T) => {
			dispatch({ type: "SET", newPresent });
		},
		[dispatch],
	);

	const clear = useCallback(() => {
		dispatch({ type: "CLEAR", initialPresent });
	}, [dispatch]);

	// If needed we could also return past and future state
	return { state: state.present as T, set, undo, redo, clear, canUndo, canRedo };
};

/**
 * Carrega um script externo dinamicamente e controla seu status de carregamento.
 *
 * @param {string} src - A URL do script externo a ser carregado.
 * @returns {("idle"|"loading"|"ready"|"error")} - O status atual do script (ocioso, carregando, pronto ou erro).
 */
export const useScript = (src: string) => {
	const [status, setStatus] = useState<string>(src ? "loading" : "idle");

	useEffect(() => {
		if (!src) {
			setStatus("idle");
			return;
		}

		let script: HTMLScriptElement = document.querySelector(`script[src="${src}"]`) as HTMLScriptElement;
		if (!script) {
			script = document.createElement("script") as HTMLScriptElement;
			script.src = src;
			script.async = true;
			script.setAttribute("data-status", "loading");
			document.body.appendChild(script);
			const setAttributeFromEvent = (event: Event) => {
				script.setAttribute("data-status", event.type === "load" ? "ready" : "error");
			};
			script.addEventListener("load", setAttributeFromEvent);
			script.addEventListener("error", setAttributeFromEvent);
		} else {
			setStatus((p) => script?.getAttribute("data-status") ?? p);
		}

		const setStateFromEvent = (event: Event) => {
			setStatus(event.type === "load" ? "ready" : "error");
		};

		script.addEventListener("load", setStateFromEvent);
		script.addEventListener("error", setStateFromEvent);

		return () => {
			if (script) {
				script.removeEventListener("load", setStateFromEvent);
				script.removeEventListener("error", setStateFromEvent);
			}
		};
	}, [src]);

	return status as "idle" | "loading" | "ready" | "error";
};

/**
 * Hook personalizado que detecta quando uma tecla específica é pressionada.
 *
 * @param {string} targetKey - A tecla alvo que você deseja detectar.
 * @returns {boolean} - Retorna `true` se a tecla alvo estiver pressionada, caso contrário, retorna `false`.
 *
 * @example
 * // Uso do hook para detectar a tecla "Enter" pressionada
 * const enterPressed = useKeyPress("Enter");
 *
 * if (enterPressed) {
 *   // Faça algo quando a tecla "Enter" for pressionada
 * }
 */
export const useKeyPress = (targetKey: string) => {
	const [keyPressed, setKeyPressed] = useState(false);

	const downHandler = ({ key }: KeyboardEvent) => {
		if (key === targetKey) {
			setKeyPressed(true);
		}
	};

	const upHandler = ({ key }: KeyboardEvent) => {
		if (key === targetKey) {
			setKeyPressed(false);
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", downHandler);
		window.addEventListener("keyup", upHandler);

		return () => {
			window.removeEventListener("keydown", downHandler);
			window.removeEventListener("keyup", upHandler);
		};
	}, []);

	return keyPressed;
};

/**
 * Hook personalizado para aplicar um atraso a um valor antes de atualizá-lo.
 *
 * @param {string|number|boolean} value - O valor a ser debatido.
 * @param {number} [delay=1000] - O atraso em milissegundos.
 * @returns {string|number|boolean} - O valor debatido.
 */
export const useDebounce = (value: string | number | boolean, delay: number = 1000) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
};

/**
 * Um hook personalizado que verifica se um elemento está visível na tela.
 *
 * @template T - O tipo de elemento alvo (por padrão, Window, Document ou HTMLElement)
 * @param {React.MutableRefObject<T>} ref - A referência do elemento a ser monitorado.
 * @param {string} [rootMargin="0px"] - A margem em relação à área de visualização para considerar como visível.
 * @returns {boolean} - Um valor booleano que indica se o elemento está visível na tela.
 */
export const useOnScreen = <T = Window | Document | HTMLElement>(ref: React.MutableRefObject<T>, rootMargin: string = "0px") => {
	const [isIntersecting, setIntersecting] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIntersecting(entry.isIntersecting);
			},
			{ rootMargin },
		);

		if (ref.current) {
			observer.observe(ref.current as any);
		}

		return () => {
			observer.unobserve(ref.current as any);
		};
	}, []);

	return isIntersecting;
};

/**
 * Custom hook para obter o valor anterior de uma variável.
 *
 * @template T - O tipo de valor que você deseja rastrear.
 * @param {T} value - O valor atual que você deseja rastrear.
 * @returns {T | undefined} - O valor anterior, ou `undefined` se for a primeira renderização.
 *
 * @example
 * // Exemplo de uso:
 * const MyComponent = () => {
 *   const [count, setCount] = useState(0);
 *   const previousCount = usePrevious(count);
 *
 *   return (
 *     <div>
 *       <p>Valor atual: {count}</p>
 *       <p>Valor anterior: {previousCount !== undefined ? previousCount : 'Nenhum'}</p>
 *       <button onClick={() => setCount(count + 1)}>Incrementar</button>
 *     </div>
 *   );
 * }
 */
export const usePrevious = <T = any>(value: T) => {
	const ref = useRef<T>();

	useEffect(() => {
		ref.current = value;
	}, [value]);

	return ref.current;
};

/**
 * Custom hook que permite detectar cliques fora de um elemento especificado.
 *
 * @template T - O tipo de elemento que você deseja rastrear (Window, Document, ou HTMLElement).
 * @param {React.MutableRefObject<T>} ref - A referência ao elemento que você deseja monitorar.
 * @param {(event: Event) => void} handler - A função de retorno de chamada que será executada quando ocorrer um clique fora do elemento.
 *
 * @example
 * // Exemplo de uso:
 * const MyComponent = () => {
 *   const ref = useRef(null);
 *
 *   useOnClickOutside(ref, (event) => {
 *     // Esta função será chamada quando ocorrer um clique fora do elemento referenciado.
 *     console.log("Clique fora do elemento!");
 *   });
 *
 *   return (
 *     <div ref={ref}>
 *       Conteúdo do seu componente
 *     </div>
 *   );
 * }
 */
export const useOnClickOutside = <T = Window | Document | HTMLElement>(ref: React.MutableRefObject<T>, handler: (event: Event) => void) => {
	useEffect(() => {
		const listener = (event: Event) => {
			if (!ref.current || (ref.current as any).contains(event.target)) {
				return;
			}
			handler(event);
		};

		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);

		return () => {
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	}, [ref, handler]);
};

/**
 * Hook personalizado que fornece um temporizador de animação que atualiza o tempo decorrido.
 *
 * @param {number} [duration=1000] - A duração da animação em milissegundos.
 * @param {number} [delay=0] - O atraso antes de iniciar a animação em milissegundos.
 * @returns {number} - O tempo decorrido em milissegundos.
 *
 * @example
 * // Exemplo de uso:
 * const MyComponent = () => {
 *   const elapsed = useAnimationTimer(3000, 500);
 *
 *   return (
 *     <div>
 *       Tempo decorrido: {elapsed} ms
 *     </div>
 *   );
 * }
 */
export const useAnimationTimer = (duration: number = 1000, delay: number = 0) => {
	const [elapsed, setTime] = useState(0);

	useEffect(() => {
		let animationFrame: number, timerStop: NodeJS.Timeout, start: number;

		const onFrame = () => {
			setTime(Date.now() - start);
			loop();
		};

		const loop = () => {
			animationFrame = requestAnimationFrame(onFrame);
		};

		const onStart = () => {
			timerStop = setTimeout(() => {
				cancelAnimationFrame(animationFrame);
				setTime(Date.now() - start);
			}, duration);

			start = Date.now();
			loop();
		};

		const timerDelay = setTimeout(onStart, delay);

		return () => {
			clearTimeout(timerStop);
			clearTimeout(timerDelay);
			cancelAnimationFrame(animationFrame);
		};
	}, [duration, delay]);

	return elapsed;
};

/**
 * @function {@link https://github.com/gre/bezier-easing bezierEasing-Github}
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @returns {Function}
 */
const bezierEasing = function (x1: number, y1: number, x2: number, y2: number) {
	if (!(0 <= x1 && x1 <= 1 && 0 <= x2 && x2 <= 1)) {
		throw new Error("bezier x values must be in [0, 1] range");
	}
	let kSplineTableSize = 11,
		kSampleStepSize = 1.0 / (kSplineTableSize - 1.0);
	const A = (a: number, b: number) => {
		return 1.0 - 3.0 * b + 3.0 * a;
	};
	const B = (a: number, b: number) => {
		return 3.0 * b - 6.0 * a;
	};
	const C = (a: number) => {
		return 3.0 * a;
	};
	const calcBezier = (a: number, b: number, c: number) => {
		return ((A(b, c) * a + B(b, c)) * a + C(b)) * a;
	};
	const getSlope = (a: number, b: number, c: number) => {
		return 3.0 * A(b, c) * a * a + 2.0 * B(b, c) * a + C(b);
	};
	const binarySubdivide = (a: number, b: number, c: number, d: number, e: number) => {
		let f,
			g,
			i = 0;
		do {
			g = b + (c - b) / 2.0;
			f = calcBezier(g, d, e) - a;
			if (f > 0.0) {
				c = g;
			} else {
				b = g;
			}
		} while (Math.abs(f) > 0.0000001 && ++i < 10);
		return g;
	};
	const newtonRaphsonIterate = (a: number, b: number, c: number, d: number) => {
		for (let i = 0; i < 4; ++i) {
			let currentSlope = getSlope(b, c, d);
			if (currentSlope === 0.0) {
				return b;
			}
			let currentX = calcBezier(b, c, d) - a;
			b -= currentX / currentSlope;
		}
		return b;
	};
	if (x1 === y1 && x2 === y2) {
		return (x: number) => {
			return x;
		};
	}
	let sampleValues = typeof Float32Array === "function" ? new Float32Array(kSplineTableSize) : new Array(kSplineTableSize);
	for (let i = 0; i < kSplineTableSize; ++i) {
		sampleValues[i] = calcBezier(i * kSampleStepSize, x1, x2);
	}
	const getTForX = (a: number) => {
		let b = 0.0,
			c = 1,
			d = kSplineTableSize - 1;
		for (; c !== d && sampleValues[c] <= a; ++c) {
			b += kSampleStepSize;
		}
		--c;
		let e = (a - sampleValues[c]) / (sampleValues[c + 1] - sampleValues[c]),
			f = b + e * kSampleStepSize,
			g = getSlope(f, x1, x2);
		if (g >= 0.001) {
			return newtonRaphsonIterate(a, f, x1, x2);
		} else if (g === 0.0) {
			return f;
		} else {
			return binarySubdivide(a, b, b + kSampleStepSize, x1, x2);
		}
	};
	return (x: number) => {
		return x === 0 ? 0 : x === 1 ? 1 : calcBezier(getTForX(x), y1, y2);
	};
};

/**
 * Hook personalizado que fornece uma função de interpolação baseada em tempo.
 *
 * @param {Array|[[number, number], [number, number]]|string} [easing="linear"] - A função de interpolação ou um nome de função de interpolação predefinida.
 * @param {number} [duration=500] - A duração da animação em milissegundos.
 * @param {number} [delay=0] - O atraso antes de iniciar a animação em milissegundos.
 * @returns {number} - O valor interpolado com base no tempo.
 *
 * @example
 * // Exemplo de uso:
 * const MyComponent = () => {
 *   const progress = useAnimation("ease-out", 1000, 500);
 *
 *   return (
 *     <div>
 *       Progresso da animação: {progress}
 *     </div>
 *   );
 * }
 */
export const useAnimation = (
	easing:
		| [number, number, number, number]
		| [[number, number], [number, number]]
		| "linear"
		| "elastic"
		| "ease"
		| "ease-in"
		| "ease-in-elastic"
		| "ease-in-bounce"
		| "ease-in-expo"
		| "ease-in-sine"
		| "ease-in-quad"
		| "ease-in-cubic"
		| "ease-in-back"
		| "ease-in-quart"
		| "ease-in-quint"
		| "ease-in-circ"
		| "ease-in-out"
		| "ease-in-out-elastic"
		| "ease-in-out-bounce"
		| "ease-in-out-sine"
		| "ease-in-out-quad"
		| "ease-in-out-cubic"
		| "ease-in-out-back"
		| "ease-in-out-quart"
		| "ease-in-out-quint"
		| "ease-in-out-expo"
		| "ease-in-out-circ"
		| "ease-out"
		| "ease-out-elastic"
		| "ease-out-bounce"
		| "ease-out-sine"
		| "ease-out-quad"
		| "ease-out-cubic"
		| "ease-out-back"
		| "ease-out-quart"
		| "ease-out-quint"
		| "ease-out-expo"
		| "ease-out-circ"
		| "fast-out-slow-in"
		| "fast-out-linear-in"
		| "linear-out-slow-in" = "linear",
	duration: number = 500,
	delay: number = 0,
) => {
	const elastic = (x: number) => {
		return x * (33 * x * x * x * x - 106 * x * x * x + 126 * x * x - 67 * x + 15);
	};

	const easeInElastic = (x: number) => {
		const c4 = (2 * Math.PI) / 3;
		return x === 0 ? 0 : x === 1 ? 1 : -Math.pow(2, 10 * x - 10) * Math.sin((x * 10 - 10.75) * c4);
	};

	const easeInOutElastic = (x: number) => {
		const c5 = (2 * Math.PI) / 4.5;
		return x === 0
			? 0
			: x === 1
			? 1
			: x < 0.5
			? -(Math.pow(2, 20 * x - 10) * Math.sin((20 * x - 11.125) * c5)) / 2
			: (Math.pow(2, -20 * x + 10) * Math.sin((20 * x - 11.125) * c5)) / 2 + 1;
	};

	const easeOutElastic = (x: number) => {
		const c4 = (2 * Math.PI) / 3;
		return x === 0 ? 0 : x === 1 ? 1 : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
	};

	const easeOutBounce = (x: number) => {
		const n1 = 7.5625;
		const d1 = 2.75;
		return x < 1 / d1
			? n1 * x * x
			: x < 2 / d1
			? n1 * (x -= 1.5 / d1) * x + 0.75
			: x < 2.5 / d1
			? n1 * (x -= 2.25 / d1) * x + 0.9375
			: n1 * (x -= 2.625 / d1) * x + 0.984375;
	};

	const easeInBounce = (x: number) => {
		return 1 - easeOutBounce(1 - x);
	};

	const easeInOutBounce = (x: number) => {
		return x < 0.5 ? (1 - easeOutBounce(1 - 2 * x)) / 2 : (1 + easeOutBounce(2 * x - 1)) / 2;
	};

	const easingList = {
		"linear": (n: number) => n,
		"elastic": elastic,
		"ease": bezierEasing(0.25, 0.1, 0.25, 1.0),
		"ease-in": bezierEasing(0.42, 0.0, 1.0, 1.0),
		"ease-in-elastic": easeInElastic,
		"ease-in-bounce": easeInBounce,
		"ease-in-expo": bezierEasing(0.95, 0.05, 0.795, 0.035),
		"ease-in-sine": bezierEasing(0.47, 0, 0.75, 0.72),
		"ease-in-quad": bezierEasing(0.55, 0.09, 0.68, 0.53),
		"ease-in-cubic": bezierEasing(0.55, 0.06, 0.68, 0.19),
		"ease-in-back": bezierEasing(0.6, -0.28, 0.74, 0.05),
		"ease-in-quart": bezierEasing(0.895, 0.03, 0.685, 0.22),
		"ease-in-quint": bezierEasing(0.755, 0.05, 0.855, 0.06),
		"ease-in-circ": bezierEasing(0.6, 0.04, 0.98, 0.335),
		"ease-in-out": bezierEasing(0.42, 0.0, 0.58, 1.0),
		"ease-in-out-elastic": easeInOutElastic,
		"ease-in-out-bounce": easeInOutBounce,
		"ease-in-out-sine": bezierEasing(0.45, 0.05, 0.55, 0.95),
		"ease-in-out-quad": bezierEasing(0.46, 0.03, 0.52, 0.96),
		"ease-in-out-cubic": bezierEasing(0.65, 0.05, 0.36, 1),
		"ease-in-out-back": bezierEasing(0.68, -0.55, 0.27, 1.55),
		"ease-in-out-quart": bezierEasing(0.77, 0, 0.175, 1),
		"ease-in-out-quint": bezierEasing(0.86, 0, 0.07, 1),
		"ease-in-out-expo": bezierEasing(1, 0, 0, 1),
		"ease-in-out-circ": bezierEasing(0.785, 0.135, 0.15, 0.86),
		"ease-out": bezierEasing(0.0, 0.0, 0.58, 1.0),
		"ease-out-elastic": easeOutElastic,
		"ease-out-bounce": easeOutBounce,
		"ease-out-sine": bezierEasing(0.39, 0.58, 0.57, 1),
		"ease-out-quad": bezierEasing(0.25, 0.46, 0.45, 0.94),
		"ease-out-cubic": bezierEasing(0.22, 0.61, 0.36, 1),
		"ease-out-back": bezierEasing(0.18, 0.89, 0.32, 1.28),
		"ease-out-quart": bezierEasing(0.165, 0.84, 0.44, 1),
		"ease-out-quint": bezierEasing(0.23, 1, 0.32, 1),
		"ease-out-expo": bezierEasing(0.19, 1, 0.22, 1),
		"ease-out-circ": bezierEasing(0.075, 0.82, 0.165, 1),
		"fast-out-slow-in": bezierEasing(0.4, 0, 0.2, 1),
		"fast-out-linear-in": bezierEasing(0.4, 0, 1, 1),
		"linear-out-slow-in": bezierEasing(0, 0, 0.2, 1),
	};

	if (Array.isArray(easing)) {
		if (easing.every((n: any) => typeof n === "number") && easing.length >= 4) {
			easing = bezierEasing.apply(null, easing as any) as any;
		} else {
			easing = (easing as any[]).filter((v: any) => Array.isArray(v) && v.every((n) => typeof n === "number") && v.length >= 2) as any;
			easing =
				easing.length === 2
					? (bezierEasing.apply(null, Array.prototype.concat(easing[0].slice(0, 2), easing[1].slice(0, 2)) as any) as any)
					: "linear";
		}
	}

	if (typeof easing === "string") {
		easing = (Object.keys(easingList).includes(easing) ? easingList[easing] : easingList["linear"]) as any;
	}

	const elapsed = useAnimationTimer(duration, delay);
	const x = Math.min(1, elapsed / duration);

	return typeof easing === "function" ? (easing as any)(x) : x;
};

/**
 * Hook personalizado que detecta quando um elemento é hoverado.
 *
 * @template T
 * @returns {[React.MutableRefObject<T | null>, boolean]} - Um array contendo a referência ao elemento e um valor booleano que indica se o elemento está sendo hoverado.
 *
 * @example
 * // Exemplo de uso:
 * const MyComponent = () => {
 *   const [hoverRef, isHovered] = useHover();
 *
 *   return (
 *     <div ref={hoverRef}>
 *       {isHovered ? 'Está sendo hoverado!' : 'Não está sendo hoverado.'}
 *     </div>
 *   );
 * }
 */
export const useHover = <T = Window | Document | HTMLElement>(): [React.MutableRefObject<T | null>, boolean] => {
	const [value, setValue] = useState<boolean>(false);
	const ref = useRef<T | null>(null);

	const handleMouseOver = () => setValue(true);
	const handleMouseOut = () => setValue(false);

	useEffect(() => {
		const node = ref.current;
		if (node) {
			(node as any).addEventListener("mouseover", handleMouseOver);
			(node as any).addEventListener("mouseout", handleMouseOut);
			return () => {
				(node as any).removeEventListener("mouseover", handleMouseOver);
				(node as any).removeEventListener("mouseout", handleMouseOut);
			};
		}
	}, [ref.current]);

	return [ref, value];
};

/**
 * Um hook personalizado que permite armazenar e recuperar valores no armazenamento local do navegador.
 *
 * @template T
 * @param {string} key - A chave sob a qual o valor será armazenado no armazenamento local.
 * @param {T} initialValue - O valor inicial a ser usado se nenhum valor estiver armazenado no armazenamento local.
 * @returns {[T, (value: T | ((value: T) => T)) => void]} - Um array contendo o valor armazenado atualmente e uma função para definir um novo valor.
 *
 * @example
 * // Exemplo de uso:
 * const [storedValue, setStoredValue] = useLocalStorage('myKey', 'defaultValue');
 *
 * // Define um novo valor no armazenamento local
 * setStoredValue('novoValor');
 *
 * // Obtém o valor atual do armazenamento local
 * const valorAtual = storedValue;
 */
export const useLocalStorage = <T = string | number | boolean>(key: string, initialValue: T) => {
	const [storedValue, setStoredValue] = useState<T>(() => {
		if (typeof window === "undefined") {
			return initialValue;
		}
		try {
			const item = window.localStorage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			return initialValue;
		}
	});

	const setValue = (value: T | ((velua: T) => T)) => {
		try {
			const valueToStore = value instanceof Function ? value(storedValue) : value;

			setStoredValue(valueToStore);

			if (typeof window !== "undefined") {
				window.localStorage.setItem(key, JSON.stringify(valueToStore));
			}
		} catch (error) {
			console.error(error);
		}
	};
	return [storedValue, setValue];
};

/**
 * Um hook personalizado que permite executar uma função em intervalos regulares.
 *
 * @param {() => void} callback - A função a ser executada em cada intervalo.
 * @param {number|undefined} delay - O intervalo de tempo, em milissegundos, entre as execuções da função. Se não for fornecido, o intervalo não será definido e a função só será executada manualmente.
 * @returns {React.MutableRefObject<number | undefined>} - Um objeto ref que contém o identificador do intervalo atual.
 *
 * @example
 * // Exemplo de uso:
 * const intervalRef = useInterval(() => {
 *   console.log('Intervalo executado!');
 * }, 1000); // Execute a função a cada 1000ms (1 segundo)
 *
 * // Para limpar o intervalo, se necessário
 * clearInterval(intervalRef.current);
 */
export const useInterval = (callback: () => void, delay?: number) => {
	const intervalRef = useRef<number | undefined>();
	const callbackRef = useRef<() => void>(callback);

	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	useEffect(() => {
		if (typeof delay === "number") {
			intervalRef.current = window.setInterval(() => callbackRef.current(), delay);
			return () => window.clearInterval(intervalRef.current);
		}
	}, [delay, callbackRef]);

	return intervalRef;
};

/**
 * Um hook personalizado que monitora as dimensões de um elemento e retorna uma referência para o elemento e um objeto contendo a largura e a altura do elemento.
 *
 * @returns {[React.MutableRefObject<T | null>, { width: number; height: number }]} - Um array contendo uma referência para o elemento monitorado e um objeto com as dimensões (largura e altura).
 *
 * @example
 * // Exemplo de uso:
 * const [elementRef, size] = useElementSize();
 *
 * return (
 *   <div ref={elementRef}>
 *     <p>Width: {size.width}px</p>
 *     <p>Height: {size.height}px</p>
 *   </div>
 * );
 *
 * // O elemento referenciado pelo elementRef será monitorado e as dimensões serão atualizadas automaticamente.
 */
export const useElementSize = <T = Window | Document | HTMLElement>(): [
	React.MutableRefObject<T | null>,
	{
		width: number;
		height: number;
	},
] => {
	const ref = useRef<T | null>(null);
	const [size, setSize] = useState({ width: 0, height: 0 });

	useInterval(() => {
		try {
			const { width: w, height: h } = (ref.current as any)?.getBoundingClientRect();

			const s = { width: parseFloat(w.toFixed(2)), height: parseFloat(h.toFixed(2)) };

			if (JSON.stringify(size) != JSON.stringify(s)) {
				setSize((prev) => {
					return { ...prev, width: s.width, height: s.height };
				});
			}
		} catch (e) {}
	}, 200);

	return [ref, size];
};

/**
 * Um hook personalizado que ajusta o tamanho de um texto para se ajustar ao contêiner pai com tamanhos de fonte mínimos e máximos especificados.
 *
 * @param {number} [minFontSize=-Infinity] - O tamanho mínimo da fonte permitido.
 * @param {number} [maxFontSize=Infinity] - O tamanho máximo da fonte permitido.
 * @returns {{ containerRef: React.MutableRefObject<HTMLElement | null>; textRef: React.MutableRefObject<HTMLElement | null> }} - Um objeto contendo referências para o contêiner e o elemento de texto que serão ajustados.
 *
 * @example
 * // Exemplo de uso:
 * const { containerRef, textRef } = useFitText(16, 32);
 *
 * return (
 *   <div ref={containerRef}>
 *     <p ref={textRef}>Texto ajustado em tamanho.</p>
 *   </div>
 * );
 *
 * // O tamanho do texto será ajustado automaticamente com base nas dimensões do contêiner e nas configurações de tamanho mínimo e máximo.
 */
export const useFitText = <T = HTMLElement, I = HTMLElement>(minFontSize: number = 5, maxFontSize: number = Infinity, increment: number = 0) => {
	const containerRef = useRef<T | null>(null);
	const textRef = useRef<I | null>(null);

	const measureTextSize = (text: string, fontSize: number = 10): { width: number; height: number } => {
		const phantomElement = document.createElement("div");
		phantomElement.style.position = "absolute";
		phantomElement.style.visibility = "hidden";
		phantomElement.style.whiteSpace = "nowrap";
		phantomElement.style.fontSize = `${fontSize}px`;
		phantomElement.textContent = text;

		document.body.appendChild(phantomElement);

		const width = phantomElement.offsetWidth;
		const height = phantomElement.offsetHeight;

		document.body.removeChild(phantomElement);

		return { width, height };
	};

	useEffect(() => {
		let frameNumber: number;
		let timeNumber: NodeJS.Timeout;

		const fitText = () => {
			clearTimeout(timeNumber);
			cancelAnimationFrame(frameNumber);

			if (containerRef.current && textRef.current) {
				const initialFontSize = Math.max(10, minFontSize);
				const { width: initialWidth } = measureTextSize((containerRef.current as any).innerText, initialFontSize);

				const desiredWidth = (containerRef.current as any).clientWidth;
				const fontIdeal = (initialFontSize / initialWidth) * desiredWidth;
				const incr = 1 + increment;
				const fontSize = Math.max(Math.min(Math.round(fontIdeal * incr), maxFontSize), minFontSize);

				(textRef.current as any).style.fontSize = `${fontSize}px`;
			}

			timeNumber = setTimeout(() => {
				frameNumber = requestAnimationFrame(fitText);
			}, 1000);
		};

		fitText();
		window.addEventListener("resize", fitText);

		return () => {
			clearTimeout(timeNumber);
			cancelAnimationFrame(frameNumber);
			window.removeEventListener("resize", fitText);
		};
	}, [minFontSize, maxFontSize]);

	return { containerRef, textRef };
};
