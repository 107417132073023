import React, { useCallback } from "react";
import { useRouter } from "Utils/useHook";

import { Chat } from "Components";

import styles from "./style.module.scss";

import bot from "./chatBot";

export default ()=>{
    const { query: { idRoomChat }, push } = useRouter("/chat/:idRoomChat");

    const changeRoomId = useCallback((roomId)=>{
        if(typeof roomId === "string" && roomId !== idRoomChat){
            push(`/chat/${roomId}`);
        }else{
            push(`/chat`);
        }
    }, []);

    return <div className={styles["main"]}>
        <Chat idRoom={idRoomChat} disableConversationList={false} changeRoomId={changeRoomId}/>
    </div>
}