import React from "react";
import style from "./style.module.scss";

import { useColorList } from "./utils";
import { Color } from "ivip-utils";
import { SvgIcon } from "Components";
import { mdiCircle } from "@mdi/js";

export interface RadarChartOptions {
	[p: string]: any;
	color?: Array<string | number[]>;
	min?: number;
	max?: number;
}

export type RadarChartData = {
	labels: Array<{ label: string; icon?: React.ReactNode | string }>;
	data: number[][];
};

const calcularPosicao = (raio: number, anguloEmGraus: number) => {
	const anguloEmRadianos = (anguloEmGraus * Math.PI) / 180;
	const x = raio * Math.cos(anguloEmRadianos);
	const y = raio * Math.sin(anguloEmRadianos);
	return { x, y };
};

const truncarString = (str: string, maxLength: number) => (str.length > maxLength ? str.slice(0, maxLength) + "..." : str);

const RadarChart: React.FC<{
	data: RadarChartData;
	options?: RadarChartOptions;
}> = ({ data, options = {} }) => {
	const colors = useColorList();

	const svgSize = [300, 320];
	const radarRaio = 250 / 2;

	const pontos = (raio: number = radarRaio, center: number = radarRaio) =>
		data.labels.map((_, j) => {
			const angulo = (360 / data.labels.length) * j - 90;
			const { x, y } = calcularPosicao(raio, angulo);
			return { x: center + x, y: center + y, originalX: x / raio, originalY: y / raio, angulo, raio, size: radarRaio };
		});

	const minValue = typeof options.min !== "number" ? Math.min(...data.data.flat()) * 0.85 : options.min;
	const maxValue = typeof options.max !== "number" ? Math.max(...data.data.flat()) * 1.15 : options.max;

	return (
		<div className={[style["chart-content"], style["chart-radar"]].join(" ")}>
			<svg viewBox={`0 0 ${svgSize[0]} ${svgSize[1]}`}>
				<g
					className={style["grid"]}
					style={{ transform: `translate(${svgSize[0] / 2 - radarRaio}px, ${svgSize[1] / 2 - radarRaio}px)` }}
				>
					{new Array(5).fill(null).map((_, i, self) => {
						const raio = (radarRaio / self.length) * (i + 1);
						return (
							<path
								key={i}
								d={`M${pontos(raio)
									.map(({ x, y }) => `${x},${y}`)
									.join(" L")} Z`}
							/>
						);
					})}
					{pontos().map(({ x, y }, i) => {
						return (
							<line
								key={i}
								x1={radarRaio}
								y1={radarRaio}
								x2={x}
								y2={y}
							/>
						);
					})}
					{pontos(radarRaio * 1.02).map(({ x, y, originalX, originalY }, i) => {
						const size = 30;
						const positionX = originalX < -0.5 ? -size : originalX > 0.5 ? 0 : -(size / 2);
						const positionY = originalY < -0.5 ? -size : originalY > 0.5 ? 0 : -(size / 2);

						const { label = "", icon = mdiCircle } = data.labels[i] ?? {};

						return (
							<g
								data-title={label}
								style={{ transform: `translate(${x + positionX}px, ${y + positionY}px)` }}
								key={i}
							>
								{typeof icon === "string" ? (
									<SvgIcon
										width={size}
										height={size}
										path={icon}
										style={{
											fill: "var(--text-primary)",
											opacity: 0.8,
										}}
									/>
								) : (
									icon
								)}
							</g>
						);
					})}
				</g>
				<g
					className={style["marker"]}
					style={{ transform: `translate(${svgSize[0] / 2 - radarRaio}px, ${svgSize[1] / 2 - radarRaio}px)` }}
				>
					{data.data.map((data, i) => {
						const pontos = data.map((value, j, self) => {
							const angulo = (360 / self.length) * j - 90;
							const raio = radarRaio * ((value - minValue) / (maxValue - minValue));
							const { x, y } = calcularPosicao(raio, angulo);
							return { x: radarRaio + x, y: radarRaio + y };
						});

						const color = new Color((options?.color ?? [])[i] ?? colors[i + 2] ?? "#212121");

						return (
							<React.Fragment key={i}>
								<path
									d={`M${pontos.map(({ x, y }) => `${x},${y}`).join(" L")} Z`}
									stroke={color.hex}
									fill={`rgba(${color.vector.join(", ")}, .2)`}
								/>
								{pontos.map(({ x, y }, j) => {
									return (
										<circle
											key={j}
											cx={x}
											cy={y}
											r={4}
											fill={color.hex}
										/>
									);
								})}
							</React.Fragment>
						);
					})}
				</g>
			</svg>
		</div>
	);
};

export default RadarChart;
