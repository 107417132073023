/* eslint-disable react/no-children-prop */
import React, { useState, useCallback } from "react";
import { Stack, Chip, Link } from "@mui/material";

import style from "./index.module.scss";

import { formatDate, limitString } from "./utils";
import { Markdown } from "Components";

export default ({ message: { isMe, type, context, userId }, timestamp, user = {}, prev, next, disabled, suggestionChange, roomType, ...props }) => {
	const isStart = prev === null || prev.userId !== userId;
	const isEnd = next === null || next.userId !== userId;

	let { message, suggestions } = context || {};

	message = type === "password" ? "*".repeat(8) : message || "";

	message = typeof message === "string" && message.trim() !== "" ? message : "";

	const userName = !user || !user.name ? "Carregando..." : user.name;

	return (
		<>
			<div
				className={[
					style["message"],
					isMe ? style["me"] : style["him"],
					isStart ? style["start"] : "",
					isEnd ? style["end"] : "",
					typeof roomType === "string" && roomType.trim() !== "" ? style[roomType] : "",
				].join(" ")}
				{...props}
			>
				{roomType === "group" && isStart && (
					<div
						className={style["picture"]}
						style={{
							backgroundImage: user?.picture && user?.picture.trim() !== "" ? `url(${user?.picture})` : "",
						}}
					></div>
				)}
				<div className={style["content"]}>
					{roomType === "group" && isStart && (
						<p
							className={style["title"]}
							title={userName}
						>
							{userName}
						</p>
					)}
					<Markdown
						children={String(message)}
						limitChars={255}
						nextLimitChars={500}
					/>
					<span className={style["time"]}>
						<span dir="auto">{formatDate(new Date(timestamp))}</span>
					</span>
					<div
						className={style["time-inner"]}
						title={new Date(timestamp).toLocaleString()}
					>
						<span dir="auto">{formatDate(new Date(timestamp))}</span>
					</div>
				</div>
			</div>

			{Array.isArray(suggestions) ? (
				<div className={style["suggestions"]}>
					<Stack
						direction="row"
						spacing={1}
						sx={{ flexWrap: "wrap" }}
					>
						{suggestions.map(({ value, message, action }, i) => {
							return (
								<Chip
									disabled={disabled}
									sx={{ marginBottom: "4px" }}
									key={i}
									label={message}
									variant="outlined"
									onClick={() => {
										if (typeof suggestionChange === "function") {
											suggestionChange({
												value: ["number", "string"].includes(typeof value) ? value : i,
												message: message,
											});
										}
									}}
								/>
							);
						})}
					</Stack>
				</div>
			) : null}
		</>
	);
};
