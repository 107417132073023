import React, { useState, useRef, useEffect, useLayoutEffect, useCallback } from "react";

import { useElementSize } from "Utils/useHook";
import { currencyFormat, Color } from "Utils";

import { useColorList } from "./utils";

import style from "./style.module.scss";

interface PathInfo {
	index: number;
	value: number;
	percentage: number;
	borderSize: number | undefined;
	cx: number;
	cy: number;
	radius: number;
	centerDegrees: any;
	centerPoint: {
		x: number;
		y: number;
	};
	d: string;
}

(Number as any).prototype.floor = function (decimals: number): number {
	return parseFloat(this.toFixed(decimals));
};

const numberWithCommas = (value: number): string => {
	const { thousand, decimal } = currencyFormat.getSeparators();
	value = typeof value === "number" ? value : 0;
	return value
		.toString()
		.split(".")
		.map((p, i) => (i === 0 ? p.replace(/\B(?=(\d{3})+(?!\d))/g, thousand) : p))
		.join(decimal);
};

const arcradius = (
	cx: number,
	cy: number,
	radius: number,
	degrees: number,
): {
	x: number;
	y: number;
} => {
	let radians = ((degrees - 90) * Math.PI) / 180.0;
	return { x: cx + radius * Math.cos(radians), y: cy + radius * Math.sin(radians) };
};

const Donut = (data: number[], cx: number, cy: number, radius: number, borderSize: number | undefined = undefined) => {
	const per = (valueNow: number): number => {
		let valueMin = Math.min.apply(null, data);
		let valueMax = Math.max.apply(null, data);
		console.log(valueMin, valueMax);
		return ((valueNow - valueMin) / (valueMax - valueMin)) * 100;
	};

	data = data.filter((v) => typeof v === "number").sort((a, b) => b - a);

	let decimals = 4,
		total = data.reduce((partialSum, a) => partialSum + a, 0),
		arr = [],
		beg = 0,
		end = 0;

	borderSize = typeof borderSize === "number" && borderSize >= 2 && borderSize <= radius * 0.6 ? borderSize : radius * 0.3;

	radius = radius - borderSize / 2;

	const spacing = (((borderSize * 70 * borderSize) / (borderSize * radius)) as any).floor(2);

	let paths = data
		.map((value, i, p) => (((360 - (spacing * p.length - 1)) * (value / total)) as any).floor(2))
		.map((value, i, p) => {
			return p.slice(0, i).reduce((a, b) => b + a, 0) + spacing * i;
		});

	return paths.map((value, i, p) => {
		let beg = value + spacing / 2;
		let end = (i + 1 >= p.length ? 360 : p[i + 1]) - spacing / 2;
		let centerDegrees = beg + (end - beg) * 0.5;

		let tmp: PathInfo = {
			index: i,
			value: data[i],
			percentage: total == 0 ? 0 : data[i] / total,
			borderSize: borderSize,
			cx,
			cy,
			radius: radius + (borderSize as any) / 2,
			centerDegrees: centerDegrees,
			centerPoint: arcradius(cx, cy, radius + (borderSize as any) / 2, centerDegrees),
			d: "",
		};

		let b = arcradius(cx, cy, radius, end);
		let e = arcradius(cx, cy, radius, beg);
		let la = end - beg <= 180 ? 0 : 1;

		tmp.d = [
			"M",
			(b.x as any).floor(decimals),
			(b.y as any).floor(decimals),
			"A",
			radius,
			radius,
			0,
			la,
			0,
			(e.x as any).floor(decimals),
			(e.y as any).floor(decimals),
		].join(" ");

		return tmp;
	});
};

export type PieChartOptions = Partial<{
	direction: "vertical" | "horizontal";
	indexAlign: "start" | "center" | "end";
	chartBackground: boolean;
}>;

export type PieChartData = Array<any[]>;

const PieChart: React.FC<{
	data: PieChartData;
	options?: PieChartOptions;
}> = ({ data, options }) => {
	const contentRef = useRef<HTMLDivElement | null>(null);
	const [chartViewRef, { width, height }] = useElementSize();
	const [paths, setPath] = useState<PathInfo[]>([]);
	const [labels, setLabels] = useState<any[]>([]);

	options = {
		direction: "vertical",
		chartBackground: true,
		indexAlign: "start",
		...(options ?? {}),
	};

	const colors = useColorList();

	let size = Math.min.apply(null, [width, height]);

	useLayoutEffect(() => {
		let d = data
			.slice(1, data.length)
			.filter((d) => typeof d[1] === "number")
			.sort((a, b) => b[1] - a[1]);

		const length = 8;

		const others = d.length > length ? d.slice(length) : [];

		d = others.length > 0 ? d.slice(0, length).concat([["Outros", 0]]) : d;

		const isNegative = d.reduce((total, [label, value]) => total + (value > 0 ? 0 : 1), 0) >= data.length;

		d = d.map(([label, value]) => {
			value = isNegative && value < 0 ? Math.abs(value) : !isNegative && value < 0 ? 0 : isNegative && value > 0 ? 0 : value;
			return [label, value];
		});

		setPath(() => Donut(d.length > 0 ? d.map((d) => parseFloat(d[1]) || 0) : [0], (size - 10) / 2, (size - 10) / 2, (size - 10) / 2 - 20, 20));

		setLabels(() => {
			const list = d.map((d) => d[0]);
			return list.length > 0 ? list : ["NaN"];
		});
	}, [data, width, height]);

	const getColor = useCallback(
		(i: number) => {
			i = i % paths.length;
			return paths.length > colors.length ? Color.blend(colors, i / (paths.length - 1))?.hex : colors[i];
		},
		[paths, colors],
	);

	const indexOut = useCallback(() => {
		if (!contentRef || !contentRef.current) {
			return;
		}

		const paths = contentRef.current.querySelectorAll<SVGAElement>(`.${style["chart-view"]} > svg > g`);
		const labels = contentRef.current.querySelectorAll<HTMLDivElement>(`.${style["indexes"]} > div.${style["indexes-item"]}`);

		for (let p = 0; p < paths.length; p++) {
			paths[p].style.opacity = "1";
		}

		for (let l = 0; l < labels.length; l++) {
			labels[l].style.opacity = "1";
			labels[l].style.textDecoration = "none";
		}
	}, [contentRef, paths, labels]);

	const indexOver = useCallback(
		(i: number) => {
			if (!contentRef || !contentRef.current) {
				return;
			}

			indexOut();

			const paths = contentRef.current.querySelectorAll<SVGAElement>(`.${style["chart-view"]} > svg > g`);
			const labels = contentRef.current.querySelectorAll<HTMLDivElement>(`.${style["indexes"]} > div.${style["indexes-item"]}`);

			for (let p = 0; p < paths.length; p++) {
				paths[p].style.opacity = "0.3";
			}

			for (let l = 0; l < labels.length; l++) {
				labels[l].style.opacity = "0.3";
				labels[l].style.textDecoration = "none";
			}

			paths[i].style.opacity = "1";
			labels[i].style.opacity = "1";
			labels[i].style.textDecoration = "underline";
		},
		[contentRef, paths, labels],
	);

	useLayoutEffect(() => {
		const time = setTimeout(() => {
			if (!contentRef || !contentRef.current || !contentRef.current?.querySelector) {
				return;
			}
			const svg = contentRef.current.querySelector<SVGAElement>(`.${style["chart-view"]} > svg`);
			if (!svg) {
				return;
			}
			svg.style.opacity = "1";
		}, 1000);

		return () => {
			clearTimeout(time);
		};
	}, [contentRef, data, paths, labels]);

	return (
		<div
			ref={contentRef}
			className={[style["chart-content"], style["chart-pie"], options.direction === "horizontal" ? style["chart-pie-horizontal"] : null].join(
				" ",
			)}
		>
			<div
				className={[style["chart-view"], options.chartBackground ? style.background : ""].join(" ")}
				ref={chartViewRef as any}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox={`-70 -10 ${size + 140} ${size + 5}`}
					style={{ opacity: 0 }}
				>
					{paths.map(({ d, borderSize, value, cx, cy, radius, centerDegrees, centerPoint, percentage }, i) => {
						const labelCenter = arcradius(cx, cy, radius + 15, centerDegrees);
						const labelPosition = { x: labelCenter.x + (centerDegrees <= 180 ? 10 : -10), y: labelCenter.y };

						return (
							<g key={`chart-view-g-${i}`}>
								<path
									className={style["marker"]}
									onMouseOver={() => indexOver(i)}
									onMouseOut={indexOut}
									onMouseLeave={indexOut}
									d={d}
									stroke={getColor(i)}
									fill="none"
									strokeWidth={borderSize}
									strokeLinecap="round"
								/>
								<path
									className={style["indicator"]}
									d={[
										"M",
										(centerPoint.x as any).floor(2),
										(centerPoint.y as any).floor(2),
										"L",
										(labelCenter.x as any).floor(2),
										(labelCenter.y as any).floor(2),
										(labelPosition.x as any).floor(2) - (centerDegrees <= 180 ? 5 : -5),
										(labelPosition.y as any).floor(2),
									].join(" ")}
									strokeLinecap="round"
								/>
								<text
									x={(labelPosition.x as any).floor(2)}
									y={(labelPosition.y as any).floor(2)}
									className={style["indicatorLabel"]}
									textAnchor={centerDegrees <= 180 ? "start" : "end"}
									alignmentBaseline="middle"
								>
									{numberWithCommas(((percentage * 100) as any).floor(2))}%
								</text>
							</g>
						);
					})}
				</svg>
			</div>
			<div className={[style["indexes"], style[`align-${options.indexAlign ?? "start"}`]].join(" ")}>
				{labels.map((label, i) => {
					return (
						<div
							key={`indexes-item-${i}`}
							className={style["indexes-item"]}
							onMouseOver={() => indexOver(i)}
							onMouseOut={indexOut}
							onMouseLeave={indexOut}
						>
							<div
								className={style["indexes-item-circle"]}
								style={{ background: getColor(i) }}
							></div>
							<span>{label}</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default PieChart;
