import { Button, Stack, Typography } from '@mui/material';

import { InteractionHelper } from 'Helper';

import style from './style.module.scss';
import under_maintenance from 'Resources/images/under_maintenance.png';

const MainComponent = ()=>{
    const click = ()=>{
        InteractionHelper.alert(<>
            <Typography variant="h5" gutterBottom>{"Prezado(a) usuário(a),"}</Typography>
            <br/>
            <Typography variant="body1" gutterBottom>Estamos entrando em contato para informar que estamos realizando uma importante manutenção em nosso sistema. Queremos garantir que você tenha a melhor experiência possível ao utilizar nossos serviços, por isso, estamos corrigindo possíveis bugs e aprimorando a segurança da plataforma.</Typography>
            
            <Typography variant="body1" gutterBottom>Durante esse período de manutenção, algumas funcionalidades podem ficar temporariamente indisponíveis. No entanto, fique tranquilo(a), estamos trabalhando arduamente para minimizar qualquer impacto e garantir que o sistema esteja de volta o mais breve possível, operando de forma mais eficiente e segura.</Typography>

            <Typography variant="body1" gutterBottom>Nossa equipe técnica especializada está dedicada a resolver essas questões para proporcionar a você um ambiente confiável e protegido. Reconhecemos a importância de manter a integridade dos seus dados e estamos implementando medidas para reforçar ainda mais a segurança do sistema.</Typography>

            <Typography variant="body1" gutterBottom>Agradecemos sua compreensão durante esse período de manutenção. Caso você precise de suporte ou tenha alguma dúvida, nossa equipe de atendimento está à disposição para auxiliá-lo(a). Estamos comprometidos em fornecer a você um serviço de qualidade e garantir que sua experiência seja a melhor possível.</Typography>

            <Typography variant="body1" gutterBottom>Pedimos desculpas por qualquer inconveniente causado e agradecemos sua confiança em nossos serviços.</Typography>
            <br/><br/>
            <Typography variant="body1" gutterBottom>Atenciosamente, <b>iVipCoin LTDA</b></Typography>
        </>, "Em manutenção");
    }

    return <div className={style["main"]}>
        <div className={style["banner"]} style={{
            backgroundImage: `url(${under_maintenance})`
        }}></div>
        <Typography variant="h5">Opss... Desculpe!</Typography>
        <Typography variant="h5" gutterBottom>Estamos em manutenção!</Typography>
        <Stack spacing={2} direction="row">
            <Button variant="outlined" color="inherit" onClick={click}>Entenda</Button>
        </Stack>
    </div>
}

export default MainComponent;