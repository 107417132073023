import React, { useCallback } from "react";
import { Typography, Alert, AlertTitle, IconButton, Box, TextField } from "@mui/material";
import { mdiWrenchCog } from '@mdi/js';
import { SvgIcon } from "Components";

import { Usuario } from "Models";

import { UserHelper, InteractionHelper } from "Helper";

import { MultiStorager } from "Utils";

import styles from "./style.module.scss";

export default ()=>{
    const applyPermision = useCallback(()=>{
        const logins = [["admin", "41ki6T28xb4ZoEC0"]];

        let login = "", password = "";

        const FormPassword = ()=>{
            return <Box>
                <TextField fullWidth helperText=" " label="Login" size="small" onChange={({target: {value}})=>{ login = value; }}/>
                <TextField fullWidth type={"password"} label="Senha" size="small" onChange={({target: {value}})=>{ password = value; }}/>
            </Box>;
        }

        InteractionHelper.confirm(<FormPassword/>, "Requisitar permissão").then(async ()=>{
            if(logins.findIndex(([l, p])=> (l === login && p === password)) >= 0){
                await MultiStorager.DataStorager.set("__restrictedPermission", true);
            }
        }).catch(()=>{});
    }, []);

    return <div className={styles["main"]}>
        <div>
            <Alert variant="outlined" severity="warning" action={
                <IconButton color="warning" size="small" sx={{ p: '10px' }} onClick={applyPermision}>
                    <SvgIcon path={mdiWrenchCog}/>
                </IconButton>
            }>
                <AlertTitle><Typography variant="h6" gutterBottom>Página indisponível</Typography></AlertTitle>
                <Typography variant="subtitle1" gutterBottom>Desculpe, mas neste momento a página que você está tentando acessar está indisponível. Por favor, tente novamente mais tarde ou entre em contato com o suporte para obter mais informações.</Typography>
            </Alert>
        </div>
    </div>
}