import { APIHelper, UserHelper } from "Helper";

import { Wallet, Usuario, Resultado } from "Models";
import { MultiStorager, useHook } from "Utils";

import React, { useState, useEffect } from "react";
import { getDatabase, EventStream, DataSnapshot } from "ivipbase";

import { showSwap, payInstallment, showRenegotiation, showStakingApply, showTradeSwap } from "./Interactions";
import { WalletInfo } from "Types/Wallet";

const promiseState = (p: Promise<any>) => {
	const t = { __timestamp__: Date.now() };
	return Promise.race([p, t]).then(
		(v) => (v === t ? "pending" : "fulfilled"),
		() => "rejected",
	);
};

interface StorageWallets {
	timestamp: number;
	wallets: Wallet[];
}

/**
 * @param {boolean} [immediate=true]
 * @returns {{ wallets: Wallet[], status: ("loading"|"pending"|"success"|"error"), error: catch.Error }}
 */
export const useWallets = (): {
	wallets: Wallet[];
	status: "loading" | "pending" | "success" | "error";
	error: undefined | null | string;
} => {
	const [wallets, setWallets] = useState<Wallet[]>([]);

	const { execute, value, status, error } = useHook.useAsync(async (forceFetch = false, isInit = false) => {
		const timeDiff = (a: number, b: number) => ((a > b ? a - b : b - a) / (1000 * 60)) % 60;
		let requestFetchStorager = MultiStorager.DataStorager.get("wallets-requestFetch");
		let requestFetch;

		if (requestFetchStorager && requestFetchStorager.promise instanceof Promise) {
			const status = await promiseState(requestFetchStorager.promise);
			const valid = [];

			valid.push(isInit);
			valid.push(forceFetch === true && status === "pending");
			valid.push(forceFetch !== true && status === "pending");
			valid.push(forceFetch !== true && status !== "pending" && timeDiff(Date.now(), requestFetchStorager.timestamp) <= 60 * 5);

			if (valid.includes(true)) {
				requestFetch = requestFetchStorager.promise;
			}
		}

		if (!(requestFetch instanceof Promise)) {
			requestFetch = APIHelper.fetch(`/wallet/all_wallets`);
			MultiStorager.DataStorager.set("wallets-requestFetch", { timestamp: Date.now(), promise: requestFetch });
		}

		const result = await Promise.any([requestFetch])
			.then((wallets): Promise<Wallet[]> => {
				const result: Wallet[] = [];

				for (let key in wallets) {
					result.push(new Wallet().parse(wallets[key].path, wallets[key]) as any);
				}

				MultiStorager.DataStorager.set<StorageWallets>("wallets", { timestamp: Date.now(), wallets: result });

				return Promise.resolve(result);
			})
			.catch((): Promise<Wallet[]> => {
				let walletsStorager = MultiStorager.DataStorager.get<StorageWallets>("wallets");
				if (walletsStorager && Array.isArray(walletsStorager.wallets)) {
					return Promise.resolve(walletsStorager.wallets);
				}

				return Promise.resolve([] as any);
			});

		return result.filter(({ path }) => typeof path === "string" && path.trim() !== "");
	}, true);

	useEffect(() => {
		let delay: NodeJS.Timeout | undefined;

		const callback = () => {
			clearTimeout(delay);
			delay = setTimeout(() => {
				execute(true);
			}, 10000);
		};

		const timeInterval = window.setInterval(() => {
			if (status !== "pending") {
				execute();
			}
		}, 60 * 5 * 1000);

		const userListener = MultiStorager.DataStorager.addListener("usuario", callback);

		const walletsListener = MultiStorager.DataStorager.addListener<StorageWallets>("wallets", (data) => {
			setWallets(() => data?.wallets ?? []);
		});

		execute(false, true);

		let event: EventStream<DataSnapshot<any>>;

		const init = async () => {
			const user = await UserHelper.getUser().catch(() => null);
			if (!(user instanceof Usuario) || !user.path) {
				return;
			}

			// const ref = getDatabase().ref(user.path);
			// event = ref.on("value", callback);
		};

		init();

		return () => {
			window.clearInterval(timeInterval);
			userListener.stop();
			//MultiStorager.DataStorager.deleteListener("usuario", callback);
			walletsListener.stop();
			//MultiStorager.DataStorager.deleteListener("wallets", updateWallets);
			clearTimeout(delay);
			if (event) {
				event.stop();
			}
		};
	}, []);

	return { wallets: wallets || [], status: status === "idle" ? "loading" : (status as any), error };
};

class WalletHelper {
	static generatePath(): Promise<string> {
		return new Promise(async (resolve, reject) => {
			try {
				const r = await APIHelper.fetch("use/generateWalletCode");
				let path = String(r).length !== 18 ? "0".repeat(18 - String(r).length) + String(r).substr(0, 18) : String(r);
				resolve(`Wallets/${path}`);
			} catch (erro) {
				reject(new Resultado(-1, (erro as any).message));
			}
		});
	}

	static getWalletInfo(wallet: Wallet): Promise<Wallet> {
		return new Promise(async (resolve, reject) => {
			try {
				if (!(wallet instanceof Wallet)) {
					return reject(new Resultado(-1, "no Wallet"));
				}

				const key = wallet.path?.split("/").pop();
				const balance: Pick<WalletInfo, "information"> = await APIHelper.fetch(`/wallet/balance/${key}`);

				const result = new Wallet().parse(wallet.path, Object.assign(wallet.toJson(), balance));

				result.information = Object.assign(wallet.information, balance.information);

				return resolve(result);
			} catch (erro) {
				console.log(new Resultado(-1, (erro as any).message));
			}

			resolve(wallet);
		});
	}

	static getWallet(path: string): Promise<Wallet> {
		return new Promise(async (resolve, reject) => {
			try {
				if (typeof path !== "string") {
					return reject(new Resultado(-1, "O ID da carteira é necessário e não foi informado!"));
				}
				const key = path.split("/").pop();
				const wallet = await APIHelper.fetch(`/wallet/balance/${key}`);
				resolve(new Wallet().parse(wallet.path, wallet) as any);
			} catch (erro) {
				reject(new Resultado(-1, (erro as any).message));
			}
		});
	}

	static getAllWallets(): Promise<Wallet[]> {
		return new Promise(async (resolve, reject) => {
			try {
				let walletsStorager = MultiStorager.DataStorager.get("wallets");
				const timestamp = Date.now();

				const timeDiff = (a: number, b: number) => ((a > b ? a - b : b - a) / (1000 * 60)) % 60;

				if (walletsStorager && Array.isArray(walletsStorager.wallets) && timeDiff(timestamp, walletsStorager.timestamp) <= 2) {
					resolve(walletsStorager.wallets);
					return;
				}

				//all_wallets

				let wallets: Wallet[] = await APIHelper.fetch(`/wallet/all_wallets`).catch(() => Promise.resolve([]));

				// let wallets: Wallet[] = [];

				// const user = await UserHelper.getUser();
				// const ref = getDatabase().ref("Wallets");

				// const summary = await APIHelper.fetch(`/wallet/summary`).catch(() => {});

				// for (let i = 0; i < user.wallets.length; i++) {
				// 	const walletPath = user.wallets[i];
				// 	const key = walletPath.split("/").pop();
				// 	const wallet = await ref.child(key).get();
				// 	if (wallet && wallet.exists()) {
				// 		const newWallet = new Wallet().parse(Global.getDataSnapshotPath(wallet.ref), wallet.val() ?? {}) as any;
				// 		if (summary && key in summary) {
				// 			const {
				// 				balance: { value, currencyType },
				// 			} = summary[key];

				// 			newWallet.totalValue = parseFloat((value && typeof value === "number" ? value : 0).toFixed(3));
				// 			newWallet.currencyType = typeof currencyType === "string" ? currencyType : newWallet.currencyType;
				// 		}
				// 		wallets.push(newWallet);
				// 	}
				// }

				MultiStorager.DataStorager.set("wallets", { timestamp, wallets });

				resolve(wallets);
			} catch (erro) {
				reject(new Resultado(-1, (erro as any).message));
			}
		});
	}

	static saveWallet(wallet: Wallet, user?: Usuario, data: Object = {}): Promise<Wallet> {
		return new Promise(async (resolve, reject: (erro: Resultado) => void) => {
			user = user instanceof Usuario ? user : await UserHelper.getUser().catch(() => undefined);

			try {
				if (!user) {
					return reject(new Resultado(-1, "Não foi encontrado o usuário!"));
				} else if (typeof data !== "object" || !(wallet instanceof Wallet) || !wallet.path) {
					return reject(new Resultado(-1, "Parâmetros inválidos!"));
				}
				const key = wallet.path.split("/").pop();

				wallet = (await APIHelper.fetch(`wallet/set-wallet/${key}`, {
					wallet: wallet.toJson(),
					data: JSON.parse(JSON.stringify(data)),
				}).then((res) => Promise.resolve(new Wallet().parse(wallet.path, res)))) as Wallet;

				(user as any).wallets.push(wallet.path);

				let currentUser = MultiStorager.DataStorager.get("usuario");
				if (currentUser && currentUser.path === user.path) {
					MultiStorager.DataStorager.set("usuario", user);
				}

				resolve(wallet as Wallet);
			} catch (erro) {
				reject(new Resultado(-1, (erro as any).message));
			}
		});
	}

	static createWallet(wallet: Wallet, user?: Usuario, data: Object = {}): Promise<Wallet> {
		return new Promise(async (resolve, reject: (erro: Resultado) => void) => {
			user = user instanceof Usuario ? user : await UserHelper.getUser().catch(() => undefined);

			try {
				if (!user) {
					return reject(new Resultado(-1, "Não foi encontrado o usuário!"));
				} else if (typeof data !== "object" || !(wallet instanceof Wallet) || !wallet.path) {
					return reject(new Resultado(-1, "Parâmetros inválidos!"));
				}
				const key = wallet.path.split("/").pop();

				wallet = (await APIHelper.fetch(`wallet/new-wallet/${key}`, {
					wallet: wallet.toJson(),
					data: JSON.parse(JSON.stringify(data)),
				}).then((res) => Promise.resolve(new Wallet().parse(wallet.path, res)))) as Wallet;

				(user as any).wallets.push(wallet.path);

				let currentUser = MultiStorager.DataStorager.get("usuario");
				if (currentUser && currentUser.path === user.path) {
					MultiStorager.DataStorager.set("usuario", user);
				}

				let walletsStorager = MultiStorager.DataStorager.get<StorageWallets>("wallets");
				if (walletsStorager && Array.isArray(walletsStorager.wallets)) {
					MultiStorager.DataStorager.set<StorageWallets>("wallets", {
						...walletsStorager,
						wallets: [...walletsStorager.wallets, wallet],
					});
				}

				resolve(wallet);
			} catch (erro) {
				reject(new Resultado(-1, (erro as any).message));
			}
		});
	}

	static deleteWallet(wallet: Wallet, user?: Usuario) {
		return new Promise<void>(async (resolve, reject: (erro: Resultado) => void) => {
			user = user instanceof Usuario ? user : await UserHelper.getUser().catch(() => undefined);

			try {
				if (!user) {
					return reject(new Resultado(-1, "Não foi encontrado o usuário!"));
				} else if (!(wallet instanceof Wallet) || !wallet.path) {
					return reject(new Resultado(-1, "Parâmetros inválidos!"));
				}
				const key = wallet.path.split("/").pop();

				if (!key) {
					return reject(new Resultado(-1, "Erro ao tentar excluir a carteira!"));
				}

				APIHelper.fetch(`wallet/remove-wallet/${key}`, "DELETE").then(() => {
					(user as any).wallets = ((user as any).wallets as string[]).filter((p) => p !== wallet.path);

					let currentUser = MultiStorager.DataStorager.get<Usuario>("usuario");
					if (currentUser && currentUser.path === user?.path) {
						MultiStorager.DataStorager.set("usuario", user);
					}

					//MultiStorager.DataStorager.set("wallets-requestFetch", { timestamp: Date.now(), promise: null });

					let walletsStorager = MultiStorager.DataStorager.get<StorageWallets>("wallets");
					if (walletsStorager && Array.isArray(walletsStorager.wallets)) {
						MultiStorager.DataStorager.set<StorageWallets>("wallets", {
							timestamp: Date.now(),
							wallets: walletsStorager.wallets.filter(({ path }) => path && key && path.search(key) < 0),
						});
					}

					resolve();
				});
			} catch (erro) {
				reject(new Resultado(-1, (erro as any).message));
			}
		});
	}

	static getMetaMaskKey(browser: string) {
		return new Promise(async (resolve, reject) => {
			reject();
			/*
            const { WebSocketServer } = require('ws');
            const { createServer } = require('http');
            const win = nw.Window.get();
            const { MetaMask } = require("./EthAccountsPages");

            let timeForResponse;

            win.hide();

            const server = createServer((req, res)=>{
                clearTimeout(timeForResponse);
                res.writeHead(200, {'Content-Type': 'text/html'});
                res.write(MetaMask);
                res.end();
            });

            const wss = new WebSocketServer({ server });

            const callback = (r)=>{
                server.close();
                wss.close();
                win.show();

                if(r.error){
                    reject(new Resultado(-1, "Tipo de carteira inválido!"));
                }else{
                    resolve(r.response);
                }
            }

            wss.on('connection', (ws)=>{
                ws.on('message', (data)=>{
                    clearTimeout(timeForResponse);
                    const result = JSON.parse(data.toString());
                    callback(result);
                });

                ws.send('something');
            });
            
            server.listen(8080);

            open('http://localhost:8080', {
                app: {
                    name: (browser in open.apps ? open.apps[browser] : open.apps.chrome)
                },
                wait: true
            });

            timeForResponse = setTimeout(()=>{
                callback({error: "Erro ao tentar buscar uma conta do MetaMask!", response: []});
            }, 5000);
            */
		});
	}

	static getEthAccounts(wallet: string) {
		return new Promise((resolve, reject) => {
			reject();
			// switch(String(wallet).toLocaleLowerCase()){
			//     case "metamask":
			//         this.getEthAccounts_MetaMask().then(resolve).catch(reject);
			//         break;
			//     default:
			//         reject(new Resultado(-1, "Tipo de carteira inválido!"));
			// }
		});
	}

	static showSwap(props: any): void {
		return showSwap.apply(null, [props]);
	}

	static payInstallment(walletId: string, type: "payable" | "overdue" = "overdue", props: { [k: string]: any } = {}): Promise<void> {
		return payInstallment.apply(null, [walletId, type, props]);
	}

	static showRenegotiation(props: any): void {
		return showRenegotiation.apply(null, [props]);
	}

	static showStakingApply(props: any): void {
		return showStakingApply.apply(null, [props]);
	}

	static showTradeSwap(props: { walletId: string; symbol: string[]; quantity: number; amount: number; type: "BUY" | "SELL" }) {
		return showTradeSwap.apply(null, [props]);
	}
}

export default WalletHelper;
