import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './index.css';

export default function AccessibleTabs2() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs variant="fullWidth"  
        onChange={handleChange}
        value={value}
        aria-label="Tabs where each tab needs to be selected manually">
            <Tab label="RANKING PAS" />
            <Tab label="SOTEIO" />
            
      </Tabs>
    </Box>
  );
}
