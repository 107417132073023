import React from "react";
import InteractionHelper from "Helper/InteractionHelper";
import APIHelper from "Helper/APIHelper";
import { SimulateStakingApply } from "../Components";
import { Resultado } from "Models";
import { StakingInfo } from "Types/Staking";

interface WalletInformation {
	[key: string]: any;
	information: {
		balances?: {
			[symbol: string]: {
				symbol: string;
				available: string;
				value: number;
			};
		};
	};
}

const showStakingApply = (
	props: {
		[key: string]: any;
		stakingId: number | string | null | undefined;
		amount?: number;
		available_amount?: number;
		min_allowed_amount?: number;
		max_allowed_amount?: number;
		valid_allowed_amount?: number;
		stakingInfo: Partial<StakingInfo>;
	},
	stage = 0,
) => {
	props = Object.assign(
		{
			amount: 0,
			stakingId: null,
			available_amount: 0,
			min_allowed_amount: 0,
			max_allowed_amount: 0,
			valid_allowed_amount: 0,
			stakingInfo: {},
		},
		props,
	);

	InteractionHelper.close();

	if (stage === 1) {
		InteractionHelper.confirm<any>(
			<SimulateStakingApply
				byProps={props}
				onChange={(propsChanged: { [key: string]: any }) => {
					props = Object.assign(props, propsChanged);
				}}
			/>,
			"Aplicar Staking",
		)
			.then(() => {
				showStakingApply(props, stage + 1);
			})
			.catch(() => Promise.resolve());
	} else if (stage === 2) {
		InteractionHelper.loading();
		APIHelper.fetch(`/staking/apply/${props.stakingId}`, {
			amount: props.amount,
		})
			.then(() => {
				return APIHelper.fetch("/staking", {}, true);
			})
			.then((list) => {
				if (typeof props.callback === "function") {
					props.callback(list);
				}
				InteractionHelper.close();
				InteractionHelper.toast("Staking aplicado com sucesso", null, "success");
			})
			.catch((e) => {
				console.log(e.message);
				showStakingApply(props, stage - 1);
				InteractionHelper.toast(String(e.message), null, "warning");
			});
	} else {
		InteractionHelper.loading();
		APIHelper.fetch("/staking", {}, true)
			.then((list: any) => {
				props.stakingInfo = (list as StakingInfo[]).find(({ id }) => id === props.stakingId) as Partial<StakingInfo>;
				return !props.stakingInfo ? Promise.reject(new Resultado(-1, "Staking não encontrado!")) : APIHelper.fetch(`/wallet/balance`);
			})
			.then((response: any) => {
				const { information = {} } = response as WalletInformation;

				props.available_amount = Object.values(information?.balances ?? {})
					.filter(({ symbol }) => symbol === props.stakingInfo.typeAmount)
					.reduce((a, { available = "0" }) => {
						return Math.max(0, a + parseFloat(available));
					}, 0);

				props.min_allowed_amount = props.stakingInfo.minAmount as number;
				props.max_allowed_amount = Math.max(props.stakingInfo.maxAmount as number, props.available_amount);
				props.valid_allowed_amount = Math.min(props.stakingInfo.maxAmount as number, props.available_amount);

				showStakingApply(props, stage + 1);
			})
			.catch((e) => {
				InteractionHelper.close();
				InteractionHelper.toast(String(e.message), null, "warning");
			});
	}
};

export default showStakingApply;
