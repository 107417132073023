import React, { useEffect, useCallback, useState, useRef } from "react";

import { ButtonGroup, Button, Paper, Popper, MenuItem, MenuList, Grow, ClickAwayListener, ListItemText, ListItemIcon } from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { SvgIcon } from "Components";

import { mdiCircle, mdiCashFast, mdiPencil, mdiHandCoin, mdiDelete, mdiClipboardAlert, mdiSwapVerticalCircleOutline } from "@mdi/js";

import style from "./style.module.scss";

const HorizontalButtonGroup: React.FC<{
	buttons: Array<{
		icon?: string;
		label: string;
		hidden?: boolean;
		onClick?: () => void;
		disabled?: boolean;
	}>;
}> = ({ buttons }) => {
	const [openPopperWalletActions, setOpenPopperWalletActions] = useState(false);
	const WalletActionsRef = useRef<HTMLButtonElement | null>(null);
	const mainGroupRef = useRef<HTMLDivElement | null>(null);
	const buttonGroupRef = useRef<HTMLDivElement | null>(null);
	const [menuButtons, setMenuButtons] = useState<boolean[]>([]);

	const handleOverflowButtons = useCallback(() => {
		const mainElement = mainGroupRef.current;
		let elementWidth = (mainElement?.offsetWidth ?? 0) * 0.95;
		const buttonGroupElement = buttonGroupRef.current;
		const buttonGroupChildren = buttonGroupElement?.childNodes;

		if (!buttonGroupChildren) {
			return;
		}

		let buttonWidthSum = 0;
		const lastElement = buttonGroupChildren[buttonGroupChildren.length - 1] as any;
		lastElement.style.display = "";
		lastElement.style.visibility = "";
		elementWidth -= lastElement.offsetWidth;

		let checkMost = buttonGroupChildren.length - 1;
		const menuButtons: boolean[] = [];

		for (let i = 0; i < buttonGroupChildren.length - 1; i++) {
			const buttonElement = buttonGroupChildren[i] as any;
			buttonElement.style.display = "";
			buttonElement.style.visibility = "";
			menuButtons[i] = true;

			buttonWidthSum += buttonElement.offsetWidth;

			if (buttonWidthSum >= elementWidth) {
				checkMost = Math.min(checkMost, i);
				buttonElement.style.display = "none";
				buttonElement.style.visibility = "hidden";
				menuButtons[i] = false;
			}
		}

		if (checkMost >= buttonGroupChildren.length - 1) {
			lastElement.style.display = "none";
			lastElement.style.visibility = "hidden";
		}

		setMenuButtons(() => menuButtons);
	}, [mainGroupRef, buttonGroupRef]);

	useEffect(() => {
		let time: NodeJS.Timeout;

		const fn = () => {
			clearTimeout(time);
			time = setTimeout(handleOverflowButtons, 200);
		};

		fn();
		window.addEventListener("resize", fn);

		return () => {
			clearTimeout(time);
			window.removeEventListener("resize", fn);
		};
	}, [buttons, handleOverflowButtons]);

	return (
		<>
			<div
				ref={mainGroupRef}
				className={style["HorizontalButtonGroup"]}
			>
				<ButtonGroup
					ref={buttonGroupRef}
					variant="contained"
				>
					{Array.isArray(buttons) &&
						buttons.map(({ icon, label, hidden, ...props }, index) => {
							return (
								hidden !== true && (
									<Button
										key={index}
										startIcon={<SvgIcon path={typeof icon === "string" ? icon : mdiCircle} />}
										{...props}
									>
										{label}
									</Button>
								)
							);
						})}
					<Button
						ref={WalletActionsRef}
						size="small"
						onClick={() => {
							setOpenPopperWalletActions((prevOpen) => !prevOpen);
						}}
					>
						<ArrowDropDownIcon />
					</Button>
				</ButtonGroup>
			</div>

			<Popper
				sx={{ zIndex: 9999 }}
				open={openPopperWalletActions}
				anchorEl={WalletActionsRef.current}
				transition
				placement="bottom-end"
				container={document.body}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{ transformOrigin: "right top" }}
					>
						<Paper>
							<ClickAwayListener
								onClickAway={() => {
									setOpenPopperWalletActions(false);
								}}
							>
								<MenuList>
									{Array.isArray(buttons) &&
										buttons.map(({ icon, label, hidden, ...props }, index) => {
											return (
												hidden !== true &&
												menuButtons[index] !== true && (
													<MenuItem
														key={index}
														{...props}
													>
														<ListItemIcon>
															<SvgIcon path={typeof icon === "string" ? icon : mdiCircle} />
														</ListItemIcon>
														<ListItemText>{label}</ListItemText>
													</MenuItem>
												)
											);
										})}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};

export default HorizontalButtonGroup;
