import FirestoreObject, { DataTypes } from "./FirestoreObject";

import Address from "./Address";

class Identification extends FirestoreObject {
	type: string = "CPF";
	number: string = "";
	constructor(path: string | null | undefined = undefined) {
		super(path, {
			type: { type: DataTypes.String, default: "CPF" },
			number: { type: DataTypes.String, default: "" },
		});
	}
}

class DefinitionsGifts extends FirestoreObject {
	shirt: {
		sex: string;
		size: string;
	} = {
		sex: "unissex",
		size: "M",
	};
	receiveAtAddress: boolean = true;

	constructor(path: string | null | undefined = undefined) {
		super(path, {
			shirt: {
				type: DataTypes.Object,
				properties: {
					sex: { type: DataTypes.String, default: "unissex" },
					size: { type: DataTypes.String, default: "M" },
				},
			},
			receiveAtAddress: { type: DataTypes.Boolean, default: true },
		});
	}
}

type permissoes = ReturnType<typeof Usuario.permissoesList>[keyof ReturnType<typeof Usuario.permissoesList>] | string;

export default class Usuario extends FirestoreObject<Usuario> {
	fotoPerfil: string = "";
	atSign: string = "";
	nome: string = "";
	email: string = "";
	telefone: string = "";
	descricao: string = "";
	municipio: string = "";
	estado: string = "";
	pais: string = "";
	cep: string = "";
	endereco: string = "";
	numero: string = "";
	complemento: string = "";
	bairro: string = "";
	address: Address = new Address();
	permissoes: permissoes[] = [];
	identification: Identification = new Identification();
	wallets: string[] = [];
	points: number = 40;
	referenceCode: string = "";
	referenceBy: string = "";
	dataCriacao: Date = new Date();
	dataModificacao: Date = new Date();
	definitionsGifts: DefinitionsGifts = new DefinitionsGifts();

	constructor(path: string | null | undefined = undefined) {
		super(path, {
			fotoPerfil: { type: DataTypes.String, default: "" },
			atSign: { type: DataTypes.String, default: "" },
			nome: { type: DataTypes.String, default: "" },
			email: { type: DataTypes.String, default: "" },
			telefone: { type: DataTypes.String, default: "" },
			descricao: { type: DataTypes.String, default: "" },
			municipio: { type: DataTypes.String, default: "" },
			estado: { type: DataTypes.String, default: "" },
			pais: { type: DataTypes.String, default: "" },
			cep: { type: DataTypes.String, default: "" },
			endereco: { type: DataTypes.String, default: "" },
			numero: { type: DataTypes.String, default: "" },
			complemento: { type: DataTypes.String, default: "" },
			bairro: { type: DataTypes.String, default: "" },
			address: { type: new Address() },
			permissoes: { type: DataTypes.Array, items: { type: DataTypes.String } },
			identification: { type: new Identification() },
			wallets: { type: DataTypes.Array, items: { type: DataTypes.String } },
			points: { type: DataTypes.Number, default: 40 },
			referenceCode: { type: DataTypes.String, default: "" },
			referenceBy: { type: DataTypes.String, default: "" },
			dataCriacao: {
				type: DataTypes.Date,
				default: Date.now(),
				get: (value: any) => {
					return new Date(value);
				},
			},
			dataModificacao: {
				type: DataTypes.Date,
				default: Date.now(),
				get: (value: any) => {
					return new Date(value);
				},
			},
			definitionsGifts: { type: new DefinitionsGifts() },
		});
	}

	addPermissao(permissao: permissoes | Array<permissoes>) {
		const permissoes = typeof permissao === "string" ? [permissao] : permissao;

		if (Array.isArray(permissoes) !== true || permissoes.length <= 0) {
			return;
		}

		permissoes.forEach((p) => {
			if (p in Usuario.permissoesList() && this.verificarPermissao(p) !== true) {
				this.permissoes.push(p);
			}
		});
	}

	removerPermissao(permissao: permissoes | Array<permissoes>) {
		const permissoes = typeof permissao === "string" ? [permissao] : permissao;

		if (
			Array.isArray(permissoes) !== true ||
			permissoes.length <= 0 ||
			this.permissoes.length <= 0 ||
			this.verificarPermissao(permissoes) !== true
		) {
			return;
		}

		permissoes.forEach((p) => {
			let i = this.permissoes.indexOf(p);
			if (i < 0) {
				return;
			}
			this.permissoes.splice(i, 1);
		});
	}

	verificarPermissao(permissao: permissoes | Array<permissoes>) {
		const permissoes = typeof permissao === "string" ? [permissao] : permissao;

		if (Array.isArray(permissoes) !== true || permissoes.length <= 0 || this.permissoes.length <= 0) {
			return false;
		}

		return (
			permissoes.reduce((accumulator, currentValue) => {
				return this.permissoes.includes(currentValue) ? accumulator + 1 : accumulator;
			}, 0) > 0
		);
	}

	static verificarPermissao(permissao: permissoes | Array<permissoes>, current: Array<permissoes>) {
		return new Usuario()
			.parse(null, {
				permissoes: current,
			})
			.verificarPermissao(permissao);
	}

	static permissoesList() {
		return {
			Desenvolvedor: "desenvolvedor",
			Root: "root",
			Administrador: "administrador",
			Gestor: "gestor",
			Restrito: "restrito",
		} as const;
	}

	getLevel(): {
		level: number;
		minPoints: number;
		maxPoints: number;
		points: number;
	} {
		const levels = Usuario.rangeLevels();
		let level: {
			level: number;
			minPoints: number;
			maxPoints: number;
			points?: number;
		} = {
			level: 0,
			minPoints: 0,
			maxPoints: 0,
		};

		if (this.points > levels[levels.length - 1].maxPoints) {
			level = levels[levels.length - 1];
		} else if (this.points < levels[0].minPoints) {
			level = levels[0];
		} else {
			level = levels.find((v) => v.minPoints <= this.points && v.maxPoints > this.points) ?? { level: 0, minPoints: 0, maxPoints: 0 };
		}

		level.points = level.minPoints >= this.points ? level.minPoints : level.maxPoints < this.points ? level.maxPoints : this.points;

		return level as any;
	}

	static rangeLevels() {
		let levels = [150, 150, 200, 200, 250, 250, 270, 270, 300, 300, 320, 320, 320, 340, 340, 360, 360, 380, 380, 400];

		let pointsRange = [0];

		return levels.map((v, i) => {
			pointsRange.push(pointsRange[i] + v);
			return {
				level: i + 1,
				minPoints: pointsRange[i],
				maxPoints: pointsRange[i + 1],
			};
		});
	}
}
