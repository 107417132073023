import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, Grid, IconButton, TextField } from "@mui/material";
import { validBep20 } from "Dialog/utils";
import { APIHelper, InteractionHelper } from "Helper";
import React, { useLayoutEffect } from "react";
import style from "./index.module.scss";
import { mdiCheckCircle, mdiClockOutline, mdiDelete } from "@mdi/js";
import { SvgIcon } from "Components";
import { Bep20TrustList } from "Types/Settings";
import { timeFormat } from "Utils";

const Bep20Trust: React.FC = () => {
	const [showError, setShowError] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [name, setName] = React.useState("");
	const [address, setAddress] = React.useState("");

	const [listAddress, setListAddress] = React.useState<Bep20TrustList>([]);

	const addNewBep20 = async () => {
		setLoading(true);
		await new Promise((resolve) => setTimeout(resolve, 1000));
		if (name.trim() === "" || validBep20(address) !== true) {
			InteractionHelper.toast("Preencha os campos corretamente", undefined, "error");
			setLoading(false);
			return;
		}

		APIHelper.fetch<Bep20TrustList>("/settings/bep20_trust", "POST", {
			name,
			address,
		})
			.then((res) => {
				InteractionHelper.toast("Endereço BEP-20 cadastrado com sucesso", undefined, "success");
				setListAddress(res);
				setName("");
				setAddress("");
			})
			.catch((e) => {
				InteractionHelper.toast(`Erro ao cadastrar endereço BEP-20: ${String(e.message)}`, undefined, "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const deleteBep20 = async (id: string) => {
		setLoading(true);
		await new Promise((resolve) => setTimeout(resolve, 1000));

		APIHelper.fetch<Bep20TrustList>(`/settings/bep20_trust/${id}`, "DELETE")
			.then((res) => {
				InteractionHelper.toast("Endereço BEP-20 deletado com sucesso", undefined, "success");
				setListAddress(res);
			})
			.catch((e) => {
				InteractionHelper.toast(`Erro ao deletar endereço BEP-20: ${String(e.message)}`, undefined, "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useLayoutEffect(() => {
		const time = setTimeout(() => {
			APIHelper.fetch<Bep20TrustList>("/settings/bep20_trust", "GET")
				.then((res) => {
					setListAddress(res);
				})
				.catch((e) => {
					InteractionHelper.toast("Erro ao tentar carregar a lista de endereços BEP-20", undefined, "error");
				})
				.finally(() => {
					setLoading(false);
				});
		}, 1000);

		return () => {
			clearTimeout(time);
		};
	}, []);

	return (
		<>
			<div
				style={{
					padding: "15px 10px",
				}}
			>
				<Grid
					container
					spacing={2}
					component="form"
					noValidate
					autoComplete="off"
				>
					<Grid
						item
						xs={12}
						md={4}
					>
						<TextField
							error={showError && name.trim() === ""}
							label="Nome"
							helperText={showError && name.trim() === "" ? "Campo obrigatório" : " "}
							size="small"
							fullWidth
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={8}
					>
						<TextField
							error={(showError || address.length > 0) && validBep20(address) !== true}
							label="Carteira"
							helperText={(showError || address.length > 0) && validBep20(address) !== true ? "Endereço BEP-20 inválido" : " "}
							size="small"
							fullWidth
							value={address}
							onChange={(e) => setAddress(e.target.value)}
						/>
					</Grid>
				</Grid>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<LoadingButton
						loading={loading}
						variant="outlined"
						onClick={addNewBep20}
					>
						Cadastrar
					</LoadingButton>
				</div>
			</div>
			<div className={style.Bep20TrustList}>
				{listAddress
					.sort((a, b) => {
						return new Date(b.validDate).getTime() - new Date(a.validDate).getTime();
					})
					.map(({ id, name, address, validDate }, i) => {
						address = address.slice(0, 6) + "..." + address.slice(-4);
						validDate = new Date(validDate).getTime();

						return (
							<Box
								key={`Bep20TrustList-${i}`}
								component="div"
								className={Date.now() > validDate ? style.valid : ""}
							>
								<SvgIcon path={Date.now() < validDate ? mdiClockOutline : mdiCheckCircle} />
								<div
									style={{
										fontWeight: "bold",
									}}
								>
									{name}
								</div>
								<div
									style={{
										flex: 1,
										opacity: 0.6,
									}}
								>
									{address}
								</div>
								{Date.now() < validDate && (
									<div
										style={{
											opacity: 0.6,
											fontSize: "0.8rem",
											fontStyle: "italic",
										}}
									>
										Válido em: {timeFormat(validDate - Date.now())}
									</div>
								)}
								<IconButton
									onClick={loading ? undefined : () => deleteBep20(id)}
									disabled={loading}
								>
									<SvgIcon path={mdiDelete} />
								</IconButton>
							</Box>
						);
					})}
			</div>
		</>
	);
};

export default Bep20Trust;
