import { Button, ButtonGroup, Grid } from "@mui/material";
import { Usuario, Wallet } from "Models";
import React from "react";
import ViewCoins from "./ViewCoins";
import { Chart } from "Components";
import { currencyFormat } from "Utils";

import style from "./style.module.scss";
import ViewPerformanceResumo from "./ViewPerformanceResumo";

const ViewStatistic: React.FC<
	Partial<{
		[p: string]: any;
		wallet: Wallet | null;
		user: Usuario | null;
		activeEye: boolean;
	}>
> = ({ wallet, user, activeEye = false, ...props }) => {
	const permission = user instanceof Usuario ? user.verificarPermissao(["root", "desenvolvedor", "restrito"]) : false;

	return (
		<Grid
			container
			spacing={"20px"}
			columnSpacing={3}
			style={{ paddingTop: 25 }}
			alignItems="stretch"
		>
			<Grid
				item
				xs={12}
				lg={6}
			>
				<ViewCoins
					wallet={wallet}
					activeEye={activeEye}
				/>
			</Grid>

			<Grid
				item
				xs={12}
				lg={6}
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
			>
				<div
					className={style.Box}
					style={{ flex: "1" }}
				>
					<ViewPerformanceResumo
						wallet={wallet}
						secret={activeEye}
					/>
				</div>

				<div className={style.Box}>
					{permission && (
						<React.Fragment>
							<Chart
								height={300}
								chartType={"AreaChart"}
								data={[
									["Dia", "Sales", "Expenses"],
									[new Date(2022, 7, 11), 60],
									[new Date(2022, 7, 12), 65],
									[new Date(2022, 7, 13), 43],
									[new Date(2022, 7, 14), -50],
									[new Date(2022, 7, 15), -10],
									[new Date(2022, 7, 16), 0],
									[new Date(2022, 7, 17), 90],
									[new Date(2022, 7, 18), 81],
									[new Date(2022, 7, 19), 70],
									[new Date(2022, 7, 20), 50],
									[new Date(2022, 7, 21), 70],
									[new Date(2022, 7, 22), 78],
								]}
								options={{
									format: {
										vAxis: (v: number | string) => {
											return currencyFormat.convert(v, "USD");
										},
									},
								}}
								width={undefined}
								style={undefined}
							/>
							<div className={style["Chart-ButtonGroup"]}>
								<ButtonGroup
									variant="outlined"
									size="small"
								>
									<Button variant="contained">Patrimônio</Button>
									<Button>Operação</Button>
									<Button>Eficiência</Button>
									<Button>Ordens</Button>
									<Button>Categoria</Button>
								</ButtonGroup>
							</div>
						</React.Fragment>
					)}
				</div>
			</Grid>

			<Grid
				item
				xs={12}
				lg={12}
			>
				<div className={style.Box}>
					<Chart
						height={300}
						chartType={"ProgressProfile"}
						data={{
							data: [],
							indexes: [],
							labels: [],
						}}
					/>
				</div>
			</Grid>
		</Grid>
	);
};

export default ViewStatistic;
