import React, { useCallback, useLayoutEffect, useState } from "react";

import style from "./style.module.scss";
import { StatisticFinancial } from "Types/Statistic";
import { Chart, PageHeaderWrapper, SvgIcon, TableCustom } from "Components";
import { APIHelper, InteractionHelper } from "Helper";
import { maskAmount } from "Utils";
import { Wallet } from "Models";
import { Typography } from "@mui/material";
import { TableOrdes } from "Types/Wallet";
import { mdiAlertCircle, mdiChevronDoubleDown, mdiChevronDoubleUp } from "@mdi/js";
import { ViewPaymentDialog } from "Dialog";

//https://api.ivipcoin.com/v1/statistic/financial

const EconomiaAoVivo: React.FC = () => {
	const [initialTime] = useState<number>(Date.now());
	const [data, set_data] = useState<StatisticFinancial | null>(null);
	const [loading, set_loading] = useState<boolean>(true);
	const [lastTotalAmount, setLastTotalAmount] = useState<number[]>([-1, 0, 1]);

	const [history_orders, set_history_orders] = useState<TableOrdes | null>(null);
	const [history_orders_loading, set_history_orders_loading] = useState<boolean>(true);

	const forceUpdateStatistic = () => {
		APIHelper.fetch<StatisticFinancial>("statistic/financial", {}, 5)
			.then((data) => {
				set_data(data);
				set_history_orders(data.history_orders.data);
				setLastTotalAmount((p) => {
					return [...p.slice(-2), data?.total ?? 0];
				});
			})
			.catch(() => {
				InteractionHelper.toast("Não foi possível carregar os dados da economia ao vivo", null, "error");
			})
			.finally(() => {
				set_loading(false);
				set_history_orders_loading(false);
			});
	};

	useLayoutEffect(() => {
		const inProcess = lastTotalAmount.every((elemento, i, array) => elemento === array[0]) !== true;
		if (!inProcess) {
			return;
		}

		const time = setTimeout(() => forceUpdateStatistic(), data === null ? 1000 : 10000);

		return () => {
			clearTimeout(time);
		};
	}, [lastTotalAmount, data]);

	const loadPageHistoryorders = useCallback(
		(page: number) => {
			set_history_orders_loading(true);
			APIHelper.fetch<TableOrdes>(
				"finances/orders",
				{
					page: page,
					take: data?.history_orders.take ?? 15,
				},
				10,
			)
				.then((data) => {
					set_history_orders(data);
				})
				.catch(() => {
					InteractionHelper.toast("Não foi possível carregar os dados da economia ao vivo", null, "error");
				})
				.finally(() => {
					set_history_orders_loading(false);
				});
		},
		[data],
	);

	const filters = [
		{
			title: "Tipo de operação",
			dataIndex: "type",
			options:
				data?.history_orders.filters?.type.map((item) => {
					return {
						label: item.label,
						value: item.key,
					};
				}) ?? [],
		},
		{
			title: "Situação",
			dataIndex: "status",
			options:
				data?.history_orders.filters?.status.map((item) => {
					return {
						label: item.label,
						value: item.key,
					};
				}) ?? [],
		},
	];

	const rows =
		history_orders?.list.map((order) => {
			const { id, date_last_updated, type, original_amount, payment_method, status, currency_id, walletInfo } = order;
			return {
				id,
				date_last_updated,
				type,
				original_amount,
				payment_method,
				status,
				currency_id,
				wallet_id: walletInfo.id,
			};
		}) ?? [];

	const inProcess = lastTotalAmount.every((elemento, i, array) => elemento === array[0]) !== true;

	return (
		<PageHeaderWrapper
			colorDark="#455a64"
			colorLight="#cfd8dc"
			title="Economia ao vivo"
			header={
				<>
					{(inProcess || history_orders?.processing) && (
						<div className={style["warning"]}>
							<SvgIcon path={mdiAlertCircle} />
							{Date.now() - initialTime > 1000 * 50 ? (
								<div>
									O carregamento de dados em processamento está levando mais tempo do esperado. Volte mais tarde para obter dados
									mais precisos
								</div>
							) : (
								<div>Carregamento de dados em processamento; informações abaixo podem estar incompletas</div>
							)}
						</div>
					)}
					<div className={style["indicators"]}>
						<div className={style["primary"]}>
							<div className={style["title"]}>Entrada do mês</div>
							<div className={style["value"]}>{maskAmount(data?.entry_of_month ?? 0, 2, "$")}</div>
						</div>
						<div className={style["secondary"]}>
							<div className={style["title"]}>Saída do mês</div>
							<div className={style["value"]}>{maskAmount(data?.outflow_of_month ?? 0, 2, "$")}</div>
						</div>
						<div>
							<div className={style["title"]}>Movimentação do mês</div>
							<div className={style["value"]}>{maskAmount(data?.movement_of_month ?? 0, 2, "$")}</div>
						</div>
						<div className={data && data.performance ? style[data.performance > 0 ? "success" : "warning"] : undefined}>
							<div className={style["title"]}>Performance</div>
							<div className={style["value"]}>
								{data && data.performance ? (
									<SvgIcon path={data.performance > 0 ? mdiChevronDoubleUp : mdiChevronDoubleDown} />
								) : null}
								{maskAmount(data?.performance ?? 0, 2, "%", "RTL")}
							</div>
						</div>
						<div>
							<div className={style["title"]}>Entrada total</div>
							<div className={style["value"]}>{maskAmount(data?.total_entry ?? 0, 2, "$")}</div>
						</div>
						<div>
							<div className={style["title"]}>Saída total</div>
							<div className={style["value"]}>{maskAmount(data?.total_outflow ?? 0, 2, "$")}</div>
						</div>
						<div>
							<div className={style["title"]}>Movimentação total</div>
							<div className={style["value"]}>{maskAmount(data?.total ?? 0, 2, "$")}</div>
						</div>
						<div>
							<div className={style["title"]}>Total de empréstimo movimentado</div>
							<div className={style["value"]}>{maskAmount(data?.total_loan ?? 0, 2, "$")}</div>
						</div>
					</div>
				</>
			}
		>
			<div className={style["main"]}>
				<Chart
					height={400}
					chartType="Comparative"
					data={{
						labels:
							data?.candles?.map(({ label }) => {
								return label;
							}) ?? [],
						primary:
							data?.candles?.map(({ data }) => {
								return Object.values(data).reduce((a, b) => a + (b.entry ?? 0), 0);
							}) ?? [],
						secondary:
							data?.candles?.map(({ data }) => {
								return Object.values(data).reduce((a, b) => a + (b.outflow ?? 0), 0);
							}) ?? [],
					}}
					options={{
						axises: {
							y: {
								render: (v) => {
									return maskAmount(v, 2, "$");
								},
							},
						},
					}}
				/>
				<br />
				<Typography
					variant="h5"
					gutterBottom
				>
					Histórico das operações
				</Typography>
				<TableCustom
					loading={history_orders_loading}
					filters={filters}
					onDoubleClick={(item) => {
						ViewPaymentDialog.show({ wallet: item.wallet_id, paymentId: item.id });
					}}
					pagination={{
						total: history_orders?.lengthPages ?? 0,
						current: history_orders?.page ?? 0,
					}}
					onPaginationChange={loadPageHistoryorders}
					columns={[
						{
							title: "Data",
							dataIndex: "date_last_updated",
							type: "datetime",
							stickyCol: true,
							lineBreak: false,
						},
						{
							title: "Ordem ID",
							dataIndex: "id",
							type: "text",
							align: "center",
						},
						{
							title: "Tipo de operação",
							dataIndex: "type",
							type: "text",
							align: "center",
							lineBreak: false,
							render(value, record, index) {
								const { icon, label } = Wallet.getMethodRenderBy(value);
								return (
									<div className={style[`movement-method`]}>
										{icon}
										<span>{label}</span>
									</div>
								);
							},
						},
						{
							title: "Valor",
							dataIndex: "original_amount",
							type: "text",
							align: "right",
							lineBreak: false,
							render(value, record, index) {
								return maskAmount(value, 4, record.currency_id, "RTL");
							},
						},
						{
							title: "Metódo",
							dataIndex: "payment_method",
							type: "text",
							align: "center",
						},
						{
							title: "Situação",
							dataIndex: "status",
							type: "text",
							align: "center",
							render(value, record, index) {
								const byStatus = ["charged_back", "discounted", "drawee", "transferred"].includes(value)
									? "draw"
									: ["rejected", "refunded", "cancelled"].includes(value)
									? "rejected"
									: ["approved", "authorized"].includes(value)
									? "approved"
									: "pending";

								const label = {
									draw: "Sacado",
									rejected: "Rejeitado",
									approved: "Aprovado",
									pending: "Pendente",
								}[byStatus];

								return (
									<div className={style[`movement-status-${byStatus}`]}>
										<div>{label}</div>
									</div>
								);
							},
						},
					]}
					data={rows}
				/>
			</div>
		</PageHeaderWrapper>
	);
};

export default EconomiaAoVivo;
