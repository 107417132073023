import React from "react";

import { Typography } from "@mui/material";

import { Icons } from "Components";

import style from "./style.module.scss";

const SelectCurrency: React.FC<{
	byCurrency?: string;
	currencyName?: string;
	label?: string;
}> = ({ byCurrency = "IVIP", currencyName = "IVIPCOIN", label = "Moeda:" }) => {
	return (
		<div className={style["select-currency"]}>
			<Typography
				className={style["select-label"]}
				component="div"
				variant="subtitle1"
			>
				{label}
			</Typography>
			<div className={style["select-item"]}>
				<Icons
					name={String(byCurrency).toUpperCase()}
					style={{ marginRight: 15 }}
				/>
				<div>
					<Typography component="h6">{String(byCurrency).toUpperCase()}</Typography>
					<Typography component="div">{currencyName}</Typography>
				</div>
			</div>
		</div>
	);
};

export default SelectCurrency;
