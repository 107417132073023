import React from "react";

import { Typography, IconButton } from "@mui/material";

import style from "../utils/style.module.scss";

import { barcode, qrcode, svg2url } from "pure-svg-code";

import { mdiPrinter, mdiWhatsapp, mdiHandCoin, mdiContentCopy } from "@mdi/js";

import { SvgIcon } from "Components";

import { copyStringToClipboard, currencyFormat } from "Utils";
import { OrderInfo } from "Models";
import InteractionHelper from "Helper/InteractionHelper";

import { PhaseProps, Structure } from "Dialog/utils";
import { APIHelper } from "Helper";

interface ViewPaymentDialog {
	wallet: string;
	paymentId: string;
	info: Partial<OrderInfo>;
}

export default new Structure<ViewPaymentDialog>(
	"BEP-20",
	{
		wallet: "",
		paymentId: "",
		info: {},
	},
	[
		({ state, nextPhase, reject }) => {
			const walletId = state.wallet
				.replace(/(\/+)$/gi, "")
				.split("/")
				.pop();

			APIHelper.get<OrderInfo>(`finances/info_by/${walletId}/history/${state.paymentId}`)
				.then((response) => {
					state.info = response;
					nextPhase();
				})
				.catch((e) => {
					reject(e);
				});
		},
		{
			phase: (p) => {
				return <ViewPayment {...p} />;
			},
			type: "alert",
		},
	],
);

const getCurrencyFormat = (value: string | number | undefined, currency_id: string) => {
	const isCurrencySystem = currencyFormat.indexOf(currency_id) >= 0;
	const currency = !isCurrencySystem ? "USD" : currency_id;
	value = currencyFormat.convert(value as string | number, currency);
	return !isCurrencySystem && value ? `${(value as string).split(/\s/gi).pop()} ${currency_id}` : value;
};

const ViewTariffs: React.FC<Partial<OrderInfo>> = ({ currency_id = "USD", original_amount = 0, total_amount = 0, details = {} }) => {
	return (
		<>
			<Typography
				variant="h5"
				sx={{ marginTop: "30px", marginBottom: "15px" }}
			>
				Tarifas aplicadas:
			</Typography>

			<div className={style["list-info"]}>
				<div>
					<div className={style["label"]}>
						<Typography
							variant="subtitle1"
							component="div"
						>
							Valor desejado
						</Typography>
					</div>
					<div className={style["value"]}>
						<Typography
							variant="h6"
							component="div"
						>
							{getCurrencyFormat(original_amount, currency_id)}
						</Typography>
					</div>
				</div>

				{details && details.costs && Array.isArray(details.costs) && details.costs.length > 0 ? (
					details.costs.map(({ title, label, amount }, index) => {
						return (
							<div key={index}>
								<div className={style["label"]}>
									<Typography
										variant="body2"
										component="div"
									>
										{title}
									</Typography>
									<Typography
										variant="caption"
										component="div"
									>
										{label}
									</Typography>
								</div>
								<div className={style["value"]}>
									<Typography
										variant="subtitle1"
										component="div"
									>
										{total_amount >= original_amount ? "+" : "-"} {getCurrencyFormat(amount, currency_id)}
									</Typography>
								</div>
							</div>
						);
					})
				) : (
					<div>
						<div className={style["label"]}>
							<Typography
								variant="subtitle1"
								component="div"
								sx={{ margin: "0px auto" }}
							>
								<i>Sem taxas</i>
							</Typography>
						</div>
					</div>
				)}

				<div>
					<div className={style["label"]}>
						<Typography
							variant="subtitle1"
							component="div"
						>
							Valor a pagar
						</Typography>
					</div>
					<div className={style["value"]}>
						<Typography
							variant="h6"
							component="div"
						>
							{getCurrencyFormat(total_amount, currency_id)}
						</Typography>
					</div>
				</div>
			</div>
		</>
	);
};

const ViewPayment: React.FC<PhaseProps<ViewPaymentDialog>> = ({ state }) => {
	const {
		currency_id = "USD",
		payment_method: type = "whatsapp",
		original_amount = 0,
		total_amount = 0,
		date_last_updated = Date.now(),
		status = "pending",
		status_detail = "pending",
		description,
		details = {},
	} = state.info;

	if (
		[
			"approved",
			"drawee",
			"rejected",
			"in_mediation",
			"in_process",
			"refunded",
			"cancelled",
			"charged_back",
			"discounted",
			"transferred",
		].includes(status)
	) {
		const color = ["rejected", "refunded", "cancelled", "charged_back"].includes(status)
			? "red"
			: ["in_mediation", "in_process"].includes(status)
			? "warn"
			: "success";

		return (
			<div className={[style["dialogPayment"], style["resolved"], style[`color-${color}`]].join(" ")}>
				<div className={style["amount"]}>
					<span>
						{["refunded", "charged_back"].includes(status) ? "- " : ""}
						{getCurrencyFormat(original_amount, currency_id)}
					</span>
				</div>
				<div className={style["description"]}>
					{description}
					{typeof details?.transactionHash === "string" && (
						<>
							<br />
							<br />
							Hash da transação:{" "}
							<a
								href={`https://bscscan.com/tx/${details?.transactionHash}`}
								target="_blank"
							>
								{details?.transactionHash}
							</a>
						</>
					)}
				</div>

				<Typography
					variant="h5"
					sx={{ marginTop: "30px", marginBottom: "15px" }}
				>
					Detalhes/Situação:
				</Typography>

				<div className={style["list-info"]}>
					<div>
						<div className={style["label"]}>
							<Typography
								variant="subtitle1"
								component="div"
							>
								<i>Situação</i>
							</Typography>
						</div>
						<div className={style["value"]}>
							<Typography
								variant="h6"
								component="div"
								className={style["apply-color"]}
							>
								<b>{OrderInfo.statusLabel[status].toUpperCase()}</b>
							</Typography>
						</div>
					</div>
					{["approved", "drawee"].includes(status) !== true && (
						<div>
							<div className={style["label"]}>
								<Typography
									variant="body2"
									component="div"
								>
									Detalhe/Motivo
								</Typography>
							</div>
							<div className={style["value"]}>
								<Typography
									variant="subtitle2"
									component="div"
								>
									{OrderInfo.statusDetailLabels[status_detail]}
								</Typography>
							</div>
						</div>
					)}
					<div>
						<div className={style["label"]}>
							<Typography
								variant="body2"
								component="div"
							>
								Data aplicada
							</Typography>
						</div>
						<div className={[style["value"], style["apply-color"]].join(" ")}>
							<Typography
								variant="subtitle1"
								component="div"
							>
								{new Date(date_last_updated).toLocaleDateString()}
							</Typography>
						</div>
					</div>
				</div>

				<ViewTariffs
					currency_id={currency_id}
					original_amount={original_amount}
					total_amount={total_amount}
					details={details}
				/>
			</div>
		);
	}

	let imageCode = "";

	if (["bolbradesco", "pec"].includes(type) && details && details.barcode) {
		imageCode = barcode(typeof details.barcode === "string" ? details.barcode : details.barcode.content, "code93", {
			width: 300,
			color: "#212121",
			barHeight: 50,
		});
	} else if (type === "pix" && details && details.qr_code) {
		imageCode = qrcode({
			content: details.qr_code,
			padding: 0,
			width: 256,
			height: 256,
			color: "#212121",
			background: "transparent",
			ecl: "M",
		});
	} else if (type === "cryptocurrency" && details && details.bep20_address) {
		imageCode = qrcode({
			content: details.bep20_address,
			padding: 0,
			width: 256,
			height: 256,
			color: "#212121",
			background: "transparent",
			ecl: "M",
		});
	}

	return (
		<div className={style["dialogPayment"]}>
			{["bolbradesco", "pix", "pec", "cryptocurrency"].includes(type) && (
				<div className={style["payment-code"]}>
					<div
						className={style["payment-code-image"]}
						style={{ width: ["bolbradesco", "pec"].includes(type) ? "100%" : "auto" }}
					>
						<object data={svg2url(imageCode)}></object>
					</div>
					<div className={style["payment-code-input"]}>
						<div className={style["payment-code-input-value"]}>
							<Typography variant="subtitle2">
								{["bolbradesco", "pec"].includes(type)
									? typeof details?.barcode === "string"
										? details.barcode ?? ""
										: details?.barcode?.content ?? ""
									: details?.qr_code ?? ""}
							</Typography>
						</div>
						<IconButton
							size="large"
							disableRipple
							onClick={() => {
								copyStringToClipboard(
									["bolbradesco", "pec"].includes(type)
										? typeof details?.barcode === "string"
											? details.barcode ?? ""
											: details?.barcode?.content ?? ""
										: type === "pix"
										? details?.qr_code ?? ""
										: details?.bep20_address ?? "",
								)
									.then(() => {
										InteractionHelper.toast("Código copiado com sucesso", null, "success");
									})
									.catch(() => {
										InteractionHelper.toast("Não foi possível copiar o código", null, "error");
									});
							}}
						>
							<SvgIcon path={mdiContentCopy} />
						</IconButton>
						{["bolbradesco", "pec"].includes(type) && (
							<IconButton
								size="large"
								disableRipple
								onClick={() => {
									if (["bolbradesco", "pec"].includes(type)) {
										window.open(details?.external_resource_url, "_blank");
									} else if (type === "pix") {
										window.open(details?.ticket_url, "_blank");
									}
								}}
							>
								<SvgIcon path={mdiPrinter} />
							</IconButton>
						)}
					</div>
				</div>
			)}

			{["whatsapp", "emprestimo"].includes(type) && (
				<div className={style["payment-icon"]}>
					<SvgIcon path={type === "whatsapp" ? mdiWhatsapp : mdiHandCoin} />
				</div>
			)}

			<div className={style["description"]}>
				{description}
				{typeof details?.transactionHash === "string" && (
					<>
						<br />
						<br />
						Hash da transação:{" "}
						<a
							href={`https://bscscan.com/tx/${details?.transactionHash}`}
							target="_blank"
						>
							{details?.transactionHash}
						</a>
					</>
				)}
			</div>
			{["whatsapp"].includes(type) && (
				<div className={style["payment-whatsapp-info"]}>
					Este meio de pagamento foi solicitado através do WhatsApp para a equipe financeira da iVipCoin. Enviamos as instruções para o seu
					e-mail para realizar o pagamento. Por favor, verifique a sua caixa de entrada. Se ainda não tiver recebido o e-mail, por favor
					tente solicitar uma nova transação e essa transação em questão será cancelada automaticamente após seu vencimento.
				</div>
			)}

			<ViewTariffs
				currency_id={currency_id}
				original_amount={original_amount}
				total_amount={total_amount}
				details={details}
			/>
		</div>
	);
};
