import React from "react";

import style from "./style.module.scss";
import { isDate } from "Utils";
import { Chip, CircularProgress, Pagination } from "@mui/material";
import { SvgIcon } from "Components";
import { mdiPlus } from "@mdi/js";

interface Columns {
	title: string;
	dataIndex: string;
	type: "text" | "number" | "date" | "datetime" | "time" | "currency" | "percent" | "action" | "profile" | "custom";
	stickyCol?: boolean;
	align?: "left" | "center" | "right";
	render?: (value: any, record: any, index: number) => React.ReactNode;
	lineBreak?: boolean;
}

interface ColumnPercent extends Columns {
	type: "percent";
	max?: number;
}

interface ColumnCurrency extends Columns {
	type: "currency";
	currency: string;
	locales?: string | string[];
}

interface CellValueProfile {
	name: string;
	email: string;
	photo: string;
}

interface TableCustomProps {
	columns: Array<Columns | ColumnPercent | ColumnCurrency>;
	data: Array<{
		[key: string]: number | string | Date | React.ReactNode | JSX.Element | CellValueProfile;
	}>;
	loading?: boolean;
	pagination?: {
		total: number;
		current: number;
	};
	onPaginationChange?: (page: number) => void;
	onDoubleClick?: (item: any, index: number) => void;
	className?: string;
	style?: React.CSSProperties;
	filters?: Array<{
		title: string;
		dataIndex: string;
		options: Array<{
			label: string;
			value: string;
		}>;
	}>;
	onFilterChange?: (
		filters: Array<{
			dataIndex: string;
			options: string[];
		}>,
	) => void;
}

const TableCustom: React.FC<TableCustomProps> = ({
	columns,
	data,
	className,
	style: component_style,
	loading,
	pagination,
	onPaginationChange,
	onDoubleClick,
	filters,
	onFilterChange,
}) => {
	return (
		<div className={style["main"]}>
			{Array.isArray(filters) && filters.length && (
				<div className={style["filters"]}>
					{filters.map((item, index) => {
						return (
							<div key={index}>
								<div className={style["title"]}>{item.title}:</div>
								<div className={style["options"]}>
									<Chip
										color="info"
										size="small"
										label="Todos"
									/>
									<Chip
										color="info"
										size="small"
										label="Adicionar"
										icon={<SvgIcon path={mdiPlus} />}
										disabled
									/>
								</div>
							</div>
						);
					})}
				</div>
			)}
			<div
				className={[style["wrap-table"], className ?? ""].join(" ")}
				style={component_style}
			>
				<table>
					<thead>
						<tr>
							{columns.map((item, index) => {
								return (
									<th
										key={index}
										className={item.stickyCol ? style["sticky-col"] : undefined}
										style={{
											textAlign: item.align ?? "left",
											whiteSpace: item.lineBreak === false ? "nowrap" : "pre-wrap",
										}}
									>
										{item.title}
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody>
						{data.map((item, index) => {
							return (
								<tr
									key={index}
									onDoubleClick={() => {
										if (typeof onDoubleClick === "function") {
											onDoubleClick(item, index);
										}
									}}
								>
									{columns.map((column, index) => {
										const val =
											typeof column.render === "function"
												? column.render(item[column.dataIndex], item, index)
												: item[column.dataIndex];
										let cellValue = val;
										if (val instanceof Date || (["date", "datetime", "time"].includes(column.type) && isDate(val))) {
											cellValue = new Date(val).toLocaleString();
										} else if (column.type === "percent" && typeof val === "number") {
											cellValue = `${val}%`;
										} else if (column.type === "currency" && typeof val === "number") {
											cellValue = val.toLocaleString((column as ColumnCurrency).locales, {
												style: "currency",
												currency: (column as ColumnCurrency).currency,
											});
										} else if (column.type === "profile") {
											cellValue = (
												<div className={style["user__info"]}>
													<img
														className={style["user__photo"]}
														src={(val as CellValueProfile).photo}
														alt=""
													/>
													<div>
														<div className={style["user__name"]}>{(val as CellValueProfile).name}</div>
														<div className={style["user__email"]}>{(val as CellValueProfile).email}</div>
													</div>
												</div>
											);
										} else if (column.type === "action" && React.isValidElement(val)) {
											cellValue = val;
										}

										return (
											<td
												key={index}
												className={column.stickyCol ? style["sticky-col"] : undefined}
												data-label={column.title}
												style={{
													textAlign: column.align ?? "left",
													whiteSpace: column.lineBreak === false ? "nowrap" : "pre-wrap",
												}}
											>
												{React.isValidElement(cellValue) || ["number", "string", "boolean"].includes(typeof cellValue) ? (
													(cellValue as any)
												) : (
													<i>undefined</i>
												)}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
				{loading && (
					<div className={style["loading"]}>
						<CircularProgress
							color="inherit"
							size={"30px"}
						/>
					</div>
				)}
				{!loading && data.length <= 0 && <div className={style["empty"]}>Não há dados</div>}
			</div>
			{pagination && pagination.total > 0 && (
				<Pagination
					count={pagination.total}
					page={pagination.current + 1}
					onChange={(e, page) => {
						if (typeof onPaginationChange === "function") {
							onPaginationChange(page - 1);
						}
					}}
					color="primary"
					disabled={loading}
					siblingCount={0}
					boundaryCount={1}
				/>
			)}
		</div>
	);
};

export default TableCustom;
