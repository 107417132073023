import React, { useCallback, useEffect, useRef, useState } from "react";
import { CircularProgress, Grid, LinearProgress, Typography } from "@mui/material";
import style from "./style.module.scss";
import { currencyFormat } from "Utils";
import { WalletPerformanceResumo } from "Types/Wallet";
import { Wallet } from "Models";
import { APIHelper } from "Helper";

const numberWithCommas = (value: number) => {
	const { thousand, decimal } = currencyFormat.getSeparators();
	value = typeof value === "number" ? value : 0;
	return value
		.toString()
		.split(".")
		.map((p, i) => (i === 0 ? p.replace(/\B(?=(\d{3})+(?!\d))/g, thousand) : p))
		.join(decimal);
};

const ViewPerformanceResumo: React.FC<{
	wallet?: Wallet | null;
	secret?: boolean;
}> = ({ wallet, secret } = { secret: true }) => {
	const [resumo, setResumo] = useState<WalletPerformanceResumo>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isError, setIsError] = useState<boolean>(false);
	const [timer, setTimer] = useState<number>(0);
	const timeFetch = useRef<NodeJS.Timeout | undefined>();

	const timeToRefresh = 60000 * 2;

	const updateData = useCallback(() => {
		clearTimeout(timeFetch.current);

		if (!wallet) {
			return;
		}

		setIsLoading(true);
		setIsError(false);
		setTimer(0);

		timeFetch.current = setTimeout(() => {
			APIHelper.fetch(`wallet/performance/resumo?id_wallet=${wallet.getWalletId()}&idWallet=${wallet.getWalletId()}`, {}, 60 * 2)
				.then((info) => {
					setResumo(() => info as any);
				})
				.catch(() => {
					setResumo(undefined);
					setIsError(true);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}, 2000);
	}, []);

	useEffect(() => {
		if (isLoading) {
			return;
		}

		const MIN = Date.now();
		const MAX = MIN + timeToRefresh;

		const time = setInterval(() => {
			const percentage = ((Date.now() - MIN) * 100) / (MAX - MIN);
			setTimer(Math.max(0, 100 - percentage));
			if (percentage >= 100) {
				clearInterval(time);
				updateData();
			}
		}, 1000);

		return () => {
			clearInterval(time);
		};
	}, [isLoading]);

	useEffect(() => {
		updateData();
	}, [wallet]);

	secret = !wallet || secret;

	return (
		<>
			{isLoading ? (
				<LinearProgress color="inherit" />
			) : (
				<LinearProgress
					color="inherit"
					variant="determinate"
					value={timer}
				/>
			)}
			<Grid
				container
				spacing={3}
				rowSpacing={1}
				columns={12}
				className={style["info-performance"]}
				sx={{ flex: "1" }}
			>
				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Saldo Líquido Total
					</Typography>
					<Typography
						style={{ display: secret ? "" : "none" }}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.secretCurrencyValue("USD")}
					</Typography>
					<Typography
						style={{ display: secret ? "none" : "" }}
						className={
							(resumo?.total_net_balance ?? 0) < 0 ? style["red"] : (resumo?.total_net_balance ?? 0) > 0 ? style["green"] : undefined
						}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.convert(resumo?.total_net_balance ?? 0, "USD")}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Saldo Total
					</Typography>
					<Typography
						style={{ display: secret ? "" : "none" }}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.secretCurrencyValue("USD")}
					</Typography>
					<Typography
						style={{ display: secret ? "none" : "" }}
						className={(resumo?.total_balance ?? 0) < 0 ? style["red"] : (resumo?.total_balance ?? 0) > 0 ? style["green"] : undefined}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.convert(resumo?.total_balance ?? 0, "USD")}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Lucro Bruto
					</Typography>
					<Typography
						style={{ display: secret ? "" : "none" }}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.secretCurrencyValue("USD")}
					</Typography>
					<Typography
						style={{ display: secret ? "none" : "" }}
						className={(resumo?.gross_profit ?? 0) < 0 ? style["red"] : (resumo?.gross_profit ?? 0) > 0 ? style["green"] : undefined}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.convert(resumo?.gross_profit ?? 0, "USD")}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Prejuízo Bruto
					</Typography>
					<Typography
						style={{ display: secret ? "" : "none" }}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.secretCurrencyValue("USD")}
					</Typography>
					<Typography
						style={{ display: secret ? "none" : "" }}
						className={(resumo?.gross_loss ?? 0) !== 0 ? style["red"] : undefined}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.convert(resumo?.gross_loss ?? 0, "USD")}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Fator de Lucro
					</Typography>
					<Typography
						variant="body2"
						gutterBottom
					>
						{numberWithCommas(resumo?.profit_factor ?? 0)}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Custos
					</Typography>
					<Typography
						style={{ display: secret ? "" : "none" }}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.secretCurrencyValue("USD")}
					</Typography>
					<Typography
						style={{ display: secret ? "none" : "" }}
						className={(resumo?.costs ?? 0) < 0 ? style["red"] : (resumo?.costs ?? 0) > 0 ? style["green"] : undefined}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.convert(resumo?.costs ?? 0, "USD")}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					md={12}
					className="info-performance-item"
				></Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Número Total de Operações
					</Typography>
					<Typography gutterBottom>{resumo?.total_number_of_trades ?? 0}</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Percentual de Operações Vencedoras
					</Typography>
					<Typography
						variant="body2"
						gutterBottom
					>
						{numberWithCommas(resumo?.winning_trades_percentage ?? 0)}%
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Operações Vencedoras
					</Typography>
					<Typography
						variant="body2"
						gutterBottom
					>
						{resumo?.winning_trades ?? 0}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Operações Perdedoras
					</Typography>
					<Typography
						variant="body2"
						gutterBottom
					>
						{resumo?.losing_trades ?? 0}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Operações Zeradas
					</Typography>
					<Typography
						variant="body2"
						gutterBottom
					>
						{resumo?.break_even_trades ?? 0}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					md={12}
					className="info-performance-item"
				></Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Média de Lucro/Prejuízo
					</Typography>
					<Typography
						style={{ display: secret ? "" : "none" }}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.secretCurrencyValue("USD")}
					</Typography>
					<Typography
						style={{ display: secret ? "none" : "" }}
						className={
							(resumo?.average_profit_loss ?? 0) < 0
								? style["red"]
								: (resumo?.average_profit_loss ?? 0) > 0
								? style["green"]
								: undefined
						}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.convert(resumo?.average_profit_loss ?? 0, "USD")}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Razão Média de Lucro:Média Prejuízo
					</Typography>
					<Typography
						variant="body2"
						gutterBottom
					>
						{numberWithCommas(resumo?.average_profit_loss_ratio ?? 0)}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Média de Operações Vencedoras
					</Typography>
					<Typography
						style={{ display: secret ? "" : "none" }}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.secretCurrencyValue("USD")}
					</Typography>
					<Typography
						style={{ display: secret ? "none" : "" }}
						className={
							(resumo?.average_winning_trades ?? 0) < 0
								? style["red"]
								: (resumo?.average_winning_trades ?? 0) > 0
								? style["green"]
								: undefined
						}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.convert(resumo?.average_winning_trades ?? 0, "USD")}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Média de Operações Perdedoras
					</Typography>
					<Typography
						style={{ display: secret ? "" : "none" }}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.secretCurrencyValue("USD")}
					</Typography>
					<Typography
						style={{ display: secret ? "none" : "" }}
						className={(resumo?.average_losing_trades ?? 0) !== 0 ? style["red"] : undefined}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.convert(resumo?.average_losing_trades ?? 0, "USD")}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Maior Operações Vencedoras
					</Typography>
					<Typography
						style={{ display: secret ? "" : "none" }}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.secretCurrencyValue("USD")}
					</Typography>
					<Typography
						style={{ display: secret ? "none" : "" }}
						className={
							(resumo?.largest_winning_trade ?? 0) < 0
								? style["red"]
								: (resumo?.largest_winning_trade ?? 0) > 0
								? style["green"]
								: undefined
						}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.convert(resumo?.largest_winning_trade ?? 0, "USD")}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Maior Operações Perdedoras
					</Typography>
					<Typography
						style={{ display: secret ? "" : "none" }}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.secretCurrencyValue("USD")}
					</Typography>
					<Typography
						style={{ display: secret ? "none" : "" }}
						className={(resumo?.largest_losing_trade ?? 0) !== 0 ? style["red"] : undefined}
						variant="body2"
						gutterBottom
					>
						{currencyFormat.convert(resumo?.largest_losing_trade ?? 0, "USD")}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Maior Sequência Vencedoras
					</Typography>
					<Typography
						variant="body2"
						gutterBottom
					>
						{resumo?.largest_winning_streak ?? 0}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					className="info-performance-item"
				>
					<Typography
						variant="subtitle2"
						gutterBottom
					>
						Maior Sequência Perdedoras
					</Typography>
					<Typography
						variant="body2"
						gutterBottom
					>
						{resumo?.largest_losing_streak ?? 0}
					</Typography>
				</Grid>
			</Grid>
			<Typography
				className={style.info}
				variant="caption"
				component="div"
				style={{
					color: isError ? "var(--warning-main)" : undefined,
				}}
			>
				{isError
					? "Ocorreu um erro inesperado ao buscar os dados, tente novamente mais tarde!"
					: "As informações apresentadas neste resumo de relatório de desempenho consideram as transações realizadas nos últimos 30 dias."}
			</Typography>
		</>
	);
};

export default ViewPerformanceResumo;
