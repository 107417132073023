import React, { useEffect, useLayoutEffect, useRef } from "react";
import style from "./style.module.scss";
import { Icons, SvgIcon } from "Components";
import { mdiBackspace } from "@mdi/js";
import { maskAmount } from "Utils";

const inverseMaskAmount = (value: string, separator: string = "."): number => {
	const [integer = "0", decimal = "0"] = value.split(separator);
	return parseFloat(`${integer.replace(/\D/gi, "")}.${decimal}`) ?? 0;
};

const InputAmount: React.FC<
	Partial<{
		value: number;
		onChange: (value: number) => void;
		currency: string;
		max: number;
		min: number;
		disabled: boolean;
		warn: string;
	}>
> = ({ value, onChange, currency = "USD", max, min, disabled = false, warn = "" }) => {
	const [currentValue, setCurrentValue] = React.useState("0");
	const [onFocus, setOnFocus] = React.useState(false);
	const difRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!difRef.current) {
			return;
		}

		const handleFocus = (e: MouseEvent) => {
			if (difRef.current && !difRef.current.contains(e.target as Node)) {
				setOnFocus(false);
			} else {
				const activeElement = document.activeElement;
				if (activeElement && activeElement instanceof HTMLElement) {
					(activeElement as HTMLElement).blur();
				}
				setOnFocus(true);
			}
		};

		document.addEventListener("click", handleFocus);

		return () => {
			document.removeEventListener("click", handleFocus);
		};
	}, [difRef.current]);

	const decimalSeparator = ((1.1).toLocaleString().match(/[^0-9]/) as any)[0] ?? ".";

	useEffect(() => {
		if (inverseMaskAmount(currentValue, decimalSeparator) === value) {
			return;
		}

		setValue(maskAmount(value ?? 0, 8).replace(new RegExp(`${decimalSeparator}?0+$`, "g"), ""));
	}, [value]);

	const setValue = (value: string) => {
		let [integer = "", decimal = ""] = value.split(decimalSeparator);
		integer = integer.replace(/\D/gi, "").replace(/^0+/, "0");
		integer = integer.length > 1 ? integer.replace(/^0+/, "") : integer;
		let newInteger = "";
		for (let i = integer.length - 1; i >= 0; i--) {
			const j = integer.length - 1 - i;
			if (j % 3 === 0 && j !== 0) {
				newInteger = (decimalSeparator === "." ? "," : ".") + newInteger;
			}
			newInteger = integer[i] + newInteger;
		}
		const newValue = value.search(decimalSeparator) > 0 ? `${newInteger}${decimalSeparator}${decimal}` : newInteger;
		setCurrentValue(newValue);
		onChange?.(inverseMaskAmount(newValue, decimalSeparator));
	};

	const backspace = () => {
		setValue(currentValue.slice(0, -1));
	};

	const addDigit = (digit: string) => {
		if (digit === decimalSeparator && currentValue.includes(decimalSeparator)) {
			return;
		}
		setValue(`${currentValue}${digit}`);
	};

	useEffect(() => {
		if (!onFocus) {
			return;
		}

		const keydown = (e: KeyboardEvent) => {
			if (e.key === "Backspace") {
				backspace();
			} else if (e.key === decimalSeparator) {
				addDigit(decimalSeparator);
			} else if (!isNaN(parseInt(e.key))) {
				addDigit(e.key);
			}
		};

		document.addEventListener("keydown", keydown);

		return () => {
			document.removeEventListener("keydown", keydown);
		};
	}, [onFocus, addDigit, backspace, decimalSeparator]);

	const innerWarn =
		typeof min === "number" && inverseMaskAmount(currentValue, decimalSeparator) < min
			? `Valor mínimo: ${maskAmount(min, 8, currency, "RTL")}`
			: typeof max === "number" && inverseMaskAmount(currentValue, decimalSeparator) > max
			? `Valor máximo: ${maskAmount(max, 8, currency, "RTL")}`
			: warn;

	return (
		<div className={style.input_amount}>
			<div
				className={[style.content, disabled ? style.disabled : "", (innerWarn?.trim() ?? "") !== "" ? style.warn : ""].join(" ")}
				onMouseDown={(e) => e.preventDefault()}
				ref={difRef}
			>
				<div className={style.display}>
					<div className={style.currency}>
						<Icons
							name={currency}
							width={25}
							height={25}
						/>
						{currency}
					</div>
					<div className={style.input}>
						<div className={style.value}>{currentValue}</div>
						{!disabled && onFocus && <div className={style.cursor}></div>}
					</div>
				</div>
				<div className={style.digits}>
					<div onClick={disabled ? undefined : () => addDigit("7")}>7</div>
					<div onClick={disabled ? undefined : () => addDigit("8")}>8</div>
					<div onClick={disabled ? undefined : () => addDigit("9")}>9</div>
					<div onClick={disabled ? undefined : () => addDigit("4")}>4</div>
					<div onClick={disabled ? undefined : () => addDigit("5")}>5</div>
					<div onClick={disabled ? undefined : () => addDigit("6")}>6</div>
					<div onClick={disabled ? undefined : () => addDigit("1")}>1</div>
					<div onClick={disabled ? undefined : () => addDigit("2")}>2</div>
					<div onClick={disabled ? undefined : () => addDigit("3")}>3</div>
					<div onClick={disabled ? undefined : backspace}>
						<SvgIcon path={mdiBackspace} />
					</div>
					<div onClick={disabled ? undefined : () => addDigit("0")}>0</div>
					<div onClick={disabled ? undefined : () => addDigit(decimalSeparator)}>{decimalSeparator}</div>
				</div>
				{(innerWarn?.trim() ?? "") !== "" && (
					<div className={style.warning}>
						<span>{innerWarn}</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default InputAmount;
