import React from "react";

import { Resultado, Wallet } from "Models";

import WalletHelper from "Helper/WalletHelper";

import { InteractionHelper } from "Helper";
import { Typography } from "@mui/material";

const ProcessRemoveWallet = (wallet: Wallet, props: any = {}) => {
	return new Promise<void>(async (resolve, reject) => {
		if (!(wallet instanceof Wallet)) {
			return reject(new Resultado(-1, "Erro ao tentar iniciar o processo de remoção!"));
		}

		InteractionHelper.confirm(
			<React.Fragment>
				<Typography variant="body1">
					Você está prestes a excluir a carteira com as seguintes informações:
					<br />
					<br />- Nome da Carteira: {wallet.nome}
					<br />- ID: {wallet.getWalletCode()}
					<br />
					<br />
					Por favor, esteja ciente de que esta ação é irreversível. Uma vez que a carteira for excluída, todos os dados associados serão
					removidos permanentemente. Certifique-se de ter feito backup de quaisquer informações importantes. <br />
					<br />
					Você confirma que deseja prosseguir com a exclusão da carteira?
				</Typography>
			</React.Fragment>,
			"Atenção: Exclusão de Carteira",
		)
			.then(() => {
				InteractionHelper.loading();

				WalletHelper.deleteWallet(wallet)
					.then(() => {
						InteractionHelper.close();
						InteractionHelper.toast("Carteira removida com sucesso!", null, "success");
						resolve();
					})
					.catch((error: Resultado) => {
						InteractionHelper.close();
						reject(error.message);
					});
			})
			.catch(() => reject("Não foi possível excluir a carteira!"));
	});
};

export default ProcessRemoveWallet;
