const Base64 = function (e) {
  let _Base64 = {
    _keyStr:
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
  };

  _Base64.encode = (e) => {
    let t = "";
    let n, r, i, s, o, u, a;
    let f = 0;

    e = _Base64._utf8_encode(e);

    while (f < e.length) {
      n = e.charCodeAt(f++);
      r = e.charCodeAt(f++);
      i = e.charCodeAt(f++);
      s = n >> 2;
      o = ((n & 3) << 4) | (r >> 4);
      u = ((r & 15) << 2) | (i >> 6);
      a = i & 63;
      if (isNaN(r)) {
        u = a = 64;
      } else if (isNaN(i)) {
        a = 64;
      }
      t =
        t +
        _Base64._keyStr.charAt(s) +
        _Base64._keyStr.charAt(o) +
        _Base64._keyStr.charAt(u) +
        _Base64._keyStr.charAt(a);
    }

    return t;
  };

  _Base64.decode = (e) => {
    let t = "";
    let n, r, i;
    let s, o, u, a;
    let f = 0;
    e = e.replace(/[^A-Za-z0-9+/=]/g, "");

    while (f < e.length) {
      s = _Base64._keyStr.indexOf(e.charAt(f++));
      o = _Base64._keyStr.indexOf(e.charAt(f++));
      u = _Base64._keyStr.indexOf(e.charAt(f++));
      a = _Base64._keyStr.indexOf(e.charAt(f++));
      n = (s << 2) | (o >> 4);
      r = ((o & 15) << 4) | (u >> 2);
      i = ((u & 3) << 6) | a;
      t = t + String.fromCharCode(n);
      if (u != 64) {
        t = t + String.fromCharCode(r);
      }
      if (a != 64) {
        t = t + String.fromCharCode(i);
      }
    }

    t = _Base64._utf8_decode(t);
    return t;
  };

  _Base64._utf8_encode = (e) => {
    e = e.replace(/rn/g, "n");
    let t = "";

    for (let n = 0; n < e.length; n++) {
      let r = e.charCodeAt(n);
      if (r < 128) {
        t += String.fromCharCode(r);
      } else if (r > 127 && r < 2048) {
        t += String.fromCharCode((r >> 6) | 192);
        t += String.fromCharCode((r & 63) | 128);
      } else {
        t += String.fromCharCode((r >> 12) | 224);
        t += String.fromCharCode(((r >> 6) & 63) | 128);
        t += String.fromCharCode((r & 63) | 128);
      }
    }

    return t;
  };

  _Base64._utf8_decode = (e) => {
    let t = "";
    let n = 0;
    let r = 0,
      c1 = 0,
      c2 = 0,
      c3 = 0;

    while (n < e.length) {
      r = e.charCodeAt(n);
      if (r < 128) {
        t += String.fromCharCode(r);
        n++;
      } else if (r > 191 && r < 224) {
        c2 = e.charCodeAt(n + 1);
        t += String.fromCharCode(((r & 31) << 6) | (c2 & 63));
        n += 2;
      } else {
        c2 = e.charCodeAt(n + 1);
        c3 = e.charCodeAt(n + 2);
        t += String.fromCharCode(
          ((r & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
        );
        n += 3;
      }
    }

    return t;
  };

  return {
    encode: _Base64.encode(e),
    decode: _Base64.decode(e),
  };
};

export default Base64;
