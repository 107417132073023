import { Grid, Typography } from "@mui/material";
import React from "react";
import styles from "./style.module.scss";
import { shippingTracking } from "./Resources";
import { SvgIcon } from "Components";
import * as mdi from "@mdi/js";
import { mdiCircle, mdiDotsHorizontal } from "@mdi/js";

const tracking_info: Array<{
	index: number;
	icon: string;
	status: "inProgress" | "concluded" | "pending" | "canceled";
	title?: string;
	description?: string;
	forecast_time: string;
	applied_time?: string;
}> = [
	{
		index: 0,
		status: "concluded",
		icon: "mdiPin",
		forecast_time: "2024-03-13T03:00:00.000Z",
		applied_time: "2024-03-14T23:33:00.000Z",
	},
	{
		index: 1,
		status: "inProgress",
		icon: "mdiAccountEdit",
		title: "Etapa para atualização de endereço",
		description:
			"Nesta fase do processo, é fundamental que os usuários forneçam o endereço de entrega de forma precisa e completa, garantindo que a encomenda seja recebida sem problemas.",
		forecast_time: "2024-03-14T23:33:00.000Z",
		applied_time: "2024-03-15T14:27:00.000Z",
	},
	{
		index: 2,
		status: "pending",
		icon: "mdiAccountFilter",
		title: "Preparação e produção de itens",
		description:
			"Durante esta fase, todos os pedidos serão preparados de acordo com as informações previamente coletadas para a primeira remessa. É importante ressaltar que, nesta etapa, não será possível efetuar qualquer modificação nas informações fornecidas, a fim de garantir a continuidade sem interrupções no processo de fabricação dos itens personalizados.",
		forecast_time: "2024-03-22T03:00:00.000Z",
	},
	{
		index: 3,
		status: "pending",
		icon: "mdiAccountSearch",
		title: "Confirmação dos itens",
		description:
			"Durante este processo, todos os itens serão minuciosamente verificados antes do envio, a fim de garantir que os produtos personalizados sejam enviados de forma correta e sem erros.",
		forecast_time: "2024-04-05T03:00:00.000Z",
	},
	{
		index: 4,
		status: "pending",
		icon: "mdiAirplaneClock",
		title: "Entregue a transportadora",
		description:
			"Nesta fase, a encomenda será entregue à transportadora. A partir desse ponto, tanto o usuário quanto a transportadora assumirão a responsabilidade pelo transporte e pela entrega da encomenda.",
		forecast_time: "2024-04-12T03:00:00.000Z",
	},
	{
		index: 5,
		status: "pending",
		icon: "mdiAirplaneSearch",
		title: "Código de rastreio e monitoramento online",
		description:
			"Nesta fase, o usuário aguardará o código de rastreamento da transportadora, que permitirá acompanhar o progresso da entrega diretamente pelo sistema da transportadora. Além disso, se desejar, o usuário também poderá monitorar o processo de entrega pela plataforma IVIPCOIN.",
		forecast_time: "2024-04-16T03:00:00.000Z",
	},
	{
		index: 10,
		status: "pending",
		icon: "mdiMapMarker",
		title: "Entregue ao destinatário",
		forecast_time: "2024-04-30T03:00:00.000Z",
	},
];

const ShippingTracking: React.FC = () => {
	return (
		<Grid
			className={[styles["container-box"]].join(" ")}
			container
			rowSpacing={4}
			columnSpacing={0}
		>
			<Grid
				item
				xs={12}
				md={6}
			>
				<div
					className={styles["image"]}
					style={{ backgroundImage: `url(${shippingTracking})` }}
				></div>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
			>
				<Typography
					variant="h5"
					style={{ marginBottom: "15px", fontWeight: "bold" }}
				>
					Rastreio de entrega do brinde
				</Typography>
				<div className={styles["tracking_content"]}>
					{tracking_info.map(({ title, description, status, icon, index, forecast_time, applied_time }, i, self) => {
						const isInternal = i < self.length - 1 && self[i + 1].index !== index + 1;

						return (
							<React.Fragment key={i}>
								<div className={styles[status]}>
									<div className={styles["tracking_indicator"]}>
										<SvgIcon path={icon in (mdi as any) ? (mdi as any)[icon] : mdiCircle} />
									</div>
									{title && (
										<Typography
											variant="h6"
											component="div"
										>
											{title}
										</Typography>
									)}
									<div className={styles["tracking_time"]}>
										{applied_time
											? new Date(applied_time).toLocaleString()
											: `Previsão: ${new Date(forecast_time).toLocaleDateString()}`}
									</div>
									{description && (
										<Typography
											variant="caption"
											component="div"
										>
											{description}
										</Typography>
									)}
								</div>
								{isInternal && (
									<div className={styles["internal"]}>
										<div className={styles["tracking_indicator"]}>
											<SvgIcon path={mdiDotsHorizontal} />
										</div>
									</div>
								)}
							</React.Fragment>
						);
					})}
				</div>
			</Grid>
		</Grid>
	);
};

export default ShippingTracking;
