import React, { Component, useState } from "react";
import "./index.css";

import {Global} from "Utils";

import Icon from "@mdi/react";
import { mdiBrightness7, mdiBrightness2 } from "@mdi/js";

import { useTheme } from "@mui/material/styles";

import { ColorModeContext } from "Crucial/Theme";

export default function ModeThemeSwitch({ size }) {
  const [id, setId] = useState("switch-button-" + Global.uniqueid(8));
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  size = typeof size === "string" && ["small", "medium", "large"].includes(size.toLocaleLowerCase()) ? size.toLocaleLowerCase() : "medium";

  return (<div className={`ModeThemeSwitch-root ModeThemeSwitch-size-${size}`}>
        <input
            type="checkbox"
            className="switch-button"
            checked={theme.palette.mode === "dark"}
            id={id}
            onChange={colorMode.toggleColorMode}
        />
        <label htmlFor={id}>
            <div className="fa-sun">
                <Icon path={mdiBrightness7} />
            </div>
            <div className="fa-moon">
                <Icon path={mdiBrightness2} />
            </div>
        </label>
    </div>);
}
