const maskTheValue = function (value, mask) {
  let convert = (value, mask) => {
    if (Array.isArray(mask) !== true) {
      return value;
    }
    let temp = String(value),
      result = "";

    mask.forEach((m, i) => {
      if (typeof m === "string" && temp.length >= 1) {
        result += m;
      } else if (m instanceof RegExp) {
        let str = temp.match(m);
        if (!str || str.length < 1) {
          return;
        }
        str = str[0];
        if (temp.search(str) === 0) {
          temp = temp.substring(str.length, temp.length);
          result += str;
        }
      }
    });

    return result;
  };

  let invert = (value, mask) => {
    if (Array.isArray(mask) !== true) {
      return value;
    }
    let temp = String(value),
      result = "";

    mask.forEach((m) => {
      if (m instanceof RegExp) {
        let str = temp.match(m);
        if (!str || str.length < 1) {
          return;
        }
        str = str[0];
        if (temp.search(str) >= 0) {
          temp = temp.substring(temp.search(str) + str.length, temp.length);
          result += str;
        }
      }
    });

    return result;
  };

  return {
    invert: invert(value, mask),
    convert: convert(value, mask),
  };
};

export default maskTheValue;
