import { Color } from "Utils";

const wings = [];
wings.push([
    "M218,144.5c-6.5-41.5-23.5-84.5-102-84-76.51.49-104-18-104-18S26.5,80,81.5,80c0,0,48-1,75,7h-71a127.41,127.41,0,0,1-33-3s10,26,50,26c0,0,65-1,74,5h-92s16,20,42,21h53S199.5,135,218,144.5Z", 
    "M282,144.5c6.5-41.5,23.5-84.5,102-84,76.51.49,104-18,104-18S473.5,80,418.5,80c0,0-48-1-75,7h71a127.41,127.41,0,0,0,33-3s-10,26-50,26c0,0-65-1-74,5h92s-16,20-42,21h-53S300.5,135,282,144.5Z"
]);

wings.push([
    "M286.5,143.6V119.34s22.34-9.93,28.54-32c0,0,6.83-28.13-5.58-43.58,0,0-6.21-11,7.45-18.75s45.91-7.72,45.91-7.72H446S468.9,17.3,488.74,4c2.43-1.63,5.72.65,4.49,3.14C485,23.73,467.16,53.7,444.73,53.7c-31,0-34.75-8.83-69.5-9.93,0,0-28.77-2.32-46.12-.48-4.31.46-6.8,4.58-4.86,8l.07.12a6.59,6.59,0,0,0,7.21,2.89c7-1.58,23-2.92,54.87,3.78,20.62,4.82,41.24,9.65,55.22,10,2.65.07,4.27,2.6,3,4.66-5.07,8.16-17.84,23.9-40.82,23.9-31-2.2-19.86-15.44-55.85-17.65H334.42c-3.93,0-6.87,3.19-6.1,6.61h0a6,6,0,0,0,5.77,4.43c9.68.41,33,3.24,59.61,19.82a5.48,5.48,0,0,1,1.17,8.72c-4,4.07-11.56,9-24.6,9-22.34,0-16.13-12.13-40.95-13.24h-3a11.56,11.56,0,0,0-9.86,5.21C311.4,127.65,300.41,142.41,286.5,143.6Z", 
    "M213.5,143.6V119.34s-22.34-9.93-28.54-32c0,0-6.83-28.13,5.58-43.58,0,0,6.21-11-7.45-18.75s-45.91-7.72-45.91-7.72H54S31.1,17.3,11.26,4c-2.43-1.63-5.72.65-4.49,3.14C15,23.73,32.84,53.7,55.27,53.7c31,0,34.75-8.83,69.5-9.93,0,0,28.77-2.32,46.12-.48,4.31.46,6.8,4.58,4.86,8l-.07.12a6.59,6.59,0,0,1-7.21,2.89c-7-1.58-23-2.92-54.87,3.78-20.62,4.82-41.24,9.65-55.22,10-2.65.07-4.27,2.6-3,4.66,5.07,8.16,17.84,23.9,40.82,23.9,31-2.2,19.86-15.44,55.85-17.65h13.51c3.93,0,6.87,3.19,6.1,6.61h0a6,6,0,0,1-5.77,4.43c-9.68.41-33,3.24-59.61,19.82a5.48,5.48,0,0,0-1.17,8.72c4,4.07,11.56,9,24.6,9,22.34,0,16.13-12.13,41-13.24h3a11.56,11.56,0,0,1,9.86,5.21C188.6,127.65,199.59,142.41,213.5,143.6Z"
]);

wings.push([
    "M342.69,71.53h0a2.45,2.45,0,0,0,3.17,2.12C360.45,70,422.43,55.32,476.28,57c2.75.08,4.53,2.58,3.43,4.81-3.15,6.41-11.75,17.54-33.95,20.28a49,49,0,0,1-12.52-.1c-11.56-1.56-44.52-5-89.58-.54A2.14,2.14,0,0,0,341.73,83a2.08,2.08,0,0,0-.05.41,2.15,2.15,0,0,0,2.61,1.91c10.54-1.72,46.8-5.74,108.89,6.36,2.64.51,3.94,3.15,2.55,5.2-2.9,4.31-9.33,10.58-22.61,12.29a40.09,40.09,0,0,1-17.45-1.72c-10.52-3.45-35.65-10.51-75-14.55A2.43,2.43,0,0,0,338,94.59a2.1,2.1,0,0,0,0,.43,2.27,2.27,0,0,0,2.22,2.14c11.15.81,54.19,4.92,86.95,21.33a2,2,0,0,1,.24,3.57,37.12,37.12,0,0,1-13.48,5.34,29.38,29.38,0,0,1-18.07-2.59c-10.66-5.28-36.61-17.27-61.15-22a2.1,2.1,0,0,0-2.25,1,1.55,1.55,0,0,0-.2.77,1.84,1.84,0,0,0,1.54,1.73c8.31,1.8,36.36,8.76,59.59,24.89a2.06,2.06,0,0,1-.36,3.66c-2.11,1-5.89,1.85-12.55,1.22a23.67,23.67,0,0,1-9.85-3.17c-8.91-5.26-30.74-17.88-41.87-21.91a2.36,2.36,0,0,0-2.53.6,1.8,1.8,0,0,0-.46,1.18,1.9,1.9,0,0,0,1.3,1.76,173,173,0,0,1,42.86,24.79,1.83,1.83,0,0,1-.82,3.27,18.87,18.87,0,0,1-8.85-.22,30.83,30.83,0,0,1-12.55-6.09c-6-4.87-18.4-14.59-26.11-18.4a2.24,2.24,0,0,0-2.06.06,1.63,1.63,0,0,0,0,2.92c5.93,3.35,20.46,12,26.29,18.92a1.78,1.78,0,0,1-1.34,2.9c-3.1.39-8,.28-11.74-3.26l-17.91-17.33a1.32,1.32,0,0,0-1.07-.34,1.14,1.14,0,0,0-1.09,1.09,1,1,0,0,0,.35.75c3.43,3.13,10.85,10.24,12.93,14.84a1.23,1.23,0,0,1-1.35,1.64,15.53,15.53,0,0,1-8.41-2.85,4.85,4.85,0,0,1-1.45-2,35.76,35.76,0,0,0-7.28-10.6,1.66,1.66,0,0,0-1.74-.38,1.36,1.36,0,0,0-1,1.25,1.24,1.24,0,0,0,.48,1c2.09,1.73,5.33,5,6.27,9.13a1.64,1.64,0,0,1-1.93,1.84l-.36,0a8.14,8.14,0,0,1-6.5-4.78l-1.85-4.38a2.14,2.14,0,0,0-2.8-1,31.5,31.5,0,0,1-8,2.25c-3,.42-5.43-2-4.65-4.61l.74-2.46s.83-8,22.62-14c16.74-4.59,22.82-28.12,24.74-38.8A11,11,0,0,1,340,61.76C361,52.23,434.61,19.85,483.3,13.1c4.22-.58,7.65,3,6.24,6.58-4.28,10.79-15.78,29.45-44.17,30.08,0,0-40.26.69-101,19.7A2.24,2.24,0,0,0,342.69,71.53Z", 
    "M157.31,71.53h0a2.45,2.45,0,0,1-3.17,2.12C139.55,70,77.57,55.32,23.72,57c-2.75.08-4.53,2.58-3.43,4.81,3.15,6.41,11.75,17.54,34,20.28A49,49,0,0,0,66.76,82c11.56-1.56,44.52-5,89.58-.54A2.14,2.14,0,0,1,158.27,83a2.08,2.08,0,0,1,0,.41,2.15,2.15,0,0,1-2.61,1.91c-10.54-1.72-46.8-5.74-108.89,6.36-2.64.51-3.94,3.15-2.55,5.2,2.9,4.31,9.33,10.58,22.61,12.29a40.09,40.09,0,0,0,17.45-1.72c10.52-3.45,35.65-10.51,75-14.55A2.43,2.43,0,0,1,162,94.59,2.1,2.1,0,0,1,162,95a2.27,2.27,0,0,1-2.22,2.14c-11.15.81-54.19,4.92-86.95,21.33a2,2,0,0,0-.24,3.57A37.12,37.12,0,0,0,86.1,127.4a29.38,29.38,0,0,0,18.07-2.59c10.66-5.28,36.61-17.27,61.15-22a2.1,2.1,0,0,1,2.25,1,1.55,1.55,0,0,1,.2.77,1.84,1.84,0,0,1-1.54,1.73c-8.31,1.8-36.36,8.76-59.59,24.89a2.06,2.06,0,0,0,.36,3.66c2.11,1,5.89,1.85,12.55,1.22a23.67,23.67,0,0,0,9.85-3.17c8.91-5.26,30.74-17.88,41.87-21.91a2.36,2.36,0,0,1,2.53.6,1.8,1.8,0,0,1,.46,1.18,1.9,1.9,0,0,1-1.3,1.76,173,173,0,0,0-42.86,24.79,1.83,1.83,0,0,0,.82,3.27,18.87,18.87,0,0,0,8.85-.22,30.83,30.83,0,0,0,12.55-6.09c6-4.87,18.4-14.59,26.11-18.4a2.24,2.24,0,0,1,2.06.06,1.63,1.63,0,0,1,0,2.92c-5.93,3.35-20.46,12-26.29,18.92a1.78,1.78,0,0,0,1.34,2.9c3.1.39,8,.28,11.74-3.26l17.91-17.33a1.32,1.32,0,0,1,1.07-.34,1.14,1.14,0,0,1,1.09,1.09,1,1,0,0,1-.35.75c-3.43,3.13-10.85,10.24-12.93,14.84a1.23,1.23,0,0,0,1.35,1.64,15.53,15.53,0,0,0,8.41-2.85,4.85,4.85,0,0,0,1.45-2,35.76,35.76,0,0,1,7.28-10.6,1.66,1.66,0,0,1,1.74-.38,1.36,1.36,0,0,1,.95,1.25,1.24,1.24,0,0,1-.48,1c-2.09,1.73-5.33,5-6.27,9.13a1.64,1.64,0,0,0,1.93,1.84l.36,0a8.14,8.14,0,0,0,6.5-4.78l1.85-4.38a2.14,2.14,0,0,1,2.8-1,31.5,31.5,0,0,0,8,2.25c3,.42,5.43-2,4.65-4.61l-.74-2.46s-.83-8-22.62-14c-16.74-4.59-22.82-28.12-24.74-38.8A11,11,0,0,0,160,61.76C139.05,52.23,65.39,19.85,16.7,13.1c-4.22-.58-7.65,3-6.24,6.58,4.28,10.79,15.78,29.45,44.17,30.08,0,0,40.26.69,101,19.7A2.24,2.24,0,0,1,157.31,71.53Z"
]);

wings.push([
    "M296.14,102.63c8.26-9.35,24.68-32.7,12.69-60.28a5.78,5.78,0,0,1,1.7-6.78c3.52-3,11.49-6.21,29.5-5.07,0,0,118.12,7,153.65-24.09C495,5.3,497,6.57,496.3,8c-7.24,14.72-27.54,43.05-75.78,39.67-1.51-.11-3-.3-4.48-.52C404.13,45.34,345.49,36.79,320.2,41a1.67,1.67,0,0,0-1.27,2.62h0a2,2,0,0,0,1.91.73c5.29-.78,27.39-3.23,54.74,5.25a68.82,68.82,0,0,0,8.22,2c16.06,2.88,65,9.67,92.81-8.32,1.43-.93,3.33.43,2.58,1.85-4.71,8.86-17.3,25.28-46.27,26.4-.76,0-1.53,0-2.29,0-39.14-1.33-73.23-20-103.39-20.1-2,0-3.45,1.79-2.69,3.46h0a2.87,2.87,0,0,0,2.67,1.59c5.46,0,23.2,1.19,61.51,14.08,0,0,32.89,11.8,62.45,6,1.49-.29,2.55,1.24,1.58,2.29-5.15,5.54-16.92,15.93-31.18,13.71,0,0-18.17-2-49.81-17.33-1.12-.55-3-1.37-4.18-1.83-14-5.46-22.76-9.91-35.63-11.17-2.52-.25-4.28,2.16-3,4.08l0,.06a3.15,3.15,0,0,0,2.28,1.32c5.33.56,24.61,3.54,49.75,18.3.66.38,1.3.8,2,1.16,5,2.66,25.81,13.43,39.54,14.49a1.47,1.47,0,0,1,.82,2.74,31.9,31.9,0,0,1-23.19,3.76,19.86,19.86,0,0,1-4.29-1.44A386.56,386.56,0,0,1,357,85.5s-13.58-8-24.66-11.58c-1.66-.54-3.31.8-2.89,2.32l.07.24a2.18,2.18,0,0,0,1.36,1.42c5.58,2.14,32.44,13,65.65,35.95a1.74,1.74,0,0,1-.42,3.12c-3.42,1.38-9.87,2.79-18.73-.47,0,0-10.19-3-27.18-18,0,0-9-7.94-16.84-11.56-2-.9-4.22.61-3.78,2.51h0A2.3,2.3,0,0,0,330.85,91c4.08,2.08,19.88,10.65,37.63,27.46a1.79,1.79,0,0,1-1.15,3.07c-3.78.62-10.9.29-21.64-6-5.73-3-13.79-10.18-17.94-14.64a2.21,2.21,0,0,0-3.37.06h0a1.75,1.75,0,0,0,.42,2.66c2.77,1.86,8.43,6.92,17.23,16a3,3,0,0,1-1.53,4.9c-3.38,1-8.45,1.26-13.58-3.51a12.26,12.26,0,0,1-.93-1l-4.16-4.71a3.17,3.17,0,0,0-5.12.61c-3.66,7.93-13.26,24.54-32.1,32.3-2.76,1.14-5.75-1.16-4.91-3.74,2.62-8.06,7.25-22.26,10-30.67A31.64,31.64,0,0,1,296.14,102.63Z", 
    "M203.86,102.63c-8.26-9.35-24.68-32.7-12.69-60.28a5.78,5.78,0,0,0-1.7-6.78C186,32.58,178,29.36,160,30.5c0,0-118.12,7-153.65-24.09C5.05,5.3,3,6.57,3.7,8c7.24,14.72,27.54,43.05,75.78,39.67,1.51-.11,3-.3,4.48-.52C95.87,45.34,154.51,36.79,179.8,41a1.67,1.67,0,0,1,1.27,2.62h0a2,2,0,0,1-1.91.73c-5.29-.78-27.39-3.23-54.74,5.25a68.82,68.82,0,0,1-8.22,2c-16.06,2.88-65,9.67-92.81-8.32-1.43-.93-3.33.43-2.58,1.85,4.71,8.86,17.3,25.28,46.27,26.4.76,0,1.53,0,2.29,0,39.14-1.33,73.23-20,103.39-20.1,2,0,3.45,1.79,2.69,3.46h0a2.87,2.87,0,0,1-2.67,1.59c-5.46,0-23.2,1.19-61.51,14.08,0,0-32.89,11.8-62.45,6-1.49-.29-2.55,1.24-1.58,2.29,5.15,5.54,16.92,15.93,31.18,13.71,0,0,18.17-2,49.81-17.33,1.12-.55,3-1.37,4.18-1.83,14-5.46,22.76-9.91,35.63-11.17,2.52-.25,4.28,2.16,3,4.08l0,.06a3.15,3.15,0,0,1-2.28,1.32c-5.33.56-24.61,3.54-49.75,18.3-.66.38-1.3.8-2,1.16-5,2.66-25.81,13.43-39.54,14.49a1.47,1.47,0,0,0-.82,2.74,31.9,31.9,0,0,0,23.19,3.76,19.86,19.86,0,0,0,4.29-1.44A386.56,386.56,0,0,0,143,85.5s13.58-8,24.66-11.58c1.66-.54,3.31.8,2.89,2.32l-.07.24a2.18,2.18,0,0,1-1.36,1.42c-5.58,2.14-32.44,13-65.65,35.95a1.74,1.74,0,0,0,.42,3.12c3.42,1.38,9.87,2.79,18.73-.47,0,0,10.19-3,27.18-18,0,0,9-7.94,16.84-11.56,2-.9,4.22.61,3.78,2.51h0A2.3,2.3,0,0,1,169.15,91c-4.08,2.08-19.88,10.65-37.63,27.46a1.79,1.79,0,0,0,1.15,3.07c3.78.62,10.9.29,21.64-6,5.73-3,13.79-10.18,17.94-14.64a2.21,2.21,0,0,1,3.37.06h0a1.75,1.75,0,0,1-.42,2.66c-2.77,1.86-8.43,6.92-17.23,16a3,3,0,0,0,1.53,4.9c3.38,1,8.45,1.26,13.58-3.51a12.26,12.26,0,0,0,.93-1l4.16-4.71a3.17,3.17,0,0,1,5.12.61c3.66,7.93,13.26,24.54,32.1,32.3,2.76,1.14,5.75-1.16,4.91-3.74-2.62-8.06-7.25-22.26-10-30.67A31.64,31.64,0,0,0,203.86,102.63Z"
]);

wings.push([
    "M178.92,104.72l-1.89-.21a1.38,1.38,0,0,0-1.51,1.1,135.08,135.08,0,0,1-7.72,24.84,1.15,1.15,0,0,0,1.37,1.56c5.73-1.54,7.23-9.94,7.23-9.94l3.71-15.66A1.38,1.38,0,0,0,178.92,104.72Z", 
    "M68.78,93.73a22.41,22.41,0,0,0,2.22-.9l55-26.07a2.46,2.46,0,0,0,1.09-3.45l-3.24-5.73a2.48,2.48,0,0,0-2.95-1.13A640.77,640.77,0,0,1,23.8,81.74a1.89,1.89,0,0,0-1.21,3C35.94,103.56,62.55,95.86,68.78,93.73Z", 
    "M144.61,86.91l-3.3-2.75a2.48,2.48,0,0,0-3.17,0C131,90,100.82,114.18,76,125.94a1.45,1.45,0,0,0-.09,2.57,33.16,33.16,0,0,0,3.83,1.9,25.45,25.45,0,0,0,26.77-5l38.18-34.78A2.5,2.5,0,0,0,144.61,86.91Z", 
    "M185.07,104.94l-1.1.1a1.35,1.35,0,0,0-1.22,1.24c-.24,3.31-1,13.07-2.52,19.13a1,1,0,0,0,1.52,1.14,9.59,9.59,0,0,0,3.4-3.28,9.09,9.09,0,0,0,1.37-4.9l0-12.09A1.35,1.35,0,0,0,185.07,104.94Z", 
    "M163.12,99.67l-1.3-.63a2.07,2.07,0,0,0-2.57.63c-4.15,5.69-20.12,27.29-30.12,37.27a1.15,1.15,0,0,0,.7,1.95c13.17,1.14,16.64-6.67,16.64-6.67L164,102.58A2.07,2.07,0,0,0,163.12,99.67Z", 
    "M154.71,94.73l-3.15-2.18a1.75,1.75,0,0,0-2.27.21c-5.29,5.41-29.31,29.8-41.2,38.11a1.69,1.69,0,0,0-.07,2.73c9.73,7.43,19.58-3.44,19.58-3.44l27.46-32.84A1.76,1.76,0,0,0,154.71,94.73Z", 
    "M87,111.88l48.25-32a2.28,2.28,0,0,0,.41-3.46l-4.14-4.45a2.29,2.29,0,0,0-2.83-.42c-8.77,5.14-52.32,30.16-80.48,36.92a1.61,1.61,0,0,0-.84,2.6c10.24,12.47,29,5.9,35.65,3A26,26,0,0,0,87,111.88Z", 
    "M171.15,103.17l-1.62-.49a1.7,1.7,0,0,0-2,.93c-2.25,5-10.36,22.64-17.31,32.21a1.08,1.08,0,0,0,1.15,1.68c10.36-2.74,12.51-9.64,12.51-9.64l8.41-22.46A1.7,1.7,0,0,0,171.15,103.17Z", 
    "M190.35,90c-3.66.32-42.2-1.71-54.1-44a12.27,12.27,0,0,0-2.59-4.87,13.07,13.07,0,0,0-4.95-3.43,10.39,10.39,0,0,0-4.72-.61L3.36,47.82a1.53,1.53,0,0,0-1.08,2.45C7,56.36,21.47,72.83,39.64,71.21,61.89,69.22,120.06,51,120.06,51s8.18-2.6,9.68,3.8,18.2,40.42,49.5,44.84a10.91,10.91,0,0,0,2.49,0l4.31-.38s3.71-.33,4,2.45c.23,2.57,3,15.31,9.24,20.75a.86.86,0,0,0,1.42-.73l-2.31-25.91C198.1,93,195,89.57,190.35,90Z", 
    "M317.25,106.28A1.35,1.35,0,0,0,316,105l-1.1-.1a1.35,1.35,0,0,0-1.47,1.34l0,12.09a9.09,9.09,0,0,0,1.37,4.9,9.59,9.59,0,0,0,3.4,3.28,1,1,0,0,0,1.52-1.14C318.28,119.35,317.49,109.59,317.25,106.28Z", 
    "M332.51,103.61a1.7,1.7,0,0,0-2-.93l-1.62.49a1.7,1.7,0,0,0-1.1,2.23l8.41,22.46s2.15,6.9,12.51,9.64a1.08,1.08,0,0,0,1.15-1.68C342.87,126.25,334.76,108.62,332.51,103.61Z", 
    "M340.75,99.67a2.07,2.07,0,0,0-2.57-.63l-1.3.63a2.07,2.07,0,0,0-.88,2.91l17.53,29.64s3.47,7.81,16.64,6.67a1.15,1.15,0,0,0,.7-1.95C360.87,127,344.9,105.36,340.75,99.67Z", 
    "M424,125.94c-24.82-11.76-55-35.93-62.14-41.8a2.48,2.48,0,0,0-3.17,0l-3.3,2.75a2.5,2.5,0,0,0-.08,3.75l38.18,34.78a25.45,25.45,0,0,0,26.77,5,33.16,33.16,0,0,0,3.83-1.9A1.45,1.45,0,0,0,424,125.94Z", 
    "M476.2,81.74a640.77,640.77,0,0,1-97.07-25.29,2.48,2.48,0,0,0-2.95,1.13l-3.24,5.73A2.46,2.46,0,0,0,374,66.76l55,26.07a22.41,22.41,0,0,0,2.22.9c6.23,2.13,32.84,9.83,46.19-9A1.89,1.89,0,0,0,476.2,81.74Z", 
    "M496.64,47.82,376,37.05a10.39,10.39,0,0,0-4.72.61,13.07,13.07,0,0,0-5,3.43A12.27,12.27,0,0,0,363.75,46c-11.9,42.32-50.44,44.35-54.1,44-4.64-.42-7.75,3-8,5.82l-2.31,25.91a.86.86,0,0,0,1.42.73c6.28-5.44,9-18.18,9.24-20.75.25-2.78,4-2.45,4-2.45l4.31.38a10.91,10.91,0,0,0,2.49,0c31.3-4.42,48-38.51,49.5-44.84s9.68-3.8,9.68-3.8,58.17,18.27,80.42,20.26c18.17,1.62,32.66-14.85,37.36-20.94A1.53,1.53,0,0,0,496.64,47.82Z", 
    "M391.91,130.87C380,122.56,356,98.17,350.71,92.76a1.75,1.75,0,0,0-2.27-.21l-3.15,2.18a1.76,1.76,0,0,0-.35,2.59l27.46,32.84S382.25,141,392,133.6A1.69,1.69,0,0,0,391.91,130.87Z", 
    "M451.83,108.44c-28.16-6.76-71.71-31.78-80.48-36.92a2.29,2.29,0,0,0-2.83.42l-4.14,4.45a2.28,2.28,0,0,0,.41,3.46l48.25,32a26,26,0,0,0,4,2.17c6.66,2.89,25.41,9.46,35.65-3A1.61,1.61,0,0,0,451.83,108.44Z", 
    "M324.48,105.61a1.38,1.38,0,0,0-1.51-1.1l-1.89.21a1.38,1.38,0,0,0-1.19,1.69l3.71,15.66s1.5,8.4,7.23,9.94a1.15,1.15,0,0,0,1.37-1.56A135.08,135.08,0,0,1,324.48,105.61Z"
]);

wings.push([
    "M484,24.59l-11.48,9.79a14.08,14.08,0,0,1-9.14,3.28H359.33a4.4,4.4,0,0,0-4.39,3.17,3.94,3.94,0,0,0-.11.89,4.29,4.29,0,0,0,4.5,4h97.09A1.89,1.89,0,0,1,457.88,49l-10.32,8.8a17.61,17.61,0,0,1-11.51,4.07L351,61.1a4.45,4.45,0,0,0-4.3,2.76,3.66,3.66,0,0,0-.23,1.29,4.27,4.27,0,0,0,4.48,4h78.31c2,0,3,2.2,1.6,3.49l-10.24,9.22a14.07,14.07,0,0,1-9.38,3.5H335.58a4.73,4.73,0,0,0-3.18,1.19,3.76,3.76,0,0,0-1.33,2.83,4.28,4.28,0,0,0,4.51,4.08h66.5c2.39,0,3.55,2.63,1.82,4.12L394.06,106a15.92,15.92,0,0,1-10.32,3.7H287a4.16,4.16,0,0,1-4.35-3.82l-.29-9.53a4,4,0,0,1,3.34-3.92c12.82-2.68,57.08-15.59,57.66-66.11A5.64,5.64,0,0,1,349.24,21l133.41.41A1.81,1.81,0,0,1,484,24.59Z",
    "M377.36,121.72l-8.19,7.37A19.66,19.66,0,0,1,356,134H285.86a3,3,0,0,1-3.14-2.83V119.72a3,3,0,0,1,3.14-2.83h89.29C377.94,116.89,379.34,119.94,377.36,121.72Z",
    "M16,24.59l11.48,9.79a14.08,14.08,0,0,0,9.14,3.28H140.67a4.4,4.4,0,0,1,4.39,3.17,3.94,3.94,0,0,1,.11.89,4.29,4.29,0,0,1-4.5,4H43.58A1.89,1.89,0,0,0,42.12,49l10.32,8.8A17.61,17.61,0,0,0,64,61.91L149,61.1a4.45,4.45,0,0,1,4.3,2.76,3.66,3.66,0,0,1,.23,1.29,4.27,4.27,0,0,1-4.48,4H70.75c-2,0-3,2.2-1.6,3.49l10.24,9.22a14.07,14.07,0,0,0,9.38,3.5h75.65a4.73,4.73,0,0,1,3.18,1.19,3.76,3.76,0,0,1,1.33,2.83,4.28,4.28,0,0,1-4.51,4.08H97.92c-2.39,0-3.55,2.63-1.82,4.12l9.84,8.39a15.92,15.92,0,0,0,10.32,3.7H213a4.16,4.16,0,0,0,4.35-3.82l.29-9.53a4,4,0,0,0-3.34-3.92c-12.82-2.68-57.08-15.59-57.66-66.11A5.64,5.64,0,0,0,150.76,21l-133.41.41A1.81,1.81,0,0,0,16,24.59Z",
    "M122.64,121.72l8.19,7.37A19.66,19.66,0,0,0,144,134h70.15a3,3,0,0,0,3.14-2.83V119.72a3,3,0,0,0-3.14-2.83H124.85C122.06,116.89,120.66,119.94,122.64,121.72Z"
]);

wings.push([
    "M494.7,7.93,355.5,42a19.54,19.54,0,0,0-11.2,7.63L293.09,121.1a3.65,3.65,0,0,0,1.43,5.44l3.66,1.62a3.41,3.41,0,0,0,4.11-1.08L350.48,63.7a13.63,13.63,0,0,1,8.82-5.27l73.94-11.18c40.5-3.88,58-26.07,63.89-35.86A2.3,2.3,0,0,0,494.7,7.93Z",
    "M364.24,129.61l-45.63-9.13a7.35,7.35,0,0,0-7.3,2.82l-4.1,5.39a2.07,2.07,0,0,0,1,3.22l27.62,8.63a33.16,33.16,0,0,0,16.51,1c7.77-1.58,11.62-5.21,13.48-7.93A2.58,2.58,0,0,0,364.24,129.61Z",
    "M385.71,108.92,330,104.32a7.37,7.37,0,0,0-6.47,2.92l-5.18,6.81a2,2,0,0,0,1.16,3.21l35.33,7c20.9,2.78,29.59-5.59,32.94-10.93A2.85,2.85,0,0,0,385.71,108.92Z",
    "M411.54,84.37,344,85.94a9,9,0,0,0-7,3.6l-6.91,9.09a2,2,0,0,0,1.4,3.14l54.33,2.48c14.57,0,23.7-9.36,28-15.28A2.87,2.87,0,0,0,411.54,84.37Z",
    "M446.72,52.06,361,63.92a12.46,12.46,0,0,0-8.18,4.83l-6.3,8.3A1.81,1.81,0,0,0,347.91,80L411,78.25c20.4-2.11,32.68-14.59,38.18-21.82A2.72,2.72,0,0,0,446.72,52.06Z",
    "M5.3,7.93,144.5,42a19.54,19.54,0,0,1,11.2,7.63l51.21,71.43a3.65,3.65,0,0,1-1.43,5.44l-3.66,1.62a3.41,3.41,0,0,1-4.11-1.08L149.52,63.7a13.63,13.63,0,0,0-8.82-5.27L66.76,47.25c-40.5-3.88-58-26.07-63.89-35.86A2.3,2.3,0,0,1,5.3,7.93Z",
    "M135.76,129.61l45.63-9.13a7.35,7.35,0,0,1,7.3,2.82l4.1,5.39a2.07,2.07,0,0,1-1,3.22l-27.62,8.63a33.16,33.16,0,0,1-16.51,1c-7.77-1.58-11.62-5.21-13.48-7.93A2.58,2.58,0,0,1,135.76,129.61Z",
    "M114.29,108.92l55.71-4.6a7.37,7.37,0,0,1,6.47,2.92l5.18,6.81a2,2,0,0,1-1.16,3.21l-35.33,7c-20.9,2.78-29.59-5.59-32.94-10.93A2.85,2.85,0,0,1,114.29,108.92Z",
    "M88.46,84.37,156,85.94a9,9,0,0,1,7,3.6l6.91,9.09a2,2,0,0,1-1.4,3.14l-54.33,2.48c-14.57,0-23.7-9.36-28-15.28A2.87,2.87,0,0,1,88.46,84.37Z",
    "M53.28,52.06,139,63.92a12.46,12.46,0,0,1,8.18,4.83l6.3,8.3A1.81,1.81,0,0,1,152.09,80L89,78.25c-20.4-2.11-32.68-14.59-38.18-21.82A2.72,2.72,0,0,1,53.28,52.06Z"
]);

wings.push([
    "M495.84,13.38c-2.95,8.75-11.44,23.92-35.23,27.26-32.36,4.53-88.8,4.34-116.4,6.27a3.9,3.9,0,0,1-4.14-3.38c-.09-.58-.17-1.17-.27-1.75a3.93,3.93,0,0,1,3.12-4.48l148.45-29A3.93,3.93,0,0,1,495.84,13.38Z",
    "M488.08,52.05c-4.09,7.72-14.23,20.38-36.66,20-29.94-.55-80.44-8.62-107-11.14a3.75,3.75,0,0,1-3.4-3.75c0-.58,0-1.17,0-1.76a3.78,3.78,0,0,1,3.63-3.86l140-5A3.78,3.78,0,0,1,488.08,52.05Z",
    "M321.3,138.22a.9.9,0,0,1-.88,1.33,6.62,6.62,0,0,1-5.45-4.12c-2-4.18-4.32-11.36-6-15.94a.92.92,0,0,1,.49-1.14h0a.91.91,0,0,1,1.2.38Z",
    "M378,134.64c-3.63,1.59-9.73,2.73-16.87-2.19-9.49-6.52-23.4-19-32.77-26.91a2.35,2.35,0,0,1-.4-3.16.08.08,0,0,0,0,0,2.37,2.37,0,0,1,3.16-.65l47.12,28.78A2.36,2.36,0,0,1,378,134.64Z",
    "M436,109.73c-4.83,4.29-14.07,9.44-28.09,4.85-19.35-6.34-50-21.25-68.09-29a3.53,3.53,0,0,1-2-4.24s0,0,0-.06a3.53,3.53,0,0,1,4.31-2.42l92.38,24.83A3.53,3.53,0,0,1,436,109.73Z",
    "M402,123.15c-4,2.68-11.26,5.45-21,.77-13.31-6.37-33.76-19.79-46.47-27.36a2.7,2.7,0,0,1-1.05-3.49l0,0a2.7,2.7,0,0,1,3.47-1.34l64.58,26.72A2.7,2.7,0,0,1,402,123.15Z",
    "M465.05,85.47c-4.78,6.09-15,14.76-33.46,11.66C406.62,92.91,365.82,80,343,74.2A3.81,3.81,0,0,1,340.16,70c0-.24.07-.48.1-.71a3.82,3.82,0,0,1,4.2-3.32l118,13.44A3.79,3.79,0,0,1,465.05,85.47Z",
    "M355.46,136.39a1.77,1.77,0,0,1-.74,3.06c-2.87.72-7.48.79-12.12-3.67-6.19-6-14.89-16.83-20.84-23.78a1.76,1.76,0,0,1,.11-2.4l0,0a1.77,1.77,0,0,1,2.4-.08Z",
    "M336.07,136.39a1.24,1.24,0,0,1-.8,2.06c-2,.22-5.22-.17-8-3.66-3.69-4.65-8.64-12.88-12.09-18.23a1.23,1.23,0,0,1,.32-1.68h0a1.26,1.26,0,0,1,1.65.18Z",
    "M4.16,13.38c3,8.75,11.44,23.92,35.23,27.26,32.36,4.53,88.8,4.34,116.4,6.27a3.9,3.9,0,0,0,4.14-3.38c.09-.58.17-1.17.27-1.75a3.93,3.93,0,0,0-3.12-4.48L8.63,8.26A3.93,3.93,0,0,0,4.16,13.38Z",
    "M11.92,52.05C16,59.77,26.15,72.43,48.58,72c29.94-.55,80.44-8.62,107-11.14a3.75,3.75,0,0,0,3.4-3.75c0-.58,0-1.17,0-1.76a3.78,3.78,0,0,0-3.63-3.86l-140-5A3.78,3.78,0,0,0,11.92,52.05Z",
    "M178.7,138.22a.9.9,0,0,0,.88,1.33,6.62,6.62,0,0,0,5.45-4.12c2-4.18,4.32-11.36,6-15.94a.92.92,0,0,0-.49-1.14h0a.91.91,0,0,0-1.2.38Z",
    "M122,134.64c3.63,1.59,9.73,2.73,16.87-2.19,9.49-6.52,23.4-19,32.77-26.91a2.35,2.35,0,0,0,.4-3.16l0,0a2.37,2.37,0,0,0-3.16-.65l-47.12,28.78A2.36,2.36,0,0,0,122,134.64Z",
    "M64,109.73c4.83,4.29,14.07,9.44,28.09,4.85,19.35-6.34,50-21.25,68.09-29a3.53,3.53,0,0,0,2-4.24s0,0,0-.06a3.53,3.53,0,0,0-4.31-2.42L65.51,103.71A3.53,3.53,0,0,0,64,109.73Z",
    "M98,123.15c4,2.68,11.26,5.45,21,.77,13.31-6.37,33.76-19.79,46.47-27.36a2.7,2.7,0,0,0,1-3.49l0,0a2.7,2.7,0,0,0-3.47-1.34L98.53,118.41A2.7,2.7,0,0,0,98,123.15Z",
    "M35,85.47c4.78,6.09,15,14.76,33.46,11.66C93.38,92.91,134.18,80,157,74.2A3.81,3.81,0,0,0,159.84,70c0-.24-.07-.48-.1-.71a3.82,3.82,0,0,0-4.2-3.32l-118,13.44A3.79,3.79,0,0,0,35,85.47Z",
    "M144.54,136.39a1.77,1.77,0,0,0,.74,3.06c2.87.72,7.48.79,12.12-3.67,6.19-6,14.89-16.83,20.84-23.78a1.76,1.76,0,0,0-.11-2.4l0,0a1.77,1.77,0,0,0-2.4-.08Z",
    "M163.93,136.39a1.24,1.24,0,0,0,.8,2.06c2.05.22,5.22-.17,8-3.66,3.69-4.65,8.64-12.88,12.09-18.23a1.23,1.23,0,0,0-.32-1.68h0a1.26,1.26,0,0,0-1.65.18Z"
]);

wings.push([
    "M286.62,121.09l-3,6.18a2.05,2.05,0,0,0,1.85,2.93h27.33a7.62,7.62,0,0,0,6.91-4.42l42.84-92.6A8.19,8.19,0,0,1,370,28.44H484.73a8.7,8.7,0,0,0,6.13-2.54l4.42-4.42a2.45,2.45,0,0,0-1.73-4.18H361.16a8.54,8.54,0,0,0-7.77,5l-42.31,92a7.41,7.41,0,0,1-6.73,4.31h-13.7A4.47,4.47,0,0,0,286.62,121.09Z",
    "M479.27,37.81l-6.16,5.89a7,7,0,0,1-4.85,1.95H364.51A1.86,1.86,0,0,1,362.82,43l1.81-3.93a7.88,7.88,0,0,1,7.14-4.56H478A1.91,1.91,0,0,1,479.27,37.81Z",
    "M461.61,54.71l-6.16,5.9a7.43,7.43,0,0,1-5.11,2H356.76A1.94,1.94,0,0,1,355,59.9l2.07-4.47a6.74,6.74,0,0,1,6.11-3.91h97.16A1.85,1.85,0,0,1,461.61,54.71Z",
    "M443.84,71.72l-6.17,5.9a7.39,7.39,0,0,1-5.11,2H348.89a2,2,0,0,1-1.77-2.76l2.07-4.47a6.74,6.74,0,0,1,6.11-3.91h87.26A1.85,1.85,0,0,1,443.84,71.72Z",
    "M426.06,88.73l-6.16,5.9a7.39,7.39,0,0,1-5.11,2.05H341a1.94,1.94,0,0,1-1.76-2.76l2.06-4.47a6.74,6.74,0,0,1,6.12-3.91h77.34A1.85,1.85,0,0,1,426.06,88.73Z",
    "M408.29,105.74l-6.16,5.9a7.39,7.39,0,0,1-5.11,2H333.16a1.94,1.94,0,0,1-1.77-2.76l2.06-4.47a6.76,6.76,0,0,1,6.12-3.91H407A1.85,1.85,0,0,1,408.29,105.74Z",
    "M390.51,122.75l-6.16,5.9a7.39,7.39,0,0,1-5.11,2h-54a1.94,1.94,0,0,1-1.77-2.76l2.07-4.47a6.74,6.74,0,0,1,6.11-3.91h57.54A1.85,1.85,0,0,1,390.51,122.75Z",
    "M213.38,121.09l3,6.18a2.05,2.05,0,0,1-1.85,2.93H187.16a7.62,7.62,0,0,1-6.91-4.42l-42.84-92.6A8.19,8.19,0,0,0,130,28.44H15.27A8.7,8.7,0,0,1,9.14,25.9L4.72,21.48A2.45,2.45,0,0,1,6.45,17.3H138.84a8.54,8.54,0,0,1,7.77,5l42.31,92a7.41,7.41,0,0,0,6.73,4.31h13.7A4.47,4.47,0,0,1,213.38,121.09Z",
    "M20.73,37.81l6.16,5.89a7,7,0,0,0,4.85,1.95H135.49A1.86,1.86,0,0,0,137.18,43l-1.81-3.93a7.88,7.88,0,0,0-7.14-4.56H22.05A1.91,1.91,0,0,0,20.73,37.81Z",
    "M38.39,54.71l6.16,5.9a7.43,7.43,0,0,0,5.11,2h93.58A1.94,1.94,0,0,0,145,59.9l-2.07-4.47a6.74,6.74,0,0,0-6.11-3.91H39.67A1.85,1.85,0,0,0,38.39,54.71Z",
    "M56.16,71.72l6.17,5.9a7.39,7.39,0,0,0,5.11,2h83.67a2,2,0,0,0,1.77-2.76l-2.07-4.47a6.74,6.74,0,0,0-6.11-3.91H57.44A1.85,1.85,0,0,0,56.16,71.72Z",
    "M73.94,88.73l6.16,5.9a7.39,7.39,0,0,0,5.11,2.05H159a1.94,1.94,0,0,0,1.76-2.76l-2.06-4.47a6.74,6.74,0,0,0-6.12-3.91H75.22A1.85,1.85,0,0,0,73.94,88.73Z",
    "M91.71,105.74l6.16,5.9a7.39,7.39,0,0,0,5.11,2h63.86a1.94,1.94,0,0,0,1.77-2.76l-2.06-4.47a6.76,6.76,0,0,0-6.12-3.91H93A1.85,1.85,0,0,0,91.71,105.74Z",
    "M109.49,122.75l6.16,5.9a7.39,7.39,0,0,0,5.11,2h54a1.94,1.94,0,0,0,1.77-2.76l-2.07-4.47a6.74,6.74,0,0,0-6.11-3.91H110.77A1.85,1.85,0,0,0,109.49,122.75Z"
]);

wings.push([
    "M496.65,10C487.87,24.7,470.11,49.35,449,51.2c-30.25,2.63-65.22-27-93.58-24.54C337,28.27,324.42,41.71,317.88,51c-.65.91.41,2.18,1.18,1.41,7.54-7.56,21-18.33,36.92-19,22.92-.95,65.35,33.36,94.81,28.06,2.1-.37,3.51,2.61,2.07,4.42-5.07,6.43-14.09,15.54-25,16.49-17,1.48-52.75-37.11-83-34.47-10.74.93-18.87,6.26-24.07,11-1,.87,0,2.66,1.1,2,5.76-3.41,14.17-7.18,23.46-7.38,16-.34,59,33.29,77.77,37.55a2.49,2.49,0,0,1,1,4c-2.68,2.66-7.71,5.83-16.51,6-15.22.2-41.61-40.33-70.91-37.78-7.4.65-11.34,3.46-13.37,5.81-.75.87,0,2.37,1,2A37.74,37.74,0,0,1,340,68.58c28.9,1.48,50.79,34.71,61.14,40a2.36,2.36,0,0,1,.2,3.89c-2.46,1.6-6.52,2.72-12.72.31-10.39-4-30.09-30.73-50-35.09a16.84,16.84,0,0,0-12.75,2.44,1.05,1.05,0,0,0-.45.89,1,1,0,0,0,1,1.11c1.22-.11,2.44-.16,3.67-.16a45,45,0,0,1,27.13,9.4c6.93,5.23,15.87,13.12,27,25a2.74,2.74,0,0,1-.42,4.12c-2.29,1.24-6,2.15-10.75-.75-7.52-4.61-23.49-30.08-45.33-29-2.58.13-4.72,2.26-5.32,5.21-1.32,6.52-5.81,18.08-21,21L300,101.37s28.8-18.51,5.59-33.41a7.41,7.41,0,0,1-2.4-9c6.41-14.41,25.42-48.63,62.1-50,43.85-1.7,81.66,34.19,128.6-2.23C495.7,5.26,497.9,7.91,496.65,10Z",
    "M3.35,10C12.13,24.7,29.89,49.35,51,51.2c30.25,2.63,65.22-27,93.58-24.54,18.41,1.61,31,15.05,37.52,24.29.65.91-.41,2.18-1.18,1.41-7.54-7.56-21-18.33-36.92-19-22.92-.95-65.35,33.36-94.81,28.06-2.1-.37-3.51,2.61-2.07,4.42,5.07,6.43,14.09,15.54,25,16.49,17,1.48,52.75-37.11,83-34.47,10.74.93,18.87,6.26,24.07,11,1,.87,0,2.66-1.1,2-5.76-3.41-14.17-7.18-23.46-7.38-16-.34-59,33.29-77.77,37.55a2.49,2.49,0,0,0-1,4c2.68,2.66,7.71,5.83,16.51,6,15.22.2,41.61-40.33,70.91-37.78,7.4.65,11.34,3.46,13.37,5.81.75.87,0,2.37-1,2A37.74,37.74,0,0,0,160,68.58c-28.9,1.48-50.79,34.71-61.14,40a2.36,2.36,0,0,0-.2,3.89c2.46,1.6,6.52,2.72,12.72.31,10.39-4,30.09-30.73,50-35.09a16.84,16.84,0,0,1,12.75,2.44,1.05,1.05,0,0,1,.45.89,1,1,0,0,1-1,1.11c-1.22-.11-2.44-.16-3.67-.16a45,45,0,0,0-27.13,9.4c-6.93,5.23-15.87,13.12-27,25a2.74,2.74,0,0,0,.42,4.12c2.29,1.24,6,2.15,10.75-.75,7.52-4.61,23.49-30.08,45.33-29,2.58.13,4.72,2.26,5.32,5.21,1.32,6.52,5.81,18.08,21,21L200,101.37S171.21,82.86,194.42,68a7.41,7.41,0,0,0,2.4-9c-6.41-14.41-25.42-48.63-62.1-50C90.87,7.2,53.06,43.09,6.12,6.67,4.3,5.26,2.1,7.91,3.35,10Z"
]);

wings.push([
    "M495,14.13a159.19,159.19,0,0,1-5.56,17.29A11.85,11.85,0,0,1,478.54,39L354.79,40.26a.41.41,0,0,0,0,.82l121.16,7.4a3.53,3.53,0,0,1,2.84,5.28q-3.82,6.59-8.22,12.75a11.87,11.87,0,0,1-11.73,4.83L353.33,52.58a.4.4,0,0,0-.17.79l99.91,26.22a3.53,3.53,0,0,1,1.64,5.86q-5,5.07-10.33,9.69a11.9,11.9,0,0,1-12.08,2.1L352.65,66.06a.41.41,0,0,0-.33.74l73.47,35.78a3.52,3.52,0,0,1,.38,6.12q-4.89,3.14-10,5.92a11.71,11.71,0,0,1-12-.52l-55.53-36a.41.41,0,0,0-.47.66L397,117.88a3.52,3.52,0,0,1-.93,6q-5.22,2-10.61,3.64a11.85,11.85,0,0,1-12-3.07L341.66,91.53a.41.41,0,0,0-.62.53l26.48,34.68a3.53,3.53,0,0,1-2.19,5.61q-4.17.74-8.4,1.23a11.87,11.87,0,0,1-11.6-5.58L330.8,104.1a.4.4,0,0,0-.72.36l10.5,25.42a3.53,3.53,0,0,1-3.25,4.88c-2.14,0-4.3,0-6.45-.13A11.8,11.8,0,0,1,319.77,125l-2-11.21a1.59,1.59,0,0,0-2.92-.52c-4,6.73-11,9.24-15.93,17l0,.07a3.64,3.64,0,0,1-6.55-.89l-.3-1-7.7-25.06a9.58,9.58,0,0,1,4.35-11.1c13-7.49,40.17-26.46,39.21-53.22a7,7,0,0,1,6-7.27L491.13,9.75A3.52,3.52,0,0,1,495,14.13Z",
    "M5,14.13a159.19,159.19,0,0,0,5.56,17.29A11.85,11.85,0,0,0,21.46,39l123.75,1.26a.41.41,0,0,1,0,.82L24.07,48.48a3.53,3.53,0,0,0-2.84,5.28q3.82,6.59,8.22,12.75a11.87,11.87,0,0,0,11.73,4.83L146.67,52.58a.4.4,0,0,1,.17.79L46.93,79.59a3.53,3.53,0,0,0-1.64,5.86q5,5.07,10.33,9.69a11.9,11.9,0,0,0,12.08,2.1l79.65-31.18a.41.41,0,0,1,.33.74L74.21,102.58a3.52,3.52,0,0,0-.38,6.12q4.89,3.14,10,5.92a11.71,11.71,0,0,0,12-.52l55.53-36a.41.41,0,0,1,.47.66L103,117.88a3.52,3.52,0,0,0,.93,6q5.22,2,10.61,3.64a11.85,11.85,0,0,0,12-3.07l31.75-32.95a.41.41,0,0,1,.62.53l-26.48,34.68a3.53,3.53,0,0,0,2.19,5.61q4.17.74,8.4,1.23a11.87,11.87,0,0,0,11.6-5.58l14.53-23.9a.4.4,0,0,1,.72.36l-10.5,25.42a3.53,3.53,0,0,0,3.25,4.88c2.14,0,4.3,0,6.45-.13A11.8,11.8,0,0,0,180.23,125l2.05-11.21a1.59,1.59,0,0,1,2.92-.52c4,6.73,11,9.24,15.93,17l0,.07a3.64,3.64,0,0,0,6.55-.89l.3-1,7.7-25.06a9.58,9.58,0,0,0-4.35-11.1c-13-7.49-40.17-26.46-39.21-53.22a7,7,0,0,0-6-7.27L8.87,9.75A3.52,3.52,0,0,0,5,14.13Z"
]);

const colors = [
    ["#37474f", "#eceff1"],
    ["#00695c", "#b2dfdb"],
    ["#1565c0", "#90caf9"],
    ["#6a1b9a", "#e1bee7"],
    ["#4e342e", "#d7ccc8"],
    ["#d84315", "#ffccbc"]
];

const getLevelColor = (type, level)=>{
    let lengthLevels = 0;
    switch(type){
        case "wings":
            lengthLevels = wings.length;
            break;
        default:
            lengthLevels = wings.length;
    }

    let interval = 1 / (colors.length-1);
    let percent = (level-1)/(lengthLevels-1);
    let index = Math.round((percent-interval < 0 ? 0 : percent-interval)/interval);

    return colors[index].map((c, i) => {
        return new Color(colors[index+1][i]).blend(c, (percent%interval)/interval).hex;
    });
}

export {
    wings,
    getLevelColor
};