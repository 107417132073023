import { useState, useLayoutEffect } from "react";
import { TraderChart, TradingView } from "Components";
import { APIHelper } from "Helper";
import { isDate } from "Utils";
import { Stack, Typography, Switch } from "@mui/material";

import { useTheme } from "@mui/material";

import style from "./style.module.scss";

const getTrade = () => {
	return new Promise((result, reject) => {
		APIHelper.fetch("/trade/candles/IVIP", {}, 60 * 30 * 1000)
			.then((list) => {
				list = list.map(({ open, high, low, close, volume, strTime, time }) => {
					return {
						date: isDate(strTime) ? new Date(strTime) : new Date(time),
						open: parseFloat(open) ?? 0,
						high: parseFloat(high) ?? 0,
						low: parseFloat(low) ?? 0,
						close: parseFloat(close) ?? 0,
						volume: parseFloat(volume) ?? 0,
					};
				});
				result(list);
			})
			.catch(reject);
	});
};

const MainTrader = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState();
	const {
		palette: { mode: theme },
	} = useTheme();

	useLayoutEffect(() => {
		const getData = () => {
			getTrade()
				.then((r) => {
					setData(() => r);
				})
				.catch(console.log)
				.finally(() => {
					setLoading(false);
				});
		};

		getData();

		const time = setInterval(getData, 60 * 30 * 1000);

		return () => {
			clearInterval(time);
		};
	}, []);

	if (loading) {
		return null;
	}

	return (
		<TraderChart
			data={data}
			theme={theme}
			width={"100%"}
			height={"100%"}
			numberOfPricePoints={24}
		/>
	);
};

const Contant = () => {
	const [defaultView, setDefaultView] = useState(true);

	const handleChange = ({ target: { checked } }) => {
		setDefaultView(!checked);
	};

	return (
		<div className={style["main-contant"]}>
			<div className={style["tools-bar"]}>
				<Stack
					direction="row"
					spacing={1}
					alignItems="center"
				>
					<Typography variant="caption">iVipTrading</Typography>
					<Switch
						checked={!defaultView}
						size="small"
						color="default"
						onChange={handleChange}
					/>
					<Typography variant="caption">TradingView</Typography>
				</Stack>
			</div>
			<div className={style["contant"]}>{defaultView ? <MainTrader /> : <TradingView className={style["tradingview-widget-container"]} />}</div>
		</div>
	);
};

export default Contant;
