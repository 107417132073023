import queryString from "query-string";
import { MultiStorager, matchPath, normalizePath } from "Utils";
import { Resultado } from "Models";
import { InteractionTypes, listInteractionTypes } from "Providers/InteractionProvider/Interactions";

import history from "../../routerHistory";
import { LocationDescriptor } from "history";

/**
 * Classe de roteamento
 * @class Route
 * @static push - Navega para uma nova rota.
 * @static replace - Substitui a rota atual.
 * @static pathname - Retorna o caminho da rota atual.
 * @static query - Retorna os parâmetros da rota atual.
 * @example
 * Route.push("/home");
 * Route.replace("/home");
 * console.log(Route.pathname);
 * console.log(Route.query("/home"));
 */
class Route {
	/**
	 * Navega para uma nova rota.
	 * @static
	 * @template T - O tipo do estado da rota.
	 * @param {LocationDescriptor<T>} location - O caminho da rota.
	 * @param {T} [state] - O estado da rota (opcional).
	 * @returns {void}
	 * @example
	 * Route.push("/home");
	 * Route.push("/home", { id: 1 });
	 * Route.push({ pathname: "/home", search: "?id=1" });
	 * Route.push({ pathname: "/home", search: "?id=1" }, { id: 1 });
	 * Route.push({ pathname: "/home", search: "?id=1", state: { id: 1 } });
	 * Route.push({ pathname: "/home", search: "?id=1", state: { id: 1 } }, { id: 1 });
	 */
	static push<T = unknown>(location: LocationDescriptor<T>, state?: T): void {
		location = typeof location === "string" ? normalizePath(location) : location;
		history.push(location as any, state as any);
	}

	static goBack() {
		history.goBack();
	}

	static goForward() {
		history.goForward();
	}

	/**
	 * Substitui a rota atual.
	 * @static
	 * @template T - O tipo do estado da rota.
	 * @param {LocationDescriptor<T>} location - O caminho da rota.
	 * @param {T} [state] - O estado da rota (opcional).
	 * @returns {void}
	 * @example
	 * Route.replace("/home");
	 * Route.replace("/home", { id: 1 });
	 * Route.replace({ pathname: "/home", search: "?id=1" });
	 * Route.replace({ pathname: "/home", search: "?id=1" }, { id: 1 });
	 * Route.replace({ pathname: "/home", search: "?id=1", state: { id: 1 } });
	 * Route.replace({ pathname: "/home", search: "?id=1", state: { id: 1 } }, { id: 1 });
	 */
	static replace<T = unknown>(location: LocationDescriptor<T>, state?: T): void {
		location = typeof location === "string" ? normalizePath(location) : location;
		history.replace(location as any, state as any);
	}

	/**
	 * Retorna o caminho da rota atual.
	 * @static
	 * @returns {string}
	 * @example
	 * console.log(Route.pathname);
	 */
	static get pathname(): string {
		return normalizePath(history.location.pathname);
	}

	/**
	 * Retorna os parâmetros da rota atual.
	 * @static
	 * @param {string | RegExp | (string | RegExp)[]} path - O caminho da rota.
	 * @returns {any}
	 * @example
	 * console.log(Route.query("/home"));
	 * console.log(Route.query(["/home", "/profile"]));
	 */
	static query<
		S = {
			[key: string]: string | string[] | number | boolean | null;
		},
	>(path: string): S & ReturnType<typeof matchPath> {
		let paths: (string | RegExp)[] = (Array.isArray(path) ? path : [path]).filter((s) => typeof s === "string" || s instanceof RegExp);
		if (paths.length <= 0) {
			return (history.location.state as any) ?? {};
		}
		const params = Object.assign.apply(
			null,
			(paths as any).map((p: string) => matchPath(p, history.location.pathname)["query"] || {}).concat(history.location.state || {}),
		);

		return {
			...queryString.parse(location.search), // Convert string to object
			...params,
		};
	}

	/**
	 * Verifica se a rota atual é exata.
	 * @static
	 * @param {string} path - O caminho da rota.
	 * @returns {boolean}
	 * @example
	 * console.log(Route.isExact("/home"));
	 * console.log(Route.isExact(["/home", "/profile"]));
	 * console.log(Route.isExact("/home/"));
	 */
	static isExact(path: string): boolean {
		path = normalizePath(path);
		const location = normalizePath(history.location.pathname);
		return matchPath(path, location).exact;
	}
}

export default class InteractionHelper {
	/**
	 * Exibe a tela de carregamento
	 * @static
	 * @returns {Promise<void>}
	 */
	static loading(): Promise<void> {
		return new Promise((resolve, reject) => {
			try {
				const show = MultiStorager.DataStorager.get("InteractionContent-show");
				show("loading", {});
				resolve();
			} catch (error) {
				const message = (error as { message: string }).message;
				reject(new Resultado(-1, message));
			}
		});
	}

	/**
	 * Fecha a tela de interação
	 * @static
	 * @returns {Promise<void>}
	 */
	static close(type: InteractionTypes = "loading", id?: number): Promise<void> {
		return new Promise((resolve, reject) => {
			try {
				type = listInteractionTypes.includes(type as any) ? type : "loading";
				const close = MultiStorager.DataStorager.get("InteractionContent-close");
				close(type, { id });
				resolve();
			} catch (error) {
				const message = (error as { message: string }).message;
				reject(new Resultado(-1, message));
			}
		});
	}

	/**
	 * Exibe a tela de alerta
	 * @static
	 * @template T - O tipo do corpo do alerta.
	 * @param {T} body - Componente React a ser exibido no modal.
	 * @param {string} [title] - Título do modal (opcional).
	 * @param {Function} [onClose] - Função de callback a ser chamada após o fechamento do modal (opcional).
	 * @returns {Promise<any>} Uma promessa vazia.
	 */
	static alert<T>(body: T, title?: string, onClose?: (...args: any[]) => void): Promise<any> {
		return new Promise((resolve, reject) => {
			try {
				const show = MultiStorager.DataStorager.get("InteractionContent-show");
				const _onClose = (...args: any[][]) => {
					if (typeof onClose === "function") {
						onClose.apply(null, args);
					}
					resolve(args[0]);
				};
				show("alert", { title: typeof title !== "string" ? "Alerta:" : title, body, onClose: _onClose });
			} catch (error) {
				const message = (error as { message: string }).message;
				reject(new Resultado(-1, message));
			}
		});
	}

	/**
	 * Exibe a tela de confirmação
	 * @static
	 * @template T - O tipo do corpo do alerta.
	 * @param {T} body - Componente React a ser exibido no modal.
	 * @param {string} [title] - Título do modal (opcional).
	 * @param {string} term - Termo do modal  (opcional).
	 * @param {string[]} btnNames - Nomes dos botões do modal (opcional). Ex: ["Sim", "Não"]
	 * @param {Function} [onClose] - Função de callback a ser chamada após o fechamento do modal (opcional).
	 * @returns {Promise<void>} Uma promessa vazia.
	 */
	static confirm<T>(
		body: T,
		title?: string,
		term?: string,
		btnNames?: [string] | [string, string],
		onClose?: (...args: any[]) => void,
	): Promise<void> {
		return new Promise((resolve, reject) => {
			try {
				const show = MultiStorager.DataStorager.get("InteractionContent-show");
				const _onClose = (...args: any[]) => {
					if (typeof onClose === "function") {
						onClose.apply(null, args);
					}
					if (args[0] === true) {
						resolve();
						return;
					}
					reject(new Resultado(-1, "Confirmação rejeitado"));
				};
				show("confirm", { title: typeof title !== "string" ? "Alerta:" : title, body, term, btnNames, onClose: _onClose });
			} catch (error) {
				const message = (error as { message: string }).message;
				reject(new Resultado(-1, message));
			}
		});
	}

	/**
	 * Exibe a tela de campo de texto
	 * @static
	 * @template T - O tipo do corpo do alerta.
	 * @param {T} body - Componente React a ser exibido no modal.
	 * @param {string} value - Valor padrão do campo de texto do modal.
	 * @param {string} title - Título do modal.
	 * @param {string} term - Termo do modal (opcional).
	 * @param {string[]} btnNames - Nomes dos botões do modal (opcional). Ex: ["Enviar", "Cancelar"]
	 * @param {Function} [onClose] - Função de callback a ser chamada após o fechamento do modal (opcional).
	 * @returns {Promise<any>} Uma promessa vazia.
	 */
	static prompt<T>(
		body: T,
		value: string,
		title: string,
		term?: string,
		btnNames?: [string] | [string, string],
		onClose?: (...args: any[]) => void,
	): Promise<any> {
		return new Promise((resolve, reject) => {
			try {
				const show = MultiStorager.DataStorager.get("InteractionContent-show");
				const _onClose = (...args: any[]) => {
					if (typeof onClose === "function") {
						onClose.apply(null, args);
					}
					if (args[0] !== false) {
						resolve(args[0]);
						return;
					}
					reject(new Resultado(-1, "Prompt rejeitado"));
				};
				show("prompt", { title: typeof title !== "string" ? "Alerta:" : title, body, term, value, btnNames, onClose: _onClose });
			} catch (error) {
				const message = (error as { message: string }).message;
				reject(new Resultado(-1, message));
			}
		});
	}

	static uploadImage(
		config: {
			imageSize: number;
			aspectRatio?: string;
		},
		onClose?: (...args: any[]) => void,
	): Promise<any> {
		return new Promise((resolve, reject) => {
			try {
				const { imageSize, aspectRatio }: { imageSize?: number; aspectRatio?: string } = typeof config === "object" ? config : {};
				const show = MultiStorager.DataStorager.get("InteractionContent-show");
				const _onClose = (...args: any[]) => {
					if (typeof onClose === "function") {
						onClose.apply(null, args);
					}
					if (!args[0]) {
						reject(new Resultado(-1, "Carregamento de imagem rejeitado"));
						return;
					}
					resolve(args[0]);
				};
				show("uploadImage", {
					imageSize: imageSize && typeof imageSize === "number" ? imageSize : null,
					aspectRatio: aspectRatio && typeof aspectRatio === "string" ? aspectRatio : null,
					onClose: _onClose,
				});
			} catch (error) {
				const message = (error as { message: string }).message;
				reject(new Resultado(-1, message));
			}
		});
	}

	/**
	 * Adiciona uma nova notificação.
	 * @static
	 * @param {object} options - Opções da notificação.
	 * @param {string} options.title - Título da notificação.
	 * @param {string} options.message - Mensagem da notificação.
	 * @param {'error' | 'info' | 'success' | 'warning'} [options.severity] - Tipo da notificação.
	 * @param {string} [options.image] - Imagem da notificação.
	 * @param {string} [options.type] - Tipo da notificação.
	 * @param {function} [options.action] - Função de callback para ação.
	 * @returns {Promise<void>} Uma promessa vazia.
	 */
	static pushNotification(options: {
		title: string;
		message: string;
		severity?: "error" | "info" | "success" | "warning";
		icon?: string;
		type?: string;
		action?: ((...args: any[]) => void) | null;
	}) {
		return new Promise<void>(async (resolve, reject) => {
			const { title, message, severity = "info", type = "", action = () => {}, ...opt } = options;
			let push = MultiStorager.DataStorager.get<(o: typeof options) => void>("InteractionContent-push-notification");
			while (!push) {
				await new Promise((r) => setTimeout(r, 100));
				push = MultiStorager.DataStorager.get<(o: typeof options) => void>("InteractionContent-push-notification");
			}
			push({ title, message, severity, type, action, ...opt });
			resolve();
		});
	}

	/**
	 * Exibe ou oculta a janela de notificações.
	 * @static
	 * @returns {Promise<void>} Uma promessa vazia.
	 */
	static toggleNotifications() {
		return new Promise<void>(async (resolve, reject) => {
			let toggle = MultiStorager.DataStorager.get("InteractionContent-toggle-notifications");
			while (!toggle) {
				await new Promise((r) => setTimeout(r, 100));
				toggle = MultiStorager.DataStorager.get("InteractionContent-toggle-notifications");
			}
			toggle();
			resolve();
		});
	}

	/**
	 * Exibe uma mensagem em um Toast.
	 * @static
	 * @param {string} message - A mensagem a ser exibida.
	 * @param {string} [title] - O título da mensagem (opcional).
	 * @param {'error' | 'info' | 'success' | 'warning'} [severity] - O tipo da mensagem (opcional).
	 * @param {function} [action] - Função de callback para ação (opcional).
	 * @param {number} [duration=5000] - Tempo em milissegundos de exibição da mensagem (opcional).
	 * @param {function} [onClose] - Função de callback para fechar a mensagem (opcional).
	 * @returns {Promise<void>} - Uma promessa vazia.
	 */
	static toast(
		message: string,
		title?: string | null | undefined,
		severity?: "error" | "info" | "success" | "warning",
		action?: ((...args: any[]) => void) | null,
		duration?: number,
		onClose?: (...args: any[]) => void,
	): Promise<number> {
		return new Promise((resolve, reject) => {
			try {
				const show = MultiStorager.DataStorager.get("InteractionContent-show");
				const id = Math.round(Math.random() * 100000);
				show("toast", {
					title: typeof title !== "string" || title.trim() === "" ? null : title,
					message,
					severity: typeof severity === "string" ? severity : null,
					duration: typeof duration === "number" ? duration : 6000,
					onClose: ["function", "boolean"].includes(typeof onClose) ? onClose : true,
					id,
				});
				resolve(id);
			} catch (error) {
				const message = (error as { message: string }).message;
				reject(new Resultado(-1, message));
			}
		});
	}

	/**
	 * Propriedade de roteamento
	 * @static
	 * @type {Route}
	 * @memberof InteractionHelper
	 * @example
	 * InteractionHelper.route.push("/home");
	 * InteractionHelper.route.replace("/home");
	 * console.log(InteractionHelper.route.pathname);
	 * console.log(InteractionHelper.route.query("/home"));
	 * console.log(InteractionHelper.route.query(["/home", "/profile"]));
	 */
	static route: typeof Route = Route;

	static default() {
		window.alert = InteractionHelper.alert;
		(window.confirm as any) = InteractionHelper.confirm;
		(window.prompt as any) = InteractionHelper.prompt;
		window.uploadImage = InteractionHelper.uploadImage;
		window.toast = InteractionHelper.toast;
		window.loading = InteractionHelper.loading;
	}
}
