const palette = (mode: "dark" | "light") => {
	let isDark = mode === "dark";

	return {
		box: {
			background: isDark ? "rgba(0, 0, 0, 0.2)" : "rgba(255, 255, 255, 0.3)",
			overlaid: isDark ? "rgba(0, 0, 0, .1)" : "rgba(255, 255, 255, .1)",
			dark: {
				background: isDark ? "#3f3f3f" : "#bfbfbf",
				border: isDark ? "rgba(255, 255, 255, .1)" : "rgba(0, 0, 0, .1)",
			},
		},
		text: {
			primary: isDark ? "#fafafa" : "#424242",
			secondary: isDark ? "#fafafa96" : "#424242",
		},
		TableCustom: {
			background: isDark ? "#3f3f3f" : "#bfbfbf",
			border: isDark ? "rgba(255, 255, 255, .1)" : "rgba(0, 0, 0, .1)",
			focus: isDark ? "#ffca28" : "#e65100",
		},
		MenubarComponent: {
			background: isDark ? "#212121" : "#616161",
			color: isDark ? "#e0e0e0" : "#fafafa",
			icon: {
				color: isDark ? "#FF6D00" : "#FF6D00",
			},
			MenuItem: {
				background: isDark ? "#F94C00" : "#FF6D00",
				color: isDark ? "#ff9800" : "#ff6f00",
				active: {
					color: "#ffffff",
				},
			},
			MenuSubmenu: {
				background: isDark ? "rgba(255, 255, 255, .1)" : "rgba(0, 0, 0, .2)",
				color: isDark ? "#e0e0e0" : "#212121",
			},
			SpaceAction: {
				btn: {
					background: isDark ? "rgba(255, 255, 255, .1)" : "rgba(0, 0, 0, .1)",
				},
			},
			mobile: {
				background: isDark ? "#262626" : "#bdbdbd",
			},
		},
		WindowComponent: {
			background: isDark ? "#424242" : "#e0e0e0",
			content: {
				gradient: {
					base: isDark ? "rgba(0,0,0,.6)" : "rgba(255,255,255,.6)",
				},
				background: isDark ? "#353535" : "#c9c9c9",
				border: {
					color: isDark ? "rgba(0, 0, 0, .2)" : "rgba(0, 0, 0, .1)",
				},
			},
		},
		Widget: {
			background: isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(38, 50, 56, 0.1)",
			border: isDark ? "rgba(255, 255, 255, 0.2)" : "rgba(38, 50, 56, 0.2)",
			title: isDark ? "#eceff1" : "#424242",
			aviso: isDark ? "#e0e0e0" : "#757575",
		},
		Wallets: {
			loading: {
				color: isDark ? "rgba(255, 255, 255, 0.4)" : "rgba(38, 50, 56, 0.4)",
			},
			item: {
				background: isDark ? "#121212" : "#616161",
				color: isDark ? "#f5f5f5" : "#f5f5f5",
			},
			cart: {
				color: isDark ? "#f5f5f5" : "#f5f5f5",
			},
			infoContent: {
				// background: isDark ? "#252525" : "#d6d6d6",
				// border: isDark ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.2)",

				background: isDark ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .05)",
				border: isDark ? "rgba(255, 255, 255, .1)" : "rgba(0, 0, 0, .1)",
			},
			ButtonGroup: {
				item: {
					borderColor: isDark ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.1)",
					hover: {
						borderColor: isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.2)",
						background: isDark ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.15)",
					},
				},
				contained: {
					background: isDark ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.05)",
					hover: {
						background: isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
					},
				},
			},
		},
		primary: {
			main: isDark ? "#ffca28" : "#e65100",
			light: isDark ? "#ffe082" : "#f57c00",
			dark: isDark ? "#ffb300" : "#bf360c",
		},
		error: {
			main: isDark ? "#e57373" : "#c62828",
			light: isDark ? "#ffcdd2" : "#e53935",
			dark: isDark ? "#e53935" : "#d50000",
		},
		Tela1: {
			background: isDark ? "#5f5f63" : "#c0c0c0",
		},
		Tela2: {
			background: isDark ? "#5f5f63" : "#c0c0c0",
		},
		Tela4: {
			background: isDark ? "#5f5f63" : "#c0c0c0",
		},
		Tela5: {
			background: isDark ? "#5f5f63" : "#c0c0c0",
		},
		Tela6: {
			background: isDark ? "#5f5f63" : "#c0c0c0",
		},
		Tela7: {
			background: isDark ? "#5f5f63" : "#c0c0c0",
		},
		ApiCarteiras: {
			background: isDark ? "#5f5f63" : "#c0c0c0",
			backgroundDark: isDark ? "#1B1D2D" : "#ADD8E6",
			backgroundLightDark: isDark ? "#323238" : "#323238",
			backgroundTipoCarteira: "#414040",
			borderButton: "#D9D9D9",
		},
		AddConta: {
			background: isDark ? "#414040" : "#323238",
			cab: "#323238",
		},
		Pares: {
			background: isDark ? "#414040" : "#323238",
			linhasTab: "#57575c",
			cab: "#323238",
		},
		Quiz: {
			backgroundBoxes:
				"linear-gradient(50deg,transparent 10%,rgb(255,231,0,0.5) 10%,rgb(255,231,0,0.5) 40%,transparent 40%,transparent 53%,rgb(255,231,0,0.5) 53%,rgb(255,231,0,0.5) 71%,transparent 71%),rgb(255,200,0)",
			secondaryColor: {
				background: isDark ? "rgb(148, 139, 139)" : "#c0c0c0",
			},
			backgroundBtnBoxes: "#eb781b",
			borderBtnBoxes: "#8b4a00 solid 3px",
			textBoxes: "#8b4a00",
			darkText: isDark ? "#969696" : "#353535",
			gameText: isDark ? "#fafafa" : "#353535",
			greenText: isDark ? "#93d333" : "#5a9108",
		},
		Settings: {
			"menu-item": isDark ? "rgba(255, 255, 255, .5)" : "rgba(0, 0, 0, .5)",
			"registerIP": {
				background: isDark ? "rgba(255, 255, 255, .1)" : "rgba(0, 0, 0, .05)",
				border: isDark ? "rgba(255, 255, 255, .3)" : "rgba(0, 0, 0, .3)",
			},
		},
		Chart: {
			color: {
				primary: isDark ? "#00AEEF" : "#0288d1",
				secondary: isDark ? "#ffca28" : "#e65100",
			},
			chartView: {
				background: isDark ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .05)",
				border: isDark ? "rgba(255, 255, 255, .1)" : "rgba(0, 0, 0, .1)",
			},
			popup: {
				background: isDark ? "rgba(0, 0, 0, .6)" : "rgba(255, 255, 255, .6)",
				border: isDark ? "rgba(0, 0, 0, .2)" : "rgba(255, 255, 255, .2)",
			},
			zeroLine: {
				stroke: isDark ? "rgba(255, 255, 255, .5)" : "rgba(0, 0, 0, .5)",
			},
		},
		MuiPaper: {
			background: isDark ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .05)",
			border: isDark ? "rgba(255, 255, 255, .1)" : "rgba(0, 0, 0, .1)",
		},
		SplashLogoMarca: {
			letters: {
				stroke: isDark ? "#e0e0e0" : "#424242",
				fill: isDark ? "#e0e0e0" : "#424242",
			},
		},
		BDManager: {
			nodeTree: {
				sibling: {
					background: isDark ? "rgba(255, 255, 255, .3)" : "rgba(0, 0, 0, .3)",
				},
			},
		},
		Presale: {
			Widget: {
				"balance-carousel": {
					cart: {
						background: isDark ? "rgba(0, 0, 0, .2)" : "rgba(255, 255, 255, .3)",
						details: isDark ? "rgba(0, 0, 0, .2)" : "rgba(0, 0, 0, 0.04)",
					},
				},
			},
		},
		action: {
			light: {
				active: isDark ? "rgba(0, 0, 0, .7)" : "rgba(255, 255, 255, .7)",
			},
		},
		Chat: {
			picture: {
				background: isDark ? "#212121" : "#757575",
			},
			glowing: {
				background: isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
			},
			background: {
				image: isDark ? "url(/src/image/chat-seamless-pattern-dark.png)" : "url(/src/image/chat-seamless-pattern-light.png)",
			},
			header: {
				background: isDark ? "#212121" : "#EEEEEE",
			},
			chatbox: {
				empty: {
					shadow: {
						color: isDark ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)",
					},
				},
				chatmessages: {
					message: {
						me: {
							background: {
								color: isDark ? "#ffc107" : "#ed6c02",
								image: isDark
									? "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))"
									: "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
							},
						},
					},
				},
			},
			input: {
				background: isDark ? "#121212" : "#363636",
				color: "#fafafa",
				button: {
					send: {
						color: "#ffca28",
					},
				},
			},
		},
		Ranking: {
			title: isDark ? "#ECEFF1" : "#363636",
			paragraph: isDark ? "#ECEFF1" : "#363636",
			card: isDark ? "#ECEFF1" : "#ECEFF1",
			experience: isDark ? "#eba142" : "#eba142",
			headerBackground: isDark ? "#343434" : "#FFFFFF",
			underline: isDark ? "#eba142" : "#363636",
			gradient: isDark
				? "linear-gradient(45deg, rgba(3, 3, 3, 0.1), rgba(255, 255, 255, 0.178))"
				: "linear-gradient(45deg, rgba(3, 3, 3, 0.4), rgba(65, 64, 64, 0.778))",
			firstPlace: isDark
				? "linear-gradient(45deg, rgba(255, 217, 1, 0.7), rgba(83, 83, 83, 0.8))"
				: "linear-gradient(45deg, rgba(255, 217, 1, 0.7), rgba(83, 83, 83, 0.8))",
			secondPlace: isDark
				? "linear-gradient(45deg, rgba(185, 185, 185, 0.7), rgba(83, 83, 83, 0.8))"
				: "linear-gradient(45deg, rgba(185, 185, 185, 0.7), rgba(83, 83, 83, 0.8))",
			thirdPlace: isDark
				? "linear-gradient(45deg, rgba(133, 101, 15, 0.7), rgba(83, 83, 83, 0.8))"
				: "linear-gradient(45deg, rgba(133, 101, 15, 0.7), rgba(83, 83, 83, 0.8))",
		},
		Profile: {
			background: isDark ? "rgba(255,255,255,0.1)" : "rgba(255,255,255,0.1)",
			disabled: isDark ? "rgba(255,255,255,0.1)" : "rgba(255,255,255,0.2)",
			user: isDark ? "rgba(255,255,255,0.2)" : "rgba(255,255,255,0.2)",
			brandWhite: isDark ? "#FFFFFF" : "rgba(0,0,0,0.8)",
		},
		Portal: {
			basicGrey: isDark ? "rgb(132, 142, 156)" : "rgb(255, 255, 255)",
		},
	};
};

export default palette;
