import React, { useState, useEffect } from 'react'
import style from './style.module.scss'
import { CircularProgress, Box } from '@mui/material'
import RankingList from './Components/RankingList'

const Ranking = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  },[loading])

  return (
    <div className={style['root']}>
      {loading && (
        <Box className={style['loading']} sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )}
      {!loading && <RankingList />}
    </div>
  )
}

export default Ranking
