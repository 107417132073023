import React, { useEffect, useLayoutEffect, useState } from "react";

import { FormControl, InputLabel, Select, MenuItem, Grid, SelectChangeEvent } from "@mui/material";

const InstallmentSelect: React.FC<{
	defaultInstallment: number;
	installments?: number;
	onChange?: (portion: number) => void;
}> = ({ defaultInstallment, installments, onChange }) => {
	const [id_1] = useState<string>(`select-type-of-payment-${Math.round(Math.random() * 10000)}`);
	const [id_2] = useState<string>(`select-portion-${Math.round(Math.random() * 10000)}`);
	const [typeOfPayment, setTypeOfPayment] = useState<"in_cash" | "installments">("in_cash");
	const [portion, setPortion] = useState<number>(1);

	useLayoutEffect(() => {
		setTypeOfPayment(defaultInstallment > 0 ? "installments" : "in_cash");
		setPortion(defaultInstallment + 1);
	}, [defaultInstallment]);

	useEffect(() => {
		if (typeof onChange === "function") {
			onChange(portion - defaultInstallment);
		}
	}, [portion]);

	const handleChangeTypeOfPayment = ({ target }: SelectChangeEvent<"installments" | "in_cash">) => {
		setTypeOfPayment(target.value as any);
		if (target.value === "in_cash") {
			setPortion(1);
		} else if (portion <= 1) {
			setPortion(2);
		}
	};

	const handleChangePortion = ({ target }: SelectChangeEvent<number>) => {
		setPortion(parseFloat(target.value as any));
	};

	const installmentsList = new Array(Math.max(defaultInstallment) + (typeof installments === "number" && installments > 1 ? installments - 1 : 0))
		.fill(null)
		.map((v, i) => i + 2);

	return (
		<Grid
			container
			spacing={2}
			sx={{ marginBottom: "16px" }}
		>
			<Grid
				item
				xs={12}
				md={7}
			>
				<FormControl
					size="small"
					fullWidth
				>
					<InputLabel id={id_1}>Tipo de pagamento</InputLabel>
					<Select
						id={id_1}
						value={typeOfPayment}
						label="Tipo de pagamento"
						onChange={handleChangeTypeOfPayment}
					>
						<MenuItem
							value="in_cash"
							disabled={defaultInstallment > 0}
						>
							Á Vista
						</MenuItem>
						<MenuItem value="installments">Parcelado</MenuItem>
					</Select>
				</FormControl>
			</Grid>
			<Grid
				item
				xs={12}
				md={5}
			>
				<FormControl
					size="small"
					fullWidth
					disabled={typeOfPayment === "in_cash"}
				>
					<InputLabel id={id_2}>Parcelas</InputLabel>
					<Select
						id={id_2}
						value={portion}
						label="Parcelas"
						onChange={handleChangePortion}
					>
						<MenuItem
							value={1}
							disabled
						>
							<em>1 parcela</em>
						</MenuItem>
						{installmentsList.map((portion, index) => {
							return (
								<MenuItem
									value={portion}
									key={index}
								>{`${portion} parcelas`}</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</Grid>
		</Grid>
	);
};

export default InstallmentSelect;
