import { SimulateSwap, TradingView } from "Components";
import React from "react";
import style from "./style.module.scss";
import { Wallet } from "Models";
import { CircularProgress } from "@mui/material";
import { WalletHelper } from "Helper";

const ViewTrade: React.FC<{
	wallet?: Wallet | null;
}> = ({ wallet }) => {
	return (
		<div className={style["view-trade-container"]}>
			{!wallet && <CircularProgress color="inherit" />}
			{wallet && (
				<>
					<div className={style["view-trade-swap"]}>
						<SimulateSwap
							walletId={wallet.getWalletId()}
							walletType={wallet.type}
							from={"BRL"}
							to={"IVIP"}
							tradeButton={true}
							onApllyOrder={(symbol, quantity, amount, type) => {
								WalletHelper.showTradeSwap({
									walletId: wallet.getWalletId(),
									symbol,
									quantity,
									amount,
									type,
								});
							}}
						/>
					</div>
					<TradingView className={style["view-trade-iframe"]} />
				</>
			)}
		</div>
	);
};

export default ViewTrade;
