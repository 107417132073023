import { currencyFormat } from "Utils";

export const themes = {
    light: {
        background: "#b5b5b5",
        timeline_color: "#212121",
        color: "#212121"
    },
    dark: {
        background: "#2a2a2a",
        timeline_color: "#e0e0e0",
        color: "#e0e0e0"
    }
}

export const SVG_clear = (node)=>{
    while(node.lastChild){
        node.removeChild(node.lastChild);
    }
}

/**
 * Adiciona um elemento SVG a um nó do documento.
 * @param {Element} node - O nó do documento ao qual o elemento SVG será anexado.
 * @param {string} name - O nome do elemento SVG a ser criado.
 * @param {Object} attrs - Os atributos do elemento SVG a serem definidos.
 * @param {string} [text] - O texto a ser adicionado como conteúdo do elemento SVG (opcional).
 * @returns {Element} - O elemento SVG criado e anexado ao nó do documento.
 */
export const SVG_appendTo = (node, name, attrs, text)=>{
    let p, ns, svg = node, doc = node.ownerDocument;
    if(!ns){
        while(svg && svg.tagName.toLowerCase() != 'svg'){
            svg = svg.parentNode;
        };
        ns = { svg: svg.namespaceURI };
        for(let a=svg.attributes, i=a.length; i--;){
            if(a[i].namespaceURI){
                ns[a[i].localName] = a[i].nodeValue;
            }
        }
    }
    let el = doc.createElementNS(ns.svg, name);
    for(let attr in attrs){
        if(!attrs.hasOwnProperty(attr)){
            continue;
        }
        if(!(p=attr.split(':'))[1]){
            el.setAttribute(attr, attrs[attr]);
        }else{
            el.setAttributeNS(ns[p[0]] || null, p[1], attrs[attr]);
        }
    }
    if(text){
        el.appendChild(doc.createTextNode(text));
    }
    node.appendChild(el);
    return el;
}

export const keysModel = ["date", "open", "high", "low", "close", "volume"];

export const getPathMarkerBoxplot = (data, index, { height_area, start, end, maxValue, minValue, maxVolume, minVolume, deficit_width, width_signal })=>{
    if(data.isDataValid !== true || typeof index !== "number" || index < 0 || index >= data.data.length){
        return "";
    }

    let d = data.data[index];

    if(!(d && d.hasOwnProperty && keysModel.every(item => d.hasOwnProperty(item)))){
        return "";
    }

    if(start > index || end < index){return "";}

    let rect = {
        x: 0, y: 0,
        width: 0, height: 0,
        top: 0, left: 0, right: 0, bottom: 0,
        top_box: 0, bottom_box: 0, center: 0
    };

    rect.width = Math.max(5, width_signal * 0.8) - 2;

    rect.left = rect.x = deficit_width + ((width_signal * index) - (rect.width/2));
    rect.top = rect.y = height_area - (height_area * ((d["high"] - minValue)/(maxValue - minValue)));
    rect.bottom = height_area - (height_area * ((d["low"] - minValue)/(maxValue - minValue)));
    rect.right = rect.left + rect.width;

    rect.height = rect.bottom - rect.top;

    rect.top_box = height_area - (height_area * ((Math.min(d["open"], d["close"]) - minValue)/(maxValue - minValue)));
    rect.bottom_box = height_area - (height_area * ((Math.max(d["open"], d["close"]) - minValue)/(maxValue - minValue)));

    rect.center = rect.left + (rect.width/2);

    const pathPicker = `M${rect.left},${rect.top_box} L${rect.center},${rect.top_box} L${rect.center},${rect.top} L${rect.center},${rect.top_box} L${rect.right},${rect.top_box} L${rect.right},${rect.bottom_box} L${rect.center},${rect.bottom_box} L${rect.center},${rect.bottom} L${rect.center},${rect.bottom_box} L${rect.left},${rect.bottom_box} L${rect.left},${rect.top_box} Z`;

    const bar_width = Math.max(1, width_signal * 0.5) - 2;
    const height_volume = 0.2;
    const bar_height = (height_area * height_volume);

    const bar_top = height_area - Math.round(bar_height * ((d["volume"] - minVolume)/(maxVolume - minVolume))) - 10;
    const bar_left = Math.round(rect.center - (bar_width/2));
    const bar_right = Math.round(bar_left + bar_width);

    const pathVolume = `M${bar_left} ${height_area} L${bar_left} ${bar_top} L${bar_right} ${bar_top} L${bar_right} ${height_area} Z`;

    return [pathPicker, pathVolume];
}

export const getPathRectPanel = ({ width, height, radius })=>{
    let rect = {
        x: 0, y: 0,
        width: 0, height: 0,
        top: 0, left: 0, right: 0, bottom: 0,
        radius: Math.min(50, Math.max(0, typeof radius === "number" ? radius : 0))
    };

    rect.width = width;
    rect.height = height;

    rect.right = rect.left + rect.width;
    rect.bottom = rect.top + rect.height;

    return "M" + rect.left + "," + rect.top + " h" + (rect.width - rect.radius) + " a" + rect.radius + "," + rect.radius + " 0 0 1 " + rect.radius + "," + rect.radius + " v" + (rect.height - (rect.radius*2)) + " a" + rect.radius + "," + rect.radius + " 0 0 1 " + (-rect.radius) + "," + rect.radius + " h" + (-(rect.width - (rect.radius*2))) + " a" + rect.radius + "," + rect.radius + " 0 0 1 " + (-rect.radius) + "," + (-rect.radius) + " v" + (-(rect.height - (rect.radius*2))) + " a" + rect.radius + "," + rect.radius + " 0 0 1 " + rect.radius + "," + (-rect.radius);
}

export const getAreaSlice = (data, { width = 0, width_signal = 20, timeline_pos = 0 })=>{
    if((data.data.length * width_signal) <= width){
        return {
            start: 0,
            end: data.data.length-1
        }
    }

    let start = timeline_pos > 0 ? 0 : Math.round(Math.abs(timeline_pos)/width_signal);
    let end = start + Math.round(width/width_signal);

    return {
        start: Math.max(0, start - 2),
        end: Math.min(data.data.length-1, end + 2)
    }
}

export const getPathAverage = (data, {height_area, start, end, maxValue, minValue, deficit_width, d_area, width_signal, dateStart, dateEnd})=>{
    if(data.isDataValid !== true){return "";}
    let result = [];

    d_area.forEach((obj, i)=>{
        let x = 0, y = 0;
        let index = Math.round((new Date(obj.date) - dateStart)/(1000*60*60*24))+1;
        index = i + start;

        x = deficit_width + (width_signal * index);
        y = height_area-(height_area * ((obj.average - minValue)/(maxValue - minValue)));

        result.push(`${x} ${y}`);
    });

    return "M" + result.join(" L");
}

export const getBasicSettingGridlines = (data = {}, basicSetting = {})=>{
    if(data.isDataValid !== true){return {path: "", labels: []};}

    try{
        let result = {path: [], labels: []}, length = 6;

        const { width_area, height_area, start, end, maxValue, minValue, deficit_width, d_area, width_signal, dateStart, dateEnd, margin_top } = basicSetting;

        length = Math.floor(height_area/50);

        const interval = (maxValue-minValue)/length;

        for(let i=0; i<=length; i++){
            let y = Number(((height_area*((interval*i)/(maxValue-minValue)))+margin_top).toFixed(16));
            if(isNaN(y)){continue;}

            let label = new Intl.NumberFormat('pt-BR', {style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2}).format((interval*(length-i))+minValue);

            label = currencyFormat.convert((interval*(length-i))+minValue, "USD", 4);

            result.labels.push({
                y: y,
                x: width_area,
                label: label
            });

            let margin_grid = i === 0 ? 0.5 : i === length ? -0.5 : 0

            result.path.push(`M${0} ${y+margin_grid} L${width_area} ${y+margin_grid} Z`);
        }

        result.path = result.path.join(" ");

        return result;
    }catch(e){return {path: "", labels: []};}
}

export const reduceDataToDays = (data)=>{
    const groupedData = data.reduce((groups, obj)=>{
        const date = new Date(obj.date).toISOString().split('T')[0];
        if(!groups[date]){
            groups[date] = [];
        }
        groups[date].push(obj);
        return groups;
    }, {});

    const reducedData = Object.keys(groupedData).map(date =>{
        const dayData = groupedData[date];
        const high = dayData.reduce((max, obj) => Math.max(max, obj.high), dayData[0].high);
        const low = dayData.reduce((min, obj) => Math.min(min, obj.low), dayData[0].low);
        const open = dayData[0].open;
        const close = dayData[dayData.length - 1].close;
        const volume = dayData.reduce((a, {volume}) => a+volume, 0);
        const average = dayData.reduce((a, {average}) => a+average, 0) / dayData.length;
        return { date: new Date(date), open, high, low, close, average, volume };
    });

    return reducedData;
}