import React, { useEffect, useLayoutEffect, useRef } from "react";
import InsigniaImage from "./InsigniaImage";
import style from "./style.module.scss";
import { RankTypes } from "Types/Quiz";
import { isMobile } from "Utils";

const calcularPosicao = (raio: number, anguloEmGraus: number) => {
	const anguloEmRadianos = (anguloEmGraus * Math.PI) / 180;
	const x = raio * Math.cos(anguloEmRadianos);
	const y = raio * Math.sin(anguloEmRadianos);
	return { x, y };
};

const Insignia: React.FC<{
	type?: RankTypes;
	progress?: number;
	stars?: number[];
}> = ({ type = "bronze", progress = 0, stars = [] }) => {
	const main_ref = useRef<HTMLDivElement>(null);

	const parallaxMove = (x: number, y: number, boxWidth: number, boxHeight: number, strength: number = 0.2) => {
		if (!main_ref.current) {
			return;
		}
		const parallaxContainer = main_ref.current;
		const list = parallaxContainer.childNodes as NodeListOf<HTMLElement>;
		for (const element of list) {
			const depth = parseFloat(element.getAttribute("data-depth") ?? "0");
			const moveX = (boxWidth / 2 - x) * (strength * depth);
			const moveY = (boxHeight / 2 - y) * (strength * depth);
			element.style.transform = `translate3d(${moveX.toFixed(4)}px, ${moveY.toFixed(4)}px, 0)`;
		}
	};

	const resetParallaxPosition = () => {
		if (!main_ref.current) {
			return;
		}
		const parallaxContainer = main_ref.current;
		const list = parallaxContainer.childNodes as NodeListOf<HTMLElement>;
		for (const element of list) {
			element.style.transform = "translate3d(0px, 0px, 0px)";
		}
	};

	useEffect(() => {
		const move = ({ clientX, clientY, ...event }: MouseEvent) => {
			//event.stopPropagation();
			if (!main_ref.current || isMobile) {
				return;
			}

			const { x, y, width, height } = main_ref.current.getBoundingClientRect();

			parallaxMove(clientX - x, clientY - y, width, height);
		};

		const deviceorientation = ({ gamma, beta, ...event }: DeviceOrientationEvent) => {
			//event.stopPropagation();
			if (!main_ref.current) {
				return;
			}

			const { x, y, width, height } = main_ref.current.getBoundingClientRect();

			const newWidth = width * 20;
			const newHeight = height * 20;

			parallaxMove((((gamma ?? 0) + 180) / 180) * newWidth - newWidth, (((beta ?? 0) + 90) / 180) * newHeight - newHeight, width, height);
		};

		const leave = () => {
			resetParallaxPosition();
		};

		document.addEventListener("mousemove", move);
		document.addEventListener("mouseleave", leave);
		//window.addEventListener("deviceorientation", deviceorientation);

		const time = setTimeout(() => {
			resetParallaxPosition();
		}, 200);

		return () => {
			document.removeEventListener("mousemove", move);
			document.removeEventListener("mouseleave", leave);
			//window.removeEventListener("deviceorientation", deviceorientation);
			clearTimeout(time);
		};
	}, [main_ref?.current, type]);

	return (
		<div
			className={style["main"]}
			ref={main_ref}
		>
			<svg
				viewBox="0 0 164.87 164.87"
				data-depth="0"
			>
				<circle
					cx="82.43"
					cy="82.43"
					r="55.47"
				/>
				<circle
					cx="82.43"
					cy="82.43"
					r="55.47"
					strokeLinecap="round"
					style={
						{
							"--stroke-length": "347",
							"--stroke-offset": (347 * (1 - progress)).toFixed(0),
						} as React.CSSProperties & { [k: string]: string }
					}
				/>
			</svg>
			<div data-depth="0.02">
				{stars.map((percent, index, self) => {
					const center = 300 / 2;
					const size = 30;
					const angle = 360 * percent - 90;
					const isActive = percent < progress;
					const { x, y } = calcularPosicao(100.93, angle);

					return (
						<svg
							key={index}
							height={`${size}px`}
							width={`${size}px`}
							viewBox="0 0 47.94 47.94"
							style={{ position: "absolute", top: `${y + center - size / 2}px`, left: `${x + center - size / 2}px` }}
						>
							<path
								style={{ fill: isActive ? "#ED8A19" : "#D8D8D8", filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, .4))" }}
								d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757  c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042  c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685  c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528  c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956  C22.602,0.567,25.338,0.567,26.285,2.486z"
							/>
						</svg>
					);
				})}
			</div>
			<InsigniaImage
				type={type}
				depth={0.04}
			/>
		</div>
	);
};

export default Insignia;
