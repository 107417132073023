import "./styles.scss";

import React, { useEffect, useState } from "react";
import { APIHelper } from "Helper";

const QuizAdmin: React.FC = () => {
	const [formData, setFormData] = useState<any[]>([
		{
			sectionName: "",
			units: [
				{
					unitName: "",
					levels: [
						{
							quizes: [
								{
									questionText: "",
									options: ["", "", "", ""],
									correctOptionIndex: "",
								},
							],
						},
					],
				},
			],
		},
	]);

	const handleAddSection = () => {
		setFormData([
			...formData,
			{
				sectionName: "",
				units: [
					{
						unitName: "",
						levels: [
							{
								quizes: [
									{
										questionText: "",
										options: ["", "", "", ""],
										correctOptionIndex: "",
									},
								],
							},
						],
					},
				],
			},
		]);
	};

	const handleAddUnit = (sectionIndex: number) => {
		const newFormData = [...formData];
		newFormData[sectionIndex].units.push({
			unitName: "",
			levels: [
				{
					quizes: [
						{
							questionText: "",
							options: ["", "", "", ""],
							correctOptionIndex: "",
						},
					],
				},
			],
		});
		setFormData(newFormData);
	};

	const handleAddLevel = (sectionIndex: number, unitIndex: number) => {
		const newFormData = [...formData];
		newFormData[sectionIndex].units[unitIndex].levels.push({
			quizes: [{ questionText: "", options: ["", "", "", ""], correctOptionIndex: "" }],
		});
		setFormData(newFormData);
	};

	const handleAddQuestion = (sectionIndex: number, unitIndex: number, levelIndex: number) => {
		const newFormData = [...formData];
		newFormData[sectionIndex].units[unitIndex].levels[levelIndex].quizes.push({
			questionText: "",
			options: ["", "", "", ""],
			correctOptionIndex: "",
		});
		setFormData(newFormData);
	};

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
		sectionIndex: number,
		unitIndex: number,
		levelIndex: number,
		questionIndex: number,
		field: string,
	) => {
		const { value } = e.target;
		const newFormData = [...formData];
		if (field === "options") {
			// If the field is "options", update the corresponding option at the specified index
			newFormData[sectionIndex].units[unitIndex].levels[levelIndex].quizes[questionIndex].options[e.target.dataset.optionindex as any] = value;
		} else {
			// For other fields, update normally
			newFormData[sectionIndex].units[unitIndex].levels[levelIndex].quizes[questionIndex][field] = value;
		}
		setFormData(newFormData);
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		await APIHelper.fetch("education/quiz/create", "POST", { formData });
		// const response = await APIHelper.fetch("education/quiz/getAllQuizzes", "POST",{formData});

		// console.log(JSON.stringify(response, null, 2));
	};

	// const { status, response } = APIHelper.useFetch<any>({
	// 	route: "/education/quiz/getAllQuizzes",
	// 	method: "GET",
	// });
	// console.log(JSON.stringify(response, null, 2));
	return (
		<form
			className="form"
			onSubmit={handleSubmit}
		>
			{formData.map((section, sectionIndex) => (
				<div
					key={sectionIndex}
					className="section"
				>
					<input
						type="text"
						placeholder="Nome da seção"
						value={section.sectionName}
						onChange={(e) =>
							setFormData(formData.map((item, index) => (index === sectionIndex ? { ...item, sectionName: e.target.value } : item)))
						}
					/>
					{section.units.map((unit: any, unitIndex: any) => (
						<div
							key={unitIndex}
							className="unit"
						>
							<input
								type="text"
								placeholder="Nome da unidade"
								value={unit.unitName}
								onChange={(e) =>
									setFormData(
										formData.map((item, index) =>
											index === sectionIndex
												? {
														...item,
														units: item.units.map((u: any, i: any) =>
															i === unitIndex ? { ...u, unitName: e.target.value } : u,
														),
												  }
												: item,
										),
									)
								}
							/>
							{unit.levels.map((level: any, levelIndex: any) => (
								<div
									key={levelIndex}
									className="level"
								>
									{level.quizes.map((quiz: any, quizIndex: any) => (
										<div
											key={quizIndex}
											className="quiz"
										>
											<input
												type="text"
												placeholder="Pergunta"
												value={quiz.questionText}
												onChange={(e) => handleChange(e, sectionIndex, unitIndex, levelIndex, quizIndex, "questionText")}
											/>
											{/* Input for options */}
											{quiz.options &&
												quiz.options.map((option: any, optionIndex: any) => (
													<input
														key={optionIndex}
														type="text"
														placeholder={`Opção ${optionIndex + 1}`}
														value={option}
														onChange={(e) => handleChange(e, sectionIndex, unitIndex, levelIndex, quizIndex, "options")}
														data-optionindex={optionIndex}
													/>
												))}
											{/* Input for correct option index */}
											<input
												type="text"
												placeholder="Índice da opção correta"
												value={quiz.correctOptionIndex}
												onChange={(e) =>
													handleChange(e, sectionIndex, unitIndex, levelIndex, quizIndex, "correctOptionIndex")
												}
											/>
										</div>
									))}
									<button
										type="button"
										onClick={() => handleAddQuestion(sectionIndex, unitIndex, levelIndex)}
									>
										Adicionar Pergunta
									</button>
								</div>
							))}
							<button
								type="button"
								onClick={() => handleAddLevel(sectionIndex, unitIndex)}
							>
								Adicionar Nível
							</button>
						</div>
					))}
					<button
						type="button"
						onClick={() => handleAddUnit(sectionIndex)}
					>
						Adicionar Unidade
					</button>
				</div>
			))}
			<button
				type="button"
				className="add-section-button"
				onClick={handleAddSection}
			>
				Adicionar Seção
			</button>
			<button
				type="submit"
				className="submit-button"
			>
				Enviar
			</button>
		</form>
	);
};

export default QuizAdmin;
