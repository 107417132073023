import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import { InputLabel, OutlinedInput, MenuItem, Select, FormHelperText, FormControl } from "@mui/material";

import { ResponseHelper, APIHelper } from "Helper";

import { InputCustom } from "Components";

import { Address } from "Models";

const AddressView = ({ address = {}, updateAddress }) => {
    const [paisLista, setPaisLista] = useState([]);
    const [editAddress, setEditAddress] = useState(new Address());
    const [loading, setLoading] = useState(true);
    const timeSearchCep = useRef();

    useLayoutEffect(()=>{
        if(typeof updateAddress === "function"){
            updateAddress(editAddress);
        }
    }, [editAddress]);

    const editInput = useCallback((prop = "", value) => {
        if(loading){ return; }

        prop = prop.split(".");

        setEditAddress(prev => {
            prev = prev.toJson();
            const lastProp = prop.pop();
            const current = prop.reduce((obj, prop)=>{
                return typeof obj[prop] === "object" && obj[prop] !== null ? obj[prop] : obj[prop] = {};
            }, prev);
            current[lastProp] = value;
            return new Address().parse(null, prev);
        });
    }, [ loading ]);

    useEffect(()=>{
        setEditAddress(()=> new Address().parse(null, address));
    }, [address]);

    useLayoutEffect(() => {
        setLoading(true);

        ResponseHelper.getCountry().then((pais) => {
            setPaisLista(() => Object.entries(pais));
            setLoading(false);
        });
    }, []);

    const searchCep = useCallback((cep)=>{
        clearTimeout(timeSearchCep.current);
        if(typeof cep != "string" || cep.replace(/\D/gi, "").length != 8){ return; }
        timeSearchCep.current = setTimeout(()=>{
            setLoading(true);
            APIHelper.fetch(`/utils/cep/${cep}`).then(({logradouro, complemento, bairro, municipio, estado}) => {
                setEditAddress(prev =>{
                    prev.logradouro = prev.logradouro.trim() !== "" ? prev.logradouro : logradouro;
                    prev.complemento = prev.complemento.trim() !== "" ? prev.complemento : complemento;
                    prev.bairro = prev.bairro.trim() !== "" ? prev.bairro : bairro;
                    prev.municipio = prev.municipio.trim() !== "" ? prev.municipio : municipio;
                    prev.estado = prev.estado.trim() !== "" ? prev.estado : estado;

                    return new Address().parse(null, prev);
                });
            }).finally(()=>{
                setLoading(false);
            })
        }, 2000);
    }, []);

    return (<>
        <FormControl fullWidth size="small">
            <InputLabel id="paisLabel">País</InputLabel>
            <Select
                labelId="paisLabel" size="small"
                value={editAddress.pais} disabled={loading}
                input={<OutlinedInput label="País" />}
                onChange={({target: { value }}) => editInput("pais", value)}
            >
                {paisLista.map(([id, pais], index) => {
                    return (<MenuItem key={index} value={id}>{pais}</MenuItem>);
                })}
            </Select>
            <FormHelperText> </FormHelperText>
        </FormControl>

        {(editAddress.pais == "BR") && <InputCustom
            label="Cep" helperText=" "
            value={editAddress.cep} disabled={loading}
            onChange={(value)=>{
                editInput("cep", value)
                searchCep(value);
            }}
        />}

        <InputCustom
            label="Endereço" helperText=" "
            value={editAddress.logradouro} disabled={loading}
            onChange={(value) => editInput("logradouro", value)}
        />

        <InputCustom
            label="Número" helperText=" "
            value={editAddress.numero} disabled={loading}
            onChange={(value) => editInput("numero", value)}
        />

        <InputCustom
            label="Complemento" helperText=" " disabled={loading}
            value={editAddress.complemento}
            onChange={(value) => editInput("complemento", value)}
        />

        <InputCustom
            label="Bairro" helperText=" " disabled={loading}
            value={editAddress.bairro}
            onChange={(value) => editInput("bairro", value)}
        />

        <InputCustom
            label="Município" helperText=" " disabled={loading}
            value={editAddress.municipio}
            onChange={(value) => editInput("municipio", value)}
        />

        <InputCustom
            label="Estado" helperText=" " disabled={loading}
            value={editAddress.estado}
            onChange={(value) => editInput("estado", value)}
        />
    </>);
};

export default AddressView;