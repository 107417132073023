import React, { Component, useRef, useState, useEffect, useCallback, useLayoutEffect } from "react";
import { Router, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import history from "./routerHistory";
import { UserHelper, InteractionHelper, APIHelper } from "./Helper";
import { MultiStorager } from "./Utils";

import { MainFrame } from "./Components";

import Page_404 from "./Pages/Page_404";
import Splash from "./Pages/Splash";
import Restriction from "./Pages/Restriction";

import { pages } from "./Pages";
import { Usuario } from "Models";

window.routerHistory = history;

//window.UserHelper = UserHelper;

const LinkReferencia = ({ history, match }) => {
	useLayoutEffect(() => {
		InteractionHelper.loading();
		const referenceBy = match.params.refId;

		const time = setTimeout(async () => {
			if (/^[A-Z0-9]{16,18}$/gi.test(String(referenceBy).trim()) !== true) {
				return;
			}

			try {
				const usuario = await UserHelper.getUser().catch((err) => Promise.resolve(null));

				if (usuario instanceof Usuario && typeof usuario.referenceBy === "string" && usuario.referenceBy.trim() !== "") {
					if (usuario.referenceBy !== referenceBy) {
						InteractionHelper.toast("Você já está associado(a) a uma conta e não pode se associar a outra conta", null, "warning");
					}
				} else {
					const isValid = await APIHelper.fetch(`use/validateReferenceCode/${referenceBy}`).catch(() => Promise.resolve(false));
					if (isValid) {
						if (usuario instanceof Usuario) {
							if (usuario.referenceCode !== referenceBy) {
								usuario.referenceBy = referenceBy;
								await UserHelper.salvarUsuario(usuario);
								InteractionHelper.toast("Você acabou de associar a uma conta", null, "success");
							} else {
								InteractionHelper.toast("Você não pode se associar consigo mesmo", null, "warning");
							}
						} else {
							MultiStorager.DataStorager.set("referenceBy", referenceBy);
						}
					}
				}
			} catch {}

			InteractionHelper.close();
			history.push("/");
		}, 500);

		return () => {
			clearTimeout(time);
			InteractionHelper.close();
		};
	}, []);

	return;
};

export default () => {
	const [estado, setState] = useState("carregando");
	const [isOnline, setIsOnline] = useState(true);
	const [usuario, setUsuario] = useState(null);
	const [restrictedPermission, setRestrictedPermission] = useState(false);
	const timeSetUser = useRef(null);

	useEffect(() => {
		if (estado === "conectado") {
			MainFrame.Controller.secondaryPages();
		} else if (estado === "desconectado") {
			MainFrame.Controller.initialPages();
		} else {
			MainFrame.Controller.splash();
		}
	}, [estado]);

	const useUser = useCallback(
		(usr) => {
			clearTimeout(timeSetUser.current);
			const time = estado === "carregando" ? 1000 : 0;

			timeSetUser.current = setTimeout(() => {
				if (usr instanceof Usuario) {
					setUsuario(() => usr);
					setState("conectado");
				} else {
					setUsuario(() => null);
					setState("desconectado");
				}
			}, time);
		},
		[estado],
	);

	useEffect(() => {
		const callUser = MultiStorager.DataStorager.addListener("usuario", (usuario) => {
			useUser(usuario);
		});

		const callRestrictedPermission = MultiStorager.DataStorager.addListener("__restrictedPermission", (permission) => {
			setRestrictedPermission(() => (typeof permission === "boolean" ? permission : false));
		});

		return () => {
			callUser.stop();
			callRestrictedPermission.stop();
		};
	}, [usuario, estado]);

	useLayoutEffect(() => {
		const initialTime = setTimeout(() => {
			if (usuario !== null) {
				return;
			}

			UserHelper.getUser()
				.then((usuario) => {
					useUser(usuario);
				})
				.catch((err) => {
					useUser(null);
				});
		}, 1000);

		return () => {
			clearTimeout(initialTime);
		};
	}, []);

	if (estado === "conectado" && isOnline) {
		if (restrictedPermission && usuario.verificarPermissao("restrito") !== true) {
			usuario.permissoes.push("restrito");
		}

		return (
			<Router history={history}>
				<Switch>
					{pages.map(({ permissoes, ...props }, i) => {
						const withoutPermission =
							(permissoes && usuario instanceof Usuario && usuario.verificarPermissao(permissoes) !== true) ||
							(permissoes && !(usuario instanceof Usuario));
						const requiresAccess = permissoes === "restrito" || (Array.isArray(permissoes) && permissoes.includes("restrito"));

						return (
							<Route
								exact
								{...props}
								key={`Rota-${i}`}
								component={withoutPermission ? (requiresAccess ? Restriction : Page_404) : props.component}
							/>
						);
					})}
					<Route
						exact
						path="/:refId"
						component={LinkReferencia}
					/>
					<Route
						path="*"
						component={Page_404}
					/>
				</Switch>
			</Router>
		);
	}

	return (
		<Splash
			isOnline={isOnline}
			desconectado={estado === "desconectado"}
		/>
	);
};
