import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";

import useScroll from "./useScroll";

const StableInfiniteScroll = forwardRef(({ 
    children, loadingComponent, 
    onNext, nextEnd = true, nextLoading = false, 
    onPrevious, previousEnd = true, previousLoading = false, ...props }, ref)=>{

    const [scrolledToBottom, scrolledToTop, containerRef] = useScroll();

    useImperativeHandle(ref, ()=>{
        return {
            containerRef,
            container: containerRef.current,
            getContainer: ()=>{
                return containerRef.current;
            }
        };
    });

    const handlePrevious = useCallback(()=>{
        const container = containerRef.current;
        if(!container) return;

        if(scrolledToTop && !previousLoading && !previousEnd && !nextLoading && typeof onPrevious === "function"){
            onPrevious();
        }
    }, [scrolledToTop, onPrevious, nextLoading, previousEnd, previousLoading]);

    const handleNext = useCallback(()=>{
        const container = containerRef.current;
        if(!container) return;

        if(scrolledToBottom && !previousLoading && !nextEnd && !nextLoading && typeof onNext === "function"){
            onNext();
        }
    }, [scrolledToBottom, onNext, nextLoading, nextEnd, previousLoading]);

    useEffect(()=>{
        const time = setTimeout(()=>{
            handlePrevious();
        }, 200);

        return ()=>{
            clearTimeout(time);
        }
    }, [scrolledToTop]);

    useEffect(()=>{
        const time = setTimeout(()=>{
            handleNext();
        }, 200);

        return ()=>{
            clearTimeout(time);
        }
    }, [scrolledToBottom]);

    return <div
        style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto"
        }}
        {...props}
        ref={containerRef}
    >
        {previousLoading && loadingComponent}
        {children}
        {(nextLoading && !previousLoading) && loadingComponent}
    </div>
  },
);

export default StableInfiniteScroll;