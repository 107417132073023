import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.scss";
import { FlashOn } from "@mui/icons-material";
import { CrisisAlert } from "@mui/icons-material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import routerHistory from "routerHistory";
import { MoreVert } from "@mui/icons-material";
import { APIHelper, InteractionHelper } from "Helper";
import { FinishedQuiz, UserStatus, RankTypes, AnswersBody } from "Types/Quiz";
import { Button, CircularProgress, Typography } from "@mui/material";
import { QuizHelper } from "Helper";
import { MainFrame, SvgIcon } from "Components";
import { mdiAccountDetails, mdiAlertRhombus, mdiArmFlex, mdiAutoMode, mdiFlash, mdiPiggyBank } from "@mdi/js";

const pointsToText = (points: number, maxPoints: number) => {
	const textStyle_0 = [
		"translate(199.63 183.19) rotate(29.15) scale(1.1 1)",
		"translate(205.93 186.71) rotate(24.7) scale(1.1 1)",
		"translate(212.45 189.71) rotate(20.3) scale(1.1 1)",
		"translate(219.21 192.22) rotate(15.96) scale(1.1 1)",
		"translate(226.1 194.2) rotate(11.69) scale(1.1 1)",
		"translate(233.14 195.67) rotate(7.48) scale(1.1 1)",
		"translate(240.23 196.61) rotate(3.33) scale(1.1 1)",
	];

	const textStyle_1 = [
		"translate(252.19 197.03) rotate(-3.33) scale(1.1 1)",
		"translate(259.34 196.6) rotate(-7.48) scale(1.1 1)",
		"translate(266.45 195.65) rotate(-11.69) scale(1.1 1)",
		"translate(273.47 194.19) rotate(-15.96) scale(1.1 1)",
		"translate(280.38 192.19) rotate(-20.3) scale(1.1 1)",
		"translate(287.13 189.68) rotate(-24.7) scale(1.1 1)",
		"translate(293.65 186.67) rotate(-29.15) scale(1.1 1)",
	];

	const p = Math.round(Math.min(maxPoints, Math.max(0, points))).toFixed(0);
	const mP = Math.round(maxPoints).toFixed(0);

	let result = [];

	p.split("")
		.reverse()
		.forEach((v, i) => {
			result.push(
				<text
					key={`profilePicture-insignia-path-${result.length}`}
					className="cls-3"
					transform={textStyle_0[textStyle_0.length - (i + 1)]}
				>
					{v}
				</text>,
			);
		});

	result.push(
		<text
			key={`profilePicture-insignia-path-${result.length}`}
			xmlns="http://www.w3.org/2000/svg"
			className="cls-3"
			transform="matrix(1.1, 0.01, -0.01, 1, 247.31, 196.98)"
		>
			/
		</text>,
	);

	mP.split("").forEach((v, i) => {
		result.push(
			<text
				key={`profilePicture-insignia-path-${result.length}`}
				className="cls-4"
				transform={textStyle_1[i]}
			>
				{v}
			</text>,
		);
	});

	return result;
};

const BarXP: React.FC<{
	beforeXP: number;
	currentXP: number;
	minXP: number;
	maxXP: number;
}> = ({ beforeXP, currentXP, minXP, maxXP }) => {
	beforeXP = Math.min(maxXP, Math.max(minXP, beforeXP));
	currentXP = Math.min(maxXP, Math.max(minXP, currentXP));
	const isNegative = beforeXP > currentXP;

	const start = Math.min(149.23, Math.max(0, 149.23 * ((beforeXP - minXP) / (maxXP - minXP)))) - 149.23;
	const end = Math.min(149.23, Math.max(1, 149.23 * ((currentXP - minXP) / (maxXP - minXP)))) - 149.23;

	return (
		<svg viewBox="150 130 200 80">
			<path d="M314.5,149a81,81,0,0,1-129,0" />
			<path
				d="M314.5,149a81,81,0,0,1-129,0"
				style={{
					strokeDashoffset: end,
					opacity: isNegative ? 0 : 1,
				}}
			/>
			<path
				d="M314.5,149a81,81,0,0,1-129,0"
				style={{
					strokeDashoffset: start,
				}}
			/>
			<path
				d="M314.5,149a81,81,0,0,1-129,0"
				style={{
					strokeDasharray: `${start - end} 149.23`,
					strokeDashoffset: start,
					opacity: isNegative ? 1 : 0,
				}}
			/>
			{pointsToText(currentXP, maxXP)}
		</svg>
	);
};

const conquests: Array<{
	color: string;
	title: string;
	icon: string;
	formatText: (status: FinishedQuiz, userStatus: UserStatus) => string;
}> = [
	{
		color: "#ffc800",
		title: "Porcentagem de acertos",
		icon: mdiAlertRhombus,
		formatText(status) {
			return `${Math.floor((status.correct / status.total) * 100)}%`;
		},
	},
	{
		color: "#93d333",
		title: "Experiência recebida",
		icon: mdiAutoMode,
		formatText(status) {
			return `${status.conquest} XP`;
		},
	},
	{
		color: "#ff9800",
		title: "Cofrinho",
		icon: mdiPiggyBank,
		formatText(status) {
			return `0 C`;
		},
	},
	{
		color: "#009688",
		title: "Ofensiva",
		icon: mdiArmFlex,
		formatText(status) {
			return `0 PTS`;
		},
	},
	{
		color: "#ec407a",
		title: "Habilidade",
		icon: mdiAccountDetails,
		formatText(status) {
			return `${Math.round(status.specialty * 100)}%`;
		},
	},
];

const QuizEnd: React.FC<{
	sectionId: string;
	unitId: string;
	levelId: string;
	answers: AnswersBody;
}> = ({ sectionId, unitId, levelId, answers }) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [userInfo, setUserInfo] = useState<UserStatus | null>(null);
	const [momentXp, setMomentXp] = useState<number>(0);
	const [momentMinXP, setMomentMinXP] = useState<number>(0);
	const [momentMaxXP, setMomentMaxXP] = useState<number>(150);

	const [isToBack, setIsToBack] = useState<boolean>(false);

	const { error, response, status } = APIHelper.useFetch<FinishedQuiz>({
		...QuizHelper.fetchOptions.finishQuiz(sectionId, unitId, levelId, answers),
		immediate: true,
	});

	const rootRef = useRef<HTMLDivElement>(null);
	const timeRef = useRef<NodeJS.Timeout>();

	const {
		type: afterTypeRank,
		minXP,
		maxXP,
		beforeRank,
	} = userInfo?.rank ?? {
		type: "bronze",
		minXP: 0,
		maxXP: 100,
		beforeRank: {
			type: "bronze",
			minXP: 0,
			maxXP: 100,
		},
	};

	const { xp: afterXP } = userInfo?.statistic ?? {
		xp: 0,
	};

	const loading = isLoading && status !== "success";
	//const beforeXP = afterXP - (response?.conquest ?? 0);
	const beforeXP = response?.previousXP ?? 0;

	const initAnimation = () => {
		clearTimeout(timeRef.current);
		rootRef.current?.classList.remove(style["stage-1"]);
		rootRef.current?.classList.remove(style["stage-2"]);
		rootRef.current?.classList.remove(style["stage-3"]);
		setMomentXp(beforeXP);

		const isNegative = beforeXP > afterXP;

		let min_xp = minXP,
			max_xp = maxXP,
			isBefore = false;

		if (beforeXP < minXP) {
			min_xp = beforeRank.minXP;
			max_xp = beforeRank.maxXP;
			isBefore = true;
		}

		setMomentMinXP(min_xp);
		setMomentMaxXP(max_xp);

		timeRef.current = setTimeout(() => {
			rootRef.current?.classList.add(isNegative ? style["stage-2"] : style["stage-1"]);
			let currentXP = 0,
				toXP = response?.conquest ?? 0;

			const loopCountXP = () => {
				if ((!isNegative && currentXP >= toXP) || (isNegative && currentXP <= toXP)) {
					clearTimeout(timeRef.current);
					timeRef.current = setTimeout(() => {
						rootRef.current?.classList.add(style["stage-3"]);

						timeRef.current = setTimeout(() => {
							setIsToBack(true);
						}, 1000);
					}, 300);
					return;
				}

				if (!isNegative && beforeXP + currentXP > max_xp && isBefore) {
					setMomentMinXP(minXP);
					setMomentMaxXP(maxXP);
					rootRef.current?.classList.add(style["stage-2"]);
				} else if (isNegative && beforeXP + currentXP < min_xp && !isBefore) {
					setMomentMinXP(beforeRank.minXP);
					setMomentMaxXP(beforeRank.maxXP);
					rootRef.current?.classList.remove(style["stage-2"]);
					rootRef.current?.classList.add(style["stage-1"]);
				}

				currentXP += 1 * (isNegative ? -1 : 1);
				setMomentXp((p) => p + 1 * (isNegative ? -1 : 1));
				timeRef.current = setTimeout(loopCountXP, 120);
			};

			timeRef.current = setTimeout(loopCountXP, 300);
		}, 100);
	};

	useEffect(() => {
		clearTimeout(timeRef.current);
		if (isLoading && status !== "success") {
			return;
		}
		const time = setTimeout(() => {
			initAnimation();
		}, 2000);
		return () => clearTimeout(time);
	}, [isLoading, status, response, userInfo]);

	useEffect(() => {
		const time = setTimeout(() => {
			QuizHelper.updateDataQuiz(sectionId).then(([userInfo]) => {
				setUserInfo(userInfo);
				setIsLoading(false);
			});
		}, 2000);
		return () => clearTimeout(time);
	}, []);

	useEffect(() => {
		const time = setTimeout(() => {
			if (status === "error" && error) {
				InteractionHelper.toast(error.message, null, "error");
				InteractionHelper.route.goBack();
			}
		}, 2000);
		return () => clearTimeout(time);
	}, [error, status]);

	useEffect(() => {
		MainFrame.Controller.hiddenMenuSummary();
		return () => {
			MainFrame.Controller.showMenuSummary();
		};
	}, []);

	const handleBack = () => {
		// initAnimation();
		// return;
		if (!isToBack) {
			return;
		}
		InteractionHelper.route.goBack();
	};

	return (
		<div
			className={style["root"]}
			ref={rootRef}
			onClick={handleBack}
		>
			<CircularProgress color="inherit" />
			{!loading && (
				<>
					<div className={style["emblem"]}>
						<div
							className={style["before-emblem-img"]}
							style={{
								backgroundImage: `url(${QuizHelper.insigniasImage[beforeRank.type]})`,
							}}
						></div>
						<div
							className={style["after-emblem-img"]}
							style={{
								backgroundImage: `url(${QuizHelper.insigniasImage[afterTypeRank]})`,
							}}
						></div>
						<div className={style["bar-xp"]}>
							<BarXP
								beforeXP={beforeXP}
								currentXP={momentXp}
								minXP={momentMinXP}
								maxXP={momentMaxXP}
							/>
						</div>
					</div>
					<div className={style["achievements-items"]}>
						<div className={style["division"]}>
							<div></div>
							<div>Recompensas</div>
							<div></div>
						</div>
						<div className={style["content"]}>
							{conquests.map(({ color, icon, title, formatText }, i) => {
								return (
									<div
										key={i}
										style={{ "--color": color } as React.CSSProperties & { [p: string]: string }}
									>
										<p>{title}</p>
										<div className={style["content"]}>
											<SvgIcon path={icon} />
											<p>{response && userInfo ? formatText(response, userInfo) : ""}</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<Typography
						variant="caption"
						component="div"
					>
						CLICK PARA CONTINUAR
					</Typography>
				</>
			)}
		</div>
	);
};

export default QuizEnd;
