import React, { useEffect, useState, useRef } from "react";

import { CircularProgress, TableContainer, Paper, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";

import { Chart, Icons } from "Components";

import { currencyFormat } from "Utils";
import { Wallet } from "Models";

import style from "./style.module.scss";

const ViewCoins: React.FC<
	Partial<{
		[p: string]: any;
		wallet: Wallet | null;
		activeEye: boolean;
	}>
> = ({ wallet, activeEye = false, ...props }) => {
	if (!wallet) {
		return (
			<div className={style.viewCoins}>
				<CircularProgress color="inherit" />
			</div>
		);
	}

	const balances: {
		symbol: string;
		value: number;
		available: string;
		name?: string;
	}[] = Object.values(wallet.information["balances"] ?? {});

	const data = balances.map(({ symbol, value }, i) => {
		value = parseFloat((value && typeof value === "number" ? value : 0).toFixed(3));
		return [symbol, value];
	});

	return (
		<div
			className={style.viewCoins}
			{...props}
		>
			<Chart
				style={{ marginTop: 5, marginBottom: 15 }}
				height={350}
				chartType={"PieChart"}
				data={[["Coin", "Quantity"], ...data]}
				options={{
					direction: "horizontal",
					chartBackground: false,
					indexAlign: "center",
				}}
				width={undefined}
			/>
			<TableContainer component={Paper}>
				<Table aria-label="customized table">
					{balances.length <= 0 && (
						<caption
							style={{
								textAlign: "center",
							}}
						>
							Não dados ainda para serrem exibidos!
						</caption>
					)}
					<TableHead>
						<TableRow>
							<TableCell size="small">Moeda</TableCell>
							<TableCell
								size="small"
								align="left"
							>
								Quantidade
							</TableCell>
							<TableCell
								size="small"
								align="left"
							>
								Valor
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{balances.map(({ symbol, name, available, value }, i) => {
							value = parseFloat((value && typeof value === "number" ? value : 0).toFixed(2));
							return (
								<TableRow key={`MyCoins-table-${i}`}>
									<TableCell
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<Icons
											name={`indicador-${(/^S\#([0-9]{2,})\:/gi.test(symbol) ? "stake" : symbol).toLowerCase()}`}
											style={{ marginRight: 15 }}
										/>{" "}
										{name || symbol}
									</TableCell>
									<TableCell align="left">{activeEye ? "***,**" : parseFloat(available)}</TableCell>
									<TableCell align="left">
										{activeEye
											? currencyFormat.secretCurrencyValue(wallet.currencyType)
											: currencyFormat.convert(value, wallet.currencyType)}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default ViewCoins;
