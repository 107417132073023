import React, { useState, useCallback, useEffect } from "react";

import style from './index.module.scss';

import { InputBase, IconButton, CircularProgress, Box } from '@mui/material';

import { mdiArrowLeft, mdiMagnify } from '@mdi/js';

import { SvgIcon } from "Components";

import { APIHelper } from "Helper";
import { useDebounceCallbackEffect } from "Utils/useHook";

export default ({ toBack, onSelect })=>{
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState([]);

    const goBack = useCallback(()=>{
        if(typeof toBack === "function"){
            toBack();
        }
    }, [toBack]);

    const gotTo = useCallback((info)=>{
        if(typeof onSelect === "function"){
            onSelect(info);
        }

        //goBack();
    }, [onSelect, goBack]);

    useEffect(()=>{
        setLoading(inputValue.trim() !== "");
    }, [inputValue]);

    const search = useCallback(()=>{
        if(inputValue.trim() === ""){
            setLoading(false);
            return;
        }

        setLoading(true);

        APIHelper.fetch("/chat/search", {by: inputValue}).then((list)=>{
            setResult(()=> list);
        }).finally(()=>{
            setLoading(false);
        });
    }, [inputValue]);

    useDebounceCallbackEffect(()=>{
        search();
    }, 2000, [search]);

    return <>
        <div className={style["header-search-user"]}>
            <IconButton sx={{ m: '2px', p: '10px' }} onClick={goBack}>
                <SvgIcon path={mdiArrowLeft}/>
            </IconButton>
            <InputBase 
                type="text" fullWidth
                sx={{ m: '0px', p: '10px' }} 
                inputProps={{
                    style: {
                        margin: "0px",
                        padding: "0px"
                    }
                }}
                onChange={({target})=> setInputValue(target.value)}
                placeholder="Procurar usuário..."
            />
            <IconButton sx={{ m: '2px', p: '10px' }} onClick={search}>
                <SvgIcon path={mdiMagnify}/>
            </IconButton>
        </div>
        {loading && <Box className={style["loading"]} sx={{ display: 'flex' }}>
            <CircularProgress />
        </Box>}
        {(!loading && result.length <= 0) && <div className={style["info"]}>
            {inputValue.trim() === "" ? "Digite o arroba (@), nome de usuário, e-mail ou nome de grupo para realizar a pesquisa" : "Desculpe, mas não encontramos nenhum usuário ou grupo com o nome informado"}
        </div>}
        {result.map((info, index)=>{
            const {name, picture, type} = info;

            return <div className={[style["item"], style[type]].join(" ")} key={index} onClick={()=>{
                gotTo(info);
            }}>
                <div className={style["picture"]} style={{backgroundImage: `url(${picture})`}}></div>
                <div className={style["preview"]}>
                    <div className={style["title"]}>
                        <div className={style["label"]}>{name}</div>
                    </div>
                </div>
            </div>
        })}
    </>
}