/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useObserveObjectChange } from "Utils/useHook";
import { currencyFormat, datediff } from "Utils";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import { Typography, Checkbox, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import styles from "./style.module.scss";

const SelectInstallmentsToPay = ({ outsideProps, onChange }) => {
	const [byProps, setOnChange] = useObserveObjectChange(outsideProps);
	const [onlyInstallments, setOnlyInstallments] = useState({});
	const [expanded, setExpanded] = useState();

	const handleExpandedChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : null);
	};

	const handleOnlyInstallment =
		(invoice, installment) =>
		({ target: { checked } }) => {
			if (!installment) {
				byProps.onlyInstallments[invoice] = checked ? byProps.response.installment[invoice].map(({ id }) => id) : [];
			} else if (checked) {
				byProps.onlyInstallments[invoice].push(installment);
			} else {
				byProps.onlyInstallments[invoice] = byProps.onlyInstallments[invoice].filter((id) => id !== installment);
			}

			setOnlyInstallments(() => Object.assign({}, byProps.onlyInstallments));
		};

	useEffect(() => {
		setOnChange(() => onChange);
	}, [onChange]);

	useLayoutEffect(() => {
		setOnlyInstallments(() => byProps.onlyInstallments);
	}, [byProps.onlyInstallments]);

	const entriesInvoices = Object.entries(byProps.response.installment);

	const dateInvoices = entriesInvoices
		.map(([invoice]) => {
			const year = parseInt(invoice.substring(0, 4));
			const month = parseInt(invoice.substring(4, 6)) - 1;
			return new Date(year, month, 15, 0, 0, 0);
		})
		.sort((a, b) => a - b);

	const totalAmount = entriesInvoices
		.map(([invoice, installments]) => {
			return installments.reduce((amount, { id, installment_amount }) => {
				const checked = Array.isArray(onlyInstallments[invoice]) && onlyInstallments[invoice].includes(id);
				return amount + (checked ? installment_amount : 0);
			}, 0);
		})
		.reduce((a, b) => a + b, 0);

	const dateNow = new Date();

	return (
		<div className={styles["main"]}>
			<div className={styles["content"]}>
				{Object.entries(byProps.response.installment).map(([invoice, installments], index) => {
					const year = parseInt(invoice.substring(0, 4));
					const month = parseInt(invoice.substring(4, 6)) - 1;
					const due_date = new Date(year, month, 15, 0, 0, 0);
					const checked = Array.isArray(onlyInstallments[invoice]) && onlyInstallments[invoice].length === installments.length;
					const indeterminate = Array.isArray(onlyInstallments[invoice]) && !checked && onlyInstallments[invoice].length > 0;

					const amount = installments.reduce((amount, { id, original_amount, installments }) => {
						const checked = Array.isArray(onlyInstallments[invoice]) && onlyInstallments[invoice].includes(id);
						return amount + (checked ? original_amount / installments : 0);
					}, 0);

					const costs = installments.reduce((amount, { id, original_amount, installments, installment_amount }) => {
						const checked = Array.isArray(onlyInstallments[invoice]) && onlyInstallments[invoice].includes(id);
						return amount + (checked ? installment_amount - original_amount / installments : 0);
					}, 0);

					const renegotiationRate = 0;

					const lateInterest = 0;

					return (
						<Accordion
							disableGutters
							elevation={0}
							square
							expanded={expanded === invoice}
							onChange={handleExpandedChange(invoice)}
							key={index}
						>
							<AccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}>
								<Typography variant="h6">{due_date.toLocaleDateString()}</Typography>
								<Typography variant="subtitle1">{currencyFormat.convert(parseFloat((amount + costs).toFixed(2)), "BRL")}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell align="left">Data de contrato</TableCell>
											<TableCell align="center">Parcelas</TableCell>
											<TableCell align="right">Valor</TableCell>
											<TableCell align="right">
												<Checkbox
													onChange={handleOnlyInstallment(invoice)}
													checked={checked}
													indeterminate={indeterminate}
												/>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{installments.map(({ id, date_created, original_amount, installments, installments_paid }, index) => {
											const date = new Date(date_created);
											const checked = Array.isArray(onlyInstallments[invoice]) && onlyInstallments[invoice].includes(id);
											const amount = original_amount / installments;

											return (
												<TableRow
													key={`installment-${index}`}
													sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
												>
													<TableCell align="left">{date.toLocaleDateString()}</TableCell>
													<TableCell align="center">{`${(installments_paid || 0) + 1}/${installments}`}</TableCell>
													<TableCell align="right">
														{currencyFormat.convert(parseFloat(amount.toFixed(2)), "BRL")}
													</TableCell>
													<TableCell align="right">
														<Checkbox
															checked={checked}
															onChange={handleOnlyInstallment(invoice, id)}
														/>
													</TableCell>
												</TableRow>
											);
										})}

										<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
											<TableCell
												align="right"
												colSpan={2}
											>
												Custos de contratação de empréstimo:{" "}
											</TableCell>
											<TableCell align="right">{currencyFormat.convert(parseFloat(costs.toFixed(2)), "BRL")}</TableCell>
											<TableCell align="right">
												<Checkbox
													checked={true}
													disabled
												/>
											</TableCell>
										</TableRow>

										<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
											<TableCell
												align="right"
												colSpan={2}
											>
												Taxa de renegociação:{" "}
											</TableCell>
											<TableCell align="right">
												{currencyFormat.convert(parseFloat(renegotiationRate.toFixed(2)), "BRL")}
											</TableCell>
											<TableCell align="right">
												<Checkbox
													checked={true}
													disabled
												/>
											</TableCell>
										</TableRow>

										<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
											<TableCell
												align="right"
												colSpan={2}
											>
												Juros de atraso:{" "}
											</TableCell>
											<TableCell align="right">{currencyFormat.convert(parseFloat(lateInterest.toFixed(2)), "BRL")}</TableCell>
											<TableCell align="right">
												<Checkbox
													checked={true}
													disabled
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</AccordionDetails>
						</Accordion>
					);
				})}

				{datediff(dateInvoices[0], dateNow) > 0 && (
					<div className={dateInvoices[0] < dateNow ? styles["description-warning"] : styles["description-alert"]}>
						{dateInvoices[0] < dateNow
							? `As faturas estão atrasadas há ${datediff(
									dateInvoices[0],
									dateNow,
							  )} dias. Por favor, tente mantê-las em dia para evitar quaisquer transtornos.`
							: `Suas faturas vencerão em ${datediff(
									dateInvoices[0],
									dateNow,
							  )} dias. Pedimos que mantenha o pagamento em dia para evitar transtornos futuros.`}
					</div>
				)}

				<div className={styles["description"]}>
					Ao clicar em "Confirmar", você estará efetuando o pagamento da fatura no valor indicado e estará ciente de todas as informações
					apresentadas. Por favor, verifique atentamente todos os valores antes de prosseguir com a confirmação. Se você identificar algum
					valor suspeito ou equivocado, entre em contato imediatamente com o nosso suporte. Importante ressaltar que a IVIPCOIN não se
					responsabilizará por eventuais equívocos do usuário, a menos que a cobrança seja feita duplicadamente.
				</div>
			</div>
			<div className={styles["footer"]}>
				<Typography variant="subtitle1">Valor total a pagar:</Typography>
				<Typography variant="h6">{currencyFormat.convert(parseFloat(totalAmount.toFixed(2)), "BRL")}</Typography>
			</div>
		</div>
	);
};

export default SelectInstallmentsToPay;
