import React from "react";
import style from "./style.module.scss";
import { Icons, SvgIcon } from "Components";
import { InputBase, Typography } from "@mui/material";
import { mdiArrowRight } from "@mdi/js";
import { currencyFormat } from "Utils";

const ConfirmOrder: React.FC<{
	symbol: string[];
	quantity: number;
	amount: number;
	type: "BUY" | "SELL";
}> = ({ symbol, quantity, amount, type }) => {
	const fromIsCurrencySystem = currencyFormat.indexOf(String(symbol[0]).toUpperCase()) >= 0;

	const renderValueBalanceAvailableFrom = currencyFormat.convert(quantity, !fromIsCurrencySystem ? "USD" : String(symbol[0]).toUpperCase(), 8);

	return (
		<div className={style["root"]}>
			<div
				className={style["values-swap"]}
				style={{
					flexDirection: type === "BUY" ? "column-reverse" : "column",
				}}
			>
				<div className={style["input-base"]}>
					<Icons
						className={style["input-symbol"]}
						name={String(symbol[0]).toUpperCase()}
						style={{ marginRight: 15 }}
					/>
					<div className={style["input-base-by"]}>
						<InputBase
							type="number"
							fullWidth
							sx={{ ml: 1, flex: 1 }}
							placeholder="0,00"
							value={quantity}
							readOnly
						/>
					</div>
					<Typography
						className={style["balance"]}
						variant="caption"
						component="p"
					>
						Saldo: {`0,00 ${symbol[0]}`}
					</Typography>
				</div>

				<div className={style["input-arrow-icon"]}>
					<SvgIcon path={mdiArrowRight} />
				</div>

				<div className={style["input-base"]}>
					<Icons
						className={style["input-symbol"]}
						name={String(symbol[1]).toUpperCase()}
						style={{ marginRight: 15 }}
					/>
					<div className={style["input-base-by"]}>
						<InputBase
							type="number"
							fullWidth
							sx={{ ml: 1, flex: 1 }}
							placeholder="0,00"
							value={amount}
							readOnly
						/>
					</div>
					<Typography
						className={style["balance"]}
						variant="caption"
						component="p"
					>
						Saldo: {`0,00 ${symbol[1]}`}
					</Typography>
				</div>
			</div>

			<Typography
				variant="subtitle2"
				component="p"
			>
				{`Ao confirmar, você estará realizando uma ${type === "BUY" ? "compra" : "venda"} de ${
					!fromIsCurrencySystem
						? `${renderValueBalanceAvailableFrom?.toString().split(/\s/gi).pop()} ${symbol[0]}`
						: renderValueBalanceAvailableFrom
				} com a sua carteira conectada a Binance seguindo suas restrições. Deseja mesmo prosseguir?`}
			</Typography>
		</div>
	);
};

export default ConfirmOrder;
