import React, { useEffect, useState } from "react";

import { Typography, TextField, FormControl, InputLabel, Select, MenuItem, Slider, FormGroup, FormControlLabel, Checkbox } from "@mui/material";

import style from "../utils/style.module.scss";

import { currencyFormat, list_payment_methods } from "Utils";

import { calculateCosts } from "Components/SimulateAmountCosts";
import { InputAmount, InstallmentSelect, SelectCurrency, SimulateAmountCosts } from "Components";
import { PhaseProps } from "Dialog/utils";
import { DepositPaymentDialoggProps } from ".";
import { ListPaymentMethodsIten, PaymentMethodId } from "Types/ListPaymentMethods";

interface PaymentProps {
	[key: string]: any;
	type: PaymentMethodId;
	amount: number;
	amountWithCosts: number;
	list_payment_methods: Array<{ [key: string]: any }>;
	installments: number;
	wallet: string;
	typeAmount: string;
	response: { [key: string]: any };
}

// : React.FC<PhaseProps<WithdrawDialogProps>>

const SimulateCosts: React.FC<PhaseProps<DepositPaymentDialoggProps>> = ({ state }) => {
	const [paymentType, setPaymentType] = useState(state.type);

	const [amount, setAmount] = useState<number>(typeof state.amount === "number" ? state.amount : 0);

	const [installments, setInstallments] = useState(1);
	const [id_1] = useState(`simulate-costs-${Math.round(Math.random() * 10000)}`);
	const [typeAmount, setTypeAmount] = useState("");

	const [currencyId, setCurrencyId] = useState<string>(
		typeof state.currency_id === "string" && state.currency_id.trim() !== "" ? state.currency_id : "BRL",
	);

	const [automatic_conversion, set_automatic_conversion] = useState<boolean>(state.automatic_conversion);

	const handleChangeTypeAmount = ({ target: { value } }: any) => {
		state.typeAmount = value ?? "";
		setTypeAmount(value);
	};

	const getFindPayment = (): Pick<
		ListPaymentMethodsIten,
		"min_allowed_amount" | "max_allowed_amount" | "payer_costs" | "types_installments" | "currency_id"
	> => {
		const {
			min_allowed_amount = 0,
			max_allowed_amount = 100000,
			payer_costs = [],
			types_installments,
			currency_id = "BRL",
		} = state.list_payment_methods?.find(({ id, payment_type_id }) => {
			const now = list_payment_methods.find(({ value }) => value === paymentType);
			return now && id === now.value && now.payment_id === payment_type_id;
		}) ?? {};

		return { min_allowed_amount, max_allowed_amount, payer_costs, types_installments, currency_id };
	};

	useEffect(() => {
		const payment = getFindPayment();

		state.type = paymentType;
		if (paymentType !== "visa") {
			setInstallments(1);
		}

		if (Array.isArray(payment.types_installments) && payment.types_installments.length) {
			state.typeAmount = payment.types_installments[0].type;
		} else {
			state.typeAmount = "";
		}

		setTypeAmount(state.typeAmount ?? "");

		state.currency_id = typeof payment.currency_id === "string" && payment.currency_id.trim() !== "" ? payment.currency_id.toUpperCase() : "BRL";

		setCurrencyId(state.currency_id);
	}, [paymentType]);

	useEffect(() => {
		state.installments = installments;
	}, [installments]);

	const change = (value: number) => {
		value = parseFloat(value as any) ?? 0;
		value = isNaN(value) ? 0 : value;
		setAmount(value);
		state.amount = value;
	};

	//let amountValue = invertAmount(amount ?? "0");

	let totalAmount = 0,
		newTotalAmount = 0,
		costs;

	const payment = getFindPayment();

	if (payment != null) {
		totalAmount = Math.min(Math.max(payment.min_allowed_amount, amount), payment.max_allowed_amount);

		if (Array.isArray(payment.payer_costs) && payment.payer_costs.length) {
			if (Array.isArray(payment.types_installments) && payment.types_installments.length) {
				costs = payment.payer_costs.filter(({ type }) => type === typeAmount)[installments - 1];
			} else {
				costs = payment.payer_costs[installments - 1];
			}
		}

		state.amountWithCosts = newTotalAmount = calculateCosts(totalAmount, costs);
	}

	const payment_info = state.list_payment_methods?.find(({ id }) => id === paymentType);
	const description = list_payment_methods.find(({ value }) => value === paymentType)?.description || null;

	const valid_amountValue = payment_info?.max_allowed_amount_including_costs ? newTotalAmount : amount;

	const inputError = payment.max_allowed_amount <= 0 || valid_amountValue > payment.max_allowed_amount || amount < payment.min_allowed_amount;

	return (
		<div className={style["dialogPayment"]}>
			{!state.hidenOptions && (
				<div className={style["paymentMethods"]}>
					{list_payment_methods.map(({ icon, label, value, disabled }, i) => {
						if (disabled === true) {
							return;
						}

						const isActive = value === paymentType;

						return (
							<div
								className={isActive ? style["paymentMethods-active"] : ""}
								onClick={() => {
									setPaymentType(value as PaymentMethodId);
								}}
								key={i}
							>
								<div className={style["paymentMethods-logo"]}>{icon()}</div>
								<span className={style["paymentMethods-label"]}>{label}</span>
							</div>
						);
					})}
				</div>
			)}

			{paymentType === "cryptocurrency" && (
				<div className={style["description"]}>
					Ao prosseguir com esta operação, um endereço BEP-20 temporário será gerado para receber seus tokens IVIP. Após a transferência,
					você poderá visualizá-los na plataforma. É importante ressaltar que apenas os tokens IVIP devem ser enviados para este endereço
					BEP-20. Enviar qualquer outro valor resultará na impossibilidade de reversão.
				</div>
			)}

			{paymentType !== "cryptocurrency" && (
				<>
					<InputAmount
						value={amount}
						currency={currencyId}
						onChange={(value) => change(value)}
						min={payment.min_allowed_amount}
						max={payment.max_allowed_amount}
					/>

					{/* {paymentType === "cryptocurrency" && <SelectCurrency />} */}

					{paymentType !== "emprestimo" && (
						<div className={style["payment-limit-view"]}>
							{`Você pode depositar um valor máximo de ${currencyFormat.convert(
								payment.max_allowed_amount,
							)} ${currencyId} e o valor mínimo de ${currencyFormat.convert(payment.min_allowed_amount)} ${currencyId}.`}
						</div>
					)}

					{state.currency_id !== "IVIP" && paymentType !== "emprestimo" && (
						<FormGroup
							sx={{
								padding: "0px 0px 20px 0px",
							}}
						>
							<FormControlLabel
								sx={{
									alignItems: "flex-start",
								}}
								control={
									<Checkbox
										checked={automatic_conversion}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
											state.automatic_conversion = event.target.checked;
											set_automatic_conversion(event.target.checked);
										}}
									/>
								}
								label={
									<>
										<Typography
											variant="subtitle1"
											sx={{ margin: "7px 0px 0px" }}
										>
											Converter para IVIP automáticamente
										</Typography>
										<Typography
											variant="caption"
											display="block"
											sx={{
												opacity: 0.8,
											}}
										>
											Importante: ao selecionar esta opção, é crucial estar ciente de que, uma vez concluída a operação, a
											conversão será efetuada automaticamente e não poderá ser revertida.
										</Typography>
									</>
								}
							/>
						</FormGroup>
					)}

					{paymentType === "emprestimo" && (
						<div
							className={[
								style["payment-limit-view"],
								(payment_info?.max_allowed_amount ?? 0) > 0 ? style["payment-limit-view-green"] : style["payment-limit-view-warns"],
							].join(" ")}
						>
							{(payment_info?.max_allowed_amount ?? 0) > 0
								? `Seu limite disponível é de ${currencyFormat.convert(payment.max_allowed_amount)} ${currencyId}`
								: "Atualmente, não há limite disponível em sua conta. No entanto, nossa equipe financeira está constantemente reavaliando e verificando a disponibilidade de limites, portanto, fique tranquilo."}
						</div>
					)}

					{payment_info && payment_info.released !== true && (
						<div className={style["payment-unavailable"]}>No momento esse meio de pagamento está indisponível</div>
					)}

					{Array.isArray(payment.payer_costs) && payment.payer_costs.length > 1 && (
						<InstallmentSelect
							defaultInstallment={
								Array.isArray(payment.payer_costs) && payment.payer_costs.length ? payment.payer_costs[0].installments - 1 : 0
							}
							installments={
								(Array.isArray(payment.payer_costs) && payment.payer_costs.length
									? payment.payer_costs.filter(({ type }) => type === typeAmount)
									: payment.payer_costs
								).length
							}
							onChange={(installment: number) => {
								if (Array.isArray(payment.types_installments) && payment.types_installments.length) {
									handleChangeTypeAmount({
										target: {
											value: payment.types_installments[0].type,
										},
									});
								}
								setInstallments(installment);
							}}
						/>
					)}

					{Array.isArray(payment.payer_costs) &&
						payment.payer_costs.length > 1 &&
						Array.isArray(payment.types_installments) &&
						payment.types_installments.length &&
						installments > 1 && (
							<FormControl
								size="small"
								fullWidth
							>
								<InputLabel id={id_1}>Tipo de Vencimento</InputLabel>
								<Select
									id={id_1}
									value={typeAmount}
									label="Tipo de Vencimento"
									onChange={handleChangeTypeAmount}
								>
									{payment.types_installments.map(({ type, name }, index) => {
										return (
											<MenuItem
												value={type}
												key={index}
											>
												{name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						)}

					{typeof description === "string" && description.trim() !== "" && <div className={style["description"]}>{description}</div>}

					<SimulateAmountCosts
						amount={totalAmount}
						currencyId={currencyId}
						payment={payment}
						costs={costs}
					/>
				</>
			)}
		</div>
	);
};

export default SimulateCosts;
