import React from "react";
import { applyForwardRef, applyStyledProps, useCanvas, usePropsImperativeHandle } from "../Utils";
import { CANVASEllipseElement } from "../Elements";

export const Ellipse = applyForwardRef<Partial<CANVASEllipseElement>, CANVASEllipseElement>((p, ref) => {
	const [props, setProps] = usePropsImperativeHandle<CANVASEllipseElement>(ref, p, {
		x: 0,
		y: 0,
		radiusX: 50,
		radiusY: 30,
		rotation: 0,
		startAngle: 0,
		endAngle: 2 * Math.PI,
		counterclockwise: false,
	});

	useCanvas(
		({ canvas }) => {
			const ctx = canvas?.getContext("2d");
			const { x, y, radiusX, radiusY, rotation, startAngle, endAngle, counterclockwise } = props;

			if (ctx) {
				ctx.save();
				applyStyledProps(ctx, props, (ctx) => {
					ctx.beginPath();
					ctx.ellipse(x, y, radiusX, radiusY, rotation, startAngle, endAngle, counterclockwise);
					ctx.fill();
					ctx.stroke();
					ctx.closePath();
				});
				ctx.restore();
			}
		},
		[props],
	);

	return null;
});
