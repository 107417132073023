import Tabs from "../../Components/Tabs";
import Typography from "@mui/material/Typography";
import "./index.css";
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const rows = [
    createData("", 1000000000000, "BABYDOGE"),
    createData("", 10000000,"12,32%"),
    createData("", "0.25135567", "BTC"),
    createData("", "R$52.238,25", "12,59%"),


];
export default {
    id: "Tela4",
    title: "Tela 4",
    render: () => {
        return (
                <div className="mover-griD-from-scren-four">
                    <div className="mY-cex-todos-dex">
                        <Tabs></Tabs>
                    </div>
                    <Typography
                        classes={{ root: "MuiTypography-root-325345" }}
                        variant="h6"
                        gutterBottom
                        component="div"
                        sx={{ textAlign: "center", top: 20 }}
                    >
                        Lista de ativos - Todas as Exchanges e carteiras
                    </Typography>

                    <TableContainer component={Paper}   sx={{  top: 40,height:213 }}   classes={{ root: ".MuiTableContainer-root-25345" }}>
                        <Table sx={{ minWidth: 650,height:220 }} aria-label="simple table">
                            <TableHead>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.calories}</TableCell>
                                        <TableCell align="right">{row.fat}</TableCell>
                                        <TableCell align="right">{row.carbs}</TableCell>
                                        <TableCell align="right">{row.protein}</TableCell>
                                    </TableRow>
                                ))} <div>
                                        <Avatar alt="Remy Sharp" src="src/image/meta-mask.png" sx={{ width: 60, height: 20,top: -195,left:4}}/>
                                        <Avatar alt="Remy Sharp"src="src/image/icone-baby-doger.png" sx={{ width: 35, height: 35,top: -170, left:15}}/>
                                    </div>
                                    <div>
                                        <Avatar alt="Remy Sharp"src="binance-icone.png"sx={{ width: 25, height: 25,top: -150,left:20 }}/>
                                        <Avatar alt="Remy Sharp" src="bitcoin-moeda-removebg-preview.png" sx={{ width: 32, height: 32,top: -120, left:18 }}/>
                                    </div>
                                  <div>
                                 <div>
                                    <Button variant="text" sx={{  top: -315, left:145,width:150 }}>METAMASK</Button >
                                    <Button variant="text" sx={{  top: -265,right:5, width:150 }}>BABYDOGE</Button >
                                 </div>
                                 <div>
                                    <Button variant="text" sx={{ top: -246,  left:145,width:150 }}>BINANCE</Button >
                                    <Button variant="text" sx={{  top: -193,right:5, width:150 }}>BITCOIN</Button >
                                  
                                 </div>
                                
                                 
                                   
                                </div>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
        );
    },
};
