import React, { useCallback, useEffect, useRef, useState } from "react";
import { applyForwardRef, applyStyledProps, useCanvas, useCanvasContext, usePropsImperativeHandle } from "../Utils";
import { CANVASTextureMapElement, MapFaceVertices, MapFaces, MapVertice } from "../Elements";

const interpolate = <O extends Object>(a: O, b: O): ((t: number) => O) => {
	let i: any = {},
		c: O = { ...a },
		k: any;

	if (a === null || typeof a !== "object") a = {} as O;
	if (b === null || typeof b !== "object") b = {} as O;

	function value(a: number, b: number) {
		return (
			(a = +a),
			(b = +b),
			function (t: number) {
				return a * (1 - t) + b * t;
			}
		);
	}

	for (k in b) {
		if (k in a && typeof (a as any)[k] === "number" && typeof (b as any)[k] === "number") {
			i[k] = value((a as any)[k], (b as any)[k]);
		} else {
			(c as any)[k] = (b as any)[k];
		}
	}

	return function (t: number) {
		for (k in i) (c as any)[k] = i[k](t);
		return c;
	};
};

const fillQuadTex = (
	ctx: CanvasRenderingContext2D,
	vertices: MapFaceVertices,
	opts: {
		tiles?: number;
		seamOverlap?: number;
		method?: "perspective" | "bilinear";
		offset?: { x: number; y: number };
	} & (
		| {
				texture: HTMLImageElement | HTMLCanvasElement | HTMLVideoElement;
				width?: number;
				height?: number;
		  }
		| {
				texture: CanvasPattern | null;
				width: number;
				height: number;
		  }
	),
) => {
	if (!opts.texture) {
		return;
	}
	const pattern =
		opts.texture.toString().search("CanvasPattern") > 0 ? (opts.texture as CanvasPattern) : ctx.createPattern(opts.texture as any, "no-repeat");

	if (!pattern) {
		return;
	}
	const { x: offsetX, y: offsetY } = opts.offset ?? { x: 0, y: 0 };
	const tiles = opts.tiles ?? 10;
	const normalizeVertices = vertices.length === 3 ? toQuadrangle(...vertices) : vertices;
	const dst = normalizeVertices.map(({ x, y }) => ({ x: x + offsetX, y: y + offsetY })),
		src = normalizeVertices.map(({ u, v }) => ({
			x: (opts.width ?? (opts.texture as any)?.width ?? 1) * u,
			y: (opts.height ?? (opts.texture as any)?.height ?? 1) * v,
		}));
	const method = opts.method ?? "perspective";

	const lerpQuad = (q: Array<{ x: number; y: number }>) => {
		const p01 = interpolate(q[0], q[1]);
		const p32 = interpolate(q[3], q[2]);
		return (s: number, t: number) => interpolate(p01(s), p32(s))(t);
	};
	const lerpSrc = lerpQuad(src);
	const lerpDst = lerpQuad(dst);

	const projectionSrc = forwardProjectionMatrixForPoints(src);
	const projectionDst = forwardProjectionMatrixForPoints(dst);

	const pad = (opts.seamOverlap ?? 1) * 0.1; // we add padding to remove tile seams

	// return the triangles to fill the cell at the given row/column
	const rowColTris = (r: number, c: number, lerp?: ReturnType<typeof lerpQuad>, projection?: number[][]) => {
		let p = (r: number, c: number): { x: number; y: number } => ({ x: c, y: r });
		if (lerp) p = (r, c) => lerp(c / tiles, r / tiles);
		if (projection) p = (r, c) => projectPoint({ x: c / tiles, y: r / tiles }, projection);
		return [
			[
				p(r - pad, c - pad * 2), // extra diagonal padding
				p(r - pad, c + 1 + pad),
				p(r + 1 + pad * 2, c + 1 + pad), // extra diagonal padding
			],
			[p(r + 1 + pad, c + 1 + pad), p(r + 1 + pad, c - pad), p(r - pad, c - pad)],
		];
	};

	// clip to erase the external padding
	ctx.save();
	ctx.imageSmoothingEnabled = false;
	ctx.fillStyle = pattern;
	ctx.globalCompositeOperation = "source-over";
	ctx.beginPath();
	for (let { x, y } of vertices) {
		ctx.lineTo(x, y);
	}
	ctx.closePath();
	ctx.clip();

	// draw triangles
	for (let r = 0; r < tiles; r++) {
		for (let c = 0; c < tiles; c++) {
			const [srcTop, srcBot] = method === "bilinear" ? rowColTris(r, c, lerpSrc) : rowColTris(r, c, undefined, projectionSrc);
			const [dstTop, dstBot] = method === "bilinear" ? rowColTris(r, c, lerpDst) : rowColTris(r, c, undefined, projectionDst);

			fillTriTex(ctx, srcTop, dstTop);
			fillTriTex(ctx, srcBot, dstBot);
		}
	}
	ctx.restore();
};

const fillTriTex = (
	ctx: CanvasRenderingContext2D,
	src: {
		x: number;
		y: number;
	}[],
	dst: {
		x: number;
		y: number;
	}[],
) => {
	//ctx.globalCompositeOperation = "source-atop";
	ctx.beginPath();
	for (let { x, y } of dst) {
		ctx.lineTo(x, y);
	}
	ctx.closePath();
	const [[x0, y0], [x1, y1], [x2, y2]] = dst.map(({ x, y }) => [x, y]);
	const [[u0, v0], [u1, v1], [u2, v2]] = src.map(({ x, y }) => [x, y]);
	fillTexPath(ctx, x0, y0, x1, y1, x2, y2, u0, v0, u1, v1, u2, v2);
};

const fillTexPath = (
	ctx: CanvasRenderingContext2D,
	x0: number,
	y0: number,
	x1: number,
	y1: number,
	x2: number,
	y2: number,
	u0: number,
	v0: number,
	u1: number,
	v1: number,
	u2: number,
	v2: number,
) => {
	let a: number, b: number, c: number, d: number, e: number, f: number, det: number, idet: number;

	x1 -= x0;
	y1 -= y0;
	x2 -= x0;
	y2 -= y0;

	u1 -= u0;
	v1 -= v0;
	u2 -= u0;
	v2 -= v0;

	det = u1 * v2 - u2 * v1;

	if (det === 0) return;

	idet = 1 / det;

	a = (v2 * x1 - v1 * x2) * idet;
	b = (v2 * y1 - v1 * y2) * idet;
	c = (u1 * x2 - u2 * x1) * idet;
	d = (u1 * y2 - u2 * y1) * idet;

	e = x0 - a * u0 - c * v0;
	f = y0 - b * u0 - d * v0;

	ctx.save();
	ctx.transform(a, b, c, d, e, f);
	ctx.fill();
	ctx.restore();
};

const forwardProjectionMatrixForPoints = (
	points: {
		x: number;
		y: number;
	}[],
): number[][] => {
	const determinant = (m: number[][]): number =>
		m.length == 1
			? m[0][0]
			: m.length == 2
			? m[0][0] * m[1][1] - m[0][1] * m[1][0]
			: m[0].reduce((r, e, i) => r + (-1) ** (i + 2) * e * determinant(m.slice(1).map((c) => c.filter((_, j) => i != j))), 0);

	const deltaX1 = points[1].x - points[2].x;
	const deltaX2 = points[3].x - points[2].x;
	const sumX = points[0].x - points[1].x + points[2].x - points[3].x;
	const deltaY1 = points[1].y - points[2].y;
	const deltaY2 = points[3].y - points[2].y;
	const sumY = points[0].y - points[1].y + points[2].y - points[3].y;
	const denominator = determinant([
		[deltaX1, deltaX2],
		[deltaY1, deltaY2],
	]);
	const g =
		determinant([
			[sumX, deltaX2],
			[sumY, deltaY2],
		]) / denominator;
	const h =
		determinant([
			[deltaX1, sumX],
			[deltaY1, sumY],
		]) / denominator;
	const a = points[1].x - points[0].x + g * points[1].x;
	const b = points[3].x - points[0].x + h * points[3].x;
	const c = points[0].x;
	const d = points[1].y - points[0].y + g * points[1].y;
	const e = points[3].y - points[0].y + h * points[3].y;
	const f = points[0].y;
	return [
		[a, b, c],
		[d, e, f],
		[g, h, 1],
	];
};

const projectPoint = (
	point: {
		x: number;
		y: number;
	},
	projectionMatrix: number[][],
) => {
	const MatrixProd = (A: number[][], B: number[][]): number[][] =>
		A.map((row, i) => B[0].map((_, j) => row.reduce((acc, _, n) => acc + A[i][n] * B[n][j], 0)));
	const pointMatrix = MatrixProd(projectionMatrix, [[point.x], [point.y], [1]]);
	return {
		x: pointMatrix[0][0] / pointMatrix[2][0],
		y: pointMatrix[1][0] / pointMatrix[2][0],
	};
};

const toQuadrangle = (a: MapVertice, b: MapVertice, c: MapVertice): [MapVertice, MapVertice, MapVertice, MapVertice] => {
	const p = (a: { x: number; y: number }, b: { x: number; y: number }, c: { x: number; y: number }) => {
		const vector1 = { x: b.x - a.x, y: b.y - a.y };
		const vector2 = { x: c.x - a.x, y: c.y - a.y };
		const crossProduct = vector1.x * vector2.y - vector1.y * vector2.x;

		if (crossProduct > 0) {
			const temp = b;
			b = c;
			c = temp;
		}

		const x = ((a.x + b.x) / 2 - c.x / 2) * 2 * (crossProduct > 0 ? 1 : -1);
		const y = ((a.y + b.y) / 2 - c.y / 2) * 2;
		return { x, y, crossProduct };
	};

	const { x, y, crossProduct } = p(a, b, c);
	const { x: u, y: v } = p({ x: a.u, y: a.v }, { x: b.u, y: b.v }, { x: c.u, y: c.v });

	return crossProduct > 0 ? [{ x, y, u, v }, a, b, c] : [a, b, { x, y, u, v }, c];
};

export const useTextureMap = (
	initialFaces?: MapFaces,
): {
	getFaces: () => MapFaces;
	seFaces: (faces: MapFaces) => void;
	canvas: HTMLCanvasElement;
	setSize: (width: number, height: number) => void;
	getSize: () => { width: number; height: number };
	getContext: () => CanvasRenderingContext2D | null;
	update: () => void;
} => {
	const facesRef = useRef<MapFaces>(initialFaces ?? []);
	const canvasRef = useRef<HTMLCanvasElement>(document.createElement("canvas"));
	const updateRef = useRef<() => void>();

	const setSize = (width: number, height: number) => {
		canvasRef.current.width = width;
		canvasRef.current.height = height;
	};

	const getSize = () => {
		return { width: canvasRef.current.width, height: canvasRef.current.height };
	};

	const getContext = () => {
		return canvasRef.current.getContext("2d");
	};

	const getFaces = () => facesRef.current ?? [];

	const seFaces = (faces: MapFaces) => {
		facesRef.current = faces;
		updateRef.current?.();
	};

	return {
		getFaces,
		seFaces,
		canvas: canvasRef.current,
		setSize,
		getSize,
		getContext,
		update: updateRef.current ?? (() => {}),
	};
};

export const TextureMap = applyForwardRef<CANVASTextureMapElement>((p: any, ref) => {
	const canvasTextureMap = useRef<HTMLCanvasElement>(document.createElement("canvas"));
	const [props, setProps] = usePropsImperativeHandle<CANVASTextureMapElement>(ref, p, {
		faces: [],
		texture: "",
		method: "perspective",
		tiles: 10,
		seamOverlap: 0.01,
		strokeWidth: 0,
	});

	const texture = React.useRef<{
		image: HTMLImageElement | HTMLCanvasElement | HTMLVideoElement;
		width: number;
		height: number;
	}>(null);

	const canvasContext = useCanvas(
		({ canvas }) => {
			const ctx = canvas?.getContext("2d");
			let { x = 0, y = 0, faces = [], textureMap, method, seamOverlap, tiles, stroke, strokeWidth = 0 } = props;
			faces = textureMap ? textureMap.getFaces() : faces ?? [];

			if (ctx) {
				ctx.save();
				applyStyledProps(ctx, props, (ctx) => {
					if (texture.current && texture.current.image && texture.current.image.width + texture.current.image.height > 0) {
						try {
							texture.current.width = texture.current.image.width;
							texture.current.height = texture.current.image.height;

							const maxX = Math.max(...faces.map((v) => Math.max(...v.map(({ x }) => x))));
							const maxY = Math.max(...faces.map((v) => Math.max(...v.map(({ y }) => y))));
							const minX = Math.min(...faces.map((v) => Math.min(...v.map(({ x }) => x))));
							const minY = Math.min(...faces.map((v) => Math.min(...v.map(({ y }) => y))));

							const zoom = 2.5;

							canvasTextureMap.current.width = (maxX - minX) * zoom;
							canvasTextureMap.current.height = (maxY - minY) * zoom;

							const ctx2 = canvasTextureMap.current.getContext("2d");

							if (!ctx2) return;

							ctx2.clearRect(0, 0, canvasTextureMap.current.width, canvasTextureMap.current.height);

							const pattern = ctx2.createPattern(texture.current.image, "no-repeat");

							for (let i = 0; i < faces.length; i++) {
								fillQuadTex(
									ctx2,
									faces[i].map(({ x, y, ...f }) => {
										return { x: (x - minX) * zoom, y: (y - minY) * zoom, ...f };
									}) as any,
									{
										texture: pattern,
										tiles: tiles,
										seamOverlap: seamOverlap,
										method: method,
										width: texture.current.width,
										height: texture.current.height,
									},
								);
							}

							ctx.drawImage(canvasTextureMap.current, x + minX, y + minY, maxX - minX, maxY - minY);
						} catch {}
					}

					if (strokeWidth > 0) {
						ctx.save();
						ctx.lineWidth = strokeWidth;
						ctx.strokeStyle = stroke ?? "#000";
						for (let i = 0; i < faces.length; i++) {
							const [a, b, c, d] = faces[i];

							ctx.beginPath();
							for (let { x, y } of [a, b, c]) {
								ctx.lineTo(x, y);
							}
							ctx.closePath();
							ctx.stroke();

							if (d) {
								ctx.beginPath();
								for (let { x, y } of [a, c, d]) {
									ctx.lineTo(x, y);
								}
								ctx.closePath();
								ctx.stroke();
							}
						}
						ctx.restore();
					}
				});
				ctx.restore();
			}
		},
		[props, texture.current, canvasTextureMap.current],
	);

	useEffect(() => {
		if (
			(typeof props.texture === "string" &&
				texture.current?.image instanceof HTMLImageElement &&
				props.texture === texture.current?.image.src) ||
			(typeof props.texture !== "string" && texture.current?.image === props.texture)
		) {
			return;
		}

		if (typeof props.texture === "string") {
			const img = new Image();
			img.src = props.texture;
			img.onload = () => {
				(texture as any).current = {
					image: img,
					width: img.width,
					height: img.height,
				};
			};
		} else if (
			props.texture instanceof HTMLImageElement ||
			props.texture instanceof HTMLCanvasElement ||
			props.texture instanceof HTMLVideoElement
		) {
			(texture as any).current = {
				image: props.texture,
				width: props.texture.width,
				height: props.texture.height,
			};
		}

		if (
			(typeof props.texture === "object" && "canvas" in props.texture && props.texture.canvas instanceof HTMLCanvasElement) ||
			(typeof props.textureMap === "object" && "canvas" in props.textureMap && props.textureMap.canvas instanceof HTMLCanvasElement)
		) {
			const canvas = (props.texture as ReturnType<typeof useTextureMap> | undefined)?.canvas ?? props.textureMap?.canvas;
			if (!canvas) {
				return;
			}

			(texture as any).current = {
				image: canvas,
				width: canvas.width,
				height: canvas.height,
			};

			if (props.texture && typeof canvasContext.updateCanvas === "function" && "update" in (props.texture as any))
				(props.texture as any).update = () => canvasContext.updateCanvas();

			if (props.textureMap && typeof canvasContext.updateCanvas === "function" && "update" in (props.textureMap as any))
				(props.textureMap as any).update = () => canvasContext.updateCanvas();
		}
	}, [props, canvasContext]);

	return null;
});
