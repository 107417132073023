import React from "react";
import { APIHelper, InteractionHelper } from "Helper";

import { SelectInstallmentsToPay } from "Components";
import { WalletCreditContract } from "Types/Wallet";
import { catchError } from "Types";

type payInstallmentType = "payable"|"overdue";

const payInstallment = (walletId:string, type:payInstallmentType  = "overdue", props: {[k:string]: any} = {}, stage:number = 0):Promise<void> => {
	return new Promise(async (resolve, reject) => {
		props = Object.assign(
			{
				onlyInstallments: {},
				response: {},
			},
			props,
		);

		InteractionHelper.close();

		type = String(type).toLowerCase() as payInstallmentType;
		type = ["payable", "overdue"].includes(type) ? type : "overdue";

		let title = "iVip Crédito";
		let body = <div></div>;

		let onConfirm = () => {
			payInstallment(walletId, type, props, stage + 1)
				.then(resolve)
				.catch(reject);
		};

		if (stage === 0) {
			InteractionHelper.loading();
			APIHelper.fetch(`wallet/credit/${walletId}/info/${type}`)
				.then((response: any) => {
					props.response = response;
					props.onlyInstallments = Object.fromEntries(
						Object.entries(response.installment as {[id:string]: WalletCreditContract[]}).map(([invoice, installments]) => {
							return [invoice, installments.map(({ id }) => id)];
						}),
					);
					payInstallment(walletId, type, props, 1).then(resolve).catch(reject);
				})
				.catch((e) => {
					const errorMsg =
						e && typeof e.message === "string" && e.message.trim() !== ""
							? e.message
							: "Algo deu errado, verifique sua conexão e tente novamente!";
					InteractionHelper.close();
					InteractionHelper.toast(errorMsg, null, "error");
					reject();
				});

			return;
		} else if (stage === 1) {
			body = (
				<SelectInstallmentsToPay
					outsideProps={props}
					onChange={(propsChanged: {[k:string]: any} = {}) => {
						props = Object.assign(props, propsChanged);
					}}
				/>
			);
		} else if (stage === 2) {
			InteractionHelper.loading();

			try {
				let onlyInstallments:string[] = [];

				for (let invoice in props.onlyInstallments) {
					onlyInstallments = onlyInstallments
						.concat(props.onlyInstallments[invoice].map((id:string|number) => `${invoice}/${id}`))
						.filter((v, i, l) => l.indexOf(v) === i);
				}

				await APIHelper.fetch(`wallet/credit/${walletId}/pay/${type}`, {
					onlyInstallments: onlyInstallments,
				});
			} catch (e) {
				const errorMsg =
					e && typeof (e as any).message === "string" && (e as any).message.trim() !== ""
						? (e as any).message
						: "Algo deu errado, verifique sua conexão e tente novamente!";
				InteractionHelper.toast(errorMsg, null, "error");
				payInstallment(walletId, type, props, 0).then(resolve).catch(reject);
				return;
			}

			InteractionHelper.close();
			InteractionHelper.toast("Pagamento realizado com sucesso", null, "success");
			return resolve();
		} else {
			InteractionHelper.close();
			return resolve();
		}

		InteractionHelper.confirm(body, title).then(onConfirm).catch(reject);
	});
};

export default payInstallment;
