import { SimulateRenegotiation } from "../Components";
import { InteractionHelper, APIHelper } from "Helper";

const showRenegotiation = (props, stage = 0)=>{
    props = Object.assign({
        wallet_id: "",
        contract_id: "",
        typeAmount: "final_release",
        installments: 1,
        response: {}
    }, props);

    InteractionHelper.close();

    if(stage === 1){
        InteractionHelper.confirm(<SimulateRenegotiation renegotiationProps={props} onChange={(propsChanged)=>{
            props = Object.assign(props, propsChanged);
        }}/>, "Renegociação").then(()=>{
            showRenegotiation(props, 2);
        }).catch(()=>{});
    }else if(stage === 2){
        InteractionHelper.close();
        InteractionHelper.toast("Ainda estamos trabalhando nisso!", null, "warning");
    }else{
        InteractionHelper.loading();
        APIHelper.fetch(`wallet/credit/${props.wallet_id}/renegotiation/${props.contract_id}/info`).then((response)=>{
            props.response = response;

            showRenegotiation(props, 1);
        }).catch((e)=>{
            console.log(e);
            InteractionHelper.close();
            InteractionHelper.toast("Algo deu errado, verifique sua conexão e tente novamente!", null, "error");
        });
    }
}

export default showRenegotiation;