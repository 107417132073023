import React, {createRef, useEffect} from "react";

import "./index.css";

export default ({duration, delay, direction, pauseOnHover, pauseOnClick, children})=>{
   const rootRef = createRef();

    const updateVariables = ()=>{
        if(!rootRef || !rootRef.current){return;}

        const variable = {
            "--duration": (typeof duration === "number" ? duration+"s" : "20s"),
            "--delay": (typeof delay === "number" ? delay+"s" : "0s"),
            "--direction": (["normal", "reverse", "alternate", "alternate-reverse"].includes(direction) ? direction : "normal"),
            "--pauseOnHover": (["running", "paused", "revert"].includes(pauseOnHover) ? pauseOnHover : "normal"),
            "--pauseOnClick": (["running", "paused", "revert"].includes(pauseOnClick) ? pauseOnClick : "normal"),
            "--loops": "infinite",
            "--gradient-color": "rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)",
            "--gradient-width": "200px",
            "--min-width": "100%"
        }

        Object.keys(variable).forEach((k)=>{
            rootRef.current.style.setProperty(k, variable[k]);
        });
    }

    useEffect(()=>{
        updateVariables();
    }, [rootRef, duration, delay, direction, pauseOnHover, pauseOnClick]);

    return <div ref={rootRef} className="Marquee-root">
        <div>{children}</div>
        <div>{children}</div>
    </div>
}