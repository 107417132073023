import React, { useState, useEffect, useContext } from "react";

const ContextRoot = React.createContext();

export const ContextProvider = ({ children }) => {
    const [currentContext, setCurrentContext] = useState({});
    const [settings, setCurrentSettings] = useState({});

    useEffect(()=>{
        if(localStorage.hasOwnProperty("main-settings")){
            const settings = JSON.parse(window.localStorage.getItem('main-settings'));
            setCurrentSettings(prevState => ({...prevState, ...settings, timestamp: Date.now()}));
        }
    }, []);

    useEffect(()=>{
        window.localStorage.setItem('main-settings', JSON.stringify(settings));
    }, [settings]);

    const setContext = (key, value) => {
        let newContext = {};
        newContext[key] = value;
        setCurrentContext(prevState => ({...prevState, ...newContext, timestamp: Date.now()}));
    };

    const getContext = (key) => {
        if(key in currentContext){
            return currentContext[key];
        }
        return;
    };

    const hasSetting = (key)=>{
        return typeof key === "string" && key in settings && ["string", "number", "boolean", "object"].includes(typeof settings[key]);
    }

    const getSetting = (key)=>{
        if(hasSetting(key)){
            return settings[key];
        }
        return undefined;
    }

    const setSetting = (key, value)=>{
        if(typeof key === "string" && ["string", "number", "boolean", "object"].includes(typeof value)){
            let settings = {};
            settings[key] = value;
            setCurrentSettings(prevState => ({...prevState, ...settings, timestamp: Date.now()}));
        }
    }

    return (<ContextRoot.Provider
        value={{ settings, currentContext, getSetting, setSetting, hasSetting, setContext, getContext }}
    >
        {children}
    </ContextRoot.Provider>);
};

export const ContextConsumer = ContextRoot.Consumer;

/*const useContextRoot = ()=>{
    const context = useContext(ContextRoot);
    return context;
}

const {setContext: _setContext, getContext: _getContext} = useContextRoot();

export const setContext = _setContext;

export const getContext = _getContext;*/

export default ContextRoot;