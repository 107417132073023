import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { CircularProgress, Typography } from "@mui/material";
import { Color } from "ivip-utils";
import { SvgIcon } from "Components";
import { mdiChevronRightCircle, mdiLock, mdiRayStartArrow, mdiSchool } from "@mdi/js";
import { APIHelper, InteractionHelper, QuizHelper } from "Helper";

import { SectionInfo } from "Types/Quiz";
import Button from "../Button";

const ListSections: React.FC = () => {
	const {
		error,
		response: data = [],
		status,
	} = APIHelper.useFetch<SectionInfo[]>({
		...QuizHelper.fetchOptions.list_sections(),
		immediate: true,
		handleResult: (result: Array<SectionInfo & { name: string }>) =>
			(result ?? []).map(({ name, title, ...props }: any) => ({ ...props, title: title ?? name ?? "" })),
	});

	const loading = status !== "success";

	useEffect(() => {
		const time = setTimeout(() => {
			if (status === "error" && error) {
				InteractionHelper.toast(error.message, null, "error");
			}
		}, 2000);
		return () => clearTimeout(time);
	}, [error, status]);

	// const [loading, setLoading] = useState<boolean>(true);
	// const [data, setData] = useState<Array<SectionInfo>>(sections);

	// useEffect(() => {
	// 	const time = setTimeout(() => {
	// 		setLoading(false);
	// 	}, 2000);
	// 	return () => clearTimeout(time);
	// }, []);

	const toSectionPage = (sectionId: string) => {
		//InteractionHelper.toast("Não é possível acessar a trilha no momento!", undefined, "warning");
		InteractionHelper.route.push(`/quiz/section/${sectionId}`);
	};

	return (
		<div className={[style.main, loading || data.length <= 0 ? style.loading : ""].join(" ")}>
			{(loading || data.length <= 0) && <CircularProgress color="inherit" />}
			{!loading &&
				data.length > 0 &&
				data.map(({ id, title, description, color, imgURL, status, completedUnits, totalUnits }, i) => {
					const colorPrimary = new Color(status === "locked" ? "#424242" : color);
					const [h, s, l] = (colorPrimary.hsl.match(/(\d+)/g) as string[]).map((v) => parseInt(v));
					const colorSecondary = new Color(Color.hsl(h + 30, s + 5, l - 10).hex);

					const colorDark = colorPrimary.darken(0.2);
					const colorLight = colorPrimary.lighten(0.2);

					const textColor = colorPrimary.vector.slice(0, 3).reduce((c, v) => c + v, 0) / 3 > 150 ? "33,33,33" : "250,250,250";

					return (
						<div
							key={i}
							className={[style.cart, status === "locked" ? style.locked : ""].join(" ")}
							style={
								{
									"--color-primary": colorPrimary.vector.join(", "),
									"--color-secondary": colorSecondary.vector.join(", "),
									"--color-dark": colorDark.vector.join(", "),
									"--color-light": colorLight.vector.join(", "),
									"--text-color": textColor,
								} as React.CSSProperties & { [p: string]: string }
							}
						>
							<div className={style.image}>
								<div>
									{typeof imgURL !== "string" || imgURL.trim() === "" ? (
										<SvgIcon path={mdiSchool} />
									) : (
										<img
											src={imgURL}
											alt="Imagem da trilha"
										/>
									)}
								</div>
							</div>
							<div className={style.info}>
								<Typography
									variant="h5"
									component="h2"
								>
									{title}
								</Typography>
								<Typography
									variant="body1"
									component="p"
								>
									{description}
								</Typography>
								<div className={style.progress}>
									<div
										className={style.progressBar}
										style={{ width: `${Math.round((completedUnits / totalUnits) * 100)}%` }}
									></div>
									<div className={style.progressText}>
										{completedUnits}/{totalUnits}
									</div>
									<div className={style.progressIcon}>
										<SvgIcon path={mdiSchool} />
									</div>
								</div>
								<Button
									icon={status === "locked" ? mdiLock : mdiChevronRightCircle}
									label={status === "locked" ? "Bloqueado" : "Continuar"}
									onClick={() => toSectionPage(id)}
									color={colorPrimary.hex}
									disabled={status === "locked"}
								/>
							</div>
						</div>
					);
				})}
		</div>
	);
};

export default ListSections;
