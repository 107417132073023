import React from "react";
import { SvgIcon } from "Components";
import { PaymentMethodId, PaymentMethodType } from "Types/ListPaymentMethods";

const list_withdrawal_methods: Array<{
	icon: () => JSX.Element;
	label: string;
	value: PaymentMethodId;
	payment_id: PaymentMethodType;
	description?: string;
	disabled?: boolean;
}> = [
	{
		icon: () => (
			<SvgIcon viewBox="0 0 39 42">
				<g clipPath="url(#clip0_1015_173678)">
					<path
						d="M30.7237 31.6058C29.1936 31.6058 27.7545 30.9944 26.6724 29.8846L20.8229 23.8815C20.4123 23.4589 19.6965 23.4602 19.2858 23.8815L13.4149 29.9066C12.3329 31.0163 10.8938 31.6278 9.36371 31.6278H8.21094L15.6196 39.2306C17.9333 41.6049 21.6846 41.6049 23.9985 39.2306L31.4283 31.6058H30.7237Z"
						fill="#32BCAD"
					></path>
					<path
						d="M9.36339 10.3726C10.8935 10.3726 12.3326 10.9839 13.4146 12.0938L19.2855 18.1196C19.7083 18.5539 20.3988 18.5555 20.8226 18.1192L26.6721 12.1158C27.7542 11.0059 29.1933 10.3945 30.7234 10.3945H31.428L23.9983 2.77002C21.6843 0.395506 17.933 0.395506 15.6193 2.77002L8.21094 10.3726L9.36339 10.3726Z"
						fill="#32BCAD"
					></path>
					<path
						d="M37.2647 16.701L32.775 12.0935C32.6762 12.1342 32.5691 12.1595 32.456 12.1595H30.4146C29.3592 12.1595 28.3262 12.5988 27.5803 13.3647L21.731 19.3675C21.1837 19.9294 20.4643 20.2104 19.7457 20.2104C19.0265 20.2104 18.3077 19.9294 17.7604 19.3682L11.889 13.3427C11.1432 12.5767 10.1101 12.1376 9.0547 12.1376H6.54461C6.43754 12.1376 6.3375 12.1117 6.24304 12.0752L1.73539 16.701C-0.578462 19.0755 -0.578462 22.9251 1.73539 25.2995L6.24286 29.9254C6.33748 29.8888 6.43754 29.8628 6.54461 29.8628H9.0547C10.1101 29.8628 11.1432 29.4238 11.889 28.6579L17.76 22.633C18.8211 21.5451 20.671 21.5446 21.731 22.6337L27.5803 28.636C28.3262 29.4018 29.3592 29.841 30.4146 29.841H32.456C32.5691 29.841 32.6762 29.8664 32.775 29.9071L37.2647 25.2995C39.5783 22.9251 39.5783 19.0755 37.2647 16.701Z"
						fill="#32BCAD"
					></path>
				</g>
				<defs>
					<clipPath id="clip0_1015_173678">
						<rect
							width="39"
							height="42"
							fill="white"
						></rect>
					</clipPath>
				</defs>
			</SvgIcon>
		),
		label: "PIX",
		value: "pix",
		payment_id: "ticket",
		description: "Transferência por endereço PIX",
	},
	{
		icon: () => (
			<SvgIcon viewBox="0 0 24 24">
				<path
					fill="var(--text-primary)"
					d="M14.24 10.56C13.93 11.8 12 11.17 11.4 11L11.95 8.82C12.57 9 14.56 9.26 14.24 10.56M11.13 12.12L10.53 14.53C11.27 14.72 13.56 15.45 13.9 14.09C14.26 12.67 11.87 12.3 11.13 12.12M21.7 14.42C20.36 19.78 14.94 23.04 9.58 21.7C4.22 20.36 .963 14.94 2.3 9.58C3.64 4.22 9.06 .964 14.42 2.3C19.77 3.64 23.03 9.06 21.7 14.42M14.21 8.05L14.66 6.25L13.56 6L13.12 7.73C12.83 7.66 12.54 7.59 12.24 7.53L12.68 5.76L11.59 5.5L11.14 7.29C10.9 7.23 10.66 7.18 10.44 7.12L10.44 7.12L8.93 6.74L8.63 7.91C8.63 7.91 9.45 8.1 9.43 8.11C9.88 8.22 9.96 8.5 9.94 8.75L8.71 13.68C8.66 13.82 8.5 14 8.21 13.95C8.22 13.96 7.41 13.75 7.41 13.75L6.87 15L8.29 15.36C8.56 15.43 8.82 15.5 9.08 15.56L8.62 17.38L9.72 17.66L10.17 15.85C10.47 15.93 10.76 16 11.04 16.08L10.59 17.87L11.69 18.15L12.15 16.33C14 16.68 15.42 16.54 16 14.85C16.5 13.5 16 12.7 15 12.19C15.72 12 16.26 11.55 16.41 10.57C16.61 9.24 15.59 8.53 14.21 8.05Z"
				/>
			</SvgIcon>
		),
		label: "Criptomoedas",
		value: "bep20",
		payment_id: "cryptocurrency",
		description: "Transferência para carteira BEP-20",
	},
];

export default list_withdrawal_methods;
