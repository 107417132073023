import React from "react";
import { SvgIcon } from "Components";
import style from "./style.module.scss";
import { Typography } from "@mui/material";

const MainComponent: React.FC<{}> = () => {
	return (
		<div className={style["main"]}>
			<div>
				<div>
					<svg viewBox="0 0 247.95 247.95">
						<path
							className={style.path01}
							d="M223,81.19c0,65.24-49.35,118.13-110.22,118.13-1.1,0-2.19,0-3.27,0V68.63H222.4Q223,74.83,223,81.19Z M95.65,68.63v80a64.72,64.72,0,0,1-59.6-39.47c-.23-.53-.45-1.06-.66-1.6a64.49,64.49,0,0,1-4.46-23.64V68.63Z M95.65,164.33v34.81a64.7,64.7,0,0,1-64.72-64.71,66.73,66.73,0,0,1,.39-7.11C34.86,148.13,62.31,164.33,95.65,164.33Z"
						/>
					</svg>
					<Typography variant="body2">
						Em breve, você terá o prazer de explorar o <b>iVipBank</b>!
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default {
	id: "iVipBank",
	title: "iVipBank",
	icon: (
		<SvgIcon
			viewBox="0 0 247.95 247.95"
			path="M223,81.19c0,65.24-49.35,118.13-110.22,118.13-1.1,0-2.19,0-3.27,0V68.63H222.4Q223,74.83,223,81.19Z M95.65,68.63v80a64.72,64.72,0,0,1-59.6-39.47c-.23-.53-.45-1.06-.66-1.6a64.49,64.49,0,0,1-4.46-23.64V68.63Z M95.65,164.33v34.81a64.7,64.7,0,0,1-64.72-64.71,66.73,66.73,0,0,1,.39-7.11C34.86,148.13,62.31,164.33,95.65,164.33Z"
		/>
	),
	render: () => {
		return <MainComponent />;
	},
};
