/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-control-regex */
import React, { useState, useEffect } from "react";
import { getDatabase, getAuth } from "ivipbase";

import { Messages } from "Crucial";

import { APIHelper } from "Helper";

import { Usuario, Resultado } from "Models";

import { MultiStorager } from "Utils";

const PromiseFull = <t = any>(list: (() => Promise<t>)[]): Promise<t> => {
	return new Promise(async (resolve, reject) => {
		if (Array.isArray(list) && list.every((p) => typeof p === "function")) {
			for (let i = 0; i < list.length; i++) {
				try {
					const result = await list[i]();
					resolve(result);
					return;
				} catch (e) {
					continue;
				}
			}
			reject("AggregateError: All promises were rejected");
		}
	});
};

interface RegisterIP {
	as: string;
	city: string;
	clientIp: string;
	country: string;
	countryCode: string;
	isp: string;
	lat: number;
	lon: number;
	org: string;
	query: string;
	region: string;
	regionName: string;
	status: "authorized" | "authorizedDenied" | "requestedAuthorization";
	timezone: string;
	userAgent: {
		[k: string]: any;
	};
	zip: string;
	currentIp?: boolean;
}

export const useRegisterIP = () => {
	const [myIp, setMyIp] = useState<string | undefined | null>(null);
	const [list, setList] = useState<RegisterIP[]>([]);
	const [idUser, setIdUser] = useState<string | undefined | null>(null);

	useEffect(() => {
		APIHelper.getMyIP().then(({ clientIp }: any) => {
			setMyIp(clientIp);
		});

		UserHelper.getUser()
			.then((user) => {
				if (typeof user.path === "string") {
					setIdUser(user.path.split("/").pop());
				}
			})
			.catch(() => {});
	}, []);

	useEffect(() => {
		if (typeof idUser !== "string") {
			return;
		}

		const ref = getDatabase().ref(`RegisterIP`).child<RegisterIP[]>(idUser);

		let listener = ref.on("value", (snapshot) => {
			const l: any[] = [];
			snapshot.val()?.forEach((r) => {
				if (l.length > 6) {
					return true;
				}
				r.currentIp = typeof myIp === "string" && r.clientIp === myIp;
				if (r.currentIp) {
					l.unshift(r);
				} else {
					l.push(r);
				}
			});
			setList(() => l.slice(0, 6));
		});

		return () => {
			listener.stop();
		};
	}, [idUser, myIp]);

	return list;
};

export default class UserHelper {
	static login(email: string, senha: string): Promise<Usuario> {
		return new Promise(async (resolve, reject) => {
			let validEmail = new RegExp(
				/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
			).exec(email);
			if (!validEmail) {
				reject(new Resultado(-1, Messages.getMsg("LOGIN-ERROR-EMAIL-INVALID"), null, { email: email, senha: senha }));
				return;
			}

			getAuth()
				.signInWithEmailAndPassword(email, senha)
				.then(async () => {
					APIHelper.clearCache();
					MultiStorager.DataStorager.set("__validIPRecord", null);
					MultiStorager.DataStorager.delete("chatRooms");
					return this.getUser(true);
				})
				.then(async (usuario) => {
					resolve(usuario);
				})
				.catch(async (err) => {
					let errMss = Messages.getMsg("LOGIN-ERROR-DEFAULT");
					if (err.code && typeof err.code.search === "function") {
						if (err.code.search("invalid-email") >= 0) {
							errMss = Messages.getMsg("LOGIN-ERROR-EMAIL-INVALID");
						} else if (err.code.search("wrong-password") >= 0) {
							errMss = Messages.getMsg("LOGIN-ERROR-PASSWORD-INVALID");
						} else if (err.code.search("user-disabled") >= 0) {
							errMss = Messages.getMsg("LOGIN-ERROR-USER-DISABLED");
						} else if (err.code.search("user-not-found") >= 0) {
							errMss = Messages.getMsg("LOGIN-ERROR-USER-NOT-FOUND");
						}
					}
					try {
						this.logout();
					} catch (e) {}
					reject(new Resultado(-1, errMss, null, { email: email, senha: senha }));
				});
		});
	}

	static async logout(): Promise<void> {
		await getAuth().signOut().catch(console.log);
		APIHelper.clearCache();

		if (MultiStorager.DataStorager.hasKey("--usuario-mutation-function") && MultiStorager.DataStorager.hasKey("--usuario-mutation-ref")) {
			MultiStorager.DataStorager.get("--usuario-mutation-ref").off("value", MultiStorager.DataStorager.get("--usuario-mutation-function"));
			MultiStorager.DataStorager.delete("--usuario-mutation-function");
			MultiStorager.DataStorager.delete("--usuario-mutation-ref");
		}

		if (MultiStorager.DataStorager.hasKey("--event-usuario-mutation-function")) {
			const event = MultiStorager.DataStorager.get("--event-usuario-mutation-function");
			event?.stop();
			MultiStorager.DataStorager.delete("--event-usuario-mutation-function");
		}

		MultiStorager.DataStorager.set("usuario", null);
		MultiStorager.DataStorager.delete("wallets");
		MultiStorager.DataStorager.delete("wallets-requestFetch");
		MultiStorager.DataStorager.delete("chatRooms");
		window.routerHistory.push("/");
		//MultiStorager.DataStorager.eraseAll();
	}

	static passwordReset(email: string): Promise<Resultado> {
		return new Promise((resolve, reject) => {
			let validEmail = new RegExp(
				/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
			).exec(email);
			if (!validEmail) {
				reject(new Resultado(-1, Messages.getMsg("LOGIN-ERROR-EMAIL-INVALID"), null, { email: email }));
				return;
			}

			getAuth()
				.sendPasswordResetEmail(email)
				.then(() => {
					resolve(new Resultado(1, Messages.getMsg("LOGIN-SUCCESS-SEND-PASSWORD-RESET"), null, { email: email }));
				})
				.catch((error) => {
					if (error.code.search("user-not-found") >= 0) {
						reject(new Resultado(-1, Messages.getMsg("LOGIN-ERROR-SEND-PASSWORD-RESET-USER-NOT-FOUND"), null, error));
					} else {
						reject(new Resultado(-1, Messages.getMsg("ERROR-DEFAULT"), null, error));
					}
				});
		});
	}

	static getUser(isLogin: boolean = false): Promise<Usuario> {
		return new Promise(async (resolve, reject) => {
			try {
				const phases: (() => Promise<any>)[] = [];

				phases.push(
					() =>
						new Promise(async (resolve, reject) => {
							if (MultiStorager.DataStorager.get("usuario") instanceof Usuario) {
								resolve(MultiStorager.DataStorager.get("usuario"));
								return;
							}
							reject();
						}),
				);

				phases.push(
					() =>
						new Promise(async (resolve, reject) => {
							const currentUser = getAuth().currentUser;

							if (!currentUser) {
								reject();
								return;
							}

							let info = await APIHelper.fetch("user").catch(() => Promise.resolve(null));

							// let snap = await getDatabase()
							// 	.ref("Usuarios")
							// 	.child(currentUser.uid)
							// 	.get()
							// 	.catch((err) => {
							// 		console.log(err);
							// 	});

							if (info) {
								let usuario = new Usuario().parse(info.path, info) as Usuario;
								MultiStorager.DataStorager.set("usuario", usuario);

								const isValidIp = isLogin !== true ? await UserHelper.validIPRecord(usuario) : true;

								if (isValidIp) {
									resolve(usuario);
								} else {
									reject();
								}

								return;
							} else {
								reject();
								return;
							}
						}),
				);

				PromiseFull<Usuario>(phases)
					.then((usuario) => {
						if (!(usuario instanceof Usuario)) {
							reject(
								new Resultado(-1, Messages.getMsg("DB-ERROR-GET-USER"), null, {
									errorType: 2,
								}),
							);
							return;
						}

						if (!MultiStorager.DataStorager.hasKey("--usuario-mutation-function") && usuario.path) {
							const ref = getDatabase().ref(usuario.path);

							const fn = async () => {
								const usuario = MultiStorager.DataStorager.get("usuario");
								if (!(usuario instanceof Usuario) || !usuario.path) {
									return;
								}
								let info = await APIHelper.fetch("user").catch(() => Promise.resolve(null));

								// let snap = await getDatabase()
								// 	.ref(usuario.path)
								// 	.once("value")
								// 	.catch((err) => {
								// 		console.log(err);
								// 	});

								if (info) {
									MultiStorager.DataStorager.set("usuario", new Usuario().parse(info.path, info));
								}
							};

							MultiStorager.DataStorager.set("--usuario-mutation-function", fn);

							const event = ref.on("notify_mutations", fn);
							MultiStorager.DataStorager.set("--event-usuario-mutation-function", event);
							MultiStorager.DataStorager.set("--usuario-mutation-ref", ref);
						}

						if (!(MultiStorager.DataStorager.get("usuario") instanceof Usuario)) {
							MultiStorager.DataStorager.set("usuario", usuario);
						}

						resolve(usuario);
					})
					.catch(() => {
						reject(
							new Resultado(-1, Messages.getMsg("DB-ERROR-GET-USER"), null, {
								errorType: 3,
							}),
						);
					});
			} catch (e) {
				reject(new Resultado(-1, (e as any).message ?? String(e), null, {}));
			}
		});
	}

	static myIp(): Promise<string> {
		return new Promise(async (resolve, reject) => {
			try {
				if (MultiStorager.DataStorager.hasKey("__IpNetwork")) {
					let ip = MultiStorager.DataStorager.get("__IpNetwork");
					resolve(ip);
					return;
				}

				const ip = await APIHelper.fetch("use/ip");
				MultiStorager.DataStorager.set("__IpNetwork", ip);
				resolve(ip);
			} catch (e) {
				reject(new Resultado(-1, (e as any).message ?? String(e), null, {}));
			}
		});
	}

	static validIPRecord(usuario: Usuario): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			return resolve(true);
			try {
				if (!(usuario instanceof Usuario)) {
					resolve(false);
					return;
				}
				let valid = true;

				const status = await APIHelper.fetch("user/ip_status");

				valid = status === "authorized";

				if (!valid) {
					window.setTimeout(() => {
						if (usuario instanceof Usuario) {
							UserHelper.logout();
						}
					}, 100);
				}

				resolve(valid);
			} catch (e) {
				window.setTimeout(() => {
					if (usuario instanceof Usuario) {
						UserHelper.logout();
					}
				}, 100);
				resolve(false);
			}
		});
	}

	static setRegisterIPStatus(clientIp: string, status: "requestedAuthorization" | "authorized" | "authorizedDenied"): Promise<RegisterIP[]> {
		return new Promise(async (resolve, reject) => {
			try {
				if (typeof status !== "string" || ["requestedAuthorization", "authorized", "authorizedDenied"].includes(status) !== true) {
					reject(new Resultado(-1, Messages.getMsg("DB-ERROR-REGISTER-IP-TYPE-STATUS"), null, {}));
					return;
				}

				const user = await UserHelper.getUser().catch(() => undefined);

				if (!user || !user.path) {
					reject(new Resultado(-1, Messages.getMsg("DB-ERROR-GET-USER"), null, {}));
					return;
				}

				const idUser = user.path.split("/").pop() as string;

				const ip = await UserHelper.myIp();

				let lista: RegisterIP[] = [];

				const userRegisters = await getDatabase().ref("RegisterIP").child<RegisterIP[]>(idUser).get();

				userRegisters.val()?.forEach((r) => {
					if (r.clientIp === clientIp) {
						r["status"] = status;
					}
					if (r.clientIp === ip) {
						lista.unshift(r);
					} else {
						lista.push(r);
					}
				});

				await getDatabase()
					.ref("RegisterIP")
					.child(idUser)
					.set(JSON.parse(JSON.stringify(lista.slice(0, 6))));

				resolve(lista);
			} catch (e) {
				reject(new Resultado(-1, (e as any).message ?? String(e), null, {}));
			}
		});
	}

	static capturarListaUsuario(length: number) {
		return new Promise(async (resolve, reject) => {
			return resolve([]);
		});
	}

	//   static capturarListaUsuario(length) {
	//     return new Promise(async (resolve, reject) => {
	//       try {
	//         let usersRef = getDatabase().ref('Usuarios')
	//         console.log(usersRef)

	//         const snapshot = await usersRef.once('value')

	//         console.log(snapshot)

	//         snapshot.forEach((usuario) => {
	//           lista.push(
	//             new Usuario().parse(
	//               Global.getDataSnapshotPath(usuario.ref),
	//               usuario.val()
	//             )
	//           )
	//         })

	//         lista.sort((a, b) => {
	//           return b.dataCriacao - a.dataCriacao
	//         })

	//         if (typeof length === 'number') {
	//           lista = lista.slice(0, length)
	//         }

	//         console.log(lista)
	//         resolve(lista)
	//       } catch (err) {
	//         reject(
	//           new Resultado(-1, Messages.getMsg('DB-ERROR-GET-ALL-USERS'), err, {})
	//         )
	//       }
	//     })
	//   }

	static capturarUsuario(path: string) {
		return new Promise(async (resolve, reject) => {
			return reject(new Resultado(-1, Messages.getMsg("DB-ERROR-GET-USER"), null, { path }));

			// if (typeof path !== "string") {
			// 	reject(new Resultado(-1, Messages.getMsg("DB-ERROR-PATH"), null, { path }));
			// 	return;
			// }
			// getDatabase()
			// 	.ref(path)
			// 	.once("value")
			// 	.then((snap) => {
			// 		if (snap.exists()) {
			// 			let usuario = new Usuario().parse(Global.getDataSnapshotPath(snap.ref), snap.val());
			// 			resolve(usuario);
			// 		} else {
			// 			reject(new Resultado(-1, Messages.getMsg("DB-ERROR-USER-NOT-FOUND"), null, { path }));
			// 		}
			// 	})
			// 	.catch((err) => {
			// 		reject(
			// 			new Resultado(-1, Messages.getMsg("DB-ERROR-GET-USER"), err, {
			// 				path,
			// 			}),
			// 		);
			// 	});
		});
	}

	static salvarUsuario(usuario: Usuario): Promise<Usuario> {
		return new Promise(async (resolve, reject) => {
			if (!(usuario instanceof Usuario)) {
				reject(
					new Resultado(-1, Messages.getMsg("DB-ERROR-USER-INVALID"), null, {
						usuario: usuario,
					}),
				);
				return;
			}

			if (usuario.path) {
				APIHelper.fetch("user/update_account", {
					account: usuario.toJson(),
				})
					.then(() => {
						let currentUser = MultiStorager.DataStorager.get("usuario");

						if (currentUser && currentUser.path === usuario.path) {
							MultiStorager.DataStorager.set("usuario", usuario);
						}

						resolve(usuario);
					})
					.catch((err) => {
						console.log(err);
						reject(
							new Resultado(-1, Messages.getMsg("DB-ERROR-UPDATE-USER"), err, {
								usuario: usuario,
							}),
						);
					});
			} else {
				reject(
					new Resultado(-1, Messages.getMsg("DB-ERROR-UPDATE-USER"), null, {
						usuario: usuario,
					}),
				);
			}
		});
	}

	static criarUsuario(novoUsuario: Usuario, novaSenha: string): Promise<Usuario> {
		return new Promise(async (resolve, reject) => {
			if (!(novoUsuario as any).email) {
				reject(
					new Resultado(-1, Messages.getMsg("LOGIN-ERROR-EMAIL-INVALID"), {
						novoUsuario: novoUsuario,
						novaSenha: novaSenha,
					}),
				);
				return;
			}

			if (MultiStorager.DataStorager.hasKey("referenceBy")) {
				(novoUsuario as any).referenceBy = MultiStorager.DataStorager.get("referenceBy");
			}

			APIHelper.fetch("user/signup", {
				signup: {
					email: (novoUsuario as any).email,
					password: novaSenha,
				},
				account: novoUsuario.toJson(),
			})
				.then((uid) => {
					const user = new Usuario().parse(`Usuarios/${uid}`, novoUsuario.toJson()) as Usuario;
					resolve(user);
				})
				.catch((err) => {
					console.log(err);
					reject(new Resultado(-1, Messages.getMsg("DB-ERROR-SAVE-USER"), err));
				});
		});
	}

	static getEmailFromAtSign(atSign: string): Promise<string> {
		return new Promise(async (resolve, reject) => {
			const error = (e: any = undefined) => new Resultado(-1, Messages.getMsg("API-ERROR-REQUIRE"), e);

			if (typeof atSign !== "string" || !atSign || atSign === "") {
				reject(error());
				return;
			}

			APIHelper.fetch("user/" + String(atSign).trim())
				.then((response) => {
					resolve(response);
				})
				.catch((e) => {
					reject(error(e));
				});
		});
	}

	static emailAvailable(email: string): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			APIHelper.fetch("user/validate_email", {
				email: email,
			})
				.then(resolve)
				.catch(reject);
		});
	}

	static emailReadyForLogin(email: string): Promise<number> {
		return new Promise(async (resolve, reject) => {
			APIHelper.fetch("user/email_ready_for_login", {
				email: email,
			})
				.then(resolve)
				.catch(reject);
		});
	}

	static atSignAvailable(atSign: string): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			APIHelper.fetch("user/validate_atSign", {
				atSign: atSign,
			})
				.then(resolve)
				.catch(reject);

			return;
			UserHelper.getEmailFromAtSign(atSign)
				.then(() => {
					resolve(true);
				})
				.catch(() => {
					resolve(false);
				});
		});
	}

	static uploadProfilePicture(usuario: Usuario, urlImage: string): Promise<Usuario> {
		return new Promise(async (resolve, reject) => {
			const error = (e: any = undefined) => new Resultado(-1, Messages.getMsg("API-ERROR-REQUIRE"), e);

			if (!(usuario instanceof Usuario) || !usuario.path || typeof urlImage !== "string" || !urlImage || urlImage === "") {
				reject(error());
				return;
			}

			let idUser = usuario.path.split("/").pop() as string;

			APIHelper.fetch("user/" + idUser + "/uploadProfilePicture", {
				imageBase64: urlImage,
			})
				.then((urlProfilePicture) => {
					const user = new Usuario().parse(usuario.path, usuario.toJson()) as Usuario;
					(user as any).fotoPerfil = urlProfilePicture;
					MultiStorager.DataStorager.set("usuario", user);
					resolve(user);
				})
				.catch((e) => {
					reject(error(e));
				});
		});
	}
}
