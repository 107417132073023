import { mat4, Color } from "Utils";

const PI2 = Math.PI * 2;

class GlowParticle{
    constructor(x, y, radius, maxRadius, minRadius, color){
        this.x = x || 0;
        this.y = y || 0;
        this.radius = radius || 0;
        this.maxRadius = maxRadius || 0;
        this.minRadius = minRadius || 0;
        this.color = new Color(color).vector;

        this.vx = Math.random() * 4;
        this.vy = Math.random() * 4;
        this.vradius = Math.random() * 4;
    }

    draw(ctx, width, height){
        this.radius += this.vradius;

        this.x += this.vx;
        this.y += this.vy;

        if(this.x < 0){
            this.vx *= -1;
            this.x += 10;
        }else if(this.x >= width){
            this.vx *= -1;
            this.x -= 10;
        }

        if(this.y < 0){
            this.vy *= -1;
            this.y += 10;
        }else if(this.y >= height){
            this.vy *= -1;
            this.y -= 10;
        }

        if(this.radius < this.minRadius){
            this.vradius *= -1;
            this.radius += 10;
        }else if(this.radius >= this.maxRadius){
            this.vradius *= -1;
            this.radius -= 10;
        }

        ctx.beginPath();

        const g = ctx.createRadialGradient(
            this.x, this.y, Math.max(0, this.radius) * 0.01,
            this.x, this.y, Math.max(0, this.radius),
        );
        g.addColorStop(0, `rgba(${this.color.join(", ")}, 1)`);
        g.addColorStop(1, `rgba(${this.color.join(", ")}, 0)`);

        ctx.fillStyle = g;
        ctx.arc(this.x, this.y, Math.max(0, this.radius), 0, PI2, false);
        ctx.fill();
    }
}

export default class ColorShaders{
    constructor(element){
        this.root = element;

        const {width, height} = this.getSize();

        this._millisStart = -1;

        this.pixelRatio = window.devicePixelRatio > 1 ? 1.3 : 1;
        this.particles = [];
        this.maxRadius = 200;
        this.minRadius = 160;

        this.colors = ["#263238", "#f44336", "#E91E63", "#2196F3", "#ffc107", "#263238"];

        this.canvas = document.createElement("canvas");
        this.canvas.width = width * this.pixelRatio;
        this.canvas.height = height * this.pixelRatio;
        this.ctx = this.canvas.getContext('2d');

        if (!this.ctx) {
            console.error('Unable to initialize Context. Your browser or machine may not support it.');
            return;
        }

        if(!document.body.contains(element)){return;}
        this.render();
    }

    start(element){
        if(!document.body.contains(element)){return;}

        this.root = element;

        const {width, height} = this.getSize();
        this.canvas.width = width * this.pixelRatio;
        this.canvas.height = height * this.pixelRatio;

        this.render();
    }

    getSize(){
        return {
            width: (this.root?.clientWidth || 0),
            height: (this.root?.clientHeight || 0),
        }
    }

    render(){
        if(!document.body.contains(this.root)){return;}

        if(this.particles.length <= 0){
            this.createParticles();
        }

        const {width, height} = this.getSize();
        this.canvas.width = width;
        this.canvas.height = height;

        this.ctx.globalCompositeOperation = "saturation";

        this.ctx.clearRect(0, 0, width, height);

        this.particles.forEach((p)=>{
            p.draw(this.ctx, width, height);
        });

        this.root.style.backgroundImage = `url("${this.canvas.toDataURL()}")`;

        this._millisStart = window.performance.now();
        window.requestAnimationFrame(()=>{this.render()});
    }

    millis(){
        return -1 === this._millisStart ? 0 : window.performance.now() - this._millisStart;
    }

    createParticles(){
        const {width, height} = this.getSize();

        let curColor = 0;
        this.particles = [];

        for(let i=0; i<this.colors.length; i++){
            this.particles.push(new GlowParticle(
                Math.random() * width,
                Math.random() * height, 
                Math.max(Math.random() * this.maxRadius, this.minRadius),
                this.maxRadius, this.minRadius,
                this.colors[curColor]
            ));

            curColor = (curColor + 1) % this.colors.length;
        }
    }
}