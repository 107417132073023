export const maskAmount = (value) => {
	value = typeof value === "number" ? value.toFixed(2) : value;
	value = String(value).replace(/\D/gi, "").replace(/^(0+)/gi, "");
	value = `${"0".repeat(3)}${value}`.slice(-Math.max(value.length, 3));
	value = value.replace(/(\d+)(\d{2})$/, (match, p1, p2) => {
		p1 = p1.split("").reverse().join("");
		p1 = p1.replaceAll(/(\d{3})/gi, "$1.");
		p1 = p1.split("").reverse().join("");
		return `R$ ${p1.replace(/^([\.\,]+)/gi, "")},${p2}`;
	});
	return value;
};

export const calculateCosts = (amount, costs) => {
	let totalAmount = amount;

	if (typeof costs === "object") {
		if (costs.percentage_rate !== 0) {
			totalAmount += parseFloat((amount * costs.percentage_rate).toFixed(2));
		}

		if (costs.installment_rate !== 0) {
			totalAmount += parseFloat((amount * costs.installment_rate).toFixed(2));
		}

		if (costs.additional_rate !== 0) {
			totalAmount += costs.additional_rate;
		}
	}

	return totalAmount;
};

export const listCosts = (amount, costs) => {
	const result = [];

	if (typeof costs === "object") {
		if (costs.additional_rate !== 0) {
			result.push({
				title: "Taxa de serviço",
				label: `Taxa de ${maskAmount(costs.additional_rate)}`,
				amount: costs.additional_rate,
			});
		}

		if (costs.percentage_rate !== 0) {
			result.push({
				title: "Taxa de serviço",
				label: `Taxa de ${(costs.percentage_rate * 100).toFixed(2)}%`,
				amount: amount * costs.percentage_rate,
			});
		}

		if (costs.installment_rate !== 0) {
			result.push({
				title: "Taxa de parcelamento",
				label: `Em ${costs.installments} parcela(s) ${(costs.installment_rate * 100).toFixed(2)}%`,
				amount: amount * costs.installment_rate,
			});
		}
	}

	return result;
};
