import { PhaseProps } from "Dialog/utils";
import React, { useState } from "react";
import { StakingApplyProps } from ".";
import { InputAmount } from "Components";
import style from "../utils/style.module.scss";
import { Typography } from "@mui/material";
import { maskAmount } from "Utils";

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const formatDuration = (value: number = 0) => {
	const duration_years = Math.floor(value / 12);
	const duration_months = value % 12;
	let duration_format = [];

	if (duration_years > 0) {
		duration_format.push(duration_years > 1 ? `${duration_years} anos` : `um ano`);
	}

	if (duration_months > 0) {
		duration_format.push(duration_months > 1 ? `${duration_months} meses` : `um mês`);
	}

	return capitalize(duration_format.join(" e "));
};

const SimulateStakingApply: React.FC<PhaseProps<StakingApplyProps>> = ({ state }) => {
	const [amount, setAmount] = useState<number>(typeof state.amount === "number" ? state.amount : 0);

	const change = (value: number) => {
		value = parseFloat(value as any) ?? 0;
		value = isNaN(value) ? 0 : value;
		setAmount(value);
		state.amount = value;
	};

	return (
		<div className={style["dialogPayment"]}>
			<InputAmount
				value={amount}
				currency={state.stakingInfo.typeAmount ?? "IVIP"}
				onChange={(value) => change(value)}
				min={state.min_allowed_amount}
				max={Math.min(state.max_allowed_amount, state.valid_allowed_amount)}
			/>

			<div className={style["description"]}>
				<Typography variant="body2">
					Você está prestes a investir na {state.stakingInfo.name}, com um investimento de{" "}
					{maskAmount(state.stakingInfo.stakingAvailableAmount ?? 0, 8, state.stakingInfo.typeAmount, "RTL")} de um total de{" "}
					{maskAmount(state.stakingInfo.stakingAmount ?? 0, 8, state.stakingInfo.typeAmount, "RTL")} disponíveis para aplicação. É
					importante lembrar que o seu saldo atual é de {maskAmount(state.available_amount ?? 0, 8, state.stakingInfo.typeAmount, "RTL")},
					dos quais você pode utilizar para esse tipo de investimento. Nessa Staking específica, o valor mínimo de investimento é de{" "}
					{maskAmount(state.stakingInfo.minAmount ?? 0, 8, state.stakingInfo.typeAmount, "RTL")} e o máximo é de{" "}
					{maskAmount(state.stakingInfo.maxAmount ?? 0, 8, state.stakingInfo.typeAmount, "RTL")}.
				</Typography>
				<br />
				<Typography variant="body2">
					Ao realizar o investimento nessa Staking, é necessário aguardar um prazo de {formatDuration(state.stakingInfo.duration)} para que
					o valor investido seja resgatado automaticamente, juntamente com a porcentagem de rendimento correspondente (no caso dessa
					Staking,{" "}
					{maskAmount(parseFloat(((state.stakingInfo.performance ?? 0) * 12 * 100).toFixed(4)), 8, state.stakingInfo.typeAmount, "RTL")}% ao
					ano).
				</Typography>
			</div>
		</div>
	);
};

export default SimulateStakingApply;
