import React from "react";
import { SvgIcon } from "Components";
import { PaymentMethodId, PaymentMethodType } from "Types/ListPaymentMethods";

const date_now = new Date();

const loan_date = date_now.toLocaleDateString();

date_now.setMonth(date_now.getMonth() + 1);
date_now.setDate(15);

const start_loan_payment = date_now.toLocaleDateString();

const list_payment_methods: Array<{
	icon: () => JSX.Element;
	label: string;
	value: PaymentMethodId;
	payment_id: PaymentMethodType;
	description?: string;
	disabled?: boolean;
}> = [
	{
		icon: () => (
			<SvgIcon viewBox="0 0 38 38">
				<path
					d="M2.37533 7.79004V30.2099H0.791992V7.79004H2.37533Z"
					fill="var(--text-primary)"
					fillOpacity="0.9"
				></path>
				<path
					d="M5.53883 7.79004V30.2099H3.95549V7.79004H5.53883Z"
					fill="var(--text-primary)"
					fillOpacity="0.9"
				></path>
				<path
					d="M10.2991 30.2099V7.79004H8.71578V30.2099H10.2991Z"
					fill="var(--text-primary)"
					fillOpacity="0.9"
				></path>
				<path
					d="M12.6533 7.79004V30.2099H11.07V7.79004H12.6533Z"
					fill="var(--text-primary)"
					fillOpacity="0.9"
				></path>
				<path
					d="M16.6214 30.2099V7.79004H15.038V30.2099H16.6214Z"
					fill="var(--text-primary)"
					fillOpacity="0.9"
				></path>
				<path
					d="M21.3634 7.79004V30.2099H19.7801V7.79004H21.3634Z"
					fill="var(--text-primary)"
					fillOpacity="0.9"
				></path>
				<path
					d="M26.138 30.2099V7.79004H24.5547V30.2099H26.138Z"
					fill="var(--text-primary)"
					fillOpacity="0.9"
				></path>
				<path
					d="M29.3062 7.79004V30.2099H27.7229V7.79004H29.3062Z"
					fill="var(--text-primary)"
					fillOpacity="0.9"
				></path>
				<path
					d="M32.4468 30.2099V7.79004H30.8634V30.2099H32.4468Z"
					fill="var(--text-primary)"
					fillOpacity="0.9"
				></path>
				<path
					d="M37.1955 7.79004V30.2099H35.6122V7.79004H37.1955Z"
					fill="var(--text-primary)"
					fillOpacity="0.9"
				></path>
			</SvgIcon>
		),
		label: "Boleto",
		value: "bolbradesco",
		payment_id: "ticket",
	},
	{
		icon: () => (
			<SvgIcon viewBox="0 0 39 42">
				<g clipPath="url(#clip0_1015_173678)">
					<path
						d="M30.7237 31.6058C29.1936 31.6058 27.7545 30.9944 26.6724 29.8846L20.8229 23.8815C20.4123 23.4589 19.6965 23.4602 19.2858 23.8815L13.4149 29.9066C12.3329 31.0163 10.8938 31.6278 9.36371 31.6278H8.21094L15.6196 39.2306C17.9333 41.6049 21.6846 41.6049 23.9985 39.2306L31.4283 31.6058H30.7237Z"
						fill="#32BCAD"
					></path>
					<path
						d="M9.36339 10.3726C10.8935 10.3726 12.3326 10.9839 13.4146 12.0938L19.2855 18.1196C19.7083 18.5539 20.3988 18.5555 20.8226 18.1192L26.6721 12.1158C27.7542 11.0059 29.1933 10.3945 30.7234 10.3945H31.428L23.9983 2.77002C21.6843 0.395506 17.933 0.395506 15.6193 2.77002L8.21094 10.3726L9.36339 10.3726Z"
						fill="#32BCAD"
					></path>
					<path
						d="M37.2647 16.701L32.775 12.0935C32.6762 12.1342 32.5691 12.1595 32.456 12.1595H30.4146C29.3592 12.1595 28.3262 12.5988 27.5803 13.3647L21.731 19.3675C21.1837 19.9294 20.4643 20.2104 19.7457 20.2104C19.0265 20.2104 18.3077 19.9294 17.7604 19.3682L11.889 13.3427C11.1432 12.5767 10.1101 12.1376 9.0547 12.1376H6.54461C6.43754 12.1376 6.3375 12.1117 6.24304 12.0752L1.73539 16.701C-0.578462 19.0755 -0.578462 22.9251 1.73539 25.2995L6.24286 29.9254C6.33748 29.8888 6.43754 29.8628 6.54461 29.8628H9.0547C10.1101 29.8628 11.1432 29.4238 11.889 28.6579L17.76 22.633C18.8211 21.5451 20.671 21.5446 21.731 22.6337L27.5803 28.636C28.3262 29.4018 29.3592 29.841 30.4146 29.841H32.456C32.5691 29.841 32.6762 29.8664 32.775 29.9071L37.2647 25.2995C39.5783 22.9251 39.5783 19.0755 37.2647 16.701Z"
						fill="#32BCAD"
					></path>
				</g>
				<defs>
					<clipPath id="clip0_1015_173678">
						<rect
							width="39"
							height="42"
							fill="white"
						></rect>
					</clipPath>
				</defs>
			</SvgIcon>
		),
		label: "PIX",
		value: "pix",
		payment_id: "bank_transfer",
	},
	{
		icon: () => (
			<SvgIcon viewBox="0 0 48 48">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16.6289 25.6846H29.6728L38.6117 40.0871H25.5652L16.6289 25.6846Z"
					fill="#0D6FAB"
				></path>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M17.9774 22.9571H31.4548L43.1595 8.25H29.682L17.9774 22.9571ZM4.5 40.0872H17.9774L29.682 25.1987H16.2046L4.5 40.0872Z"
					fill="#DB8135"
				></path>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M9.80664 8.25H21.9525L30.2734 23.4106H18.1275L9.80664 8.25Z"
					fill="#0D6FAB"
				></path>
			</SvgIcon>
		),
		label: "PEC",
		value: "pec",
		payment_id: "ticket",
	},
	{
		icon: () => (
			<SvgIcon viewBox="0 0 46 30">
				<path
					d="M42.4145 29.0953H3.54786C2.30422 29.0953 1.2959 28.0694 1.2959 26.8042V3.35045C1.2959 2.08518 2.30422 1.05933 3.54786 1.05933H42.4145C43.6582 1.05933 44.6665 2.08518 44.6665 3.35045V26.8042C44.6665 28.0694 43.6582 29.0953 42.4145 29.0953"
					stroke="var(--text-primary)"
					strokeWidth="1.5"
					fill="transparent"
				></path>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M42.4145 29.0953H3.54786C2.30422 29.0953 1.2959 28.0694 1.2959 26.8042V3.35045C1.2959 2.08518 2.30422 1.05933 3.54786 1.05933H42.4145C43.6582 1.05933 44.6665 2.08518 44.6665 3.35045V26.8042C44.6665 28.0694 43.6582 29.0953 42.4145 29.0953Z"
					fill="var(--text-primary)"
					fillOpacity="0.02"
				></path>
				<path
					d="M6.32031 23.0905H23.9607"
					stroke="var(--text-primary)"
					strokeWidth="1.5"
				></path>
				<path
					d="M26.883 23.0905H30.7393"
					stroke="var(--text-primary)"
					strokeWidth="1.5"
				></path>
				<path
					d="M6.32031 18.4916H12.0568"
					stroke="var(--text-primary)"
					strokeWidth="1.5"
				></path>
				<path
					d="M15.3926 18.4916H21.129"
					stroke="var(--text-primary)"
					strokeWidth="1.5"
				></path>
				<path
					d="M24.4639 18.4916H30.2003"
					stroke="var(--text-primary)"
					strokeWidth="1.5"
				></path>
				<path
					d="M33.5361 18.4916H39.2726"
					stroke="var(--text-primary)"
					strokeWidth="1.5"
				></path>
				<path
					d="M7.18797 8.29688H12.0201C12.1192 8.29688 12.213 8.38023 12.213 8.4997V11.3582C12.213 11.4777 12.1192 11.561 12.0201 11.561H7.18797C7.08888 11.561 6.99512 11.4777 6.99512 11.3582V8.4997C6.99512 8.38023 7.08888 8.29688 7.18797 8.29688Z"
					fill="#5AD2FF"
					stroke="white"
					strokeWidth="1.5"
				></path>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M7.18797 7.54688H12.0201C12.5408 7.54688 12.963 7.97347 12.963 8.4997V11.3582C12.963 11.8844 12.5408 12.311 12.0201 12.311H7.18797C6.66725 12.311 6.24512 11.8844 6.24512 11.3582V8.4997C6.24512 7.97347 6.66725 7.54688 7.18797 7.54688Z"
					stroke="var(--text-primary)"
					strokeWidth="1.5"
				></path>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1.45703 24.316C1.45703 25.6755 2.76404 26.7814 4.39073 26.8151L4.46721 26.8159H41.6128C43.2493 26.8159 44.5806 25.7309 44.6212 24.3796L44.6221 24.316V26.1351C44.6221 27.8218 43.2748 29.1891 41.6128 29.1891H4.46721C2.80521 29.1891 1.45703 27.8218 1.45703 26.1351V24.316Z"
					fill="#EEEEEE"
					style={{ mixBlendMode: "multiply" }}
				></path>
			</SvgIcon>
		),
		label: "Débito",
		value: "debvisa",
		payment_id: "debit_card",
		disabled: true,
	},
	{
		icon: () => (
			<SvgIcon viewBox="0 0 24 24">
				<path d="M16 12C18.76 12 21 9.76 21 7S18.76 2 16 2 11 4.24 11 7 13.24 12 16 12M21.45 17.6C21.06 17.2 20.57 17 20 17H13L10.92 16.27L11.25 15.33L13 16H15.8C16.15 16 16.43 15.86 16.66 15.63S17 15.12 17 14.81C17 14.27 16.74 13.9 16.22 13.69L8.95 11H7V20L14 22L22.03 19C22.04 18.47 21.84 18 21.45 17.6M5 11H.984V22H5V11Z" />
			</SvgIcon>
		),
		label: "Crédito",
		value: "emprestimo",
		payment_id: "emprestimo",
		description: `Ao solicitar um crédito com a iVipCoin hoje, em ${loan_date}, você só começará a pagar as parcelas a partir de ${start_loan_payment}, com vencimento todo dia 15 de cada mês. Porém, é importante estar ciente de que, em caso de inadimplência, nossa equipe financeira poderá congelar sua carteira, impedindo qualquer tipo de movimentação ou até mesmo tomar posse de seus bens para quitar a dívida. Portanto, é essencial manter o pagamento em dia para evitar qualquer transtorno.`,
	},
	{
		icon: () => (
			<SvgIcon viewBox="0 0 24 24">
				<path
					fill="var(--text-primary)"
					d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
				/>
			</SvgIcon>
		),
		label: "WhatsApp",
		value: "whatsapp",
		payment_id: "whatsapp",
	},
	{
		icon: () => (
			<SvgIcon viewBox="0 0 46 30">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M42.3545 28.6124H3.27319C2.02268 28.6124 1.00879 27.6123 1.00879 26.3787V3.51377C1.00879 2.28025 2.02268 1.28015 3.27319 1.28015H42.3545C43.605 1.28015 44.6189 2.28025 44.6189 3.51377V26.3787C44.6189 27.6123 43.605 28.6124 42.3545 28.6124Z"
					fill="transparent"
				></path>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1.00879 23.839C1.00879 25.1706 2.32928 26.2539 3.97273 26.2869L4.05 26.2877H41.5786C43.2319 26.2877 44.5769 25.2249 44.6179 23.9012L44.6189 23.839V25.6208C44.6189 27.273 43.2577 28.6123 41.5786 28.6123H4.05C2.37086 28.6123 1.00879 27.273 1.00879 25.6208V23.839Z"
					fill="#EEEEEE"
					style={{ mixBlendMode: "multiply" }}
				></path>
				<path
					d="M42.3545 28.6124H3.27319C2.02268 28.6124 1.00879 27.6123 1.00879 26.3787V3.51377C1.00879 2.28025 2.02268 1.28015 3.27319 1.28015H42.3545C43.605 1.28015 44.6189 2.28025 44.6189 3.51377V26.3787C44.6189 27.6123 43.605 28.6124 42.3545 28.6124"
					stroke="var(--text-primary)"
					strokeWidth="1.5"
					fill="var(--text-primary)"
					fillOpacity="0.02"
				></path>
				<path
					d="M5.21284 16.1735H22.976"
					stroke="var(--text-primary)"
					strokeWidth="1.5"
				></path>
				<path
					d="M25.6419 16.1735H30.1992"
					stroke="var(--text-primary)"
					strokeWidth="1.5"
				></path>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M44.6189 6.06934H1.00879V11.6249H44.6189V6.06934Z"
					stroke="var(--text-primary)"
					strokeWidth="1.5"
				></path>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.5648 24.0555H12.4466C12.7638 24.0555 13.0212 23.9335 13.0212 23.7831V20.9946C13.0212 20.8442 12.7638 20.7222 12.4466 20.7222H6.5648C6.24764 20.7222 5.99023 20.8442 5.99023 20.9946V23.7831C5.99023 23.9335 6.24764 24.0555 6.5648 24.0555Z"
					stroke="var(--text-primary)"
					strokeWidth="1.5"
				></path>
				<path
					d="M1.75879 10.8749V6.81934H43.8689V10.8749H1.75879Z"
					fill="#5AD2FF"
					stroke="var(--text-primary)"
					strokeWidth="1.5"
				></path>
			</SvgIcon>
		),
		label: "Crédito",
		value: "visa",
		payment_id: "credit_card",
		disabled: true,
	},
	{
		icon: () => (
			<SvgIcon viewBox="0 0 24 24">
				<path
					fill="var(--text-primary)"
					d="M14.24 10.56C13.93 11.8 12 11.17 11.4 11L11.95 8.82C12.57 9 14.56 9.26 14.24 10.56M11.13 12.12L10.53 14.53C11.27 14.72 13.56 15.45 13.9 14.09C14.26 12.67 11.87 12.3 11.13 12.12M21.7 14.42C20.36 19.78 14.94 23.04 9.58 21.7C4.22 20.36 .963 14.94 2.3 9.58C3.64 4.22 9.06 .964 14.42 2.3C19.77 3.64 23.03 9.06 21.7 14.42M14.21 8.05L14.66 6.25L13.56 6L13.12 7.73C12.83 7.66 12.54 7.59 12.24 7.53L12.68 5.76L11.59 5.5L11.14 7.29C10.9 7.23 10.66 7.18 10.44 7.12L10.44 7.12L8.93 6.74L8.63 7.91C8.63 7.91 9.45 8.1 9.43 8.11C9.88 8.22 9.96 8.5 9.94 8.75L8.71 13.68C8.66 13.82 8.5 14 8.21 13.95C8.22 13.96 7.41 13.75 7.41 13.75L6.87 15L8.29 15.36C8.56 15.43 8.82 15.5 9.08 15.56L8.62 17.38L9.72 17.66L10.17 15.85C10.47 15.93 10.76 16 11.04 16.08L10.59 17.87L11.69 18.15L12.15 16.33C14 16.68 15.42 16.54 16 14.85C16.5 13.5 16 12.7 15 12.19C15.72 12 16.26 11.55 16.41 10.57C16.61 9.24 15.59 8.53 14.21 8.05Z"
				/>
			</SvgIcon>
		),
		label: "Criptomoeda",
		value: "cryptocurrency",
		payment_id: "cryptocurrency",
	},
];

export default list_payment_methods;
