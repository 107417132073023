import React, { Component, useState, useEffect, useReducer, isValidElement } from "react";

import { getElementSize } from "Utils";

import GridLayout, { Responsive as ResponsiveGridLayout } from "react-grid-layout";

import "./styles-grid-layout.css";
import "./styles-resizable.css";

import style from "./style.module.scss";

import defaultLayout from "./defaultLayout";

import * as AllWidgets from "./widgets";

import { Messages } from "Crucial";

import { Usuario } from "Models";
import { UserHelper } from "Helper";

import Icon from "@mdi/react";

import { mdiSquareRounded, mdiAlertCircle, mdiAlert } from "@mdi/js";

import { CircularProgress } from "@mui/material";

const Widget = (definitions) => {
	const widget = {
		state: {},

		carregando: true,
		erro: undefined,
		notFound: false,
		user: {},
		userType: -1,

		icon: mdiSquareRounded,
		title: "",

		dataGrid: {},
		permission: [],

		componentDidMount: () => {},

		/**
		 * Essa função é responsável pela renderização do componente quando o usuário
		 * não possui permissões sobre as informações.
		 * Deve ser sobrescrita para se adequar às especificações da entidade.
		 */
		renderDeny: () => {
			return (
				<div className={style["Widget-deny"]}>
					<Icon path={mdiAlert} />
					<div className={style["span"]}>{Messages.getMsg("WIDGET-DENY")}</div>
				</div>
			);
		},

		/**
		 * Essa função é responsável pela renderização do componente quando o usuário
		 * não possui permissões sobre as informações.
		 * Deve ser sobrescrita para se adequar às especificações da entidade.
		 */
		renderError: () => {
			return (
				<div className={style["Widget-error"]}>
					<Icon path={mdiAlertCircle} />
					<div className={style["span"]}>{this.erro}</div>
				</div>
			);
		},

		/**
		 * Essa função é responsável pela renderização do componente quando as informações ainda estão sendo carregadas.
		 * Deve ser sobrescrita para se adequar às especificações da entidade.
		 */
		renderLoading: () => {
			return (
				<div className={style["Widget-loading"]}>
					<CircularProgress color="inherit" />
				</div>
			);
		},

		/**
		 * Essa função é responsável pela renderização do componente quando a informação
		 * referenciada na propriedade entidade não existe na base de dados ou não foi preenchida.
		 * Deve ser sobrescrita para se adequar às especificações da entidade.
		 */
		renderNotFound: () => {
			return <div></div>;
		},

		render: () => {
			return <div></div>;
		},
		/**
		 * Uma callback que é disparada quando o usuário é carregado pelo componente.
		 * Pode ser sobrescrita para ações adicionadas serem realizadas após o carregamento do usuário,
		 * antes do carregamento das informações da entidade.
		 */
		onUsuario: (user) => {},

		...definitions,
	};

	widget.getDataGrid = function (data) {
		return Object.assign(
			{
				x: null,
				y: null,
				w: 3,
				h: 4,
				minW: 3,
				maxW: Infinity,
				minH: 3,
				maxH: Infinity,
				static: false,
				isDraggable: true,
				isResizable: true,
				isBounded: false,
				resizeHandles: ["se"],
			},
			this.dataGrid,
			data,
		);
	}.bind(widget);

	widget.__componentDidMount = function () {
		this.__carregar();
		try {
			this.componentDidMount();
		} catch (e) {}
	}.bind(widget);

	/**
	 * Define se a entidade foi ou não encontrada.
	 * @param {boolean} valor
	 */
	widget.setCarregando = function (valor) {
		this.carregando = valor;
		//this.setState({});
	}.bind(widget);

	/**
	 * Define se a entidade foi ou não encontrada.
	 * @param {boolean} valor
	 */
	widget.setNotFound = function (valor) {
		this.notFound = valor;
		//this.setState({});
	}.bind(widget);

	/**
	 * Define que um erro ocorreu e recebe a descrição desse erro para apresentar ao usuário.
	 * @param {string} erro Mensagem de erro.
	 */
	widget.setErro = function (erro) {
		this.erro = erro;
		this.setState({});
	}.bind(widget);

	/**
	 * Faz o carregamento das informações do usuário e dados. Não deve ser sobrescrita.
	 * @private
	 */
	widget.__carregar = function () {
		UserHelper.getUser()
			.then((user) => {
				this.user = user;
				this.userType = this.__verificarPermissao();
				this.onUsuario(user);
				this.setCarregando(false);
			})
			.catch((err) => {
				this.setErro(err.descricao);
			});
	}.bind(widget);

	widget.__verificarPermissao = function () {
		let permission = Array.isArray(this.permission) ? this.permission : [this.permission];

		if (permission.length <= 0) {
			return 0;
		}

		try {
			permission = this.permission.every((v) => typeof v === "number")
				? Object.values(Usuario.permissoesList()).filter((v, i) => this.permission.includes(i))
				: this.permission;

			permission = this.permission.every((v) => typeof v === "string")
				? this.permission.filter((v) => Object.values(Usuario.permissoesList()).includes(v))
				: [];

			if (this.user.verificarPermissao(permission)) {
				let valid = this.user.permissoes.filter((v) => permission.includes(v));
				return Object.values(Usuario.permissoesList()).indexOf(valid[0]) + 1;
			} else {
				return -1;
			}
		} catch (err) {}

		return -1;
	}.bind(widget);

	widget.__render = function () {
		if (this.erro) {
			return this.renderError();
		} else if (this.notFound) {
			return this.renderNotFound();
		} else if (this.carregando) {
			return this.renderLoading();
		}

		if (this.userType >= 0) {
			return [this.render(this.userType), this.renderLoading()].find(isValidElement);
		} else {
			return this.renderDeny(this.userType);
		}
	}.bind(widget);

	return widget;
};

const RenderWidget = ({ component, loading, onRender }) => {
	const { title, icon, ...props_component } = component;
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);
	const [state, setState] = useState(component.state);

	component.setState = (s) => {
		s = Object.assign(state, component.state, s);
		if (s == state) forceUpdate();
		else setState(s);
	};

	useEffect(() => {
		if (loading) {
			return;
		}
		try {
			component.__componentDidMount();
		} catch (e) {}
	}, [loading]);

	useEffect(() => {
		if (typeof onRender === "function") {
			onRender();
		}
	}, [component.carregando]);

	return (
		<div className={style["Widget-component"]}>
			<div className={style["Widget-title"]}>
				<div className={style["Widget-icon"]}>{typeof icon === "string" ? <Icon path={icon} /> : icon}</div>
				<div className={style["Widget-label"]}>{title}</div>
				<div className={style["Widget-actions"]}></div>
			</div>
			<div className={style["Widget-content"]}>{loading ? component.renderLoading() : component.__render()}</div>
		</div>
	);
};

const GridLayoutAutoWith = ({ rootRef, layouts, onLayoutChange, children }) => {
	const [width, setWidth] = useState(1000);

	useEffect(() => {
		let observerWidthLoop = null;
		let timeOut = null;
		let outWidth = width;

		const loop = () => {
			try {
				if (rootRef.current) {
					const { width: w } = getElementSize(rootRef.current);
					if (outWidth !== w) {
						outWidth = w;
						window.clearTimeout(timeOut);
						timeOut = window.setTimeout(() => {
							setWidth(w);
						}, 100);
					}
				}
			} catch (e) {}
			observerWidthLoop = window.requestAnimationFrame(loop);
		};

		observerWidthLoop = window.requestAnimationFrame(loop);

		return () => {
			window.clearTimeout(timeOut);
			window.cancelAnimationFrame(observerWidthLoop);
		};
	});

	return (
		<ResponsiveGridLayout
			className="Widget-root"
			rowHeight={30}
			margin={[20, 20]}
			width={width}
			draggableHandle={`.${style["Widget-title"]}`}
			layouts={layouts}
			onLayoutChange={onLayoutChange}
			cols={{ lg: 12, md: 4 }}
			breakpoints={{ lg: 768, md: 0 }}
		>
			{children}
		</ResponsiveGridLayout>
	);
};

export default class WidgetMain extends Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.layout = defaultLayout;

		this.rootRef = React.createRef();

		this.widgets = [];

		this.allWidgetsNames = Object.keys(AllWidgets);
	}

	componentDidMount() {
		this.renderLayout();
	}

	componentWillUnmount() {
		this.widgets = [];
	}

	renderLayout = () => {
		this.widgets = [];
		let layout = [];

		for (let i = 0; i < this.layout.length; i++) {
			let componentName = this.layout[i]["name"];
			if (this.allWidgetsNames.includes(componentName)) {
				this.widgets.push(AllWidgets[componentName]);
				layout.push(this.layout[i]);
			}
		}

		this.widgets = this.widgets.map((widget, i) => Widget(widget));
		this.layout = layout;

		this.setState({});
	};

	layoutParse = (layout) => {
		let result = [];

		for (let i = 0; i < layout.length; i++) {
			let componentName = layout[i]["i"].split("-")[1];
			if (this.allWidgetsNames.includes(componentName)) {
				const { w, h, x, y } = layout[i];
				result.push({ w, h, x, y, name: componentName });
			}
		}

		return result;
	};

	onLayoutChange = (layout) => {
		return;
		console.log(JSON.stringify(this.layoutParse(layout)));
	};

	render() {
		return (
			<div
				className={style["main"]}
				{...this.props}
				ref={this.rootRef}
			>
				<GridLayoutAutoWith
					rootRef={this.rootRef}
					layouts={{
						lg: this.widgets.map((w, i) => {
							const lg = typeof this.layout[i].lg === "object" ? this.layout[i].lg : {};
							return Object.assign(w.getDataGrid(this.layout[i]), lg, { i: `widget-${w.title}-${i}` });
						}),
						md: this.widgets
							.map((w, i) => {
								const md = typeof this.layout[i].md === "object" ? this.layout[i].md : {};
								const data = w.getDataGrid(this.layout[i]);
								if (typeof data.minW === "number") data.minW = Math.min(data.minW, 4);
								return Object.assign(data, md, { i: `widget-${w.title}-${i}` });
							})
							.filter((r) => r !== null),
					}}
				>
					{this.widgets.map((w, i) => {
						const loading = i > 0 && this.widgets[i - 1].carregando && w.carregando;

						return (
							<div
								key={`widget-${w.title}-${i}`}
								className={style["Widget"]}
							>
								<RenderWidget
									component={w}
									loading={loading}
									onRender={() => {
										window.setTimeout(() => {
											this.setState({});
										}, 500);
									}}
								/>
							</div>
						);
					})}
				</GridLayoutAutoWith>
			</div>
		);
	}
}
