import React, { useState, useEffect } from "react";

import { useTheme } from "@mui/material/styles";

export const colors = [["#4caf50", "#689f38"], ["#ffc107", "#ffab00"], ["#00bcd4", "#00838f"], ["#E91E63", "#ff4081"], ["#9c27b0", "#673ab7"], ["#cddc39", "#c0ca33"], ["#3f51b5", "#3d5afe"], ["#795548", "#8d6e63"], ["#607d8b", "#78909c"]];

export const initialColors = colors.map(c => c[0]);

export const useColorList = ()=>{
    const {palette: {mode}} = useTheme();
    const [newColors, setColors] = useState(initialColors);

    useEffect(()=>{
        setColors(()=>{
            return colors.map(c => c[mode === "dark" ? 0 : 1]);
        });
    }, [mode]);

    return newColors;
}