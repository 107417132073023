import React from "react";
import { Structure } from "Dialog/utils";
import { APIHelper } from "Helper";
import SimulateWithdraw from "./SimulateWithdraw";
import { list_withdrawal_methods, maskAmount } from "Utils";
import { ListPaymentMethodsIten, PaymentMethodId } from "Types/ListPaymentMethods";
import { Bep20TrustList } from "Types/Settings";
import ViewPaymentDialog from "Dialog/ViewPaymentDialog";

export const identification_valid = {
	CPF: "^[0-9]{3}[0-9]{3}[0-9]{3}[0-9]{2}$",
	CNPJ: "^[0-9]{2}[0-9]{3}[0-9]{3}[0-9]{4}[0-9]{2}$",
};

export interface WithdrawDialogProps {
	type: PaymentMethodId;
	currency_id: string;
	amount: number;
	wallet_address: string;
	wallet: string;
	identification: {
		type: keyof typeof identification_valid;
		number: string;
	};
	response: {
		[k: string]: any;
	};
	list_withdrawal_methods: ListPaymentMethodsIten[];
	hidenOptions: boolean;
	altoSelect: boolean;
	verificationCode: string;
	bep20Trust: Bep20TrustList;
}

const getInfoBySelect = (state: WithdrawDialogProps): ListPaymentMethodsIten | undefined => {
	return state.list_withdrawal_methods?.find(({ id, payment_type_id }) => {
		const now = list_withdrawal_methods.find(({ value }) => value === state.type);
		return now && id === now.value && now.payment_id === payment_type_id;
	});
};

export default new Structure<WithdrawDialogProps>(
	"Saque",
	{
		type: "bep20",
		currency_id: "IVIP",
		amount: 0,
		wallet_address: "",
		wallet: "",
		identification: {
			type: "CPF",
			number: "",
		},
		response: {},
		list_withdrawal_methods: [],
		hidenOptions: false,
		altoSelect: true,
		verificationCode: "",
		bep20Trust: [],
	},
	[
		({ state, nextPhase, reject }) => {
			const wallet_id = state.wallet
				.replace(/(\/+)$/gi, "")
				.split("/")
				.pop();

			APIHelper.fetch<ListPaymentMethodsIten[]>(`finances/list_withdrawal_methods/${wallet_id}`)
				.then((response) => {
					state.list_withdrawal_methods = response.filter(({ payment_type_id, id }) => {
						return list_withdrawal_methods.findIndex(({ payment_id, value }) => id === value && payment_type_id === payment_id) !== -1;
					});

					const payment = state.list_withdrawal_methods.find(({ released }) => released === true);

					if (payment && payment.id && state.altoSelect) {
						state.type = payment.id;
					}

					if (payment && payment.currency_id) {
						state.currency_id = payment.currency_id;
					}

					return APIHelper.fetch<Bep20TrustList>(`/settings/bep20_trust`, "GET");
				})
				.then((res) => {
					state.bep20Trust = res;
					nextPhase();
				})
				.catch((e) => {
					console.log(e);
					reject("Algo deu errado, verifique sua conexão e tente novamente!");
				});
		},
		{ phase: (p) => <SimulateWithdraw {...p} />, term: "financial" },
		async ({ state, previousPhase, nextPhase, toPhase }) => {
			const {
				min_allowed_amount = 0,
				max_allowed_amount = 0,
				currency_id = "BRL",
				settings = [],
				released = true,
				requires_identification = false,
			} = getInfoBySelect(state) ?? {};

			const validationPatern = settings.find((s) => "address" in s)?.address?.validation;

			let error = "Ocorreu um erro interno, tente novamente!";

			if (!released) {
				error = "Método de saque indisponível!";
			} else if (max_allowed_amount < min_allowed_amount) {
				error = "Não há moedas suficiente";
			} else if (state.amount > max_allowed_amount) {
				error = `Valor máximo aceitável é ${maskAmount(max_allowed_amount, 0, currency_id, "RTL")}`;
			} else if (state.amount < min_allowed_amount) {
				error = `Valor mínimo aceitável é ${maskAmount(min_allowed_amount, 0, currency_id, "RTL")}`;
			} else if (validationPatern && !new RegExp(validationPatern, "g").test(state.wallet_address)) {
				error = "Endereço de conta/carteira para transferência está incorreto!";
			} else if (requires_identification && !state.identification.number) {
				error = "Preencha o campo de documento!";
			} else if (
				requires_identification &&
				!new RegExp(identification_valid[state.identification.type], "g").test(state.identification.number)
			) {
				error = "Documento de identificação inválido!";
			} else {
				error = "";
			}

			if (error !== "") {
				previousPhase(error);
				return;
			}

			if (requires_identification) {
				const document_status = await APIHelper.fetch<{
					doc: string;
					authority: string;
					status: "REGULAR" | "IREGULAR";
					type: "CPF" | "CNPJ";
				}>(`use/cpf_cnpj/${state.identification.number.replace(/\D/gi, "")}`, {}).catch(() => Promise.resolve(null));

				if (!document_status || document_status.status !== "REGULAR") {
					previousPhase("Documento inválido ou irregular!");
					return;
				}
			}

			const sendBody: {
				[k: string]: any;
			} = {
				wallet_id: state.wallet,
				wallet_address: state.wallet_address,
				payment_method: "transfer",
				amount: state.amount,
				total_amount: state.amount,
				currency_id,
				costs: [],
				verificationCode: state.verificationCode,
			};

			if (requires_identification) {
				const [type_identification] =
					Object.entries(identification_valid).find(([k, v]) => new RegExp(v, "g").test(state.identification.number)) ?? [];

				if (typeof type_identification === "string") {
					state.identification.type = type_identification as any;
				}

				sendBody["identification"] = state.identification;
			}

			APIHelper.fetch("finances/create/draw", sendBody)
				.then((response) => {
					state.response = response;
					nextPhase();
				})
				.catch((e) => {
					const errorMsg =
						e && typeof e.message === "string" && e.message.trim() !== ""
							? e.message
							: "Algo deu errado, verifique sua conexão e tente novamente!";
					state.response = {};
					previousPhase(errorMsg);
				});
		},
		({ state, resolve }) => {
			resolve();
			ViewPaymentDialog.show({ wallet: state.wallet, paymentId: state.response.history_id });
		},
	],
);
