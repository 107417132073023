import FirestoreObject, { DataTypes } from "./FirestoreObject";

import { OrderInfoDetails, OrderInfoStatus, OrderInfoStatusDetail, OrderInfoType, PaymentMethod } from "Types/Wallet";

export default class OrderInfo extends FirestoreObject {
	type: OrderInfoType = "deposit";
	wallet_type: string = "";
	payment_method_id: PaymentMethod = "whatsapp";
	payment_method: PaymentMethod = "pix";
	original_amount: number = 0;
	total_amount: number = 0;
	id: number | string = 0;
	date_created: number | string | Date = Date.now();
	date_last_updated: number | string | Date = Date.now();
	date_of_expiration: number | string | Date = Date.now();
	money_release_date?: number | string | Date;
	money_release_status?: string;
	operation_type:
		| "investment"
		| "regular_payment"
		| "money_transfer"
		| "recurring_payment"
		| "account_fund"
		| "payment_addition"
		| "cellphone_recharge"
		| "pos_payment"
		| "money_exchange" = "regular_payment";
	payment_type:
		| "account_money"
		| "ticket"
		| "bank_transfer"
		| "atm"
		| "credit_card"
		| "debit_card"
		| "prepaid_card"
		| "digital_currency"
		| "digital_wallet"
		| "voucher_card"
		| "crypto_transfer" = "ticket";
	status: OrderInfoStatus = "pending";
	status_detail: OrderInfoStatusDetail = "pending";
	currency_id: string = "";
	base_currency_id?: string;
	history_id: number | string = 0;
	description: string = "";
	details: Partial<OrderInfoDetails> = {};

	constructor(path?: string | null) {
		super(path as any, {
			type: { type: DataTypes.String, enum: ["deposit", "emprestimo", "transfer", "swap", "staking"], default: "deposit" },
			wallet_type: { type: DataTypes.String, default: "" },
			payment_method_id: {
				type: DataTypes.String,
				enum: [
					"reference",
					"staking",
					"transfer",
					"credit",
					"whatsapp",
					"swap",
					"emprestimo",
					"bolbradesco",
					"pix",
					"pec",
					"debvisa",
					"debmaster",
					"debelo",
					"visa",
					"master",
					"elo",
					"cryptocurrency",
				],
				default: "whatsapp",
			},
			payment_method: {
				type: DataTypes.String,
				enum: [
					"reference",
					"staking",
					"transfer",
					"credit",
					"whatsapp",
					"swap",
					"emprestimo",
					"bolbradesco",
					"pix",
					"pec",
					"debvisa",
					"debmaster",
					"debelo",
					"visa",
					"master",
					"elo",
					"cryptocurrency",
				],
				default: "pix",
			},
			original_amount: { type: DataTypes.Number, default: 0 },
			total_amount: { type: DataTypes.Number, default: 0 },
			id: { type: [DataTypes.Number, DataTypes.String], default: 0 },
			date_created: { type: [DataTypes.Number, DataTypes.String, DataTypes.Date], default: Date.now() },
			date_last_updated: { type: [DataTypes.Number, DataTypes.String, DataTypes.Date], default: Date.now() },
			date_of_expiration: { type: [DataTypes.Number, DataTypes.String, DataTypes.Date], default: Date.now() },
			money_release_date: { type: [DataTypes.Number, DataTypes.String, DataTypes.Date], default: undefined },
			money_release_status: { type: DataTypes.String, default: undefined },
			operation_type: {
				type: DataTypes.String,
				enum: [
					"investment",
					"regular_payment",
					"money_transfer",
					"recurring_payment",
					"account_fund",
					"payment_addition",
					"cellphone_recharge",
					"pos_payment",
					"money_exchange",
				],
				default: "regular_payment",
			},
			payment_type: {
				type: DataTypes.String,
				enum: [
					"account_money",
					"ticket",
					"bank_transfer",
					"atm",
					"credit_card",
					"debit_card",
					"prepaid_card",
					"digital_currency",
					"digital_wallet",
					"voucher_card",
					"crypto_transfer",
				],
				default: "ticket",
			},
			status: {
				type: DataTypes.String,
				enum: [
					"pending",
					"approved",
					"rejected",
					"in_process",
					"in_mediation",
					"cancelled",
					"discounted",
					"authorized",
					"refunded",
					"charged_back",
					"paid",
					"overdue",
					"absent",
					"transferred",
					"drawee",
				],
				default: "pending",
			},
			status_detail: {
				type: DataTypes.String,
				enum: [
					"pending",
					"approved",
					"rejected",
					"in_process",
					"in_mediation",
					"cancelled",
					"discounted",
					"authorized",
					"refunded",
					"charged_back",
					"paid",
					"overdue",
					"absent",
					"transferred",
					"drawee",
					"pending_waiting_payment",
					"accredited",
					"pending_contingency",
					"pending_review_manual",
					"cc_rejected_bad_filled_date",
					"cc_rejected_bad_filled_other",
					"cc_rejected_bad_filled_security_code",
					"cc_rejected_blacklist",
					"cc_rejected_call_for_authorize",
					"cc_rejected_card_disabled",
					"cc_rejected_duplicated_payment",
					"cc_rejected_high_risk",
					"cc_rejected_insufficient_amount_blocked_tokens",
					"cc_rejected_insufficient_amount",
					"cc_rejected_invalid_installments",
					"cc_rejected_max_attempts",
					"cc_rejected_other_reason",
					"cc_discounted_due_default",
				],
				default: "pending",
			},
			currency_id: { type: DataTypes.String, default: "" },
			base_currency_id: { type: DataTypes.String, default: undefined },
			history_id: { type: [DataTypes.Number, DataTypes.String], default: 0 },
			description: { type: DataTypes.String, default: "" },
			details: { type: DataTypes.Object, default: {} },
		});
	}

	static readonly statusLabel: {
		[key in OrderInfoStatus]: string;
	} = {
		approved: "Aprovado",
		rejected: "Rejeitado",
		in_mediation: "Na mediação",
		in_process: "Em processo",
		refunded: "Devolvido",
		cancelled: "Cancelado",
		charged_back: "Cobrado de volta",
		pending: "Pendente",
		discounted: "Descontado",
		authorized: "Authorized",
		paid: "Pago",
		overdue: "Vencido",
		absent: "Ausente",
		transferred: "Transferido",
		drawee: "Sacado",
	};

	static readonly statusDetailLabels: { [key in OrderInfoStatus | OrderInfoStatusDetail]: string } = {
		approved: "Aprovado",
		rejected: "Rejeitado",
		in_mediation: "Na mediação",
		in_process: "Em processo",
		refunded: "Devolvido",
		cancelled: "Cancelado",
		charged_back: "Cobrado de volta",
		pending: "Pendente",
		discounted: "Descontado",
		authorized: "Authorized",
		paid: "Pago",
		overdue: "Vencido",
		absent: "Ausente",
		transferred: "Transferido",
		drawee: "Sacado",
		pending_waiting_payment: "Aguardando pagamento",
		accredited: "Acreditado",
		pending_contingency: "Aguardando contingência",
		pending_review_manual: "Aguardando revisão manual",
		cc_rejected_bad_filled_date: "Data de preenchimento incorreto",
		cc_rejected_bad_filled_other: "Outro erro de preenchimento",
		cc_rejected_bad_filled_security_code: "Código de segurança inválido",
		cc_rejected_blacklist: "Cartão na lista de bloqueio",
		cc_rejected_call_for_authorize: "Chamada para autorização",
		cc_rejected_card_disabled: "Cartão desabilitado",
		cc_rejected_duplicated_payment: "Pagamento duplicado",
		cc_rejected_high_risk: "Alto risco",
		cc_rejected_insufficient_amount_blocked_tokens: "Saldo insuficiente ou tokens bloqueados",
		cc_rejected_insufficient_amount: "Saldo insuficiente",
		cc_rejected_invalid_installments: "Parcelas inválidas",
		cc_rejected_max_attempts: "Limite de tentativas excedido",
		cc_rejected_other_reason: "Outro motivo de rejeição",
		cc_rejected_insufficient_staked_amount: "Rejeitado, pois o valor disponível do staking expirou ou não estava disponível",
		cc_rejected_account_contains_debit_balance: "A conta contém saldo devedor",
		cc_cancelled_other_reason: "Outro motivo de cancelamento",
		cc_discounted_due_default: "Descontado devido a inadimplência",
	};
}
