import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
// import TabsCateiraIvipIvipay from "../../Components/TabsCateiraIvipIvipay";



import "./index.css";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:  'transparent',
    ...theme.typography.body2,
    padding: theme.spacing(22),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default {
    id: "Tela8",
    title: "Tela 8",
    render: () => {
        return (
            <div >

               
             
                <Box sx={{ flexGrow: 1 }} >
                    <Grid  container spacing={2} classes={{ root: "MuiGrid-root-quadrado-copleto" }}>
                        <Grid item xs={12} sx={{borderRadius:0}}>
                        {/* <TabsCateiraIvipIvipay></TabsCateiraIvipIvipay> */}
                        <div className='quadrado-inteiro'>
                            <Button classes={{root:"MuiButton-root-cateira-vip"}} variant="text">Carteira iVip</Button >
                            <Button classes={{root:"MuiButton-root-cateira-vip-Pay"}} variant="text">Carteira iVipPay</Button >
                        </div>
                            <Item classes={{root:"MuiItem-root-envonte"}}>
                          
                              <div className='segundoquadrado'>
                                    <Typography  classes={{root:"MuiTypography-root-metamask"}} variant="body1" gutterBottom>Bnb</Typography>
                                    <Typography  classes={{root:"MuiTypography-root-binance"}} variant="body1" gutterBottom>Carteira iVip</Typography>
                                <div className='cateira-iVip'>
                                    <p>$1,000</p>
                                    <p>$384.5</p>
                                </div>
                                <div className='cateira-iVip-bnb'>
                                    <p className='carteira-ivip-bnb'>112,798</p>
                                    <p className='carteira-ivip-bnb'>0.94626</p>
                                </div>
                                <div className='deposita-leilao-sacar'>
                                    <Button classes={{root:"MuiButton-root-metamaSkK"}}  variant="text">deposita</Button >
                                    <Button classes={{root:"MuiButton-root-metamAskK"}} variant="text">leilão</Button >
                                    <Button classes={{root:"MuiButton-root-metamaskK"}} variant="text">sacar</Button >
                                </div>
                                <div className='ultimo-quadrado'>
                                    <Button classes={{root:"MuiButton-root-ivipay-ultimo-quadrado"}} variant="text">iVipPay</Button >
                                </div>
                              </div>
                            </Item>
                        </Grid>

                    </Grid>
                </Box>
            </div>
        );
    },
};







