import React from "react";
import { Structure } from "Dialog/utils";
import { APIHelper } from "Helper";
import { list_payment_methods, maskAmount } from "Utils";
import { ListPaymentMethodsIten, PaymentMethodId } from "Types/ListPaymentMethods";
import SimulateCosts from "./SimulateDeposit";
import ViewPaymentDialog from "Dialog/ViewPaymentDialog";

export interface DepositPaymentDialoggProps {
	type: PaymentMethodId;
	currency_id: string;
	amount: number;
	amountWithCosts: number;
	list_payment_methods: ListPaymentMethodsIten[];
	installments: number;
	wallet: string;
	typeAmount: string;
	response: {
		[k: string]: any;
	};
	hidenOptions: boolean;
	altoSelect: boolean;
	automatic_conversion: boolean;
}

export default new Structure<DepositPaymentDialoggProps>(
	"Meio de Depósito",
	{
		type: "bolbradesco",
		currency_id: "BRL",
		amount: 0,
		amountWithCosts: 0,
		list_payment_methods: [],
		installments: 1,
		wallet: "",
		typeAmount: "",
		response: {},
		hidenOptions: false,
		altoSelect: true,
		automatic_conversion: false,
	},
	[
		({ state, nextPhase, reject }) => {
			const wallet_id = state.wallet
				.replace(/(\/+)$/gi, "")
				.split("/")
				.pop();

			APIHelper.fetch<ListPaymentMethodsIten[]>(`finances/list_payment_methods/${wallet_id}`)
				.then((response) => {
					state.list_payment_methods = response.filter(({ payment_type_id, id }) => {
						return list_payment_methods.findIndex(({ payment_id, value }) => id === value && payment_type_id === payment_id) !== -1;
					});

					const payment = state.list_payment_methods.find(({ released }) => released === true);

					if (payment && payment.id && state.altoSelect) {
						state.type = payment.id;
					}

					if (payment && payment.currency_id) {
						state.currency_id = payment.currency_id;
					}

					nextPhase();
				})
				.catch((e) => {
					console.log(e);
					reject("Algo deu errado, verifique sua conexão e tente novamente!");
				});
		},
		{ phase: (p) => <SimulateCosts {...p} />, term: "financial" },
		({ state, previousPhase, nextPhase }) => {
			const wallet_id = state.wallet
				.replace(/(\/+)$/gi, "")
				.split("/")
				.pop();

			try {
				const payment = state.list_payment_methods.find(({ id, payment_type_id }) => {
					const now = list_payment_methods.find(({ value }) => value === state.type);
					return now && id === now.value && now.payment_id === payment_type_id;
				});

				if (!payment) {
					throw new Error("Não foi possível encontrar o método de pagamento");
				}

				const defaultInstallment =
					Array.isArray(payment.payer_costs) && payment.payer_costs.length ? payment.payer_costs[0].installments - 1 : 0;

				const sendBody = {
					wallet_id: wallet_id,
					currency_id: state.currency_id ?? "BRL",
					payment_method: state.type,
					amount: state.amount,
					total_amount: state.amountWithCosts,
					installments: state.installments + defaultInstallment,
					typeAmount: state.typeAmount,
					automatic_conversion: state.automatic_conversion,
					to_currency_id: state.automatic_conversion ? "IVIP" : state.currency_id ?? "BRL",
				};

				const valid_amountValue = payment.max_allowed_amount_including_costs ? state.amountWithCosts : state.amount;

				if (!payment) {
					throw "Algo deu errado, verifique sua conexão e tente novamente!";
				}

				if (payment.applyLimits) {
					if (payment.max_allowed_amount <= 0) {
						throw "Não há limite disponível";
					}
					if (valid_amountValue > payment.max_allowed_amount) {
						throw `Valor máximo aceitável é ${maskAmount(payment.max_allowed_amount, undefined, payment.currency_id, "RTL")}`;
					}

					if (state.amount < payment.min_allowed_amount) {
						throw `Valor mínimo aceitável é ${maskAmount(payment.min_allowed_amount, undefined, payment.currency_id, "RTL")}`;
					}
				}

				//if(["bolbradesco", "pix", "pec", "whatsapp"].includes(state.type)){
				if (["bolbradesco", "pix", "pec", "whatsapp", "emprestimo", "cryptocurrency"].includes(state.type)) {
					APIHelper.fetch("finances/create/deposit", sendBody, 2)
						.then((response) => {
							state.response = response;
							nextPhase();
						})
						.catch((e) => {
							const errorMsg =
								e && typeof e.message === "string" && e.message.trim() !== ""
									? e.message
									: "Algo deu errado, verifique sua conexão e tente novamente!";
							state.response = {};
							previousPhase(errorMsg);
						});
				} else {
					previousPhase("Essa função ainda não está liberado. Tente mais tarde!");
				}
			} catch (e) {
				previousPhase(String(e));
			}
		},
		({ state, resolve }) => {
			resolve();
			ViewPaymentDialog.show({
				wallet: state.wallet,
				paymentId: state.response.history_id,
			});
		},
	],
);
