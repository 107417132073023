import React, { useEffect, useState } from "react";
import { PhaseProps } from "Dialog/utils";
import { NewWalletDialogProps } from "..";
import { InputCustom, SvgIcon } from "Components";
import { APIHelper, InteractionHelper } from "Helper";
import { Button, CircularProgress, Typography } from "@mui/material";
import { BankPartnerInfo } from "Types/IvipBank";
import style from "../style.module.scss";
import { mdiAccount, mdiDomain } from "@mdi/js";

const status_label = {
	pending: "Pendente",
	approved: "Aprovado",
	rejected: "Rejeitado",
	blocked: "Bloqueado",
	deleted: "Deletado",
	archived: "Arquivado",
	unblocked: "Desbloqueado",
	unarchived: "Desarquivado",
};

const type_label = {
	account: "Conta",
	wallet: "Carteira",
};

const formatDocument = (document: string, type: "cpf" | "cnpj") => {
	if (type === "cpf") {
		return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
	} else {
		return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
	}
};

const ButtonCount: React.FC<{ onClick: () => void; timeout: number }> = ({ onClick, timeout }) => {
	const [count, setCount] = useState<number>(Math.round(timeout / 1000));
	const [time, setTime] = useState<number>(Date.now());

	useEffect(() => {
		const time = setInterval(() => {
			setCount((p) => Math.round(p - 1));
		}, 1000);

		return () => clearInterval(time);
	}, [timeout, time]);

	return (
		<Button
			disabled={count > 0}
			onClick={
				count > 0
					? undefined
					: () => {
							onClick();
							setCount(Math.round(timeout / 1000));
							setTime(Date.now());
					  }
			}
		>
			{count > 0 ? `${("00" + count).slice(-Math.max(2, count.toFixed(0).length))} sec` : "Reenviar"}
		</Button>
	);
};

const Ivipbank: React.FC<
	PhaseProps<NewWalletDialogProps<"IVIPBANK">> & {
		phase: number;
	}
> = ({ state, phase, previousPhase }) => {
	const [partners, setPartners] = useState<BankPartnerInfo[] | null>(null);
	const [partnerId, setPartnerId] = useState<number | undefined>(state.data.partnerId ?? undefined);

	const [sentCode, setSentCode] = useState(false);

	const sendVerificationCode = () => {
		setSentCode(false);
		APIHelper.fetch<boolean>("wallet/ivipbank/send-verification-code", {
			username: state.data.account,
			password: state.data.password,
			partnerId: state.data.partnerId,
		})
			.then((sent) => {
				setSentCode(sent);
				InteractionHelper.toast("Código de confirmação enviado para o e-mail e/ou telefone", undefined, "success");
			})
			.catch(() => {
				setPartners(null);
				InteractionHelper.toast("Erro ao enviar código de confirmação", undefined, "error");
				previousPhase();
			});
	};

	useEffect(() => {
		if (Array.isArray(partners) && partners.length > 0) {
			setPartnerId(partners[0].id);
			state.data.partnerId = partners[0].id;
		}
	}, [partners]);

	useEffect(() => {
		const time = setTimeout(() => {
			if (phase === 1) {
				APIHelper.fetch<BankPartnerInfo[]>("wallet/ivipbank/partners", {
					username: state.data.account,
				})
					.then((partners) => {
						setPartners(partners);
					})
					.catch(() => {
						setPartners(null);
						InteractionHelper.toast(
							"Erro ao buscar informações da conta bancária do usuário ou o usuário não existe",
							undefined,
							"error",
						);
						previousPhase();
					});
			} else if (phase === 2) {
				sendVerificationCode();
			}
		}, 1000);

		return () => clearTimeout(time);
	}, [phase]);

	switch (phase) {
		case 0: {
			return (
				<>
					<InputCustom
						label={"Nome"}
						helperText=" "
						value={state.wallet.nome}
						onChange={(value) => {
							state.wallet.nome = value.toString();
							state.wallet = state.wallet;
						}}
					/>
					{state.wallet.status !== "valid" && (
						<InputCustom
							label={"Conta (CPF/CNPJ)"}
							helperText=" "
							value={state.data.account}
							onChange={(value) => {
								state.data.account = value;
							}}
						/>
					)}
					{state.wallet.status !== "valid" && (
						<InputCustom
							label={"Senha"}
							helperText=" "
							value={state.data.password}
							type={"password"}
							onChange={(value) => {
								state.data.password = value;
							}}
						/>
					)}
					<InputCustom
						label={"Número"}
						helperText=" "
						value={state.wallet.getWalletCode()}
						readOnly={true}
					/>
				</>
			);
		}
		case 1: {
			return (
				<>
					{!partners && (
						<div className={style.loading}>
							<CircularProgress color="inherit" />
						</div>
					)}
					{Array.isArray(partners) && (
						<div className={style.partners}>
							{partners.map(({ document_number, document_type, account_status, type, name, id }, i) => {
								return (
									<div
										className={[style.item, partnerId === id ? style.selected : ""].join(" ")}
										key={i}
										onClick={() => {
											setPartnerId(id);
											state.data.partnerId = id;
										}}
									>
										<div className={style.icon}>
											<SvgIcon path={document_type === "cpf" ? mdiAccount : mdiDomain} />
										</div>
										<div className={style.info}>
											<Typography variant="h6">{name}</Typography>
											<Typography variant="caption">
												{formatDocument(document_number, document_type)} - {type_label[type]} -{" "}
												{status_label[account_status] ?? account_status}
											</Typography>
										</div>
									</div>
								);
							})}
						</div>
					)}
				</>
			);
		}
		case 2: {
			return (
				<>
					{!sentCode && (
						<div className={style.loading}>
							<CircularProgress color="inherit" />
						</div>
					)}
					{sentCode && (
						<>
							<InputCustom
								label={"Código de confirmação"}
								value={state.data.confirmationCode}
								onChange={(value) => {
									state.data.confirmationCode = String(value);
								}}
							/>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									margin: "10px 0",
									width: "100%",
								}}
							>
								<ButtonCount
									onClick={sendVerificationCode}
									timeout={15000}
								/>
							</div>
						</>
					)}
				</>
			);
		}
	}

	return <></>;
};

export default Ivipbank;
