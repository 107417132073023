import { mdiCheckboxBlankCircle } from "@mdi/js";
import { Usuario } from "Models";

type MenuItemOptionsType = "item" | "root" | "submenu";

export interface MenuItemOptions {
	href?: string | null;
	exact?: boolean | null;
	isActive?: ((routerHistory: { [key: string]: any }) => boolean) | null;
	action?: ((href: string) => void) | null;
	icon?: string | null;
	label?: string | null;
	active?: boolean | null;
	onClick?: ((...arg: any[]) => any) | null;
	itens?: Array<Menu | MenuItem> | null;
	submenu?: Menu | null;
	permissions?: string[] | null;
	type: MenuItemOptionsType;
	simplifiable?: boolean | null;
	props?: {
		[p: string]: any;
	} | null;
	disabled?: boolean;
	isNew?: boolean;
	hidden?: boolean;
}

type SubmenuOptions = Pick<MenuItemOptions, "label" | "permissions" | "disabled" | "isNew" | "hidden">;

const getPathToInitialRoot = (href: string): string => {
	let lengthSteps = window.routerHistory.location.pathname.split("/").length - 1;
	return (lengthSteps >= 1 ? "../".repeat(lengthSteps) : "./") + href.replace(/^(\.{1,2}\/)+/gi, "");
};

const isPath = (href: string, exact: boolean = false): boolean => {
	if (exact === true) {
		return window.routerHistory.location.pathname === new URL(href, window.location.href).pathname;
	}
	return window.routerHistory.location.pathname.search(new URL(href, window.location.href).pathname) === 0;
};

export class MenuItem {
	icon: string = mdiCheckboxBlankCircle;
	label: string | null | undefined;
	isActive: ((routerHistory: { [key: string]: any }) => boolean) | null | undefined;
	action: ((href: string) => void) | null | undefined;
	href: string | null | undefined;
	exact: boolean = false;
	submenu: Menu = new Menu();
	permissions: string[] = [];
	type: "item" = "item";
	props:
		| {
				[p: string]: any;
		  }
		| undefined;
	simplifiable: boolean = false;
	disabled: boolean = false;
	isNew: boolean = false;
	hidden: boolean = false;

	constructor(options: Partial<MenuItemOptions> | MenuItem) {
		this.icon = typeof options.icon === "string" ? options.icon : this.icon;
		this.label = typeof options.label === "string" ? options.label : this.label;
		this.isActive = typeof options.isActive === "function" ? options.isActive : this.isActive;
		this.action = typeof options.action === "function" ? options.action : this.action;
		this.href = typeof options.href === "string" ? options.href : this.href;
		this.exact = typeof options.exact === "boolean" ? options.exact : this.exact;
		this.permissions = Array.isArray(options.permissions) ? options.permissions : this.permissions;
		this.simplifiable = typeof options.simplifiable === "boolean" ? options.simplifiable : this.simplifiable;
		this.type = "item";
		this.disabled = options.disabled ?? false;
		this.isNew = options.isNew ?? false;
		this.hidden = options.hidden ?? false;
	}

	itsValid(): boolean {
		return this.label !== null && (this.action !== null || this.href !== null);
	}

	checkIsActive(exact: boolean = false): boolean {
		if (typeof this.isActive === "function") {
			return this.isActive(window.routerHistory as any);
		} else if (typeof this.href === "string") {
			return isPath(this.href, exact || this.exact);
		}
		return false;
	}

	pushSubmenu(options: SubmenuOptions) {
		return this.submenu.createSubmenu(options);
	}

	click() {
		if (Array.isArray(this.submenu) && this.submenu.length > 0) {
			return;
		}
		if (this.checkIsActive(true)) {
			return;
		}

		if (typeof this.action === "function") {
			this.action(window.routerHistory as any);
		} else if (typeof this.href === "string") {
			window.routerHistory.push(getPathToInitialRoot(this.href), this.props);
		}

		// if (typeof this.action === "function" || typeof this.href === "string") {
		// 	// this.menuRender = this.renderFatherMenu();
		// 	// this.setState({});
		// }
	}

	isItem(): this is MenuItem {
		return this.type === "item";
	}

	clone() {
		const item = new MenuItem(this);
		item.submenu = this.submenu.clone();
		return item;
	}
}

export class Menu {
	label: string | null = null;
	type: Omit<MenuItemOptionsType, "item"> = "root";
	itens: Array<Menu | MenuItem> = [];
	permissions: string[] = [];
	disabled: boolean = false;
	isNew: boolean = false;
	hidden: boolean = false;
	private current_permissions: string[] = [];

	constructor() {}

	setPermissions(permissions: string[]) {
		this.current_permissions = permissions;
		return this;
	}

	createItem(options: Partial<MenuItemOptions>): MenuItem {
		const btn = new MenuItem(options);
		if ((btn as MenuItem).itsValid()) {
			this.itens.push(btn as MenuItem);
		}
		return btn;
	}

	pushItem(btn: MenuItem): MenuItem {
		if ((btn as MenuItem).itsValid()) {
			this.itens.push(btn as MenuItem);
		}
		return btn;
	}

	createSubmenu(options: SubmenuOptions) {
		if (options instanceof Object && typeof options.label === "string") {
			let submenu = new Menu();
			submenu.label = options.label;
			submenu.type = "submenu";
			submenu.permissions = Array.isArray(options.permissions) ? options.permissions : [];
			submenu.disabled = options.disabled ?? false;
			submenu.isNew = options.isNew ?? false;
			submenu.hidden = options.hidden ?? false;

			this.itens.push(submenu);

			return submenu;
		}

		return this;
	}

	get simplified(): MenuItem[] {
		let list: MenuItem[] = [];

		this.itens.forEach((iten) => {
			if (iten instanceof Menu) {
				list = list.concat(iten.simplified);
			} else if (iten instanceof MenuItem && iten.simplifiable) {
				list.push(iten);
			}
		});

		return list;
	}

	isItem(): this is MenuItem {
		return this.type === "item";
	}

	clone() {
		const menu = new Menu();
		menu.label = this.label;
		menu.type = this.type;
		menu.itens = this.itens.map((item) => {
			return item.clone();
		});
		menu.permissions = this.permissions;
		menu.disabled = this.disabled;
		menu.isNew = this.isNew;
		menu.hidden = this.hidden;
		return menu;
	}

	map<t = any>(callbackfn: (value: Menu | MenuItem, index: number, array: (Menu | MenuItem)[]) => t) {
		return this.clone()
			.itens.filter((value) => {
				if (value.hidden) {
					return value.permissions.length > 0 && Usuario.verificarPermissao(value.permissions, this.current_permissions);
				}
				return true;
			})
			.map((value) => {
				if (
					value.disabled !== true &&
					value.permissions.length > 0 &&
					Usuario.verificarPermissao(value.permissions, this.current_permissions) !== true
				) {
					value.disabled = true;
				}
				return value;
			})
			.map<t>(callbackfn);
	}
}

export default Menu;
