import React, { useEffect, useState } from "react";

import { UserHelper } from "Helper";
import { Usuario } from "Models";

import { validateUrl } from "Utils";

import { useTheme } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

import Icon from "@mdi/react";
import { mdiPencil } from "@mdi/js";

import "./index.css";

import * as insignias from "./insignias";

const colorList = [
	"#f44336",
	"#E91E63",
	"#9c27b0",
	"#673ab7",
	"#3f51b5",
	"#2196F3",
	"#03a9f4",
	"#00838f",
	"#009688",
	"#4caf50",
	"#8bc34a",
	"#cddc39",
	"#f9a825",
	"#ff9800",
	"#ff5722",
	"#795548",
	"#607d8b",
];

const getInitialsName = (name: string) => {
	let initials = "";
	const nameSplit = name.split(" ");
	const nameLength = nameSplit.length;
	if (nameLength > 1) {
		initials = nameSplit[0].substring(0, 1).toUpperCase();
		initials += nameSplit[1].substring(0, 1).toUpperCase();
	} else if (nameLength === 1) {
		initials = nameSplit[0].substring(0, 1).toUpperCase();
		initials += nameSplit[0].substring(1, 2).toLowerCase();
	} else {
		return "";
	}

	return initials;
};

const createImageFromInitials = (size: number, name: string) => {
	if (name == null) {
		return null;
	}

	name = getInitialsName(name);

	//let indexColor = Math.round((name.split("").map(s=>{let i = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".search(s); return i >= 0 ? i : 0;}).reduce((a, b) => a + b, 0)) / 2) % colorList.length;

	let indexColor =
		Math.round(
			name
				.split("")
				.map((s: string) => {
					return s.charCodeAt(0);
				})
				.reduce((a, b) => a + b, 0) / 2,
		) % colorList.length;

	let color = colorList[indexColor];

	const canvas = document.createElement("canvas");
	const context = canvas.getContext("2d");
	if (!context) {
		return "";
	}
	canvas.width = canvas.height = size;

	context.fillStyle = color;
	context.fillRect(0, 0, size, size);

	context.fillStyle = "#ffffff";
	context.textBaseline = "middle";
	context.textAlign = "center";
	context.font = `${
		size * 0.35
	}px -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`;

	let fix = context.measureText(name).actualBoundingBoxDescent / 2;

	context.fillText(name, size / 2, size / 2 + fix);

	return canvas.toDataURL();
};

const pointsToText = (points: number, maxPoints: number) => {
	const textStyle_0 = [
		"translate(199.63 183.19) rotate(29.15) scale(1.1 1)",
		"translate(205.93 186.71) rotate(24.7) scale(1.1 1)",
		"translate(212.45 189.71) rotate(20.3) scale(1.1 1)",
		"translate(219.21 192.22) rotate(15.96) scale(1.1 1)",
		"translate(226.1 194.2) rotate(11.69) scale(1.1 1)",
		"translate(233.14 195.67) rotate(7.48) scale(1.1 1)",
		"translate(240.23 196.61) rotate(3.33) scale(1.1 1)",
	];

	const textStyle_1 = [
		"translate(252.19 197.03) rotate(-3.33) scale(1.1 1)",
		"translate(259.34 196.6) rotate(-7.48) scale(1.1 1)",
		"translate(266.45 195.65) rotate(-11.69) scale(1.1 1)",
		"translate(273.47 194.19) rotate(-15.96) scale(1.1 1)",
		"translate(280.38 192.19) rotate(-20.3) scale(1.1 1)",
		"translate(287.13 189.68) rotate(-24.7) scale(1.1 1)",
		"translate(293.65 186.67) rotate(-29.15) scale(1.1 1)",
	];

	const p = Math.round(points).toFixed(0);
	const mP = Math.round(maxPoints).toFixed(0);

	let result = [];

	p.split("")
		.reverse()
		.forEach((v, i) => {
			result.push(
				<text
					key={`profilePicture-insignia-path-${result.length}`}
					className="cls-3"
					transform={textStyle_0[textStyle_0.length - (i + 1)]}
				>
					{v}
				</text>,
			);
		});

	result.push(
		<text
			key={`profilePicture-insignia-path-${result.length}`}
			xmlns="http://www.w3.org/2000/svg"
			className="cls-3"
			transform="matrix(1.1, 0.01, -0.01, 1, 247.31, 196.98)"
		>
			/
		</text>,
	);

	mP.split("").forEach((v, i) => {
		result.push(
			<text
				key={`profilePicture-insignia-path-${result.length}`}
				className="cls-4"
				transform={textStyle_1[i]}
			>
				{v}
			</text>,
		);
	});

	return result;
};

const ProfilePicture: React.FC<{
	size?: number;
	onClick?: () => void;
	src?: Usuario | string;
	insignia?: "wings";
	loading?: boolean;
	points?: number;
}> = ({ size, onClick, src, insignia, loading }) => {
	const [user, setUser] = useState(new Usuario());
	const [imgUrl, setImgUrl] = useState<string | null>(null);
	const theme = useTheme();

	useEffect(() => {
		if (src instanceof Usuario) {
			setUser(src);
		} else if (typeof src === "string" && validateUrl(src)) {
			setImgUrl(src as any);
		} else {
			UserHelper.getUser().then((usuario) => {
				setUser(new Usuario().parse(usuario.path, usuario.toJson()));
			}).catch(()=>{
                setUser(new Usuario());
            });
		}
	}, [src]);

	useEffect(() => {
		if (user instanceof Usuario) {
			if (imgUrl === null && user.fotoPerfil && typeof user.fotoPerfil === "string" && validateUrl(user.fotoPerfil)) {
				setImgUrl(user.fotoPerfil);
			} else if (imgUrl === null && user.nome && typeof user.nome === "string") {
				setImgUrl(createImageFromInitials(200, user.nome));
			}
		}
	}, [user]);

	size = size && typeof size === "number" && size > 5 ? size : 50;

	insignia = ["wings"].includes(insignia ?? "") ? insignia : undefined;

	const editable = typeof onClick === "function" && !loading;

	const uploadImage = () => {
		if (!editable) {
			return;
		}
		onClick?.();
	};

	let className_main = ["profilePicture-main"];
	let style_main: {
			width?: string;
			height?: string;
		} = {},
		style_insignia: {
			width?: string;
			color?: string;
		} = {};

	let userLevel = user.getLevel();
	let level = 0;

	if (insignia) {
		level = userLevel.level >= insignias[insignia].length ? insignias[insignia].length - 1 : userLevel.level - 1;

		//level = insignias[insignia].length-1;

		className_main.push("profilePicture-insignia-wings");

		let height = parseFloat(((size * 200) / 120).toFixed(2));
		style_insignia.width = parseFloat(((height * 500) / 200).toFixed(2)) + "px";
		style_main.height = height + "px";

		let color = insignias.getLevelColor(insignia, level + 1);

		style_insignia.color = color[theme.palette.mode === "dark" ? 1 : 0];
	}

	return (
		<div
			className={className_main.join(" ")}
			style={style_main}
		>
			{insignia && (
				<div
					className={"profilePicture-insignia"}
					style={style_insignia}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 500 200"
					>
						{(insignias as any)[insignia][level].map((d: string, i: number) => (
							<path
								key={`profilePicture-insignia-path-${i}`}
								style={{ fill: "currentcolor" }}
								d={d}
							></path>
						))}
						<g xmlns="http://www.w3.org/2000/svg">
							<path
								className="cls-1"
								d="M314.5,149a81,81,0,0,1-129,0"
							/>
							<path
								className="cls-2"
								d="M314.5,149a81,81,0,0,1-129,0"
								style={{
									strokeDashoffset:
										149.23 * ((userLevel.points - userLevel.minPoints) / (userLevel.maxPoints - userLevel.minPoints) - 1),
								}}
							/>
							{pointsToText(userLevel.points, userLevel.maxPoints)}
						</g>
					</svg>
				</div>
			)}
			<div
				className={"profilePicture-content"}
				style={{
					width: size + "px",
					height: size + "px",
					backgroundColor: style_insignia.color || "rgba(0, 0, 0, .3)",
					backgroundImage: typeof imgUrl === "string" ? 'url("' + imgUrl + '")' : "",
				}}
			>
				{editable && (
					<div
						className={"profilePicture-btn"}
						onClick={uploadImage}
					>
						<Icon path={mdiPencil} />
					</div>
				)}
				{loading && (
					<div className={"profilePicture-loading"}>
						<CircularProgress />
					</div>
				)}
			</div>
		</div>
	);
};

export default ProfilePicture;
