import React, { Component } from "react";

import WidgetMain from "Widgets";

const Home = ()=>{
    return <div color={"#ffc107"}>
        <WidgetMain />
    </div>
}

export default Home;