import { createBrowserHistory, History } from "history";

const initialHistory = createBrowserHistory();

let listKeys: string[] = [];
let currentIndex: number = 0;

listKeys.push(typeof initialHistory.location.key === "string" ? initialHistory.location.key : "home");

initialHistory.listen(({ key }) => {
	key = typeof key === "string" ? key : "home";

	if (listKeys.includes(key) !== true) {
		listKeys.push(key);
	}

	currentIndex = listKeys.indexOf(key);
});

const initial = (): History => {
	if (!window.__routerHistory) {
		window.__routerHistory = initialHistory;
	}
	return initialHistory;
};

export const getHistoryLentgth = (): number => {
	return listKeys.length;
};

export const getHistoryCurrentIndex = (): number => {
	return currentIndex;
};

export default initial();
