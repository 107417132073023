import React, { useState, useEffect } from "react";

import { CircularProgress } from "@mui/material";

import AreaChart from "./AreaChart";
import PieChart from "./PieChart";
import ProgressProfile from "./ProgressProfile";
import Comparative from "./Comparative";

import type { AreaChartOptions, AreaChartData } from "./AreaChart";
import type { PieChartOptions, PieChartData } from "./PieChart";
import type { ProgressProfileOptions, ProgressProfileData } from "./ProgressProfile";
import type { ComparativeOptions, ComparativeData } from "./Comparative";
import type { RadarChartOptions, RadarChartData } from "./RadarChart";

import style from "./style.module.scss";
import RadarChart from "./RadarChart";

const DefaultChart = () => {
	return <div></div>;
};

interface Config {
	[p: string]: any;
	chartType: "AreaChart" | "PieChart" | "ProgressProfile" | "Comparative" | "RadarChart";
	height?: string | number;
	width?: string | number;
	style?: React.CSSProperties;
	data: any;
	options?: any;
}

interface ConfigAreaChart extends Config {
	chartType: "AreaChart";
	data: AreaChartData;
	options?: AreaChartOptions;
}

interface ConfigPieChart extends Config {
	chartType: "PieChart";
	data: PieChartData;
	options?: PieChartOptions;
}

interface ConfigProgressProfile extends Config {
	chartType: "ProgressProfile";
	data: ProgressProfileData;
	options?: ProgressProfileOptions;
}

interface ConfigComparative extends Config {
	chartType: "Comparative";
	data: ComparativeData;
	options?: ComparativeOptions;
}

interface ConfigRadarChart extends Config {
	chartType: "RadarChart";
	data: RadarChartData;
	options?: RadarChartOptions;
}

type ComponentConfig = ConfigAreaChart | ConfigPieChart | ConfigProgressProfile | ConfigComparative | ConfigRadarChart;

const Component: React.FC<ComponentConfig> = ({ chartType, height, width, style: oldStyle = {}, ...props }) => {
	const [loading, setLoading] = useState(true);
	const [styleMain, setStyleMain] = useState<React.CSSProperties>({ minWidth: "100px", minHeight: "100px" });

	useEffect(() => {
		setLoading(false);
	}, []);

	useEffect(() => {
		setStyleMain((prev: any) => {
			return {
				...(prev ?? {}),
				height: typeof height === "string" ? height : typeof height === "number" ? `${height}px` : null,
				width: typeof width === "string" ? width : typeof width === "number" ? `${width}px` : null,
			};
		});
	}, [height, width]);

	if (loading) {
		return (
			<div
				className={[style["chartMain"], style["loading"]].join(" ")}
				style={styleMain}
			>
				<CircularProgress color="inherit" />
			</div>
		);
	}

	switch (chartType) {
		case "AreaChart":
			return (
				<div
					className={style["chartMain"]}
					style={{ ...oldStyle, ...styleMain }}
				>
					<AreaChart {...(props as ConfigAreaChart)} />
				</div>
			);
		case "PieChart":
			return (
				<div
					className={style["chartMain"]}
					style={{ ...oldStyle, ...styleMain }}
				>
					<PieChart {...(props as ConfigPieChart)} />
				</div>
			);
		case "ProgressProfile":
			return (
				<div
					className={style["chartMain"]}
					style={{ ...oldStyle, ...styleMain }}
				>
					<ProgressProfile {...(props as any)} />
				</div>
			);
		case "Comparative":
			return (
				<div
					className={style["chartMain"]}
					style={{ ...oldStyle, ...styleMain }}
				>
					<Comparative {...(props as any)} />
				</div>
			);
		case "RadarChart":
			return (
				<div
					className={style["chartMain"]}
					style={{ ...oldStyle, ...styleMain }}
				>
					<RadarChart {...(props as any)} />
				</div>
			);
		default:
			return (
				<div
					className={style["chartMain"]}
					style={{ ...oldStyle, ...styleMain }}
				>
					<DefaultChart />
				</div>
			);
	}
};

export default Component;
