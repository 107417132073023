import React from "react";
import { applyForwardRef, applyStyledProps, useCanvas, usePropsImperativeHandle } from "../Utils";
import { CANVASRectElement } from "../Elements";

export const Rect = applyForwardRef<Partial<CANVASRectElement>, CANVASRectElement>((p, ref) => {
	const [props, setProps] = usePropsImperativeHandle<CANVASRectElement>(ref, p, {
		x: 0,
		y: 0,
		width: 15,
		height: 15,
	});

	useCanvas(
		({ canvas }) => {
			const ctx = canvas?.getContext("2d");
			const { x, y, width, height } = props;

			if (ctx) {
				ctx.save();
				applyStyledProps(ctx, props, (ctx) => {
					ctx.beginPath();
					ctx.fillRect(x, y, width, height);
					ctx.strokeRect(x, y, width, height);
					ctx.fill;
					ctx.closePath();
				});
				ctx.restore();
			}
		},
		[props],
	);

	return null;
});
