import { MultiStorager } from "Utils";

export default class MainFrame {
	static showMenu() {
		MultiStorager.DataStorager.set<boolean>("MainFrame_disableMenu", false);
	}

	static hiddenMenu() {
		MultiStorager.DataStorager.set<boolean>("MainFrame_disableMenu", true);
	}

	static toggleMenu() {
		let state = MultiStorager.DataStorager.get<boolean>("MainFrame_disableMenu");
		state = typeof state !== "boolean" ? true : state;
		MultiStorager.DataStorager.set<boolean>("MainFrame_disableMenu", !state);
	}

	static enableToolbar() {
		MultiStorager.DataStorager.set<boolean>("MainFrame_disableToolbar", false);
	}

	static disableToolbar() {
		MultiStorager.DataStorager.set<boolean>("MainFrame_disableToolbar", true);
	}

	static showThemeSwitchToolbar() {
		MultiStorager.DataStorager.set<boolean>("MainFrame_themeSwitchToolbar", true);
	}

	static hiddenThemeSwitchToolbar() {
		MultiStorager.DataStorager.set<boolean>("MainFrame_themeSwitchToolbar", false);
	}

	static showCurrencyIndex() {
		MultiStorager.DataStorager.set<boolean>("MainFrame_disableCurrencyIndex", false);
	}

	static hiddenCurrencyIndex() {
		MultiStorager.DataStorager.set<boolean>("MainFrame_disableCurrencyIndex", true);
	}

	static showToolbarWallets() {
		MultiStorager.DataStorager.set<boolean>("MainFrame_showWallets", true);
	}

	static hiddenToolbarWallets() {
		MultiStorager.DataStorager.set<boolean>("MainFrame_showWallets", false);
	}

	static showMenuSummary() {
		MultiStorager.DataStorager.set<boolean>("MainFrame_hidden_menu_summary", false);
	}

	static hiddenMenuSummary() {
		MultiStorager.DataStorager.set<boolean>("MainFrame_hidden_menu_summary", true);
	}

	static isMenuSummaryHidden(): boolean {
		return MultiStorager.DataStorager.get<boolean>("MainFrame_hidden_menu_summary") ?? false;
	}

	static initialPages() {
		MainFrame.hiddenMenu();
		MainFrame.hiddenCurrencyIndex();
		MainFrame.enableToolbar();
		MainFrame.hiddenThemeSwitchToolbar();
		MainFrame.hiddenToolbarWallets();
		MainFrame.hiddenMenuSummary();
	}

	static secondaryPages() {
		MainFrame.showMenu();
		MainFrame.showCurrencyIndex();
		MainFrame.enableToolbar();
		MainFrame.hiddenThemeSwitchToolbar();
		MainFrame.showToolbarWallets();
		MainFrame.showMenuSummary();
	}

	static splash() {
		MainFrame.hiddenMenu();
		MainFrame.hiddenCurrencyIndex();
		MainFrame.disableToolbar();
		MainFrame.hiddenThemeSwitchToolbar();
		MainFrame.hiddenToolbarWallets();
		MainFrame.hiddenMenuSummary();
	}
}
