import { UserHelper } from 'Helper';
import { Usuario } from 'Models';
import { Messages } from 'Crucial';
import { validateEmail, validatePhone } from "Utils";

const validatePassword = (value)=>{
    try{
        if(value.length < 8){
            return Promise.reject("A senha deve ser superior ou igual a 8 (oito) caracteres!");
        }
        
        if(!((/[0-9]/).test(value) && (/[a-zA-Z]/).test(value))){
            return Promise.reject("A senha deve ser alfanumérica (letras e números)!");
        }

        if(!(/[A-Z]/).test(value)){
            return Promise.reject("A senha deve conter pelo menos uma letra maiúscula!");
        }

        if(!(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/).test(value)){
            return Promise.reject("A senha deve conter pelo menos um caractere especial (@, $, !, &, etc)!");
        }
        return Promise.resolve(true);
    }catch{
        return Promise.reject("Senha inválida!");
    }
}

export default {
    "id": "login_bot",
    "start": function(){
        return {
            message: [
                "Olá, bem vindo ao Painel iVip Coin 🤩",
                "Toda vez que quiser sair de uma etapa do diálogo, digite \"Sair\" ou \"Cancelar\""
            ],
            next: "stage_01"
        }
    },
    "onChange": function(msg){
        msg = String(msg).trim().toLowerCase();
        if(["cancelar", "sair"].includes(msg)){
            return {
                next: "stage_01"
            }
        }
    },
    "stage_01": function(){
        return {
            message: "Em quê posso ser útil?",
            options: [
                {
                    label: "Entrar",
                    next: "stage_02"
                },
                {
                    label: "Cadastrar",
                    next: "stage_05"
                },
                {
                    label: "Restaurar senha",
                    next: "stage_04"
                }
            ]
        }
    },
    "stage_02": function(){
        return {
            type: "email",
            message: "Digite seu e-mail:",
            onChange: async (value, next)=>{
                value = String(value).trim();
                if(validateEmail(value)){
                    this.email = value;

                    const emailAvailable = await UserHelper.emailAvailable(this.email);

                    if(typeof emailAvailable !== "boolean"){
                        return next("internal_error");
                    }else if(!emailAvailable){
                        return {
                            message: "E-mail inexistente 😰",
                            next: "stage_01"
                        }
                    } 

                    return next("stage_03");
                }

                return {
                    message: "E-mail inválido, tente novamente!",
                    next: "stage_02"
                }
            }
        }
    },
    "stage_03": function(){
        return {
            type: "password",
            message: "Digite sua senha:",
            onChange: async (value, next)=>{
                let error = "";

                const isLogin = await UserHelper.login(this.email, value).then(()=> Promise.resolve(true)).catch((e)=> {
                    error = e.message;
                    return false;
                });

                if(!isLogin){
                    return {
                        message: error,
                        next: "stage_01"
                    }
                }
            }
        }
    },
    "stage_04": function(){
        return {
            message: "Desculpe, no momento esta opção não é possível 😥",
            next: "stage_01"
        }
    },
    "stage_05": function(){
        this.values = {};

        return {
            message: [
                "Olá olá olá 🤩", 
                "Estamos muito contentes em saber que está interessado em fazer parte da iVip",
                "Nesse processo de cadastro, preste bastante atenção ao preencher os dados 🔍",
                "Enquanto a questão de segurança e proteção de dados, fique tranquilo. \nPossuímos a certificação SSL 🔐 e seguimos com os termos da Lei Geral de Proteção de Dados (LGPD) 📃"
            ],
            next: "stage_06"
        }
    },
    "stage_06": function(){
        return {
            message: "Para começar, me diga o seu nome completo",
            onChange: (value, next)=>{
                this.values.nome = String(value).trim();

                if(this.values.nome.length < 4){
                    return {
                        message: "Não entendi 😕",
                        next: "stage_06"
                    }
                }

                return {
                    message: `Prazer em te conhecer ${this.values.nome.split(" ").shift().toUpperCase()} 😁`,
                    next: "stage_07"
                }
            }
        }
    },
    "stage_07": function(){
        return {
            message: "Digite o seu e-mail:",
            onChange: async (value, next)=>{
                value = String(value).trim();

                if(validateEmail(value)){
                    this.values.email = value;

                    const emailAvailable = await UserHelper.emailAvailable(this.values.email);

                    if(typeof emailAvailable !== "boolean"){
                        return next("internal_error");
                    }else if(emailAvailable){
                        return {
                            message: "Que pena, infelizmente esse e-mail já existe, tente novamente!",
                            next: "stage_07"
                        }
                    } 

                    return next("stage_08");
                }

                return {
                    message: "E-mail inválido, tente novamente!",
                    next: "stage_07"
                }
            }
        }
    },
    "stage_08": async function(){
        this.values.atSign = this.values.email.split("@").shift().replace(/\s/ig, "_");

        const atSignExists = await UserHelper.atSignAvailable(this.values.atSign);

        if(typeof atSignExists !== "boolean"){
            return {
                next: "internal_error"
            }
        }else if(atSignExists){
            return {
                message: "Na plataforma da iVip, possui um sistema de login e identificação dos usuários, o tão famoso arroba",
                next: "stage_09"
            }
        }

        return {
            message: [
                "Na plataforma da iVip, possui um sistema de login e identificação dos usuários, o tão famoso arroba",
                `Sugerimos que utilize o mesmo do seu e-mail: ${this.values.atSign}`,
                "Deseja manter?"
            ],
            options: [
                {
                    label: "Sim",
                    next: "stage_10"
                },
                {
                    label: "Não",
                    next: "stage_09"
                }
            ]
        }
    },
    "stage_09": function(){
        return {
            message: "Digite o seu arroba",
            onChange: async (value, next)=>{
                this.values.atSign = String(value).trim();

                if((/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/).test(this.values.atSign) || (/\s/).test(this.values.atSign)){
                    return {
                        message: [
                            "Esse arroba não é válido 😟",
                            "Lembre-se que o seu arroba não pode possuir espaços e nem caracteres especiais (@, $, !, &, etc)!"
                        ],
                        next: "stage_09"
                    }
                }

                const atSignExists = await UserHelper.atSignAvailable(this.values.atSign).catch(()=> null);

                if(typeof atSignExists !== "boolean"){
                    return next("internal_error");
                }else if(atSignExists){
                    return {
                        message: "Que pena, infelizmente esse arroba já existe, tente novamente!",
                        next: "stage_09"
                    }
                } 

                next("stage_10");
            }
        }
    },
    "stage_10": function(){
        return {
            message: "Digite o seu telefone de contato",
            onChange: async (value, next)=>{
                this.values.telefone = String(value).replace(/\D/gi, "");

                if(!validatePhone(this.values.telefone)){
                    return {
                        message: "Número de telefone incorreto, tente novamente!",
                        next: "stage_10"
                    }
                }

                const [telefone, ddd, d, g1, g2] = this.values.telefone.match(/^(\d{2})(\d{1})?(\d{4})(\d{4})$/);
                this.values.telefone = `(${ddd}) ${d || "9"} ${g1}-${g2}`;

                next("stage_11");
            }
        }
    },
    "stage_11": function(){
        return {
            type: "password",
            message: [
                "Agora define a sua senha",
                "Lembre-se dos requisitos necessários ao criar uma senha:\n\n● A senha deve ser superior ou igual a 8 (oito) caracteres!\n● A senha deve ser alfanumérica (letras e números)!\n● A senha deve conter pelo menos uma letra maiúscula!\n● A senha deve conter pelo menos um caractere especial (@, $, !, &, etc)!"
            ],
            onChange: async (value, next)=>{
                let error = "Senha inválida!";

                const valid = await validatePassword(value).catch((msg)=>{
                    error = msg;
                });

                if(valid !== true){
                    return {
                        message: error,
                        next: "stage_11"
                    }
                }

                this.password = value;

                next("stage_12");
            }
        }
    },
    "stage_12": function(){
        return {
            type: "password",
            message: "Digite novamente a senha que definiu",
            onChange: async (value)=>{
                if(value !== this.password){
                    return {
                        message: [
                            "As senhas que informou não coincidem!",
                            "Vamos tentar novamente?"
                        ],
                        next: "stage_11"
                    }
                }

                this.password = value;

                return {
                    message: "Agora, aguarde um instante que iremos criar sua conta de acesso 😁",
                    next: "stage_13"
                }
            }
        }
    },
    "stage_13": async function(){
        let user = new Usuario().parse(null, this.values);
        let error = "Ocorreu um Erro ao tentar criar a conta de usuário 😟";

        user = await UserHelper.criarUsuario(user, this.password).catch((e) => {
            error = e.message;
            return null;
        });

        if(!user || !(user instanceof Usuario)){
            return {
                message: [
                    error,
                    "Vamos tentar novamente?"
                ],
                next: "stage_06"
            }
        }

        return {
            message: [
                "Conta criada com sucesso 🥳",
                "Agradecemos muito pela sua paciência no processo de cadastro e por se associar ao iVip 🤩",
                "A etapa de cadastramento foi finalizado 😌"
            ],
            next: "stage_01"
        }
    },
    "internal_error": function(){
        return {
            message: [
                "Desculpe, houve um erro interno em que no momento não será possível resolver 😰",
                "Tente novamente mais tarde..."
            ],
            next: "stage_01"
        }
    }
}