import React, { useEffect, useRef } from 'react'

declare global {
  interface Window {
    TradingView: any
  }
}

let tvScriptLoadingPromise: Promise<Event> | null

const TradingViewWidget: React.FC = () => {
  const onLoadScriptRef = useRef<(() => void) | null>(null)

  useEffect(() => {
    onLoadScriptRef.current = createWidget

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script')
        script.id = 'tradingview-widget-loading-script'
        script.src = 'https://s3.tradingview.com/tv.js'
        script.type = 'text/javascript'
        script.onload = resolve

        document.head.appendChild(script)
      })
    }

    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current()
    )

    return () => {
      onLoadScriptRef.current = null
    }
  }, [])

  const createWidget = () => {
    if (
      document.getElementById('tradingview_552d9') &&
      'TradingView' in window
    ) {
      new window.TradingView.widget({
        width: '100%',
        height: 700,
        symbol: 'PANCAKESWAP:IVIPBTCB_93A441',
        interval: 'D',
        timezone: 'America/Sao_Paulo',
        theme: 'dark',
        style: '1',
        locale: 'br',
        toolbar_bg: '#f1f3f6',
        enable_publishing: false,
        allow_symbol_change: true,
        container_id: 'tradingview_552d9',
      })
    }
  }

  return (
    <div className="tradingview-widget-container">
      <div id="tradingview_552d9" />
      <div className="tradingview-widget-copyright">
        <a
          href="https://br.tradingview.com/"
          rel="noopener nofollow"
          target="_blank"
        ></a>
      </div>
    </div>
  )
}

export default TradingViewWidget
