export type severity = "error" | "info" | "success" | "warning";

export interface InteractionElementProps {
	alert: {
		title: string | null | undefined;
		body: string | JSX.Element;
		onClose?: (confirmed: boolean) => void;
	};
	confirm: {
		title: string | null | undefined;
		body: string | JSX.Element;
		term?: string | null;
		btnNames?: [string] | [string, string];
		onClose?: (confirmed: boolean) => void;
	};
	prompt: {
		title: string | null | undefined;
		body: string | JSX.Element | Array<string | JSX.Element>;
		value: string;
		term?: string | null;
		btnNames?: [string] | [string, string];
		onClose?: (value: string) => void;
	};
	toast: {
		title: string | null | undefined;
		message: string | JSX.Element;
		severity?: severity;
		duration: number;
		onClose?: boolean | (() => void) | null;
		id?: number;
	};
	loading: {};
	uploadImage: {
		imageSize: number | null;
		aspectRatio: number | null;
		onClose?: (image: string | null) => void;
	};
}

export const types = {
	alert: ({ title, body, onClose }: InteractionElementProps["alert"]) => {
		title = title || null;
		body = body || "";
		onClose = typeof onClose === "function" ? onClose : () => {};
		return { title, body, onClose };
	},
	confirm: ({ title, body, term, btnNames, onClose }: InteractionElementProps["confirm"]) => {
		title = title || null;
		body = body || "";
		term = typeof term === "string" && term.trim() ? term : null;
		onClose = typeof onClose === "function" ? onClose : () => {};
		return { title, body, term, btnNames, onClose };
	},
	prompt: ({ title, body, value, term, btnNames, onClose }: InteractionElementProps["prompt"]) => {
		title = title || null;
		body = body || "";
		value = value || "";
		term = typeof term === "string" && term.trim() ? term : null;
		onClose = typeof onClose === "function" ? onClose : () => {};
		return { title, body, value, term, btnNames, onClose };
	},
	toast: ({ title, message, severity, duration, onClose, id }: InteractionElementProps["toast"]) => {
		title = title || null;
		message = message || "";
		severity = severity && ["error", "info", "success", "warning"].includes(severity) ? severity : "info";
		duration = typeof duration === "number" ? duration : 5000;
		onClose = typeof onClose === "function" ? onClose : typeof onClose === "boolean" && onClose === true ? () => {} : null;
		id = typeof id === "number" ? id : Math.round(Math.random() * 100000);
		return { title, message, severity, duration, onClose, id };
	},
	loading: (props: InteractionElementProps["loading"]) => {
		return {};
	},
	uploadImage: ({ imageSize, aspectRatio, onClose }: InteractionElementProps["uploadImage"]) => {
		imageSize = imageSize ? imageSize : null;
		aspectRatio = aspectRatio ? aspectRatio : null;
		onClose = typeof onClose === "function" ? onClose : () => {};
		return { imageSize, aspectRatio, onClose };
	},
};

export type InteractionTypes = keyof typeof types;
export const listInteractionTypes = Object.keys(types) as Array<InteractionTypes>;

export const getConfBy = <T extends keyof typeof types>(type: T, props: InteractionElementProps[T]): ReturnType<(typeof types)[T]> => {
	return type in types ? types[type](props as any) : ({} as any);
};

export const getStackInfo = (): Array<{
	at: string;
	file: string;
	line: number;
	column: number;
}> => {
	try {
		throw new Error();
	} catch (e) {
		if ((e as any).stack) {
			const stack: string[] = ((e as any).stack as string)
				.split("\n")
				.slice(1)
				.map((line) => line.trim());

			// console.trace();
			// console.log(stack);

			return stack.map((l) => {
				const [trace = "", file = "", line = "", column = ""] = l.match(/\((.*):(\d+):(\d+)\)$/) ?? [];

				if ((file + line + column).trim() !== "") {
					return { at: l.replace(trace, "").replace(/^(at)/gi, "").trim(), file, line: parseInt(line), column: parseInt(column) };
				} else {
					return {
						at: l
							.replace(/\(.+\)/gi, "")
							.replace(/^(at)/gi, "")
							.trim(),
						file: "anonymous",
						line: 0,
						column: 0,
					};
				}
			});
		} else {
			return [];
		}
	}
};
