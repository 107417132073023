import React from "react";

import style from "./style.module.scss";
import { Color } from "ivip-utils";

const ModernCard: React.FC<
	React.HTMLAttributes<HTMLDivElement> & {
		color?: string;
		image: string;
		colorInImage?: boolean;
		imageInView?: boolean;
		header: React.ReactNode;
		headerStyle?: React.CSSProperties;
		className?: string;
	}
> = ({ className, header, color, image, colorInImage, imageInView, headerStyle, ...props }) => {
	const textColor = (({ vector }) => (vector.reduce((a, b) => a + b, 0) / vector.length > 100 ? "#212121" : "#fafafa"))(
		new Color(color ?? "#e0e0e0"),
	);

	return (
		<div
			{...props}
			className={[style.card, className ?? ""].join(" ")}
			style={
				{
					...(props.style ?? {}),
					"--color": color,
					"--color-rgb": new Color(color).vector.join(", "),
					"--text-color": textColor,
				} as React.CSSProperties & { [p: string]: string }
			}
		>
			<div>
				<div
					className={style["card-header"]}
					style={headerStyle}
				>
					{header}
				</div>
				<div className={[style["card-image"], imageInView ? style["card-image-in-view"] : ""].join(" ")}>
					<div
						style={{
							backgroundColor: colorInImage || image.trim() === "" ? color : "transparent",
							backgroundImage: "url(" + image + ")",
						}}
					></div>
				</div>
			</div>
		</div>
	);
};

export default ModernCard;
