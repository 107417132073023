export const formatDate = (date: Date): string => {
	const today = new Date();
	today.setHours(0);
	today.setMinutes(0);
	today.setSeconds(0);
	today.setMilliseconds(0);
	const yesterday = new Date(today);
	yesterday.setDate(today.getDate() - 1);
	const twoDaysAgo = new Date(yesterday);
	twoDaysAgo.setDate(yesterday.getDate() - 1);

	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	const hour = date.getHours();
	const minute = date.getMinutes();

	if (date >= today) {
		return `Hoje às ${hour}:${minute.toString().padStart(2, "0")}`;
	} else if (date >= yesterday) {
		return `Ontem às ${hour}:${minute.toString().padStart(2, "0")}`;
	} else if (date >= twoDaysAgo) {
		return `Anteontem às ${hour}:${minute.toString().padStart(2, "0")}`;
	} else {
		return `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year} às ${hour}:${minute.toString().padStart(2, "0")}`;
	}
};

export const limitString = (str: string, limit: number) => (str.length <= limit ? str : str.slice(0, limit) + "... ");

export const isDate = function (value: any): value is Date {
	return (
		value instanceof Date ||
		(typeof value === "string" && !isNaN(Date.parse(value))) ||
		(typeof value === "number" && !isNaN(new Date(value).getTime()))
	);
};

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;
export const assignObj = <T extends object, U extends object[]>(target: T, ...sources: U): T & UnionToIntersection<U[number]> => {
	for (let source of sources) {
		for (let key in source) {
			if (source.hasOwnProperty(key)) {
				if (
					typeof (source as any)[key] === "object" &&
					(source as any)[key] !== null &&
					typeof (target as any)[key] === "object" &&
					(target as any)[key] !== null
				) {
					assignObj((target as any)[key], (source as any)[key]);
				} else {
					(target as any)[key] = (source as any)[key];
				}
			}
		}
	}
	return target as any;
};
