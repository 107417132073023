import GamePrepared from "Pages/Quiz/Components/GamePrepared";
import React from "react";

const Game: React.FC<{
	sectionId: string;
	unitId: string;
	levelId: string;
}> = (props) => {
	return <GamePrepared {...props} />;
};

export default Game;
