import React, { useState, useEffect, useRef, useCallback } from "react";
import NavigationBar from "./NavigationBar";
import { Color } from "ivip-utils";

import style from "./index.module.scss";

const setLightness = (color: Color, sum: number): Color => {
	const [h, s, l] = color.props.hsl;
	sum = sum < 0 ? 1 - Math.abs(sum) : 1 + sum;
	return new Color(Color.hsl(h, s, Math.min(100, Math.max(0, l * sum))).hex);
};

const PageHeaderWrapper: React.FC<
	Partial<{
		isLoading: boolean;
		title: string | React.ReactNode;
		color: string | Color;
		colorDark: string | Color;
		colorLight: string | Color;
		showNavigationBar: boolean;
		header: React.ReactNode;
		headerStyle: React.CSSProperties;
		content: React.ReactNode;
		contentStyle: React.CSSProperties;
		children: React.ReactNode;
		disableGradient: boolean;
	}>
> = ({
	isLoading = false,
	title,
	color = "#212121",
	colorDark,
	colorLight,
	showNavigationBar = true,
	header,
	headerStyle,
	content,
	contentStyle,
	children,
	disableGradient = false,
}) => {
	const [containerColor, setContainerColor] = useState<{ dark: Color; light: Color }>({
		dark: new Color("#212121"),
		light: new Color("#757575"),
	});
	const refRoot = useRef<HTMLDivElement | null>(null);
	const refAnimateTime = useRef<NodeJS.Timer>();

	useEffect(() => {
		clearInterval(refAnimateTime.current as any);

		let theme = {
			dark: new Color("#212121"),
			light: new Color("#757575"),
		};

		if (typeof color === "string" || color instanceof Color) {
			theme = {
				dark: setLightness(typeof color === "string" ? new Color(color) : color, 0.3),
				light: setLightness(typeof color === "string" ? new Color(color) : color, 0.15),
			};
		}

		if (typeof colorDark === "string" || colorDark instanceof Color) {
			theme.dark = typeof colorDark === "string" ? new Color(colorDark) : colorDark;
		}

		if (typeof colorLight === "string" || colorLight instanceof Color) {
			theme.light = typeof colorLight === "string" ? new Color(colorLight) : colorLight;
		}

		if (containerColor.dark.hex === theme.dark.hex && containerColor.light.hex === theme.light.hex) {
			return;
		}

		const duration = 100,
			increment = 20;
		let currentTime = 0;

		refAnimateTime.current = setInterval(() => {
			if (currentTime > duration) {
				clearInterval(refAnimateTime.current as any);
				setContainerColor(() => theme);
				return;
			}

			if (refRoot.current) {
				const colorDark = theme.dark.blend(containerColor.dark.hex, currentTime / duration);
				const colorLight = theme.light.blend(containerColor.light.hex, currentTime / duration);

				refRoot.current.style.setProperty("--color-dark", colorDark.vector.join(", "));
				refRoot.current.style.setProperty("--color-light", colorLight.vector.join(", "));
			}

			currentTime += increment;
		}, increment);
	}, [color, colorDark, colorLight, containerColor]);

	const updateScrolling = useCallback(() => {
		if (refRoot.current) {
			const scrollTop = refRoot.current.parentElement?.scrollTop ?? 0;
			const navigationBar = refRoot.current.querySelector(`.${style.navigationBar}`);
			if (scrollTop > 20) {
				if (!navigationBar?.classList.contains(style.active)) navigationBar?.classList.add(style.active);
			} else {
				navigationBar?.classList.remove(style.active);
			}
		}
	}, [refRoot, disableGradient]);

	useEffect(() => {
		if (disableGradient) {
			if (refRoot.current) {
				const navigationBar = refRoot.current.querySelector(`.${style.navigationBar}`);
				navigationBar?.classList.add(style.active);
			}
			return;
		}

		const onScroll: EventListener = (e) => {
			updateScrolling();
		};

		refRoot.current?.parentElement?.addEventListener("scroll", onScroll);

		return () => {
			refRoot.current?.parentElement?.removeEventListener("scroll", onScroll);
		};
	}, [refRoot, updateScrolling, disableGradient]);

	const textColorDark = new Color(
		containerColor.dark.vector.reduce((a, b) => a + b, 0) / containerColor.dark.vector.length > 100 ? "#212121" : "#fafafa",
	);
	const textColorLight = new Color(
		containerColor.light.vector.reduce((a, b) => a + b, 0) / containerColor.light.vector.length > 100 ? "#212121" : "#fafafa",
	);

	return (
		<div
			ref={refRoot}
			className={style.root}
			style={
				{
					"--color-dark": containerColor.dark.vector.join(", "),
					"--color-light": containerColor.light.vector.join(", "),
					"--text-color-dark": textColorDark.vector.join(", "),
					"--text-color-light": textColorLight.vector.join(", "),
				} as React.CSSProperties
			}
		>
			{showNavigationBar && (
				<NavigationBar
					isLoading={isLoading}
					title={title}
					autoActive={!disableGradient}
				/>
			)}
			{header && !isLoading && (
				<div
					className={style.header}
					style={headerStyle}
				>
					{header}
				</div>
			)}
			<div
				className={[style.content, disableGradient ? style.disabled : ""].join(" ")}
				style={contentStyle}
			>
				{!isLoading && content}
				{!isLoading && children}
			</div>
		</div>
	);
};

export default PageHeaderWrapper;
