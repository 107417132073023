import Button from "@mui/material/Button";

import "./style.css";

export default {
    id: "Tela_1",
    title: "Tela 1",
    render: () => {
        return (
            <div className="mover-grid-from-scren-one">
                <div className="cex-todos-dex">
                    <div className="cex-one">
                        <Button variant="text">CEX</Button>
                    </div>
                    <div className="todos-one">
                        <Button variant="text">TODOS</Button>
                    </div>
                    <div className="Vender-up">
                        <Button variant="text">DEX</Button>
                    </div>
                </div>
                <div className="valores-from-btc">
                    <div className="other-valor-btc">0.263416498 BTC </div>
                    <div className="movendo-valor-btc">~=R$ 165.174,25</div>
                </div>
                <div className="grupo-buton-one">
                    <div className="buton-indvidual-btc-primeirao">
                        <Button variant="text" color="warning">
                            BTC
                        </Button>
                    </div>
                    <div className="buton-indvidual-usdt-primeiro">
                        <Button variant="text">USDT</Button>
                    </div>
                </div>
                <div className="grind-and-responsividade">
                    <div className="grind-and-responsividade-one">
                        0.263416498
                    </div>
                    <div className="grind-and-responsividade-one-btc">BTC</div>
                    <div className="valoreS">~=R$ 165.174,25</div>
                    <div className="valoR">(+10.00%)</div>
                </div>
                <div className="grupo-buton-two">
                    <div className="buton-indvidual-btc-two-umD">
                        <Button variant="text" color="warning">
                            1D
                        </Button>
                    </div>
                    <div className="buton-indvidual-usdt-umS">
                        <Button variant="text">1S</Button>
                    </div>
                    <div className="buton-indvidual-usdt-two-child">
                        <Button variant="text">1M</Button>
                    </div>
                </div>
            </div>
        );
    },
};
