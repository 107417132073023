import React from "react";
import { RankTypes } from "Types/Quiz";
import { QuizHelper } from "Helper";

interface InsigniaProps {
	[p: string]: any;
	type?: RankTypes;
	depth?: number;
	style?: React.CSSProperties;
}

const styleDefault: React.CSSProperties = {
	position: "relative",
	width: "100%",
	height: "100%",
};

const InsigniaImage: React.FC<InsigniaProps> = ({ type = "bronze", depth = 0, style, ...props }) => {
	return (
		<div
			data-depth={depth}
			{...props}
			style={{ ...styleDefault, ...style }}
		>
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					width: "90%",
					height: "90%",
					transform: "translate(-50%, -50%)",
					backgroundImage: `url(${QuizHelper.insigniasImage[type]})`,
					backgroundSize: "contain",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
				}}
			></div>
		</div>
	);
};

export default InsigniaImage;
