import { uniqueid } from "Utils";

export interface MessageContextSettings {
	message: string;
	value?: number | string;
	suggestions?: Array<{
		value: string | number;
		message: string;
	}>;
}

type MessageType = "text" | "option" | "email" | "password" | "tel";

export interface MessageSettings {
	type: MessageType;
	context: MessageContextSettings;
	userId: string;
	roomId: string;
	isMe?: boolean;
	timestamp?: number | Date;
}

export class Message {
	readonly type: MessageType;

	readonly context: MessageContextSettings;

	readonly timestamp: number;

	readonly roomId: string;

	readonly userId: string;

	constructor(props: MessageSettings) {
		const { type, context, userId, roomId } = props;

		this.type = type;
		this.context = context;
		this.timestamp = Date.now();
		this.userId = userId;
		this.roomId = roomId;
	}

	toJSON() {
		return {
			type: this.type,
			context: this.context,
			timestamp: this.timestamp,
			userId: this.userId,
			roomId: this.roomId,
		};
	}
}

interface MessageBotProcessFunBase {
	type: string;
	message: string | string[];
	next?: string;
	processMoment?: number;
	isRedirected?: boolean;
}

type propsProcess = void | MessageBotProcessFun | Promise<MessageBotProcessFun>;

interface MessageBotProcessFunWithType extends MessageBotProcessFunBase {
	type: "text" | "email" | "password" | "tel";
}

interface MessageBotProcessFunWithOption extends MessageBotProcessFunBase {
	type: "option";
	options: Array<{
		label: string;
		next: string;
		processMoment?: number;
		nextProps?: { [key: string]: any };
		action?(): propsProcess;
	}>;
}

export type MessageBotProcessFun = MessageBotProcessFunWithOption | MessageBotProcessFunWithType;

type processes = Array<(msg?: string) => propsProcess>;

export interface MessageBotSettings {
	id: string;
	processes?: processes;
	userId: string;
	roomId: string;
}

export type MessageBotNextFun = (id: string, processMoment?: number, messages?: string[]) => Promise<MessageBotProcessFun>;

export interface MessageBotInstance {
	back(messages?: string[]): Promise<MessageBotProcessFun>;
	next(messages?: string[]): Promise<MessageBotProcessFun>;
	dispatch: MessageBotNextFun;
	props: {
		[key: string]: any;
	};
	values: {
		[key: string]: any;
	};
}

export type MessageBotFlowProcessFun = MessageBotProcessFun;

interface MessageBotHistory {
	processId: string;
	values: Object;
	messages: Message[];
	isWaitingForMessage: boolean;
	lastMessage?: Message;
	next: string;
	processMoment?: number;
	processProps: MessageBotProcessFun;
}

export class MessageBot {
	private processes: processes;
	readonly processId: string;

	constructor(readonly config: MessageBotSettings) {
		this.processId = config.id;
		this.processes = config.processes ?? [];
	}

	pushProcess(process: (msg?: string) => propsProcess) {
		this.processes.push(process);
	}

	lengthProcesses(): number {
		return this.processes.length;
	}

	process(instance: MessageBotInstance, processMoment: number = 0, msg: string = ""): Promise<MessageBotHistory> {
		const { userId, roomId } = this.config;

		return new Promise(async (resolve, reject) => {
			const configMessageList: MessageSettings[] = [];
			let messages: Message[] = [];
			let propsProcess: MessageBotProcessFun;

			try {
				propsProcess = (await this.processes[processMoment].apply(instance, [msg])) as MessageBotProcessFun;
				if (!propsProcess) {
					throw "Houve um erro ao processar a mensagem!";
				}
			} catch (e) {
				propsProcess = {
					type: "text",
					message: String(e),
					next: "start",
				};
			}

			propsProcess.message = Array.isArray(propsProcess.message) ? propsProcess.message : [propsProcess.message];

			for (let strMessage of propsProcess.message) {
				configMessageList.push({
					type: "text",
					context: {
						message: strMessage,
					},
					userId,
					roomId,
				});
			}

			if (configMessageList.length) {
				configMessageList[configMessageList.length - 1].type = propsProcess.type;

				if (propsProcess.type === "option" && Array.isArray(propsProcess.options)) {
					configMessageList[configMessageList.length - 1].context.suggestions = propsProcess.options.map(
						({ label, next, action }: { label: string; next: string; action?(): propsProcess }, i: number) => ({
							value: i,
							message: label,
						}),
					);
				}

				messages = configMessageList.map((conf: MessageSettings) => new Message(conf));
			}

			const next = propsProcess.next;

			propsProcess.next = propsProcess.next ? propsProcess.next : this.processId;

			propsProcess.processMoment =
				typeof next === "string" && typeof propsProcess.processMoment === "number"
					? propsProcess.processMoment % this.processes.length
					: typeof next === "string" && next !== this.processId
					? 0
					: processMoment;

			resolve({
				processId: this.config.id,
				values: instance.values,
				messages,
				lastMessage: messages.slice(-1).pop(),
				next: propsProcess.next ?? "",
				processMoment: propsProcess.processMoment,
				processProps: propsProcess,
				isWaitingForMessage: (propsProcess.next === this.processId ? propsProcess.processMoment : processMoment) < this.processes.length - 1,
			});
		});
	}
}

export default class ChatBot {
	readonly roomId: string;
	private flow: Array<MessageBot> = [];

	private processMoments: Array<string> = [];

	private history: Array<MessageBotHistory> = [];

	private props: {
		[key: string]: any;
	} = {};

	private values: {
		[key: string]: any;
	} = {};

	public onMenssage: null | undefined | ((messages: Message[]) => any | Promise<any>) = null;

	constructor(readonly id: string) {
		this.roomId = `chat_bot_${this.id}`;
		this.push("start", () => {});
	}

	hasProcessId(id: string = ""): boolean {
		return String(id).trim() !== "" && this.flow.findIndex(({ processId }) => processId === id) >= 0;
	}

	clear() {
		this.processMoments = [];
		this.history = [];
		this.props = {};
		this.values = {};
	}

	init(): Promise<Message[]> {
		this.clear();
		return this.next("start", 0);
	}

	private removeDuplicatesByProcessId() {
		const uniqueFlow: any[] = [];
		const uniqueProcessIds: Set<string> = new Set();

		for (let i = this.flow.length - 1; i >= 0; i--) {
			const { processId } = this.flow[i];
			if (!uniqueProcessIds.has(processId)) {
				uniqueFlow.unshift(this.flow[i]);
				uniqueProcessIds.add(processId);
			}
		}

		this.flow = uniqueFlow;
	}

	push(id: string, ...processes: processes): MessageBot {
		const process: MessageBot = new MessageBot({
			id,
			userId: this.roomId,
			roomId: this.roomId,
			processes,
		});
		this.flow.push(process);
		this.removeDuplicatesByProcessId();
		return process;
	}

	containsProcesses(id: string): boolean {
		return this.flow.findIndex(({ processId }) => processId === id) >= 0;
	}

	private getInstance(memoryProcess: string[] = []): MessageBotInstance {
		const self = this;

		const dispatch = async (id: string, processMoment: number = 0, messages: string[] = []): Promise<MessageBotProcessFun> => {
			const nextMessages: Message[] = await this.next(id, processMoment, undefined, memoryProcess, false);
			const lastMessage: Message | undefined = nextMessages.slice(-1).pop();
			const lastHistoryMessage: MessageBotHistory | undefined = this.history.slice(-1).pop();

			const parseMessages: MessageBotProcessFun = {
				type: "text",
				message: "Houve um erro ao processar a mensagem!",
				next: "start",
				isRedirected: true,
			};

			if (nextMessages.length && lastMessage && lastHistoryMessage) {
				(parseMessages.type as MessageType) = lastMessage.type as MessageType;
				parseMessages.message = Array.prototype.concat.apply(
					messages,
					nextMessages.map(({ context: { message } }) => message),
				);

				if ((parseMessages.type as MessageType) === "option") {
					(parseMessages as any).options = (lastHistoryMessage.processProps as any).options;
				}

				parseMessages.next = lastHistoryMessage.next;
				parseMessages.processMoment = lastHistoryMessage.processMoment;

				lastHistoryMessage.processId = id;
				lastHistoryMessage.processMoment = processMoment;

				this.history.pop();
				this.history.push(lastHistoryMessage);
			}

			return parseMessages;
		};

		return {
			back: async (messages: string[] = []): Promise<MessageBotProcessFun> => {
				const { processId, processMoment, maxProcessMoment } = self.parseStageMoment(this.processMoments.slice(-1).pop());
				return await dispatch(processId as string, (processMoment - 1 + maxProcessMoment + 1) % (maxProcessMoment + 1), messages);
			},
			next: async (messages: string[] = []): Promise<MessageBotProcessFun> => {
				const { processId, processMoment, maxProcessMoment } = self.parseStageMoment(this.processMoments.slice(-1).pop());
				return await dispatch(processId as string, (processMoment + 1) % (maxProcessMoment + 1), messages);
			},
			dispatch: dispatch,
			props: new Proxy(this.props, {
				get: (target: Object, prop: string, receiver: any) => {
					return Reflect.get(target, prop, receiver);
				},
				set: (obj: Object, prop: string, value: any) => {
					self.props[prop] = value;
					return Reflect.set(obj, prop, value);
				},
			}),
			values: new Proxy(this.values, {
				get: (target: Object, prop: string, receiver: any) => {
					return Reflect.get(target, prop, receiver);
				},
				set: (obj: Object, prop: string, value: any) => {
					self.values[prop] = value;
					return Reflect.set(obj, prop, value);
				},
			}),
		};
	}

	isWaitingForMessage(): boolean {
		const { processMoment, maxProcessMoment } = this.parseStageMoment();
		return processMoment < maxProcessMoment;
	}

	next(id?: string, processMoment: number = 0, msg: string = "", memoryProcess: string[] = [], callOnMessage: boolean = true): Promise<Message[]> {
		return new Promise(async (resolve) => {
			if (memoryProcess.indexOf(`${id}::${processMoment}`) >= 0) {
				return resolve([]);
			}

			id =
				this.flow.findIndex(({ processId }) => processId === id) >= 0
					? id
					: history.length <= 0
					? "start"
					: this.history
							.slice()
							.reverse()
							.find(({ next }) => next in this.flow)?.next;

			if (!id) {
				return resolve([]);
			}

			memoryProcess.push(`${id}::${processMoment}`);
			this.processMoments.push(`${id}::${processMoment}`);

			const process: MessageBotHistory = await (this.flow.find(({ processId }) => processId === id) as MessageBot).process(
				this.getInstance(memoryProcess),
				processMoment,
				msg,
			);

			if (!process.processProps.isRedirected) {
				this.history.push(process);
			}

			if (callOnMessage && typeof this.onMenssage === "function") {
				await this.onMenssage(process.messages);
			}

			let messages: Message[] = process.messages;

			if (
				typeof process.next === "string" &&
				process.next.trim() !== "" &&
				//(process.next !== id || process.processMoment !== processMoment) &&
				//!process.isWaitingForMessage &&
				memoryProcess.indexOf(`${process.next}::${process.processMoment}`) < 0 //&&
				//this.getStageMoment() !== `${process.next}::${process.processMoment}`
			) {
				await this.next(process.next, process.processMoment, msg, memoryProcess).then((mess: Message[]) => {
					messages = Array.prototype.concat.apply([], [messages, mess]);
					return Promise.resolve();
				});
			}

			resolve(messages);
		});
	}

	processMessage(message: Message): Promise<Message[]> {
		return new Promise(async (resolve) => {
			const lastMessage: MessageBotHistory | undefined = this.history.slice(-1).pop();

			if (!lastMessage || !lastMessage.lastMessage) {
				return resolve([]);
			}

			const { type } = lastMessage.lastMessage;

			let messages: Message[] = [];

			const { processId, processMoment, maxProcessMoment } = this.parseStageMoment();

			if (message.type === "option" && type === "option") {
				const options = (lastMessage.processProps as MessageBotProcessFunWithOption).options;

				let { next, processMoment: nextProcessMoment, nextProps, action } = options[(message?.context.value as number) ?? 0];

				nextProcessMoment =
					typeof next === "string" && /^([\S\d\s]+)\:\:[0-9]+$/gi.test(next)
						? this.parseStageMoment(next).processMoment
						: typeof nextProcessMoment === "number"
						? nextProcessMoment
						: processId === next && processMoment < maxProcessMoment
						? processMoment + 1
						: 0;

				if (typeof next === "string") {
					this.props = Object.assign(this.props, nextProps);
					messages = await this.next(next, nextProcessMoment);
				} else if (typeof action === "function") {
					const actionProcess: MessageBot = new MessageBot({
						id: uniqueid(16),
						userId: this.roomId,
						roomId: this.roomId,
						processes: [action],
					});

					const process: MessageBotHistory = await actionProcess.process(this.getInstance(), 0);
					messages = process.messages;
				}
				// }else if(this.hasProcessId("onChange")){
				//     messages = await this.next("onChange", 0, message.context.message);
			} else if (processMoment < maxProcessMoment) {
				messages = await this.next(processId as string, processMoment + 1, message.context.message);
			}

			resolve(messages);
		});
	}

	getStageMoment(): string | undefined {
		const lastMessage: MessageBotHistory | undefined = this.history.slice(-1).pop();
		if (!lastMessage) return;
		return `${lastMessage.processId}::${lastMessage.processMoment}`;
	}

	parseStageMoment(stageMoment?: string): { processId: string | null; processMoment: number; maxProcessMoment: number } {
		let result = {
			processId: null,
			processMoment: 0,
			maxProcessMoment: 0,
		};

		stageMoment = typeof stageMoment === "string" && /^([\S\d\s]+)\:\:[0-9]+$/gi.test(stageMoment) ? stageMoment : this.getStageMoment();

		if (typeof stageMoment === "string" && /^([\S\d\s]+)\:\:[0-9]+$/gi.test(stageMoment)) {
			const [value, processId, processMoment] = stageMoment.match(/^([\S\d\s]+)\:\:([0-9]+)$/) ?? ["", null, null];

			const process: MessageBot | undefined = this.flow.find(({ processId: id }) => processId === id);

			return {
				processId,
				processMoment: parseInt(processMoment ?? "0"),
				maxProcessMoment: Math.max(0, (process?.lengthProcesses() ?? 0) - 1),
			};
		}

		return result;
	}
}
