import React, { useState, useCallback, useRef, useEffect, useLayoutEffect } from "react";
import { getDatabase } from "ivipbase";

import { useMessages } from "./useMessages";
import { Box, CircularProgress, Link, IconButton, Divider } from "@mui/material";

import Message from "./Message";

import { uniqueid, MultiStorager } from "Utils";

import { Usuario } from "Models";
import { APIHelper } from "Helper";

import { mdiArrowDownBoldCircle } from "@mdi/js";

import { SvgIcon } from "Components";

import StableInfiniteScroll from "./StableInfiniteScroll";

import style from "./index.module.scss";

export default ({ disabled, onSendMessage, suggestionChange, isChatBot, botTyping, user, infoRoom, botId }) => {
	const [id, setId] = useState(uniqueid());
	const [pageMessagesId, setPageMessagesId] = useState("");

	const [previousLoading, setPreviousLoading] = useState(true);
	const [previousMoreMessages, setPreviousMoreMessages] = useState(true);

	const [nextLoading, setNextLoading] = useState(false);
	const [nextMoreMessages, setNextMoreMessages] = useState(false);

	const [startUnread, setStartUnread] = useState(Date.now());

	const [firstMessage, setFirstMessage] = useState(-Infinity);
	const [lastMessage, setLastMessage] = useState(Infinity);

	const [directionPage, setDirectionPage] = useState(1);

	const [isInitial, setIsInitial] = useState(true);

	const [idRoom, setIdRoom] = useState(null);
	const [members, setMembers] = useState([]);

	const chatmessagesRef = useRef();
	const buttomToBottom = useRef();

	const { update: updateMessages, reset: resetMessages, messages, prepareMessage } = useMessages();

	useEffect(() => {
		if (isChatBot) {
			setPreviousMoreMessages(false);
		}
	}, [isChatBot]);

	useEffect(() => {
		if (!infoRoom || typeof infoRoom.chatId !== "string" || infoRoom.chatId === idRoom) {
			return;
		}
		setFirstMessage(-Infinity);
		setLastMessage(Infinity);
		setId(uniqueid());
		resetMessages();
		setNextMoreMessages(false);
		setPreviousMoreMessages(!isChatBot);
		setDirectionPage(1);
		setMembers(() => []);
		setPreviousLoading(true);
		setIdRoom(infoRoom.chatId);
		setPageMessagesId(infoRoom.chatId);
		setIsInitial(true);
		setStartUnread(Date.now());
	}, [infoRoom, isChatBot, idRoom]);

	const isChatInBottom = useCallback(() => {
		const container = chatmessagesRef.current?.container;
		if (!container) {
			return false;
		}

		const actualContainer = container ? container.parentNode?.querySelector(`.${style["chatmessages"]}`) : null;
		if (!actualContainer) {
			return false;
		}

		return (
			isInitial ||
			actualContainer.scrollHeight <= actualContainer.clientHeight ||
			actualContainer.scrollTop >= (actualContainer.scrollHeight - actualContainer.clientHeight) * 0.9
		);
	}, [chatmessagesRef.current, isInitial]);

	const toBottomChat = useCallback(
		(force, time) => {
			return setTimeout(
				() => {
					const container = chatmessagesRef.current?.container;
					if (!container) {
						return;
					}

					if (force === true) {
						container.scrollTop = container.scrollHeight;
						return;
					}

					if (isChatInBottom()) {
						container.scrollTop = container.scrollHeight;
					}
				},
				typeof time === "number" ? time : 10,
			);
		},
		[chatmessagesRef.current, isChatInBottom],
	);

	useEffect(() => {
		const container = chatmessagesRef.current?.container;
		if (!container) {
			return;
		}

		let time;

		const event = () => {
			if (!buttomToBottom || !buttomToBottom.current) {
				return;
			}
			const inBottom = isChatInBottom();
			buttomToBottom.current.style.display = inBottom ? "none" : "";
		};

		container.addEventListener("scroll", event);

		return () => {
			clearTimeout(time);
			container?.removeEventListener("scroll", event);
		};
	}, [chatmessagesRef.current?.container, buttomToBottom]);

	useEffect(() => {
		if (idRoom === botId || !infoRoom || !infoRoom.chat_started || !previousMoreMessages || typeof idRoom !== "string" || idRoom.trim() === "") {
			setPreviousLoading(false);
			return;
		}

		const updateMembers = (id) => {
			APIHelper.fetch(`chat/messages/${idRoom}/members`, { id: id }, 5000)
				.then((list) => {
					setMembers(list);
				})
				.catch(() => {});
		};

		const listener = getDatabase()
			.ref("Chats")
			.child(idRoom)
			.child("members")
			.on("mutated", () => {
				updateMembers(uniqueid());
			});

		updateMembers(id);

		return () => {
			listener?.stop();
		};
	}, [idRoom, botId, id]);

	useLayoutEffect(() => {
		if (
			directionPage === 0 ||
			idRoom === botId ||
			!infoRoom ||
			!infoRoom.chat_started ||
			!previousMoreMessages ||
			typeof idRoom !== "string" ||
			idRoom.trim() === ""
		) {
			setNextLoading(false);
			setPreviousLoading(false);
			return;
		}

		if (directionPage === 1) {
			setPreviousLoading(true);
		} else if (directionPage === -1) {
			setNextLoading(true);
		}

		const time = setTimeout(() => {
			messages.sort((a, b) => a.timestamp - b.timestamp);

			const nextMessage = messages.length ? messages[messages.length - 1].timestamp : null;
			const previousMessage = messages.length ? messages[0].timestamp : null;

			let idRoomNow = idRoom,
				chatIdNow = idRoom;

			APIHelper.fetch(
				`chat/messages/${idRoom}`,
				{
					nextMessage,
					previousMessage,
					directionPage,
					id: id,
				},
				5000,
			)
				.then(({ list, nextMore, previousMore, startUnread, unreadMessages, isInitial, chatId }) => {
					const container = chatmessagesRef.current?.getContainer();
					const getActualContainer = () => (container ? container.parentNode.querySelector(`.${style["chatmessages"]}`) : null);

					idRoomNow = getActualContainer()?.id;
					chatIdNow = chatId;

					if (chatId !== idRoomNow) {
						return;
					}

					const memoHeight = getActualContainer()?.clientHeight || 0;
					const memoScrollHeight = getActualContainer()?.scrollHeight || 0;
					const memoScrollTo = getActualContainer()?.scrollTop || 0;

					setPageMessagesId(chatId);
					updateMessages(list);
					setNextMoreMessages(nextMore);
					setPreviousMoreMessages(previousMore);

					if (isInitial) {
						setStartUnread(startUnread);
					}

					let chatRooms = MultiStorager.DataStorager.get("chatRooms");
					chatRooms = Array.isArray(chatRooms) ? chatRooms : [];

					const index = chatRooms.findIndex(({ chatId: _chatId }) => _chatId === chatId);

					chatRooms[index].count_message = unreadMessages;
					MultiStorager.DataStorager.set("chatRooms", chatRooms);

					setTimeout(() => {
						const actualContainer = getActualContainer();

						if (actualContainer && !isInitial) {
							setIsInitial(false);
							if (directionPage === 1) {
								actualContainer.scrollTop =
									actualContainer.scrollHeight - actualContainer.scrollTop - (memoScrollHeight - memoScrollTo);
							} else if (directionPage === -1) {
								//actualContainer.scrollTop = actualContainer.scrollTop;
							}
						}
					}, 100);
				})
				.catch(() => {})
				.finally(() => {
					if (chatIdNow !== idRoomNow) {
						return;
					}

					setTimeout(() => {
						setNextLoading(false);
						setPreviousLoading(false);
						setDirectionPage(0);
						setIsInitial(false);
					}, 2000);
				});
		}, 2000);

		return () => {
			clearTimeout(time);
		};
	}, [chatmessagesRef.current?.container, directionPage, previousMoreMessages, idRoom, botId, id]);

	useEffect(() => {
		const container = chatmessagesRef.current?.getContainer();
		const actualContainer = container ? container.parentNode.querySelector(`.${style["chatmessages"]}`) : null;
		const startUnreadNode = actualContainer ? actualContainer.querySelector(`.${style["start-unread"]}`) : null;

		messages.sort((a, b) => a.timestamp - b.timestamp);

		if (messages[0]?.roomId !== idRoom) {
			return;
		}

		const first = messages.length ? messages[0].timestamp : null;
		const last = messages.length ? messages[messages.length - 1].timestamp : null;

		if (isInitial) {
			if (startUnreadNode) {
				actualContainer.scrollTop = Math.max(0, startUnreadNode.offsetTop - actualContainer.clientHeight * 0.3);
			} else {
				toBottomChat(true, 100);
			}
		} else {
			if (lastMessage > last) {
				toBottomChat(false, 100);
			}
		}

		//setFirstMessage(first);
		//setLastMessage(last);
	}, [chatmessagesRef.current, messages, isInitial, botTyping, idRoom]);

	useEffect(() => {
		if (typeof idRoom !== "string" || idRoom.trim() === "") {
			return;
		}
		let listener, viewMessage;

		const userId = user instanceof Usuario ? user.path.split("/").pop() : null;

		if (botId === idRoom) {
			const messages = MultiStorager.DataStorager.get(`Chat_messages_${idRoom}`);

			if (Array.isArray(messages)) {
				updateMessages(messages);
			}

			listener = MultiStorager.DataStorager.addListener(`Chat_messages_${idRoom}`, (messages) => {
				const isBottom = isChatInBottom();
				updateMessages(messages);
				toBottomChat(isBottom, 100);
			});
		} else if (typeof userId === "string" && userId.trim() !== "") {
			if (!nextMoreMessages) {
				listener = getDatabase()
					.ref("Chats")
					.child(idRoom)
					.child("messages")
					.on("mutated", (snap) => {
						const [root, chatId, messageRoot, messageId, ...propertyTrail] = snap.ref.path.split("/");
						const isBottom = isChatInBottom();

						if (Array.isArray(propertyTrail) && propertyTrail.length) {
							return;
						}

						const message = snap.val();
						updateMessages([message]);
						toBottomChat(isBottom, 100);
						setStartUnread(message.timestamp);

						clearTimeout(viewMessage);
						viewMessage = setTimeout(() => {
							APIHelper.fetch(`chat/messages/${idRoom}/view/${message.timestamp}`, { id: id }, 5000).catch(() => {});
						}, 200);
					});
			}
		}

		return () => {
			listener?.stop();
		};
	}, [user, idRoom, botId, nextMoreMessages]);

	const sendMessage = useCallback(
		(...args) => {
			if (typeof onSendMessage === "function") {
				onSendMessage.apply(null, args);
			}
		},
		[onSendMessage],
	);

	const suggestionChangeIn = useCallback(
		(...args) => {
			if (typeof suggestionChange === "function") {
				suggestionChange.apply(null, args);
			}
		},
		[suggestionChange],
	);

	const handleNextMoreMessage = useCallback(
		(e) => {
			if (nextLoading || previousLoading) {
				return;
			}
			e?.preventDefault();
			setNextLoading(true);
			setDirectionPage(-1);
		},
		[previousLoading, nextLoading, directionPage],
	);

	const handlePreviousMoreMessage = useCallback(
		(e) => {
			if (previousLoading || nextLoading) {
				return;
			}
			e?.preventDefault();
			setPreviousLoading(true);
			setDirectionPage(1);
		},
		[nextLoading, previousLoading, directionPage],
	);

	return (
		<>
			<StableInfiniteScroll
				style={{}}
				className={style["chatmessages"]}
				ref={chatmessagesRef}
				loadingComponent={
					<Box className={style["loading"]}>
						<CircularProgress />
					</Box>
				}
				onNext={handleNextMoreMessage}
				nextEnd={!nextMoreMessages}
				nextLoading={nextLoading || pageMessagesId !== idRoom}
				onPrevious={handlePreviousMoreMessage}
				previousEnd={!previousMoreMessages}
				previousLoading={previousLoading || pageMessagesId !== idRoom}
				id={idRoom}
			>
				{!previousLoading && !nextLoading && previousMoreMessages && (
					<Box>
						<Link
							href="#"
							onClick={handlePreviousMoreMessage}
						>
							Mais mensagens
						</Link>
					</Box>
				)}
				{pageMessagesId === idRoom &&
					messages.map(({ type, context, userId, roomId, timestamp }, i, list) => {
						let userMessage = {};

						if (Array.isArray(members) && members.length) {
							userMessage = members.find(({ userId: _userId }) => _userId === userId);
							userMessage = !userMessage ? {} : userMessage;
						}

						const isStartUnread =
							i === list.length - 1
								? false
								: i + 1 < list.length - 1
								? list[i + 1].timestamp > startUnread && timestamp <= startUnread
								: startUnread == timestamp;

						return [
							<Message
								id={`#message-${timestamp}`}
								key={timestamp}
								message={prepareMessage(type, context, userId, roomId, user)}
								timestamp={timestamp}
								user={userMessage}
								prev={i > 0 ? messages[i - 1] : null}
								next={i < messages.length - 1 ? messages[i + 1] : null}
								disabled={disabled || i < messages.length - 1}
								suggestionChange={({ value, message }) => {
									sendMessage("option", { message, value: value });
									suggestionChangeIn(value || message);
								}}
								roomType={infoRoom?.type}
							/>,
							isStartUnread && (
								<Box
									key={`start-unread-${i}`}
									className={style["start-unread"]}
								>
									<Divider>Mensagens não lidas</Divider>
								</Box>
							),
						];
					})}
				{isChatBot === true && botTyping === true ? (
					<div
						className={[style["message"], style["him"], style["start"], style["end"]].join(" ")}
						style={{
							marginTop: "5px",
							backgroundColor: "rgba(0,0,0,.3)",
						}}
					>
						<div className={style["content"]}>
							<div className={style["typing"]}>
								<div className={style["dot"]}></div>
								<div className={style["dot"]}></div>
								<div className={style["dot"]}></div>
							</div>
						</div>
					</div>
				) : null}
				{!previousLoading && !nextLoading && nextMoreMessages && (
					<Box>
						<Link
							href="#"
							onClick={handleNextMoreMessage}
						>
							Mais mensagens
						</Link>
					</Box>
				)}
			</StableInfiniteScroll>
			<IconButton
				className={style["goToBottom"]}
				style={{ display: "none" }}
				color="primary"
				onClick={() => {
					toBottomChat(true, 0);
				}}
				ref={buttomToBottom}
				disableRipple
			>
				<SvgIcon path={mdiArrowDownBoldCircle} />
			</IconButton>
		</>
	);
};
