import { Global } from "Utils";

import languagesList from "./languages.json";

import * as languages from "./lang";

interface LanguageInfo {
	name: string;
	nativeName: string;
	code: string;
}

interface LanguagesList {
	[lng: string]: Omit<LanguageInfo, "code">;
}

export const langList = Object.entries(languages)
	.map((r) => r[1]["@metadata"]["lang-code"])
	.filter((l) => l in languagesList)
	.map((l) => Object.assign({ code: l }, (languagesList as LanguagesList)[l]) as LanguageInfo);

export const lang = () => {
	if (localStorage.hasOwnProperty("main-settings")) {
		const settings = JSON.parse(window.localStorage.getItem("main-settings") as any);
		if (
			"language.selectLanguage" in settings &&
			typeof settings["language.selectLanguage"] === "string" &&
			langList.findIndex(({ code }) => settings["language.selectLanguage"] == code) >= 0
		) {
			return settings["language.selectLanguage"];
		}
	}

	return "pt-BR";
};

export const dic = () => Object.assign(languages["messages"], (languages as any)[lang().replace(/-/gi, "_")]);

export const dicKeys = () => Object.keys(dic());

export const getMsg = function (name: keyof Omit<typeof languages.messages, "@metadata">): string {
	if (typeof name !== "string") {
		return "";
	}

	name = name.toLocaleUpperCase() as any;

	const _dic = dic();
	const _dicKeys = dicKeys();

	if (_dicKeys.includes(name)) {
		return _dic[name];
	}

	let k = _dicKeys.map((a) => [Global.similarityStrings(a, name), a]).sort((a, b) => (b[0] > a[0] ? -1 : b[0] < a[0] ? 1 : 0));
	return _dic[k[0][1]] || "";
};

export default class Messages {
	static langList = langList;
	static lang = lang();
	static dic = dic();
	static getMsg = getMsg;
}
