import roundPathCorners from "./roundPathCorners";
import MultiStorager from "./MultiStorager";
import * as Global from "./Global";
import Menu from "./Menu";
import ColorShaders from "./ColorShaders";
import * as mat4 from "./gl-mat4";
import * as useHook from "./useHook";
import mergeClasses from "./mergeClasses";
import textareaAutosize from "./textareaAutosize";
import spiral from "./spiral";
import list_withdrawal_methods from "./list_withdrawal_methods";
import list_payment_methods from "./list_payment_methods";

export * from "./Global";

export const parseNumber = (value: string | number): number | typeof NaN => {
	if (typeof value === "number") {
		return value;
	}
	const americanFormat = /^(\d{1,3}((\,\d{3})*)?)(\.\d+)$/;
	const brazilianFormat = /^(\d{1,3}((\.\d{3})*)?)(,\d+)$/;

	if (americanFormat.test(value)) {
		return parseFloat(value.replace(",", ""));
	} else if (brazilianFormat.test(value)) {
		return parseFloat(value.replace(".", "").replace(",", "."));
	} else {
		return parseFloat(value);
	}
};

export const roundToTwoDecimals = (number: number | string, decimalMargin: number = 2): number => {
	number = parseNumber(number as string) ?? 0;
	decimalMargin = Math.min(16, Math.max(1, typeof decimalMargin === "number" ? decimalMargin : 2));
	let decimal = (number.toString().split(".")[1] ?? "").replace(/(0+)$/gi, "");
	let decimalLength = decimal ? decimal.length : 0;
	let rounded = number.toFixed(Math.min(decimalLength, decimalMargin));
	return parseFloat(rounded);
};

export const maskAmount = (value: number | string, decimalMargin: number = 3, symbol?: string, direction: "LTR" | "RTL" = "LTR"): string => {
	value = ["number", "string"].includes(typeof value) ? value.toString() : "0";
	const decimalSeparator = ((1.1).toLocaleString().match(/[^0-9]/) as any)[0] ?? ".";

	const integer = ((value: string) => {
		let result: string[] = [];
		while (/(\d{3})$/gi.test(value)) {
			const [number] = value.match(/(\d{3})$/gi) ?? ["000"];
			result.push(number);
			value = value.replace(/(\d{3})$/gi, "");
		}
		result.push(value);
		return result
			.filter((v) => v !== "")
			.reverse()
			.join(decimalSeparator === "." ? "," : ".");
	})(Math.abs(parseInt(value)).toString());

	const decimal = ((value: string) => {
		value = value.replace(/(0+)$/gi, "");
		return value.length >= 2 ? value : (value + "00").slice(0, 2);
	})(roundToTwoDecimals(value, decimalMargin).toString().split(".")[1] ?? "00");

	const result: string[] = [];
	if (symbol && symbol.trim() !== "") {
		result.push(symbol.trim());
	}
	result.push(`${parseInt(value) < 0 ? "-" : ""}${integer}${decimalSeparator}${decimal !== "0" ? decimal : "00"}`);
	return direction === "LTR" ? result.join(" ") : result.reverse().join(" ");
};

/**
 * Converte um número para uma string formatada
 * @param value Número a ser formatado
 * @param separator Separador de milhar
 * @returns String formatada
 * @example
 * numberToFormat(1000) // "1 K"
 * numberToFormat(1000000) // "1 M"
 * numberToFormat(1000000000) // "1 B"
 * numberToFormat(1000000000000) // "1 T"
 * numberToFormat(1000000000000000) // "1 Q"
 */
export const numberToFormat = (value: number, separator: string = "."): string => {
	const itens = ["", "K", "M", "B", "T", "Q", "QQ", "S", "SS", "O", "N", "D", "UD", "DD", "TD", "QD", "QQD", "SD", "SSD", "OD", "ND", "V"];
	let index = 0;
	while (value >= 1000) {
		value /= 1000;
		index++;
	}
	return (
		value
			.toFixed(2)
			.replace(/\.?(0|0[0-9]+)?$/gi, "")
			.replace(".", separator) +
		" " +
		itens[index]
	);
};

/**
 * Converte um número em milissegundos para uma string formatada
 * @param time Número em milissegundos
 * @returns String formatada
 * @example
 * timeFormat(1000) // "1s"
 * timeFormat(60000) // "1m"
 */
export const timeFormat = (time: number): string => {
	const itens = ["ms", "s", "m", "h", "d", "w", "y"];
	const counter = [1, 1000, 60, 60, 24, 7, 365];

	const result = counter.map((t, i, l) => {
		return l.slice(0, i + 1).reduce((a, b) => a / b, time);
	});

	let index = 0;

	for (let i = result.length - 1; i >= 0; i--) {
		if (result[i] >= 1) {
			index = i;
			break;
		}
	}

	const inteiro = parseInt(result[index].toFixed(0));
	const decimal = parseInt((result[index] * 100).toFixed(0)) % 100;

	return inteiro + itens[index] + (decimal > 0 && index > 0 ? " e " + decimal + itens[index - 1] : "");
};

export const copyStringToClipboard = async (str: string) => {
	return new Promise(async (resolve, reject) => {
		try {
			await navigator.clipboard.writeText(str);
		} catch {
			const el = document.createElement("textarea");
			el.value = str;
			el.setAttribute("readonly", "");
			el.style.position = "absolute";
			el.style.left = "-9999px";
			document.body.appendChild(el);
			const documentSelect = document.getSelection();
			if (!documentSelect) {
				return reject("Não foi possível copiar o texto.");
			}
			const selected = documentSelect.rangeCount > 0 ? documentSelect.getRangeAt(0) : false;
			el.select();
			document.execCommand("copy");
			document.body.removeChild(el);
			if (selected) {
				documentSelect.removeAllRanges();
				documentSelect.addRange(selected);
			}
		}

		return resolve(str);
	});
};

export {
	roundPathCorners,
	MultiStorager,
	Global,
	Menu,
	ColorShaders,
	mat4,
	useHook,
	mergeClasses,
	textareaAutosize,
	spiral,
	list_payment_methods,
	list_withdrawal_methods,
};
