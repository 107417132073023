import { CANVASTextureMapElement, CanvasCurrentElement, Context, MapFaces, TextureMap, useTextureMap } from "Utils/Canvas";
import React, { useRef, useEffect } from "react";

import textureImage from "./83fd85bb4bd525a774e0385aa265fc9b7286ee37.png";
import { BezierEasing } from "ivip-utils";

const getRandomColor = () => {
	let letters = "0123456789ABCDEF";
	let color = "#";
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
};

const polarToCartesian = (radius: number, angleDegrees: number, offset: { x: number; y: number } = { x: 0, y: 0 }) => {
	const angleRadians = (angleDegrees * Math.PI) / 180;
	const x = radius * Math.cos(angleRadians);
	const y = radius * Math.sin(angleRadians);
	return [offset.x + x, offset.y + y] as [number, number];
};

const dist = (x1: number, y1: number, x2: number, y2: number) => {
	return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
};

const TestCanvas: React.FC = () => {
	const textureMapRef = useTextureMap();
	const imageRef = useRef<HTMLImageElement>(new Image());

	useEffect(() => {
		// const size = 1;

		// const vertices: MapFaces = [
		// 	[
		// 		{ x: 0, y: 80 * size, u: 0, v: 0 },
		// 		{ x: 600 * size, y: 0 * size, u: 1, v: 0 },
		// 		{ x: 600 * size, y: 250 * size, u: 1, v: 1 },
		// 		{ x: 0, y: 170 * size, u: 0, v: 1 },
		// 	],
		// ];

		const vertices: MapFaces = [];

		const cols = 35,
			rows = 5,
			size = 200,
			angleStep = 360 / cols,
			raioStep = size / rows;
		const offset = { x: size, y: size };
		const b = 0.08;
		const base = BezierEasing.easeInCirc;

		for (let i = 0; i < cols; i++) {
			const angle = angleStep * i;
			for (let j = 0; j < rows; j++) {
				const raio = size - raioStep * j;
				const [x1, y1] = polarToCartesian(raio, angle, offset);
				const [x2, y2] = polarToCartesian(raio, angle + angleStep + b, offset);
				const [x3, y3] = polarToCartesian(raio - raioStep - b * 2, angle + angleStep + b, offset);
				const [x4, y4] = polarToCartesian(raio - raioStep - b * 2, angle, offset);

				const d1 = dist(x1, y1, offset.x, offset.y) / size;
				const d2 = dist(x2, y2, offset.x, offset.y) / size;
				const d3 = dist(x3, y3, offset.x, offset.y) / size;
				const d4 = dist(x4, y4, offset.x, offset.y) / size;

				vertices.push([
					{ x: x1, y: y1, u: i / cols, v: base(d1) },
					{ x: x2, y: y2, u: (i + 1) / cols, v: base(d2) },
					{ x: x3, y: y3, u: (i + 1) / cols, v: base(d3) },
					{ x: x4, y: y4, u: i / cols, v: base(d4) },
				]);
			}
		}

		textureMapRef.seFaces(vertices);

		if (imageRef.current.src !== textureImage) {
			imageRef.current.src = textureImage;
		}

		const duration = 30,
			steep = 10;

		let time: number,
			timeLoop: NodeJS.Timeout,
			move = 0;

		let lastTime = Date.now();

		const loop = () => {
			clearTimeout(timeLoop);
			time = requestAnimationFrame(() => {
				const now = Date.now();
				const currentTime = now - lastTime;
				lastTime = now;
				const { width, height } = imageRef.current;
				textureMapRef.setSize(width, height);
				const ctx = textureMapRef.getContext();

				if (!ctx) {
					return;
				}

				const pattern = ctx.createPattern(imageRef.current, "repeat");

				if (!pattern) {
					return;
				}

				ctx.clearRect(0, 0, width, height);

				ctx.save();
				ctx.globalCompositeOperation = "xor";

				let gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
				gradient.addColorStop(0, "white");
				gradient.addColorStop(0.4, "transparent");
				gradient.addColorStop(1, "white");
				ctx.fillStyle = gradient;
				ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

				ctx.fillStyle = pattern;
				ctx.beginPath();
				ctx.rect(0, 0, width, height);

				ctx.translate(move, move);
				ctx.fill();
				ctx.restore();

				// const imageData = ctx.getImageData(0, 0, width, height);

				// for (let i = 0; i < imageData.data.length; i += 4) {
				// 	const x = (i / 4) % width;
				// 	const y = Math.floor(i / (4 * width));
				// 	const [r, g, b, a] = imageData.data.slice(i, i + 4);
				// 	const opacity = (width - x) / width;

				// 	imageData.data[i + 0] = 255;
				// 	imageData.data[i + 1] = 255;
				// 	imageData.data[i + 2] = 255;
				// 	imageData.data[i + 3] = Math.max(0, Math.round((r + g + b) / 3));
				// 	//imageData.data[i + 3] = Math.round(255 * opacity);
				// }

				// ctx.clearRect(0, 0, width, height);
				// ctx.putImageData(imageData, 0, 0);

				textureMapRef.update();

				move += (currentTime * steep) / duration;
				move = move % width;
				timeLoop = setTimeout(loop, 20);
			});
		};

		imageRef.current.onload = loop;

		loop();

		return () => {
			clearTimeout(timeLoop);
			cancelAnimationFrame(time);
		};
	}, [imageRef]);

	return (
		<Context
			width={600}
			height={400}
			onClick={() => {}}
		>
			<TextureMap
				textureMap={textureMapRef}
				tiles={2}
				seamOverlap={0.012}
				strokeWidth={0}
				method="bilinear"
			/>
		</Context>
	);
};

export default TestCanvas;
