import React, { useLayoutEffect, useState, useCallback } from "react";

import { CircularProgress, Link } from "@mui/material";

import { Wallet } from "Models";

import { currencyFormat } from "Utils";

import style from "./style.module.scss";

import { APIHelper, InteractionHelper } from "Helper";
import { MovimentsList, OrderInfo } from "Types/Wallet";
import { ViewPaymentDialog } from "Dialog";

const ViewMoviments: React.FC<{
	[prop: string]: any;
	wallet: Wallet | null;
}> = ({ wallet }) => {
	const [movement, setMovement] = useState<MovimentsList>({ list: [], page: -1, more: true, loading: true });

	const updateForFetch = useCallback(
		(list: OrderInfo[], page: number, more: boolean) => {
			if (movement.page !== page - 1) {
				return;
			}

			setMovement((prev) => {
				return { list: prev.list.concat(list), page: page, more: more, loading: false };
			});
		},
		[movement],
	);

	const nextMovementPage = useCallback(() => {
		if (!(wallet instanceof Wallet) || !wallet.path) {
			return;
		}

		if (movement.more) {
			setMovement((prev) => {
				return { ...prev, loading: true };
			});

			APIHelper.fetch(
				`wallet/history/${wallet.path
					.replace(/(\/+)$/gi, "")
					.split("/")
					.pop()}`,
				{
					page: movement.page + 1,
					take: 15,
				},
				5000,
			)
				.then(({ list, page, more }: any) => {
					updateForFetch(list, page, more);
				})
				.catch(() => {});
		}
	}, [movement, wallet]);

	useLayoutEffect(() => {
		setMovement((prev) => {
			return { list: [], page: -1, more: true, loading: true };
		});
		const time = setTimeout(() => {
			nextMovementPage();
		}, 500);

		return () => {
			clearTimeout(time);
		};
	}, [wallet]);

	return (
		<div className={style["movement-main"]}>
			{movement.list.map(({ history_id, type, status, date_created: date, currency_id, original_amount: value }, i) => {
				const byStatus = ["charged_back", "discounted", "drawee", "transferred"].includes(status)
					? "draw"
					: ["rejected", "refunded", "cancelled"].includes(status)
					? "rejected"
					: ["approved", "authorized"].includes(status)
					? "approved"
					: "pending";

				const isCurrencySystem = currencyFormat.indexOf(currency_id) >= 0;

				const renderValue = currencyFormat.convert(value, !isCurrencySystem ? "USD" : currency_id) as string;

				const { icon: method_icon, label: method_label } = Wallet.getMethodRenderBy(type);

				return (
					<div
						key={`movement-${i}`}
						className={style["movement-item"]}
						onClick={() => {
							const walletId = wallet?.path
								?.replace(/(\/+)$/gi, "")
								.split("/")
								.pop();

							if (!walletId || !history_id) {
								InteractionHelper.toast("Algo deu errado, tente novamente mais tarde!", null, "warning", null, 15000);
								return;
							}

							ViewPaymentDialog.show({ wallet: walletId, paymentId: String(history_id) });
						}}
					>
						<div className={style["movement-icon"]}>{method_icon}</div>
						<div
							className={style[`movement-type-method`]}
							style={{ display: "none" }}
						>
							<div>{method_label}</div>
						</div>
						<div className={style[`movement-status-${byStatus}`]}>
							<div>{String(byStatus || "").toUpperCase()}</div>
						</div>
						<div className={style["movement-date"]}>{new Date(date).toLocaleString()}</div>
						<div className={style["movement-value"]}>
							{!isCurrencySystem ? `${renderValue.split(/\s/gi).pop()} ${currency_id}` : renderValue}
						</div>
					</div>
				);
			})}
			{movement.loading && <CircularProgress color="inherit" />}
			{!movement.loading && movement.more && (
				<div className={style["movement-btn-more"]}>
					<Link
						href="#"
						underline="hover"
						onClick={() => {
							nextMovementPage();
						}}
					>
						Ver mais
					</Link>
				</div>
			)}
		</div>
	);
};

export default ViewMoviments;
