import React, { useState, useCallback, useRef, useEffect, useLayoutEffect } from "react";

import { CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { mdiMessage } from '@mdi/js';

import { SvgIcon } from "Components";

import style from './index.module.scss';

import { APIHelper, InteractionHelper } from "Helper";

export default ({infoRoom, onUpdateRoom})=>{
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [chatId, setChatId] = useState("");

    useEffect(()=>{
        if(chatId !== infoRoom.chatId){
            setChatId(infoRoom.chatId);
            setDisable(true);
            setLoading(false);
        }
    }, [infoRoom, chatId]);

    const updateRoom = useCallback((...args)=>{
        if(typeof onUpdateRoom === "function"){
            onUpdateRoom.apply(null, args);
        }
    }, [onUpdateRoom]);

    const createNewChat = useCallback(()=>{
        setLoading(true);

        APIHelper.fetch(`chat/create/${infoRoom.chatId}`, {}, 10).then((infoRoom)=>{
            updateRoom(infoRoom);
        }).catch(()=>{
            InteractionHelper.toast("Ocorreu um erro interno, tente novamente mais tarde!", null, "error");
            setLoading(false);
        });
    }, [onUpdateRoom, infoRoom]);

    useLayoutEffect(()=>{
        if(disable && infoRoom && infoRoom.chatId === chatId){ 
            setDisable(false); 
        }
    }, [infoRoom, disable, chatId]);

    if(disable){
        return <div className={style["new-chat-view"]}>
            <CircularProgress />
        </div>
    }

    const name = infoRoom?.type === "group" ? infoRoom?.name : (infoRoom?.name || "").split(" ").shift();

    return <div className={style["new-chat-view"]}>
        <div className={style["picture"]} style={{backgroundImage: `url(${infoRoom?.picture})`}}></div>
        <div className={style["title"]}>{infoRoom?.name}</div>
        <div className={style["message"]}>{
            `Inicie uma nova conversa ${infoRoom?.type === "group" ? "com" : "em grupo"} ${name} agora e aproveite ao máximo todos os recursos que a plataforma tem a oferecer!`
        }</div>
        <LoadingButton 
            loading={loading} loadingPosition="start"
            disabled={disable} variant="outlined"
            startIcon={<SvgIcon path={mdiMessage}/>}
            onClick={createNewChat}>
                {infoRoom?.type !== "group" ? "Iniciar conversa" : "Entrar no grupo"}
        </LoadingButton>
    </div>;
}