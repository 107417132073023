import React from "react";
import { PhaseProps, Structure } from "Dialog/utils";
import { WalletStatus, walletType } from "Types/Wallet";
import { Binance, Ivipbank } from "./Process";
import { Wallet } from "Models";
import { WalletHelper } from "Helper";

import style from "./style.module.scss";
import { Typography } from "@mui/material";
import { InputCustom, SvgIcon } from "Components";
import { mdiArrowLeftRight } from "@mdi/js";

interface NewWalletDialogPropsDefault<t = walletType> {
	type: t;
	data: any;
	wallet: Wallet;
	isNew: boolean;
}

interface BinanceProps extends NewWalletDialogPropsDefault<"BINANCE"> {
	data: {
		apiKey: string;
		apiSecret: string;
	};
}

interface MetamaskProps extends NewWalletDialogPropsDefault<"METAMASK"> {
	data: {
		address: string;
	};
}

interface IviBankProps extends NewWalletDialogPropsDefault<"IVIPBANK"> {
	data: {
		account: string;
		password: string;
		confirmationCode: string;
		partnerId: number;
	};
}

export type NewWalletDialogProps<t = walletType> = NewWalletDialogPropsDefault<t> &
	(t extends "BINANCE"
		? BinanceProps
		: t extends "METAMASK"
		? MetamaskProps
		: t extends "IVIPBANK"
		? IviBankProps
		: NewWalletDialogPropsDefault<t>);

const NewWallet: React.FC<
	PhaseProps<NewWalletDialogProps> & {
		phase?: number;
	}
> = ({ state, ...props }) => {
	return (
		<>
			<div className={style.header}>
				<div className={style.picture}>
					<div>{Wallet.walletIcon({ type: state.type })}</div>
					<div>{Wallet.walletIcon({ type: "IVIPCOIN" })}</div>
				</div>
				<div className={style.title}>
					<Typography variant="h6">{state.type}</Typography>
					<SvgIcon path={mdiArrowLeftRight} />
					<Typography variant="h6">IVIPCOIN</Typography>
				</div>
			</div>
			<div className={style.content}>
				{state.type === "METAMASK" && <></>}
				{state.type === "BINANCE" && (
					<Binance
						state={state as any}
						{...props}
					/>
				)}
				{state.type === "IVIPBANK" && (
					<Ivipbank
						state={state as any}
						{...props}
						phase={props.phase ?? 0}
					/>
				)}
			</div>
		</>
	);
};

const prepareData = (state: NewWalletDialogProps) => {
	switch (state.type) {
		case "BINANCE":
			state.data = {
				type: state.type,
				apiKey: "",
				apiSecret: "",
			};
			break;
		case "METAMASK":
			state.data = {
				type: state.type,
				address: "",
			};
			break;
		case "IVIPBANK":
			state.data = {
				type: state.type,
				account: "",
				password: "",
			};
			break;
	}
};

const preparePostData = (state: NewWalletDialogProps) => {
	switch (state.type) {
		case "BINANCE":
			return {
				walletAPIKey: state.data.apiKey,
				walletAPISecret: state.data.apiSecret,
				walletType: "BINANCE",
				walletPath: state.wallet.path,
			};
		case "METAMASK":
			return {
				walletKey: state.data.address,
				walletType: "METAMASK",
				walletPath: state.wallet.path,
			};
		case "IVIPBANK":
			return {
				walletAccount: state.data.account,
				walletPassword: state.data.password,
				walletCode: state.data.confirmationCode,
				walletPartnerId: state.data.partnerId,
				walletType: "IVIPBANK",
				walletPath: state.wallet.path,
			};
	}
};

const validate = (state: NewWalletDialogProps) => {
	switch (state.type) {
		case "BINANCE":
			return state.data.apiKey !== "" && state.data.apiSecret !== "";
		case "METAMASK":
			return state.data.address !== "";
		case "IVIPBANK":
			return state.data.account !== "" && state.data.password !== "";
	}
	return false;
};

export default new Structure<NewWalletDialogProps>(
	"Nova Carteira",
	{
		type: "IVIPBANK",
		data: {
			account: "",
			password: "",
		},
		wallet: {} as any,
		isNew: true,
	},
	[
		async ({ state, nextPhase, setTitle, reject }) => {
			state.wallet = new Wallet();

			if (["BINANCE", "IVIPBANK"].includes(state.type) !== true) {
				return reject(new Error("Tipo de carteira indisponível no momento"));
			}

			state.isNew = !(state.wallet instanceof Wallet && typeof state.wallet.path === "string" && state.wallet.path.trim() !== "");

			if (state.isNew) {
				const newPath = await WalletHelper.generatePath();
				state.wallet = new Wallet().parse(newPath, {
					type: state.type,
					nome: `Carteira - (${new Date().toLocaleString()})`,
				});
			}

			prepareData(state);
			setTitle(`Nova Carteira - ${state.type}`);
			nextPhase();
		},
		{
			phase: (p) => {
				if (p.state.type === "IVIPBANK") {
					return [
						<NewWallet
							{...p}
							phase={0}
						/>,
						<NewWallet
							{...p}
							phase={1}
						/>,
						<NewWallet
							{...p}
							phase={2}
						/>,
					];
				}
				return <NewWallet {...p} />;
			},
			term: "financial",
		},
		({ state, resolve, previousPhase }) => {
			if (validate(state) !== true) {
				return previousPhase(new Error("Existe campos obrigatórios não preenchidos ou inválidos!"));
			}

			new Promise((resolve: (isNew: boolean) => void) => {
				return resolve(state.isNew);
			})
				.then((isNew) => {
					const data = preparePostData(state);
					return isNew ? WalletHelper.createWallet(state.wallet, undefined, data) : WalletHelper.saveWallet(state.wallet, undefined, data);
				})
				.then((wallet) => {
					resolve(wallet);
				})
				.catch((error) => {
					previousPhase(new Error("Erro ao salvar a carteira"));
				});
		},
	],
);
