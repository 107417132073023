export default class Resultado {
	/** Código da atividade. */
	readonly code: number;

	/** Define a descrição da atividade. */
	readonly message: string;

	/** Informações adicionais sobre o erro. */
	readonly stackTrace: any;

	/** Caso haja algum dado à ser retornado em operações bem sucedidas. */
	readonly dice: any;

	/**
	 * Utilizada para informar sucesso ou problemas ao realizar atividades dentro do sistema.
	 * @param {number} code Código da atividade.
	 * @param {String} message Define a descrição da atividade.
	 * @param {any} stackTrace Informações adicionais sobre o erro.
	 * @param {any} dice Caso haja algum dado à ser retornado em operações bem sucedidas.
	 */
	constructor(code: number, message: string, stackTrace?: any, dice?: any) {
		this.code = code;
		this.message = message;
		Object.defineProperty(this, "stackTrace", {
			get: function () {
				return this._stackTrace;
			},
			set: function (value) {
				this._stackTrace = value;
			},
		});
		this.stackTrace = stackTrace;
		this.dice = dice;
	}
}
