import React, { useState, useEffect, useContext } from "react";

import { Typography, Switch, Grid } from "@mui/material";

import { mdiAlien } from "@mdi/js";

import { SvgIcon } from "Components";

import settingsContext from "Context";

import SelectComponent from "./SelectComponent";

interface ListItemItens {
	value: string;
	label: string;
	img: string;
	disabled?: boolean;
}

type ListItemDefaultValue = string | number | boolean | Array<string | number | boolean>;

export interface ListItem {
	id?: string;
	type: "multiple-select" | "select" | "switch" | "info";
	defaultValue?: ListItemDefaultValue;
	value?: string;
	icon: string;
	label: string;
	multiple?: boolean;
	itens?: ListItemItens[];
	disabled?: boolean;
	renderValue?: (selected: ListItemItens[]) => string;
}

const ListItemComponentSelect: React.FC<{
	value: any;
	icon: string;
	label: string;
	multiple?: boolean;
	defaultValue?: ListItemDefaultValue;
	itens?: ListItemItens[];
	disabled?: boolean;
	renderValue?: (selected: ListItemItens[]) => string;
	onChange?: (selected: string) => void;
}> = ({ value, icon, label, multiple, defaultValue, itens, disabled, renderValue, onChange }) => {
	return (
		<div className={["Settings-List-item", "Settings-List-select", disabled ? "disabled" : ""].join(" ")}>
			<SvgIcon path={icon || mdiAlien} />
			<Typography component="div">{label || ""}</Typography>
			<SelectComponent
				value={value}
				multiple={multiple}
				defaultValue={defaultValue}
				itens={itens}
				disabled={disabled}
				renderValue={renderValue}
				onChange={onChange}
				sx={undefined}
			/>
		</div>
	);
};

const ListItemComponentSwitch: React.FC<{
	value: any;
	icon: string;
	label: string;
	defaultValue?: boolean;
	disabled?: boolean;
	onChange?: (selected: boolean) => void;
}> = ({ value: _value, icon, label, defaultValue, disabled, onChange }) => {
	const [value, setValue] = useState<boolean>(false);

	useEffect(() => {
		setValue((p) => (typeof _value === "boolean" ? _value : defaultValue) ?? p);
	}, [_value, defaultValue]);

	const handleChange = () => {
		typeof onChange === "function" && onChange(!value);
		setValue(!value);
	};

	return (
		<div className={["Settings-List-item", "Settings-List-switch", disabled ? "disabled" : ""].join(" ")}>
			<SvgIcon path={icon || mdiAlien} />
			<Typography component="div">{label || ""}</Typography>
			<Switch
				disabled={disabled}
				checked={value}
				onChange={handleChange}
			/>
		</div>
	);
};

const ListItemComponentInfo: React.FC<{
	icon: string;
	label: string;
	value?: string;
}> = ({ icon, label, value }) => {
	if (typeof label !== "string") {
		return <></>;
	}
	return (
		<div className="Settings-List-item Settings-List-default">
			<SvgIcon path={icon || mdiAlien} />
			<Typography component="div">{label || ""}</Typography>
			<Typography component="p">{value || ""}</Typography>
		</div>
	);
};

const List: React.FC<{
	itens: ListItem[];
}> = ({ itens }) => {
	const [listItens, setListItens] = useState<JSX.Element[]>([]);
	const { settings, getSetting, setSetting, hasSetting } = useContext(settingsContext);

	useEffect(() => {
		if (!(Array.isArray(itens) && itens.length > 0)) {
			return;
		}

		setListItens(
			itens.map(({ type, id, ...propsItem }, i) => {
				const onChange = (value: any) => {
					setSetting(id, value);
				};

				switch (String(type).toLowerCase()) {
					case "multiple-select":
						return (
							<Grid
								key={`ListItemComponent-${i}`}
								item
								xs={12}
								md={6}
							>
								<ListItemComponentSelect
									{...propsItem}
									multiple={true}
									value={getSetting(id)}
									onChange={onChange}
								/>
							</Grid>
						);
					case "select":
						return (
							<Grid
								key={`ListItemComponent-${i}`}
								item
								xs={12}
								md={6}
							>
								<ListItemComponentSelect
									{...propsItem}
									value={getSetting(id)}
									onChange={onChange}
								/>
							</Grid>
						);
					case "switch":
						return (
							<Grid
								key={`ListItemComponent-${i}`}
								item
								xs={12}
								md={6}
							>
								<ListItemComponentSwitch
									{...(propsItem as any)}
									value={getSetting(id)}
									onChange={onChange}
								/>
							</Grid>
						);
					default:
						return (
							<Grid
								key={`ListItemComponent-${i}`}
								item
								xs={12}
								md={6}
							>
								<ListItemComponentInfo {...propsItem} />
							</Grid>
						);
				}
			}),
		);
	}, [itens]);

	return (
		<Grid
			className="Settings-List"
			container
			spacing={2}
		>
			{listItens}
		</Grid>
	);
};

export default List;
