import React, { useState, useCallback, useEffect } from "react";

import {
	Box,
	Paper,
	Grid,
	Typography,
	Stepper,
	Step,
	StepButton,
	Tooltip,
	LinearProgress,
	ButtonGroup,
	Button,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	CircularProgress,
	InputBase,
	Divider,
} from "@mui/material";

import { useClassNames, useElementSize, useWindowSize } from "Utils/useHook";

import { mdiInformation, mdiCashFast, mdiHandCoin, mdiArrowDown } from "@mdi/js";

import { SvgIcon, Icons } from "Components";

import { currencyFormat, isDate } from "Utils";

import { useWallets } from "Helper/WalletHelper";
import { APIHelper, WalletHelper, InteractionHelper, UserHelper } from "Helper";
import { Wallet, Usuario } from "Models";

import style from "./style.module.scss";
import { DepositDialog } from "Dialog";

const enumer = (num) => (parseInt(num) > 9 ? num : "0" + num);

Array.prototype.reverseFindIndex = function (callback) {
	const index =
		this.length -
		1 -
		this.slice()
			.reverse()
			.findIndex((element, index) => callback(element, this.length - 1 - index, this));
	return index >= 0 && index < this.length ? index : -1;
};

Date.prototype.getDateDifference = function (date) {
	const difference = date - this;
	const oneMinute = 1000 * 60;
	const oneHour = oneMinute * 60;
	const oneDay = oneHour * 24;
	const oneMonth = oneDay * 30;

	const months = Math.floor(difference / oneMonth);
	const days = Math.floor(difference / oneDay);
	const hours = Math.floor(difference / oneHour);
	const minutes = Math.floor(difference / oneMinute) + 1;

	if (difference >= oneMonth) {
		return `${months} ${months > 1 ? "meses" : "mês"}`;
	} else if (difference >= oneDay) {
		return `${days} dia${days > 1 ? "s" : ""}`;
	} else if (difference >= oneHour) {
		return `${hours} hora${hours > 1 ? "s" : ""}`;
	} else if (difference >= oneMinute) {
		return `${minutes} minuto${minutes > 1 ? "s" : ""}`;
	} else {
		return "menos de um minuto";
	}
};

const formatNumber = (value) => {
	const suffixes = ["", "mil", "milhões", "bilhões", "trilhões", "quatrilhões"];
	let suffixIndex = 0;

	while (value >= 1000 && suffixIndex < suffixes.length - 1) {
		value /= 1000;
		suffixIndex++;
	}

	return `${currencyFormat.convert(value)} ${suffixes[suffixIndex]}`;
};

const CountMoment = ({ start: _start, end: _end }) => {
	const [start, setStart] = useState(null);
	const [end, setEnd] = useState(null);
	const [timeNow, setTimeNow] = useState(new Date());

	useEffect(() => {
		if (!_start instanceof Date) {
			return;
		}
		setStart(() => _start);
	}, [_start]);

	useEffect(() => {
		if (!_end instanceof Date) {
			return;
		}
		setEnd(() => _end);
	}, [_end]);

	useEffect(() => {
		if (timeNow > end) {
			return;
		}

		const time = setInterval(() => {
			if (timeNow > end) {
				return;
			}
			setTimeNow(() => new Date());
		}, 60000);

		setTimeNow(() => new Date());

		return () => {
			clearTimeout(time);
		};
	}, [start, end]);

	let label = "";

	if (start instanceof Date && end instanceof Date) {
		if (start > timeNow) {
			label = `Essa fase iniciará aproximadamente em ${timeNow.getDateDifference(start)}`;
		} else if (timeNow < end) {
			label = `Essa fase encerrará aproximadamente em ${timeNow.getDateDifference(start)}`;
		} else {
			const date = end.getDate();
			const month = end.getMonth() + 1;
			const hours = end.getHours();
			const minutes = end.getMinutes();

			label = `Essa fase já se encerrou em ${enumer(date)}/${enumer(month)}/23 - ${enumer(hours)}:${enumer(minutes)}`;
		}
	} else {
		return null;
	}

	let labelDate = "";

	if (start > timeNow) {
		labelDate = `Essa fase estará disponível em ${enumer(start.getDate())}/${enumer(start.getMonth() + 1)}/${enumer(
			start.getFullYear(),
		)} ás ${enumer(start.getHours())}:${enumer(start.getMinutes())}. `;
	} else if (timeNow < end) {
		labelDate = `Essa fase estará finalizada em ${enumer(end.getDate())}/${enumer(end.getMonth() + 1)}/${enumer(end.getFullYear())} ás ${enumer(
			end.getHours(),
		)}:${enumer(end.getMinutes())}. `;
	} else {
		labelDate = `Essa fase já foi finalizada em ${enumer(end.getDate())}/${enumer(end.getMonth() + 1)}/${enumer(end.getFullYear())} ás ${enumer(
			end.getHours(),
		)}:${enumer(end.getMinutes())}. `;
	}

	return (
		<Tooltip
			title={
				timeNow > end
					? labelDate
					: `${labelDate}Essa data pode ser adiada ou adiantada, dependendo da demanda da pré-venda da iVipCoin. Fique atendo em nossos meios de divulgações.`
			}
			placement="top-end"
		>
			<Box className={style["countdown"]}>
				<Typography variant="body2">{label}</Typography>
				<SvgIcon
					path={mdiInformation}
					style={{ fontSize: "15px", color: "currentColor", marginLeft: "10px" }}
				/>
			</Box>
		</Tooltip>
	);
};

const WalletView = ({ wallet }) => {
	if (!wallet || !(wallet instanceof Wallet)) {
		return null;
	}

	const balances = Object.values(wallet.information?.balances || {});

	return (
		<>
			<div className={style["WalletActions"]}>
				<ButtonGroup variant="contained">
					<Button
						disabled
						startIcon={<SvgIcon path={mdiCashFast} />}
						onClick={() => DepositDialog.show({ wallet: wallet.path })}
					>
						Depósito
					</Button>
					<Button
						startIcon={<SvgIcon path={mdiHandCoin} />}
						disabled
					>
						Saque
					</Button>
				</ButtonGroup>
			</div>

			<div className={style["WalletBalances"]}>
				<TableContainer component={Paper}>
					<Table aria-label="customized table">
						<TableHead>
							<TableRow>
								<TableCell size="small">Moeda</TableCell>
								<TableCell
									size="small"
									align="left"
								>
									Quantidade
								</TableCell>
								<TableCell
									size="small"
									align="left"
								>
									Valor
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Array.isArray(balances) &&
								balances.length > 0 &&
								balances.map(({ symbol, name, available, value }, i) => {
									value = parseFloat((value && typeof value === "number" ? value : 0).toFixed(3));

									return (
										<TableRow key={`MyCoins-table-${i}`}>
											<TableCell
												sx={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
												}}
											>
												<Icons
													name={symbol.toLowerCase()}
													style={{ marginRight: 15 }}
												/>{" "}
												{name || symbol}
											</TableCell>
											<TableCell align="left">{parseFloat(available)}</TableCell>
											<TableCell align="left">{currencyFormat.convert(value, wallet.currencyType)}</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</>
	);
};

const MainComponent = () => {
	const [steps, setSteps] = useState({
		start: new Date(2023, 2, 5, 20, 0, 0),
		end: new Date(2023, 2, 5, 20, 0, 0),
		currentStep: -1,
		selectStep: -1,
		phases: [],
	});
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(new Usuario());
	const [activeStep, setActiveStep] = useState(0);
	const [mainRef, elementSize] = useElementSize();
	const size = useWindowSize();

	const [wallet, setWalletUser] = useState(null);
	const { wallets } = useWallets();

	useEffect(() => {
		if (Array.isArray(wallets) && wallets.length > 0 && !(wallet instanceof Wallet)) {
			setWalletUser(() => wallets.find(({ type }) => type === "IVIPCOIN"));
		}
	}, [wallets]);

	useEffect(() => {
		if (!(wallet instanceof Wallet) || Object.keys(wallet.information).length > 0) {
			return;
		}

		WalletHelper.getWalletInfo(wallet).then((wallet) => {
			setWalletUser(() => wallet);
		});
	}, [wallet]);

	const toStep = useCallback((step) => {
		setActiveStep(step);
	}, []);

	useEffect(() => {
		if (loading && steps.selectStep >= 0) {
			toStep(Math.max(0, steps.selectStep));
			setLoading(false);
		}
	}, [loading, steps]);

	useEffect(() => {
		UserHelper.getUser()
			.then((user) => {
				setUser(() => user);
			})
			.catch(() => setUser(new Usuario()));

		let time,
			reload = true;

		const update = () => {
			if (!reload) {
				return;
			}

			APIHelper.fetch("finances/presale-status")
				.then((presale) => {
					if (presale === null || typeof presale !== "object") {
						return;
					}
					setSteps(() => presale);
					reload = presale.availableValue > 100000;
				})
				.finally(() => {
					//time = setTimeout(update, 30000);
				});
		};

		time = setTimeout(update, 500);

		return () => {
			clearTimeout(time);
		};
	}, []);

	const isMobile = Math.floor(size.width) <= 832;

	const stepsPhase = steps.phases.find(({ label, days, start, end, closure }, index, phases) => {
		const dateNow = Date.now();

		if (dateNow < steps.start && index === 0) {
			return true;
		}

		start = new Date(start);
		end = new Date(end);
		closure = isDate(closure) ? new Date(closure) : end;

		return (start <= dateNow && closure > dateNow) || (closure < dateNow && index === phases.length - 1);
	});

	let permission = user.verificarPermissao(["desenvolvedor", "root"]);
	permission = false;

	return (
		<Box
			ref={mainRef}
			className={style["main"]}
			sx={{ flexDirection: isMobile ? "column" : "" }}
		>
			<div className={style["balance"]}>
				<img
					className={style["background-chart"]}
					src={require("./background.png")}
				/>
				{steps.phases.length <= 0 && (
					<div className={style["loading"]}>
						<CircularProgress color="inherit" />
					</div>
				)}
				{steps.phases.length > 0 && (
					<>
						<Stepper
							className={style["balance-steps"]}
							activeStep={activeStep}
							nonLinear
							alternativeLabel
						>
							{steps.phases.map(({ label, days, start, end, closure }, index, phases) => {
								const phaseDate = new Date(start);
								const date = phaseDate.getDate();
								const month = phaseDate.getMonth() + 1;
								end = new Date(end);
								closure = isDate(closure) ? new Date(closure) : end;

								return (
									<Step
										key={label}
										completed={closure < Date.now() && index !== activeStep}
									>
										<StepButton
											color="inherit"
											onClick={() => {
												toStep(index);
											}}
											disableRipple
										>
											{`${enumer(date)}/${enumer(month)}/23`}
										</StepButton>
									</Step>
								);
							})}
						</Stepper>
						<div className={style["balances-carousel"]}>
							{steps.phases.map(({ label, days, price, gain, amountCoins, value, start, end, closure }, index, phases) => {
								const dateNow = new Date();

								const phaseDateStart = new Date(start);

								const phaseDateEnd = new Date(end);

								const phaseTotalPrice = parseFloat((amountCoins * price).toFixed(2));

								const phasePercentual = Math.floor(Math.min(100, Math.max(0, (value / amountCoins) * 100)));

								const phaseRemainingValue = Math.min(phaseTotalPrice, Math.max(0, (amountCoins - value) * price));

								closure = isDate(closure) ? new Date(closure) : new Date(end);

								const availablePurchase = steps.currentStep === index && phaseDateStart < dateNow && closure > dateNow;

								return (
									<div
										className={style["balance-body"]}
										key={index}
										style={{ left: `${index * 100 - activeStep * 100}%` }}
									>
										<div>
											<Typography variant="h6">{label}</Typography>
											<div className={style["currency-change"]}>
												<Typography
													variant={!isMobile ? "h4" : "h5"}
													sx={{ fontWeight: "bold" }}
												>
													{currencyFormat.convert(phaseTotalPrice, "USD")}
												</Typography>
												<LinearProgress
													className={style["linear-progress"]}
													variant="determinate"
													value={phasePercentual}
												/>
												<Typography variant="body2">
													Faltam: <b>{currencyFormat.convert(parseFloat(phaseRemainingValue.toFixed(2)), "USD")}</b>
												</Typography>
											</div>
											<Typography
												variant="caption"
												className={style["details"]}
											>
												Serão lançadas <b>{formatNumber(amountCoins)}</b> de moedas iVipCoin nessa fase, com preço{" "}
												<b>{currencyFormat.convert(price, "USD")}/unid.</b>, marcando um lucro de{" "}
												<b>{gain.toFixed(2).replaceAll(".", ",")}%</b>
											</Typography>
											{!availablePurchase && (
												<CountMoment
													start={phaseDateStart}
													end={closure < phaseDateEnd ? closure : phaseDateEnd}
												/>
											)}
											{(availablePurchase || permission) && (
												<Button
													disabled
													variant="outlined"
													onClick={() => {
														WalletHelper.showSwap({
															from: "BRL",
															to: "IVIP",
															walletId: String(wallet?.path)
																.replace(/(\/+)$/gi, "")
																.split("/")
																.pop(),
														});
													}}
												>
													Comprar
												</Button>
											)}
										</div>
									</div>
								);
							})}
						</div>
					</>
				)}
			</div>
			<div
				className={style["account"]}
				style={{ width: isMobile ? "auto" : "", maxWidth: isMobile ? "none" : "", marginLeft: isMobile ? "" : "0px" }}
			>
				<WalletView
					wallet={wallet}
					stepsPhase={stepsPhase}
				/>
			</div>
		</Box>
	);
};

export default {
	id: "Presale",
	title: "Pré-venda",
	icon: mdiHandCoin,
	render: () => {
		return <MainComponent />;
	},
};
