import React from 'react';
import { SvgIcon, SvgIconTypeMap, SxProps, Theme } from '@mui/material';

const Icon:React.FC<Partial<{
    [prop:string]: any;
    className: string;
    style: React.CSSProperties;
    path: string;
    color: 'inherit' | 'action' | 'disabled' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | string;
    fontSize: 'inherit' | 'large' | 'medium' | 'small' | string;
    sx: SxProps<Theme> | undefined;
    titleAccess: string;
    viewBox: string;
    children: React.ReactNode;
}>> = ({path, children, ...props})=>{
    return <SvgIcon {...props as any}>
        {typeof path === "string" && <path d={path} />}
        {children}
    </SvgIcon>
}

export default Icon;