import React, { useRef, useLayoutEffect } from "react";

import ModeThemeSwitch from "Components/ModeThemeSwitch";

import Wallets from "./WalletsBar";

import style from "./index.module.scss";

const ToolbarComponent = ({ themeSwitch, showWallets, onResize }) => {
	const toolbarTopRef = useRef(null);

	useLayoutEffect(() => {
		if (!toolbarTopRef.current) {
			return;
		}
		let size = toolbarTopRef.current.getBoundingClientRect();

		if (size && typeof onResize === "function") {
			onResize(size.width, size.height);
		}
	}, [toolbarTopRef.current, onResize]);

	themeSwitch = typeof themeSwitch === "boolean" ? themeSwitch : false;
	showWallets = typeof showWallets === "boolean" ? showWallets : false;

	return (
		<div
			className={style["main"]}
			ref={toolbarTopRef}
			style={{ display: !themeSwitch && !showWallets ? "none" : "" }}
		>
			<div className={style["content"]}>{!themeSwitch && showWallets && <Wallets />}</div>
			{themeSwitch && <ModeThemeSwitch size="small" />}
		</div>
	);
};

export default ToolbarComponent;
