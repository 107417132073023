import React, { Component, useState, useRef, useEffect, useLayoutEffect, useCallback } from "react";

import { useResizeObserver, useDebouncedCallback } from "@react-hookz/web";

import { useRouter } from "Utils/useHook";

import { roundPathCorners, MultiStorager, currencyFormat } from "Utils";
import { MenuItem, Menu, MenuItemOptions } from "Utils/Menu";
import { Messages, MenuBar } from "Crucial";

import { Usuario } from "Models";
import { UserHelper, APIHelper, InteractionHelper } from "Helper";
import Icon from "@mdi/react";
import { mdiCheckboxBlankCircle, mdiChevronDown, mdiCog, mdiLogout, mdiNewBox } from "@mdi/js";

import { Tooltip } from "@mui/material";

import { SvgIcon, ModeThemeSwitch, ProfilePicture } from "Components";

import style from "./index.module.scss";

const getPathToInitialRoot = (href: string): string => {
	let lengthSteps = window.routerHistory.location.pathname.split("/").length - 1;
	return (lengthSteps >= 1 ? "../".repeat(lengthSteps) : "./") + href.replace(/^(\.{1,2}\/)+/gi, "");
};

const isPath = (href: string, exact: boolean = false) => {
	if (exact === true) {
		return window.routerHistory.location.pathname === new URL(href, window.location.href).pathname;
	}

	return window.routerHistory.location.pathname.search(new URL(href, window.location.href).pathname) === 0;
};

const MenuItemComponent: React.FC<Partial<MenuItemOptions>> = ({ icon, label, active, onClick, disabled, isNew }) => {
	return (
		<div
			className={[style["MenuItem-buttom"], active ? style["active"] : "", disabled ? style["disabled"] : ""].join(" ")}
			onClick={disabled ? undefined : (onClick as any)}
		>
			<div className={style["icon"]}>
				<Icon path={typeof icon === "string" ? icon : mdiCheckboxBlankCircle} />
			</div>
			<div className={style["label"]}>{label}</div>
			{isNew && (
				<div
					className={style["icon"]}
					style={{
						color: "var(--primary-dark)",
						opacity: 1,
					}}
				>
					<Icon path={mdiNewBox} />
				</div>
			)}
		</div>
	);
};

const MenuSubmenu: React.FC<Partial<MenuItemOptions>> = ({ label, itens, onClick, disabled, isNew }) => {
	const [active, setActive] = useState<boolean>(false);
	const itensList = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setActive(!disabled);
	}, [itensList.current, disabled]);

	return (
		<div className={[style["MenuSubmenu-submenu"], active ? style["active"] : "", disabled ? style["disabled"] : ""].join(" ")}>
			<div
				className={style["content"]}
				onClick={() => {
					setActive((p) => !p);
				}}
			>
				<div className={style["label"]}>
					<span>{label}</span>
				</div>
				{isNew && (
					<div
						className={style["icon"]}
						style={{
							color: "var(--primary-dark)",
							opacity: 1,
						}}
					>
						<Icon path={mdiNewBox} />
					</div>
				)}
				<Icon path={mdiChevronDown} />
			</div>
			<div
				className={style["itens"]}
				ref={itensList}
				style={{
					maxHeight: active ? itensList?.current?.scrollHeight : 0,
				}}
			>
				{Array.isArray(itens) &&
					(itens as any[]).map((iten: MenuItem, index) => {
						return (
							<MenuItemComponent
								icon={iten.icon}
								label={iten.label}
								key={`MenuSubmenu-${index}`}
								active={iten.checkIsActive()}
								onClick={() => {
									onClick && onClick(iten);
								}}
								disabled={disabled ? true : iten.disabled ?? false}
								isNew={iten.isNew ?? false}
							/>
						);
					})}
			</div>
		</div>
	);
};

const Indicators = () => {
	const [value, setValue] = useState<string>("...,..");
	const [percentage, setpercentage] = useState<string>("..%");

	useLayoutEffect(() => {
		const getPrice = () => {
			APIHelper.fetch("binance/currency_moment/ivip", {}, 60000)
				.then(({ priceUsd, changePercent24Hr }) => {
					setValue(currencyFormat.convert(parseFloat(priceUsd), "USD", 4)?.toString() ?? "");
					changePercent24Hr = parseFloat(changePercent24Hr);
					setpercentage(`${changePercent24Hr === 0 ? "" : changePercent24Hr > 0 ? "+" : ""}${parseFloat(changePercent24Hr).toFixed(2)}%`);
				})
				.catch(() => {});
		};

		getPrice();

		const time = setInterval(getPrice, 60000);

		return () => {
			clearInterval(time);
		};
	}, []);

	return (
		<div className={style["profile-wallet"]}>
			<div>
				<div className={style["profile-wallet-prise-icon"]}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="-78 -9.5 500 500"
						role="presentation"
					>
						<path
							style={{ fill: "currentcolor" }}
							d="M78.73,215.08h89.41l46.94,164.28L203.9,419.59h8.94l82.7-285h87.17L292.19,431.89c-1.68,5-7.63,27.47-28.5,43A77.15,77.15,0,0,1,219.55,490a77.88,77.88,0,0,1-46.38-16.2c-19.85-15.43-24.59-34.65-27.38-41.91Q112.25,323.49,78.73,215.08Z"
							transform="translate(-78.05 -9.5)"
							strokeMiterlimit="10"
						/>
						<polygon
							style={{ fill: "currentcolor" }}
							points="322.88 66.44 310.81 105.55 222.52 105.55 234.17 66.44 322.88 66.44"
							strokeMiterlimit="10"
						/>
						<polygon
							style={{ fill: "currentcolor" }}
							points="335.28 26.2 329.08 46.32 240.16 46.32 246.15 26.2 335.28 26.2"
							strokeMiterlimit="10"
						/>
						<polygon
							style={{ fill: "currentcolor" }}
							points="343.22 0.5 341.5 6.09 252.14 6.09 253.81 0.5 343.22 0.5"
							strokeMiterlimit="10"
						/>
					</svg>
				</div>
				<span className={style["profile-wallet-prise-value"]}>{value}</span>
				<span
					className={[
						style["profile-wallet-prise-percent"],
						percentage.indexOf("+") === 0 ? style["green"] : percentage.indexOf("-") === 0 ? style["red"] : "",
					].join(" ")}
				>
					{percentage}
				</span>
			</div>
		</div>
	);
};

const MenubarComponent: React.FC<{ onPage?: () => void }> = ({ onPage }) => {
	const [rect, setRect] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
	const { pathname } = useRouter();

	const [usuario, setUsuario] = useState<Usuario>(new Usuario());

	const menubarComponentRef = useRef<HTMLDivElement | null>(null);
	const menubarPreviousRef = useRef<HTMLDivElement | null>(null);
	const menubarNextRef = useRef<HTMLDivElement | null>(null);
	const menubarScrollRef = useRef<HTMLDivElement | null>(null);

	const updateSize = useDebouncedCallback(
		(e) => {
			setRect(e.contentRect);
		},
		[],
		200,
	);

	useResizeObserver(menubarComponentRef, updateSize);

	const actionMenuItem = useCallback(
		(iten: MenuItem) => {
			if (iten.disabled) {
				InteractionHelper.toast(Messages.getMsg("DISABLED_MENU"), "", "warning");
				return;
			}
			if (typeof iten.click === "function") {
				iten.click();

				if (typeof onPage === "function") {
					onPage();
				}
			}
		},
		[onPage],
	);

	const updateUser = useCallback(() => {
		UserHelper.getUser().then((user) => {
			setUsuario(() => user);
		}).catch(()=>{
            setUsuario(new Usuario());
        });
	}, []);

	useLayoutEffect(() => {
		const event = MultiStorager.DataStorager.addListener("usuario", () => {
			updateUser();
		});

		updateUser();

		return () => {
			event?.stop();
		};
	}, [updateUser]);

	const goToSettings = () => {
		const href = "./settings";
		if (isPath(href)) {
			return;
		}

		window.routerHistory.push(getPathToInitialRoot(href));

		if (typeof onPage === "function") {
			onPage();
		}
	};

	const { nome, fotoPerfil } = (usuario as any) ?? {};

	return (
		<div
			className={[style["main"], rect.width > 150 ? style["expanded"] : ""].join(" ")}
			ref={menubarComponentRef}
		>
			<div>
				<div className={style["space-logo"]}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="-78 -9.5 500 500"
						role="presentation"
					>
						<path
							style={{ fill: "currentcolor" }}
							d="M78.73,215.08h89.41l46.94,164.28L203.9,419.59h8.94l82.7-285h87.17L292.19,431.89c-1.68,5-7.63,27.47-28.5,43A77.15,77.15,0,0,1,219.55,490a77.88,77.88,0,0,1-46.38-16.2c-19.85-15.43-24.59-34.65-27.38-41.91Q112.25,323.49,78.73,215.08Z"
							transform="translate(-78.05 -9.5)"
							strokeMiterlimit="10"
						/>
						<polygon
							style={{ fill: "currentcolor" }}
							points="322.88 66.44 310.81 105.55 222.52 105.55 234.17 66.44 322.88 66.44"
							strokeMiterlimit="10"
						/>
						<polygon
							style={{ fill: "currentcolor" }}
							points="335.28 26.2 329.08 46.32 240.16 46.32 246.15 26.2 335.28 26.2"
							strokeMiterlimit="10"
						/>
						<polygon
							style={{ fill: "currentcolor" }}
							points="343.22 0.5 341.5 6.09 252.14 6.09 253.81 0.5 343.22 0.5"
							strokeMiterlimit="10"
						/>
					</svg>
					<span>iVipCoin</span>
				</div>
				<div className={style["space-profile"]}>
					<div
						className={style["profile-info"]}
						onClick={() => {
							window.routerHistory.push("/profile");
							onPage?.();
						}}
					>
						<div>
							<ProfilePicture
								src={fotoPerfil}
								size={40}
								onClick={undefined}
								insignia={undefined}
								loading={undefined}
							/>
						</div>
						<div className={style["profile-account"]}>
							<div>{nome}</div>
							<div className={style["profile-type"]}>Nível Prata</div>
						</div>
					</div>
					<Indicators />
				</div>
			</div>
			<div className={style["space-menu"]}>
				<div
					ref={menubarScrollRef}
					className={style["list"]}
				>
					{MenuBar.setPermissions(usuario.permissoes).map((iten, index) => {
						if (iten.isItem()) {
							return (
								<MenuItemComponent
									icon={iten.icon}
									label={iten.label}
									key={`MenuItem-${index}`}
									active={(iten as MenuItem).checkIsActive()}
									onClick={() => {
										actionMenuItem(iten as any);
									}}
									disabled={iten.disabled ?? false}
									isNew={iten.isNew ?? false}
								/>
							);
						} else {
							return (
								<MenuSubmenu
									label={iten.label}
									key={`MenuItem-${index}`}
									onClick={(iten) => {
										actionMenuItem(iten);
									}}
									itens={iten.itens}
									disabled={iten.disabled ?? false}
									isNew={iten.isNew ?? false}
								/>
							);
						}
					})}
				</div>

				<div className={style["space-action"]}>
					<Tooltip
						title={Messages.getMsg("SIGN-OUT")}
						placement="top"
					>
						<div
							className={style["btn"]}
							onClick={UserHelper.logout}
						>
							<SvgIcon path={mdiLogout} />
						</div>
					</Tooltip>
					<Tooltip
						title={Messages.getMsg("SETTINGS")}
						placement="top"
					>
						<div
							className={[style["btn"], isPath("./settings") ? style["action"] : ""].join(" ")}
							onClick={goToSettings}
						>
							<SvgIcon path={mdiCog} />
						</div>
					</Tooltip>
					<ModeThemeSwitch size={"small"} />
				</div>
			</div>
		</div>
	);
};

export default MenubarComponent;
