export const debounce = (func, delay)=>{
    let timeout;
    return function(){
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), delay);
    };
}

export const throttle = (func, delay)=>{
    let wait = false;

    return (...args) => {
        if(wait){ return; }

        func(...args);
        wait = true;
        setTimeout(()=>{
            wait = false;
        }, delay);
    }
}

export const once = (func)=>{
    let ran = false;
    let result;

    return function(){
        if(ran) return result;
        result = func.apply(this, arguments);
        ran = true;
        return result;
    };
}

export const memoize = (func)=>{
    const cache = new Map();
    return function(){
        const key = JSON.stringify(arguments);
        if(cache.has(key)){
            return cache.get(key);
        }
        const result = func.apply(this, arguments);
        cache.set(key, result);
        return result;
    };
}

export const curry = (func, arity = func.length)=>{
    return function curried(...args){
        if(args.length >= arity) return func(...args);

        return function(...moreArgs) {
            return curried(...args, ...moreArgs);
        };
    };
}

export const partial = (func, ...args)=>{
    return function(...moreArgs){
        return func(...args, ...moreArgs);
    }
}

export const pipe = (...funcs)=>{
    return function(...args){
        return funcs.reduce((result, func) => [func.call(this, ...result)], args)[0];
    };
}

export const compose = (...funcs)=>{
    return function(...args){
        return funcs.reduceRight((result, func) => [func.call(this, ...result)], args)[0];
    };
}

export const objectPick = (obj, keys)=>{
    return keys.reduce((acc, key)=>{
        if(obj.hasOwnProperty(key)){
            acc[key] = obj[key];
        }
        return acc;
    }, {});
}