import React, { useState, useEffect, useRef } from "react";

import { TextField, InputAdornment, IconButton, InputProps, OutlinedInputProps, FilledInputProps } from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";

type InputType = "email" | "number" | "password" | "search" | "tel" | "text" | "time" | "url" | "date" | "datetime-local";

const inputTypes = ["email", "number", "password", "search", "tel", "text", "time", "url", "date", "datetime-local"];

const inputValidationTypes = [
	/^([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}$/i, //Email Validation
	/[\d\.\,]+/i, //number Validation
	/(.){8,}/i, //password Validation
	/.+/i, //search Validation
	/^(\([0-9]{2}\))\s?([9]{1})?\s?([0-9]{4,5})\-([0-9]{4})$/i, //tel Validation
	/.+/i, //text Validation
	/^((([0-1]?[0-9])|([2][0-3])):)?(([0-5][0-9]):)?([0-5][0-9])(\.\d{1,3})?$|^\d+(\.\d{1,3})?$/i, //time Validation
	/^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i, //url Validation
	/^([0-9]{4})((-[0-9]{2}){2})$/i, //date Validation
	/^(([0-9]{4})((-[0-9]{2}){2}(( |T)?([0-9]{2}:){2}[0-9]{2}((\+[0-9]{2}(:[0-9]{2})?)|Z)?)?|(-W[0-9]{2}(-[1-7])?)|(-[1-3]?[0-9]{2})))$/i, //datetime-local Validation
];

const nomalizeInputType = (type: InputType | undefined): string => {
	return type && inputTypes.includes(type.toLocaleLowerCase()) ? String(type).toLocaleLowerCase() : "text";
};

const convertMask = (value: string | undefined, mask: Array<string | RegExp> = []) => {
	if ((mask && Array.isArray(mask) && mask.length > 0) !== true) {
		return value ?? "";
	}
	let temp = String(value),
		result = "";

	mask?.forEach((m, i) => {
		if (typeof m === "string" && temp.length >= 1) {
			result += m;
		} else if (m instanceof RegExp) {
			let str: any = temp.match(m);
			if (!str || str.length < 1) {
				return;
			}
			str = str[0];
			if (temp.search(str) === 0) {
				temp = temp.substring(str.length, temp.length);
				result += str;
			}
		}
	});

	return result;
};

const invertMask = (value: string | undefined, mask: Array<string | RegExp> = []) => {
	if ((mask && Array.isArray(mask) && mask.length > 0) !== true) {
		return value ?? "";
	}
	let temp = String(value),
		result = "";

	mask?.forEach((m) => {
		if (m instanceof RegExp) {
			let str: any = temp.match(m);
			if (!str || str.length < 1) {
				return;
			}
			str = str[0];
			if (temp.search(str) >= 0) {
				temp = temp.substring(temp.search(str) + str.length, temp.length);
				result += str;
			}
		}
	});

	return result;
};

const InputCustom: React.FC<{
	[prop: string]: any;
	onChange?: (value: string | number, maskValid: boolean) => void;
	disabled?: boolean;
	value?: string;
	label?: string;
	validate?: RegExp | boolean;
	helperText?: string;
	readOnly?: boolean;
	startAdornment?: string | JSX.Element;
	endAdornment?: string | JSX.Element;
	type?: InputType;
	InputProps?: Partial<InputProps> | Partial<OutlinedInputProps> | Partial<FilledInputProps>;
	mask?: Array<string | RegExp>;
	maskAmount?: boolean;
}> = ({
	onChange: onChange_,
	disabled: disabled_,
	value: value_,
	label,
	validate,
	helperText,
	readOnly,
	startAdornment,
	endAdornment,
	type: type_,
	InputProps,
	mask,
	maskAmount,
	...props
}) => {
	const [id] = useState(`InputCustom-${Math.floor(Math.random() * 10000)}`);
	const [show, setShow] = useState<boolean>(false);
	const [value, setValue] = useState<string>(value_ || "");
	const [type, setType] = useState<string>(nomalizeInputType(type_));
	const [disabled, setDisabled] = useState(disabled_);
	const [selection, setSelection] = useState(0);

	const inputRef = useRef<any>(null);

	const reg = validate instanceof RegExp ? validate : inputValidationTypes[inputTypes.indexOf(type)];
	const error = RegExp(reg).test(value) !== true;

	useEffect(() => {
		setValue(convertMask(invertMask(value_, mask), mask) ?? "");
		setType(nomalizeInputType(type_));
		setDisabled(disabled_);
	}, [value_, type_, disabled_, readOnly]);

	const handleClickShow = () => {
		setShow(!show);
	};

	const handleMouseDown: React.MouseEventHandler<HTMLButtonElement> = (event) => {
		event.preventDefault();
	};

	useEffect(() => {
		if (!inputRef.current || typeof inputRef.current.selectionStart !== "number") {
			return;
		}
		inputRef.current.selectionStart = selection;
		inputRef.current.selectionEnd = selection;
		//inputRef.current.focus();
	}, [value, selection]);

	const onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = ({ target }) => {
		if (disabled || readOnly) {
			return;
		}
		let value = convertMask(invertMask(target.value, mask), mask),
			start = inputRef.current.selectionStart;
		start = convertMask(invertMask(target.value.substring(0, start), mask), mask).length;

		setValue(value);
		setSelection(start);

		if (typeof onChange_ === "function") {
			let result = typeof maskAmount === "boolean" && maskAmount === true ? value : invertMask(value);
			onChange_(result, RegExp(reg).test(value));
		}
	};

	const getStartAdornment = () => {
		return <InputAdornment position="start">{startAdornment}</InputAdornment>;
	};

	const getEndAdornment = () => {
		return (
			<InputAdornment position="end">
				{type === "password" && (
					<IconButton
						aria-label="toggle password visibility"
						onClick={handleClickShow}
						onMouseDown={handleMouseDown}
						size="small"
						edge="end"
						disabled={disabled}
						disableRipple
					>
						{show ? <VisibilityOff /> : <Visibility />}
					</IconButton>
				)}
				{endAdornment}
			</InputAdornment>
		);
	};

	InputProps = InputProps && typeof InputProps === "object" ? InputProps : {};

	InputProps.startAdornment = startAdornment ? getStartAdornment() : null;
	InputProps.endAdornment = type === "password" || endAdornment ? getEndAdornment() : null;
	InputProps.readOnly = typeof readOnly == "boolean" ? readOnly : false;

	return (
		<TextField
			key={id}
			id={id}
			autoComplete={"off"}
			variant="outlined"
			size="small"
			fullWidth
			margin="none"
			{...props}
			type={type === "password" ? (show ? "text" : "password") : type}
			label={label}
			disabled={disabled}
			InputProps={InputProps}
			error={(validate || (validate as any) instanceof RegExp) && error}
			value={value || ""}
			onChange={onChange}
			helperText={helperText}
			inputRef={inputRef}
		/>
	);
};

export default InputCustom;
