import React, { useEffect, useState } from "react";

import { Typography, TextField, FormControl, InputLabel, Select, MenuItem, Slider } from "@mui/material";

import style from "../style.module.scss";

import { maskAmount } from "../Utils";

import { InstallmentSelect, SimulateAmountCosts } from "Components";

const SimulateRenegotiation = ({ renegotiationProps, onChange }) => {
	const props = new Proxy(typeof renegotiationProps === "object" ? renegotiationProps : {}, {
		set: function (target, property, value, receiver) {
			target[property] = value;
			if (typeof onChange === "function") {
				onChange(target);
			}
			return true;
		},
	});

	const [id_1] = useState(`simulate-renegotiation-${Math.round(Math.random() * 10000)}`);
	const [installments, setInstallments] = useState(1);
	const [typeAmount, setTypeAmount] = useState("");

	const handleChangeTypeAmount = ({ target }) => {
		props.typeAmount = target.value;
		setTypeAmount(target.value);
	};

	useEffect(() => {
		if (Array.isArray(props.response.types_installments) && props.response.types_installments.length) {
			props.typeAmount = props.response.types_installments[0].type;
		} else {
			props.typeAmount = "";
		}

		setTypeAmount(props.typeAmount);
	}, [renegotiationProps]);

	useEffect(() => {
		props.installments = installments;
	}, [installments]);

	let costs,
		payer_costs = [],
		original_amount = typeof props.response.original_amount === "number" ? props.response.original_amount : 0,
		original_amount_paid = typeof props.response.original_amount_paid === "number" ? props.response.original_amount_paid : 0,
		totalAmount = original_amount - original_amount_paid;

	if (Array.isArray(props.response.types_installments) && props.response.types_installments.length) {
		payer_costs = props.response.payer_costs.filter(({ type }) => type === typeAmount);
		costs = payer_costs[installments - 1];
	}

	return (
		<div className={style["dialogPayment"]}>
			<div className={style["description"]}>
				Valor pago: {maskAmount(original_amount_paid)} de um total de {maskAmount(original_amount)}. Resta renegociar o valor restante de{" "}
				{maskAmount(totalAmount)}
			</div>

			<InstallmentSelect
				defaultInstallment={installments}
				installments={payer_costs.length - 1}
				onChange={(installment) => {
					setInstallments(installment);
				}}
			/>

			<FormControl
				size="small"
				fullWidth
			>
				<InputLabel id={id_1}>Tipo de Vencimento</InputLabel>
				<Select
					id={id_1}
					value={typeAmount}
					label="Tipo de Vencimento"
					onChange={handleChangeTypeAmount}
				>
					{props.response.types_installments.map(({ type, name }, index) => {
						return (
							<MenuItem
								value={type}
								key={index}
							>
								{name}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>

			{typeof props.response.description === "string" && props.response.description.trim() !== "" && (
				<div className={style["description"]}>
					<Typography
						variant="h6"
						gutterBottom
					>
						Descrição do contrato a renegociar:
					</Typography>
					<Typography
						variant="body2"
						gutterBottom
					>
						{props.response.description}
					</Typography>
				</div>
			)}

			<SimulateAmountCosts
				amount={totalAmount}
				typeAmount={"renegotiation"}
				costs={costs}
			/>
		</div>
	);
};

export default SimulateRenegotiation;
