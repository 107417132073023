import { APIHelper, InteractionHelper } from "Helper";
import { SimulatePurchase } from "../Components";
import { ViewPaymentDialog } from "Dialog";

const showSwap = (props, stage = 0) => {
	props = Object.assign(
		{
			amount: 0,
			from: "BRL",
			to: "IVIP",
			walletId: null,
			walletType: "IVIPCOIN",
			response: {},
		},
		props,
	);

	props.from = String(props.from).trim().toUpperCase();
	props.to = String(props.to).trim().toUpperCase();

	let title = "Swap";
	let body = <div></div>;

	InteractionHelper.close();

	let onConfirm = () => {
		showSwap(props, stage + 1);
	};

	if (stage === 1) {
		InteractionHelper.loading();
		try {
			APIHelper.fetch(`swap/${props.walletId}`, {
				from: props.from,
				to: props.to,
				amount: props.amount,
			})
				.then((response) => {
					if (!response || !response.history_id) {
						return Promise.reject();
					}
					props.response = response;
					showSwap(props, 2);
				})
				.catch((e) => {
					const errorMsg =
						e && typeof e.message === "string" && e.message.trim() !== ""
							? e.message
							: "Algo deu errado, verifique sua conexão e tente novamente!";
					InteractionHelper.close();
					InteractionHelper.toast(errorMsg, null, "error");
					props.response = {};
					showSwap(props, 0);
				});
		} catch {
			showSwap(props, 0);
		}
		return;
	} else if (stage === 2) {
		ViewPaymentDialog.show({ wallet: props.walletId, paymentId: props.response.history_id });
		return;
	} else {
		body = (
			<SimulatePurchase
				swapProps={props}
				onChange={(propsChanged) => {
					props = Object.assign(props, propsChanged);
				}}
			/>
		);

		// InteractionHelper.loading();
		// APIHelper.fetch("finances/presale-status").then((response)=>{
		//     InteractionHelper.close();
		//     props.presale = response;
		//     showSwap(props, 1);
		// }).catch(()=>{
		//     InteractionHelper.close();
		//     InteractionHelper.toast("Algo deu errado, verifique sua conexão e tente novamente!", null, "error");
		// });
		// return;
	}

	InteractionHelper.confirm(body, title)
		.then(onConfirm)
		.catch(() => {});
};

export default showSwap;
