import React, { useState, useEffect, useLayoutEffect, useDeferredValue, useCallback, useRef, EventHandler } from "react";
import { useDebouncedCallback } from "@react-hookz/web";
import { MultiStorager } from "Utils";

import ToolbarComponent from "../ToolbarComponent";
import MenubarComponent from "../MenubarComponent";

import { mdiArrowSplitVertical, mdiBell, mdiChevronRight, mdiMenu } from "@mdi/js";

import { CurrencyIndex, SvgIcon } from "Components";

import { MenuBar } from "Crucial";

import { OverlayScrollbarsComponent, OverlayScrollbarsComponentRef } from "overlayscrollbars-react";

import style from "./index.module.scss";
import { useDataStorager } from "Utils/MultiStorager";
import { useRouter } from "Utils/useHook";
import { Divider } from "@mui/material";
import { InteractionHelper } from "Helper";

const NotificationButton: React.FC<{}> = () => {
	return (
		<div
			onClick={() => {
				InteractionHelper.toggleNotifications();
			}}
		>
			<SvgIcon path={mdiBell} />
		</div>
	);
};

const MenuSummaryItensComponent: React.FC<{}> = () => {
	const [select, setSelect] = useState(-1);
	const { pathname } = useRouter();

	return (
		<>
			{MenuBar.simplified.splice(0, 3).map((iten, index) => {
				return (
					<div
						key={index}
						className={iten.checkIsActive() ? style["active"] : undefined}
						onClick={() => {
							if (typeof iten.click === "function") {
								iten.click();
							}
							setSelect(index);
						}}
					>
						<SvgIcon path={iten.icon} />
					</div>
				);
			})}
		</>
	);
};

const Main: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const [disableMenu] = useDataStorager<boolean>("MainFrame_disableMenu", true);
	const [showWallets] = useDataStorager<boolean>("MainFrame_showWallets", true);
	const [disableCurrencyIndex] = useDataStorager<boolean>("MainFrame_disableCurrencyIndex", true);
	const [themeSwitchToolbar] = useDataStorager<boolean>("MainFrame_themeSwitchToolbar", false);
	const [md_size, setMd_size] = useState(false);
	const { pathname } = useRouter();

	const [menuMobileShow, setMenuMobileShow] = useState(false);

	const [hiddenMobileMenuSummary] = useDataStorager<boolean>("MainFrame_hidden_menu_summary", false);

	const clientWidth = useRef<number>(225);
	const backupClientWidth = useRef<number>(225);
	const xDividerPos = useRef<number | null>(null);
	const xDividerDirection = useRef<number>(0);

	const scrollPosition = useRef<number>(0);

	const split_menu_ref = useRef<HTMLDivElement>(null);
	const split_bar_ref = useRef<HTMLDivElement>(null);
	const mobile_menu_ref = useRef<HTMLDivElement>(null);
	const mobile_menu_backdrop_ref = useRef<HTMLDivElement>(null);
	const mobile_menu_summary_ref = useRef<HTMLDivElement>(null);

	const scrollRef = useRef<OverlayScrollbarsComponentRef>(null);

	const updateMdSize = useDebouncedCallback(
		(e) => {
			setMd_size((p) => window.innerWidth < 900);
		},
		[],
		200,
	);

	useEffect(() => {
		const time = setTimeout(() => {
			const osInstance = scrollRef.current?.osInstance();
			if (!osInstance) {
				return;
			}

			const { scrollOffsetElement } = osInstance.elements();

			scrollOffsetElement.scrollTo({
				behavior: "instant",
				left: 0,
				top: 0,
			});
		}, 10);

		return () => {
			clearTimeout(time);
		};
	}, [pathname, scrollRef.current]);

	const onTouch = (evt: TouchEvent) => {
		if (evt.touches.length > 1 || (evt.type === "touchend" && evt.touches.length > 0)) return;
		//evt.preventDefault();

		const newEvt = document.createEvent("MouseEvents");
		let type: string | null = null;
		let touch = null;

		switch (evt.type) {
			case "touchstart":
				type = "mousedown";
				touch = evt.changedTouches[0];
				break;
			case "touchmove":
				type = "mousemove";
				touch = evt.changedTouches[0];
				break;
			case "touchend":
				type = "mouseup";
				touch = evt.changedTouches[0];
				break;
		}

		if (typeof type === "string") {
			newEvt.initMouseEvent(
				type,
				true,
				true,
				(evt.target as any)?.ownerDocument?.defaultView,
				0,
				touch?.screenX ?? 0,
				touch?.screenY ?? 0,
				touch?.clientX ?? 0,
				touch?.clientY ?? 0,
				evt.ctrlKey,
				evt.altKey,
				evt.shiftKey,
				evt.metaKey,
				0,
				null,
			);
		}

		evt.target?.dispatchEvent(newEvt);

		return true;
	};

	const onMouseHoldDown: React.MouseEventHandler<HTMLDivElement> = (e) => {
		e.preventDefault();
		xDividerPos.current = e.clientX;
		clientWidth.current = e.clientX;
	};

	useLayoutEffect(() => {
		updateMdSize(null);
		xDividerDirection.current = 0;
		setMenuMobileShow(false);
	}, [disableMenu, hiddenMobileMenuSummary]);

	useLayoutEffect(() => {
		const onResize = () => {
			updateMdSize(null);
		};

		onResize();
		window.addEventListener("resize", onResize);

		return () => {
			window.removeEventListener("resize", onResize);
		};
	}, []);

	useLayoutEffect(() => {
		if (md_size) {
			backupClientWidth.current = clientWidth.current;
			clientWidth.current = 0;
		} else {
			clientWidth.current = backupClientWidth.current;
		}

		if (split_menu_ref.current) split_menu_ref.current.style.minWidth = `${clientWidth.current}px`;

		const onMouseHoldUp = () => {
			if (!xDividerPos.current) {
				return;
			}
			xDividerPos.current = null;
			if (md_size) {
				const menuMobileShow = xDividerDirection.current >= 1;

				if (mobile_menu_ref.current) {
					mobile_menu_ref.current.style.left = `0px`;
					mobile_menu_ref.current.style.transform = !menuMobileShow ? `translateX(-100%)` : `translateX(0%)`;
					mobile_menu_ref.current.style.transitionDuration = ``;
				}

				if (mobile_menu_backdrop_ref.current) {
					mobile_menu_backdrop_ref.current.style.zIndex = !menuMobileShow ? "-1" : "2";
					mobile_menu_backdrop_ref.current.style.opacity = !menuMobileShow ? "0" : "1";
				}

				setMenuMobileShow(menuMobileShow);
			} else {
				clientWidth.current = clientWidth.current > 150 ? clientWidth.current : 0;
				if (split_menu_ref.current) split_menu_ref.current.style.minWidth = `${clientWidth.current}px`;
			}
		};

		const onMouseHoldMove = (e: MouseEvent) => {
			if (!xDividerPos.current) {
				return;
			}
			e.preventDefault();

			if (md_size) {
				if (mobile_menu_ref.current) {
					const { width, right } = mobile_menu_ref.current.getBoundingClientRect();
					clientWidth.current = clientWidth.current + e.clientX - xDividerPos.current;
					const translateX = Math.min(100, Math.max(0, 100 - (clientWidth.current / width) * 100));
					mobile_menu_ref.current.style.transform = `translateX(-${translateX}%)`;
					mobile_menu_ref.current.style.transitionDuration = `0s`;

					if (mobile_menu_backdrop_ref.current) {
						mobile_menu_backdrop_ref.current.style.zIndex = "2";
						mobile_menu_backdrop_ref.current.style.opacity = `${1 - parseFloat((translateX / 100).toFixed(3))}`;
					}
				}

				if (Math.abs(e.clientX - xDividerPos.current) > 5) {
					xDividerDirection.current = e.clientX > xDividerPos.current ? 1 : e.clientX < xDividerPos.current ? -1 : 0;
				}
			} else {
				clientWidth.current = Math.max(55, Math.round(Math.min(300, clientWidth.current + e.clientX - xDividerPos.current)));
				if (split_menu_ref.current) split_menu_ref.current.style.minWidth = `${clientWidth.current}px`;
			}

			xDividerPos.current = e.clientX;
		};

		document.addEventListener("mouseup", onMouseHoldUp);
		document.addEventListener("mousemove", onMouseHoldMove);
		document.addEventListener("touchend", onTouch, { passive: false });
		document.addEventListener("touchmove", onTouch, { passive: false });

		return () => {
			document.removeEventListener("mouseup", onMouseHoldUp);
			document.removeEventListener("mousemove", onMouseHoldMove);
			document.removeEventListener("touchend", onTouch);
			document.removeEventListener("touchmove", onTouch);
		};
	}, [md_size]);

	useEffect(() => {
		if (!md_size || !mobile_menu_ref.current) {
			return;
		}
		mobile_menu_ref.current.style.transform = !menuMobileShow ? `translateX(-100%)` : `translateX(0%)`;

		if (mobile_menu_backdrop_ref.current) {
			mobile_menu_backdrop_ref.current.style.zIndex = !menuMobileShow ? "-1" : "2";
			mobile_menu_backdrop_ref.current.style.opacity = !menuMobileShow ? "0" : "1";
		}
	}, [md_size, menuMobileShow]);

	useEffect(() => {
		const childrenProps = React.Children.toArray(children).reduce((self, child) => {
			return typeof child === "object" ? { ...self, ...(child as any).props } : { ...self };
		}, {});
	}, [children]);

	return (
		<div className={[style["main"], md_size ? style["small-size"] : ""].join(" ")}>
			{!md_size && (
				<ToolbarComponent
					themeSwitch={themeSwitchToolbar}
					showWallets={showWallets}
					onResize={undefined}
				/>
			)}
			{md_size && !disableMenu && (
				<>
					<div
						className={style["mobile-menu"]}
						ref={mobile_menu_ref}
					>
						<div
							className={style["split-menu"]}
							ref={split_menu_ref}
						>
							<MenubarComponent onPage={() => setMenuMobileShow(false)} />
						</div>
						{menuMobileShow && (
							<div
								className={style["mobile-menu-dots"]}
								onClick={() => setMenuMobileShow((p) => !p)}
								style={{
									backgroundColor: menuMobileShow
										? "var(--MenubarComponent-mobile-background)"
										: "var(--WindowComponent-content-background)",
								}}
							>
								<SvgIcon
									path={mdiChevronRight}
									style={{
										transform: `rotate(${menuMobileShow ? -180 : 0}deg)`,
									}}
								/>
							</div>
						)}
						<div
							className={style["split-bar"]}
							ref={split_bar_ref}
							onMouseDown={onMouseHoldDown}
							onTouchStart={onTouch as any}
						>
							<SvgIcon
								path={mdiArrowSplitVertical}
								children={undefined}
							/>
						</div>
					</div>
				</>
			)}
			{md_size && !disableMenu && (
				<div
					className={style["mobile-menu-backdrop"]}
					ref={mobile_menu_backdrop_ref}
					onClick={() => setMenuMobileShow(false)}
				></div>
			)}
			{md_size && !disableMenu && (
				<div
					ref={mobile_menu_summary_ref}
					className={[style["menu-summary"], hiddenMobileMenuSummary ? style.forceHidden : ""].join(" ")}
				>
					<div onClick={() => setMenuMobileShow((p) => !p)}>
						<SvgIcon path={mdiMenu} />
					</div>
					<MenuSummaryItensComponent />
					<Divider
						orientation="vertical"
						variant="middle"
						flexItem
					/>
					<NotificationButton />
				</div>
			)}
			<div className={[style["view"], disableMenu && disableCurrencyIndex ? style["full-view"] : ""].join(" ")}>
				{!(md_size || disableMenu) && (
					<div
						className={style["split-menu"]}
						ref={split_menu_ref}
						style={{
							minWidth: `${clientWidth.current}px`,
						}}
					>
						<MenubarComponent onPage={undefined} />
					</div>
				)}
				{!(md_size || disableMenu) && (
					<div
						className={style["split-bar"]}
						ref={split_bar_ref}
						onMouseDown={onMouseHoldDown}
						onTouchStart={onTouch as any}
					>
						<SvgIcon
							path={mdiArrowSplitVertical}
							children={undefined}
						/>
					</div>
				)}
				<div
					className={style["container"]}
					style={{ paddingBottom: 0 }}
				>
					<OverlayScrollbarsComponent
						ref={scrollRef}
						className={style["content"]}
						defer
						events={{
							scroll: (c: any, e: any) => {
								const scrollTop = (e?.target as any)?.scrollTop ?? 0;

								if (Math.abs(scrollPosition.current - scrollTop) > 15 || scrollTop < 5) {
									if (mobile_menu_summary_ref.current && !hiddenMobileMenuSummary) {
										if (scrollTop < 5 && mobile_menu_summary_ref.current.classList.contains(style.hidden)) {
											mobile_menu_summary_ref.current.classList.remove(style.hidden);
										} else {
											if (
												scrollTop > scrollPosition.current &&
												mobile_menu_summary_ref.current.classList.contains(style.hidden) !== true
											) {
												mobile_menu_summary_ref.current.classList.add(style.hidden);
											} else if (
												scrollTop < scrollPosition.current &&
												mobile_menu_summary_ref.current.classList.contains(style.hidden)
											) {
												mobile_menu_summary_ref.current.classList.remove(style.hidden);
											}
										}
									}

									scrollPosition.current = scrollTop;
								}
							},
						}}
						options={
							{
								overflow: {
									y: "scroll",
									x: "hidden",
								},
								scrollbars: {
									autoHide: "move",
								},
								update: {
									debounce: 1000,
								},
							} as any
						}
					>
						{children}
					</OverlayScrollbarsComponent>
				</div>
			</div>
			{!disableCurrencyIndex && <CurrencyIndex />}
			{!disableCurrencyIndex && (
				<div className={style["notification-button"]}>
					<NotificationButton />
				</div>
			)}
		</div>
	);
};

export default Main;
