const validatePhone = function (phone) {
    if (typeof phone !== "string") {
        return false;
    }
    var regex = new RegExp('^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9?[0-9]{3}[0-9]{5}))$');
    return regex.test(String(phone).replace(/\D/gi, ""));
};

const validateUrl = function (url) {
  if (typeof url !== "string") {
    return false;
  }
  var regex = /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/gi;

  regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  
  return regex.test(url);
};

const isUrlImageValid = async function (imageSrc) {
  function checkImage(good, bad) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.onload = () => {
        resolve(true);
      };
      img.onerror = () => {
        resolve(false);
      };
      img.src = imageSrc;
    });
  }

  let isImg = await checkImage();
  return isImg;
};

const validateEmail = function (email) {
  if (typeof email !== "string") {
    return false;
  }
  var regex = /^\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b$/gi;
  return regex.test(email);
};

const validatePassword = function (password) {
  if (typeof password !== "string") {
    return false;
  }
  var regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;
  return regex.test(password);
};

export {
  validatePhone,
  validateUrl,
  isUrlImageValid,
  validateEmail,
  validatePassword,
};
