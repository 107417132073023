const convertFloadToReal = function (valor: number | string) {
	if (typeof valor !== "number") {
		return valor;
	}
	valor = Number(valor).toFixed(2);
	let inteiro = null,
		decimal = null,
		c = valor.indexOf(".", 0);
	if (c > 0) {
		inteiro = valor.substring(0, c);
		decimal = valor.substring(c + 1, valor.length);
	} else {
		inteiro = valor;
	}
	let aux = [];
	for (let j = inteiro.length, c = 0; j > 0; j -= 3, c++) {
		aux[c] = inteiro.substring(j - 3, j);
	}
	inteiro = aux.join(".");
	return inteiro + "," + decimal;
};

const roundToTwoDecimals = (number: number | string, decimalMargin?: number) => {
	number = parseFloat(number as string) ?? 0;
	decimalMargin = Math.min(16, Math.max(1, typeof decimalMargin === "number" ? decimalMargin : 2));
	let decimal: RegExpMatchArray | null | number = (number.toString().split(".")[1] ?? "00").match(/^0+/);
	decimal = decimal ? decimal[0].length : 0;
	let rounded = number.toFixed(decimal >= 1 ? decimal + decimalMargin : 2);
	return parseFloat(rounded);
};

type NumberFormatOptions = {
	style?: "decimal" | "currency" | "percent";
	currency?: string;
	currencyDisplay?: "symbol" | "code" | "name";
	useGrouping?: boolean;
	minimumIntegerDigits?: number;
	minimumFractionDigits?: number;
	maximumFractionDigits?: number;
	minimumSignificantDigits?: number;
	maximumSignificantDigits?: number;
};

class currencyFormat {
	/**
	 * @param {string|number} value
	 * @param {string} currencyType
	 * @returns {number|string}
	 */
	static convert(value: string | number, currencyType?: string | null, decimalMargin?: number): number | string | undefined {
		try {
			const displaySymbol = currencyType && this.indexOf(currencyType) >= 0;
			const labelCurrency = !displaySymbol && typeof currencyType === "string" && currencyType.trim() !== "" ? currencyType.toUpperCase() : "";
			currencyType = !currencyType || this.indexOf(currencyType) < 0 ? "USD" : currencyType;

			//@ts-ignore
			const locale: string = navigator.language || navigator.userLanguage;
			const opt = { style: "currency", currency: currencyType };

			value = typeof value === "string" ? roundToTwoDecimals(value, decimalMargin).toString() : roundToTwoDecimals(value, decimalMargin);

			if (typeof value === "string") {
				const { thousand, decimal } = this.getSeparators();
				return parseFloat(
					(value as any)
						.replaceAll(thousand, "")
						.replaceAll(decimal, ".")
						.match(/([\d\.]+)/gi)
						.pop(),
				);
			}

			if (typeof value !== "number") {
				return undefined;
			}

			let lenFraction: string | number = value.toString().split(".")[1];
			lenFraction = !lenFraction || lenFraction.length < 2 ? 2 : lenFraction.length;

			value = new Intl.NumberFormat(locale, Object.assign(opt, { minimumFractionDigits: lenFraction }) as NumberFormatOptions).format(value);

			return displaySymbol ? value : value.split(/\s/gi).pop();
		} catch (e) {
			return undefined;
		}
	}

	static getSeparators(): { thousand: string; decimal: string } {
		//@ts-ignore
		const locale: string = navigator.language || navigator.userLanguage;
		const thousand: string = Intl.NumberFormat(locale)
			.format(11111)
			.replace(/\p{Number}/gu, "");
		const decimal: string = Intl.NumberFormat(locale)
			.format(1.1)
			.replace(/\p{Number}/gu, "");
		return { thousand, decimal };
	}

	static secretCurrencyValue(currencyType: string | null | undefined): string {
		try {
			return (this.convert(111.11, currencyType as string) as string).replace(/1/gi, "*");
		} catch (e) {
			return "***.**";
		}
	}

	static indexOf(currencyType: string | null | undefined): number {
		return this.currencyList().findIndex((c) => c.includes(currencyType as string));
	}

	static getBy(currencyType: string | null | undefined): string[] | undefined {
		const currency = this.indexOf(currencyType);
		if (currency < 0) {
			return undefined;
		}
		return this.currencyList()[currency];
	}

	static getEntityBy(currencyType: string | null | undefined): string | undefined {
		const currency = this.getBy(currencyType);
		if (!Array.isArray(currency)) {
			return undefined;
		}
		return currency[0];
	}

	static getNameBy(currencyType: string | null | undefined): string | undefined {
		const currency = this.getBy(currencyType);
		if (!Array.isArray(currency)) {
			return undefined;
		}
		return currency[1];
	}

	static getCodeBy(currencyType: string | null | undefined): string | undefined {
		const currency = this.getBy(currencyType);
		if (!Array.isArray(currency)) {
			return undefined;
		}
		return currency[2];
	}

	static getIdentifierBy(currencyType: string | null | undefined): string | undefined {
		const currency = this.getBy(currencyType);
		if (!Array.isArray(currency)) {
			return undefined;
		}
		return currency[3];
	}

	static currencyList(): string[][] {
		return [
			["AFGHANISTAN", "Afghani", "AFN", "971"],
			["ÅLAND ISLANDS", "Euro", "EUR", "978"],
			["ALBANIA", "Lek", "ALL", "008"],
			["ALGERIA", "Algerian Dinar", "DZD", "012"],
			["AMERICAN SAMOA", "US Dollar", "USD", "840"],
			["ANDORRA", "Euro", "EUR", "978"],
			["ANGOLA", "Kwanza", "AOA", "973"],
			["ANGUILLA", "East Caribbean Dollar", "XCD", "951"],
			["ANTARCTICA", "No universal currency", "", ""],
			["ANTIGUA AND BARBUDA", "East Caribbean Dollar", "XCD", "951"],
			["ARGENTINA", "Argentine Peso", "ARS", "032"],
			["ARMENIA", "Armenian Dram", "AMD", "051"],
			["ARUBA", "Aruban Florin", "AWG", "533"],
			["AUSTRALIA", "Australian Dollar", "AUD", "036"],
			["AUSTRIA", "Euro", "EUR", "978"],
			["AZERBAIJAN", "Azerbaijan Manat", "AZN", "944"],
			["BAHAMAS (THE)", "Bahamian Dollar", "BSD", "044"],
			["BAHRAIN", "Bahraini Dinar", "BHD", "048"],
			["BANGLADESH", "Taka", "BDT", "050"],
			["BARBADOS", "Barbados Dollar", "BBD", "052"],
			["BELARUS", "Belarusian Ruble", "BYN", "933"],
			["BELGIUM", "Euro", "EUR", "978"],
			["BELIZE", "Belize Dollar", "BZD", "084"],
			["BENIN", "CFA Franc BCEAO", "XOF", "952"],
			["BERMUDA", "Bermudian Dollar", "BMD", "060"],
			["BHUTAN", "Indian Rupee", "INR", "356"],
			["BHUTAN", "Ngultrum", "BTN", "064"],
			["BOLIVIA (PLURINATIONAL STATE OF)", "Boliviano", "BOB", "068"],
			["BOLIVIA (PLURINATIONAL STATE OF)", "Mvdol", "BOV", "984"],
			["BONAIRE, SINT EUSTATIUS AND SABA", "US Dollar", "USD", "840"],
			["BOSNIA AND HERZEGOVINA", "Convertible Mark", "BAM", "977"],
			["BOTSWANA", "Pula", "BWP", "072"],
			["BOUVET ISLAND", "Norwegian Krone", "NOK", "578"],
			["BRAZIL", "Brazilian Real", "BRL", "986"],
			["BRITISH INDIAN OCEAN TERRITORY (THE)", "US Dollar", "USD", "840"],
			["BRUNEI DARUSSALAM", "Brunei Dollar", "BND", "096"],
			["BULGARIA", "Bulgarian Lev", "BGN", "975"],
			["BURKINA FASO", "CFA Franc BCEAO", "XOF", "952"],
			["BURUNDI", "Burundi Franc", "BIF", "108"],
			["CABO VERDE", "Cabo Verde Escudo", "CVE", "132"],
			["CAMBODIA", "Riel", "KHR", "116"],
			["CAMEROON", "CFA Franc BEAC", "XAF", "950"],
			["CANADA", "Canadian Dollar", "CAD", "124"],
			["CAYMAN ISLANDS (THE)", "Cayman Islands Dollar", "KYD", "136"],
			["CENTRAL AFRICAN REPUBLIC (THE)", "CFA Franc BEAC", "XAF", "950"],
			["CHAD", "CFA Franc BEAC", "XAF", "950"],
			["CHILE", "Chilean Peso", "CLP", "152"],
			["CHILE", "Unidad de Fomento", "CLF", "990"],
			["CHINA", "Yuan Renminbi", "CNY", "156"],
			["CHRISTMAS ISLAND", "Australian Dollar", "AUD", "036"],
			["COCOS (KEELING) ISLANDS (THE)", "Australian Dollar", "AUD", "036"],
			["COLOMBIA", "Colombian Peso", "COP", "170"],
			["COLOMBIA", "Unidad de Valor Real", "COU", "970"],
			["COMOROS (THE)", "Comorian Franc ", "KMF", "174"],
			["CONGO (THE DEMOCRATIC REPUBLIC OF THE)", "Congolese Franc", "CDF", "976"],
			["CONGO (THE)", "CFA Franc BEAC", "XAF", "950"],
			["COOK ISLANDS (THE)", "New Zealand Dollar", "NZD", "554"],
			["COSTA RICA", "Costa Rican Colon", "CRC", "188"],
			["CÔTE D'IVOIRE", "CFA Franc BCEAO", "XOF", "952"],
			["CROATIA", "Kuna", "HRK", "191"],
			["CUBA", "Cuban Peso", "CUP", "192"],
			["CUBA", "Peso Convertible", "CUC", "931"],
			["CURAÇAO", "Netherlands Antillean Guilder", "ANG", "532"],
			["CYPRUS", "Euro", "EUR", "978"],
			["CZECHIA", "Czech Koruna", "CZK", "203"],
			["DENMARK", "Danish Krone", "DKK", "208"],
			["DJIBOUTI", "Djibouti Franc", "DJF", "262"],
			["DOMINICA", "East Caribbean Dollar", "XCD", "951"],
			["DOMINICAN REPUBLIC (THE)", "Dominican Peso", "DOP", "214"],
			["ECUADOR", "US Dollar", "USD", "840"],
			["EGYPT", "Egyptian Pound", "EGP", "818"],
			["EL SALVADOR", "El Salvador Colon", "SVC", "222"],
			["EL SALVADOR", "US Dollar", "USD", "840"],
			["EQUATORIAL GUINEA", "CFA Franc BEAC", "XAF", "950"],
			["ERITREA", "Nakfa", "ERN", "232"],
			["ESTONIA", "Euro", "EUR", "978"],
			["ESWATINI", "Lilangeni", "SZL", "748"],
			["ETHIOPIA", "Ethiopian Birr", "ETB", "230"],
			["EUROPEAN UNION", "Euro", "EUR", "978"],
			["FALKLAND ISLANDS (THE) [MALVINAS]", "Falkland Islands Pound", "FKP", "238"],
			["FAROE ISLANDS (THE)", "Danish Krone", "DKK", "208"],
			["FIJI", "Fiji Dollar", "FJD", "242"],
			["FINLAND", "Euro", "EUR", "978"],
			["FRANCE", "Euro", "EUR", "978"],
			["FRENCH GUIANA", "Euro", "EUR", "978"],
			["FRENCH POLYNESIA", "CFP Franc", "XPF", "953"],
			["FRENCH SOUTHERN TERRITORIES (THE)", "Euro", "EUR", "978"],
			["GABON", "CFA Franc BEAC", "XAF", "950"],
			["GAMBIA (THE)", "Dalasi", "GMD", "270"],
			["GEORGIA", "Lari", "GEL", "981"],
			["GERMANY", "Euro", "EUR", "978"],
			["GHANA", "Ghana Cedi", "GHS", "936"],
			["GIBRALTAR", "Gibraltar Pound", "GIP", "292"],
			["GREECE", "Euro", "EUR", "978"],
			["GREENLAND", "Danish Krone", "DKK", "208"],
			["GRENADA", "East Caribbean Dollar", "XCD", "951"],
			["GUADELOUPE", "Euro", "EUR", "978"],
			["GUAM", "US Dollar", "USD", "840"],
			["GUATEMALA", "Quetzal", "GTQ", "320"],
			["GUERNSEY", "Pound Sterling", "GBP", "826"],
			["GUINEA", "Guinean Franc", "GNF", "324"],
			["GUINEA-BISSAU", "CFA Franc BCEAO", "XOF", "952"],
			["GUYANA", "Guyana Dollar", "GYD", "328"],
			["HAITI", "Gourde", "HTG", "332"],
			["HAITI", "US Dollar", "USD", "840"],
			["HEARD ISLAND AND McDONALD ISLANDS", "Australian Dollar", "AUD", "036"],
			["HOLY SEE (THE)", "Euro", "EUR", "978"],
			["HONDURAS", "Lempira", "HNL", "340"],
			["HONG KONG", "Hong Kong Dollar", "HKD", "344"],
			["HUNGARY", "Forint", "HUF", "348"],
			["ICELAND", "Iceland Krona", "ISK", "352"],
			["INDIA", "Indian Rupee", "INR", "356"],
			["INDONESIA", "Rupiah", "IDR", "360"],
			["INTERNATIONAL MONETARY FUND (IMF)", "SDR (Special Drawing Right)", "XDR", "960"],
			["IRAN (ISLAMIC REPUBLIC OF)", "Iranian Rial", "IRR", "364"],
			["IRAQ", "Iraqi Dinar", "IQD", "368"],
			["IRELAND", "Euro", "EUR", "978"],
			["ISLE OF MAN", "Pound Sterling", "GBP", "826"],
			["ISRAEL", "New Israeli Sheqel", "ILS", "376"],
			["ITALY", "Euro", "EUR", "978"],
			["JAMAICA", "Jamaican Dollar", "JMD", "388"],
			["JAPAN", "Yen", "JPY", "392"],
			["JERSEY", "Pound Sterling", "GBP", "826"],
			["JORDAN", "Jordanian Dinar", "JOD", "400"],
			["KAZAKHSTAN", "Tenge", "KZT", "398"],
			["KENYA", "Kenyan Shilling", "KES", "404"],
			["KIRIBATI", "Australian Dollar", "AUD", "036"],
			["KOREA (THE DEMOCRATIC PEOPLE'S REPUBLIC OF)", "North Korean Won", "KPW", "408"],
			["KOREA (THE REPUBLIC OF)", "Won", "KRW", "410"],
			["KUWAIT", "Kuwaiti Dinar", "KWD", "414"],
			["KYRGYZSTAN", "Som", "KGS", "417"],
			["LAO PEOPLE'S DEMOCRATIC REPUBLIC (THE)", "Lao Kip", "LAK", "418"],
			["LATVIA", "Euro", "EUR", "978"],
			["LEBANON", "Lebanese Pound", "LBP", "422"],
			["LESOTHO", "Loti", "LSL", "426"],
			["LESOTHO", "Rand", "ZAR", "710"],
			["LIBERIA", "Liberian Dollar", "LRD", "430"],
			["LIBYA", "Libyan Dinar", "LYD", "434"],
			["LIECHTENSTEIN", "Swiss Franc", "CHF", "756"],
			["LITHUANIA", "Euro", "EUR", "978"],
			["LUXEMBOURG", "Euro", "EUR", "978"],
			["MACAO", "Pataca", "MOP", "446"],
			["NORTH MACEDONIA", "Denar", "MKD", "807"],
			["MADAGASCAR", "Malagasy Ariary", "MGA", "969"],
			["MALAWI", "Malawi Kwacha", "MWK", "454"],
			["MALAYSIA", "Malaysian Ringgit", "MYR", "458"],
			["MALDIVES", "Rufiyaa", "MVR", "462"],
			["MALI", "CFA Franc BCEAO", "XOF", "952"],
			["MALTA", "Euro", "EUR", "978"],
			["MARSHALL ISLANDS (THE)", "US Dollar", "USD", "840"],
			["MARTINIQUE", "Euro", "EUR", "978"],
			["MAURITANIA", "Ouguiya", "MRU", "929"],
			["MAURITIUS", "Mauritius Rupee", "MUR", "480"],
			["MAYOTTE", "Euro", "EUR", "978"],
			["MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP", "ADB Unit of Account", "XUA", "965"],
			["MEXICO", "Mexican Peso", "MXN", "484"],
			["MEXICO", "Mexican Unidad de Inversion (UDI)", "MXV", "979"],
			["MICRONESIA (FEDERATED STATES OF)", "US Dollar", "USD", "840"],
			["MOLDOVA (THE REPUBLIC OF)", "Moldovan Leu", "MDL", "498"],
			["MONACO", "Euro", "EUR", "978"],
			["MONGOLIA", "Tugrik", "MNT", "496"],
			["MONTENEGRO", "Euro", "EUR", "978"],
			["MONTSERRAT", "East Caribbean Dollar", "XCD", "951"],
			["MOROCCO", "Moroccan Dirham", "MAD", "504"],
			["MOZAMBIQUE", "Mozambique Metical", "MZN", "943"],
			["MYANMAR", "Kyat", "MMK", "104"],
			["NAMIBIA", "Namibia Dollar", "NAD", "516"],
			["NAMIBIA", "Rand", "ZAR", "710"],
			["NAURU", "Australian Dollar", "AUD", "036"],
			["NEPAL", "Nepalese Rupee", "NPR", "524"],
			["NETHERLANDS (THE)", "Euro", "EUR", "978"],
			["NEW CALEDONIA", "CFP Franc", "XPF", "953"],
			["NEW ZEALAND", "New Zealand Dollar", "NZD", "554"],
			["NICARAGUA", "Cordoba Oro", "NIO", "558"],
			["NIGER (THE)", "CFA Franc BCEAO", "XOF", "952"],
			["NIGERIA", "Naira", "NGN", "566"],
			["NIUE", "New Zealand Dollar", "NZD", "554"],
			["NORFOLK ISLAND", "Australian Dollar", "AUD", "036"],
			["NORTHERN MARIANA ISLANDS (THE)", "US Dollar", "USD", "840"],
			["NORWAY", "Norwegian Krone", "NOK", "578"],
			["OMAN", "Rial Omani", "OMR", "512"],
			["PAKISTAN", "Pakistan Rupee", "PKR", "586"],
			["PALAU", "US Dollar", "USD", "840"],
			["PALESTINE, STATE OF", "No universal currency", "", ""],
			["PANAMA", "Balboa", "PAB", "590"],
			["PANAMA", "US Dollar", "USD", "840"],
			["PAPUA NEW GUINEA", "Kina", "PGK", "598"],
			["PARAGUAY", "Guarani", "PYG", "600"],
			["PERU", "Sol", "PEN", "604"],
			["PHILIPPINES (THE)", "Philippine Peso", "PHP", "608"],
			["PITCAIRN", "New Zealand Dollar", "NZD", "554"],
			["POLAND", "Zloty", "PLN", "985"],
			["PORTUGAL", "Euro", "EUR", "978"],
			["PUERTO RICO", "US Dollar", "USD", "840"],
			["QATAR", "Qatari Rial", "QAR", "634"],
			["RÉUNION", "Euro", "EUR", "978"],
			["ROMANIA", "Romanian Leu", "RON", "946"],
			["RUSSIAN FEDERATION (THE)", "Russian Ruble", "RUB", "643"],
			["RWANDA", "Rwanda Franc", "RWF", "646"],
			["SAINT BARTHÉLEMY", "Euro", "EUR", "978"],
			["SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA", "Saint Helena Pound", "SHP", "654"],
			["SAINT KITTS AND NEVIS", "East Caribbean Dollar", "XCD", "951"],
			["SAINT LUCIA", "East Caribbean Dollar", "XCD", "951"],
			["SAINT MARTIN (FRENCH PART)", "Euro", "EUR", "978"],
			["SAINT PIERRE AND MIQUELON", "Euro", "EUR", "978"],
			["SAINT VINCENT AND THE GRENADINES", "East Caribbean Dollar", "XCD", "951"],
			["SAMOA", "Tala", "WST", "882"],
			["SAN MARINO", "Euro", "EUR", "978"],
			["SAO TOME AND PRINCIPE", "Dobra", "STN", "930"],
			["SAUDI ARABIA", "Saudi Riyal", "SAR", "682"],
			["SENEGAL", "CFA Franc BCEAO", "XOF", "952"],
			["SERBIA", "Serbian Dinar", "RSD", "941"],
			["SEYCHELLES", "Seychelles Rupee", "SCR", "690"],
			["SIERRA LEONE", "Leone", "SLL", "694"],
			["SIERRA LEONE", "Leone", "SLE", "925"],
			["SINGAPORE", "Singapore Dollar", "SGD", "702"],
			["SINT MAARTEN (DUTCH PART)", "Netherlands Antillean Guilder", "ANG", "532"],
			['SISTEMA UNITARIO DE COMPENSACION REGIONAL DE PAGOS "SUCRE"', "Sucre", "XSU", "994"],
			["SLOVAKIA", "Euro", "EUR", "978"],
			["SLOVENIA", "Euro", "EUR", "978"],
			["SOLOMON ISLANDS", "Solomon Islands Dollar", "SBD", "090"],
			["SOMALIA", "Somali Shilling", "SOS", "706"],
			["SOUTH AFRICA", "Rand", "ZAR", "710"],
			["SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS", "No universal currency", "", ""],
			["SOUTH SUDAN", "South Sudanese Pound", "SSP", "728"],
			["SPAIN", "Euro", "EUR", "978"],
			["SRI LANKA", "Sri Lanka Rupee", "LKR", "144"],
			["SUDAN (THE)", "Sudanese Pound", "SDG", "938"],
			["SURINAME", "Surinam Dollar", "SRD", "968"],
			["SVALBARD AND JAN MAYEN", "Norwegian Krone", "NOK", "578"],
			["SWEDEN", "Swedish Krona", "SEK", "752"],
			["SWITZERLAND", "Swiss Franc", "CHF", "756"],
			["SWITZERLAND", "WIR Euro", "CHE", "947"],
			["SWITZERLAND", "WIR Franc", "CHW", "948"],
			["SYRIAN ARAB REPUBLIC", "Syrian Pound", "SYP", "760"],
			["TAIWAN (PROVINCE OF CHINA)", "New Taiwan Dollar", "TWD", "901"],
			["TAJIKISTAN", "Somoni", "TJS", "972"],
			["TANZANIA, UNITED REPUBLIC OF", "Tanzanian Shilling", "TZS", "834"],
			["THAILAND", "Baht", "THB", "764"],
			["TIMOR-LESTE", "US Dollar", "USD", "840"],
			["TOGO", "CFA Franc BCEAO", "XOF", "952"],
			["TOKELAU", "New Zealand Dollar", "NZD", "554"],
			["TONGA", "Pa'anga", "TOP", "776"],
			["TRINIDAD AND TOBAGO", "Trinidad and Tobago Dollar", "TTD", "780"],
			["TUNISIA", "Tunisian Dinar", "TND", "788"],
			["TURKEY", "Turkish Lira", "TRY", "949"],
			["TURKMENISTAN", "Turkmenistan New Manat", "TMT", "934"],
			["TURKS AND CAICOS ISLANDS (THE)", "US Dollar", "USD", "840"],
			["TUVALU", "Australian Dollar", "AUD", "036"],
			["UGANDA", "Uganda Shilling", "UGX", "800"],
			["UKRAINE", "Hryvnia", "UAH", "980"],
			["UNITED ARAB EMIRATES (THE)", "UAE Dirham", "AED", "784"],
			["UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)", "Pound Sterling", "GBP", "826"],
			["UNITED STATES MINOR OUTLYING ISLANDS (THE)", "US Dollar", "USD", "840"],
			["UNITED STATES OF AMERICA (THE)", "US Dollar", "USD", "840"],
			["UNITED STATES OF AMERICA (THE)", "US Dollar (Next day)", "USN", "997"],
			["URUGUAY", "Peso Uruguayo", "UYU", "858"],
			["URUGUAY", "Uruguay Peso en Unidades Indexadas (UI)", "UYI", "940"],
			["URUGUAY", "Unidad Previsional", "UYW", "927"],
			["UZBEKISTAN", "Uzbekistan Sum", "UZS", "860"],
			["VANUATU", "Vatu", "VUV", "548"],
			["VENEZUELA (BOLIVARIAN REPUBLIC OF)", "Bolívar Soberano", "VES", "928"],
			["VENEZUELA (BOLIVARIAN REPUBLIC OF)", "Bolívar Soberano", "VED", "926"],
			["VIET NAM", "Dong", "VND", "704"],
			["VIRGIN ISLANDS (BRITISH)", "US Dollar", "USD", "840"],
			["VIRGIN ISLANDS (U.S.)", "US Dollar", "USD", "840"],
			["WALLIS AND FUTUNA", "CFP Franc", "XPF", "953"],
			["WESTERN SAHARA", "Moroccan Dirham", "MAD", "504"],
			["YEMEN", "Yemeni Rial", "YER", "886"],
			["ZAMBIA", "Zambian Kwacha", "ZMW", "967"],
			["ZIMBABWE", "Zimbabwe Dollar", "ZWL", "932"],
			["ZZ01_Bond Markets Unit European_EURCO", "Bond Markets Unit European Composite Unit (EURCO)", "XBA", "955"],
			["ZZ02_Bond Markets Unit European_EMU-6", "Bond Markets Unit European Monetary Unit (E.M.U.-6)", "XBB", "956"],
			["ZZ03_Bond Markets Unit European_EUA-9", "Bond Markets Unit European Unit of Account 9 (E.U.A.-9)", "XBC", "957"],
			["ZZ04_Bond Markets Unit European_EUA-17", "Bond Markets Unit European Unit of Account 17 (E.U.A.-17)", "XBD", "958"],
			["ZZ06_Testing_Code", "Codes specifically reserved for testing purposes", "XTS", "963"],
			["ZZ07_No_Currency", "The codes assigned for transactions where no currency is involved", "XXX", "999"],
			["ZZ08_Gold", "Gold", "XAU", "959"],
			["ZZ09_Palladium", "Palladium", "XPD", "964"],
			["ZZ10_Platinum", "Platinum", "XPT", "962"],
			["ZZ11_Silver", "Silver", "XAG", "961"],
		];
	}
}

export default currencyFormat;
