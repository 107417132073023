import React, { useEffect, useRef, useState } from "react";
import style from "./index.module.scss";
import {
	CircularProgress,
	ClickAwayListener,
	Grid,
	Grow,
	IconButton,
	Link,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Typography,
} from "@mui/material";
import { APIHelper, InteractionHelper } from "Helper";
import { SvgIcon } from "Components";
import { mdiAlertOctagon, mdiAutorenew, mdiCancel, mdiDotsVertical, mdiEye, mdiEyeOff, mdiInformation, mdiInformationOutline } from "@mdi/js";

interface UserInfoMemberAccess {
	name: string;
	pictureUrl: string;
	description: string;
	website: string;
	category: "other" | "finance" | "monitoring" | "banking";
	date_created: number | string | Date;
	inConfirmationPhase: boolean;
	confirmationCodes: string[] | null;
	codeDuration: number | null;
	requestDate: number | null;
	confirmationDate: number | null;
	expirationDate: number | null;
}

const generateCode = (): string => {
	const code1 = ("0000" + Math.round(Math.random() * 10000).toString()).slice(-4);
	const code2 = ("0000" + Math.round(Math.random() * 10000).toString()).slice(-4);
	return `${code1}-${code2}`;
};

const createRoundedPath = (coords: Array<{ x: number; y: number }>, radius: number, close: boolean = true) => {
	let path = "";
	const length = coords.length + (close ? 1 : -1);
	for (let i = 0; i < length; i++) {
		const a = coords[i % coords.length];
		const b = coords[(i + 1) % coords.length];
		const t = Math.min(radius / Math.hypot(b.x - a.x, b.y - a.y), 0.5);

		if (i > 0) path += `Q${a.x},${a.y} ${a.x * (1 - t) + b.x * t},${a.y * (1 - t) + b.y * t}`;

		if (!close && i == 0) path += `M${a.x},${a.y}`;
		else if (i == 0) path += `M${a.x * (1 - t) + b.x * t},${a.y * (1 - t) + b.y * t}`;

		if (!close && i == length - 1) path += `L${b.x},${b.y}`;
		else if (i < length - 1) path += `L${a.x * t + b.x * (1 - t)},${a.y * t + b.y * (1 - t)}`;
	}
	if (close) path += "Z";
	return path;
};

const InputCodeConfirm: React.FC<{
	codes: string[];
	duration: number;
	initialDate: number;
}> = ({ codes, duration, initialDate }) => {
	const [index, seIndex] = useState<number>(Math.floor((Date.now() - initialDate) / duration));
	const [percent, setPercent] = useState<number>(((Date.now() - initialDate) % duration) / duration);
	const [hideCode, setHideCode] = useState<boolean>(true);
	const rootRef = useRef<SVGSVGElement | null>(null);
	const strokeRef = useRef<SVGPathElement | null>(null);
	const textCodeRef = useRef<SVGTextElement | null>(null);

	useEffect(() => {
		if (index >= codes.length) {
			return;
		}

		let time: NodeJS.Timeout;

		const handleClickOutside = (event: MouseEvent) => {
			if (rootRef.current && !rootRef.current.contains(event.target as Node)) {
				window.getSelection()?.removeAllRanges();
			}
		};

		document.body.addEventListener("click", handleClickOutside);

		const loop = () => {
			const index = Math.floor((Date.now() - initialDate) / duration);
			seIndex(index);
			setPercent(((Date.now() - initialDate) % duration) / duration);

			if (index >= codes.length) {
				document.body.removeEventListener("click", handleClickOutside);
				clearInterval(time);
			}

			time = setTimeout(() => {
				requestAnimationFrame(loop);
			}, 1000);
		};

		loop();

		return () => {
			clearTimeout(time);
			document.body.removeEventListener("click", handleClickOutside);
		};
	}, []);

	const stroke = 4 / 2;
	const width = 200;
	const height = 50;
	const radius = 10;

	const path = createRoundedPath(
		[
			{ x: stroke, y: stroke },
			{ x: width - stroke, y: stroke },
			{ x: width - stroke, y: height - stroke },
			{ x: stroke, y: height - stroke },
		],
		radius,
	);

	const strokeLength = strokeRef.current?.getTotalLength() ?? 468;
	const strokeValue = strokeLength * percent;

	const handlerText: React.MouseEventHandler<SVGSVGElement> = (el) => {
		if (index >= codes.length) {
			return;
		}

		const selection = window.getSelection();

		if (textCodeRef.current && selection) {
			selection.removeAllRanges();
			const range = document.createRange();
			range.selectNode(textCodeRef.current);
			selection.addRange(range);
			document.execCommand("copy");
			InteractionHelper.toast("Código de confirmação copiado com sucesso", null, "success");
		}
	};

	return (
		<>
			{index < codes.length && (
				<div
					style={{
						margin: "0px",
					}}
				>
					<IconButton
						size="small"
						disableRipple={true}
						onClick={() => setHideCode((p) => !p)}
					>
						<SvgIcon path={hideCode ? mdiEyeOff : mdiEye} />
					</IconButton>
				</div>
			)}
			<svg
				className={[style.input_code_confirm, index >= codes.length ? style.error : ""].join(" ")}
				width={width}
				height={height}
				viewBox={`0 0 ${width} ${height}`}
				onClick={hideCode ? undefined : handlerText}
				ref={rootRef}
			>
				<path
					x="0"
					y="0"
					d={path}
					fill="none"
					strokeWidth={stroke * 2}
					strokeDasharray={strokeLength}
					strokeDashoffset={strokeValue}
					ref={strokeRef}
				/>
				<text
					x="50%"
					y="55%"
					dominantBaseline="middle"
					textAnchor="middle"
					style={{
						userSelect: index >= codes.length || hideCode ? "none" : "text",
						cursor: index >= codes.length || hideCode ? "auto" : "pointer",
					}}
					ref={textCodeRef}
				>
					{index >= codes.length ? "expirado" : hideCode ? "▮▮▮▮-▮▮▮▮" : codes[index]}
				</text>
			</svg>
		</>
	);
};

const IconButtonOptions: React.FC<{
	[prop: string]: any;
	options?: Array<{
		[prop: string]: any;
		icon: React.ReactNode;
		label: string;
		hidden?: boolean;
		disabled?: boolean;
		onClick?: (() => void) | undefined;
	}>;
	children?: React.ReactNode;
}> = ({ options, children, ...props }) => {
	const [openPopperActions, setOpenPopperActions] = useState(false);
	const buttonRef = useRef<HTMLButtonElement | null>(null);

	return (
		<>
			<IconButton
				{...props}
				ref={buttonRef}
				onClick={() => setOpenPopperActions(true)}
			>
				{children}
			</IconButton>
			<Popper
				sx={{ zIndex: 9999 }}
				open={openPopperActions}
				anchorEl={buttonRef.current}
				transition
				placement="bottom-end"
				container={document.body}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{ transformOrigin: "right top" }}
					>
						<Paper>
							<ClickAwayListener
								onClickAway={() => {
									setOpenPopperActions(false);
								}}
							>
								<MenuList>
									{Array.isArray(options) &&
										options.map(({ icon, label, hidden, disabled, onClick, ...props }, index) => {
											return (
												hidden !== true && (
													<MenuItem
														key={index}
														{...props}
														disabled={disabled}
														onClick={() => {
															if (!disabled && typeof onClick === "function") {
																onClick();
															}
															setOpenPopperActions(false);
														}}
													>
														<ListItemIcon>{icon}</ListItemIcon>
														<ListItemText>{label}</ListItemText>
													</MenuItem>
												)
											);
										})}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};

const categories = {
	other: "Outro",
	finance: "Financeiro",
	monitoring: "Monitoramento",
	banking: "Bancário",
};

const AccessInfoBody: React.FC<{ info: UserInfoMemberAccess }> = ({ info }) => {
	return (
		<div className={style.access_info}>
			<div className={style.picture}>
				<div
					className={style.img}
					style={{
						backgroundImage: `url(${info.pictureUrl})`,
					}}
				></div>
				{typeof info.website === "string" && info.website.trim() !== "" ? (
					<Link
						href={info.website}
						underline="none"
						target="_blank"
					>
						<Typography
							variant="h4"
							gutterBottom
						>
							{info.name}
						</Typography>
					</Link>
				) : (
					<Typography
						variant="h4"
						gutterBottom
					>
						{info.name}
					</Typography>
				)}
			</div>
			<div
				className={style.contentInformation}
				style={{ flexDirection: "column", alignItems: "flex-start" }}
			>
				<Typography
					variant="h6"
					gutterBottom
				>
					Descrição:
				</Typography>
				<Typography
					variant="body2"
					gutterBottom
				>
					{typeof info.description === "string" && info.description.trim() !== "" ? info.description : <i>Não informado</i>}
				</Typography>
			</div>
			<div className={style.contentInformation}>
				<Typography
					variant="h6"
					gutterBottom
				>
					Tipo de perfil:
				</Typography>
				<Typography
					variant="body2"
					gutterBottom
				>
					Empresarial
				</Typography>
			</div>
			<div className={style.contentInformation}>
				<Typography
					variant="h6"
					gutterBottom
				>
					CNPJ:
				</Typography>
				<Typography
					variant="body2"
					gutterBottom
				>
					<i>Não informado</i>
				</Typography>
			</div>
			<div className={style.contentInformation}>
				<Typography
					variant="h6"
					gutterBottom
				>
					Categoria:
				</Typography>
				<Typography
					variant="body2"
					gutterBottom
				>
					{info.category in categories ? categories[info.category] : <i>Desconhecido</i>}
				</Typography>
			</div>
			<div className={style.contentInformation}>
				<Typography
					variant="h6"
					gutterBottom
				>
					Data de inscrição:
				</Typography>
				<Typography
					variant="body2"
					gutterBottom
				>
					{new Date(info.date_created).toLocaleString()}
				</Typography>
			</div>
			<div className={style.contentInformation}>
				<Typography
					variant="h6"
					gutterBottom
				>
					Data de confirmação de acesso:
				</Typography>
				<Typography
					variant="body2"
					gutterBottom
				>
					{info.confirmationDate ? new Date(info.confirmationDate).toLocaleString() : <i>Em processo</i>}
				</Typography>
			</div>
			<div className={style.contentInformation}>
				<Typography
					variant="h6"
					gutterBottom
				>
					Data de expiração:
				</Typography>
				<Typography
					variant="body2"
					gutterBottom
				>
					{info.confirmationDate && info.expirationDate ? new Date(info.expirationDate).toLocaleString() : <i>Aguardando confirmação</i>}
				</Typography>
			</div>
			<div
				className={style.contentInformation}
				style={{ flexDirection: "column", alignItems: "flex-start" }}
			>
				<Typography
					variant="h6"
					gutterBottom
				>
					Permissões:
				</Typography>
				<Typography
					variant="body2"
					gutterBottom
				>
					<i>Não informado</i>
				</Typography>
			</div>
		</div>
	);
};

const openAccessInfo = (info: UserInfoMemberAccess) => {
	InteractionHelper.alert(<AccessInfoBody info={info} />, `Informações sobre: ${info.name}`);
};

const SettingsSecurityAccess: React.FC<{}> = () => {
	const [isLoading, setLoading] = useState<boolean>(true);
	const [list, setList] = useState<UserInfoMemberAccess[]>([
		{
			website: "https://ivipbank.com/",
			category: "banking",
			date_created: 1697733902924,
			pictureUrl: "https://api.ivipcoin.com:5055/ext/ivipcoin-db/storage/ProfilePicture/lnwhob8v0wm1mxzdftbdm1ca/1697680274556.png",
			name: "iVipBank",
			description:
				"IVIPBANK é um banco digital inovador que oferece serviços bancários de forma conveniente e acessível através de plataformas online e móveis. Ele permite que os clientes acessem suas contas, façam transferências, paguem contas e realizem uma variedade de operações financeiras de forma rápida e fácil, sem a necessidade de visitar uma agência física. Com um foco na praticidade e na experiência do cliente, IVIPBANK oferece soluções financeiras modernas e eficientes para atender às necessidades bancárias do dia a dia.",
			inConfirmationPhase: false,
			confirmationCodes: null,
			codeDuration: null,
			requestDate: 1697759734233,
			confirmationDate: 1697759774263,
			expirationDate: 1697932534233,
		},
		{
			website: "",
			category: "banking",
			date_created: 1697733902924,
			pictureUrl: "https://api.ivipcoin.com:5055/ext/ivipcoin-db/storage/ProfilePicture/ljyiukomgocunpzd7izcfxn7/1697734332826.png",
			name: "Apenas um teste",
			description: "",
			inConfirmationPhase: true,
			confirmationCodes: new Array(6).fill("").map(generateCode),
			codeDuration: 5 * 60 * 1000,
			requestDate: Date.now() - Math.round(Math.random() * (5 * 60 * 1000 * 6)),
			confirmationDate: null,
			expirationDate: Date.now() + 60 * 60 * 1000 * 24,
		},
		{
			website: "",
			category: "banking",
			date_created: 1697733902924,
			pictureUrl: "https://api.ivipcoin.com:5055/ext/ivipcoin-db/storage/ProfilePicture/ljyiukomgocunpzd7izcfxn7/1697734332826.png",
			name: "API teste",
			description: "",
			inConfirmationPhase: true,
			confirmationCodes: new Array(6).fill("").map(generateCode),
			codeDuration: 5 * 60 * 1000,
			requestDate: Date.now() - Math.round(Math.random() * (5 * 60 * 1000 * 6)),
			confirmationDate: null,
			expirationDate: Date.now() + 60 * 60 * 1000 * 24,
		},
	]);

	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		//return;
		setLoading(true);
		setError(null);
		setList([]);

		let updateInfo: NodeJS.Timeout;

		const getInfo = () => {
			clearTimeout(updateInfo);

			updateInfo = setTimeout(() => {
				APIHelper.fetch("oauth/list_member_access", {}, true)
					.then((list: any) => {
						setList(() => list);
					})
					.catch(({ message }) => {
						setError(message);
					})
					.finally(() => {
						setLoading(false);
					});
			}, 4000);
		};

		getInfo();

		const handlePageFocus = () => {
			getInfo();
		};

		window.addEventListener("focus", handlePageFocus);

		return () => {
			clearTimeout(updateInfo);
			window.removeEventListener("focus", handlePageFocus);
		};
	}, []);

	return (
		<div
			className={[style.access_permissions, error ? style.error : ""].join(" ")}
			empty-message={error ? error : list.length ? "" : "Nenhum acesso ou solicitação por terceiros encontrado até o momento!"}
		>
			{isLoading && <CircularProgress color="inherit" />}
			{!isLoading && list.length > 0 && (
				<Grid
					container
					spacing={0}
				>
					{list.map((info, i) => {
						const {
							name,
							pictureUrl,
							description,
							inConfirmationPhase,
							confirmationCodes,
							codeDuration,
							confirmationDate,
							expirationDate,
							requestDate,
						} = info;

						let status = "";
						const dateNow = Date.now();

						if ((expirationDate && dateNow > expirationDate) || (!confirmationDate && !confirmationCodes)) {
							status = style["register-denied"];
						} else if (confirmationDate && dateNow > confirmationDate && expirationDate && dateNow <= expirationDate) {
							status = style["register-authorized"];
						}

						const isCodeForConfirm = inConfirmationPhase && confirmationCodes !== null && codeDuration !== null && requestDate !== null;
						const isDateExpiration = confirmationDate && expirationDate;

						return (
							<Grid
								key={`SettingsSecurityAccess-${i}`}
								item
								xs={12}
								md={6}
							>
								<div className={[style["register"], status].join(" ")}>
									<div
										className={style["register-picture"]}
										style={{
											backgroundImage: `url(${pictureUrl})`,
										}}
									></div>
									<div className={style["register-label"]}>
										<Typography
											variant="h6"
											gutterBottom
											component="div"
										>
											{name}
										</Typography>
										{requestDate && (
											<Typography
												variant="caption"
												gutterBottom
												component="div"
											>
												{new Date(requestDate).toLocaleString()}
											</Typography>
										)}
									</div>
									{isCodeForConfirm && (
										<InputCodeConfirm
											codes={confirmationCodes as any}
											duration={codeDuration as any}
											initialDate={requestDate as any}
										/>
									)}
									{isDateExpiration && (
										<div
											className={style["register-label"]}
											style={{
												textAlign: "right",
												whiteSpace: "nowrap",
											}}
										>
											<Typography
												variant="caption"
												gutterBottom
												component="div"
											>
												Válido até
											</Typography>
											<Typography
												variant="h6"
												gutterBottom
												component="div"
											>
												{new Date(expirationDate as any).toLocaleString()}
											</Typography>
										</div>
									)}
									<div className={style.actions}>
										<IconButtonOptions
											size="small"
											disableRipple={true}
											options={[
												{
													icon: <SvgIcon path={mdiInformationOutline} />,
													label: "Informações",
													onClick: () => {
														openAccessInfo(info);
													},
												},
												{
													icon: <SvgIcon path={mdiAutorenew} />,
													label: "Renovar acesso",
													hidden: isCodeForConfirm,
													disabled: true,
												},
												{
													icon: <SvgIcon path={mdiAlertOctagon} />,
													label: "Suspeito",
													disabled: true,
												},
												{
													icon: <SvgIcon path={mdiCancel} />,
													label: "Desinscrever",
													hidden: isCodeForConfirm,
													disabled: true,
												},
												{
													icon: <SvgIcon path={mdiCancel} />,
													label: "Rejeitar",
													hidden: !isCodeForConfirm,
													disabled: true,
												},
											]}
										>
											<SvgIcon path={mdiDotsVertical} />
										</IconButtonOptions>
									</div>
								</div>
							</Grid>
						);
					})}
				</Grid>
			)}
		</div>
	);
};

export default SettingsSecurityAccess;
