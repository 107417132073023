const datediff = function (first: string | number | Date, second: string | number | Date): number {
	first = new Date(first);
	second = new Date(second);

	let firstDate = new Date(first.getFullYear(), first.getMonth(), first.getDate()).getTime(),
		secondDate = new Date(second.getFullYear(), second.getMonth(), second.getDate()).getTime();

	return Math.round(Math.abs((firstDate - secondDate) / (1000 * 3600 * 24)));
};

const durationDatesByMsec = function (msec: number, isArray: boolean): any[] | any {
	let toJson = function (milliseconds: number, seconds: number, minutes: number, hours: number, days: number, weekdays: number) {
		return {
			milliseconds: milliseconds,
			seconds: seconds,
			minutes: minutes,
			hours: hours,
			days: days,
			weekdays: weekdays,
		};
	};

	let d = typeof msec === "number" ? msec : 0;
	let weekdays = Math.floor(d / 1000 / 60 / 60 / 24 / 7);
	let days = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7);
	let hours = Math.floor(d / 1000 / 60 / 60 - weekdays * 7 * 24 - days * 24);
	let minutes = Math.floor(d / 1000 / 60 - weekdays * 7 * 24 * 60 - days * 24 * 60 - hours * 60);
	let seconds = Math.floor(d / 1000 - weekdays * 7 * 24 * 60 * 60 - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60);
	let milliseconds = Math.floor(
		d - weekdays * 7 * 24 * 60 * 60 * 1000 - days * 24 * 60 * 60 * 1000 - hours * 60 * 60 * 1000 - minutes * 60 * 1000 - seconds * 1000,
	);

	let t: ReturnType<typeof toJson> = toJson(milliseconds, seconds, minutes, hours, days, weekdays);
	let result = JSON.parse(JSON.stringify(t));
	if (isArray === true) {
		result = (["weekdays", "days", "hours", "minutes", "seconds", "milliseconds"] as Array<keyof typeof t>).map((k) => {
			return t[k];
		});
	}
	return result;
};

const durationDates = function (t0: string | number | Date, t1: string | number | Date, isArray: boolean) {
	return durationDatesByMsec(new Date(t1).getTime() - new Date(t0).getTime(), isArray);
};

const dataToString = function (data: string | number | Date) {
	data = new Date(data || new Date().getTime());

	let y = data.getFullYear(),
		m = data.getMonth() + 1 <= 9 ? "0" + (data.getMonth() + 1) : data.getMonth() + 1,
		d = data.getDate() <= 9 ? "0" + data.getDate() : data.getDate();

	return y + "_" + m + "_" + d;
};

const isDate = function (value: any): value is Date {
	return (
		value instanceof Date ||
		(typeof value === "string" && !isNaN(Date.parse(value))) ||
		(typeof value === "number" && !isNaN(new Date(value).getTime()))
	);
};

export { datediff, durationDates, durationDatesByMsec, dataToString, isDate };
