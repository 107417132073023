import { Resultado } from "../../Models";
import multiStorager from "../MultiStorager";

import clientDetection from "./clientDetection";
import currencyFormat from "./currencyFormat";
import { validatePhone, validateUrl, validateEmail, validatePassword, isUrlImageValid } from "./validate";
import SvgToDataURL from "./SvgToDataURL";
import Base64 from "./Base64";
import { getStyle, getElementSize, getInputSelection, getCursorXY, StringToHTML, getElementPosition } from "./dom";
import Color from "./color";
import maskTheValue from "./maskTheValue";
import { datediff, durationDates, durationDatesByMsec, dataToString, isDate } from "./date";
import SwipeEventDispatcher from "./SwipeEventDispatcher";

import BezierEasing from "./BezierEasing";

import Animation from "./Animation";

const isIpad = window.navigator.userAgent.match(/iPad/i) != null;

const isIphone = !isIpad && (window.navigator.userAgent.match(/iPhone/i) != null || window.navigator.userAgent.match(/iPod/i) != null);

const isIos = isIpad || isIphone;

const isAndroid = !isIos && window.navigator.userAgent.match(/android/i) != null;

const isMobile = isIos || isAndroid;

const isPageHidden =
	window.document.hidden || (window.document as any).msHidden || (window.document as any).webkitHidden || (window.document as any).mozHidden;

const openUrlInNewTab = (url: string) => {
	//let win = window.open(url, '_system');
	let win = window.open(url, "_blank");
	win?.focus();
};

const isJson = (str: any): boolean => {
	try {
		const result = JSON.parse(typeof str === "string" ? str : JSON.stringify(str));
		return typeof result === "object";
	} catch {
		return false;
	}
};

const asyncForEach = (array: any[], callback: (value: (typeof array)[number], index: number, self: typeof array) => void) => {
	return new Promise<void>(async (resolve, reject) => {
		for (let i = 0; i < array.length; i++) {
			await callback(array[i], i, array);
			if (i >= array.length - 1) {
				resolve();
			}
		}
	});
};

const generateUUID = (): string => {
	let d = new Date().getTime(); //Timestamp
	let d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0;
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		let r = Math.random() * 16;
		if (d > 0) {
			r = (d + r) % 16 | 0;
			d = Math.floor(d / 16);
		} else {
			r = (d2 + r) % 16 | 0;
			d2 = Math.floor(d2 / 16);
		}
		return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
	});
};

const uniqueid = (length?: number) => {
	length = typeof length !== "number" ? 32 : Math.min(Math.max(5, length ?? 32), 32);
	let idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
	do {
		let ascicode = Math.floor(Math.random() * 42 + 48);
		if (ascicode < 58 || ascicode > 64) {
			idstr += String.fromCharCode(ascicode);
		}
	} while (idstr.length < length);
	return String(idstr).slice(0, length);
};

const pathEqual = (actual: string, expected: string) => {
	if (actual === expected) return true;

	let normalizePath = (value: string) => {
		return value
			.replace(/\\/g, "/")
			.replace(/(\w):/, "/$1")
			.replace(/(\w+)\/\.\.\/?/g, "")
			.replace(/(\w+)\/\.\.\/?/g, "")
			.replace(/(\w+)\/\.\.\/?/g, "")
			.replace(/^\.\//, "")
			.replace(/\/\.\//, "/")
			.replace(/\/\.$/, "")
			.replace(/\/$/, "");
	};

	return normalizePath(actual) === normalizePath(expected);
};

const getID = () => {
	return new Promise<string>(async (resolve, reject) => {
		if (multiStorager.LocalStorager.hasKey("__machine_key")) {
			resolve(multiStorager.LocalStorager.get("__machine_key") as string);
			return;
		}

		let trace = await fetch("https://www.cloudflare.com/cdn-cgi/trace");

		let navigator_info = window.navigator,
			screen_info = window.screen,
			uid: string = "";

		if (!trace || !trace.text) {
			uid += navigator_info.mimeTypes.length;
			uid += navigator_info.userAgent.replace(/\D+/g, "");
			uid += navigator_info.plugins.length;
			uid += screen_info.height || "";
			uid += screen_info.width || "";
			uid += screen_info.pixelDepth || "";
		} else {
			let text = await trace.text();
			uid = text.replace(/\D+/g, "");
		}

		uid = String(uid).slice(0, 32);

		multiStorager.LocalStorager.set("__machine_key", uid);

		resolve(uid);
	});
};

const convert = {
	bin2dec: (s: string) => parseInt(s, 2).toString(10),
	bin2hex: (s: string) => parseInt(s, 2).toString(16),
	dec2bin: (s: string) => parseInt(s, 10).toString(2),
	dec2hex: (s: string) => parseInt(s, 10).toString(16),
	hex2bin: (s: string) => parseInt(s, 16).toString(2),
	hex2dec: (s: string) => parseInt(s, 16).toString(10),
};

const similarityStrings = (s1: string, s2: string): number => {
	const editDistance = (s1: string, s2: string) => {
		s1 = s1.toLowerCase();
		s2 = s2.toLowerCase();

		let costs = new Array();
		for (let i = 0; i <= s1.length; i++) {
			let lastValue = i;
			for (let j = 0; j <= s2.length; j++) {
				if (i == 0) costs[j] = j;
				else {
					if (j > 0) {
						let newValue = costs[j - 1];
						if (s1.charAt(i - 1) != s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
						costs[j - 1] = lastValue;
						lastValue = newValue;
					}
				}
			}
			if (i > 0) costs[s2.length] = lastValue;
		}
		return costs[s2.length];
	};

	let longer = s1;
	let shorter = s2;
	if (s1.length < s2.length) {
		longer = s2;
		shorter = s1;
	}
	let longerLength = longer.length;
	if (longerLength == 0) {
		return 1.0;
	}
	return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength as any);
};

const getDataSnapshotPath = (snap: any, keys: string[] = []): string => {
	if (typeof snap.path === "string") {
		return snap.path;
	}

	keys = Array.isArray(keys) ? keys : [];
	let k = snap.key;
	if (typeof k !== "string") {
		return keys.join("/");
	}

	return getDataSnapshotPath(snap.parent, [k, ...keys]);
};

const noise = (x: number, y: number, z: number) => {
	const permutation = [
		151, 160, 137, 91, 90, 15, 131, 13, 201, 95, 96, 53, 194, 233, 7, 225, 140, 36, 103, 30, 69, 142, 8, 99, 37, 240, 21, 10, 23, 190, 6, 148,
		247, 120, 234, 75, 0, 26, 197, 62, 94, 252, 219, 203, 117, 35, 11, 32, 57, 177, 33, 88, 237, 149, 56, 87, 174, 20, 125, 136, 171, 168, 68,
		175, 74, 165, 71, 134, 139, 48, 27, 166, 77, 146, 158, 231, 83, 111, 229, 122, 60, 211, 133, 230, 220, 105, 92, 41, 55, 46, 245, 40, 244, 102,
		143, 54, 65, 25, 63, 161, 1, 216, 80, 73, 209, 76, 132, 187, 208, 89, 18, 169, 200, 196, 135, 130, 116, 188, 159, 86, 164, 100, 109, 198, 173,
		186, 3, 64, 52, 217, 226, 250, 124, 123, 5, 202, 38, 147, 118, 126, 255, 82, 85, 212, 207, 206, 59, 227, 47, 16, 58, 17, 182, 189, 28, 42,
		223, 183, 170, 213, 119, 248, 152, 2, 44, 154, 163, 70, 221, 153, 101, 155, 167, 43, 172, 9, 129, 22, 39, 253, 19, 98, 108, 110, 79, 113, 224,
		232, 178, 185, 112, 104, 218, 246, 97, 228, 251, 34, 242, 193, 238, 210, 144, 12, 191, 179, 162, 241, 81, 51, 145, 235, 249, 14, 239, 107, 49,
		192, 214, 31, 181, 199, 106, 157, 184, 84, 204, 176, 115, 121, 50, 45, 127, 4, 150, 254, 138, 236, 205, 93, 222, 114, 67, 29, 24, 72, 243,
		141, 128, 195, 78, 66, 215, 61, 156, 180,
	];

	const p = (i: number) => {
		return permutation[i % 256];
	};

	const fade = (t: number) => {
		return t * t * t * (t * (t * 6 - 15) + 10);
	};

	const lerp = (t: number, a: number, b: number) => {
		return a + t * (b - a);
	};

	const grad = (hash: number, x: number, y: number, z: number) => {
		const h = hash & 15;
		const u = h < 8 ? x : y,
			v = h < 4 ? y : h == 12 || h == 14 ? x : z;
		return ((h & 1) == 0 ? u : -u) + ((h & 2) == 0 ? v : -v);
	};

	const scale = (n: number) => {
		return (1 + n) / 2;
	};

	x = typeof x !== "number" ? 1 : x;
	y = typeof y !== "number" ? 0 : y;
	z = typeof z !== "number" ? 0 : z;

	let X = Math.floor(x) & 255,
		Y = Math.floor(y) & 255,
		Z = Math.floor(z) & 255;
	x -= Math.floor(x);
	y -= Math.floor(y);
	z -= Math.floor(z);
	let u = fade(x),
		v = fade(y),
		w = fade(z);
	let A = p(X) + Y,
		AA = p(A) + Z,
		AB = p(A + 1) + Z,
		B = p(X + 1) + Y,
		BA = p(B) + Z,
		BB = p(B + 1) + Z;

	return scale(
		lerp(
			w,
			lerp(v, lerp(u, grad(p(AA), x, y, z), grad(p(BA), x - 1, y, z)), lerp(u, grad(p(AB), x, y - 1, z), grad(p(BB), x - 1, y - 1, z))),
			lerp(
				v,
				lerp(u, grad(p(AA + 1), x, y, z - 1), grad(p(BA + 1), x - 1, y, z - 1)),
				lerp(u, grad(p(AB + 1), x, y - 1, z - 1), grad(p(BB + 1), x - 1, y - 1, z - 1)),
			),
		),
	);
};

/*const noise = (x, y = 0, z = 0)=>{
    const PERLIN_YWRAPB = 4;
    const PERLIN_YWRAP = 1 << PERLIN_YWRAPB;
    const PERLIN_ZWRAPB = 8;
    const PERLIN_ZWRAP = 1 << PERLIN_ZWRAPB;
    const PERLIN_SIZE = 4095;

    let perlin_octaves = 4;
    let perlin_amp_falloff = 0.5;

    const scaled_cosine = i => 0.5 * (1.0 - Math.cos(i * Math.PI));

    let perlin;

    if (perlin == null) {
        perlin = new Array(PERLIN_SIZE + 1);
        for (let i = 0; i < PERLIN_SIZE + 1; i++) {
            perlin[i] = Math.random();
        }
    }

    if (x < 0) {x = -x;}
    if (y < 0) {y = -y;}
    if (z < 0) {z = -z;}

    let xi = Math.floor(x), yi = Math.floor(y), zi = Math.floor(z);
    let xf = x - xi;
    let yf = y - yi;
    let zf = z - zi;
    let rxf, ryf;

    let r = 0;
    let ampl = 0.5;

    let n1, n2, n3;

    for(let o = 0; o < perlin_octaves; o++){
        let _of = xi + (yi << PERLIN_YWRAPB) + (zi << PERLIN_ZWRAPB);

        rxf = scaled_cosine(xf);
        ryf = scaled_cosine(yf);

        n1 = perlin[_of & PERLIN_SIZE];
        n1 += rxf * (perlin[(_of + 1) & PERLIN_SIZE] - n1);
        n2 = perlin[(_of + PERLIN_YWRAP) & PERLIN_SIZE];
        n2 += rxf * (perlin[(_of + PERLIN_YWRAP + 1) & PERLIN_SIZE] - n2);
        n1 += ryf * (n2 - n1);

        _of += PERLIN_ZWRAP;
        n2 = perlin[_of & PERLIN_SIZE];
        n2 += rxf * (perlin[(_of + 1) & PERLIN_SIZE] - n2);
        n3 = perlin[(_of + PERLIN_YWRAP) & PERLIN_SIZE];
        n3 += rxf * (perlin[(_of + PERLIN_YWRAP + 1) & PERLIN_SIZE] - n3);
        n2 += ryf * (n3 - n2);

        n1 += scaled_cosine(zf) * (n2 - n1);

        r += n1 * ampl;
        ampl *= perlin_amp_falloff;
        xi <<= 1;
        xf *= 2;
        yi <<= 1;
        yf *= 2;
        zi <<= 1;
        zf *= 2;

        if (xf >= 1.0) {
            xi++;
            xf--;
        }
        if (yf >= 1.0) {
            yi++;
            yf--;
        }
        if (zf >= 1.0) {
            zi++;
            zf--;
        }
    }
    return r;
};*/

const normalizePath = (path: string) => {
	path = typeof path === "string" ? path.replace(/\/+/g, "/").replace(/\/+$/g, "") : path;
	const partes = path.split("/");
	const pilha = [];
	for (const parte of partes) {
		if (parte === "..") {
			pilha.pop();
		} else if (parte !== ".") {
			pilha.push(parte);
		}
	}
	return pilha.map((s) => (/(https?\:)/.test(s) ? s + "/" : s)).join("/");
};

const matchPath = (
	path: string | RegExp,
	url: string,
): {
	exact: boolean;
	search: any;
	params: {
		[key: string]: string;
	};
	query: any;
} => {
	path = typeof path === "string" ? normalizePath(path) : path;
	url = normalizePath(url);

	const getLocation = (href: string) => {
		const match = href.match(/^((https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?))?([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
		return (
			match && {
				href: href,
				protocol: match[2],
				host: match[3],
				hostname: match[4],
				port: match[5],
				pathname: match[6],
				search: match[7],
				hash: match[8],
			}
		);
	};

	const pathToRegExp = (path: string) =>
		new RegExp(
			path
				.replace(/\./g, "\\.")
				.replace(/\//g, "/")
				.replace(/\?/g, "\\?")
				.replace(/\/+$/, "")
				.replace(/\*+/g, ".*")
				.replace(/:([^\d|^\/][a-zA-Z0-9_]*(?=(?:\/|\\.)|$))/g, (_, paramName) => `(?<${paramName}>[^\/]+?)`)
				.concat("(\\/|$)"),
			"gi",
		);

	const getQuery = (url: string) =>
		typeof url === "string" && url.search(/\?/) >= 0
			? JSON.parse('{"' + decodeURI((url.split("?").pop() ?? "").replace(/&/g, '","').replace(/=/g, '":"')) + '"}')
			: {};

	const urlParsed = getLocation(url);

	const expression = path instanceof RegExp ? path : pathToRegExp(path);
	const match = expression.exec(urlParsed?.pathname ?? "") || false;
	const matches = path instanceof RegExp ? !!match : !!match && match[0] === match.input;
	const params = match ? match.groups || {} : {};
	const search = getQuery(urlParsed?.search ?? "");
	return { exact: matches, search, params, query: Object.assign({}, params, search) };
};

export {
	SwipeEventDispatcher,
	isIpad,
	isIphone,
	isIos,
	isAndroid,
	isMobile,
	isPageHidden,
	clientDetection,
	currencyFormat,
	openUrlInNewTab,
	isJson,
	validatePhone,
	validateUrl,
	validateEmail,
	isUrlImageValid,
	SvgToDataURL,
	validatePassword,
	asyncForEach,
	generateUUID,
	uniqueid,
	Base64,
	getStyle,
	getElementSize,
	StringToHTML,
	getElementPosition,
	getInputSelection,
	getCursorXY,
	Color,
	pathEqual,
	getID,
	maskTheValue,
	datediff,
	durationDates,
	durationDatesByMsec,
	dataToString,
	isDate,
	convert,
	similarityStrings,
	getDataSnapshotPath,
	BezierEasing,
	Animation,
	noise,
	matchPath,
	normalizePath,
};
