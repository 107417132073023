import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { PageHeaderWrapper, SvgIcon } from "Components";
import { SectionInfoUnits, SectionUnitInfoNivel } from "Types/Quiz";
import { Color } from "ivip-utils";

import style from "./style.module.scss";
import { Typography } from "@mui/material";
import { mdiAlertOctagon } from "@mdi/js";
import LevelButton from "Pages/Quiz/Components/LevelButton";
import Button from "Pages/Quiz/Components/Button";
import { APIHelper, InteractionHelper, QuizHelper } from "Helper";

const getPalette = (color: string) => {
	const colorPrimary = new Color(color);
	const [h, s, l] = (colorPrimary.hsl.match(/(\d+)/g) as string[]).map((v) => parseInt(v));
	const colorSecondary = new Color(Color.hsl(h + 30, s + 5, l - 10).hex);

	const colorDark = colorPrimary.darken(0.2);
	const colorLight = colorPrimary.lighten(0.2);

	const textColor = new Color(colorPrimary.vector.slice(0, 3).reduce((c, v) => c + v, 0) / 3 > 120 ? "#424242" : "#FAFAFA");

	return { colorPrimary, colorSecondary, colorDark, colorLight, textColor };
};

let posiplePositons = [0, 78, 116, 78];
let posipleIndicador = [0, 0.5, 0.7, 0.5];

const difficultyLabel = {
	easy: "Fácil",
	medium: "Médio",
	hard: "Difiçil",
};

const Section: React.FC<{
	id: string;
}> = ({ id }) => {
	const {
		error,
		response: section,
		status,
	} = APIHelper.useFetch<SectionInfoUnits>({
		...QuizHelper.fetchOptions.section(id),
		immediate: true,
	});

	const loading = status !== "success";

	useEffect(() => {
		const time = setTimeout(() => {
			if (status === "error" && error) {
				InteractionHelper.toast(error.message, null, "error");
			}
		}, 2000);
		return () => clearTimeout(time);
	}, [error, status]);

	//const [loading, setLoading] = useState<boolean>(true);
	//const [section, setSection] = useState<SectionInfoUnits | null>(null);

	const [showPopup, setShowPopup] = useState<boolean>(false);
	const [popupContent, setPopupContent] = useState<
		SectionUnitInfoNivel & {
			index: number;
			positionLeft: number;
			positionTop: number;
			color: Color;
			textColor: Color;
			length: number;
			current: number;
			unitId: string;
		}
	>({
		index: -1,
		positionLeft: 0,
		positionTop: 0,
		color: new Color("#212121"),
		textColor: new Color("#fafafa"),
		unitId: "",
		id: "",
		title: "",
		status: "locked",
		conquest: 5,
		difficulty: "easy",
		length: 0,
		current: 0,
	});

	const popupRef = useRef<HTMLDivElement>(null);

	// useLayoutEffect(() => {
	// 	const time = setTimeout(() => {
	// 		setSection(sectionUnits(id));
	// 		setLoading(false);
	// 	}, 2000);
	// 	return () => clearTimeout(time);
	// }, [id]);

	useLayoutEffect(() => {
		if (!showPopup) {
			return;
		}

		const event = (event: MouseEvent) => {
			if (!popupRef.current) return;
			if (event.target) {
				let parent = event.target as HTMLElement;
				while (parent) {
					if (parent.tagName === "BODY") {
						break;
					}
					if (parent.classList.contains(style.levelButton)) {
						return;
					}
					parent = parent.parentElement as HTMLElement;
				}
			}
			if (popupRef.current.contains(event.target as Node | null) !== true) {
				setShowPopup(false);
			}
		};

		document.addEventListener("mousedown", event);

		return () => {
			document.removeEventListener("mousedown", event);
		};
	}, [showPopup]);

	return (
		<PageHeaderWrapper
			colorDark={"#303030"}
			colorLight={"#b5b5b5"}
			title={!section ? "Quiz Educacional" : section.title}
			isLoading={loading}
			disableGradient={true}
		>
			<div className={style.main}>
				{!section && !loading && (
					<div className={style.noPage}>
						<SvgIcon path={mdiAlertOctagon} />
						<Typography
							variant="h4"
							component="h1"
						>
							Página não encontrada!
						</Typography>
					</div>
				)}

				{(section?.units ?? []).map(({ id: unitId, color, status, nivels }, i, self) => {
					const { colorPrimary, colorSecondary, colorDark, colorLight, textColor } = getPalette(
						!color || color.trim() === "" || status === "locked" ? "#424242" : color,
					);

					const beforeLength = i > 0 ? self.slice(0, i - 1).reduce((c, { nivels }) => c + nivels.length, 0) : 0;

					return (
						<React.Fragment key={i}>
							<div
								className={[style.unit_summary, status === "locked" ? style.locked : ""].join(" ")}
								style={
									{
										"--color-primary": colorPrimary.vector.join(", "),
										"--color-secondary": colorSecondary.vector.join(", "),
										"--color-dark": colorDark.vector.join(", "),
										"--color-light": colorLight.vector.join(", "),
										"--text-color": textColor.vector.join(", "),
									} as React.CSSProperties & { [p: string]: string }
								}
							>
								<Typography
									variant="h5"
									component="h2"
								>
									{section?.units[i].title}
								</Typography>
								<Typography
									variant="body1"
									component="p"
								>
									{section?.units[i].description}
								</Typography>
							</div>
							{nivels.map((levelInfo, j, self) => {
								const index = j + beforeLength;
								let positionLeft = posiplePositons[index % 4] * ((index % 8) - 4 < 0 ? -1 : 1);

								return (
									<LevelButton
										key={j}
										{...levelInfo}
										className={style.levelButton}
										showPopup={!showPopup}
										disabled={levelInfo.status === "locked"}
										color={color}
										style={{
											margin: `15px 0px 15px ${positionLeft}px`,
										}}
										onClick={(id, levelInfo, element) => {
											let positionTop = 0;

											if (element) {
												const { height } = element.getBoundingClientRect();
												positionTop = element.offsetTop + height + 15;
											}

											let indicador = posipleIndicador[index % 4] * ((index % 8) - 4 < 0 ? 1 : -1);
											setPopupContent({
												index: index,
												positionLeft: positionLeft + 80 * indicador,
												positionTop,
												color: colorPrimary,
												textColor: textColor,
												length: self.length,
												current: j + 1,
												unitId,
												...levelInfo,
											});
											setShowPopup(true);
										}}
									/>
								);
							})}
						</React.Fragment>
					);
				})}

				{showPopup && (
					<div
						ref={popupRef}
						className={style.levelInfoPopUp}
						style={
							{
								"top": `${popupContent.positionTop}px`,
								"--position-left": `${popupContent.positionLeft}px`,
								"--color-primary": popupContent.status === "locked" ? "66,66,66" : popupContent.color.vector.join(", "),
								"--text-color": popupContent.status === "locked" ? "250,250,250" : popupContent.textColor.vector.join(", "),
								"--opacity": popupContent.status === "locked" ? ".5" : "1",
							} as React.CSSProperties & { [p: string]: string }
						}
					>
						<div className={style.header}>
							<Typography
								variant="h6"
								component="div"
							>
								{popupContent.title}
							</Typography>
							<Typography
								variant="button"
								component="div"
							>
								{difficultyLabel[popupContent.difficulty]}
							</Typography>
						</div>
						<Typography
							variant="body1"
							component="div"
						>
							{popupContent.status === "locked"
								? `Complete todos os níveis acima pra desbloquear esse aqui!`
								: `Lição ${popupContent.current} de ${popupContent.length}`}
						</Typography>
						<div>
							<Button
								label={popupContent.status === "locked" ? "Bloqueado" : `Começar +${popupContent.conquest}xp`}
								onClick={() => {
									InteractionHelper.route.replace(`/quiz/section/${id}/game`, {
										sectionId: id,
										unitId: popupContent.unitId,
										levelId: popupContent.id,
									});
									// InteractionHelper.toast("Não é possível acessar este nível no momento!", undefined, "warning");
								}}
								color={popupContent.color.hex}
								disabled={popupContent.status === "locked"}
							/>
						</div>
					</div>
				)}
			</div>
		</PageHeaderWrapper>
	);
};

export default Section;
