import React, { useCallback, useEffect, useRef, useState } from "react";

import style from "./style.module.scss";
import { maskAmount } from "Utils";

export type ComparativeOptions = Partial<{
	axises: Partial<{
		y: Partial<{
			render: (value: number) => string | number;
		}>;
	}>;
	colors: {
		primary: string;
		secondary: string;
	};
}>;

export interface ComparativeData {
	labels: string[];
	primary: number[];
	secondary: number[];
}

const Comparative: React.FC<{
	data: ComparativeData;
	options?: ComparativeOptions;
}> = ({ data, options }) => {
	const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);
	const y_axis_primary_ref = useRef<HTMLDivElement>(null);
	const y_axis_secondary_ref = useRef<HTMLDivElement>(null);
	const graph_primary_ref = useRef<HTMLDivElement>(null);
	const graph_secondary_ref = useRef<HTMLDivElement>(null);

	const x_axis_ref = useRef<HTMLDivElement>(null);

	const max_value_primary = Math.max(...data.primary, 0) ?? 0;
	const max_value_secondary = Math.max(...data.secondary, 0) ?? 0;

	const color_primary = options?.colors?.primary;
	const color_secondary = options?.colors?.secondary;

	const update = useCallback(() => {
		if (y_axis_primary_ref.current && y_axis_secondary_ref.current && x_axis_ref.current) {
			y_axis_primary_ref.current.style.width = `auto`;
			y_axis_secondary_ref.current.style.width = `auto`;

			const primary_width = y_axis_primary_ref.current.getBoundingClientRect().width;
			const primary_height = y_axis_primary_ref.current.getBoundingClientRect().height;
			const secondary_width = y_axis_secondary_ref.current.getBoundingClientRect().width;
			const secondary_height = y_axis_secondary_ref.current.getBoundingClientRect().height;
			const max_width = Math.max(primary_width, secondary_width);
			const max_height = Math.max(primary_height, secondary_height);

			y_axis_primary_ref.current.style.width = `${max_width}px`;
			y_axis_secondary_ref.current.style.width = `${max_width}px`;
			x_axis_ref.current.style.marginLeft = `${max_width}px`;
		}

		if (graph_primary_ref.current && graph_secondary_ref.current) {
			graph_primary_ref.current.style.width = `auto`;
			graph_secondary_ref.current.style.width = `auto`;

			const primary_width = graph_primary_ref.current.getBoundingClientRect().width;
			const secondary_width = graph_secondary_ref.current.getBoundingClientRect().width;
			const max_width = Math.max(primary_width, secondary_width);

			setInnerWidth(max_width);
		}
	}, [data, options]);

	useEffect(() => {
		let time: NodeJS.Timeout;

		const event = () => {
			time = setTimeout(() => {
				update();
			}, 500);
		};

		window.addEventListener("resize", event);

		event();

		return () => {
			clearTimeout(time);
			window.removeEventListener("resize", event);
		};
	}, [data, options, update]);

	const marker_width = 50;

	const max_length = Math.min(data.primary.length, Math.floor((innerWidth - 30) / marker_width));

	const data_primary = data.primary.slice(0, max_length);
	const data_secondary = data.secondary.slice(0, max_length);

	const labels = data.labels.slice(0, max_length);

	return (
		<div className={[style["chart-content"], style["chart-comparative"]].join(" ")}>
			<div className={style["content"]}>
				<div
					style={{
						flex: max_value_primary < max_value_secondary ? "1" : "auto",
					}}
				>
					<div
						className={style["y-axis"]}
						ref={y_axis_primary_ref}
					>
						{new Array(max_value_primary > max_value_secondary ? 5 : 3).fill(0).map((_, index, list) => {
							let value: number | string = parseFloat(((max_value_primary / (list.length - 1)) * index).toFixed(2));
							if (typeof options?.axises?.y?.render === "function") {
								value = options?.axises?.y?.render(value);
							}
							return <div key={index}>{value}</div>;
						})}
					</div>
					<div
						className={style["graph"]}
						ref={graph_primary_ref}
					>
						{data_primary.map((value, index) => {
							return (
								<div key={index}>
									<div
										key={index}
										style={{
											height: `${(value / max_value_primary) * 100}%`,
											backgroundColor: color_primary,
										}}
									></div>
								</div>
							);
						})}
					</div>
				</div>
				<div
					style={{
						flex: max_value_primary > max_value_secondary ? "1" : "auto",
					}}
				>
					<div
						className={style["y-axis"]}
						ref={y_axis_secondary_ref}
					>
						{new Array(max_value_primary < max_value_secondary ? 5 : 3).fill(0).map((_, index, list) => {
							let value: number | string = parseFloat(((max_value_secondary / (list.length - 1)) * index).toFixed(2));
							if (typeof options?.axises?.y?.render === "function") {
								value = options?.axises?.y?.render(value);
							}
							return <div key={index}>{value}</div>;
						})}
					</div>
					<div
						className={style["graph"]}
						ref={graph_secondary_ref}
					>
						{data_secondary.map((value, index) => {
							return (
								<div key={index}>
									<div
										key={index}
										style={{
											height: `${(value / max_value_secondary) * 100}%`,
											backgroundColor: color_secondary,
										}}
									></div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div
				className={style["x-axis"]}
				ref={x_axis_ref}
			>
				{labels.map((value, index) => {
					return <div key={index}>{value}</div>;
				})}
			</div>
		</div>
	);
};

export default Comparative;
